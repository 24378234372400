import {
  CustomerLoyaltySegment,
  CustomerLoyaltySegmentDescription,
  CustomerLoyaltySegmentText,
} from '@karutekun/core/customer';
import { Tooltip } from '@mui/material';
import CChip from './CChip';

type OwnProps = {
  loyaltySegment: CustomerLoyaltySegment;
};

const CLoyaltySegmentChip: FC<OwnProps> = (props) => {
  const { loyaltySegment } = props;

  return (
    <Tooltip title={CustomerLoyaltySegmentDescription[loyaltySegment]}>
      <CChip size="small" label={CustomerLoyaltySegmentText[loyaltySegment]} />
    </Tooltip>
  );
};

export default CLoyaltySegmentChip;
