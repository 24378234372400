import { TextField, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React from 'react';
import {
  CounselingQuestion,
  CounselingQuestionOption,
  OptionItem,
  QuestionItem,
  QuestionType,
} from '../../models/counseling';
import CDivider from '../atoms/CDivider';
import CRadioGroup from '../atoms/CRadioGroup';
import CSelect from '../atoms/CSelect';
import CSelectMulti from '../atoms/CSelectMulti';

// Cannot invoke an expression whose type lacks a call signature を回避するための型宣言。。
type CommonCounseling = {
  id: number;
  order: number;
  title: string | null;
  description: string | null;
  isNewItem?: boolean; // サーバーに送る際はidを削除して送る。
  isActive: boolean;
  questions: QuestionItem[] | CounselingQuestion[];
};

type CommonQuestion = {
  id: number;
  type: QuestionType;
  order: number;
  title: string | null;
  description: string | null;
  isActive: boolean;
  options: CounselingQuestionOption[] | OptionItem[];
};

type CommonOption = {
  id: number;
  description: string;
  textPlaceholder: string | null;
  isActive: boolean;
};

export type Map = {
  [optionId: number]: { value: string | null };
};

type OwnProps = {
  counselings: CommonCounseling[];
  map?: Map;
  onChange?(map: Map): void;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  section: {
    marginBottom: theme.spacing(2),
  },
  sectionDescription: {
    whiteSpace: 'pre-wrap',
  },
  question: {
    marginBottom: theme.spacing(4),
  },
  questionTitle: {
    marginBottom: theme.spacing(1),
  },
  questionDescription: {
    marginBottom: theme.spacing(1),
    whiteSpace: 'pre-wrap',
  },
}));

// questionsとoptionsはsortされている前提になっています。
const CCounselingList: FC<OwnProps> = (props) => {
  const {
    counselings,
    map = {},
    onChange = () => {
      // 何もしない
    },
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.container}>
      {counselings.length > 0 ? (
        counselings
          .filter((c) => c.isActive)
          .map((counseling) => {
            const questions: CommonQuestion[] = counseling.questions;
            return (
              <div key={counseling.id} className={classes.section}>
                {counseling.title && (
                  <Typography variant="h5" align="center">
                    {counseling.title}
                  </Typography>
                )}
                {counseling.description && (
                  <Typography
                    variant="subtitle1"
                    align="center"
                    color="textSecondary"
                    className={classes.sectionDescription}
                  >
                    {counseling.description}
                  </Typography>
                )}

                <CDivider spacing={2} />

                {questions
                  .filter((q) => q.isActive)
                  .map((question) => {
                    return (
                      <div key={question.id} className={classes.question}>
                        {question.title && (
                          <Typography
                            variant="h6"
                            className={classes.questionTitle}
                          >
                            {question.title}
                          </Typography>
                        )}
                        {question.description && (
                          <Typography
                            variant="subtitle2"
                            className={classes.questionDescription}
                            color="textSecondary"
                          >
                            {question.description}
                          </Typography>
                        )}
                        <QuestionComponent
                          question={question}
                          map={map}
                          onChange={onChange}
                        />
                      </div>
                    );
                  })}
              </div>
            );
          })
      ) : (
        <Typography variant="body1" align="center" color="textSecondary">
          カウンセリング項目がありません
        </Typography>
      )}
    </div>
  );
};

const QuestionComponent: FC<{
  question: CommonQuestion;
  map: Map;
  onChange(map: Map): void;
}> = React.memo(
  function QuestionComponent(props) {
    const { question, map, onChange } = props;
    const options: CommonOption[] = question.options;
    const activeOptions = options.filter((q) => q.isActive);
    if (!activeOptions.length) {
      return null;
    }

    const optionIds = activeOptions.map((q) => q.id);
    switch (question.type) {
      case QuestionType.Checkbox: {
        return (
          <div>
            <CSelectMulti
              fullWidth
              onChange={(ids) => {
                const selectedIdMap = ids.reduce(
                  (prev: IdMap<boolean>, current: number) => {
                    prev[current] = true;
                    return prev;
                  },
                  {}
                );
                // 他の回答状況も入っているのでまずコピーしておく
                const updatedMap = { ...map };
                optionIds.forEach((id) => {
                  if (selectedIdMap[id] && !map[id]) {
                    updatedMap[id] = { value: null };
                  } else if (!selectedIdMap[id] && map[id]) {
                    delete updatedMap[id];
                  }
                });
                onChange(updatedMap);
              }}
              selected={activeOptions.map((q) => q.id).filter((id) => map[id])}
              options={activeOptions.map((q) => ({
                value: q.id,
                element: q.description,
              }))}
              emptyString="選択してください"
            />
            <ExtraTextInput
              options={activeOptions}
              map={map}
              onChange={onChange}
            />
          </div>
        );
      }
      case QuestionType.Radiobutton: {
        const currentValue = optionIds.find((id) => map[id]);
        // 初期値は非選択にしてnull,undefinedをいれないようにするため0を入れておく。(autoincrementのquestion_idとは一致しない。)
        return (
          <div>
            <CRadioGroup
              currentValue={currentValue || 0}
              options={activeOptions.map((q) => ({
                value: q.id,
                label: q.description,
              }))}
              onChange={(selectedId) => {
                const updatedMap = { ...map };
                optionIds.forEach((id) => {
                  if (selectedId === id && !map[id]) {
                    updatedMap[id] = { value: null };
                  } else if (selectedId !== id && map[id]) {
                    delete updatedMap[id];
                  }
                });
                onChange(updatedMap);
              }}
            />
            <ExtraTextInput
              options={activeOptions}
              map={map}
              onChange={onChange}
            />
          </div>
        );
      }
      case QuestionType.Pulldown: {
        const currentValue = optionIds.find((id) => map[id]);
        return (
          <CSelect
            options={activeOptions.map((q) => ({
              value: q.id,
              element: q.description,
            }))}
            displayEmpty
            emptyString="未選択"
            value={currentValue}
            onChange={(selectedId) => {
              const updatedMap = { ...map };
              optionIds.forEach((id) => {
                if (selectedId === id && !map[id]) {
                  updatedMap[id] = { value: null };
                } else if (selectedId !== id && map[id]) {
                  delete updatedMap[id];
                }
              });
              onChange(updatedMap);
            }}
          />
        );
      }
      case QuestionType.TextInput: {
        return (
          <div>
            {activeOptions.map((q) => {
              return (
                <TextField
                  key={q.id}
                  variant="standard"
                  inputProps={{ maxLength: 255 }}
                  label={q.description}
                  value={map[q.id] ? map[q.id].value || '' : ''}
                  multiline
                  fullWidth
                  onChange={(e) => {
                    const updatedMap = {
                      ...map,
                      [q.id]: {
                        ...map[q.id],
                        value: e.target.value,
                      },
                    };
                    // 空文字はisActive: falseにしておく。
                    if (e.target.value === '') {
                      delete updatedMap[q.id];
                    }
                    onChange(updatedMap);
                  }}
                />
              );
            })}
          </div>
        );
      }
      default: {
        return null;
      }
    }
  },
  (prev, next) => _.isEqual(prev, next)
);

const ExtraTextInput: FC<{
  options: CommonOption[];
  map: Map;
  onChange(map: Map): void;
}> = (props) => {
  const { options, map, onChange } = props;
  const targetOptions = options.filter((o) => o.textPlaceholder && map[o.id]);
  if (!targetOptions.length) {
    return null;
  }

  return (
    <div>
      {targetOptions.map((o) => {
        return (
          <TextField
            key={o.id}
            variant="standard"
            inputProps={{ maxLength: 255 }}
            placeholder={o.textPlaceholder || ''}
            value={map[o.id].value || ''}
            fullWidth
            onChange={(e) => {
              const updatedMap = {
                ...map,
                [o.id]: {
                  ...map[o.id],
                  value: e.target.value,
                },
              };
              onChange(updatedMap);
            }}
          />
        );
      })}
    </div>
  );
};

export default CCounselingList;
