import { formatDateTimeForUser } from '@karutekun/shared/util/datetime';
import { formatMoney } from '@karutekun/shared/util/format';
import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import CPriceLine from '../../../../../components_old/atoms/CPriceLine';
import { useSelectPaymentMethodMap } from '../../../../../selectors/salonSelector';
import { VoucherPayementMethodForPreview } from '../types';

type Props = {
  salonId: number;
  isPaid: boolean;
  paymentMethods: VoucherPayementMethodForPreview[];
  paymentChange: number;
  paidAt: number;
};

const useStyles = makeStyles((theme: Theme) => ({
  paymentContainer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
  },
  unpaidOpacity: {
    opacity: 0.6,
  },
  unpaidText: {
    color: theme.palette.accent.main,
  },
  paymentMethodContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

export const VoucherPaymentArea: FC<Props> = ({
  salonId,
  isPaid,
  paymentMethods,
  paymentChange,
  paidAt,
}) => {
  const classes = useStyles();
  const paymentMethodMap = useSelectPaymentMethodMap(salonId);
  return (
    <div className={classes.paymentContainer}>
      <div className={clsx({ [classes.unpaidOpacity]: !isPaid })}>
        {paymentMethods.map((vpm) => (
          <CPriceLine key={vpm.paymentMethodId}>
            <div className={classes.paymentMethodContainer}>
              <Typography variant="body1" style={{ marginRight: 5 }}>
                {paymentMethodMap[vpm.paymentMethodId]?.name}
              </Typography>
            </div>
            <Typography variant="body1">{formatMoney(vpm.amount)}</Typography>
          </CPriceLine>
        ))}
      </div>

      {isPaid && paymentChange !== 0 && (
        <Box mt={1}>
          <CPriceLine>
            <Typography variant="body1">お釣り</Typography>
            <Typography variant="body1">
              {formatMoney(paymentChange)}
            </Typography>
          </CPriceLine>
        </Box>
      )}

      {isPaid ? (
        <Typography variant="body2" color="textSecondary" align="right">
          会計日時: {formatDateTimeForUser(paidAt)}
        </Typography>
      ) : (
        <Typography
          variant="body1"
          align="right"
          className={classes.unpaidText}
        >
          下書き
        </Typography>
      )}
    </div>
  );
};
