import { CustomerLoyaltySegment } from '@karutekun/core/customer';
import { ServiceType } from '@karutekun/core/salon-service';
import { trpc } from '@karutekun/shared/data-access/api-base';
import {
  VisitHistoryFilter,
  VisitHistorySort,
} from '@karutekun/shared/data-access/visit';
import {
  endOfMonthDate,
  startOfMonthDate,
  thisMonth,
  today,
} from '@karutekun/shared/util/datetime';
import { Card, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { keepPreviousData } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { StickyContainer } from 'react-sticky';
import CDivider from '../../../components_old/atoms/CDivider';
import CInformation from '../../../components_old/atoms/CInformation';
import CProgress from '../../../components_old/atoms/CProgress';
import CAnalyticsDisplayTaxIncludedPriceSwitcher from '../../../components_old/molecules/CAnalyticsDisplayTaxIncludedPriceSwitcher';
import { CChartBarSexAgeGroup } from '../../../components_old/molecules/CChartBarSexAgeGroup';
import CStickyDateRangeControl from '../../../components_old/molecules/CStickyDateRangeControl';
import CTrialWillEndBanner from '../../../components_old/molecules/CTrialWillEndBanner';
import CChartGroupedServiceSwitchable from '../../../components_old/organisms/charts/CChartGroupedServiceSwitchable';
import CChartWrapper from '../../../components_old/organisms/charts/CChartWrapper';
import { CStylistCustomerStatsCard } from '../../../components_old/organisms/stylists/CStylistCustomerStatsCard';
import { CStylistSalesStatsCard } from '../../../components_old/organisms/stylists/CStylistSalesStatsCard';
import { CustomerListData } from '../../../reducers/types';
import {
  selectMySalon,
  selectStylistMap,
} from '../../../selectors/salonSelector';
import { selectAnalyticsDisplayTaxIncludedPrice } from '../../../selectors/settingsSelector';
import { InformationText } from '../../../texts/infomation';
import { useSyncedQueryParams } from '../../../util/hooks/router/useSyncedQueryParams';
import { AnalyticsCustomerSection } from '../customers/_components/AnalyticsCustomerSection';
import { StylistDailyStatsSection } from './_components/StylistDailyStatsSection';
import { StylistLostCustomerStatsCard } from './_components/StylistLostCustomerStatsCard';
import { StylistRepeatCustomerStatsCard } from './_components/StylistRepeatCustomerStatsCard';
import { StylistSelect } from './_components/StylistSelect';
import { VisitListSection } from './_components/VisitListSection';

const useStyles = makeStyles(() => ({
  root: {
    padding: '76px 20px 20px 20px',
  },
}));

export const Stylist: FC = () => {
  const classes = useStyles();
  const params = useParams<{ stylistId: string }>();
  const navigate = useNavigate();
  const salon = useSelector(selectMySalon);
  const stylist = useSelector(selectStylistMap)[Number(params.stylistId)];
  const stylistId = stylist?.id;
  const displayTaxIncludedPrice = useSelector(
    selectAnalyticsDisplayTaxIncludedPrice
  );

  const [
    { dateRange, repeatDateRange, lostMonthRange, lostCustomerList, visitList },
    setParams,
    urlSearchParams,
  ] = useSyncedQueryParams(
    {
      dateRange: { from: startOfMonthDate(), to: endOfMonthDate() },
      repeatDateRange: { from: today(-180), to: today(-1) },
      lostMonthRange: { from: thisMonth(-12), to: thisMonth() },
      lostCustomerList: {
        page: 0,
        sortKey: undefined,
        sortOrder: undefined,
        filter: {
          salon: {
            salonId: salon.id,
            loyaltySegment: CustomerLoyaltySegment.RepeatLost,
          },
        },
      } as Pick<CustomerListData, 'page' | 'sortKey' | 'sortOrder' | 'filter'>,
      visitList: {} as {
        page?: number;
        sort?: VisitHistorySort;
        filter?: VisitHistoryFilter;
      },
    },
    true
  );

  const { isRefetching, data: aggregatedStats } =
    trpc.analytics.stylistStats.aggregatedStats.useQuery(
      {
        stylistId,
        range: { from: dateRange.from, to: dateRange.to },
      },
      { enabled: !!stylistId, placeholderData: keepPreviousData }
    );

  const handleChangeStylist = useCallback(
    (id: number) => navigate(`./../${id}?${urlSearchParams}`),
    [navigate, urlSearchParams]
  );

  const fixedCustomerFilter = useMemo(() => {
    return {
      salon: {
        salonId: salon.id,
        lastStylistId: stylistId,
        lastVisitedAt: { from: dateRange.from, to: dateRange.to },
      },
    };
  }, [dateRange.from, dateRange.to, salon.id, stylistId]);

  if (!stylistId) {
    return (
      <div className={classes.root}>
        <CProgress />;
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <CTrialWillEndBanner />
      <Grid container justifyContent="flex-end" alignItems="center">
        <CAnalyticsDisplayTaxIncludedPriceSwitcher />
        <CInformation
          type="dialog"
          content={InformationText.stylists.overall}
          size="lg"
          mb={8}
        />
      </Grid>

      <Grid
        container
        justifyContent="space-evenly"
        alignItems="center"
        spacing={2}
      >
        <Grid container item xs={12}>
          <Grid item container xs={12} sm={8} md={6}>
            <StylistSelect
              selectedStylistId={stylistId}
              onChange={handleChangeStylist}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <StylistRepeatCustomerStatsCard
            stylistId={stylistId}
            from={repeatDateRange.from}
            to={repeatDateRange.to}
            onChangeDate={(from, to) =>
              setParams({ repeatDateRange: { from, to } })
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StylistLostCustomerStatsCard
            stylistId={stylistId}
            from={lostMonthRange.from}
            to={lostMonthRange.to}
            onChangeMonth={(from, to) =>
              setParams({ lostMonthRange: { from, to } })
            }
          />
        </Grid>
      </Grid>

      <CDivider />

      <StickyContainer>
        <Grid
          container
          justifyContent="space-evenly"
          alignItems="center"
          spacing={2}
        >
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={12}>
              <CStickyDateRangeControl
                label="期間"
                from={dateRange.from}
                to={dateRange.to}
                onChange={(from, to) => {
                  setParams({ dateRange: { from, to } });
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={8}>
            <CStylistSalesStatsCard
              stats={aggregatedStats}
              isFetching={isRefetching}
              displayTaxIncludedPrice={displayTaxIncludedPrice}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CStylistCustomerStatsCard
              stats={aggregatedStats}
              isFetching={isRefetching}
              displayTaxIncludedPrice={displayTaxIncludedPrice}
            />
          </Grid>

          <Grid item xs={12}>
            <StylistDailyStatsSection
              stylistId={stylistId}
              from={dateRange.from}
              to={dateRange.to}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Card>
              <CChartWrapper
                title="メニュー分析"
                isFetching={isRefetching}
                informationText={InformationText.stylists.menuAnalytics}
                height={300}
              >
                <CChartGroupedServiceSwitchable
                  chartType="bar"
                  serviceType={ServiceType.Menu}
                  serviceNumMap={aggregatedStats?.serviceNumMap ?? {}}
                  serviceOriginalPriceSumMap={
                    (displayTaxIncludedPrice
                      ? aggregatedStats?.serviceOriginalUnitPriceSumMap
                      : aggregatedStats?.serviceOriginalUnitPriceSumWithoutTaxMap) ??
                    {}
                  }
                  serviceSalesSumMap={
                    (displayTaxIncludedPrice
                      ? aggregatedStats?.serviceSalesMap
                      : aggregatedStats?.serviceSalesWithoutTaxMap) ?? {}
                  }
                />
              </CChartWrapper>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card>
              <CChartWrapper
                title="店販分析"
                isFetching={isRefetching}
                informationText={InformationText.stylists.productAnalytics}
                height={300}
              >
                <CChartGroupedServiceSwitchable
                  chartType="bar"
                  serviceType={ServiceType.Product}
                  serviceNumMap={aggregatedStats?.serviceNumMap ?? {}}
                  serviceOriginalPriceSumMap={
                    (displayTaxIncludedPrice
                      ? aggregatedStats?.serviceOriginalUnitPriceSumMap
                      : aggregatedStats?.serviceOriginalUnitPriceSumWithoutTaxMap) ??
                    {}
                  }
                  serviceSalesSumMap={
                    (displayTaxIncludedPrice
                      ? aggregatedStats?.serviceSalesMap
                      : aggregatedStats?.serviceSalesWithoutTaxMap) ?? {}
                  }
                />
              </CChartWrapper>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CChartWrapper
                title="男女年齢分解"
                isFetching={isRefetching}
                informationText={InformationText.stylists.sexAge}
              >
                <CChartBarSexAgeGroup
                  sexAgeGroupMap={aggregatedStats?.customerSexAgeGroupMap}
                />
              </CChartWrapper>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <AnalyticsCustomerSection
              title="失客一覧"
              listData={lostCustomerList}
              fixedFilter={fixedCustomerFilter}
              onChangeListData={(listData) => {
                setParams({ lostCustomerList: listData });
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <VisitListSection
              stylistId={stylistId}
              listData={visitList}
              from={dateRange.from}
              to={dateRange.to}
              onChangeListData={(listData) => {
                setParams({ visitList: listData });
              }}
            />
          </Grid>
        </Grid>
      </StickyContainer>
    </div>
  );
};
