type ScheduleItem = {
  id: number;
  startedAt: number;
  finishedAt: number;
};

export type ScheduleRow = ScheduleItem[];

/**
 * スケジュールを重ならないように横並びに配置する
 */
export function alignSchedulesIntoRows(
  schedules: ScheduleItem[]
): ScheduleRow[] {
  if (schedules.length === 0) {
    return [];
  }

  // 前処理としてスケジュールを時刻順に並び替える
  schedules.sort((a, b) => {
    if (a.startedAt === b.startedAt) {
      return a.finishedAt - b.finishedAt;
    } else {
      return a.startedAt - b.startedAt;
    }
  });

  // 仮想の行
  // スケジュールを順々に配置していく際に、重なってしまう場合に新たに行が追加される
  const rows: ScheduleRow[] = [];

  for (const schedule of schedules) {
    let packed = false;

    // 順々に行に入れられるか調査
    for (const row of rows) {
      const last = row[row.length - 1];
      if (last.finishedAt <= schedule.startedAt) {
        row.push(schedule);
        packed = true;
        break;
      }
    }

    // 入れられない場合は新しい行を追加
    if (!packed) {
      rows.push([schedule]);
    }
  }

  return rows;
}
