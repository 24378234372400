import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      d="M35.82 5.52c-.79-.56-1.88-.38-2.44.41l-9.23 12.94-9.54-13.25c-.56-.78-1.66-.96-2.44-.4-.78.56-.96 1.66-.4 2.44l9.68 13.45h-7.56c-.97 0-1.75.78-1.75 1.75s.78 1.75 1.75 1.75h8.36v4.41h-8.36c-.97 0-1.75.78-1.75 1.75s.78 1.75 1.75 1.75h8.36v8.83c0 .97.78 1.75 1.75 1.75s1.75-.78 1.75-1.75v-8.83h8.36c.97 0 1.75-.78 1.75-1.75s-.78-1.75-1.75-1.75h-8.36v-4.41h8.36c.97 0 1.75-.78 1.75-1.75s-.78-1.75-1.75-1.75h-7.27l9.38-13.15c.56-.79.38-1.88-.41-2.44z"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgYenSign = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgYenSign);
export default Memo;
