import { ServiceType } from '@karutekun/core/salon-service';
import { VoucherResource, VoucherUtils } from '@karutekun/core/voucher';
import { useMemo } from 'react';
import { useSelectSalonServices } from './serviceSelector';

export function useSelectVoucherLineServiceCategories(
  voucher: Nullable<VoucherResource>
) {
  const { serviceMap, categoryMap } = useSelectSalonServices(voucher?.salonId);
  return useMemo(() => {
    if (!voucher) {
      return [];
    }
    return VoucherUtils.extractCategoriesUnique(voucher.lines, {
      serviceMap,
      categoryMap,
    });
  }, [categoryMap, serviceMap, voucher]);
}

export function useSelectVoucherLineServices(
  voucher: Nullable<VoucherResource>,
  serviceType?: ServiceType
) {
  const { serviceMap, categoryMap, discountMap } = useSelectSalonServices(
    voucher?.salonId
  );
  return useMemo(() => {
    if (!voucher) {
      return { services: [], discounts: [] };
    }
    const services = VoucherUtils.extractVoucherLineServices(
      voucher.lines,
      { serviceMap, categoryMap },
      serviceType
    );
    const discounts = VoucherUtils.extractAllDiscountsUnique(voucher, {
      discountMap,
    });
    return { services, discounts };
  }, [categoryMap, discountMap, serviceMap, serviceType, voucher]);
}
