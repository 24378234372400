import { mapById } from '@karutekun/shared/util/objects';
import { DateType, DateTypes } from '../util/date';

// 設定上書き時のオプション
export type OverwriteOption = {
  overwrite: boolean;
  overwriteFrom?: string;
  overwriteManuallyModified?: boolean;
};

/**
 * BasicSettings
 */
export type ScheduleBasicSetting = {
  minuteInterval: MinuteInterval;
  shouldValidateCapacity: boolean;
  showBusinessInfo: boolean;
};

export function emptyScheduleBasicSetting(
  overwrite: Partial<ScheduleBasicSetting> = {}
): ScheduleBasicSetting {
  return {
    minuteInterval: 15,
    shouldValidateCapacity: false,
    showBusinessInfo: false,
    ...overwrite,
  };
}
export function scheduleBasicSettingFromResource(
  // TODO 一時的に lint を無効化しています。気づいたベースで直してください
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  res: any
): ScheduleBasicSetting {
  return {
    minuteInterval: res.minuteInterval,
    shouldValidateCapacity: res.shouldValidateCapacity,
    showBusinessInfo: res.showBusinessInfo,
  };
}

/**
 * BusinessHourSetting
 */
export type BusinessHour = {
  isNonBusinessDay: boolean;
  openTime: string;
  closeTime: string;
};
export type BusinessHourSetting = { [key in DateType]: BusinessHour };

export function emptyBusinessHour(
  overwrite: Partial<BusinessHour> = {}
): BusinessHour {
  return {
    isNonBusinessDay: false,
    openTime: '00:00:00',
    closeTime: '24:00:00',
    ...overwrite,
  };
}
export function emptyBusinessHourSetting(
  overwrite: Partial<BusinessHourSetting> = {}
): BusinessHourSetting {
  const empty = emptyBusinessHour();
  return {
    [DateType.Monday]: { ...empty },
    [DateType.Tuesday]: { ...empty },
    [DateType.Wednesday]: { ...empty },
    [DateType.Thursday]: { ...empty },
    [DateType.Friday]: { ...empty },
    [DateType.Saturday]: { ...empty },
    [DateType.Sunday]: { ...empty },
    [DateType.Holiday]: { ...empty },
    ...overwrite,
  };
}
// TODO 一時的に lint を無効化しています。気づいたベースで直してください
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function businessHourSettingFromResource(res: any): BusinessHourSetting {
  // TODO 一時的に lint を無効化しています。気づいたベースで直してください
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function createBusinessHour(res: any): BusinessHour {
    return {
      isNonBusinessDay: res.isNonBusinessDay,
      openTime: res.openTime,
      closeTime: res.closeTime,
    };
  }
  return DateTypes.reduce((prev, dateType) => {
    prev[dateType] = createBusinessHour(res[dateType]);
    return prev;
    // TODO 一時的に lint を無効化しています。気づいたベースで直してください
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }, {} as any);
}

/**
 * ScheduleCapacitySetting
 */
export const ScheduleCapacityMax = 99;
export type ScheduleCapacitySetting = { [key in DateType]: number };

export function emptyScheduleCapacitySetting(
  overwrite: Partial<ScheduleCapacitySetting> = {}
): ScheduleCapacitySetting {
  return {
    [DateType.Monday]: 0,
    [DateType.Tuesday]: 0,
    [DateType.Wednesday]: 0,
    [DateType.Thursday]: 0,
    [DateType.Friday]: 0,
    [DateType.Saturday]: 0,
    [DateType.Sunday]: 0,
    [DateType.Holiday]: 0,
    ...overwrite,
  };
}
export function scheduleCapacitySettingFromResource(
  // TODO 一時的に lint を無効化しています。気づいたベースで直してください
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  res: any
): ScheduleCapacitySetting {
  return DateTypes.reduce((prev, dateType) => {
    prev[dateType] = res[dateType];
    return prev;
    // TODO 一時的に lint を無効化しています。気づいたベースで直してください
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }, {} as any);
}

/**
 * Shift
 */
export type ShiftPattern = {
  id: number;
  title: string;
  titleShort: string;
  startTime: string | null;
  endTime: string | null;
  isActive: boolean;
};
export enum WorkType {
  DayOff = 0, // 休日
  FullTime = 1, // 全日出勤
  Shift = 2, // シフト勤務
}
export type PlainWorkShift =
  | {
      workType: WorkType.DayOff | WorkType.FullTime;
      shiftPatternId: null;
    }
  | {
      workType: WorkType.Shift;
      shiftPatternId: number;
    };
export type WorkShift =
  | {
      workType: WorkType.DayOff | WorkType.FullTime;
      shiftPatternId: null;
      shiftPattern: null;
    }
  | {
      workType: WorkType.Shift;
      shiftPatternId: number;
      shiftPattern: ShiftPattern;
    };
// TODO 一時的に lint を無効化しています。気づいたベースで直してください
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function shiftPatternFromResource(res: any): ShiftPattern {
  return {
    id: res.id,
    title: res.title,
    titleShort: res.titleShort,
    startTime: res.startTime,
    endTime: res.endTime,
    isActive: res.isActive,
  };
}
export function getShiftShortTitle(shift: WorkShift) {
  return shift.workType === WorkType.FullTime
    ? '出勤'
    : shift.workType === WorkType.DayOff
    ? '休日'
    : shift.shiftPattern?.titleShort || '?';
}

/**
 * All
 */
export type ScheduleSettings = {
  basicSetting: ScheduleBasicSetting;
  businessHourSetting: BusinessHourSetting | null;
  scheduleCapacitySetting: ScheduleCapacitySetting | null;
  shiftPatternMap: IdMap<ShiftPattern>;
};
export type PartialScheduleSettings = Partial<{
  basicSetting: Partial<ScheduleBasicSetting>;
  businessHourSetting: Partial<BusinessHourSetting> | null;
  scheduleCapacitySetting: Partial<ScheduleCapacitySetting> | null;
  shiftPatternMap: IdMap<ShiftPattern>;
}>;

export function emptyScheduleSettings(
  overwrite: PartialScheduleSettings = {}
): ScheduleSettings {
  return {
    basicSetting: emptyScheduleBasicSetting(overwrite.basicSetting),
    businessHourSetting: overwrite.businessHourSetting
      ? emptyBusinessHourSetting(overwrite.businessHourSetting)
      : null,
    scheduleCapacitySetting: overwrite.scheduleCapacitySetting
      ? emptyScheduleCapacitySetting(overwrite.scheduleCapacitySetting)
      : null,
    shiftPatternMap: overwrite.shiftPatternMap || {},
  };
}
// TODO 一時的に lint を無効化しています。気づいたベースで直してください
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function scheduleSettingsFromResource(res: any): ScheduleSettings {
  return {
    basicSetting: scheduleBasicSettingFromResource(res.basicSetting),
    businessHourSetting: res.businessHourSetting
      ? businessHourSettingFromResource(res.businessHourSetting)
      : null,
    scheduleCapacitySetting: res.scheduleCapacitySetting
      ? scheduleCapacitySettingFromResource(res.scheduleCapacitySetting)
      : null,
    shiftPatternMap: res.shiftPatterns
      ? mapById(res.shiftPatterns.map(shiftPatternFromResource))
      : {},
  };
}
