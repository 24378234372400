import { PermissionRoleEdit } from '@karutekun/core/permission-role';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback } from 'react';
import CButton from '../../../../../components_old/atoms/CButton';
import { useWidthDown } from '../../../../../util/hooks/useWidth';
import { useCreatePermissionRole } from '../_hooks/useCreatePermissionRole';
import { PermissionRoleForm } from './PermissionRoleForm';

type Props = {
  open: boolean;
  onClose(): void;
};

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    backgroundColor: theme.palette.background.default,
  },
}));

const formId = 'create-permission-role-form';

export const CreatePermissionRoleDialog: FC<Props> = (props) => {
  const classes = useStyles();

  const { open, onClose } = props;
  const { createPermissionRole, isFetching } = useCreatePermissionRole();

  const handleSubmit = useCallback(
    async (data: PermissionRoleEdit) => {
      await createPermissionRole(data);
      onClose();
    },
    [createPermissionRole, onClose]
  );

  const fullScreen = useWidthDown('sm');

  return (
    <Dialog
      classes={{ paper: classes.dialogContent }}
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>役割の追加</DialogTitle>
      <DialogContent dividers>
        <PermissionRoleForm formId={formId} onSubmit={handleSubmit} />
      </DialogContent>
      <DialogActions>
        <CButton
          disabled={isFetching}
          variant="outlined"
          color="primary"
          onClick={onClose}
        >
          キャンセル
        </CButton>
        <CButton
          disabled={isFetching}
          type="submit"
          variant="contained"
          color="primary"
          form={formId}
        >
          保存
        </CButton>
      </DialogActions>
    </Dialog>
  );
};
