import { moment } from '@karutekun/shared/util/datetime';
import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { pushSnackbarSuccess } from '../../../../../actions/generalAction';
import {
  fetchBusinessInfo,
  updateBusinessInfo,
} from '../../../../../actions/salonBusinessInfoAction';
import CButton from '../../../../../components_old/atoms/CButton';
import CDivider from '../../../../../components_old/atoms/CDivider';
import CProgressOverlay from '../../../../../components_old/atoms/CProgressOverlay';
import { DailyBusinessInfoUpdate } from '../../../../../models/salonBusinessInfo';
import { checkPermission } from '../../../../../models/stylist';
import { selectPlainBusinessInfoMap } from '../../../../../selectors/businessInfoSelector';
import {
  selectActiveStylists,
  useSelectMe,
} from '../../../../../selectors/salonSelector';
import {
  WithConfirmDialog,
  withConfirmDialog,
} from '../../../../../templates/hoc/ConfirmDialogHOC';
import { useFlag } from '../../../../../util/hooks/useFlag';
import { useThunkDispatch } from '../../../../../util/hooks/useThunkDispatch';
import { BusinessInfoCalendar } from './BusinessInfoCalendar';
import { ShiftTable } from './ShiftTable';

type Props = WithConfirmDialog;

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
  },
}));

const BusinessInfo: FC<Props> = (props) => {
  const classes = useStyles();

  const { openConfirmDialog, closeConfirmDialog } = props;

  const me = useSelectMe();
  const businessInfoMap = useSelector(selectPlainBusinessInfoMap);
  const stylists = useSelector(selectActiveStylists);
  const dispatch = useThunkDispatch();

  const hasPermission = checkPermission(me, 'canUpdateScheduleSetting');

  const [isFetching, callWithFetching] = useFlag(false);
  const [currentYearMonth, setCurrentYearMonth] = useState<{
    year: number;
    month: number;
  }>(() => {
    const m = moment();
    return { year: m.year(), month: m.month() + 1 };
  });

  const [tabIndex, setTabIndex] = useState(0);
  const [updateMap, setUpdateMap] = useState<DateMap<DailyBusinessInfoUpdate>>(
    {}
  );
  const isUpdated = Object.keys(updateMap).length > 0;

  const range = useMemo(() => {
    const m = moment()
      .year(currentYearMonth.year)
      .month(currentYearMonth.month - 1);
    return {
      from: m.startOf('month').format('YYYY-MM-DD'),
      to: m.endOf('month').format('YYYY-MM-DD'),
    };
  }, [currentYearMonth]);

  const handleFetchBusinessInfo = useCallback(
    async (range: { from: string; to: string }) => {
      const { request } = dispatch(fetchBusinessInfo(range));
      await request;
    },
    [dispatch]
  );

  useEffect(() => {
    callWithFetching(() => handleFetchBusinessInfo(range));
  }, [callWithFetching, dispatch, handleFetchBusinessInfo, range]);

  const handleRefresh = useCallback(() => {
    if (isUpdated) {
      openConfirmDialog({
        description: '編集内容が保存されていません。破棄してよろしいですか？',
        onOk: () => {
          closeConfirmDialog();
          setUpdateMap({});
          callWithFetching(() => handleFetchBusinessInfo(range));
        },
      });
    } else {
      callWithFetching(() => handleFetchBusinessInfo(range));
    }
  }, [
    callWithFetching,
    closeConfirmDialog,
    handleFetchBusinessInfo,
    isUpdated,
    openConfirmDialog,
    range,
  ]);

  const handleSave = useCallback(() => {
    callWithFetching(async () => {
      await dispatch(updateBusinessInfo(updateMap));
      dispatch(pushSnackbarSuccess('営業情報を更新しました'));
    }).then(() => setUpdateMap({}));
  }, [callWithFetching, dispatch, updateMap]);

  const handleUpdate = useCallback(
    (date: string, updates: Partial<DailyBusinessInfoUpdate>) => {
      const original = businessInfoMap[date];
      if (!original) {
        // 元データがない場合は無視(元データに対する更新なのでありえない)
        return;
      }

      setUpdateMap((updateMap) => {
        const updateResult: DailyBusinessInfoUpdate = {
          ...updateMap[date],
          date,
        };

        if (updates.businessHour !== undefined) {
          if (_.isEqual(original.businessHour, updates.businessHour)) {
            delete updateResult.businessHour;
          } else {
            updateResult.businessHour = updates.businessHour;
          }
        }
        if (updates.scheduleCapacity !== undefined) {
          if (typeof updates.scheduleCapacity === 'number') {
            updateResult.scheduleCapacity = updates.scheduleCapacity;
          } else {
            updateResult.scheduleCapacity = _.merge(
              {},
              updateResult.scheduleCapacity,
              updates.scheduleCapacity
            );
          }
        }
        if (updates.stylistShift !== undefined) {
          const stylistShift = {
            ...updateResult.stylistShift,
            ...updates.stylistShift,
          };

          if (
            _.isEqual(original.stylistShift, {
              ...original.stylistShift,
              ...stylistShift,
            })
          ) {
            delete updateResult.stylistShift;
          } else {
            updateResult.stylistShift = stylistShift;
          }
        }

        // 編集内容がない場合はキーから削除
        if (Object.keys(updateResult).length === 0) {
          const newInfo = _.cloneDeep(updateMap);
          delete newInfo[date];
          return newInfo;
        }

        return { ...updateMap, [date]: updateResult };
      });
    },
    [businessInfoMap]
  );

  const handleMonthChange = useCallback(
    (yearMonth: { year: number; month: number }) => {
      if (isUpdated) {
        openConfirmDialog({
          description: '編集内容が保存されていません。破棄してよろしいですか？',
          onOk: () => {
            closeConfirmDialog();
            setUpdateMap({});
            setCurrentYearMonth(yearMonth);
          },
        });
      } else {
        setCurrentYearMonth(yearMonth);
      }
    },
    [closeConfirmDialog, isUpdated, openConfirmDialog]
  );

  const handleClearUpdates = useCallback(() => {
    openConfirmDialog({
      description: '変更を破棄します。よろしいですか？',
      onOk: () => {
        closeConfirmDialog();
        setUpdateMap({});
      },
    });
  }, [closeConfirmDialog, openConfirmDialog]);

  return (
    <div className={classes.container}>
      {isFetching && <CProgressOverlay />}

      <Tabs
        value={tabIndex}
        indicatorColor="primary"
        textColor="primary"
        onChange={(_, v) => setTabIndex(v)}
      >
        <Tab label="営業カレンダー" />
        <Tab label="シフト表" />
      </Tabs>
      <CDivider spacing={0} />

      <Box p={2}>
        <MonthPickerHeader
          year={currentYearMonth.year}
          month={currentYearMonth.month}
          handleRefresh={handleRefresh}
          onChange={handleMonthChange}
        />

        {tabIndex === 0 ? (
          <BusinessInfoCalendar
            year={currentYearMonth.year}
            month={currentYearMonth.month}
            businessInfoMap={businessInfoMap}
            updateMap={updateMap}
            hasPermission={hasPermission}
            onChange={handleUpdate}
          />
        ) : (
          <ShiftTable
            year={currentYearMonth.year}
            month={currentYearMonth.month}
            stylists={stylists}
            businessInfoMap={businessInfoMap}
            updateMap={updateMap}
            hasPermission={hasPermission}
            onChange={handleUpdate}
          />
        )}

        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item>
              <CButton
                variant="outlined"
                disabled={!isUpdated}
                onClick={handleClearUpdates}
              >
                変更を破棄する
              </CButton>
            </Grid>
            <Grid item>
              <CButton disabled={!isUpdated} onClick={handleSave}>
                保存
              </CButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

const MonthPickerHeader: FC<{
  year: number;
  month: number;
  onChange(month: { year: number; month: number }): void;
  handleRefresh(): void;
}> = React.memo(function MonthPickerHeader(props) {
  const { year, month, handleRefresh, onChange } = props;

  const handlePressPrev = useCallback(() => {
    const m = moment()
      .year(year)
      .month(month - 1)
      .add(-1, 'month');
    onChange({ year: m.year(), month: m.month() + 1 });
  }, [year, month, onChange]);

  const handlePressNext = useCallback(() => {
    const m = moment()
      .year(year)
      .month(month - 1)
      .add(1, 'month');
    onChange({ year: m.year(), month: m.month() + 1 });
  }, [year, month, onChange]);

  const handlePressCurrentMonth = useCallback(() => {
    const m = moment();
    onChange({ year: m.year(), month: m.month() + 1 });
  }, [onChange]);

  return (
    <Box mb={1}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <IconButton onClick={handlePressPrev} size="large">
            <SVGIcon name="angle-left" />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant="h6">
            {year}年 {month}月
          </Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={handlePressNext} size="large">
            <SVGIcon name="angle-right" />
          </IconButton>
        </Grid>
        <Grid item>
          <Button
            size="small"
            variant="outlined"
            onClick={handlePressCurrentMonth}
          >
            今月
          </Button>
        </Grid>
        <Grid item>
          <Button
            size="small"
            variant="outlined"
            startIcon={<SVGIcon name="refresh" size="sm" />}
            onClick={handleRefresh}
          >
            再読み込み
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
});

export default withConfirmDialog(BusinessInfo);
