import { trpc } from '@karutekun/shared/data-access/api-base';
import { VisitHistoryFilter } from '@karutekun/shared/data-access/visit';
import { thisMonth, today } from '@karutekun/shared/util/datetime';
import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import CInformation from '../components_old/atoms/CInformation';
import CAnalyticsDisplayTaxIncludedPriceSwitcher from '../components_old/molecules/CAnalyticsDisplayTaxIncludedPriceSwitcher';
import CTrialWillEndBanner from '../components_old/molecules/CTrialWillEndBanner';
import { CSalonCustomerStatsCard } from '../components_old/organisms/salon/CSalonCustomerStatsCard';
import CSalonSalesStatsCard from '../components_old/organisms/salon/CSalonSalesStatsCard';
import { CVisitHistoryList } from '../components_old/organisms/visits/visitCardList/CVisitHistoryList';
import { selectAnalyticsDisplayTaxIncludedPrice } from '../selectors/settingsSelector';
import { InformationText } from '../texts/infomation';
import { useSyncedQueryParams } from '../util/hooks/router/useSyncedQueryParams';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '76px 20px 20px 20px',
  },
  maingrid: {
    marginTop: 24,
  },
  karuteListTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

export const Dashboard: FC = () => {
  const classes = useStyles();

  const [{ page }, setParams] = useSyncedQueryParams({ page: 0 }, true);

  const { isRefetching, data: { dailyStats } = {} } =
    trpc.analytics.salonStats.realtimeDailyStatsOfMonth.useQuery({
      month: thisMonth(),
    });

  const statsOfToday = useMemo(() => {
    const d = today();
    return dailyStats?.find((s) => s.date === d);
  }, [dailyStats]);

  const displayTaxIncludedPrice = useSelector(
    selectAnalyticsDisplayTaxIncludedPrice
  );

  const filter: VisitHistoryFilter = useMemo(() => {
    const d = today();
    return {
      startedAt: { from: d, to: d },
    };
  }, []);

  return (
    <div className={classes.root}>
      <CTrialWillEndBanner />

      <Grid container justifyContent="flex-end" alignItems="center">
        <CAnalyticsDisplayTaxIncludedPriceSwitcher />
        <CInformation
          type="dialog"
          content={InformationText.dashboard.overall}
          size="lg"
          mb={8}
        />
      </Grid>

      <Grid
        container
        justifyContent="space-evenly"
        alignItems="flex-start"
        spacing={2}
        className={classes.maingrid}
      >
        <Grid item xs={12} md={6}>
          <CSalonSalesStatsCard
            title={displayTaxIncludedPrice ? '本日 税込売上' : '本日 税抜売上'}
            stats={statsOfToday}
            isFetching={isRefetching}
            informationText={InformationText.dashboard.realTimeSalesCard}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CSalonCustomerStatsCard
            title="本日 来店回数"
            stats={statsOfToday}
            isFetching={isRefetching}
            informationText={InformationText.dashboard.realTimeCustomerCard}
          />
        </Grid>
      </Grid>

      <Typography variant="h5" mt={4} mb={2}>
        本日の来店記録
      </Typography>
      <CVisitHistoryList
        filter={filter}
        page={page}
        onPageChange={(page) => setParams({ page })}
      />
    </div>
  );
};
