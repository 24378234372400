import { RehydrateAction } from 'redux-persist/es/types';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from '../actions/settingsAction';
import {
  ScheduleDisplayFilter,
  ScheduleDisplayMode,
} from '../models/view/viewSchedule';

export type SettingsActions = ActionType<typeof actions>;

export interface ScheduleSettings {
  displayMode: ScheduleDisplayMode;
  filter: ScheduleDisplayFilter;
  scale: number;
}
export type SalonSettings = {
  schedule: ScheduleSettings;
};

export interface SettingsState {
  salonMap: {
    [salonId: number]: Nullable<SalonSettings>;
  };
  analyticsdisplayTaxIncludedPrice?: boolean;
}

const initialState: SettingsState = {
  salonMap: {},
  analyticsdisplayTaxIncludedPrice: true,
};

export function initialScheduleSetting(
  overwrite: Partial<ScheduleSettings> = {}
): ScheduleSettings {
  return {
    displayMode: ScheduleDisplayMode.Daily,
    filter: {
      hiddenStylistIds: [],
      showNonAssignedReserve: true,
    },
    scale: 1,
    ...overwrite,
  };
}

function settingsReducer(
  state = initialState,
  action: SettingsActions | RehydrateAction
): SettingsState {
  switch (action.type) {
    case getType(actions.setSettingsSchedule): {
      const { salonId, settings } = action.payload;
      const oldSettings = state.salonMap[salonId];
      return {
        ...state,
        salonMap: {
          ...state.salonMap,
          [salonId]: {
            ...oldSettings,
            schedule: initialScheduleSetting({
              ...(oldSettings ? oldSettings.schedule : {}),
              ...settings,
            }),
          },
        },
      };
    }
    case getType(actions.setSettingsAnalyticsDisplayTaxIncludedPrice): {
      const { displayTaxIncludedPrice } = action.payload;
      return {
        ...state,
        analyticsdisplayTaxIncludedPrice: displayTaxIncludedPrice,
      };
    }
    default:
      return state;
  }
}

export default settingsReducer;
