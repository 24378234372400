import React from 'react';
import { OwnProps as SelectCustomerDialogProps } from '../../templates/hoc/components/SelectCustomerDialog';
import SelectCustomerDialog from '../../templates/hoc/containers/SelectCustomerDialog';

type ArgumentProps = {
  defaultFilter?: SelectCustomerDialogProps['defaultFilter'];
  onCanceled?: SelectCustomerDialogProps['onCanceled'];
  onSelected: SelectCustomerDialogProps['onSelected'];
};
export type WithSelectCustomerDialog = {
  openSelectCustomerDialog(props: ArgumentProps): void;
  closeSelectCustomerDialog(): void;
};

type State = SelectCustomerDialogProps;

export const withSelectCustomerDialog = <OriginalProps extends {}>(
  WrappedComponent: React.ComponentType<
    OriginalProps & WithSelectCustomerDialog
  >
) => {
  return class SelectCustomerDialogHOC extends React.Component<
    OriginalProps,
    State
  > {
    constructor(props: OriginalProps) {
      super(props);

      this.state = {
        open: false,
        onCanceled: this.closeSelectCustomerDialog,
        onSelected: this.closeSelectCustomerDialog,
      };
    }

    openSelectCustomerDialog = (props: ArgumentProps) => {
      this.setState({ ...this.state, ...props, open: true });
    };

    closeSelectCustomerDialog = () => {
      this.setState({ ...this.state, open: false });
    };

    render() {
      return (
        <div>
          <SelectCustomerDialog {...this.state} />
          <WrappedComponent
            openSelectCustomerDialog={this.openSelectCustomerDialog}
            closeSelectCustomerDialog={this.closeSelectCustomerDialog}
            {...this.props}
          />
        </div>
      );
    }
  };
};
