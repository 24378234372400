import { mapById } from '@karutekun/shared/util/objects';
import { Dispatch } from 'redux';
import {
  CounselingSection,
  PartialCounselingSection,
} from '../../models/counseling';
import { sendRequest } from '../request';
import { setSalonCounselingMap } from '../salonAction';

export function updateCounseling(counselings: PartialCounselingSection[]) {
  return async function (dispatch: Dispatch) {
    const json = await sendRequest(dispatch, `salons/counselings`, {
      method: 'POST',
      body: JSON.stringify({ counselings }),
    });
    const updated: CounselingSection[] = json;

    dispatch(setSalonCounselingMap(mapById(updated)));
  };
}
