import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import { Grid, Theme, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import CButton from '../../../../../../../components_old/atoms/CButton';
import CDivider from '../../../../../../../components_old/atoms/CDivider';
import { PaymentProvider } from '../../../../../../../models/subscription';
import {
  WithConfirmDialog,
  withConfirmDialog,
} from '../../../../../../../templates/hoc/ConfirmDialogHOC';
import { PaymentProviderInfo } from '../../../../_components/PaymentProviderInfo';
import UpdatePaymentProviderDialog from './UpdatePaymentProviderDialog';

export type Props = {
  paymentProvider: PaymentProvider | null;
  canDelete: boolean;
  onDelete(): Promise<void>;
} & WithConfirmDialog;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1),
    maxWidth: 400,
  },
}));

const UpdatePaymentProviderForm: FC<Props> = (props) => {
  const classes = useStyles();

  const { paymentProvider, canDelete, onDelete } = props;

  const [isUpdateCardDialogOpen, setIsUpdateCardDialogOpen] = useState(false);

  const deleteButton = (
    <CButton
      disabled={!canDelete}
      variant="text"
      startIcon={<SVGIcon name="delete" />}
      onClick={() =>
        props.openConfirmDialog({
          title: 'お支払い方法の削除',
          description: '本当に削除してよろしいですか？',
          onOk: async () => {
            await onDelete();
            props.closeConfirmDialog();
          },
        })
      }
    >
      削除する
    </CButton>
  );

  return (
    <div className={classes.root}>
      <UpdatePaymentProviderDialog
        paymentProvider={paymentProvider}
        open={isUpdateCardDialogOpen}
        onClose={() => setIsUpdateCardDialogOpen(false)}
      />

      <PaymentProviderInfo paymentProvider={paymentProvider} />

      {paymentProvider !== null && (
        <>
          <CDivider />
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <CButton
                variant="text"
                startIcon={<SVGIcon name="pen" />}
                onClick={() => setIsUpdateCardDialogOpen(true)}
              >
                変更する
              </CButton>
            </Grid>
            <Grid item>
              {canDelete ? (
                deleteButton
              ) : (
                <Tooltip title="有料プランが有効な場合、お支払い情報は削除できません">
                  <span>{deleteButton}</span>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default withConfirmDialog(UpdatePaymentProviderForm);
