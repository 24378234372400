import storage from 'redux-persist/lib/storage'; // localStorage
import { ReduxStoreKey } from '../../storage';

/**
 * state のローカルへの保存戦略を管理します
 */
export const persistConfig = {
  storage,
  key: ReduxStoreKey,

  // 状態をローカルに保存する reducer 一覧
  whitelist: ['settings'],
};
