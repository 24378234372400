export * from './lib/data';
export * from './lib/resource';

import { calculateAndFillVoucherSales } from './lib/utils/calculate-and-fill-voucher-sales';
import { calculateTotalServiceMinutes } from './lib/utils/calculate-total-service-minutes';
import { extractAllDiscountsUnique } from './lib/utils/extract-all-discounts-unique';
import { extractCategoriesUnique } from './lib/utils/extract-categories-unique';
import { extractVoucherLineServices } from './lib/utils/extract-voucher-line-services';
import { getServiceTypeSalesAndTax } from './lib/utils/get-service-type-sales-and-tax';
import { isPayable } from './lib/utils/is-payable';
import { sortVoucherLinesByServiceOrder } from './lib/utils/sort-voucher-lines-by-service-order';
import { isPaid } from './lib/utils/voucher-status';
export const VoucherUtils = {
  calculateTotalServiceMinutes,
  extractAllDiscountsUnique,
  extractCategoriesUnique,
  extractVoucherLineServices,
  sortVoucherLinesByServiceOrder,
  getServiceTypeSalesAndTax,
  isPayable,
  isPaid,
  calculateAndFillVoucherSales,
};
