import _ from 'lodash';
import { BusinessCategory } from './salonMenuCategory';

function iconNameSetting(
  businessCategory: BusinessCategory | null = null,
  initialCategoryName: string | null = null,
  initialProduct: object | null = null
) {
  return {
    businessCategory,
    initialCategoryName,
    initialProduct,
  };
}

const iconNameMap = {
  '1101': iconNameSetting('ヘア', 'シャンプー', {
    name: 'シャンプー',
    price: 4000,
  }),
  '1102': iconNameSetting('ヘア', 'トリートメント'),
  '1103': iconNameSetting('ヘア', 'スタイリング剤'),
  '1104': iconNameSetting(null, '化粧品'),
  '1105': iconNameSetting(null, 'その他'),
  '1201': iconNameSetting('ネイル', 'ネイルケア', {
    name: 'ネイルオイル 10ml',
    price: 2500,
  }),
  '1202': iconNameSetting('ネイル', 'ハンドケア', {
    name: 'ハンドクリーム 50g',
    price: 2000,
  }),
  '1301': iconNameSetting('アイラッシュ', 'マスカラ', {
    name: 'マスカラ (ブラック)',
    price: 3000,
  }),
  '1302': iconNameSetting('アイラッシュ', 'まつげ美容液', {
    name: 'まつ毛美容液 2ml',
    price: 2000,
  }),
  '1303': iconNameSetting('アイラッシュ', 'アイシャンプー'),
  '1401': iconNameSetting('エステ', 'ボディ化粧品', {
    name: 'マッサージオイル',
    price: 2000,
  }),
  '1501': iconNameSetting('整体', 'ジェルパック', {
    name: 'アイス&ホット ジェルパック',
    price: 1000,
  }),
  // サロン作成時には選択されないが、設定から選択可能なカテゴリ
  '9001': iconNameSetting(),
  '9002': iconNameSetting(),
  '9003': iconNameSetting(),
  '9004': iconNameSetting(),
  '9005': iconNameSetting(),
  '9006': iconNameSetting(),
  '9007': iconNameSetting(),
  '9008': iconNameSetting(),
  '9009': iconNameSetting(),
  '9010': iconNameSetting(),
};
export type ProductIconName = keyof typeof iconNameMap;
const iconNames = Object.keys(iconNameMap) as ProductIconName[];
// サロン作成時に強制追加されるカテゴリ
const additionalIconNames: ProductIconName[] = ['1104', '1105'];

// iconNameに応じて初期店販データを作る
// サロン作成時の初期店販にのみ使用できます
export function buildInitialProductDataValues(iconName: ProductIconName) {
  if (!iconNameMap[iconName]) {
    throw new Error(`iconName not found: ${iconName}`);
  }
  return iconNameMap[iconName].initialProduct;
}

// 選択されたカテゴリの初期データ作成
export function buildInitialProductCategories(
  inputIconNames: ProductIconName[]
) {
  const validIconNames = _.uniq(
    inputIconNames
      .filter((iconName) => iconNameMap[iconName])
      .concat(additionalIconNames)
  );

  return validIconNames.map((iconName, i) => ({
    iconName,
    name: iconNameMap[iconName].initialCategoryName,
    order: validIconNames.length - i,
  }));
}

// 選択されたカテゴリの業種判定
export function getIconNamesByBusinessCategories(
  businessCategories: BusinessCategory[]
): ProductIconName[] {
  if (!businessCategories.length) {
    return [];
  }

  return iconNames.filter(
    (iconName) =>
      iconNameMap[iconName]?.businessCategory &&
      businessCategories.includes(
        iconNameMap[iconName].businessCategory as BusinessCategory
      )
  ) as ProductIconName[];
}

export function isValidProductIconName(iconName: string): boolean {
  return iconName in iconNameMap;
}
