import { Action, Dispatch } from 'redux';
import { sendRequest } from '../actions/request';

export function fetchOwnSalonStylists() {
  return async function (dispatch: Dispatch<Action>) {
    return await sendRequest(dispatch, 'salonStylist/fetch_by_own_stylist_id');
  };
}

/**
 * 予約一覧を読み込んだ時刻を更新する
 */
export function updateRecentReservationOpenedAt() {
  return async function (dispatch: Dispatch<Action>) {
    await sendRequest(
      dispatch,
      'salonStylist/update/recent_reservation_opened_at',
      { method: 'POST' }
    );
  };
}
