import { moment } from '@karutekun/shared/util/datetime';
import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import {
  Box,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useSelector } from 'react-redux';
import {
  pushSnackbarError,
  pushSnackbarSuccess,
} from '../../../../actions/generalAction';
import {
  createShiftPattern,
  deleteShiftPattern,
  fetchShiftPatterns,
  updateShiftPattern,
} from '../../../../actions/salon/scheduleSettingsAction';
import CButton from '../../../../components_old/atoms/CButton';
import CProgressOverlay from '../../../../components_old/atoms/CProgressOverlay';
import CRadioGroup from '../../../../components_old/atoms/CRadioGroup';
import CTimePicker from '../../../../components_old/atoms/CTimePicker';
import { ShiftPattern } from '../../../../models/salonScheduleSettings';
import { checkPermission } from '../../../../models/stylist';
import { useSelectMe } from '../../../../selectors/salonSelector';
import { selectActiveShiftPatterns } from '../../../../selectors/scheduleSettingsSelector';
import {
  WithConfirmDialog,
  withConfirmDialog,
} from '../../../../templates/hoc/ConfirmDialogHOC';
import { useFlag } from '../../../../util/hooks/useFlag';
import { useThunkDispatch } from '../../../../util/hooks/useThunkDispatch';

type Props = WithConfirmDialog;

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    position: 'relative',
    overflow: 'auto',
    minHeight: 100,
    maxWidth: 'fit-content',
  },
  table: {
    width: 500,
  },
  cellTitle: {
    minWidth: 140,
    padding: theme.spacing(1),
  },
  cell: {
    minWidth: 80,
    padding: theme.spacing(1),
  },
  cellSmall: {
    width: 40,
    padding: 0,
  },
  timeSelectContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const ShiftPatternSettingCard: FC<Props> = (props) => {
  const classes = useStyles();
  const { openConfirmDialog, closeConfirmDialog } = props;

  const me = useSelectMe();
  const shiftPatterns = useSelector(selectActiveShiftPatterns);
  const dispatch = useThunkDispatch();

  const hasPermission = checkPermission(me, 'canUpdateScheduleSetting');

  const [isFetching, callWithFetching] = useFlag(false);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);

  useEffect(() => {
    callWithFetching(async () => await dispatch(fetchShiftPatterns()));
  }, [callWithFetching, dispatch]);

  const handleCreate = useCallback(
    (
      shiftPattern: Pick<
        ShiftPattern,
        'title' | 'titleShort' | 'startTime' | 'endTime'
      >
    ) => {
      setIsAddDialogOpen(false);
      callWithFetching(async () => {
        try {
          await dispatch(createShiftPattern(shiftPattern));
          dispatch(pushSnackbarSuccess('勤務パターンを追加しました'));
        } catch {
          dispatch(pushSnackbarError('勤務パターンを追加できませんでした'));
        }
      });
    },
    [callWithFetching, dispatch]
  );
  const handleSave = useCallback(
    (id: number, updates: Pick<ShiftPattern, 'title' | 'titleShort'>) => {
      callWithFetching(async () => {
        try {
          await dispatch(updateShiftPattern(id, updates));
          dispatch(pushSnackbarSuccess('勤務パターンを編集しました'));
        } catch {
          dispatch(pushSnackbarError('勤務パターンを編集できませんでした'));
        }
      });
    },
    [callWithFetching, dispatch]
  );
  const handleDelete = useCallback(
    (id: number) => {
      openConfirmDialog({
        description: '本当に削除しますか？',
        onOk: () => {
          closeConfirmDialog();
          callWithFetching(async () => {
            try {
              await dispatch(deleteShiftPattern(id));
              dispatch(pushSnackbarSuccess('勤務パターンを削除しました'));
            } catch {
              dispatch(pushSnackbarError('勤務パターンを削除できませんでした'));
            }
          });
        },
      });
    },
    [callWithFetching, closeConfirmDialog, dispatch, openConfirmDialog]
  );

  const handleCloseAddDialog = useCallback(() => setIsAddDialogOpen(false), []);

  return (
    <Card className={classes.card}>
      {isFetching && <CProgressOverlay />}

      <AddShiftPatternDialog
        open={isAddDialogOpen}
        onClose={handleCloseAddDialog}
        onCreate={handleCreate}
      />

      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="center" className={classes.cellTitle}>
              名称
            </TableCell>
            <TableCell align="center" className={classes.cell}>
              略称
            </TableCell>
            <TableCell colSpan={3} align="center" className={classes.cell}>
              勤務時間
            </TableCell>
            <TableCell align="center" className={classes.cell}>
              編集
            </TableCell>
            <TableCell align="center" className={classes.cell}>
              削除
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell align="center" className={classes.cellTitle}>
              出勤
            </TableCell>
            <TableCell align="center" className={classes.cell}>
              出
            </TableCell>
            <TableCell align="right" className={classes.cell}>
              営業開始
            </TableCell>
            <TableCell align="center" className={classes.cellSmall}>
              〜
            </TableCell>
            <TableCell align="left" className={classes.cell}>
              営業終了
            </TableCell>
            <TableCell align="center" className={classes.cell} colSpan={2} />
          </TableRow>
          <TableRow>
            <TableCell align="center" className={classes.cellTitle}>
              休日
            </TableCell>
            <TableCell align="center" className={classes.cell}>
              休
            </TableCell>
            <TableCell align="center" className={classes.cell} colSpan={3}>
              -
            </TableCell>
            <TableCell align="center" className={classes.cell} colSpan={2} />
          </TableRow>

          {shiftPatterns.map((s) => (
            <ShiftPatternRow
              key={s.id}
              shiftPattern={s}
              disabled={!hasPermission}
              onSave={handleSave}
              onDelete={handleDelete}
            />
          ))}
        </TableBody>
      </Table>

      {hasPermission && (
        <Box p={2}>
          <CButton
            startIcon={<SVGIcon name="plus" />}
            onClick={() => setIsAddDialogOpen(true)}
          >
            勤務パターンを追加
          </CButton>
        </Box>
      )}
    </Card>
  );
};

const ShiftPatternRow: FC<{
  shiftPattern: ShiftPattern;
  disabled?: boolean;
  onSave(id: number, updates: Pick<ShiftPattern, 'title' | 'titleShort'>): void;
  onDelete(id: number): void;
}> = React.memo(function ShiftPatternRow(props) {
  const classes = useStyles();
  const { shiftPattern, disabled, onSave, onDelete } = props;

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const handleSave = useCallback(
    (id: number, updates: Pick<ShiftPattern, 'title' | 'titleShort'>) => {
      setIsEditDialogOpen(false);
      onSave(id, updates);
    },
    [onSave]
  );
  const handleDelete = useCallback(() => {
    onDelete(shiftPattern.id);
  }, [onDelete, shiftPattern.id]);

  const { startTime, endTime } = shiftPattern;

  return (
    <TableRow>
      <TableCell align="center" className={classes.cellTitle}>
        {shiftPattern.title}
      </TableCell>
      <TableCell align="center" className={classes.cell}>
        {shiftPattern.titleShort}
      </TableCell>
      <TableCell align="right" className={classes.cell}>
        <Typography variant="body2">
          {startTime === null
            ? '営業開始'
            : moment(startTime, 'HH:mm:ss').format('H:mm')}
        </Typography>
      </TableCell>
      <TableCell align="center" className={classes.cellSmall}>
        〜
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        <Typography variant="body2">
          {endTime === null
            ? '営業終了'
            : moment(endTime, 'HH:mm:ss').format('H:mm')}
        </Typography>
      </TableCell>
      <TableCell align="center" className={classes.cell}>
        <IconButton
          onClick={() => setIsEditDialogOpen(true)}
          disabled={disabled}
          size="large"
        >
          <SVGIcon name="pen" />
        </IconButton>
        <EditShiftPatternDialog
          open={isEditDialogOpen}
          shiftPattern={shiftPattern}
          onSave={handleSave}
          onClose={() => setIsEditDialogOpen(false)}
        />
      </TableCell>
      <TableCell align="center" className={classes.cell}>
        <IconButton onClick={handleDelete} disabled={disabled} size="large">
          <SVGIcon name="delete" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
});

const AddShiftPatternDialog: FC<{
  open: boolean;
  onCreate(
    shiftPattern: Pick<
      ShiftPattern,
      'title' | 'titleShort' | 'startTime' | 'endTime'
    >
  ): void;
  onClose(): void;
}> = React.memo(function AddShiftPatternDialog(props) {
  const classes = useStyles();

  const { open, onCreate, onClose } = props;

  const [shiftPattern, setShiftPattern] = useState<
    Pick<ShiftPattern, 'title' | 'titleShort' | 'startTime' | 'endTime'>
  >({
    title: '',
    titleShort: '',
    startTime: '09:00:00',
    endTime: '20:00:00',
  });

  useEffect(() => {
    if (open) {
      setShiftPattern({
        title: '',
        titleShort: '',
        startTime: '09:00:00',
        endTime: '20:00:00',
      });
    }
  }, [open]);

  const handleUpdate = useCallback(
    (update: Partial<typeof shiftPattern>) => {
      setShiftPattern({ ...shiftPattern, ...update });
    },
    [shiftPattern]
  );

  // 全日勤務に相当する勤務パターンは作成できないようにする
  const isFulltimeError =
    shiftPattern.startTime === null && shiftPattern.endTime === null;

  return (
    <Dialog open={open} onClose={onClose}>
      <ValidatorForm
        instantValidate={false}
        onSubmit={() => onCreate(shiftPattern)}
      >
        <DialogTitle>勤務パターンの追加</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12}>
              <TextValidator
                variant="standard"
                label="名称"
                placeholder="午後(14:00~)勤務"
                fullWidth
                value={shiftPattern.title}
                inputProps={{ maxLength: 32 }}
                onChange={(e) => handleUpdate({ title: e.target.value })}
                validators={['required']}
                errorMessages={['名称は必須です']}
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                variant="standard"
                label="略称"
                placeholder="午後"
                value={shiftPattern.titleShort}
                inputProps={{ maxLength: 2 }}
                onChange={(e) => handleUpdate({ titleShort: e.target.value })}
                validators={['required']}
                errorMessages={['略称は必須です']}
              />
            </Grid>
            <Grid item xs={12} className={classes.timeSelectContainer}>
              <CRadioGroup
                label="勤務開始時刻"
                row
                currentValue={shiftPattern.startTime === null ? 1 : 2}
                onChange={(v) =>
                  handleUpdate({ startTime: v === 1 ? null : '09:00:00' })
                }
                options={[
                  { value: 1, label: '店舗の営業開始から' },
                  { value: 2, label: '時刻指定' },
                ]}
              />
              {shiftPattern.startTime !== null && (
                <CTimePicker
                  value={shiftPattern.startTime}
                  minuteInterval={30}
                  onChange={(hhmmss) => handleUpdate({ startTime: hhmmss })}
                />
              )}
            </Grid>
            <Grid item xs={12} className={classes.timeSelectContainer}>
              <CRadioGroup
                label="勤務終了時刻"
                row
                currentValue={shiftPattern.endTime === null ? 1 : 2}
                onChange={(v) =>
                  handleUpdate({ endTime: v === 1 ? null : '20:00:00' })
                }
                options={[
                  { value: 1, label: '店舗の営業終了まで' },
                  { value: 2, label: '時刻指定' },
                ]}
              />
              {shiftPattern.endTime !== null && (
                <CTimePicker
                  value={shiftPattern.endTime}
                  minuteInterval={30}
                  onChange={(hhmmss) => handleUpdate({ endTime: hhmmss })}
                />
              )}
            </Grid>
            {isFulltimeError && (
              <Grid item xs={12}>
                <Typography variant="body1" color="error">
                  全日勤務の勤務パターンは作成できません。予め用意されている全日勤務のオプションをご利用ください。
                </Typography>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <CButton variant="outlined" onClick={onClose}>
            キャンセル
          </CButton>
          <CButton type="submit" disabled={isFulltimeError}>
            追加
          </CButton>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
});

const EditShiftPatternDialog: FC<{
  open: boolean;
  shiftPattern: ShiftPattern;
  onSave(id: number, updates: Pick<ShiftPattern, 'title' | 'titleShort'>): void;
  onClose(): void;
}> = React.memo(function EditShiftPatternDialog(props) {
  const { open, shiftPattern, onSave, onClose } = props;

  const [title, setTitle] = useState(shiftPattern.title);
  const [titleShort, setTitleShort] = useState(shiftPattern.titleShort);

  return (
    <Dialog open={open} onClose={onClose}>
      <ValidatorForm
        instantValidate={false}
        onSubmit={() => onSave(shiftPattern.id, { title, titleShort })}
      >
        <DialogTitle>勤務パターンの編集</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12}>
              <InputLabel shrink>勤務時間</InputLabel>
              <Box p={2}>
                <Typography variant="body1" gutterBottom>
                  {shiftPattern.startTime === null
                    ? '営業開始'
                    : moment(shiftPattern.startTime, 'HH:mm:ss').format(
                        'H:mm'
                      )}{' '}
                  〜{' '}
                  {shiftPattern.endTime === null
                    ? '営業終了'
                    : moment(shiftPattern.endTime, 'HH:mm:ss').format('H:mm')}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  ※ 勤務時間は編集できません
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                variant="standard"
                label="名称"
                placeholder="午後(14:00~)勤務"
                fullWidth
                value={title}
                inputProps={{ maxLength: 32 }}
                onChange={(e) => setTitle(e.target.value)}
                validators={['required']}
                errorMessages={['名称は必須です']}
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                variant="standard"
                label="略称"
                placeholder="午後"
                value={titleShort}
                inputProps={{ maxLength: 2 }}
                onChange={(e) => setTitleShort(e.target.value)}
                validators={['required']}
                errorMessages={['略称は必須です']}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CButton variant="outlined" onClick={onClose}>
            キャンセル
          </CButton>
          <CButton type="submit">保存</CButton>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
});

export default withConfirmDialog(ShiftPatternSettingCard);
