import { Typography } from '@mui/material';
import { memo, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {
  CustomerFilter,
  CustomerSortKey,
  CustomerSortOrder,
  fetchCustomers,
} from '../../../../actions/customerAction';
import CCustomerFilter from '../../../../components_old/organisms/CCustomerFilter';
import CCustomerTable from '../../../../components_old/organisms/CCustomerTable';
import { CustomerListData } from '../../../../reducers/types';
import { selectCustomerMap } from '../../../../selectors/customerSelector';
import {
  selectHasGroupSalon,
  selectMySalon,
  selectStylists,
} from '../../../../selectors/salonSelector';
import { notEmpty } from '../../../../util/common';
import { useFlag } from '../../../../util/hooks/useFlag';
import { useThunkDispatch } from '../../../../util/hooks/useThunkDispatch';

type ListData = Pick<
  CustomerListData,
  'page' | 'sortKey' | 'sortOrder' | 'filter'
>;

type Props = {
  title: string;
  listData: ListData;
  fixedFilter?: CustomerFilter;
  onChangeListData: (listData: ListData) => void;
};

const numCustomersPerPage = 10;

export const AnalyticsCustomerSection: FC<Props> = memo(
  ({ title, listData, fixedFilter, onChangeListData }) => {
    const dispatch = useThunkDispatch();

    const { filter, page, sortKey, sortOrder } = listData;

    const [isFetching, callWithFetcing] = useFlag();
    const [customerIds, setCustomerIds] = useState<number[]>([]);
    const [count, setCount] = useState(0);
    const customerMap = useSelector(selectCustomerMap);
    const customers = customerIds.map((id) => customerMap[id]).filter(notEmpty);

    const salon = useSelector(selectMySalon);
    const hasGroupSalon = useSelector(selectHasGroupSalon);
    const stylists = useSelector(selectStylists);

    const mergedFilter = useMemo(() => {
      const f = { ...filter, ...fixedFilter };
      if (f.salon?.salonId) {
        f.salon = {
          salonId: f.salon?.salonId,
          ...filter.salon,
          ...fixedFilter?.salon,
        };
      }
      return f;
    }, [filter, fixedFilter]);

    useDeepCompareEffect(() => {
      const { controller, request } = dispatch(
        fetchCustomers(
          {
            ...mergedFilter,
            limit: numCustomersPerPage,
            offset: page * numCustomersPerPage,
          },
          sortKey,
          sortOrder
        )
      );
      callWithFetcing(() =>
        request.then((data) => {
          if (data !== null) {
            setCount(data.count);
            setCustomerIds(data.customers.map((c) => c.id));
          }
        })
      );

      return () => controller?.abort();
    }, [callWithFetcing, dispatch, mergedFilter, page, sortKey, sortOrder]);

    const handleChangeFilter = useCallback(
      (filter: CustomerFilter) =>
        onChangeListData({ ...listData, filter, page: 0 }),
      [listData, onChangeListData]
    );
    const handleChangeSortOrder = useCallback(
      (sortKey: CustomerSortKey, sortOrder: CustomerSortOrder) =>
        onChangeListData({ ...listData, sortKey, sortOrder, page: 0 }),
      [listData, onChangeListData]
    );
    const handleChangePage = useCallback(
      (page: number) => onChangeListData({ ...listData, page }),
      [listData, onChangeListData]
    );

    const tableData = useMemo(
      () => ({
        isFetching,
        count,
        page: page,
        sortKey: sortKey,
        sortOrder: sortOrder,
      }),
      [count, isFetching, page, sortKey, sortOrder]
    );

    return (
      <>
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>
        <CCustomerFilter
          salonId={salon.id}
          hasGroupSalon={hasGroupSalon}
          filter={mergedFilter}
          useGroupData={false}
          stylists={stylists}
          fixedFilter={fixedFilter}
          isUseGroupDataFixed={true}
          onChangeFilter={handleChangeFilter}
        />
        <CCustomerTable
          useGroupData={false}
          customers={customers}
          customerListData={tableData}
          rowsPerPage={numCustomersPerPage}
          onSortOrderChange={handleChangeSortOrder}
          onPageChange={handleChangePage}
        />
      </>
    );
  }
);
