import { trpc } from '@karutekun/shared/data-access/api-base';
import { formatMoneyShrink } from '@karutekun/shared/util/format';
import { Card, Grid } from '@mui/material';
import { keepPreviousData } from '@tanstack/react-query';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import CChartBar from '../../../components_old/atoms/CChartBar';
import CChartComposedCustomerNumAverageBudget from '../../../components_old/molecules/CChartComposedCustomerNumAverageBudget';
import CChartHeatmapDayOfWeekVisitTime from '../../../components_old/molecules/CChartHeatmapDayOfWeekVisitTime';
import CChartWrapper from '../../../components_old/organisms/charts/CChartWrapper';
import { selectAnalyticsDisplayTaxIncludedPrice } from '../../../selectors/settingsSelector';
import { InformationText } from '../../../texts/infomation';
import { fmtDayOfWeek } from '../../../util/chart';
import { buildDayOfWeekMap } from '../../../util/common';
import { getSalesChartKeys } from './salesChartUtil';

type Props = {
  from: string;
  to: string;
};

export const SalonDayOfWeekStatsSection: FC<Props> = memo((props) => {
  const { from, to } = props;

  const { isRefetching, data: dayOfWeekStatsMap } =
    trpc.analytics.salonStats.dayOfWeekStats.useQuery(
      { from, to },
      { placeholderData: keepPreviousData }
    );

  const displayTaxIncludedPrice = useSelector(
    selectAnalyticsDisplayTaxIncludedPrice
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Card>
          <CChartWrapper
            title="曜日・時間別 平均滞在人数"
            isFetching={isRefetching}
            informationText={InformationText.salon.dayOfWeekVisitTime}
            height={500}
          >
            <CChartHeatmapDayOfWeekVisitTime
              dayOfWeekVisitTimeRatioMap={buildDayOfWeekMap(
                (dayOfWeek) =>
                  dayOfWeekStatsMap?.[dayOfWeek]?.visitTimeRatioMap ?? {}
              )}
            />
          </CChartWrapper>
        </Card>
      </Grid>
      <Grid item container xs={12} md={6} spacing={1}>
        <Grid item xs={12}>
          <Card>
            <CChartWrapper
              title="曜日別 平均来店客数"
              isFetching={isRefetching}
              informationText={InformationText.salon.dayOfWeekCustomerNum}
            >
              <CChartComposedCustomerNumAverageBudget
                data={Object.values(dayOfWeekStatsMap ?? {}).map((s) => ({
                  label: fmtDayOfWeek(s.dayOfWeek),
                  firstCustomerNum: s.firstVisitNum,
                  secondCustomerNum: s.repeatVisitNum,
                  averageBudget:
                    s.averageSalesPerCustomer === 0
                      ? null
                      : displayTaxIncludedPrice
                        ? s.averageSalesPerCustomer
                        : s.averageSalesPerCustomerWithoutTax,
                }))}
              />
            </CChartWrapper>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CChartWrapper
              title="曜日別 平均売上"
              isFetching={isRefetching}
              informationText={InformationText.salon.dayOfWeekSales}
            >
              <CChartBar
                data={Object.values(dayOfWeekStatsMap ?? {})}
                nameKey={(summary) => fmtDayOfWeek(summary.dayOfWeek)}
                formatter={formatMoneyShrink}
                valueKeys={getSalesChartKeys(displayTaxIncludedPrice)}
                stack
              />
            </CChartWrapper>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
});
