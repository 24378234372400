import appeal_line_iconChat from './img/appeal/line/icon_chat.png';
import appeal_line_iconSchedule from './img/appeal/line/icon_schedule.png';
import appeal_line_iconShare from './img/appeal/line/icon_share.png';
import appeal_line_title from './img/appeal/line/title.png';
import common_error from './img/common/error.png';
import common_logo from './img/common/karutekun_logo.png';
import common_title from './img/common/karutekun_title.png';
import common_loadingPulse from './img/common/loading_pulse.gif';
import common_maintenance from './img/common/maintenance.png';
import common_salonNoImage from './img/common/store_default.png';
import common_trialEndBanner from './img/common/trial_end.jpg';
import common_trialWillEndBanner from './img/common/trial_will_end.jpg';
import settings_reservation_isManToMan1 from './img/settings/reservation/is_man_to_man_1.png';
import settings_reservation_isManToMan2 from './img/settings/reservation/is_man_to_man_2.png';

export const Images = {
  common: {
    title: common_title,
    logo: common_logo,
    salonNoImage: common_salonNoImage,
    trialEndBanner: common_trialEndBanner,
    trialWillEndBanner: common_trialWillEndBanner,
    maintenance: common_maintenance,
    error: common_error,
    loadingPulse: common_loadingPulse,
  },
  appeal: {
    line: {
      title: appeal_line_title,
      iconSchedule: appeal_line_iconSchedule,
      iconChat: appeal_line_iconChat,
      iconShare: appeal_line_iconShare,
    },
  },
  settings: {
    reservation: {
      isManToMan1: settings_reservation_isManToMan1,
      isManToMan2: settings_reservation_isManToMan2,
    },
  },
};
