import { CustomerLoyaltySegment } from '@karutekun/core/customer';
import { TaxRoundingType } from '@karutekun/core/tax';
import { RelKey, StrictRel } from '@karutekun/shared/util/entity';
import { IPaymentMethod } from './payment-method';
import { ISalonCustomSetting } from './salon-custom-setting';

export enum BasicPlan {
  Trial = 0,
  Single = 1,
  Standard = 2,

  // 特例の人限定で、課金なし使い放題
  Unlimited = 9999,
}

export enum OptionLinePlan {
  Trial = 0,
  Single = 1,
  Standard = 2,
}

interface _ISalon {
  id: number;
  name: string;
  findKeyword: string;
  publicId: string;
  taxRoundingType: TaxRoundingType;
  basicPlan: BasicPlan;
  optionLinePlan: OptionLinePlan;
  isPlanDisabled: boolean;
  trialFinishAt: number;
  createdAt: number;
  updatedAt: number;

  image?: ISalonImage | null;
  customSetting?: ISalonCustomSetting;
  paymentMethods?: IPaymentMethod[];
}

export type ISalon<K extends RelKey<_ISalon> = never> = StrictRel<_ISalon, K>;

export enum QuestionType {
  Checkbox = 1,
  Radiobutton = 2,
  Pulldown = 3,
  TextInput = 4,
}

export interface ISalonCounselingQuestion {
  id: number;
  counselingId: number;
  salonId: number;
  order: number;
  type: QuestionType;
  title: string;
  description: string | null;
  isActive: boolean;
  createdAt: number;
  updatedAt: number;
  options?: ISalonCounselingQuestionOption[];
}

export interface ISalonCounselingQuestionOption {
  id: number;
  questionId: number;
  salonId: number;
  order: number;
  description: string;
  textPlaceholder: string | null;
  isActive: boolean;
  createdAt: number;
  updatedAt: number;
}

export interface ISalonCounselingSection {
  id: number;
  salonId: number;
  order: number;
  title: string;
  description: string | null;
  isActive: boolean;
  createdAt: number;
  updatedAt: number;
  questions?: ISalonCounselingQuestion[];
}

export interface ISalonMemoTemplate {
  id: number;
  salonId: number;
  template: string;
  createdAt: number;
  updatedAt: number;
  isActive: boolean;
  order: number;
}

export enum UploadedPaperStatus {
  Waiting = 0, // UPLOAD直後で処理待ち
  CustomerCreated = 1, // customer作成完了
  PartialCustomerCreated = 2, // customer作成完了とほぼ同じだが、読み取れない項目があり、省略した場合はこちら
  CannotRecognizeText = 3, // 名前等が認識できず、customer作成できず。要再アップロード
}

export interface ISalonUploadedPaper {
  id: number;
  salonId: number;
  status: UploadedPaperStatus;
  url1: string;
  url2: string | null;
  url3: string | null;
  customerId: number | null;
  createdAt: number;
  updatedAt: number;
}

export interface ISalonVisitMotivation {
  id: number;
  salonId: number;
  motivationName: string;
  createdAt: number;
  updatedAt: number;
  isActive: boolean;
}

export enum PrinterType {
  EPSON = 1,
}

export interface ISalonPrinter {
  id: number;
  salonId: number;
  type: PrinterType;
  printerSeriesId: number;
  address: string;
  createdAt: number;
  updatedAt: number;
}

export enum RequestStatus {
  NotYet = 0,
  Accepted = 1,
  Declined = 2,
  Canceled = 3,
}

export interface ISalonRequest {
  salonId: number;
  stylistId: number;
  status: RequestStatus;
  createdAt: number;
  updatedAt: number;
}

export interface ISalonImage {
  salonId: number;
  url: string;
}

export interface ISalonLineSetting {
  salonId: number;
  lineId: string;
  channelAccessToken: string;
  channelSecret: string;
  acceptMessage: boolean;
  notifyReservationChange: boolean;
  qrCodeEffectiveSeconds: number | null;
  qrCodeEffectiveVersion: number;
  qrCodeEffectiveVersionUpdatedAt: number;
  friendNum: number;
  blockNum: number;
  createdAt: number;
  updatedAt: number;
}

export interface ISalonCustomer {
  salonId: number;
  customerId: number;
  firstVisitedAt: number;
  lastVisitedAt: number;
  lastStylistId: number;
  totalVisitNum: number;
  totalSales: number;
  totalMenuSales: number;
  totalProductSales: number;
  averageBudget: number;
  averageVisitPeriod: number;
  isLastShimei: boolean;
  latestVisitId: number | null;
  loyaltySegment: CustomerLoyaltySegment;
  createdAt: number;
  updatedAt: number;
}

export interface ISalonGroup {
  groupId: number;
  salonId: number;
  createdAt: number;
}

export interface ISalonServiceTicketApplicableService {
  ticketServiceId: number;
  applicableServiceId: number;
  createdAt: number;
}

export interface ISalonReceiptContent {
  id: number;
  salonId: number;
  contentKey: string;
  value: string;
  isActive: boolean;
  createdAt: number;
  updatedAt: number;
}

export interface ISalonScheduleSetting {
  salonId: number;
  minuteInterval: MinuteInterval;
  shouldValidateCapacity: boolean;
  showBusinessInfo: boolean;
  createdAt: number;
  updatedAt: number;
}
