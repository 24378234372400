import { trpc } from '@karutekun/shared/data-access/api-base';
import { useCallback } from 'react';

/**
 * visit系のクエリを invalidate する
 * TODO: visit[リファクタリング] invalidate処理の設計をちゃんと考える
 */
export function useInvalidateVisits() {
  const utils = trpc.useContext();
  return useCallback(async () => await utils.visit.invalidate(), [utils.visit]);
}
