import { moment } from '@karutekun/shared/util/datetime';
import {
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';

type OwnProps = {
  value: string;
  onChange(hhmmss: string): void;
  small?: boolean;
  disabled?: boolean;
  minuteInterval?: MinuteInterval;

  // 24:00 を非表示にするかどうか
  hide24?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  outlinedInput: {
    textAlign: 'center',
    fontSize: theme.typography.body1.fontSize,
    cursor: 'pointer',
    padding: `${theme.spacing(1)} !important`,
  },
  outlinedInputSmall: {
    padding: `calc(${theme.spacing(1)} / 3) !important`,
    fontSize: theme.typography.body2.fontSize,
  },
}));

function twoDigit(n: number) {
  return n < 10 ? `0${n}` : `${n}`;
}

const timeOptionMap: {
  [interval in MinuteInterval]: {
    id: number;
    hh: string;
    mm: string;
    hhmmss: string;
  }[];
} = [5, 10, 15, 20, 30, 60].reduce(
  (prev, interval) => {
    const divs = 60 / interval;
    // TODO 一時的にルールを無効化しています。気づいたベースで直してください
    // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'number' can't be used to index type '{ 5: never[]; 10: never[]; 15: never[]; 20: never[]; 30: never[]; 60: never[]; }'.
    prev[interval] = Array(24 * divs + 1)
      .fill(0)
      .map((_, i) => {
        const hh = twoDigit(Math.floor(i / divs));
        const mm = twoDigit((i % divs) * interval);
        return { hh, mm, id: i, hhmmss: `${hh}:${mm}:00` };
      });
    return prev;
  },
  { 5: [], 10: [], 15: [], 20: [], 30: [], 60: [] }
);

function emptyIconComponent() {
  return null;
}

// TODO 一時的に lint を無効化しています。気づいたベースで直してください
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function extractHHmm(hhmmss: any) {
  if (hhmmss === '24:00:00') {
    return '24:00';
  }

  return moment(hhmmss, 'HH:mm:ss').format('HH:mm');
}

const CTimePicker: FC<OwnProps> = React.memo(function CTimePicker(props) {
  const classes = useStyles();

  const {
    value,
    onChange,
    disabled,
    small,
    minuteInterval = 15,
    hide24 = true,
  } = props;

  const handleChange = React.useCallback(
    (e: SelectChangeEvent<string>) => onChange(e.target.value),
    [onChange]
  );

  return (
    <Select
      value={value}
      disabled={disabled}
      onChange={handleChange}
      IconComponent={emptyIconComponent}
      // minuteInterval が変更されたときなど、オプションの中に value が存在しない
      // 可能性があるので、そういったときでも表示は出るようにしている
      renderValue={extractHHmm}
      input={
        <OutlinedInput
          classes={{
            input: clsx(classes.outlinedInput, {
              [classes.outlinedInputSmall]: small,
            }),
          }}
        />
      }
    >
      {timeOptionMap[minuteInterval].map((option) => {
        if (hide24 && option.hh === '24' && option.mm === '00') {
          return null;
        }
        return (
          <MenuItem key={option.id} dense value={option.hhmmss}>
            {option.hh}:{option.mm}
          </MenuItem>
        );
      })}

      {/* minuteInterval が変わると option に value が含まれない可能性があるので、
       その時は見えない要素を追加して、warning を回避する*/}
      {!timeOptionMap[minuteInterval].map((o) => o.hhmmss).includes(value) && (
        <MenuItem dense value={value} style={{ display: 'none' }} />
      )}
    </Select>
  );
});

export default CTimePicker;
