import { createSelector } from 'reselect';
import {
  ShiftPattern,
  emptyBusinessHourSetting,
  emptyScheduleCapacitySetting,
} from '../models/salonScheduleSettings';
import { GlobalState } from '../store';
import { notEmpty } from '../util/common';

export const selectScheduleSettings = (state: GlobalState) =>
  state.salon.mySalon.scheduleSettings;
export const selectBusinessHourSetting = createSelector(
  selectScheduleSettings,
  (settings) => settings.businessHourSetting ?? emptyBusinessHourSetting()
);
export const selectScheduleCapacitySetting = createSelector(
  selectScheduleSettings,
  (settings) =>
    settings.scheduleCapacitySetting ?? emptyScheduleCapacitySetting()
);
export const selectShowBusinessInfo = createSelector(
  selectScheduleSettings,
  (settings) => settings.basicSetting.showBusinessInfo
);

export const selectMinuteInterval = createSelector(
  selectScheduleSettings,
  (settings) => settings.basicSetting.minuteInterval
);

/**`
 * 勤務パターン
 */
export const selectShiftPatternMap = (
  state: GlobalState
): IdMap<ShiftPattern> =>
  state.salon.mySalon.scheduleSettings?.shiftPatternMap || {};
export const selectShiftPatterns = createSelector(
  selectShiftPatternMap,
  (shiftPatternMap: IdMap<ShiftPattern>): ShiftPattern[] => {
    return Object.values(shiftPatternMap).filter(notEmpty);
  }
);
export const selectActiveShiftPatterns = createSelector(
  selectShiftPatterns,
  (shiftPatterns: ShiftPattern[]): ShiftPattern[] => {
    return shiftPatterns.filter((s) => s.isActive);
  }
);
