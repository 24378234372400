import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';

/**
 * 条件を満たす場合に自動でリダイレクトする
 */
export function useRedirect(shouldRedirect: boolean, to?: string) {
  const location = useLocation();
  const navigate = useNavigate();

  const redirectPath = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return to || params.get('redirect') || '/';
  }, [location.search, to]);

  useEffect(() => {
    if (shouldRedirect) {
      navigate(redirectPath, { replace: true });
    }
  }, [navigate, redirectPath, shouldRedirect]);
}
