import { MenuItem, Select } from '@mui/material';
import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  PlainWorkShift,
  WorkType,
} from '../../../../../models/salonScheduleSettings';
import {
  selectActiveShiftPatterns,
  selectShiftPatternMap,
} from '../../../../../selectors/scheduleSettingsSelector';

type Props = {
  workShift: PlainWorkShift;
  disabled?: boolean;
  onChange(workShift: PlainWorkShift): void;
};

export const ScheduleShiftPatternSelect: FC<Props> = React.memo(
  function ScheduleShiftPatternSelect(props) {
    const { workShift, disabled, onChange } = props;

    const activeShiftPatterns = useSelector(selectActiveShiftPatterns);
    const allShiftPatternMap = useSelector(selectShiftPatternMap);

    return (
      <Select
        size="small"
        variant="standard"
        value={
          workShift.workType === WorkType.FullTime
            ? -1
            : workShift.workType === WorkType.DayOff
            ? -2
            : workShift.shiftPatternId
        }
        renderValue={(v) => {
          return v === -1
            ? '出勤'
            : v === -2
            ? '休日'
            : allShiftPatternMap[v as number]?.titleShort;
        }}
        onChange={(e) => {
          const v: number = e.target.value as number;
          onChange(
            v === -1
              ? {
                  workType: WorkType.FullTime,
                  shiftPatternId: null,
                }
              : v === -2
              ? {
                  workType: WorkType.DayOff,
                  shiftPatternId: null,
                }
              : {
                  workType: WorkType.Shift,
                  shiftPatternId: v,
                }
          );
        }}
        disabled={disabled}
      >
        {[
          { value: -1, title: '出勤' },
          { value: -2, title: '休日' },
          ...activeShiftPatterns.map((s) => ({
            value: s.id,
            title: s.title,
          })),
        ].map((option, i) => (
          <MenuItem key={i} dense value={option.value}>
            {option.title}
          </MenuItem>
        ))}
      </Select>
    );
  }
);
