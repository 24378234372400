import { useBoolean } from '@karutekun/shared/util/react-hooks';
import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Images } from '../../assets/images';
import { Header } from '../../components/Header';
import CAvatar from '../../components_old/atoms/CAvatar';
import CButton from '../../components_old/atoms/CButton';
import { SalonStylist } from '../../models/salonStylist';
import { LoginInfo } from '../../models/userStatus';
import { useWidthDown, useWidthUp } from '../../util/hooks/useWidth';

type OwnProps = {};
export type StateProps = {
  loginInfo: LoginInfo | null | undefined;
};
export type DispatchProps = {
  fetchOwnSalonStylists(): Promise<{
    ownSalonStylists: SalonStylist[];
    loginSalonId: number | null;
  }>;
  switchLoginSalon(salonId: number): Promise<void>;
  logout(): void;
};
type Props = OwnProps & StateProps & DispatchProps;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '100vh',
    paddingTop: 56,
  },
  container: {
    maxWidth: 640,
    padding: 28,
    [theme.breakpoints.down('md')]: {
      paddingTop: 32,
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
    },
  },
  salonInfoBox: {
    borderRadius: 16,
    border: `1px solid #D8D8D8`,
    [theme.breakpoints.down('md')]: {
      padding: 24,
    },
    [theme.breakpoints.up('sm')]: {
      padding: 32,
    },
    paddingTop: 24,
    paddingBottom: 24,
  },
  menuItem: {
    'color': theme.palette.text.primary,
    '&:focus, &:hover': {
      // TODO 一時的にルールを無効化しています。気づいたベースで直してください
      // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type '50' can't be used to index type 'PaletteColor'.
      backgroundColor: theme.palette.primary[50],
    },
    'paddingRight': 112, // デフォルトの48pxだと足りないのでボタン幅程度を指定
  },
  dialog: {
    maxHeight: '80vh',
  },
  dialogTitle: { paddingTop: 24, paddingBottom: 0 },
  dialogTitleText: {
    height: 30,
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '30px',
  },
  closeButton: {
    position: 'absolute',
    padding: 0,
    right: 20,
    top: 28,
    color: theme.palette.text.primary,
  },
  listItemSecondaryAction: { right: 0 },
  loginButton: { fontSize: 13, fontWeight: 700, width: 96, height: 32 },
}));

const NoAccessComponent: FC<Props> = (props) => {
  const classes = useStyles();
  const { loginInfo, fetchOwnSalonStylists, switchLoginSalon } = props;

  const isWidthUp = useWidthUp('sm');

  const [ownSalonStylists, setOwnSalonStylists] = useState<SalonStylist[]>([]);
  const [currentSalonId, setCurrentSalonId] = useState<number | null>(null);
  const [isDialogOpen, isDialogOpenMutations] = useBoolean();

  useEffect(() => {
    // このページに来る時点でサロンに所属はしている前提
    // admin/initでデータが取れないのでここで取得する。
    fetchOwnSalonStylists().then((result) => {
      setOwnSalonStylists(result.ownSalonStylists);
      setCurrentSalonId(result.loginSalonId);
    });
  }, [fetchOwnSalonStylists]);

  const onClickLoginLink = useCallback(
    (salonStylist: SalonStylist) => {
      if (currentSalonId === salonStylist.salonId || !salonStylist.canAccess) {
        return;
      }

      switchLoginSalon(salonStylist.salonId);
    },
    [currentSalonId, switchLoginSalon]
  );

  const currentSalonStylist = useMemo(
    () => ownSalonStylists.find((o) => o.salonId === currentSalonId),
    [currentSalonId, ownSalonStylists]
  );

  return (
    <div style={{ display: 'flex' }}>
      <Header />

      {ownSalonStylists.length > 1 && (
        <SwitchSalonDialog
          open={isDialogOpen}
          salonStylists={ownSalonStylists}
          currentSalonId={currentSalonId}
          onClick={onClickLoginLink}
          onClose={isDialogOpenMutations.setFalse}
        />
      )}

      <Grid
        container
        justifyContent="center"
        alignItems="flex-start"
        className={classes.root}
      >
        <Grid item container spacing={3} className={classes.container}>
          <Grid
            item
            container
            xs={12}
            justifyContent={isWidthUp ? 'flex-start' : 'center'}
          >
            <Typography variant="h4" style={{ fontWeight: 700 }}>
              アクセス権が必要です
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Box mb={5}>
              <Typography variant="h6">
                <Typography
                  variant="h6"
                  display="inline"
                  style={{ fontWeight: 700, color: '#ff504e' }}
                >
                  アクセス権を持つスタッフ
                </Typography>
                にアクセス権の付与を依頼してください。
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box mb={-1}>
              <Typography style={{ fontWeight: 700 }}>
                それでも解決しない場合は、以下をお試しください。
              </Typography>
            </Box>
          </Grid>

          {currentSalonStylist && (
            <Grid item xs={12}>
              <Box className={classes.salonInfoBox}>
                <Grid container spacing={3}>
                  <Grid item container sm={6} spacing={1}>
                    <Grid
                      item
                      container
                      xs={12}
                      justifyContent={isWidthUp ? 'flex-start' : 'center'}
                    >
                      <Typography variant="h6" style={{ fontWeight: 700 }}>
                        他のサロンに切り替える
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      justifyContent={isWidthUp ? 'flex-start' : 'center'}
                    >
                      <Typography color="primary" style={{ fontWeight: 700 }}>
                        <Typography color="textSecondary" display="inline">
                          ログイン中のサロン：
                        </Typography>
                        {currentSalonStylist.salonName}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    container
                    sm={6}
                    justifyContent={isWidthUp ? 'flex-end' : 'center'}
                    alignItems="center"
                  >
                    <CButton
                      variant="outlined"
                      style={{
                        height: 44,
                        width: '100%',
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                      onClick={isDialogOpenMutations.setTrue}
                    >
                      切り替える
                    </CButton>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}

          <Grid item xs={12}>
            {loginInfo && (
              <>
                <Grid
                  container
                  justifyContent={isWidthUp ? 'flex-start' : 'center'}
                >
                  <CButton
                    color="secondary"
                    variant="text"
                    onClick={props.logout}
                    style={{
                      paddingLeft: 0,
                      textDecoration: 'underline',
                      fontWeight: 700,
                      fontSize: 14,
                      height: 21,
                      marginBottom: 8,
                    }}
                  >
                    他のアカウントに切り替える
                  </CButton>
                </Grid>

                <Grid
                  container
                  justifyContent={isWidthUp ? 'flex-start' : 'center'}
                >
                  <Typography>
                    <Typography color="textSecondary" display="inline">
                      ログイン中のアカウント：
                    </Typography>
                    {loginInfo.email}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const SwitchSalonDialog: FC<{
  open: boolean;
  salonStylists: SalonStylist[];
  currentSalonId: number | null;
  onClick(salonStylist: SalonStylist): void;
  onClose(): void;
}> = (props) => {
  const classes = useStyles();
  const { open, salonStylists, currentSalonId } = props;

  const fullScreen = useWidthDown('sm');

  return (
    <Dialog
      disableEscapeKeyDown
      classes={fullScreen ? {} : { paper: classes.dialog }}
      maxWidth="xs"
      fullWidth={true}
      fullScreen={fullScreen}
      open={open}
      onClose={props.onClose}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography className={classes.dialogTitleText}>
          他のサロンに切り替える
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={props.onClose}
          size="large"
        >
          <SVGIcon name="times" />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <List disablePadding>
          {salonStylists.map((salonStylist, i) => (
            <LoginLink
              salonStylist={salonStylist}
              currentSalonId={currentSalonId}
              isLastItem={i === salonStylists.length - 1}
              onClick={props.onClick}
            />
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

const LoginLink: FC<{
  salonStylist: SalonStylist;
  currentSalonId: number | null;
  isLastItem: boolean;
  onClick(salonStylist: SalonStylist): void;
}> = (props) => {
  const { salonStylist, currentSalonId, isLastItem, onClick } = props;
  const classes = useStyles();

  return (
    <ListItem
      key={salonStylist.salonId}
      className={clsx(classes.menuItem)}
      disableGutters
      divider={!isLastItem}
    >
      <ListItemAvatar>
        <CAvatar
          size={30}
          src={salonStylist.salonImageURL ?? Images.common.salonNoImage}
        />
      </ListItemAvatar>

      <ListItemText
        primary={salonStylist.salonName}
        secondary={
          currentSalonId === salonStylist.salonId
            ? '現在ログイン中'
            : !salonStylist.canAccess
              ? 'アクセス制限'
              : ''
        }
      />

      {currentSalonId !== salonStylist.salonId && salonStylist.canAccess && (
        <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
          <CButton
            size="small"
            onClick={() => onClick(salonStylist)}
            variant="outlined"
            className={classes.loginButton}
          >
            切り替える
          </CButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

export default NoAccessComponent;
