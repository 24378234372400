import { ICustomerAnalyticsStatsCommon } from '@karutekun/core/stats/customer-analytics-stats';
import { formatMoney } from '@karutekun/shared/util/format';
import { Card, Divider, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    flex: '1',
  },
  card: {
    overflow: 'inherit',
    minHeight: 52,
    backgroundColor: theme.palette.secondary.main,
  },
}));

type Props = {
  title: string;
  data?: Pick<
    ICustomerAnalyticsStatsCommon,
    | 'customerNum'
    | 'menuSales'
    | 'menuSalesWithoutTax'
    | 'productSales'
    | 'productSalesWithoutTax'
    | 'paidVoucherNum'
  >;
  displayTaxIncludedPrice: boolean;
};

export const CCustomerAnalyticsStatsSummaryCard: FC<Props> = ({
  title,
  data,
  displayTaxIncludedPrice,
}) => {
  const classes = useStyles();

  const customerNum = data?.customerNum ?? 0;
  const menuSales =
    (displayTaxIncludedPrice ? data?.menuSales : data?.menuSalesWithoutTax) ??
    0;
  const productSales =
    (displayTaxIncludedPrice
      ? data?.productSales
      : data?.productSalesWithoutTax) ?? 0;

  const paidVoucherNum = data?.paidVoucherNum ?? 0;
  const averageMenuSalesPerCustomer =
    paidVoucherNum > 0 ? menuSales / paidVoucherNum : 0;
  const averageProductSalesPerCustomer =
    paidVoucherNum > 0 ? productSales / paidVoucherNum : 0;
  const averageSalesPerCustomer =
    paidVoucherNum > 0 ? (menuSales + productSales) / paidVoucherNum : 0;

  return (
    <div className={classes.main}>
      <Card className={classes.card}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          direction="column"
          style={{ paddingBottom: 16, paddingTop: 16 }}
        >
          <Typography variant="h4" style={{ color: '#fff' }} align="center">
            {customerNum}人
          </Typography>
          <Typography style={{ color: '#fff' }} align="center" variant="h6">
            {title}
          </Typography>
        </Grid>
        <Grid style={{ backgroundColor: '#fff' }}>
          <Grid
            container
            justifyContent="space-evenly"
            alignItems="center"
            direction="row"
            style={{ padding: 8 }}
          >
            <Grid item>
              <Typography variant="h5" align="center">
                {formatMoney(averageMenuSalesPerCustomer)}
              </Typography>
              <Typography color="textSecondary" align="center">
                施術単価
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5" align="center">
                {formatMoney(averageProductSalesPerCustomer)}
              </Typography>
              <Typography color="textSecondary" align="center">
                店販単価
              </Typography>
            </Grid>
          </Grid>
          <Divider variant="middle" />
          <Grid
            container
            justifyContent="space-evenly"
            alignItems="center"
            style={{ padding: 8 }}
          >
            <Typography color="textSecondary" align="center">
              平均客単価
            </Typography>
            <Typography variant="h5" align="center">
              {formatMoney(averageSalesPerCustomer)}
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};
