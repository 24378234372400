import { Breadcrumbs, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';

type Props = {
  breadcrumbs: { text: string; linkTo?: string }[];
};

const useStyles = makeStyles((_: Theme) => ({
  breadcrumbs: {
    marginTop: 32,
    marginBottom: 32,
  },
  breadcrumbsLink: {
    textDecoration: 'none',
  },
}));

const CBreadCrumbs: FC<Props> = (props) => {
  const { breadcrumbs } = props;

  const classes = useStyles();

  return (
    <Breadcrumbs
      separator=">"
      aria-label="breadcrumb"
      className={classes.breadcrumbs}
    >
      {breadcrumbs.map((data, i) =>
        data.linkTo ? (
          <Link key={i} to={data.linkTo} className={classes.breadcrumbsLink}>
            <Typography color="primary">{data.text}</Typography>
          </Link>
        ) : (
          <Typography key={i}>{data.text}</Typography>
        )
      )}
    </Breadcrumbs>
  );
};

export default CBreadCrumbs;
