import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="m4.75 8.242 38.093 15.8L4.75 39.752v-8.267l22.4-4.252c3.526-.669 3.527-5.718 0-6.386l-22.4-4.252zm-.026-3.8c-1.839-.778-3.474.79-3.474 2.364v10.62a2.5 2.5 0 0 0 2.031 2.453l21.924 4.16L3.285 28.2a2.501 2.501 0 0 0-2.035 2.457v10.642a2.484 2.484 0 0 0 3.49 2.243l40.018-16.504.025-.01a3.25 3.25 0 0 0 0-5.97l-.022-.01z"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgSend = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgSend);
export default Memo;
