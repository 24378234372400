import { ChatMessageBody } from '../chatMessageBody/entity';
import {
  ChatMessageBodyReservationRemind as ChatMessageBodyReservationRemindSchema,
  ReservationMenu,
  Stylist,
} from './schema';

export class ChatMessageBodyReservationRemind implements ChatMessageBody {
  title: string;
  scheduleId: number;
  startedAt: number;
  totalPriceText: string;
  stylist: Stylist | null;
  reservationMenus: ReservationMenu[];
  footerNote: string | null;

  constructor(args: ChatMessageBodyReservationRemindSchema) {
    this.title = args.title;
    this.scheduleId = args.scheduleId;
    this.startedAt = args.startedAt;
    this.totalPriceText = args.totalPriceText;
    this.stylist = args.stylist;
    this.reservationMenus = args.reservationMenus;
    this.footerNote = args.footerNote;
  }
}
