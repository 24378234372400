import { moment } from '@karutekun/shared/util/datetime';
import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React, { useRef, useState } from 'react';
import { PlainStylist } from '../../../models/stylist';
import {
  NonAssignedReservationColor,
  ScheduleDisplayFilter,
  ScheduleDisplayMode,
} from '../../../models/view/viewSchedule';
import CButton from '../../atoms/CButton';
import CDatePicker from '../../atoms/CDatePicker';
import CHorizontalScrollHintContainer from '../../atoms/CHorizontalScrollHintContainer';

type OwnProps = {
  date: string;
  displayMode: ScheduleDisplayMode;
  stylists: PlainStylist[];
  filter: ScheduleDisplayFilter;
  isFetching: boolean;

  showReservation: boolean;
  showBusinessInfo: boolean;
  newReservationCount: number;

  onChangeDisplayMode(displayMode: ScheduleDisplayMode): void;
  onChangeDate(date: string): void;
  onChangeFilter(filter: ScheduleDisplayFilter): void;
  onPressBusinessInfo(): void;
  onPressRecentReservationList(): void;
  onPressZoomIn(): void;
  onPressZoomOut(): void;
  onPressReload(): void;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  datePicker: {
    color: theme.palette.text.primary,
    backgroundColor: 'white',
  },
  titleInput: {
    width: 190,
    padding: theme.spacing(1),
    textAlign: 'center',
    fontSize: theme.typography.h6.fontSize,
    color: theme.palette.text.primary,
    cursor: 'pointer',
  },
  settingsPaper: {
    zIndex: theme.zIndex.modal,
  },
  button: {
    whiteSpace: 'nowrap',
  },
  stylistType: {
    width: 12,
    height: 12,
    borderRadius: 2,
  },
}));

const CSchedulesHeader: FC<OwnProps> = React.memo<OwnProps>(
  function CSchedulesHeader(props) {
    const classes = useStyles();

    const settingButtonRef = useRef(null);
    const [isSettingOpen, setIsSettingOpen] = useState(false);

    function goToPrevNextDay(delta: number) {
      props.onChangeDate(
        moment(props.date).add(delta, 'day').format('YYYY-MM-DD')
      );
    }

    return (
      <CHorizontalScrollHintContainer>
        <Grid
          container
          className={classes.container}
          alignItems="center"
          justifyContent="space-between"
          wrap="nowrap"
        >
          <Grid item>
            <Grid container alignItems="center" spacing={1} wrap="nowrap">
              <Grid item>
                <CDatePicker
                  date={props.date}
                  onChange={(date) => {
                    if (date) {
                      props.onChangeDate(date);
                    }
                  }}
                  format={
                    props.displayMode === ScheduleDisplayMode.Daily
                      ? 'YYYY年 M月D日 (dd)'
                      : 'YYYY年 M月'
                  }
                  textFieldProps={{
                    className: classes.datePicker,
                    variant: 'outlined',
                    InputProps: {
                      classes: { input: classes.titleInput },
                    },
                  }}
                />
              </Grid>

              <Grid item>
                <Grid container wrap="nowrap">
                  <Grid item>
                    <IconButton onClick={() => goToPrevNextDay(-1)}>
                      <SVGIcon name="angle-left" />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={() => goToPrevNextDay(1)}>
                      <SVGIcon name="angle-right" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() =>
                    props.onChangeDate(moment().format('YYYY-MM-DD'))
                  }
                >
                  今日
                </Button>
              </Grid>

              <Grid item>
                <CButton
                  className={classes.button}
                  variant="outlined"
                  size="small"
                  isLoading={props.isFetching}
                  startIcon={<SVGIcon name="refresh" size="sm" />}
                  onClick={props.onPressReload}
                >
                  再読み込み
                </CButton>
              </Grid>
            </Grid>
          </Grid>

          {props.showBusinessInfo && (
            <Grid item>
              <Box ml={2}>
                <Button
                  className={classes.button}
                  size="small"
                  variant="outlined"
                  startIcon={<SVGIcon name="calendar" size="sm" />}
                  onClick={props.onPressBusinessInfo}
                >
                  営業情報
                </Button>
              </Box>
            </Grid>
          )}

          {props.showReservation && (
            <Grid item>
              <Box ml={2}>
                <Badge
                  badgeContent={props.newReservationCount}
                  max={99}
                  color="primary"
                >
                  <Button
                    className={classes.button}
                    size="small"
                    variant="outlined"
                    onClick={props.onPressRecentReservationList}
                  >
                    新着予約
                  </Button>
                </Badge>
              </Box>
            </Grid>
          )}

          <Grid item xs>
            <Grid container justifyContent="flex-end">
              <Button
                ref={settingButtonRef}
                className={classes.button}
                size="small"
                variant="outlined"
                startIcon={<SVGIcon name="cog" size="sm" />}
                onClick={() => setIsSettingOpen(true)}
              >
                表示設定
              </Button>
              <Menu
                anchorEl={settingButtonRef.current}
                open={isSettingOpen}
                keepMounted
                onClose={() => setIsSettingOpen(false)}
              >
                <MenuItem>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing={1}
                  >
                    <Grid item>
                      <IconButton onClick={props.onPressZoomOut}>
                        <SVGIcon name="search-minus" />
                      </IconButton>
                    </Grid>
                    <Grid item xs>
                      <Typography style={{ textAlign: 'center' }}>
                        ズーム
                      </Typography>
                    </Grid>
                    <Grid item>
                      <IconButton onClick={props.onPressZoomIn}>
                        <SVGIcon name="search-plus" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </MenuItem>
                <MenuItem>
                  <Grid container justifyContent="center">
                    <SelectStylistMulti
                      stylists={props.stylists}
                      filter={props.filter}
                      onChange={props.onChangeFilter}
                    />
                  </Grid>
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Grid>
      </CHorizontalScrollHintContainer>
    );
  },
  (prev, next) => _.isEqual(prev, next)
);

const SelectStylistMulti: FC<{
  stylists: PlainStylist[];
  filter: ScheduleDisplayFilter;
  onChange(filter: ScheduleDisplayFilter): void;
}> = (props) => {
  const { stylists, filter, onChange } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  function handleChange(event: SelectChangeEvent<number[]>) {
    const ids = event.target.value as number[];
    onChange({
      ...filter,
      hiddenStylistIds: props.stylists
        .map((s) => s.id)
        .filter((id) => !ids.includes(id)),
      showNonAssignedReserve: ids.includes(NonAssignedId),
    });
  }

  function handleClose() {
    setOpen(false);
  }

  function handleOpen() {
    setOpen(true);
  }

  function renderValue(selected: number[]) {
    if (selected.length === 0) {
      return <Typography>選択してください</Typography>;
    }

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <SVGIcon name="calendar" style={{ marginRight: 5 }} size="sm" />
        <Typography>
          {selected.filter((id) => id !== NonAssignedId).length}名 表示中
        </Typography>
      </div>
    );
  }

  const NonAssignedId = 0;

  const selectedIds = props.stylists
    .map((s) => s.id)
    .filter((id) => !props.filter.hiddenStylistIds.includes(id));

  if (filter.showNonAssignedReserve) {
    selectedIds.push(NonAssignedId);
  }

  return (
    <Select
      variant="standard"
      multiple
      value={selectedIds}
      open={open}
      onChange={handleChange}
      onClose={handleClose}
      onOpen={handleOpen}
      // TODO 一時的に lint を無効化しています。気づいたベースで直してください
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderValue={renderValue as any}
      displayEmpty
    >
      {stylists.map((stylist) => (
        <MenuItem key={stylist.id} value={stylist.id}>
          <Checkbox checked={selectedIds.includes(stylist.id)} />
          <Box
            mr={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <div
              className={classes.stylistType}
              style={{ backgroundColor: stylist.color }}
            />
          </Box>
          <ListItemText
            primary={stylist.name}
            primaryTypographyProps={{
              color: stylist && !stylist.isActive ? 'textSecondary' : 'initial',
            }}
          />
        </MenuItem>
      ))}
      <MenuItem value={NonAssignedId}>
        <Checkbox checked={selectedIds.includes(NonAssignedId)} />
        <Box mr={1}>
          <div
            className={classes.stylistType}
            style={{ backgroundColor: NonAssignedReservationColor }}
          />
        </Box>
        <ListItemText primary="指名なし" />
      </MenuItem>
    </Select>
  );
};

export default CSchedulesHeader;
