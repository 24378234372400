import { Alert, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { isAdmin } from '../../models/stylist';
import { selectMe } from '../../selectors/salonSelector';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '76px 20px 20px 20px',
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
}));

export const withAdminPage = <OriginalProps extends {}>(
  WrappedComponent: React.ComponentType<OriginalProps>
) => {
  return function WithAdminHOC(props: OriginalProps) {
    const classes = useStyles();
    const me = useSelector(selectMe);

    return (
      <div className={classes.root}>
        <Alert className={classes.alert} severity="warning">
          このページは管理者のみご確認いただけます
        </Alert>
        {isAdmin(me) && <WrappedComponent {...props} />}
      </div>
    );
  };
};
