import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import { Button, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import CButton from '../../../../../components_old/atoms/CButton';
import CAutoCompleteCustomerItem from '../../../../../components_old/organisms/CAutoCompleteCustomerItem';
import { selectCustomer } from '../../../../../selectors/customerSelector';
import { GlobalState } from '../../../../../store';
import AutoCompleteCustomerInput from '../../../../../templates/containers/AutoCompleteCustomerInput';
import {
  WithCreateCustomerDialog,
  withCreateCustomerDialog,
} from '../../../../../templates/hoc/CreateCustomerDialogHOC';
import { useSalonStatus } from '../../../../../templates/providers/salonStatus/salonStatusContext';
import Routing from '../../../../../util/routing';

type Props = WithCreateCustomerDialog & {
  title: string;
  customerId: Nullable<number>;

  // 関連を解除できるかどうか
  isUnassociatable?: boolean;

  onChangeTitle?(title: string): void;
  onSelect?(customerId: number | null): void;
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    customerContainer: {
      display: 'flex',
      flexDirection: 'row',
      border: `1px solid ${theme.palette.grey[400]}`,
      borderRadius: 4,
    },
    customerLink: {
      width: '100%',
      padding: theme.spacing(1),
      textTransform: 'none',
      borderRadius: 0,
    },
    customerUnassociate: {
      padding: theme.spacing(1),
      minWidth: 0,
      color: theme.palette.text.secondary,
      borderRadius: 0,
      borderLeft: `1px solid ${theme.palette.divider}`,
    },
  };
});

const VisitFormCustomerSearchInput: FC<Props> = ({
  title,
  customerId,
  isUnassociatable,
  onSelect,
  onChangeTitle,
  openCreateCustomerDialog,
  closeCreateCustomerDialog,
}) => {
  const classes = useStyles();

  const { checkAndOpenDialog } = useSalonStatus();
  const customer = useSelector((state: GlobalState) =>
    customerId ? selectCustomer(state, customerId) : null
  );

  return (
    <div>
      {customer ? (
        <div className={classes.customerContainer}>
          <div style={{ flex: 1 }}>
            <CButton
              className={classes.customerLink}
              variant="text"
              linkTo={Routing.customer(customer.id)}
              openInNewWindow
            >
              <Grid container spacing={1} direction="column">
                <Grid item>
                  <CAutoCompleteCustomerItem customer={customer} />
                </Grid>
                {customer.allergy !== '' && (
                  <Grid item>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      style={{
                        whiteSpace: 'pre-wrap',
                        lineHeight: '1.2',
                      }}
                    >
                      {customer.allergy}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </CButton>
          </div>

          {isUnassociatable && (
            <Button
              className={classes.customerUnassociate}
              onClick={() => onSelect?.(null)}
            >
              <SVGIcon name="times" />
            </Button>
          )}
        </div>
      ) : (
        <AutoCompleteCustomerInput
          label="お客様"
          name={title}
          onChange={(name) => onChangeTitle?.(name)}
          onSelected={(customerId) => onSelect?.(customerId)}
          onCreateNewSelected={() => {
            if (!checkAndOpenDialog()) {
              return;
            }
            openCreateCustomerDialog({
              defaultData: { name: title },
              onCreateAndView: (customerId: number) => {
                onSelect?.(customerId);
                closeCreateCustomerDialog();
              },
            });
          }}
        />
      )}
    </div>
  );
};

export default withCreateCustomerDialog(VisitFormCustomerSearchInput);
