import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {
  CustomerFilter,
  CustomerSortKey,
  CustomerSortOrder,
} from '../../../actions/customerAction';
import CButton from '../../../components_old/atoms/CButton';
import CCustomerFilter from '../../../components_old/organisms/CCustomerFilter';
import CCustomerTable from '../../../components_old/organisms/CCustomerTable';
import { Customer } from '../../../models/customer';
import { Salon } from '../../../models/salon';
import { PlainStylist } from '../../../models/stylist';
import { CustomerListData } from '../../../reducers/types';
import { useWidthDown } from '../../../util/hooks/useWidth';

export type OwnProps = {
  open: boolean;
  defaultFilter?: Partial<CustomerFilter>;

  onCanceled(): void;
  onSelected(customerId: number): void;
};

export type StateProps = {
  salon: Salon;
  hasGroupSalon: boolean;
  numCustomersPerPage: number;
  stylists: PlainStylist[];
  customerListData: CustomerListData;
  customers: Customer[];
};

export type DispatchProps = {
  initialize(defaultData?: CustomerFilter): void;
  fetchCustomers(
    page: number,
    filter: CustomerFilter,
    sortKey: CustomerSortKey,
    sortOrder: CustomerSortOrder
  ): AbortController;
  setUseGroupData(useGroupData: boolean): void;
  setPage(page: number): void;
  setFilter(filter: CustomerFilter, useGroupData: boolean): void;
  setSort(sortKey: CustomerSortKey, sortOrder: CustomerSortOrder): void;
};

type Props = OwnProps & StateProps & DispatchProps;

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    maxHeight: '90vh',
    minHeight: '90vh',
  },
  dialogContent: {
    paddingTop: theme.spacing(4),
  },
}));

const SelectCustomerDialog: FC<Props> = (props) => {
  const classes = useStyles();

  const {
    open,
    initialize,
    salon,
    hasGroupSalon,
    customerListData,
    fetchCustomers,
    setFilter,
    setPage,
    setSort,
    defaultFilter,
  } = props;

  useEffect(() => {
    if (open) {
      initialize(defaultFilter);
    }
  }, [open, initialize, defaultFilter]);

  useDeepCompareEffect(() => {
    if (!open) {
      return;
    }

    const controller = fetchCustomers(
      customerListData.page,
      customerListData.filter,
      customerListData.sortKey,
      customerListData.sortOrder
    );

    return function cleanup() {
      if (controller) {
        controller.abort();
      }
    };
  }, [
    fetchCustomers,
    customerListData.page,
    customerListData.filter,
    customerListData.sortKey,
    customerListData.sortOrder,
    open,
  ]);

  const fullScreen = useWidthDown('md');

  return (
    <Dialog
      classes={fullScreen ? {} : { paper: classes.dialog }}
      maxWidth="lg"
      fullWidth={true}
      fullScreen={fullScreen}
      open={open}
      onClose={props.onCanceled}
    >
      <DialogTitle>お客様選択</DialogTitle>

      <DialogContent dividers className={classes.dialogContent}>
        <CCustomerFilter
          salonId={salon.id}
          hasGroupSalon={hasGroupSalon}
          filter={props.customerListData.filter}
          useGroupData={props.customerListData.useGroupData}
          stylists={props.stylists}
          onChangeFilter={setFilter}
        />
        <CCustomerTable
          useGroupData={props.customerListData.useGroupData}
          customers={props.customers}
          customerListData={props.customerListData}
          rowsPerPage={props.numCustomersPerPage}
          onSortOrderChange={setSort}
          onPageChange={setPage}
          onClick={props.onSelected}
        />
      </DialogContent>

      {fullScreen && (
        <DialogActions>
          <CButton variant="outlined" onClick={props.onCanceled}>
            キャンセル
          </CButton>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default SelectCustomerDialog;
