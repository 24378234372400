import { sortAscBy } from '@karutekun/shared/util/objects';
import { IVisitImage, VisitImageType } from '../data';

/**
 * images を受け取って before/after/other に分割して返す
 * displayNumber の昇順にソートして返す
 */
export function groupImagesByType<T extends Omit<IVisitImage, 'visitId'>>(
  images: T[]
): { [key in VisitImageType]: T[] } {
  return {
    [VisitImageType.Before]: sortAscBy(
      images.filter((image) => image.type === VisitImageType.Before),
      'displayNumber'
    ),
    [VisitImageType.After]: sortAscBy(
      images.filter((image) => image.type === VisitImageType.After),
      'displayNumber'
    ),
    [VisitImageType.Other]: sortAscBy(
      images.filter((image) => image.type === VisitImageType.Other),
      'displayNumber'
    ),
  };
}
