import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { backendOptions } from '../libs/react-dnd';

const isTouchDevice = 'ontouchstart' in document.documentElement;

const DndProviderTemplate: FC = (props) => {
  const { children } = props;
  return (
    <DndProvider
      backend={isTouchDevice ? TouchBackend : HTML5Backend}
      options={backendOptions}
    >
      {children}
    </DndProvider>
  );
};

export default DndProviderTemplate;
