/**
 * loyaltySegmentの定義は以下の通り(1ヶ月=60*60*24*30とする)
 *
 * None - カルテがまだ作成されていない人
 * New - 来店回数が1回で最終来店が指定期日より3ヶ月以内
 * Repeat - 来店回数が2回以上で、最終来店が指定期日より来店周期+1ヶ月以内
 * NewLost - 来店回数が1回で最終来店が指定期日より3ヶ月以降
 * RepeatLost- 来店回数が2回以上で、最終来店が来店周期+1ヶ月以降
 */
export enum CustomerLoyaltySegment {
  None = 0,
  New = 1,
  Repeat = 2,
  NewLost = 3,
  RepeatLost = 4,
}

export const CustomerLoyaltySegments = [
  CustomerLoyaltySegment.None,
  CustomerLoyaltySegment.New,
  CustomerLoyaltySegment.Repeat,
  CustomerLoyaltySegment.NewLost,
  CustomerLoyaltySegment.RepeatLost,
];

export const LostCustomerLoyaltySegments = [
  CustomerLoyaltySegment.NewLost as const,
  CustomerLoyaltySegment.RepeatLost as const,
];
export type LostCustomerLoyaltySegment =
  typeof LostCustomerLoyaltySegments[number];

// view にうつす
export const CustomerLoyaltySegmentText = {
  [CustomerLoyaltySegment.None]: '-',
  [CustomerLoyaltySegment.New]: '新規',
  [CustomerLoyaltySegment.Repeat]: '固定',
  [CustomerLoyaltySegment.NewLost]: '新規失客',
  [CustomerLoyaltySegment.RepeatLost]: '固定失客',
};
export const CustomerLoyaltySegmentTextShort = {
  [CustomerLoyaltySegment.None]: '-',
  [CustomerLoyaltySegment.New]: '新規',
  [CustomerLoyaltySegment.Repeat]: '固定',
  [CustomerLoyaltySegment.NewLost]: '新失',
  [CustomerLoyaltySegment.RepeatLost]: '固失',
};
export const CustomerLoyaltySegmentDescription = {
  [CustomerLoyaltySegment.None]: 'まだ来店されていないお客様です',
  [CustomerLoyaltySegment.New]:
    '来店回数が1回で、まだ90日以上経過していないお客様です',
  [CustomerLoyaltySegment.Repeat]:
    '来店回数が2回以上で、来店周期通りに継続して来店されているお客様です',
  [CustomerLoyaltySegment.NewLost]:
    '来店回数が1回で、最終来店日から90日を過ぎてしまったお客様です',
  [CustomerLoyaltySegment.RepeatLost]:
    '来店回数が2回以上で、最終来店から期間があいてしまっているお客様です',
};
