// eslint-disable-next-line no-restricted-imports
import dayjsNative from 'dayjs';
import ja from 'dayjs/locale/ja';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isoWeek from 'dayjs/plugin/isoWeek';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
// eslint-disable-next-line no-restricted-imports
import momentNative from 'moment';
// eslint-disable-next-line no-restricted-imports
import momentTimezone from 'moment-timezone';
import 'moment/locale/ja';

dayjsNative.locale({ ...ja, weekStart: 1 });
dayjsNative.extend(customParseFormat);
dayjsNative.extend(isoWeek);
dayjsNative.extend(isSameOrBefore);
dayjsNative.extend(utc);
dayjsNative.extend(timezone);

/**
 * dayjs のタイムゾーン処理が改善されたら dayjs を使うように修正する予定
 */
// 月曜日を週の始まりに設定
momentNative.updateLocale('ja', { week: { dow: 1, doy: 7 } });

// Next.js でプロセス内でシングルトンを管理するために globalThis を利用する必要があった
// globalThis を介さないと、例えば setDefaultTimezone で設定されたタイムゾーンが反映されない問題が発生する
const _globalThis = globalThis as {
  _DateTimeUtilMoment?: typeof momentNative;
  _DateTimeUtilDayjs?: typeof dayjsNative;
};
if (!_globalThis._DateTimeUtilMoment) {
  _globalThis._DateTimeUtilMoment = momentNative;
}
if (!_globalThis._DateTimeUtilDayjs) {
  _globalThis._DateTimeUtilDayjs = dayjsNative;
}
/**
 * @deprecated momentは使わず、すでに実装されているユーティリティ、もしくは dayjs を利用すること。
 */
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const moment = _globalThis._DateTimeUtilMoment!;
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export let dayjs = _globalThis._DateTimeUtilDayjs!;

// eslint-disable-next-line no-restricted-imports
export type { Dayjs } from 'dayjs';
// eslint-disable-next-line no-restricted-imports
export type { Moment } from 'moment';

/**
 * 初期化時にデフォルトのタイムゾーンを設定できるようにしている
 * 基本的にフロントエンドでは端末のタイムゾーンを使うので設定しない
 * バックエンドではサーバーのタイムゾーン設定に依存しないようにコード内で設定する
 */
export function setDefaultTimezone(defaultTimezone: string) {
  // ネイティブの dayjs のデフォルトタイムゾーンをグローバルに設定する方法が現状無いため、
  // ここでは、タイムゾーンを指定設定した dayjs もどきを作成して外部に公開している
  // https://github.com/iamkun/dayjs/issues/1227
  dayjsNative.tz.setDefault(defaultTimezone);
  const timezonedDayjs = (date?: dayjsNative.ConfigType, format?: string) =>
    format !== undefined
      ? dayjsNative.tz(date, format, defaultTimezone)
      : dayjsNative.tz(date, defaultTimezone);
  timezonedDayjs.unix = (v: number) => dayjsNative.unix(v).tz(defaultTimezone);

  /**
   * dayjs のタイムゾーン処理のパフォーマンスが悪く、多用する箇所では明らかに遅くなるため、
   * 一時的に moment で代用している。
   * 関連リンク
   * https://github.com/iamkun/dayjs/issues/1236
   * https://github.com/iamkun/dayjs/pull/2019
   */
  momentTimezone.tz.setDefault('Asia/Tokyo');
  momentTimezone.locale('ja');

  _globalThis._DateTimeUtilDayjs = timezonedDayjs as typeof dayjsNative;
  dayjs = _globalThis._DateTimeUtilDayjs;
}
