import { Route, Routes } from 'react-router';
import CheckPlan from './plan';
import CancelSubscription from './plan/subscription/cancel';
import CreateSubscription from './plan/subscription/new';
import UpdateSubscription from './plan/subscription/update';

export const Admin: FC = () => {
  return (
    <Routes>
      <Route path="/plan" element={<CheckPlan />} />
      <Route path="/plan/subscription/new" element={<CreateSubscription />} />
      <Route
        path="/plan/subscription/update"
        element={<UpdateSubscription />}
      />
      <Route
        path="/plan/subscription/cancel"
        element={<CancelSubscription />}
      />
    </Routes>
  );
};
