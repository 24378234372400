import { ServiceType } from '@karutekun/core/salon-service';
import { trpc } from '@karutekun/shared/data-access/api-base';
import {
  endOfMonthDate,
  startOfMonthDate,
} from '@karutekun/shared/util/datetime';
import { Box, Card, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { keepPreviousData } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { StickyContainer } from 'react-sticky';
import CDivider from '../../components_old/atoms/CDivider';
import CInformation from '../../components_old/atoms/CInformation';
import CAnalyticsDisplayTaxIncludedPriceSwitcher from '../../components_old/molecules/CAnalyticsDisplayTaxIncludedPriceSwitcher';
import { CChartBarSexAgeGroup } from '../../components_old/molecules/CChartBarSexAgeGroup';
import CStickyDateRangeControl from '../../components_old/molecules/CStickyDateRangeControl';
import CTrialWillEndBanner from '../../components_old/molecules/CTrialWillEndBanner';
import CChartGroupedServiceSwitchable from '../../components_old/organisms/charts/CChartGroupedServiceSwitchable';
import CChartWrapper from '../../components_old/organisms/charts/CChartWrapper';
import { CSalonCustomerStatsCard } from '../../components_old/organisms/salon/CSalonCustomerStatsCard';
import CSalonSalesStatsCard from '../../components_old/organisms/salon/CSalonSalesStatsCard';
import { selectAnalyticsDisplayTaxIncludedPrice } from '../../selectors/settingsSelector';
import { InformationText } from '../../texts/infomation';
import { useSyncedQueryParams } from '../../util/hooks/router/useSyncedQueryParams';
import { SalonDailyStatsSection } from './_components/SalonDailyStatsSection';
import { SalonDayOfWeekStatsSection } from './_components/SalonDayOfWeekStatsSection';
import { SalonMonthlyStatsCard } from './_components/SalonMonthlyStatsCard';
import { SalonStatsDownloadSection } from './_components/SalonStatsDownloadSection';
import { StylistStatsTable } from './_components/StylistStatsTable';

const useStyles = makeStyles(() => ({
  root: {
    padding: '76px 20px 20px 20px',
  },
}));

export const Salon: FC = () => {
  const classes = useStyles();

  const [{ from, to }, setParams] = useSyncedQueryParams<{
    from: string;
    to: string;
  }>({ from: startOfMonthDate(), to: endOfMonthDate() }, true);

  const { isRefetching, data: aggregatedStats } =
    trpc.analytics.salonStats.aggregatedStats.useQuery(
      { from, to },
      { placeholderData: keepPreviousData }
    );

  const displayTaxIncludedPrice = useSelector(
    selectAnalyticsDisplayTaxIncludedPrice
  );

  return (
    <div className={classes.root}>
      <CTrialWillEndBanner />
      <Grid container justifyContent="flex-end" alignItems="center">
        <CAnalyticsDisplayTaxIncludedPriceSwitcher />
        <CInformation
          type="dialog"
          content={InformationText.salon.overall}
          size="lg"
          mb={8}
        />
      </Grid>

      <Grid
        container
        justifyContent="space-evenly"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12}>
          <SalonMonthlyStatsCard onChangeDate={setParams} />
        </Grid>
      </Grid>

      <CDivider />

      <Box py={1}>
        <SalonStatsDownloadSection from={from} to={to} />
      </Box>

      <StickyContainer>
        <Grid
          container
          justifyContent="space-evenly"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item container xs={12}>
            <Grid item xs={12}>
              <CStickyDateRangeControl
                label="期間"
                from={from}
                to={to}
                onChange={(from, to) => {
                  setParams({ from, to });
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <CSalonSalesStatsCard
              title={
                displayTaxIncludedPrice ? '期間内 税込売上' : '期間内 税抜売上'
              }
              stats={aggregatedStats}
              isFetching={isRefetching}
              informationText={InformationText.salon.salesCard}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CSalonCustomerStatsCard
              title="期間内 来店回数"
              stats={aggregatedStats}
              isFetching={isRefetching}
              informationText={InformationText.salon.customerCard}
            />
          </Grid>

          <Grid item xs={12}>
            <SalonDailyStatsSection from={from} to={to} />
          </Grid>

          <Grid item xs={12} md={6}>
            <Card>
              <CChartWrapper
                title="メニュー分析"
                isFetching={isRefetching}
                informationText={InformationText.salon.menuAnalytics}
                height={300}
              >
                <CChartGroupedServiceSwitchable
                  chartType="bar"
                  serviceType={ServiceType.Menu}
                  serviceNumMap={aggregatedStats?.serviceNumMap ?? {}}
                  serviceOriginalPriceSumMap={
                    (displayTaxIncludedPrice
                      ? aggregatedStats?.serviceOriginalUnitPriceSumMap
                      : aggregatedStats?.serviceOriginalUnitPriceSumWithoutTaxMap) ??
                    {}
                  }
                  serviceSalesSumMap={
                    (displayTaxIncludedPrice
                      ? aggregatedStats?.serviceSalesMap
                      : aggregatedStats?.serviceSalesWithoutTaxMap) ?? {}
                  }
                />
              </CChartWrapper>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card>
              <CChartWrapper
                title="店販分析"
                isFetching={isRefetching}
                informationText={InformationText.salon.productAnalytics}
                height={300}
              >
                <CChartGroupedServiceSwitchable
                  chartType="bar"
                  serviceType={ServiceType.Product}
                  serviceNumMap={aggregatedStats?.serviceNumMap ?? {}}
                  serviceOriginalPriceSumMap={
                    (displayTaxIncludedPrice
                      ? aggregatedStats?.serviceOriginalUnitPriceSumMap
                      : aggregatedStats?.serviceOriginalUnitPriceSumWithoutTaxMap) ??
                    {}
                  }
                  serviceSalesSumMap={
                    (displayTaxIncludedPrice
                      ? aggregatedStats?.serviceSalesMap
                      : aggregatedStats?.serviceSalesWithoutTaxMap) ?? {}
                  }
                />
              </CChartWrapper>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CChartWrapper
                title="男女年齢分解"
                isFetching={isRefetching}
                informationText={InformationText.salon.sexAge}
              >
                <CChartBarSexAgeGroup
                  sexAgeGroupMap={aggregatedStats?.customerSexAgeGroupMap}
                />
              </CChartWrapper>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <SalonDayOfWeekStatsSection from={from} to={to} />
          </Grid>

          <Grid item xs={12}>
            <StylistStatsTable from={from} to={to} />
          </Grid>
        </Grid>
      </StickyContainer>
    </div>
  );
};
