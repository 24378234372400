import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import { ListItemIcon } from '@mui/material';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import CAutoComplete from '../../components_old/molecules/CAutoComplete';
import CAutoCompleteCustomerItem from '../../components_old/organisms/CAutoCompleteCustomerItem';
import { Customer } from '../../models/customer';
import {
  WithSelectCustomerDialog,
  withSelectCustomerDialog,
} from '../../templates/hoc/SelectCustomerDialogHOC';

export type OwnProps = {
  label?: string;
  placeholder?: string;
  name: string;
  onChange(name: string): void;
  onSelected(customerId: number): void;
  onCreateNewSelected(): void;
};
export type StateProps = {
  isFetching: boolean;
  hasMore: boolean;
  customers: Customer[];
};
export type DispatchProps = {
  fetchCustomers(name: string): AbortController;
  clearCustomers(): void;
};

type Props = OwnProps & StateProps & DispatchProps & WithSelectCustomerDialog;

const AutoCompleteCustomerInput: FC<Props> = (props) => {
  const {
    label,
    placeholder,
    name,
    onChange,
    onSelected,
    onCreateNewSelected,
    isFetching,
    hasMore,
    customers,
    fetchCustomers,
    clearCustomers,
  } = props;

  const requestController = useRef<AbortController | null>(null);

  // Autocompleteのテキストの状態をローカルで保持しないと、
  // 日本語入力時に変換前に確定されてしまって変換ができない
  // (character composition というキーワードらしい)
  const [autoCompleteText, setAutoCompleteText] = useState(name);
  useEffect(() => setAutoCompleteText(name), [name]);

  const onNameChange = useCallback(
    (name: string) => {
      setAutoCompleteText(name);
      onChange(name);
    },
    [onChange]
  );

  const fetchCustomersWithAbort = useMemo(
    () =>
      _.debounce((name: string) => {
        if (requestController.current) {
          requestController.current.abort();
        }
        requestController.current = fetchCustomers(name);
      }, 300),
    [fetchCustomers]
  );

  function handleLoadMore() {
    props.openSelectCustomerDialog({
      defaultFilter: {
        name,
      },
      onSelected(customerId: number) {
        props.onSelected(customerId);
        props.closeSelectCustomerDialog();
      },
    });
  }

  return (
    <CAutoComplete
      label={label}
      placeholder={placeholder}
      value={autoCompleteText}
      suggestions={customers.map((c) => ({
        id: c.id,
        label: c.name,
        content: <CAutoCompleteCustomerItem customer={c} />,
      }))}
      isFetching={isFetching}
      hasMore={hasMore}
      createNewOptionContent="カルテを新規作成"
      optionContent={
        <>
          <ListItemIcon>
            <SVGIcon name="search" />
          </ListItemIcon>
          名前以外で検索
        </>
      }
      onChange={onNameChange}
      onSelected={onSelected}
      onSuggestionFetchRequested={fetchCustomersWithAbort}
      onSuggestionClearRequested={clearCustomers}
      onLoadMoreSelected={handleLoadMore}
      onCreateNewSelected={onCreateNewSelected}
      onOptionContentSelected={handleLoadMore}
    />
  );
};

export default withSelectCustomerDialog(AutoCompleteCustomerInput);
