import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M24 44.75C12.544 44.75 3.25 35.456 3.25 24S12.544 3.25 24 3.25 44.75 12.544 44.75 24 35.456 44.75 24 44.75M6.75 24c0 9.523 7.727 17.25 17.25 17.25S41.25 33.523 41.25 24 33.523 6.75 24 6.75 6.75 14.477 6.75 24m19 7.5a1.75 1.75 0 1 1-3.5 0v-5.75H16.5a1.75 1.75 0 1 1 0-3.5h5.75V16.5a1.75 1.75 0 1 1 3.5 0v5.75h5.75a1.75 1.75 0 1 1 0 3.5h-5.75z"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgPlusCircle = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgPlusCircle);
export default Memo;
