import { dayjs } from './dayjs';

/**
 * minuteInterval分間隔に切り上げた時間をかえす
 */
export function adjustUnixToMinuteInterval(
  unixtime: number,
  minuteInterval: number
) {
  const minute =
    Math.ceil(dayjs.unix(unixtime).minute() / minuteInterval) * minuteInterval;
  return dayjs.unix(unixtime).minute(minute).unix();
}
