import React from 'react';
import { OwnProps as CreateCustomerDialogProps } from '../../templates/hoc/components/CreateCustomerDialog';
import CreateCustomerDialog from '../../templates/hoc/containers/CreateCustomerDialog';

type ArgumentProps = {
  defaultData?: CreateCustomerDialogProps['defaultData'];
  showContinuousCreate?: CreateCustomerDialogProps['showContinuousCreate'];
  onCanceled?: CreateCustomerDialogProps['onCanceled'];
  onCreateAndView: CreateCustomerDialogProps['onCreateAndView'];
};
export type WithCreateCustomerDialog = {
  openCreateCustomerDialog(props: ArgumentProps): void;
  closeCreateCustomerDialog(): void;
};

type State = CreateCustomerDialogProps;

export const withCreateCustomerDialog = <OriginalProps extends {}>(
  WrappedComponent: React.ComponentType<
    OriginalProps & WithCreateCustomerDialog
  >
) => {
  return class CreateCustomerDialogHOC extends React.Component<
    OriginalProps,
    State
  > {
    constructor(props: OriginalProps) {
      super(props);

      this.state = {
        open: false,
        onCanceled: this.closeCreateCustomerDialog,
        onCreateAndView: this.closeCreateCustomerDialog,
      };
    }

    openCreateCustomerDialog = (props: ArgumentProps) => {
      this.setState({ ...this.state, ...props, open: true });
    };

    closeCreateCustomerDialog = () => {
      this.setState({ ...this.state, open: false });
    };

    render() {
      return (
        <div>
          <CreateCustomerDialog {...this.state} />
          <WrappedComponent
            openCreateCustomerDialog={this.openCreateCustomerDialog}
            closeCreateCustomerDialog={this.closeCreateCustomerDialog}
            {...this.props}
          />
        </div>
      );
    }
  };
};
