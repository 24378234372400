import { mapBy } from '@karutekun/shared/util/objects';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from '../actions/broadcastMessageAction';
import { PlainBroadcastMessage } from '../models/broadcastMessage';

export type BroadcastMessageActions = ActionType<typeof actions>;

export interface BroadcastMessageState {
  plainBroadcastMessageMap: KeyMap<PlainBroadcastMessage>;
}

const initialState: BroadcastMessageState = {
  plainBroadcastMessageMap: {},
};

function broadcastMessageReducer(
  state: BroadcastMessageState = initialState,
  action: BroadcastMessageActions
): BroadcastMessageState {
  switch (action.type) {
    case getType(actions.setBroadcastMessage): {
      const { plainBroadcastMessage } = action.payload;
      return {
        ...state,
        plainBroadcastMessageMap: {
          ...state.plainBroadcastMessageMap,
          [plainBroadcastMessage.id]: plainBroadcastMessage,
        },
      };
    }
    case getType(actions.setBroadcastMessages): {
      const { plainBroadcastMessages } = action.payload;
      return {
        ...state,
        plainBroadcastMessageMap: {
          ...state.plainBroadcastMessageMap,
          ...mapBy(plainBroadcastMessages, 'id'),
        },
      };
    }
    case getType(actions.unsetBroadcastMessage): {
      const { id } = action.payload;

      const newMap = { ...state.plainBroadcastMessageMap };
      delete newMap[id];

      return {
        ...state,
        plainBroadcastMessageMap: newMap,
      };
    }
    default:
      return state;
  }
}

export default broadcastMessageReducer;
