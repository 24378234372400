import { trpc } from '@karutekun/shared/data-access/api-base';
import { formatMoneyShrink } from '@karutekun/shared/util/format';
import { Card, Grid } from '@mui/material';
import { keepPreviousData } from '@tanstack/react-query';
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import CChartBar from '../../../../components_old/atoms/CChartBar';
import CChartComposedCustomerNumAverageBudget from '../../../../components_old/molecules/CChartComposedCustomerNumAverageBudget';
import CChartWrapper from '../../../../components_old/organisms/charts/CChartWrapper';
import { selectAnalyticsDisplayTaxIncludedPrice } from '../../../../selectors/settingsSelector';
import { InformationText } from '../../../../texts/infomation';
import { fmtDate } from '../../../../util/chart';

type Props = {
  stylistId: number;
  from: string;
  to: string;
};

const salesChartKeys = [
  {
    dataKey: 'mainSales',
    name: '主担当売上',
    color: '#20a8d8',
  },
  {
    dataKey: 'productSales',
    name: '店販売上',
    color: '#63c2de',
  },
];
const salesChartKeysWithoutTax = [
  {
    dataKey: 'mainSalesWithoutTax',
    name: '主担当売上',
    color: '#20a8d8',
  },
  {
    dataKey: 'productSalesWithoutTax',
    name: '店販売上',
    color: '#63c2de',
  },
];
export const getSalesChartKeys = (isTaxIncluded: boolean) =>
  isTaxIncluded ? salesChartKeys : salesChartKeysWithoutTax;

export const StylistDailyStatsSection: FC<Props> = memo(
  ({ stylistId, from, to }) => {
    const { isRefetching, data: dailyStats = [] } =
      trpc.analytics.stylistStats.dailyStats.useQuery(
        {
          stylistId,
          range: { from, to },
        },
        { placeholderData: keepPreviousData }
      );

    const displayTaxIncludedPrice = useSelector(
      selectAnalyticsDisplayTaxIncludedPrice
    );

    const salesChartData = useMemo(() => {
      return dailyStats.map((s) => ({
        date: fmtDate(s.date),
        mainSales: s.mainSales,
        productSales: s.productShimeiSales + s.productNotShimeiSales,
        mainSalesWithoutTax: s.mainSalesWithoutTax,
        productSalesWithoutTax:
          s.productShimeiSalesWithoutTax + s.productNotShimeiSalesWithoutTax,
      }));
    }, [dailyStats]);

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CChartWrapper
              title="売上推移"
              isFetching={isRefetching}
              informationText={InformationText.stylists.dailySales}
            >
              <CChartBar
                data={salesChartData}
                nameKey="date"
                formatter={formatMoneyShrink}
                valueKeys={getSalesChartKeys(displayTaxIncludedPrice)}
                stack
              />
            </CChartWrapper>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CChartWrapper
              title="顧客数 推移(指名有り/無し)"
              isFetching={isRefetching}
              informationText={InformationText.stylists.dailyShimei}
            >
              <CChartComposedCustomerNumAverageBudget
                data={dailyStats.map((s) => ({
                  label: fmtDate(s.date),
                  firstCustomerNum: s.isShimeiMap[1],
                  secondCustomerNum: s.isShimeiMap[0],
                  averageBudget:
                    s.averageSalesPerCustomer === 0
                      ? null
                      : displayTaxIncludedPrice
                        ? s.averageSalesPerCustomer
                        : s.averageSalesPerCustomerWithoutTax,
                }))}
                firstCustomerName="指名有り"
                secondCustomerName="指名無し"
              />
            </CChartWrapper>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CChartWrapper
              title="顧客数 推移(新規/固定)"
              isFetching={isRefetching}
              informationText={InformationText.stylists.dailyNewRepeat}
            >
              <CChartComposedCustomerNumAverageBudget
                data={dailyStats.map((s) => ({
                  label: fmtDate(s.date),
                  firstCustomerNum: s.firstVisitNum,
                  secondCustomerNum: s.repeatVisitNum,
                  averageBudget:
                    s.averageSalesPerCustomer === 0
                      ? null
                      : displayTaxIncludedPrice
                        ? s.averageSalesPerCustomer
                        : s.averageSalesPerCustomerWithoutTax,
                }))}
              />
            </CChartWrapper>
          </Card>
        </Grid>
      </Grid>
    );
  }
);
