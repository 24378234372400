import {
  setIsMaintenance,
  setStylistCanAccess,
} from '@karutekun/shared/data-access/api-base';
import { saveAs } from 'file-saver';
import { clearState } from '../actions';
import { pushSnackbarError } from '../actions/generalAction';
import {
  AccessForbiddenError,
  ApplicationError,
  ForceSignOutError,
  MaintenanceError,
  ResourceConflictError,
} from '../actions/requestErrors';
import { firebaseAuth } from '../libs/firebase';
import Logger from '../logger';

/**
 * NOTE fetchの際に行いたい共通処理(ex. メンテナンスチェックなど)を行う
 * 意図したリクエストの結果でないものが返ってきた場合は、そのエラーに応じたactionのdispatchを行い、呼び出し元にはundefを返す
 */
export async function sendRequest(
  // TODO 一時的に lint を無効化しています。気づいたベースで直してください
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: any,
  path: string,
  {
    method = 'GET',
    params = undefined,
    body = undefined,
    forceRefreshToken = false,
    signal = undefined,
    download = undefined,

    // TODO
    // リクエストの失敗時に例外を投げるかどうか(falseでも409のときは例外が発生します)
    // 将来的には失敗時には例外を投げる形に統一したいが、全体を一気に修正することができないので、
    // 新しく作る部分についてはこのフラグを true で渡すようにして、徐々に移行していきたい
    throwError = true,

    // エラー調査で cache に no-cache をセットできるようにしておく
    noCache = undefined,
  }: {
    method?: string;
    params?: { [key: string]: string | number };
    body?: string;
    forceRefreshToken?: boolean;
    signal?: AbortSignal;
    download?: {
      filename: string;
    };
    throwError?: boolean;
    noCache?: boolean;
  } = {}
) {
  /**
   * 起動直後など、必要な場合はforceRefreshToken:trueを渡して強制リフレッシュを行う。
   * 強制リフレッシュを行わないと Firebase 上でアカウントを無効にしても、
   * ローカルにあるトークンで引き続き利用できてしまうので、管理者側の操作でユーザーの利用を100%止めることができない
   */
  // TODO 一時的に lint を無効化しています。気づいたベースで直してください
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const token = await firebaseAuth.currentUser!.getIdToken(forceRefreshToken);

  const url = generateUrl(path, params);

  const request: RequestInit = {
    signal,
    method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'X-KaruteKun-Api-Version': process.env.REACT_APP_API_VERSION || '1',
      'X-KaruteKun-Client-Platform': 'web',
    },
    ...(noCache ? { cache: 'no-cache' } : {}),
  };
  if (body) {
    request.body = body;
  }

  let response;
  let json;

  try {
    Logger.info(`[sendRequest] Request ${url}`);

    const retryCount = method === 'GET' ? 2 : 0;
    response = await fetchWithRetry(url, request, { retryCount });

    Logger.info(`[sendRequest] Response ${url}`, response);

    if (download) {
      // TODO
      // メンテナンスやアクセス制限時に弾くロジックが入っていないため、
      // そのような状態でリクエストを投げると、出力ファイルの中身にCANNOT_ACCESSなどの文字列が入ってしまう
      // アクセス制限時などにはそもそもダウンロードまでたどり着くことが難しいので現状はこれで妥協しちゃう。
      // 本来はレスポンスのステータスコードで事前に弾くことが望ましいので、そっちの実装を直して根本対応としたい。
      saveAs(await response.blob(), download.filename);
      return null;
    }

    json = await response.json();
  } catch (e) {
    if (e.name === 'AbortError') {
      // リクエストを明示的にabortしたときのエラーは握りつぶす
      Logger.info('[sendRequest] Aborted');
      return;
    }
    throw e;
  }

  if (response.status === 409) {
    throw new ResourceConflictError();
  }

  // メンテチェック
  if (json.data && json.data.IN_MAINTENANCE) {
    dispatch(setIsMaintenance(true));
    dispatch(
      pushSnackbarError(
        '現在サーバーメンテナンス中です。しばらくしてからアクセスをしてください。'
      )
    );

    if (throwError) {
      throw new MaintenanceError();
    }

    return null;
  }

  // アクセス制限チェック
  if (json.data && json.data.CANNOT_ACCESS) {
    dispatch(
      pushSnackbarError(
        'アクセスが制限されています。権限を持ったスタッフに制限解除をしてもらってください。'
      )
    );
    dispatch(setStylistCanAccess(false));

    if (throwError) {
      throw new AccessForbiddenError();
    }

    return null;
  }

  // 強制サインアウトチェック
  if (json.data && json.data.FORCE_SIGNOUT) {
    dispatch(clearState(true));
    await firebaseAuth.signOut();

    if (throwError) {
      throw new ForceSignOutError();
    }

    return null;
  }

  if (response.status !== 200 && throwError) {
    const firstError = json?.errors?.[0] || {
      messageForUser: '',
      code: '',
    };

    throw new ApplicationError(
      firstError.messageForUser,
      response.status,
      firstError.code
    );
  }

  return json;
}

async function fetchWithRetry(
  url: string,
  request: RequestInit,
  {
    retryCount = 2,
    retryDelay = 1000,
  }: { retryCount?: number; retryDelay?: number }
): // TODO 一時的に lint を無効化しています。気づいたベースで直してください
// eslint-disable-next-line @typescript-eslint/no-explicit-any
Promise<any> {
  try {
    return await fetch(url, request);
  } catch (e) {
    if (retryCount <= 0) {
      throw e;
    }

    // 明示的に Abort したときにはリトライしない
    if (e.name === 'AbortError') {
      throw e;
    }

    console.log(
      `fetch failed. remaining retry count: ${retryCount}. error: ${e}`
    );
    // sleep
    await new Promise<void>((resolve) => {
      setTimeout(() => resolve(), retryDelay);
    });

    // exponential backoffをいれる。ただし最大はMaxRetryDelay ms
    const MaxRetryDelay = 10000;
    return await fetchWithRetry(url, request, {
      retryCount: retryCount - 1,
      retryDelay: Math.min(retryDelay * 2, MaxRetryDelay),
    });
  }
}

function generateUrl(
  path: string,
  params: { [key: string]: string | number } | undefined = undefined
) {
  const url = `${process.env.REACT_APP_API_URL}${path}`;

  if (!params) {
    return url;
  }

  const keyPairs = [];
  for (const key in params) {
    keyPairs.push(`${key}=${encodeURIComponent(params[key].toString())}`);
  }

  return `${url}?${keyPairs.join('&')}`;
}
