import { createSelector } from 'reselect';
import { BroadcastMessage } from '../../models/broadcastMessage';
import { selectBroadcastMessageMap } from '../../selectors/broadcastMessageSelector';
import { GlobalState } from '../../store';
import { notEmpty } from '../../util/common';

export const selectBroadcastMessages = createSelector(
  (state: GlobalState) => state.view.broadcastMessage.broadcastMessageList,
  (state: GlobalState) => selectBroadcastMessageMap(state),
  (
    listData,
    broadcastMessageMap: KeyMap<BroadcastMessage>
  ): BroadcastMessage[] => {
    return listData.ids.map((id) => broadcastMessageMap[id]).filter(notEmpty);
  }
);
