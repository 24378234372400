import { RelKey, StrictRel } from '@karutekun/shared/util/entity';
import {
  ICustomerImage,
  ISalonCustomerLineSetting,
} from './salon-customer-line-setting';
import { CustomerLoyaltySegment } from './segments';

export enum CustomerSex {
  Male = 1,
  Female = 2,
}

interface _ICustomer {
  id: number;
  salonId: number;
  visitorId: number | null;
  name: string;
  nameKana: string;
  nameForSearch: string;
  nameKanaForSearch: string;
  sex: CustomerSex;
  allergy: string;
  memo: string;
  birthdayYear: number | null;
  birthdayMonth: number | null;
  birthdayDay: number | null;
  job: string;
  profileImageUrl: string | null;
  phone: string;
  email: string;
  postalCode: string;
  address: string;
  firstVisitedAt: number;
  lastVisitedAt: number;
  lastStylistId: number;
  totalVisitNum: number;
  totalSales: number;
  totalMenuSales: number;
  totalProductSales: number;
  averageBudget: number;
  averageVisitPeriod: number;
  isLastShimei: boolean;
  loyaltySegment: CustomerLoyaltySegment;
  mergerId: number | null;
  posCustomerKey: string | null;
  isDeleted: boolean;
  userUpdatedAt: number;
  createdAt: number;
  updatedAt: number;

  // TODO: relation を追加する
  lineSettings?: ISalonCustomerLineSetting[];
  images?: ICustomerImage[];
}

export type CustomerRelationKeys = RelKey<_ICustomer>;
export type ICustomer<K extends CustomerRelationKeys = never> = StrictRel<
  _ICustomer,
  K
>;

export interface ICustomerSalonCounselingAnswer {
  customerId: number;
  salonId: number;
  optionId: number;
  value: string | null;
  createdAt: number;
  updatedAt: number;
}

export interface ICustomerSalonVisitMotivation {
  customerId: number;
  salonVisitMotivationId: number;
  createdAt: number;
  updatedAt: number;
  isActive: boolean;
}

export interface ICustomerTicket {
  id: number;
  salonId: number;
  customerId: number;
  serviceId: number;
  num: number;
  totalNum: number;
  sales: number;
  expireAt: number;
  isExpired: boolean;
  createdAt: number;
  updatedAt: number;
}
