import { VisitHistoryResource } from '@karutekun/core/visit';
import { VoucherUtils } from '@karutekun/core/voucher';
import { RouterInput } from '@karutekun/shared/data-access/api-base';
import {
  useInvalidateVisits,
  useUpdateVisitHistory,
} from '@karutekun/shared/data-access/visit';
import { objectKeys, pick } from '@karutekun/shared/util/objects';
import { useBoolean } from '@karutekun/shared/util/react-hooks';
import { Box, Button, Grid, InputLabel, Typography } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  pushSnackbarError,
  pushSnackbarSuccess,
} from '../../../../../actions/generalAction';
import {
  setViewSchedulePreviewUpdates,
  setViewSchedulePreviewVisitId,
} from '../../../../../actions/view/viewScheduleAction';
import CButton from '../../../../../components_old/atoms/CButton';
import CDivider from '../../../../../components_old/atoms/CDivider';
import { VisitStatusTag } from '../../../../../features/visit/components/VisitStatusTag';
import { VisitFormAssignedStylist } from '../../../../../features/visit/components/form/VisitFormAssignedStylist';
import VisitFormCustomerSearchInput from '../../../../../features/visit/components/form/VisitFormCustomerSearchInput';
import { VisitFormDatetime } from '../../../../../features/visit/components/form/VisitFormDatetime';
import { VisitFormIsShimei } from '../../../../../features/visit/components/form/VisitFormIsShimei';
import { VisitFormSource } from '../../../../../features/visit/components/form/VisitFormSource';
import { VisitFormTreatmentMemo } from '../../../../../features/visit/components/form/VisitFormTreatmentMemo';
import { EditVoucherDialog } from '../../../../../features/voucher/components/EditVoucherDialog';
import { VoucherPaymentDialog } from '../../../../../features/voucher/components/VoucherPaymentDialog';
import { VoucherPreview } from '../../../../../features/voucher/components/VoucherPreview';
import { selectMySalon } from '../../../../../selectors/salonSelector';
import { useSalonStatus } from '../../../../../templates/providers/salonStatus/salonStatusContext';
import { useSimpleDialog } from '../../../../../templates/providers/simpleDialog/simpleDialogContext';
import { useThunkDispatch } from '../../../../../util/hooks/useThunkDispatch';

type FormData = RequiredDeep<
  Pick<RouterInput['visit']['updateVisitHistory'], 'visit'>
>;

type Props = {
  visitHistory: VisitHistoryResource;
};

export const SchedulesDrawerPreviewEditVisitHistory: FC<Props> = ({
  visitHistory,
}) => {
  const dispatch = useThunkDispatch();
  const { open } = useSimpleDialog();

  const invalidate = useInvalidateVisits();

  const salon = useSelector(selectMySalon);
  const { checkAndOpenDialog } = useSalonStatus();
  const [isVoucherDialogOpen, setIsVoucherDialogOpen] = useBoolean(false);
  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useBoolean(false);

  const {
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { isDirty, dirtyFields },
  } = useForm<FormData>({
    values: {
      visit: {
        ...pick(visitHistory, [
          'assignedStylistId',
          'startedAt',
          'finishedAt',
          'isShimei',
        ]),
        treatmentMemo:
          visitHistory.treatmentMemo || salon.customSetting.initialKaruteMemo,
      },
    },
  });

  useEffect(() => {
    if (isDirty) {
      dispatch(setViewSchedulePreviewUpdates({}, false, true));
    }
  }, [dispatch, isDirty]);

  const { mutate: updateVisitHistory, isPending } = useUpdateVisitHistory({
    async onSuccess() {
      await invalidate();

      dispatch(setViewSchedulePreviewVisitId(visitHistory.id));
      dispatch(setViewSchedulePreviewUpdates({}, true));

      dispatch(pushSnackbarSuccess('来店記録を更新しました'));
    },
    showConfirmation({ title, message }, onOk) {
      open({ title, content: message, onOk: async () => onOk() });
    },
    onUnhandledError(e) {
      dispatch(
        pushSnackbarError(e.data?.messageForUser ?? 'エラーが発生しました')
      );
    },
  });

  const handleSave = useCallback(async () => {
    if (!checkAndOpenDialog()) {
      return;
    }

    handleSubmit((data) => {
      updateVisitHistory({
        id: visitHistory.id,
        userUpdatedAt: visitHistory.userUpdatedAt,
        visit: pick(data.visit, objectKeys(dirtyFields.visit)),
      });
    })();
  }, [
    checkAndOpenDialog,
    dirtyFields.visit,
    handleSubmit,
    updateVisitHistory,
    visitHistory.id,
    visitHistory.userUpdatedAt,
  ]);

  const isVoucherPaid = VoucherUtils.isPaid(visitHistory.voucher);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <VisitFormCustomerSearchInput
            title={visitHistory.title}
            customerId={visitHistory.customerId}
            isUnassociatable={false}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel shrink>ステータス</InputLabel>
          <VisitStatusTag
            status={visitHistory.status}
            isPaid={VoucherUtils.isPaid(visitHistory.voucher)}
            size="small"
          />
        </Grid>
        <Grid item xs={12}>
          <VisitFormDatetime
            startedAt={watch('visit.startedAt')}
            finishedAt={watch('visit.finishedAt')}
            onChange={({ startedAt, finishedAt }) => {
              setValue('visit.startedAt', startedAt, { shouldDirty: true });
              setValue('visit.finishedAt', finishedAt, { shouldDirty: true });
            }}
            disabled={isVoucherPaid}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="visit.isShimei"
            render={({ field: { onChange, value } }) => (
              <VisitFormIsShimei
                isShimei={value}
                onChange={onChange}
                disabled={isVoucherPaid}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="visit.assignedStylistId"
            render={({ field: { onChange, value } }) => (
              <VisitFormAssignedStylist
                salonId={salon.id}
                assignedStylistId={value}
                onChange={onChange}
                disabled={isVoucherPaid}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="visit.treatmentMemo"
            render={({ field: { onChange, value } }) => (
              <VisitFormTreatmentMemo
                treatmentMemo={value}
                onChange={onChange}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel shrink>予約時メモ</InputLabel>
          <Box whiteSpace="pre-wrap">
            <Typography variant="body2">
              {visitHistory.reservationMemo || 'メモなし'}
            </Typography>
          </Box>
        </Grid>
        {!!visitHistory.noteFromCustomer && (
          <Grid item xs={12}>
            <InputLabel shrink>お客様からのご要望</InputLabel>
            <Box whiteSpace="pre-wrap">
              <Typography variant="body2">
                {visitHistory.noteFromCustomer}
              </Typography>
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <VisitFormSource
            salonId={salon.id}
            source={visitHistory.source}
            isConfirmedByStaff={visitHistory.isConfirmedByStaff}
            createdStylistId={visitHistory.createdStylistId}
            createdAt={visitHistory.createdAt}
            disabled
          />
        </Grid>
      </Grid>

      <CDivider spacing={2} />

      <VoucherPreview voucher={visitHistory.voucher} showPayment />
      <Box mt={2} display="flex" justifyContent="center">
        <EditVoucherDialog
          open={isVoucherDialogOpen}
          voucher={visitHistory.voucher}
          onClose={(showPayment) => {
            setIsVoucherDialogOpen.setFalse();
            if (showPayment) {
              setIsPaymentDialogOpen.setTrue();
            }
          }}
        />
        <VoucherPaymentDialog
          open={isPaymentDialogOpen}
          voucher={visitHistory.voucher}
          onClose={setIsPaymentDialogOpen.setFalse}
        />
        <Button
          variant="outlined"
          color="secondary"
          onClick={setIsVoucherDialogOpen.setTrue}
          disabled={VoucherUtils.isPaid(visitHistory.voucher)}
        >
          メニューの編集
        </Button>
      </Box>

      <CDivider spacing={2} />

      <Grid container justifyContent="flex-end">
        <CButton isLoading={isPending} disabled={!isDirty} onClick={handleSave}>
          保存
        </CButton>
      </Grid>
    </div>
  );
};
