import { notEmpty } from '../util/common';
import {
  BusinessHour,
  PlainWorkShift,
  WorkShift,
} from './salonScheduleSettings';

export type ScheduleCapacityMap = {
  [hour: number]:
    | {
        [minute: number]: number | undefined;
      }
    | undefined;
};
export type PlainStylistShift = PlainWorkShift & {
  note: string | null;
  capacity: number;
};
export type StylistShift = WorkShift & {
  note: string | null;
  capacity: number;
};

export type PlainDailyBusinessInfo = {
  date: string;
  businessHour: BusinessHour;
  scheduleCapacity: ScheduleCapacityMap;
  stylistShift: IdMap<PlainStylistShift>;
};
export type DailyBusinessInfo = {
  date: string;
  businessHour: BusinessHour;
  scheduleCapacity: ScheduleCapacityMap;
  stylistShift: IdMap<StylistShift>;
};
export type DailyBusinessInfoUpdate = {
  date: string;
  businessHour?: BusinessHour;
  scheduleCapacity?: number | ScheduleCapacityMap;
  stylistShift?: IdMap<PlainStylistShift>;
};

// TODO 一時的に lint を無効化しています。気づいたベースで直してください
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function businessInfoFromResource(res: any): PlainDailyBusinessInfo {
  return {
    date: res.date,
    businessHour: res.businessHour,
    scheduleCapacity: res.scheduleCapacity,
    stylistShift: res.stylistShift,
  };
}

/**
 * 受付可能数の設定が、すべての時間帯で同じなら、その受付可能数を返す
 * 時間帯によって異なるなら null を返す
 */
export function getConstScheduleCapacity(
  map: ScheduleCapacityMap
): number | null {
  let constCapacity: number | null = null;
  const checkFunc = (c: number) => c !== constCapacity;

  const minuteMaps = Object.values(map).filter(notEmpty);
  for (const minuteMap of minuteMaps) {
    const capacities = Object.values(minuteMap).filter(notEmpty);

    // 一番最初の要素の値をメモ
    if (constCapacity === null) {
      constCapacity = capacities[0];
    }

    // 一つでも異なる設定があったら null
    if (capacities.some(checkFunc)) {
      return null;
    }
  }

  return constCapacity;
}

/**
 * 指定の受付可能数で初期化された map を返す
 */
export function buildScheduleCapacityMap(
  constCapacity: number,
  minuteInterval: MinuteInterval
): ScheduleCapacityMap {
  return new Array(24).fill(0).reduce((prev, _, i) => {
    prev[i] = new Array(60 / minuteInterval).fill(0).reduce((p, _, j) => {
      p[j * minuteInterval] = constCapacity;
      return p;
    }, {});
    return prev;
  }, {});
}
