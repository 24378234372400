import { useSelector } from 'react-redux';
import { setChatSalonId } from '../../actions/view/viewChatAction';
import { selectChatSelectedSalonId } from '../../selectors/chatSelector';
import { selectAllSalons } from '../../selectors/salonSelector';
import { useThunkDispatch } from '../../util/hooks/useThunkDispatch';
import CSelect from '../atoms/CSelect';

const SelectableSalons: FC = () => {
  const dispatch = useThunkDispatch();
  const selectedSalonId = useSelector(selectChatSelectedSalonId);
  const selectableSalons = useSelector(selectAllSalons);

  return (
    <CSelect
      label="サロン"
      value={selectedSalonId}
      options={selectableSalons.map((salon) => ({
        value: salon.id,
        element: salon.name,
      }))}
      onChange={(salonId) => {
        if (salonId) {
          dispatch(setChatSalonId(salonId));
        }
      }}
    />
  );
};

export default SelectableSalons;
