/**
 * voucherLine の割引後の販売単価を計算する
 * 単価なのでメニューが外税の場合は消費税額を抜いた金額になる
 */
export function calculateVoucherLineApproxSellingUnitPrice(
  line: Readonly<{
    sales: number;
    taxAmount: number;
    quantity: number;
    isTaxIncluded: boolean;
  }>
): number {
  return Math.floor(
    (line.isTaxIncluded ? line.sales : line.sales - line.taxAmount) /
      line.quantity
  );
}
