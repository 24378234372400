import { deprecated } from 'typesafe-actions';
import { ScheduleSettings } from './../reducers/settingsReducer';

const { createAction } = deprecated;

export enum ActionNames {
  SetSettingsSchedule = 'settings/SetSettingsSchedule',
  SetSettingsAnalyticsDisplayTaxIncludedPrice = 'settings/SetSettingsAnalyticsDisplayTaxIncludedPrice',
}

export const setSettingsSchedule = createAction(
  ActionNames.SetSettingsSchedule,
  (action) => {
    return (salonId: number, settings: Partial<ScheduleSettings>) =>
      action({ salonId, settings });
  }
);

export const setSettingsAnalyticsDisplayTaxIncludedPrice = createAction(
  ActionNames.SetSettingsAnalyticsDisplayTaxIncludedPrice,
  (action) => {
    return (displayTaxIncludedPrice: boolean) =>
      action({ displayTaxIncludedPrice });
  }
);
