import * as React from 'react';
import { PlainStylist } from '../../models/stylist';
import CChartPie, { ChartData } from '../atoms/CChartPie';

type OwnProps = {
  salonStylistMap: IdMap<PlainStylist>;
  stylistMap: { [stylistId: number]: number };
};

class CChartPieStylist extends React.PureComponent<OwnProps> {
  render() {
    const { salonStylistMap, stylistMap } = this.props;

    const data: ChartData[] = [];
    for (const stylistId in stylistMap) {
      if (salonStylistMap[stylistId]) {
        data.push({
          id: Number(stylistId),
          name: salonStylistMap[stylistId].name,
          value: stylistMap[stylistId],
        });
      }
    }
    return (
      <CChartPie
        data={data}
        formatter={(v) => `${v}人`}
        sortDataByValue={true}
      />
    );
  }
}

export default CChartPieStylist;
