import { CircularProgress, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';

const useStyles = makeStyles((theme: Theme) => {
  return {
    overlay: {
      position: 'absolute',
      zIndex: theme.zIndex.appBar - 1,
      width: '100%',
      height: '100%',
      opacity: 0.5,
      backgroundColor: 'white',
      textAlign: 'center',
    },
  };
});

const CProgressOverlay: FC = React.memo(function CProgressOverlay() {
  const classes = useStyles();
  return (
    <div className={classes.overlay}>
      <CircularProgress style={{ margin: 30 }} />
    </div>
  );
});

export default CProgressOverlay;
