import { Tooltip } from '@mui/material';
import * as React from 'react';
import CAvatar from './CAvatar';

type OwnProps = {
  size?: number;
  style?: React.CSSProperties;
};

export const CDiscountIcon: FC<OwnProps> = (props) => {
  const { size = 32, style } = props;
  return (
    <Tooltip title="割引" style={style}>
      <CAvatar size={size} src="/static/images/icons/discount.png" />
    </Tooltip>
  );
};
