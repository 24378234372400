import {
  VisitHistoryFilter,
  VisitHistorySort,
  useFetchVisitHistoriesPaginated,
} from '@karutekun/shared/data-access/visit';
import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import { Card, CardContent, Grid, IconButton, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CEmpty from '../../../atoms/CEmpty';
import CProgressOverlay from '../../../atoms/CProgressOverlay';
import { CVisitCard } from './CVisitCard';
import { CVisitListFilterControls } from './CVisitListFilterControls';
import { CVisitListPaginationControl } from './CVisitListPaginationControl';
import { CVisitListSortControl } from './CVisitListSortControl';

type Props = {
  filter?: VisitHistoryFilter;
  sort?: VisitHistorySort;
  numVisitsPerPage?: number;
  page?: number;
  disabledFilters?: (keyof VisitHistoryFilter)[];

  highlightedVisitId?: number;

  onFilterChange?(filter: VisitHistoryFilter): void;
  onSortChange?(sort: VisitHistorySort): void;
  onPageChange?(page: number): void;
};

const useStyles = makeStyles(() => ({
  sortPaginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

const DefaultFilter: VisitHistoryFilter = {};

export const CVisitHistoryList: FC<Props> = ({
  filter = DefaultFilter,
  sort = 'startedAtDesc',
  numVisitsPerPage = 30,
  page = 0,
  disabledFilters,
  highlightedVisitId,
  onFilterChange,
  onSortChange,
  onPageChange,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const {
    isFetching,
    data: { count, visits },
    refetch,
  } = useFetchVisitHistoriesPaginated(filter, sort, numVisitsPerPage, page);

  return (
    <div>
      {isFetching && <CProgressOverlay />}

      {onFilterChange && (
        <CVisitListFilterControls
          filter={filter}
          disabledFilters={disabledFilters}
          onChange={onFilterChange}
        />
      )}
      {(onPageChange || onSortChange) && (
        <div className={classes.sortPaginationContainer}>
          {onSortChange && (
            <CVisitListSortControl sort={sort} onChange={onSortChange} />
          )}
          {onPageChange && (
            <CVisitListPaginationControl
              page={page}
              count={count}
              numPerPage={numVisitsPerPage}
              onChange={onPageChange}
            />
          )}
          <IconButton onClick={() => refetch()} size="large">
            <SVGIcon
              name="refresh"
              color={theme.palette.text.primary}
              size="sm"
            />
          </IconButton>
        </div>
      )}

      <Grid container alignItems="flex-start" spacing={2}>
        {!isFetching && visits.length === 0 ? (
          <Grid item>
            <Card>
              <CardContent>
                <CEmpty title="来店記録が存在しません" />
              </CardContent>
            </Card>
          </Grid>
        ) : (
          visits.map((visit) => (
            <Grid key={visit.id} item xs={12} sm={6} md={6} lg={4} xl={3}>
              <CVisitCard
                visit={visit}
                isHighlighted={highlightedVisitId === visit.id}
                linkTo={`/visit_histories/${visit.id}`}
              />
            </Grid>
          ))
        )}
      </Grid>

      {onPageChange && (
        <CVisitListPaginationControl
          page={page}
          count={count}
          numPerPage={numVisitsPerPage}
          onChange={onPageChange}
        />
      )}
    </div>
  );
};
