import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CTextBox from '../../../../../components_old/atoms/CTextBox';
import {
  BroadcastMessage,
  State,
} from '../../../../../models/broadcastMessage';
import { formatDate } from '../../../../../util/common';
import { BroadcastMessageStateChip } from './BroadcastMessageStateChip';

type Props = {
  broadcastMessage: BroadcastMessage;
};

const useStyles = makeStyles((_: Theme) => ({
  cellHead: {
    width: 150,
  },
}));

export const BroadcastMessageStatusTable: FC<Props> = (props) => {
  const classes = useStyles();

  const { broadcastMessage } = props;

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell className={classes.cellHead}>配信状況</TableCell>
          <TableCell align="left">
            <BroadcastMessageStateChip state={broadcastMessage.state} />
            {broadcastMessage.state === State.Reserved ? (
              <CTextBox mt={1} mb={1}>
                <Typography variant="body1">
                  {formatDate(
                    broadcastMessage.publishScheduledAt as number,
                    true
                  )}
                  に配信予定です
                </Typography>
              </CTextBox>
            ) : broadcastMessage.state === State.AudienceCreated ? (
              <CTextBox mt={1} mb={1}>
                <Typography variant="body1">
                  {broadcastMessage.recipientCustomerNum}
                  名のお客様に配信予定です
                </Typography>
              </CTextBox>
            ) : broadcastMessage.state === State.Published ? (
              <CTextBox mt={1} mb={1}>
                <Typography variant="body1">
                  {broadcastMessage.recipientCustomerNum}
                  名のお客様に配信しています
                </Typography>
              </CTextBox>
            ) : broadcastMessage.state === State.Error ? (
              <CTextBox mt={1} mb={1}>
                <Typography variant="body1">
                  配信に失敗しました。{'\n'}
                  お使いのLINE公式アカウントでメッセージ送信数の上限に達していないかご確認ください。
                </Typography>
              </CTextBox>
            ) : null}
          </TableCell>
        </TableRow>
        {broadcastMessage.publishedAt !== null && (
          <TableRow>
            <TableCell className={classes.cellHead}>配信日時</TableCell>
            <TableCell align="left">
              {formatDate(broadcastMessage.publishedAt, true)}
            </TableCell>
          </TableRow>
        )}
        {broadcastMessage.lineNarrowcastResult !== null && (
          <>
            <TableRow>
              <TableCell className={classes.cellHead} align="right">
                配信ターゲット数
              </TableCell>
              <TableCell align="left">
                {broadcastMessage.lineNarrowcastResult.targetCount} 名
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cellHead} align="right">
                送信成功
              </TableCell>
              <TableCell align="left">
                {broadcastMessage.lineNarrowcastResult.successCount} 名
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cellHead} align="right">
                送信失敗
              </TableCell>
              <TableCell align="left">
                {broadcastMessage.lineNarrowcastResult.failureCount} 名
              </TableCell>
            </TableRow>
            {broadcastMessage.lineNarrowcastResult.failedDescription && (
              <TableRow>
                <TableCell className={classes.cellHead}>エラー内容</TableCell>
                <TableCell align="left">
                  <Typography variant="body2" color="error">
                    {/* 現状 LINE 側のレスポンスで failedDescription に何が入ってくるかわからないので
                    とりあえず一般的な文言を出しておいている */}
                    {/* {broadcastMessage.lineNarrowcastResult.failedDescription} */}
                    LINEサーバー側でエラーが発生しました。
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </>
        )}
      </TableBody>
    </Table>
  );
};
