import { ActionType, getType } from 'typesafe-actions';
import * as actions from '../../actions/view/viewBroadcastMessageAction';
import { BroadcastMessageListData } from '../types';

export type ViewBroadcastMessageState = {
  // 一斉配信一覧ページ
  broadcastMessageList: BroadcastMessageListData;
};

const initialState: ViewBroadcastMessageState = {
  broadcastMessageList: {
    ids: [],
    count: 0,
    page: 0,
    isFetching: false,
  },
};

export type ViewBroadcastMessageActions = ActionType<typeof actions>;

function viewBroadcastMessageReducer(
  state: ViewBroadcastMessageState = initialState,
  action: ViewBroadcastMessageActions
): ViewBroadcastMessageState {
  switch (action.type) {
    case getType(actions.setBroadcastMessageList): {
      const { listData } = action.payload;
      return {
        ...state,
        broadcastMessageList: {
          ...state.broadcastMessageList,
          ...listData,
        },
      };
    }
    default:
      return state;
  }
}

export default viewBroadcastMessageReducer;
