import * as _ from 'lodash';
import { createSelector } from 'reselect';
import { MySalon } from '../models/salon';
import { buildSalonStatus } from '../models/salonStatus';
import { selectMySalon } from '../selectors/salonSelector';
import { GlobalState } from '../store';

const salonStatusInfo = createSelector(
  (state: GlobalState) => selectMySalon(state),
  (salon: MySalon) =>
    _.pick(salon, [
      'isPlanDisabled',
      'basicPlan',
      'optionLinePlan',
      'trialFinishAt',
      'lineSetting',
      'reservationSettings',
      'scheduleSettings',
    ])
);
export const selectSalonStatus = createSelector(salonStatusInfo, (info) =>
  buildSalonStatus(info)
);
