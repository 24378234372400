import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M20.73 4.25c-3.26 0-6.44.97-9.15 2.78a16.485 16.485 0 0 0-6.07 7.39 16.498 16.498 0 0 0-.94 9.52c.64 3.2 2.21 6.13 4.51 8.44 2.3 2.3 5.24 3.87 8.44 4.51 3.2.64 6.51.31 9.52-.94 1.46-.61 2.82-1.42 4.04-2.4l9.69 9.69c.68.68 1.79.68 2.47 0 .68-.68.68-1.79 0-2.47l-9.69-9.69c.31-.38.6-.78.88-1.19 1.81-2.71 2.78-5.9 2.78-9.15 0-4.37-1.74-8.56-4.83-11.65a16.479 16.479 0 0 0-11.65-4.83zm9.18 7.3a12.98 12.98 0 0 0-16.39-1.61c-2.13 1.43-3.8 3.45-4.78 5.82a12.962 12.962 0 0 0 2.81 14.14c1.81 1.81 4.13 3.05 6.64 3.55 2.52.5 5.13.24 7.5-.74s4.4-2.65 5.82-4.78a12.93 12.93 0 0 0 2.19-7.21c0-3.44-1.37-6.74-3.8-9.18z"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgSearch = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgSearch);
export default Memo;
