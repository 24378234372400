import {
  IService,
  IServiceCategory,
  IServiceDiscount,
} from '@karutekun/core/salon-service';
import { TaxRoundingType } from '@karutekun/core/tax';
import { VoucherUtils } from '@karutekun/core/voucher';
import { RouterInput } from '@karutekun/shared/data-access/api-base';
import { sortByOrder } from '@karutekun/shared/util/entity';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';

export type VoucherFormData =
  RouterInput['visit']['createReservation']['voucher'];
export type VoucherLineFormData = VoucherFormData['lines'][number];
export type VoucherDiscountFormData = VoucherFormData['discounts'][number];
export type VoucherFormDefaults = {
  stylistId: number | null;
  isShimei: boolean;
  data?: Partial<VoucherFormData>;
};

type Service = Pick<
  IService,
  'id' | 'type' | 'price' | 'isTaxIncluded' | 'taxRate'
>;
type Discount = Pick<IServiceDiscount, 'id' | 'type' | 'value'>;

type VoucherMutations = {
  set(data: VoucherFormData): void;
  reset(data: VoucherFormDefaults['data']): void;
  addLine(service: Service, defaultValues?: Partial<VoucherLineFormData>): void;
  editLine(lineIndex: number, update: Partial<VoucherLineFormData>): void;
  deleteLine(lineIndex: number): void;
  addDiscount(discount: Discount): void;
  deleteDiscount(discountIndex: number): void;
  addDiscountToLines(lineIndices: number[], discount: Discount): void;
};

function createDefaultData(
  defaults: Partial<VoucherFormData> = {},
  services: {
    serviceMap: IdMap<IService>;
    categoryMap: IdMap<IServiceCategory>;
  }
) {
  const { menuLines, productLines } =
    VoucherUtils.sortVoucherLinesByServiceOrder(defaults.lines ?? [], services);
  return {
    taxRoundingType: TaxRoundingType.Floor,
    discounts: [],
    ...defaults,
    lines: [...menuLines, ...productLines],
  };
}

export function useVoucherForm(
  defaults: VoucherFormDefaults,
  services: {
    serviceMap: IdMap<IService>;
    categoryMap: IdMap<IServiceCategory>;
  }
): [VoucherFormData, VoucherMutations, boolean] {
  const [data, setData] = useState<VoucherFormData>(
    createDefaultData(defaults.data, services)
  );

  const isEdited = useMemo(
    () => !_.isEqual(data, createDefaultData(defaults.data, services)),
    [data]
  );

  useEffect(() => {
    setData(createDefaultData(defaults.data, services));
  }, [defaults.data]);

  const mutations: VoucherMutations = useMemo(
    () => ({
      set(data: VoucherFormData) {
        setData(data);
      },
      reset(data?: VoucherFormDefaults['data']) {
        setData(createDefaultData(data, services));
      },
      addLine(service: Service, defaultValues?: Partial<VoucherLineFormData>) {
        setData((current) => ({
          ...current,
          lines: [
            ...current.lines,
            {
              serviceId: service.id,
              serviceType: service.type,
              originalUnitPrice: service.price,
              adjustedUnitPrice: service.price,
              isTaxIncluded: service.isTaxIncluded,
              taxRate: service.taxRate,
              quantity: 1,
              note: null,
              discounts: [],
              ...defaultValues,
              stylists:
                defaultValues?.stylists ??
                (defaults.stylistId !== null
                  ? [
                      {
                        stylistId: defaults.stylistId,
                        isShimei: defaults.isShimei,
                      },
                    ]
                  : []),
            },
          ],
        }));
      },
      editLine(lineIndex: number, update: Partial<VoucherLineFormData>) {
        setData((current) => ({
          ...current,
          lines: current.lines.map((line, index) => {
            if (index !== lineIndex) {
              return line;
            }
            return { ...line, ...update };
          }),
        }));
      },
      deleteLine(lineIndex: number) {
        setData((current) => ({
          ...current,
          lines: current.lines.filter((_, index) => index !== lineIndex),
        }));
      },
      addDiscount(discount: Discount) {
        setData((current) => ({
          ...current,
          discounts: addDiscountToTailAndRenumberOrder(
            current.discounts,
            discount
          ),
        }));
      },
      deleteDiscount(discountIndex: number) {
        setData((current) => ({
          ...current,
          discounts: current.discounts.filter(
            (_, index) => index !== discountIndex
          ),
        }));
      },
      addDiscountToLines(lineIndices: number[], discount: Discount) {
        setData((current) => ({
          ...current,
          lines: current.lines.map((line, index) => {
            // 割引を追加する対象外だったらそのまま返す
            if (!lineIndices.includes(index)) {
              return line;
            }

            return {
              ...line,
              discounts: addDiscountToTailAndRenumberOrder(
                line.discounts,
                discount
              ),
            };
          }),
        }));
      },
    }),
    [defaults.stylistId, defaults.isShimei]
  );

  return [data, mutations, isEdited];
}

/**
 * 末尾に割引を追加して、order 番号を振り直す
 */
function addDiscountToTailAndRenumberOrder(
  currentDiscounts: VoucherFormData['discounts'],
  discount: Discount
) {
  const discounts = [
    ...sortByOrder(currentDiscounts),
    {
      discountId: discount.id,
      type: discount.type,
      value: discount.value,
      order: 0,
    },
  ];
  for (let i = 0; i < discounts.length; i += 1) {
    discounts[i] = { ...discounts[i], order: discounts.length - i };
  }
  return discounts;
}
