import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M46.21 22.74 26.25 3.63c-.67-.68-1.57-.88-2.25-.88-.68 0-1.59.2-2.26.88L1.79 22.74c-.7.67-.72 1.78-.05 2.47.67.7 1.78.72 2.47.05l1.54-1.47V42c0 .86.34 1.69.95 2.3.61.61 1.44.95 2.3.95h9c.97 0 1.75-.78 1.75-1.75V30.75c0-.13.05-.26.15-.35a.51.51 0 0 1 .35-.15h7.5c.13 0 .26.05.35.15.09.09.15.22.15.35V43.5c0 .97.78 1.75 1.75 1.75h9c.86 0 1.69-.34 2.3-.95.61-.61.95-1.44.95-2.3V23.79l1.54 1.47c.7.67 1.81.64 2.47-.05.67-.7.64-1.81-.05-2.47"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgHomeFill = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgHomeFill);
export default Memo;
