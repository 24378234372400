import { Theme, Typography, TypographyProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';

type OwnProps = TypographyProps & {
  icon: React.ReactNode;
};

const useStyles = makeStyles((_: Theme) => ({
  typography: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    'display': 'flex',
    'flexDirection': 'row',
    'justifyContent': 'center',
    'alignItems': 'center',
    'color': 'inherit',
    '& > *:first-child': {
      fontSize: 'inherit',
      marginRight: '0.2em',
    },
  },
}));

const CTypographyIcon: FC<OwnProps> = React.memo(function CTypographyIcon(
  props
) {
  const classes = useStyles();
  const { icon, children, className, ...etc } = props;
  return (
    <Typography {...etc} className={clsx(className, classes.typography)}>
      <span className={classes.icon}>{icon}</span>
      {children}
    </Typography>
  );
});

export default CTypographyIcon;
