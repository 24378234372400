import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { clearState } from '../../actions';
import { dispatchWithErrorHandling } from '../../actions/helper/dispatchWithErrorHandling';
import { fetchOwnSalonStylists } from '../../actions/salonStylistAction';
import { switchLoginSalon } from '../../actions/userAction';
import { firebaseAuth } from '../../libs/firebase';
import { selectUserLoginInfo } from '../../selectors/userSelector';
import { GlobalState } from '../../store';
import NoAccess, {
  DispatchProps,
  StateProps,
} from '../../templates/auth/NoAccessComponent';

const mapStateToProps = (state: GlobalState): StateProps => ({
  loginInfo: selectUserLoginInfo(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  async fetchOwnSalonStylists() {
    let json = { salonStylists: [], loginSalonId: null };
    try {
      // TODO 一時的に lint を無効化しています。気づいたベースで直してください
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      json = await dispatch(fetchOwnSalonStylists() as any);
    } catch (e) {
      console.log(e);
      throw new Error('エラーが発生しました');
    }
    return {
      ownSalonStylists: json.salonStylists,
      loginSalonId: json.loginSalonId,
    };
  },
  async logout() {
    dispatch(clearState(true));
    await firebaseAuth.signOut();
  },
  async switchLoginSalon(salonId: number) {
    await dispatchWithErrorHandling(dispatch, switchLoginSalon(salonId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NoAccess);
