import { PermissionRole } from '@karutekun/core/permission-role';
import { ISalonCustomSettingWithDefaults } from '@karutekun/core/salon';
import { TaxRoundingType } from '@karutekun/core/tax';
import { mapBy, mapById } from '@karutekun/shared/util/objects';
import {
  ReservationSettings,
  reservationSettingsFromResource,
} from '../models/salonReservationSettings';
import { Subscription } from '../models/subscription';
import { CounselingSection } from './counseling';
import { MemoTemplate } from './memoTemplate';
import { PaymentMethod } from './paymentMethod';
import { emptySalonCustomSetting } from './salonCustomSetting';
import { SalonLineSetting, lineSettingFromResource } from './salonLineSetting';
import { SalonMonthlyChatStatistics } from './salonMonthlyChatStatistics';
import {
  ScheduleSettings,
  emptyScheduleSettings,
  scheduleSettingsFromResource,
} from './salonScheduleSettings';
import {
  PlainService,
  PlainServiceCategory,
  PlainServiceDiscount,
} from './service';
import {
  PlainMySalonStylist,
  PlainStylist,
  createPlainMySalonStylistFromResource,
  createPlainStylistFromResource,
} from './stylist';
import { VisitMotivation } from './visitMotivation';

export enum BasicPlan {
  Trial = 0,
  Single = 1,
  Standard = 2,

  // 特例の人限定で、課金なし使い放題
  Unlimited = 9999,
}
export enum OptionLinePlan {
  Trial = 0,
  Single = 1,
  Standard = 2,
}
export const BasicPlanNameMap = {
  [BasicPlan.Trial]: 'トライアル',
  [BasicPlan.Single]: 'シングル',
  [BasicPlan.Standard]: 'スタンダード',
  [BasicPlan.Unlimited]: 'アンリミテッド',
};
export const OptionLinePlanName = 'LINEオプション';
// 残り日数がこの日を下回ったら契約導線が表示
export const RestDaysThreshold = 8;

export type SalonBase = {
  id: number;
  publicId: string;
  name: string;
  findKeyword: string;
  taxRoundingType: TaxRoundingType;
  basicPlan: BasicPlan;
  optionLinePlan: OptionLinePlan;
  isPlanDisabled: boolean;
  trialFinishAt: number;
  imageUrl: string | null;

  services: {
    categoryMap: IdMap<PlainServiceCategory>;
    serviceMap: IdMap<PlainService>;
    discountMap: IdMap<PlainServiceDiscount>;
  };

  customInformation: {
    visitMotivationMap: IdMap<VisitMotivation>;
    memoTemplateMap: IdMap<MemoTemplate>;
    counselingMap: IdMap<CounselingSection>;
    paymentMethodMap: IdMap<PaymentMethod>;
  };

  lineSetting: SalonLineSetting | null;
  monthlyChatStatistics: SalonMonthlyChatStatistics | null;
};

export type Salon = SalonBase & {
  stylistMap: IdMap<PlainStylist>;
};

export type MySalon = SalonBase & {
  stylistMap: IdMap<PlainMySalonStylist>;

  firebaseImageBasePath: string;
  firebaseUploadedPapersBasePath: string;

  permissionRoleMap: IdMap<PermissionRole>;

  subscription: Subscription | null;

  customSetting: ISalonCustomSettingWithDefaults;
  reservationSettings: ReservationSettings | null;
  scheduleSettings: ScheduleSettings;
};

// 所属申請時に取得できるサロンの最小情報
export type SalonMinimum = Pick<Salon, 'id' | 'name' | 'imageUrl'>;

function emptySalonBase(overwrite?: Partial<SalonBase>): SalonBase {
  return {
    id: 0,
    publicId: '',
    name: '',
    findKeyword: '',
    taxRoundingType: TaxRoundingType.Floor,
    basicPlan: BasicPlan.Trial,
    optionLinePlan: OptionLinePlan.Trial,
    isPlanDisabled: false,
    trialFinishAt: 0,
    imageUrl: null,
    services: {
      categoryMap: {},
      serviceMap: {},
      discountMap: {},
    },
    customInformation: {
      visitMotivationMap: {},
      memoTemplateMap: {},
      counselingMap: {},
      paymentMethodMap: {},
    },
    lineSetting: null,
    monthlyChatStatistics: null,
    ...overwrite,
  };
}

export function emptySalon(overwrite?: Partial<Salon>): Salon {
  return {
    ...emptySalonBase(),
    stylistMap: {},
    ...overwrite,
  };
}

export function emptyMySalon(): MySalon {
  return {
    ...emptySalonBase(),
    stylistMap: {},
    firebaseImageBasePath: '',
    firebaseUploadedPapersBasePath: '',
    permissionRoleMap: {},
    subscription: null,
    customSetting: emptySalonCustomSetting(),
    reservationSettings: null,
    scheduleSettings: emptyScheduleSettings(),
  };
}

// TODO 一時的に lint を無効化しています。気づいたベースで直してください
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function createSalonBaseFromResource(res: any): SalonBase {
  return {
    id: res.id,
    publicId: res.publicId,
    name: res.name,
    findKeyword: res.findKeyword,
    taxRoundingType: res.taxRoundingType,
    basicPlan: res.basicPlan,
    optionLinePlan: res.optionLinePlan,
    isPlanDisabled: res.isPlanDisabled,
    trialFinishAt: res.trialFinishAt,
    imageUrl: res.imageUrl,
    services: {
      categoryMap: mapById(res.services.categories),
      serviceMap: mapById(res.services.services),
      discountMap: mapById(res.services.discounts),
    },
    customInformation: {
      visitMotivationMap: mapById(res.customInformation.visitMotivations),
      memoTemplateMap: mapById(res.customInformation.memoTemplates),
      counselingMap: mapById(res.customInformation.counselingSections),
      paymentMethodMap: mapById(res.customInformation.paymentMethods),
    },
    lineSetting: res.lineSetting
      ? lineSettingFromResource(res.lineSetting)
      : null,
    monthlyChatStatistics: res.monthlyChatStatistics ?? null,
  };
}

// TODO 一時的に lint を無効化しています。気づいたベースで直してください
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createSalonFromResource(res: any): Salon {
  return {
    ...createSalonBaseFromResource(res),
    stylistMap: mapById(
      // TODO 一時的に lint を無効化しています。気づいたベースで直してください
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      res.stylists.map((s: any) => createPlainStylistFromResource(s))
    ),
  };
}

// TODO 一時的に lint を無効化しています。気づいたベースで直してください
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createMySalonFromResource(res: any): MySalon {
  return {
    ...createSalonBaseFromResource(res),
    stylistMap: mapById(
      // TODO 一時的に lint を無効化しています。気づいたベースで直してください
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      res.stylists.map((s: any) => createPlainMySalonStylistFromResource(s))
    ),
    firebaseImageBasePath: res.firebaseImageBasePath,
    firebaseUploadedPapersBasePath: res.firebaseUploadedPapersBasePath,
    permissionRoleMap: mapBy(res.permissionRoles, 'roleId'),
    subscription: null,
    customSetting: emptySalonCustomSetting(res.customSetting),
    reservationSettings: res.reservationSettings
      ? reservationSettingsFromResource(res.reservationSettings)
      : null,
    scheduleSettings: scheduleSettingsFromResource(res.scheduleSettings),
  };
}
