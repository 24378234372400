import { VisitResource } from '@karutekun/core/visit';
import { Box } from '@mui/material';
import { useContext } from 'react';
import { XYCoord, useDragLayer } from 'react-dnd';
import { ScheduleItem } from './ScheduleItem';
import { DragItemData, ScheduleContext, SnapInfo } from './context';

export const ScheduleDragPreview: FC = () => {
  const { isDragging, item, sourceOffset, cursorOffset } = useDragLayer(
    (monitor) => ({
      item: monitor.getItem() as DragItemData,
      sourceOffset: monitor.getSourceClientOffset(),
      cursorOffset: monitor.getClientOffset(),
      isDragging: monitor.isDragging(),
    })
  );

  const { getContainerOffset, getSnappedInformation } =
    useContext(ScheduleContext);

  if (!isDragging) {
    return null;
  }
  if (!item || !item.layout || !item.visit) {
    return null;
  }

  const layout = item.layout;
  const visit: VisitResource = item.visit;

  function getSnapStyles(
    sourceOffset: XYCoord | null,
    cursorOffset: XYCoord | null
  ) {
    let info: SnapInfo | null;

    if (
      !sourceOffset ||
      !cursorOffset ||
      !(info = getSnappedInformation({
        x: sourceOffset.x,
        y: cursorOffset.y,
      }))
    ) {
      return {
        display: 'none',
      };
    }

    const transform = `translate(${info.x}px, ${info.y}px)`;
    return {
      transform,
      WebkitTransform: transform,
      height: info.h,
    };
  }

  function getItemStyles(sourceOffset: XYCoord | null) {
    let containerOffset: XYCoord | null;

    if (
      !sourceOffset ||
      !(containerOffset = getContainerOffset(sourceOffset))
    ) {
      return {
        display: 'none',
      };
    }

    const transform = `translate(${containerOffset.x}px, ${containerOffset.y}px)`;
    return {
      transform,
      WebkitTransform: transform,
    };
  }

  return (
    <div
      style={{
        position: 'absolute',
        pointerEvents: 'none',
        zIndex: 100,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        opacity: 0.5,
      }}
    >
      <Box
        style={{
          ...getSnapStyles(sourceOffset, cursorOffset),
          position: 'absolute',
          width: layout.width,
        }}
        bgcolor="primary.light"
      />
      <div
        style={{
          ...getItemStyles(sourceOffset),
          width: layout.width,
          height: layout.height,
        }}
      >
        <ScheduleItem
          visit={visit}
          layout={{
            left: 0,
            top: 0,
            width: layout.width,
            height: layout.height,
          }}
          onDragStart={() => {
            // 何もしない
          }}
          onClick={() => {
            // 何もしない
          }}
        />
      </div>
    </div>
  );
};
