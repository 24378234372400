import React from 'react';
import { SalonStatus } from '../../../models/salonStatus';

export type SalonStatusContextValue = {
  salonStatus: SalonStatus;

  /**
   * プランが有効かどうか判定し、無効の場合は制限のダイアログを表示する
   */
  checkAndOpenDialog(checkKey?: keyof SalonStatus['plan']): boolean;
};

export const SalonStatusContext = React.createContext<SalonStatusContextValue>({
  salonStatus: {} as SalonStatus,
  checkAndOpenDialog: () => false,
});

export function useSalonStatus(): SalonStatusContextValue {
  return React.useContext(SalonStatusContext);
}
