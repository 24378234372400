import { Badge, Paper, Tab, Tabs, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';

export type CTabViewRoute = {
  key: string;
  icon?: string;
  title?: string;
};

type OwnProps = {
  routes: CTabViewRoute[];
  renderScene: (scene: {
    route: CTabViewRoute;
    isVisible: boolean;
  }) => React.ReactNode;
  badges?: { [routeKey: string]: Nullable<number> };
  defaultTabIndex?: number;
};

const useStyles = makeStyles((_: Theme) => ({
  badge: {
    transform: 'translate(100%, -50%)',
  },
}));

const CTabView: FC<OwnProps> = (props) => {
  const { defaultTabIndex = 0, routes, badges, renderScene } = props;
  const classes = useStyles();
  const [index, setIndex] = useState(defaultTabIndex);

  return (
    <>
      <Paper square>
        <Tabs
          value={index}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, tabIndex) => setIndex(tabIndex)}
        >
          {routes.map((route) => (
            <Tab
              key={route.key}
              label={
                <Badge
                  classes={{ badge: classes.badge }}
                  badgeContent={badges?.[route.key] ?? 0}
                  max={99}
                  color="primary"
                >
                  {route.title}
                </Badge>
              }
              icon={route.icon}
            />
          ))}
        </Tabs>
      </Paper>
      {routes.map((route, i) => (
        // コンポーネントの状態を維持するために、display で表示/非表示を管理
        <div key={route.key} hidden={i !== index}>
          {renderScene({ route, isVisible: i === index })}
        </div>
      ))}
    </>
  );
};

export default CTabView;
