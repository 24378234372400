import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import LoadingImage from '../assets/img/common/loading.gif';

type Props = {
  title?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  loadingImage: {
    width: 100,
    height: 100,
    marginBottom: theme.spacing(1),
  },
}));

const LoadingPage: FC<Props> = (props) => {
  const classes = useStyles();
  const { title } = props;
  return (
    <div className={classes.container}>
      <img className={classes.loadingImage} src={LoadingImage} alt={title} />
      {title && (
        <Typography variant="body1" color="textSecondary">
          {title}
        </Typography>
      )}
    </div>
  );
};

export default LoadingPage;
