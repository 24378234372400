import { IReservation, IVisit, IVisitHistory, VisitStatus } from '../data';

type AsVisitHistory<T, K = keyof T> = T &
  Pick<IVisitHistory, K extends keyof IVisitHistory ? K : never>;

type AsReservation<T, K = keyof T> = T &
  Pick<IReservation, K extends keyof IReservation ? K : never>;

export function isVisitHistory<T extends Pick<IVisit, 'status'>>(
  visit: T
): visit is AsVisitHistory<T> {
  return visit.status === VisitStatus.Visited;
}

export function isReservation<T extends Pick<IVisit, 'status'>>(
  visit: T
): visit is AsReservation<T> {
  return visit.status === VisitStatus.Reserved;
}
