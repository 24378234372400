import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      d="M39 5.75H9c-3.45 0-6.25 2.8-6.25 6.25v24c0 3.45 2.8 6.25 6.25 6.25h30c3.45 0 6.25-2.8 6.25-6.25V12c0-3.45-2.8-6.25-6.25-6.25M9 9.25h30c1.52 0 2.75 1.23 2.75 2.75v18.76l-4.03-3.36a4.757 4.757 0 0 0-6.4.3l-2.07 2.07-8.02-8.01c-.86-.86-2-1.35-3.22-1.39-1.21-.04-2.39.39-3.29 1.2L6.24 29.1V12c0-1.52 1.23-2.75 2.75-2.75zM6.25 36v-2.21l10.8-9.6c.24-.21.54-.32.87-.31.32 0 .62.14.85.37l8.02 8.01-6.51 6.51H9c-1.52 0-2.75-1.23-2.75-2.75zM39 38.75H25.23l8.58-8.58c.22-.22.51-.35.83-.37.31-.02.62.09.86.29l6.27 5.23V36c0 1.52-1.23 2.75-2.75 2.75z"
    />
    <path
      fill={props.color || 'currentColor' || '#000'}
      d="M31.5 21c2.49 0 4.5-2.01 4.5-4.5S33.99 12 31.5 12 27 14.01 27 16.5s2.01 4.5 4.5 4.5m0-6c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5-1.5-.67-1.5-1.5.67-1.5 1.5-1.5"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgImage = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgImage);
export default Memo;
