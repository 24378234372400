import { objectKeys } from '@karutekun/shared/util/objects';
import { useBoolean } from '@karutekun/shared/util/react-hooks';
import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  InputLabel,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { dispatchWithErrorHandling } from '../../../../actions/helper/dispatchWithErrorHandling';
import {
  createReservationMenu,
  updateReservationMenu,
} from '../../../../actions/salon/reservationSettingsAction';
import CButton from '../../../../components_old/atoms/CButton';
import CDivider from '../../../../components_old/atoms/CDivider';
import CProgressOverlay from '../../../../components_old/atoms/CProgressOverlay';
import CRadioGroup from '../../../../components_old/atoms/CRadioGroup';
import CServiceCategoryIcon from '../../../../components_old/atoms/CServiceCategoryIcon';
import CStylistAvatar from '../../../../components_old/atoms/CStylistAvatar';
import CBreadCrumbs from '../../../../components_old/molecules/CBreadCrumbs';
import { ServiceSelect } from '../../../../features/service/components/ServiceSelect';
import { EditablePlainReservationMenu } from '../../../../models/reservationMenu';
import { checkPermission } from '../../../../models/stylist';
import {
  selectActiveReservationMenus,
  selectActiveReservationStylists,
} from '../../../../selectors/reservationSettingsSelector';
import { selectMe } from '../../../../selectors/salonSelector';
import { selectServiceMap } from '../../../../selectors/serviceSelector';
import { formatMoney } from '../../../../util/common';
import { useFlag } from '../../../../util/hooks/useFlag';
import { useThunkDispatch } from '../../../../util/hooks/useThunkDispatch';

const useStyles = makeStyles((_: Theme) => ({
  root: {
    padding: '76px 20px 20px 20px',
    maxWidth: 560,
  },
  card: {
    position: 'relative',
    padding: 24,
  },
  serviceSelectDialogContent: {
    padding: '0 !important',
    height: '100%',
  },
  availableStylistList: {
    maxHeight: 800,
    overflow: 'auto',
  },
  topCheckboxContainer: {
    display: 'flex',
    marginTop: 16,
    marginBottom: 4,
    marginLeft: 16,
    marginRight: 24,
  },
  indeterminateIcon: { color: '#20a8d8' },
  reservationStylistItem: {
    cursor: 'pointer',
    marginLeft: 16,
    marginRight: 24,
  },
}));

export const AddReservationMenu: FC = () => {
  const [isFetching, callWithFetching] = useFlag(false);

  const dispatch = useThunkDispatch();
  const navigate = useNavigate();

  const handleCreate = useCallback(
    (values: EditablePlainReservationMenu) =>
      callWithFetching(async () => {
        const reservationMenu = await dispatchWithErrorHandling(
          dispatch,
          createReservationMenu(values),
          { success: '掲載メニューを作成しました' }
        );

        if (reservationMenu) {
          navigate(`/settings/reservation/menu/${reservationMenu.id}`, {
            replace: true,
          });
        }
      }),
    [dispatch, callWithFetching, navigate]
  );

  return (
    <AddEditReservationMenu isFetching={isFetching} onSave={handleCreate} />
  );
};

export const EditReservationMenu: FC = () => {
  const params = useParams<{ reservationMenuId?: string }>();
  const reservationMenuId = Number(params.reservationMenuId ?? 0);

  const [isFetching, callWithFetching] = useFlag(false);

  const reservationMenus = useSelector(selectActiveReservationMenus);
  const reservationMenu = reservationMenus.find(
    (r) => r.id === reservationMenuId
  );
  const dispatch = useThunkDispatch();

  const handleSave = useCallback(
    (values: EditablePlainReservationMenu) => {
      if (!reservationMenu) {
        return;
      }

      // 更新された値だけ抽出する
      const updates: Partial<typeof values> = {};
      for (const key of objectKeys(values)) {
        if (!_.isEqual(values[key], reservationMenu[key])) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          updates[key] = values[key] as any;
        }
      }

      callWithFetching(async () => {
        await dispatchWithErrorHandling(
          dispatch,
          updateReservationMenu(reservationMenu.id, updates),
          { success: '掲載メニューの設定を保存しました' }
        );
      });
    },
    [dispatch, reservationMenu, callWithFetching]
  );

  if (!reservationMenu) {
    return null;
  }

  return (
    <AddEditReservationMenu
      defaultValues={reservationMenu}
      isFetching={isFetching}
      isEditMode
      onSave={handleSave}
    />
  );
};

const AddEditReservationMenu: FC<{
  defaultValues?: Partial<EditablePlainReservationMenu>;
  isFetching: boolean;
  isEditMode?: boolean;
  onSave(values: EditablePlainReservationMenu): void;
}> = (props) => {
  const { defaultValues, isFetching, isEditMode = false, onSave } = props;
  const classes = useStyles();

  const me = useSelector(selectMe);
  const reservationStylists = useSelector(selectActiveReservationStylists);

  const serviceMap = useSelector(selectServiceMap);

  const [values, setValues] = useState<EditablePlainReservationMenu>();

  useEffect(() => {
    setValues({
      serviceId: 0,
      name: '',
      description: '',
      showPriceFromSign: false,
      isVisible: true,
      availableReservationStylistIds: reservationStylists.map((rs) => rs.id),
      ...defaultValues,
    });
  }, [defaultValues, reservationStylists]);

  const isUpdated = useMemo(
    () => !_.isEqual(values, defaultValues),
    [defaultValues, values]
  );

  const handleUpdate = useCallback(
    (changes: Partial<typeof values>) => {
      if (values) {
        setValues({ ...values, ...changes });
      }
    },
    [values]
  );

  const handleSave = useCallback(() => {
    if (values) {
      onSave(values);
    }
  }, [onSave, values]);

  const [isDialogOpen, isDialogOpenMutations] = useBoolean();
  const handleClickService = useCallback(
    (serviceId: number) => {
      handleUpdate({
        serviceId,
        name: values?.name || serviceMap[serviceId]?.name || '',
      });
      isDialogOpenMutations.setFalse();
    },
    [handleUpdate, serviceMap, values, isDialogOpenMutations]
  );

  // 権限がなければこのページに来ないはずだけど一応チェック
  const hasPermission = checkPermission(me, 'canUpdateReservationSetting');

  if (!values || !hasPermission) {
    return null;
  }

  const service = values?.serviceId ? serviceMap[values.serviceId] : undefined;
  const isValid = service !== undefined && values?.name?.length > 0;

  return (
    <div className={classes.root}>
      {isFetching && <CProgressOverlay />}

      <Dialog
        open={isDialogOpen}
        fullScreen
        onClose={isDialogOpenMutations.setFalse}
      >
        <DialogContent
          dividers
          classes={{
            root: classes.serviceSelectDialogContent,
          }}
        >
          <ServiceSelect showMenuOnly onClickService={handleClickService} />
        </DialogContent>
        <DialogActions>
          <CButton variant="outlined" onClick={isDialogOpenMutations.setFalse}>
            キャンセル
          </CButton>
        </DialogActions>
      </Dialog>

      <CBreadCrumbs
        breadcrumbs={[
          {
            text: '予約受付：掲載メニュー',
            linkTo: '/settings/reservation/menu',
          },
          { text: isEditMode ? (values.name ?? '') : '新規作成' },
        ]}
      />

      <ValidatorForm instantValidate={false} onSubmit={handleSave}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              基本設定
            </Typography>

            <Card className={classes.card}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputLabel shrink>内部メニュー</InputLabel>
                  <Box
                    mt={1}
                    mb={1}
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                  >
                    <Button
                      variant="outlined"
                      onClick={isDialogOpenMutations.setTrue}
                    >
                      {service ? (
                        <>
                          <Box mr={1}>
                            <CServiceCategoryIcon
                              size={24}
                              category={service.category}
                            />
                          </Box>
                          <Typography>{service.name}</Typography>
                        </>
                      ) : (
                        <Typography>内部メニューを選択してください</Typography>
                      )}
                      <SVGIcon name="angle-right" size="sm" />
                    </Button>
                    {service && (
                      <Box ml={2}>
                        <Typography variant="body1">
                          {formatMoney(service.price)} (
                          {service.isTaxIncluded ? '税込' : '税抜'})
                        </Typography>
                        <Typography variant="body1">
                          {service.time}分
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Grid>

                {service && (
                  <>
                    <Grid item xs={12}>
                      <TextValidator
                        variant="standard"
                        label="表示メニュー名"
                        fullWidth
                        value={values.name}
                        inputProps={{ maxLength: 255 }}
                        onChange={(e) => handleUpdate({ name: e.target.value })}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextValidator
                        variant="outlined"
                        label="説明文"
                        fullWidth
                        multiline
                        value={values.description}
                        inputProps={{ maxLength: 255 }}
                        onChange={(e) =>
                          handleUpdate({ description: e.target.value })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CRadioGroup
                        label="掲載状況"
                        currentValue={values.isVisible ? 1 : 0}
                        onChange={(v) => handleUpdate({ isVisible: v === 1 })}
                        options={[
                          { value: 1, label: '掲載する（予約を受け付ける）' },
                          {
                            value: 0,
                            label: '掲載しない（予約を受け付けない）',
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CRadioGroup
                        label="価格の表記"
                        currentValue={values.showPriceFromSign ? 1 : 0}
                        onChange={(v) =>
                          handleUpdate({ showPriceFromSign: v === 1 })
                        }
                        options={[
                          {
                            value: 1,
                            label: `〜をつける（${formatMoney(
                              service.price
                            )}〜）`,
                          },
                          {
                            value: 0,
                            label: `〜をつけない（${formatMoney(
                              service.price
                            )}）`,
                          },
                        ]}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Card>
          </Grid>

          {service && (
            <Grid item xs={12}>
              <ReservationMenuAvailableStylists
                availableReservationStylistIds={
                  values.availableReservationStylistIds ?? []
                }
                handleUpdate={(availableReservationStylistIds: number[]) =>
                  handleUpdate({ availableReservationStylistIds })
                }
              />
            </Grid>
          )}

          <Grid container item justifyContent="flex-end" spacing={2}>
            <Grid item>
              <CButton variant="outlined" linkTo="/settings/reservation/menu">
                キャンセル
              </CButton>
            </Grid>

            <Grid item>
              <CButton type="submit" disabled={!isUpdated || !isValid}>
                保存
              </CButton>
            </Grid>
          </Grid>
        </Grid>
      </ValidatorForm>
    </div>
  );
};

const ReservationMenuAvailableStylists: FC<{
  availableReservationStylistIds: number[];
  handleUpdate(availableReservationStylistIds: number[]): void;
}> = (props) => {
  const { availableReservationStylistIds, handleUpdate } = props;

  const classes = useStyles();
  const reservationStylists = useSelector(selectActiveReservationStylists);

  const checkedStylistNum = useMemo(
    () =>
      availableReservationStylistIds.filter((id) =>
        reservationStylists.find((f) => f.id === id)
      ).length,
    [reservationStylists, availableReservationStylistIds]
  );

  // 検索結果が全て選択されているか
  const isChecked =
    reservationStylists.length > 0 &&
    reservationStylists.length === checkedStylistNum;
  const isIndeterminate = !isChecked && checkedStylistNum > 0;

  const onClick = useCallback(() => {
    if (!reservationStylists.length) {
      return;
    }

    handleUpdate(
      isChecked || isIndeterminate
        ? availableReservationStylistIds.filter(
            (id) => !reservationStylists.find((f) => f.id === id)
          )
        : _.uniq(
            availableReservationStylistIds.concat(
              reservationStylists.map((rm) => rm.id)
            )
          )
    );
  }, [
    availableReservationStylistIds,
    isChecked,
    isIndeterminate,
    reservationStylists,
    handleUpdate,
  ]);

  const toggle = useCallback(
    (id: number) => {
      const filteredIds = availableReservationStylistIds.filter(
        (reservationStylistId) => reservationStylistId !== id
      );

      if (filteredIds.length !== availableReservationStylistIds.length) {
        handleUpdate(filteredIds);
      } else {
        handleUpdate(filteredIds.concat(id));
      }
    },
    [availableReservationStylistIds, handleUpdate]
  );

  return (
    <>
      <Typography variant="h6" gutterBottom>
        担当可能スタッフ
      </Typography>

      <Card>
        <Grid
          container
          onClick={onClick}
          alignItems="center"
          spacing={1}
          className={classes.topCheckboxContainer}
        >
          <Grid item>
            <Checkbox
              checked={isChecked}
              indeterminate={isIndeterminate}
              indeterminateIcon={
                <IndeterminateCheckBoxIcon
                  className={classes.indeterminateIcon}
                />
              }
              onClick={onClick}
            />
          </Grid>
          <Grid item xs>
            <Typography color="textSecondary">
              {availableReservationStylistIds.length
                ? `${checkedStylistNum}件を選択中`
                : `${reservationStylists.length}件をすべて選択`}
            </Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.availableStylistList}>
          {reservationStylists.map((reservationStylist, i) => (
            <React.Fragment key={reservationStylist.id}>
              <Grid
                container
                alignItems="center"
                spacing={1}
                onClick={() => toggle(reservationStylist.id)}
                className={classes.reservationStylistItem}
              >
                <Grid item style={{ marginTop: 8, marginBottom: 8 }}>
                  <Checkbox
                    checked={availableReservationStylistIds.includes(
                      reservationStylist.id
                    )}
                    onClick={() => toggle(reservationStylist.id)}
                  />
                </Grid>

                <Grid item container xs alignItems="center">
                  <Grid
                    item
                    style={{ marginRight: 12, marginTop: 11, marginBottom: 11 }}
                  >
                    <CStylistAvatar
                      size={32}
                      stylist={reservationStylist.stylist}
                    />
                  </Grid>

                  <Grid item xs style={{ marginTop: 8, marginBottom: 8 }}>
                    <Typography variant="body1">
                      {reservationStylist.name}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {i < reservationStylists.length - 1 && (
                <CDivider spacing={0} style={{ width: '100%' }} />
              )}
            </React.Fragment>
          ))}
        </Grid>
      </Card>
    </>
  );
};
