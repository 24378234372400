import { DiscountType, ServiceType } from '@karutekun/core/salon-service';
import { localUniqueId } from '../util/common';

export const DefaultIconName = '1101';
export type PlainServiceCategory = {
  id: number;
  type: ServiceType;
  name: string;
  iconName: string;
  order: number;
  isActive: boolean;
  isVisible: boolean;
};
export type ServiceCategory = PlainServiceCategory;

export type PlainService = {
  id: number;
  type: ServiceType;
  categoryId: number;
  name: string;
  time: number;
  price: number;
  isTaxIncluded: boolean;
  taxRate: number;
  janCode: string | null;
  ticketNum: number;
  ticketUnusedValidDays: number;
  ticketUsedValidDays: number;
  order: number;
  isVisible: boolean;
  isActive: boolean;
};
export type Service = PlainService & {
  category: ServiceCategory;
};

export type PlainServiceDiscount = {
  id: number;
  type: DiscountType;
  name: string;
  value: number;
  order: number;
  isVisible: boolean;
  isActive: boolean;
};
export type ServiceDiscount = PlainServiceDiscount;

export function emptyPlainService(
  overwrite: Partial<PlainService> = {}
): PlainService {
  return {
    id: overwrite.id ?? localUniqueId(),
    type: overwrite.type ?? ServiceType.Menu,
    categoryId: overwrite.categoryId ?? 0,
    name: overwrite.name ?? '',
    time: overwrite.time ?? 0,
    price: overwrite.price ?? 0,
    isTaxIncluded: overwrite.isTaxIncluded ?? true,
    taxRate: overwrite.taxRate ?? 10,
    janCode: overwrite.janCode ?? null,
    ticketNum: overwrite.ticketNum ?? 0,
    ticketUnusedValidDays: overwrite.ticketUnusedValidDays ?? 0,
    ticketUsedValidDays: overwrite.ticketUsedValidDays ?? 0,
    order: overwrite.order ?? 0,
    isVisible: overwrite.isVisible ?? true,
    isActive: overwrite.isActive ?? true,
  };
}
export function emptyPlainServiceCategory(
  overwrite: Partial<PlainServiceCategory> = {}
): PlainServiceCategory {
  return {
    id: overwrite.id ?? localUniqueId(),
    type: overwrite.type ?? ServiceType.Menu,
    name: overwrite.name ?? '',
    iconName: overwrite.iconName ?? '',
    order: overwrite.order ?? 0,
    isVisible: overwrite.isVisible ?? true,
    isActive: overwrite.isActive ?? true,
  };
}

export function emptyPlainServiceDiscount(
  overwrite: Partial<PlainServiceDiscount> = {}
): PlainServiceDiscount {
  return {
    id: overwrite.id ?? localUniqueId(),
    type: overwrite.type ?? DiscountType.Price,
    name: overwrite.name ?? '',
    value: overwrite.value ?? 0,
    order: overwrite.order ?? 0,
    isVisible: overwrite.isVisible ?? true,
    isActive: overwrite.isActive ?? true,
  };
}

// TODO 一時的に lint を無効化しています。気づいたベースで直してください
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function serviceCategoryFromResource(res: any): PlainServiceCategory {
  return emptyPlainServiceCategory(res);
}
// TODO 一時的に lint を無効化しています。気づいたベースで直してください
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function serviceFromResource(res: any): PlainService {
  return emptyPlainService(res);
}
// TODO 一時的に lint を無効化しています。気づいたベースで直してください
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function serviceDiscountFromResource(res: any): PlainServiceDiscount {
  return emptyPlainServiceDiscount(res);
}

export const ServiceCategoryColorMap: {
  [key in ServiceType]: { [iconName: string]: string };
} = {
  [ServiceType.Menu]: {
    1101: '#3D4FBC',
    1102: '#50C0F0',
    1103: '#9AE942',
    1104: '#50C5AB',
    1105: '#CF0040',
    1106: '#EA291C',
    1107: '#48BE99',
    1108: '#545187',
    1201: '#FD5D62',
    1202: '#FC5935',
    1203: '#9C2826',
    1204: '#5321A8',
    1205: '#A27C47',
    1301: '#FEC26E',
    1302: '#F5ABC5',
    1303: '#FB4B89',
    1401: '#128575',
    1402: '#3FA43F',
    1403: '#7C8A1E',
    1404: '#C2D82C',
    1501: '#4150FD',
    1502: '#36B7FF',
    9001: '#509D12',
    9002: '#107B02',
    9003: '#1C8EDA',
    9004: '#0133EA',
    9005: '#9800FF',
    9006: '#EE55C6',
    9007: '#CD0060',
    9008: '#DC0005',
    9009: '#F55107',
    9010: '#8D4022',
  },
  [ServiceType.Product]: {
    1101: '#D9077F',
    1102: '#4094D3',
    1103: '#385BE0',
    1104: '#7556D9',
    1105: '#C82F60',
    1201: '#C2D82C',
    1202: '#7ABA3A',
    1301: '#128575',
    1302: '#8800A0',
    1303: '#5100FE',
    1401: '#EE2A2B',
    1501: '#FFEA2E',
    9001: '#509D12',
    9002: '#107B02',
    9003: '#1C8EDA',
    9004: '#0133EA',
    9005: '#9800FF',
    9006: '#EE55C6',
    9007: '#CD0060',
    9008: '#DC0005',
    9009: '#F55107',
    9010: '#8D4022',
  },
  [ServiceType.Ticket]: {},
};
