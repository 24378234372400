import {
  QueryConstraint,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  updateDoc,
} from 'firebase/firestore';
import { Dispatch } from 'redux';
import { deprecated } from 'typesafe-actions';
import firebaseApp from '../libs/firebase';
import { ChatInfo } from '../models/chat';
import { ChatMessage } from '../models/chatMessage/entity';
import { ChatMessageStatus } from '../models/chatMessage/schema';
import { ChatRoom } from '../models/chatRoom/entity';
import { ThunkDispatch } from '../store';
import { sendRequest } from './request';
import { setSalonChatStatistics } from './salonAction';

const { createAction } = deprecated;

const ChatMessagesFetchLimit = 30;

export enum ActionNames {
  SetChatInfo = 'chat/SetChatInfo',
  SetChatMessages = 'chat/SetChatMessages',
  SetLocalChatMessages = 'chat/SetLocalChatMessages',
  SetLocalChatMessageStatus = 'chat/SetLocalChatMessageStatus',
  SetChatRooms = 'chat/SetChatRooms',
  MarkChatRoomRead = 'chat/MarkChatRoomRead',
}

export const setChatInfo = createAction(ActionNames.SetChatInfo, (action) => {
  return (salonId: number, info: ChatInfo) => action({ salonId, info });
});
export const setChatMessages = createAction(
  ActionNames.SetChatMessages,
  (action) => {
    return (salonId: number, customerId: number, chatMessages: ChatMessage[]) =>
      action({ salonId, customerId, chatMessages });
  }
);
export const setLocalChatMessages = createAction(
  ActionNames.SetLocalChatMessages,
  (action) => {
    return (salonId: number, customerId: number, chatMessages: ChatMessage[]) =>
      action({ salonId, customerId, chatMessages });
  }
);
export const setLocalChatMessageStatus = createAction(
  ActionNames.SetLocalChatMessageStatus,
  (action) => {
    return (
      salonId: number,
      customerId: number,
      documentId: string,
      status: ChatMessageStatus
    ) => action({ salonId, customerId, documentId, status });
  }
);
export const setChatRooms = createAction(ActionNames.SetChatRooms, (action) => {
  return (salonId: number, chatRooms: ChatRoom[]) =>
    action({ salonId, chatRooms });
});
export const markChatRoomRead = createAction(
  ActionNames.MarkChatRoomRead,
  (action) => {
    return (salonId: number, customerId: number) =>
      action({ salonId, customerId });
  }
);

/**
 * メッセージを送信する
 */
export function sendMessage(chatMessage: ChatMessage) {
  return async function (dispatch: Dispatch) {
    const salonId = chatMessage.salonId;
    const customerId = chatMessage.customerId;

    // 先にローカルに反映して画面にメッセージを表示する
    dispatch(setLocalChatMessages(salonId, customerId, [chatMessage]));

    const documentId = chatMessage.documentId;
    const message = { type: chatMessage.type, body: chatMessage.body };

    try {
      const json = await sendRequest(dispatch, 'chat/message', {
        method: 'POST',
        body: JSON.stringify({
          documentId,
          customerId,
          message,
          salonId,
        }),
      });

      dispatch(setSalonChatStatistics(salonId, json.monthlyChatStatistics));
    } catch (e) {
      // 画像アップロード、APIコールが失敗した場合は status を Failure に変えて画面上で再送できるようにする
      dispatch(
        setLocalChatMessageStatus(
          salonId,
          customerId,
          documentId,
          ChatMessageStatus.Failure
        )
      );
      throw e;
    }
  };
}

/**
 * メッセージを読み込む
 * @param salonId
 * @param customerId
 * @param cursor このドキュメント以降のものを取得する
 */
export function fetchChatMessages(
  salonId: number,
  customerId: number,
  cursor?: string
) {
  return async function (dispatch: ThunkDispatch) {
    const messagesRef = getChatRoomMessagesRef(salonId, customerId);
    const queryConstraints: QueryConstraint[] = [
      orderBy('createdAtMs', 'desc'),
    ];

    if (cursor) {
      const docSnap = await getDoc(doc(messagesRef, cursor));
      queryConstraints.push(startAfter(docSnap));
    }

    queryConstraints.push(limit(ChatMessagesFetchLimit));
    const q = query(messagesRef, ...queryConstraints);
    const snapshots = await getDocs(q);

    const chatMessages: ChatMessage[] = [];
    snapshots.forEach((doc) => {
      const data = doc.data();
      const chatMessage = new ChatMessage({
        documentId: doc.id,
        salonId: salonId,
        customerId: customerId,
        fromId: data.fromId,
        status: data.status,
        type: data.type,
        body: data.body,
        createdAtMs: data.createdAtMs,
      });
      chatMessages.push(chatMessage);
    });

    if (chatMessages.length === 0) {
      return;
    }

    dispatch(setChatMessages(salonId, customerId, chatMessages));
  };
}

/**
 * 特定の顧客について、チャットメッセージの更新を listen する
 */
export function listenLatestChatMessagesForCustomer(
  salonId: number,
  customerId: number
) {
  return function (dispatch: ThunkDispatch): () => void {
    const ref = getChatRoomMessagesRef(salonId, customerId);
    const q = query(
      ref,
      orderBy('createdAtMs', 'desc'),
      limit(ChatMessagesFetchLimit)
    );

    return onSnapshot(q, (querySnapshot) => {
      const chatMessages: ChatMessage[] = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const chatMessage = new ChatMessage({
          documentId: doc.id,
          salonId: salonId,
          customerId: customerId,
          fromId: data.fromId,
          status: data.status,
          type: data.type,
          body: data.body,
          createdAtMs: data.createdAtMs,
        });
        chatMessages.push(chatMessage);
      });

      dispatch(setChatMessages(salonId, customerId, chatMessages));
    });
  };
}

/**
 * 特定の顧客について、チャットルームを listen する
 */
export function listenChatRoomForCustomer(salonId: number, customerId: number) {
  return function (dispatch: ThunkDispatch): () => void {
    const ref = getChatRoomRef(salonId, customerId);

    return onSnapshot(ref, (doc) => {
      const data = doc.data();
      if (data) {
        dispatch(
          setChatRooms(salonId, [
            {
              documentId: doc.id,
              salonId: salonId,
              customerId: data.customerId,
              latestMessage: data.latestMessage,
              unreadNum: data.unreadNum,
              isDeleted: data.isDeleted,
            },
          ])
        );
      }
    });
  };
}

/**
 * 特定の顧客とのチャットルームを既読状態にする
 */
export function markReadForChatRoom(salonId: number, customerId: number) {
  return async function (dispatch: ThunkDispatch) {
    const ref = getChatRoomRef(salonId, customerId);
    updateDoc(ref, 'unreadNum', 0).catch((e) => {
      // 既読処理は失敗しても無視してしまう
      console.log(e);
    });

    dispatch(markChatRoomRead(salonId, customerId));
  };
}

function getSalonRef(salonId: number) {
  const db = getFirestore(firebaseApp);
  return doc(db, 'salons', salonId.toString());
}

function getChatRoomsRef(salonId: number) {
  return collection(getSalonRef(salonId), 'customers');
}

function getChatRoomRef(salonId: number, customerId: number) {
  return doc(getChatRoomsRef(salonId), customerId.toString());
}

function getChatRoomMessagesRef(salonId: number, customerId: number) {
  return collection(getChatRoomRef(salonId, customerId), 'messages');
}
