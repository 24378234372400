import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M39.453 9.395A21.44 21.44 0 0 1 44.8 19.31c.287 1.388.428 2.803.42 4.22a21 21 0 0 1-20.93 21 25.062 25.062 0 0 1-5.77-.85c-1.042-.298-2.055-.665-2.597-.86a5.186 5.186 0 0 0-.403-.14.93.93 0 0 0-.41-.08 1.13 1.13 0 0 0-.45.09l-6.4 2.32a3.49 3.49 0 0 1-.9.22h-.19a2.55 2.55 0 0 1-1-.21 2.47 2.47 0 0 1-.85-.58 2.63 2.63 0 0 1-.76-1.87v-.11c.02-.226.06-.45.12-.67l1.77-6.41a1.77 1.77 0 0 0-.14-.28l-.11-.17-.07-.09A20.55 20.55 0 0 1 8.77 9a21.44 21.44 0 0 1 30.683.395M18 24a3 3 0 1 1-6 0 3 3 0 0 1 6 0m6 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6m12-3a3 3 0 1 1-6 0 3 3 0 0 1 6 0"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgCommentDotsFill = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgCommentDotsFill);
export default Memo;
