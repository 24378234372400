import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M12.5 6.75c-1.562 0-2.75 1.222-2.75 2.635v29.23c0 1.413 1.188 2.635 2.75 2.635h23c1.562 0 2.75-1.222 2.75-2.635V9.385c0-1.413-1.188-2.635-2.75-2.635zM6.25 9.385c0-3.431 2.841-6.135 6.25-6.135h23c3.409 0 6.25 2.704 6.25 6.135v29.23c0 3.431-2.841 6.135-6.25 6.135h-23c-3.409 0-6.25-2.704-6.25-6.135z"
      clipRule="evenodd"
    />
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M14.75 13.5c0-.966.784-1.75 1.75-1.75h15a1.75 1.75 0 1 1 0 3.5h-15a1.75 1.75 0 0 1-1.75-1.75m0 7.5c0-.966.784-1.75 1.75-1.75h15a1.75 1.75 0 1 1 0 3.5h-15A1.75 1.75 0 0 1 14.75 21m0 7.5c0-.966.784-1.75 1.75-1.75H24a1.75 1.75 0 1 1 0 3.5h-7.5a1.75 1.75 0 0 1-1.75-1.75"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgDocument = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgDocument);
export default Memo;
