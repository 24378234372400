import { moment } from '@karutekun/shared/util/datetime';
import numeral from 'numeral';

/**
 * Formatter
 */
export function fmtSalesShrink(v: number) {
  return `${numeral(v / 10000).format('0,0.[0]')}万`;
}
export function fmtSales(v: number) {
  return `¥${numeral(v).format('0,0')}`;
}
export function fmtAvgSales(v: number) {
  return fmtSales(Math.round(v));
}
export function fmtCustomerNum(v: number) {
  return `${v}人`;
}
export function fmtAvgCustomerNum(v: number) {
  return fmtCustomerNum(Math.round(v * 100) / 100);
}
export function fmtVisitNum(v: number) {
  return `${v}回`;
}
export function fmtMenuNum(v: number) {
  return `${v}回`;
}
export function fmtProductNum(v: number) {
  return `${v}個`;
}
export function fmtDate(v: string) {
  return moment(v).format('M月D日(ddd)');
}
export function fmtMonth(v: string) {
  return moment(v).format('YYYY年M月');
}
export function fmtDayOfWeek(v: number) {
  return moment().isoWeekday(v).format('dd');
}

/**
 * Styling
 */
export const xAxisProps = {
  tick: {
    fontSize: 12,
    fontFamily: 'Roboto',
  },
  height: 25,
};
export const yAxisProps = {
  tick: {
    fontSize: 12,
    fontFamily: 'Roboto',
  },
  width: 45,
};
export const tooltipProps = {
  itemSuffixStyle: {
    color: '#999',
    fontSize: 10,
    fontFamily: 'Roboto',
    marginLeft: 5,
  },
  itemStyle: {
    color: '#333',
    fontSize: 13,
    fontFamily: 'Roboto',
  },
  labelStyle: {
    color: '#111',
    fontSize: 15,
    fontFamily: 'Roboto',
  },
};
export const legendProps = {
  iconType: 'circle',
  iconSize: 12,
  wrapperStyle: {
    fontSize: 12,
    fontFamily: 'Roboto',
    maxHeight: 60,
    overflowY: 'scroll',
  },
} as const;
