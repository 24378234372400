import { VoucherUtils } from '@karutekun/core/voucher';
import { RouterInput, trpc } from '@karutekun/shared/data-access/api-base';
import { useInvalidateVisits } from '@karutekun/shared/data-access/visit';
import {
  adjustUnixToMinuteInterval,
  nowUnix,
} from '@karutekun/shared/util/datetime';
import { useBoolean } from '@karutekun/shared/util/react-hooks';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import Button from '@mui/material/Button';
import { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  pushSnackbar,
  pushSnackbarError,
} from '../../../../actions/generalAction';
import CButton from '../../../../components_old/atoms/CButton';
import CDivider from '../../../../components_old/atoms/CDivider';
import {
  useSelectMe,
  useSelectMySalon,
} from '../../../../selectors/salonSelector';
import { selectMinuteInterval } from '../../../../selectors/scheduleSettingsSelector';
import { useSalonStatus } from '../../../../templates/providers/salonStatus/salonStatusContext';
import { useThunkDispatch } from '../../../../util/hooks/useThunkDispatch';
import { useWidthDown } from '../../../../util/hooks/useWidth';
import { CreateVoucherDialog } from '../../../voucher/components/CreateVoucherDialog';
import { VoucherPreview } from '../../../voucher/components/VoucherPreview';
import { VisitFormAssignedStylist } from '../form/VisitFormAssignedStylist';
import { VisitFormDatetime } from '../form/VisitFormDatetime';
import { VisitFormIsShimei } from '../form/VisitFormIsShimei';
import { VisitFormTreatmentMemo } from '../form/VisitFormTreatmentMemo';

type FormData = RouterInput['visit']['createVisitHistory'];
type DefaultData = Partial<FormData>;

type Props = {
  open: boolean;
  customerId: number;
  defaultData?: DefaultData;
  onClose(): void;
};

export const CreateVisitDialog: FC<Props> = (props) => {
  const { open, customerId, defaultData, onClose } = props;

  const dispatch = useThunkDispatch();

  const salon = useSelectMySalon();
  const me = useSelectMe();
  const minuteInterval = useSelector(selectMinuteInterval);
  const customSetting = salon.customSetting;
  const { checkAndOpenDialog } = useSalonStatus();

  const [isVoucherDialogOpen, setIsVoucherDialogOpen] = useBoolean(false);

  const invalidateVisits = useInvalidateVisits();
  const { control, setValue, getValues, watch, handleSubmit } =
    useForm<FormData>({
      defaultValues: {
        visit: {
          customerId,
          assignedStylistId: me.id,
          treatmentMemo: customSetting.initialKaruteMemo,
          startedAt: adjustUnixToMinuteInterval(nowUnix(), minuteInterval),
          finishedAt:
            adjustUnixToMinuteInterval(nowUnix(), minuteInterval) + 3600,
          isShimei: customSetting.initialKaruteIsShimei,
          ...defaultData?.visit,
        },
        voucher: {
          taxRoundingType: salon.taxRoundingType,
          lines: [],
          discounts: [],
        },
      },
    });

  const { mutate: createVisitHistory, isPending } =
    trpc.visit.createVisitHistory.useMutation({
      async onSuccess(res) {
        await invalidateVisits();
        onClose();
        dispatch(
          pushSnackbar({
            message: `来店記録を作成しました`,
            type: 'success',
            linkTo: `/visit_histories/${res.id}`,
          })
        );
      },
      onError(e) {
        dispatch(
          pushSnackbarError(e.data?.messageForUser ?? 'エラーが発生しました')
        );
      },
    });

  const handleCreate = useCallback(async () => {
    if (!checkAndOpenDialog()) {
      return;
    }

    handleSubmit((data) => {
      createVisitHistory({
        visit: data.visit,
        voucher: data.voucher,
        // TODO:visit API叩いて当日の来店がないかチェックすること
        skipTodayVisitCheck: true,
      });
    })();
  }, [checkAndOpenDialog, createVisitHistory, handleSubmit]);

  const voucher = watch('voucher');
  const assignedStylistId = watch('visit.assignedStylistId');
  const isShimei = watch('visit.isShimei');
  const voucherForPreview = useMemo(
    () => ({
      salonId: salon.id,
      ...VoucherUtils.calculateAndFillVoucherSales(voucher),
    }),
    [salon.id, voucher]
  );
  const voucherDialogDefaults = useMemo(
    () => ({
      stylistId: assignedStylistId,
      isShimei,
      data: voucher,
    }),
    [assignedStylistId, isShimei, voucher]
  );

  const fullScreen = useWidthDown('sm');
  return (
    <Dialog
      disableEscapeKeyDown
      maxWidth="xs"
      fullWidth={true}
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>来店記録作成</DialogTitle>

      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <VisitFormDatetime
              startedAt={watch('visit.startedAt')}
              finishedAt={watch('visit.finishedAt')}
              onChange={({ startedAt, finishedAt }) => {
                setValue('visit.startedAt', startedAt, { shouldDirty: true });
                setValue('visit.finishedAt', finishedAt, { shouldDirty: true });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="visit.isShimei"
              render={({ field: { onChange, value } }) => (
                <VisitFormIsShimei isShimei={value} onChange={onChange} />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="visit.assignedStylistId"
              render={({ field: { onChange, value } }) => (
                <VisitFormAssignedStylist
                  salonId={salon.id}
                  assignedStylistId={value}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="visit.treatmentMemo"
              render={({ field: { onChange, value } }) => (
                <VisitFormTreatmentMemo
                  treatmentMemo={value}
                  onChange={onChange}
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>

        <CDivider spacing={2} />

        <VoucherPreview voucher={voucherForPreview} />
        <Box mt={2}>
          <CreateVoucherDialog
            open={isVoucherDialogOpen}
            defaults={voucherDialogDefaults}
            onCancel={setIsVoucherDialogOpen.setFalse}
            onComplete={(voucher, totalMinutes) => {
              setIsVoucherDialogOpen.setFalse();
              setValue('voucher', voucher, { shouldDirty: true });
              setValue(
                'visit.finishedAt',
                getValues('visit.startedAt') + totalMinutes * 60,
                { shouldDirty: true }
              );
            }}
          />
          <Button
            variant="outlined"
            color="secondary"
            onClick={setIsVoucherDialogOpen.setTrue}
          >
            メニューの編集
          </Button>
        </Box>
      </DialogContent>

      <DialogActions>
        <CButton
          disabled={isPending}
          variant="outlined"
          color="primary"
          onClick={onClose}
        >
          キャンセル
        </CButton>
        <CButton
          isLoading={isPending}
          variant="contained"
          color="primary"
          onClick={handleCreate}
        >
          作成
        </CButton>
      </DialogActions>
    </Dialog>
  );
};
