import { trpc } from '@karutekun/shared/data-access/api-base';
import { isAfterToday } from '@karutekun/shared/util/datetime';
import { useCallback } from 'react';

type UseCheckInOptions = {
  showConfirmation?: (onOk: () => void | Promise<void>) => void;
  reservationStartedAt: number | undefined;
  onSuccess?: () => Promise<void>;
};

export function useCheckIn({
  showConfirmation,
  reservationStartedAt,
  onSuccess,
}: UseCheckInOptions) {
  const { mutateAsync, isPending } = trpc.visit.checkIn.useMutation({
    onSuccess,
  });

  const checkIn = useCallback(
    async (reservationId: number) => {
      if (isAfterToday(reservationStartedAt ?? 0)) {
        showConfirmation?.(() => mutateAsync(reservationId));
        return;
      }
      await mutateAsync(reservationId);
    },
    [reservationStartedAt, mutateAsync, showConfirmation]
  );

  const checkInWithoutConfirm = useCallback(
    async (reservationId: number) => {
      await mutateAsync(reservationId);
    },
    [mutateAsync]
  );

  return {
    checkIn,
    checkInWithoutConfirm,
    checkInPending: isPending,
  };
}
