import { VisitResource } from '@karutekun/core/visit';
import { moment } from '@karutekun/shared/util/datetime';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from '../../actions/view/viewScheduleAction';

export type ViewScheduleActions = ActionType<typeof actions>;

type PreviewState = {
  isEdited: boolean;
  selectedVisitId: number | null;
  visitUpdates: Partial<VisitResource>;
};

export interface ViewScheduleState {
  currentDate: string;
  drawer: {
    isReservationListOpen: boolean;
    isSchedulePreviewOpen: boolean;

    preview: PreviewState;
  };
}

const initialState: ViewScheduleState = {
  currentDate: moment().format('YYYY-MM-DD'),
  drawer: {
    isReservationListOpen: false,
    isSchedulePreviewOpen: false,
    preview: {
      isEdited: false,
      selectedVisitId: null,
      visitUpdates: {},
    },
  },
};

function viewScheduleReducer(
  state = initialState,
  action: ViewScheduleActions
): ViewScheduleState {
  switch (action.type) {
    case getType(actions.setViewScheduleCurrentDate): {
      const { date } = action.payload;

      if (date === state.currentDate) {
        return state;
      }

      return { ...state, currentDate: date };
    }

    case getType(actions.setViewScheduleDrawerStatus): {
      const {
        drawerStates: {
          reservationList: isReservationListOpen = state.drawer
            .isReservationListOpen,
          schedulePreview: isSchedulePreviewOpen = state.drawer
            .isSchedulePreviewOpen,
        },
      } = action.payload;

      return {
        ...state,
        drawer: {
          ...state.drawer,
          isReservationListOpen,
          isSchedulePreviewOpen,
        },
      };
    }
    case getType(actions.setViewSchedulePreviewVisitId): {
      const { visitId } = action.payload;

      if (visitId === state.drawer.preview.selectedVisitId) {
        return state;
      }

      return {
        ...state,
        drawer: {
          ...state.drawer,
          preview: {
            ...state.drawer.preview,
            selectedVisitId: visitId,
          },
        },
      };
    }
    case getType(actions.setViewSchedulePreviewUpdates): {
      const { updates, reset, triggerEditFlag } = action.payload;

      const isEdited = reset
        ? false
        : triggerEditFlag
          ? true
          : state.drawer.preview.isEdited;

      const visitUpdates = reset
        ? { ...updates }
        : { ...state.drawer.preview.visitUpdates, ...updates };

      return {
        ...state,
        drawer: {
          ...state.drawer,
          preview: { ...state.drawer.preview, isEdited, visitUpdates },
        },
      };
    }
    default:
      return state;
  }
}

export default viewScheduleReducer;
