import { CancellationQuestionnaire } from '@karutekun/core/salon';
import { ZodType, z } from '@karutekun/shared/util/zod';

export const cancellationQuestionnaireSchema: ZodType<CancellationQuestionnaire> =
  z
    .object({
      checkboxes: z
        .object({
          dissatisfiedWithFunctionality: z.boolean(),
          dissatisfiedWithPrice: z.boolean(),
          shopClosed: z.boolean(),
          other: z.boolean(),
        })
        .refine((data) => Object.values(data).some((v) => v === true), {
          message: '解約のご理由を1つ以上お選びください',
        }),
      otherReason: z.string().optional(),
      reasonDetail: z.string().nonempty(),
      isUseOtherService: z.enum(['yes', 'no']),
      otherServiceName: z.string().optional(),
      isUseAgain: z.enum(['reconsider', 'possible', 'difficult', 'impossible']),
      feedback: z.string().optional(),
    })
    .refine(
      ({ isUseOtherService, otherServiceName }) =>
        isUseOtherService !== 'yes' ||
        (otherServiceName ?? '').trim().length > 0,
      {
        path: ['otherServiceName'],
        message: '他のサービス名を入力してください',
      }
    );
