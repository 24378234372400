import React from 'react';

export type SimpleDialogProps = {
  title?: string;
  content?: React.ReactNode;
  onOk?(): Promise<void>;
  onCancel?(): Promise<void>;
};

export type SimpleDialogContextValue = {
  open(props: SimpleDialogProps): void;
};

export const SimpleDialogContext =
  React.createContext<SimpleDialogContextValue>({
    open: () => false,
  });

export function useSimpleDialog(): SimpleDialogContextValue {
  return React.useContext(SimpleDialogContext);
}
