import { pascalCase } from 'change-case';
import { SVGProps, memo } from 'react';
import type { PascalCase } from 'type-fest';
import * as Icons from './lib';

export type SVGName = (typeof SVGNameList)[number];

type Props = {
  name: SVGName;
  className?: string;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};

const _SVGIcon = (props: Props) => {
  const { name, ...otherProps } = props;
  const Component = Icons[pascalCase(name) as PascalCase<SVGName>];
  return <Component {...otherProps} />;
};

export const SVGIcon = memo(_SVGIcon);

export const SVGNameList = [
  'address-book',
  'address-card',
  'angle-down',
  'angle-left',
  'angle-right',
  'angle-up',
  'arrow-downward',
  'arrow-upward',
  'asterisk',
  'bars',
  'bars-fill',
  'bell',
  'birthday-cake',
  'book-open',
  'business-time',
  'calendar',
  'calendar-alt',
  'calendar-alt-fill',
  'calendar-day',
  'calendar-plus',
  'camera',
  'caret-down',
  'chalkboard-teacher',
  'chart-bar',
  'check',
  'check-circle',
  'check-circle-fill',
  'chevron-down',
  'chevron-right',
  'chevron-up',
  'circle',
  'clipboard',
  'clipboard-list',
  'cloud-download',
  'cog',
  'cogs',
  'comment-dots',
  'comment-dots-fill',
  'copy',
  'cut',
  'cut-fill',
  'daily',
  'delete',
  'desktop',
  'document',
  'document-fill',
  'drag-indicator',
  'edit',
  'ellipsis-h',
  'enter',
  'envelope',
  'exclamation-circle',
  'exclamation-triangle',
  'external-link-alt',
  'eye-slash',
  'filter',
  'gift',
  'grip-lines',
  'grip-lines-vertical',
  'group-add',
  'home',
  'home-fill',
  'image',
  'info-circle',
  'lightbulb',
  'line',
  'loading',
  'lock',
  'minus',
  'money-bill',
  'monthly',
  'pen',
  'percent',
  'phone',
  'plus',
  'plus-circle',
  'print',
  'qrcode',
  'question-circle',
  'receipt',
  'refresh',
  'reply',
  'search',
  'search-minus',
  'search-plus',
  'send',
  'share',
  'share-alt',
  'shield',
  'sort',
  'square',
  'store',
  'sync',
  'tasks',
  'telegram-plane',
  'times',
  'times-circle',
  'times-circle-fill',
  'user',
  'user-circle',
  'user-fill',
  'user-friends',
  'user-lock',
  'user-plus',
  'user-slash',
  'weekly',
  'yen-sign',
] as const;
