import { ActionType, getType } from 'typesafe-actions';
import * as actions from '../actions/uploadedPaperActions';
import { PlainUploadedPaper } from '../models/uploadedPaper';

export type UploadedPaperActions = ActionType<typeof actions>;

export interface UploadedPaperState {
  isFetching: boolean;
  plainUploadedPaperMap: IdMap<PlainUploadedPaper>;
}

const initialState: UploadedPaperState = {
  isFetching: false,
  plainUploadedPaperMap: {},
};

function uploadedPaperReducer(
  state = initialState,
  action: UploadedPaperActions
): UploadedPaperState {
  switch (action.type) {
    case getType(actions.setUploadedPaperMap): {
      const { plainUploadedPaperMap } = action.payload;
      return {
        ...state,
        plainUploadedPaperMap,
      };
    }
    case getType(actions.setUploadedPaper): {
      const { id, plainUploadedPaper } = action.payload;
      return {
        ...state,
        plainUploadedPaperMap: {
          ...state.plainUploadedPaperMap,
          [id]: {
            ...state.plainUploadedPaperMap[id],
            ...plainUploadedPaper,
          },
        },
      };
    }
    case getType(actions.setUploadedPapersIsFetching): {
      const { isFetching } = action.payload;
      return {
        ...state,
        isFetching,
      };
    }
    default:
      return state;
  }
}

export default uploadedPaperReducer;
