import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      d="M37.011 22.548C36.29 16 30.74 10.909 24 10.909c-7.23 0-13.09 5.861-13.09 13.091 0 6.74 5.091 12.289 11.638 13.011.798.088 1.452.731 1.452 1.534 0 .804-.653 1.462-1.453 1.39C14.39 39.2 8 32.347 8 24c0-8.837 7.163-16 16-16 8.347 0 15.2 6.391 15.935 14.547.072.8-.586 1.453-1.39 1.453-.803 0-1.446-.654-1.534-1.452"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgLoading = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgLoading);
export default Memo;
