type PermissionMap = {
  [key in PermissionKey]: boolean;
};
export interface PermissionRole extends PermissionMap {
  salonId: number;
  roleId: number;
  name: string;
  createdAt: number;
  updatedAt: number;
}

export const enum ReservedRoleIds {
  Admin = 1,
  SalonManager = 2,
  Stylist = 3,
}

export const PermissionKeys = [
  'canPullbackPaymentKarute',
  'canDeleteKarute',
  'canDeleteSchedule',
  'canShowPersonalData',
  'canDeleteCustomer',
  'canUpdateSalon',
  'canAcceptSalonRequest',
  'canDeactivateSalonStylist',
  'canUpdateSalonStylistAccessibility',
  'canUpdateSalonStylistRole',
  'canUpdatePermissionRole',
  'canUpdateIsTaxIncluded',
  'canUpdateMenu',
  'canDeleteMenu',
  'canUpdateCustomerOpinion',
  'canDeleteCustomerOpinion',
  'canUpdateKaruteMemoTemplate',
  'canDeleteKaruteMemoTemplate',
  'canUpdatePrinter',
  'canUpdateScheduleSetting',
  'canUpdateReservationSetting',
  'canUpdateChatSetting',
  'canShowSalesOnSchedule',
] as const;

export type PermissionKey = typeof PermissionKeys[number];
