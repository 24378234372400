import * as _ from 'lodash';
// 施術・店販分析要のmodel
export type SummaryBase = {
  totalQuantity: number;
  totalPrice: number;
  totalAdjustedPrice: number;
  totalSalesPrice: number;
  totalVoucherNum: number;
  voucherIds: number[];
};
export type StylistSummaryBase = SummaryBase & {
  totalSalesPerStylist: number;
};
export type SummaryWithStylist = {
  summary: SummaryBase;
  stylistSummary: {
    [stylistId: string]: StylistSummaryBase;
  };
};
export type ServiceSummary = SummaryWithStylist & {
  id: number; // serviceId
};

const emptySummaryBase: SummaryBase = {
  totalQuantity: 0,
  totalPrice: 0,
  totalAdjustedPrice: 0,
  totalSalesPrice: 0,
  totalVoucherNum: 0,
  voucherIds: [],
};
const emptyStylistSummaryBase: StylistSummaryBase = {
  ...emptySummaryBase,
  totalSalesPerStylist: 0,
};

function sumupSummaryBase(
  summary: SummaryBase | StylistSummaryBase,
  added: SummaryBase | StylistSummaryBase
) {
  summary.totalQuantity += added.totalQuantity;
  summary.totalPrice += added.totalPrice;
  summary.totalAdjustedPrice += added.totalAdjustedPrice;
  summary.totalSalesPrice += added.totalSalesPrice;
  summary.totalVoucherNum += added.totalVoucherNum;
  summary.voucherIds = _.uniq(summary.voucherIds.concat(added.voucherIds));

  if ('totalSalesPerStylist' in summary && 'totalSalesPerStylist' in added) {
    summary.totalSalesPerStylist += added.totalSalesPerStylist;
  }
}

export function calcTotalSummaryWithStylist(
  summaries: ServiceSummary[]
): SummaryWithStylist {
  return summaries.reduce<SummaryWithStylist>(
    (p: SummaryWithStylist, c: ServiceSummary) => {
      sumupSummaryBase(p.summary, c.summary);
      Object.keys(c.stylistSummary).forEach((stylistId) => {
        if (!p.stylistSummary[stylistId]) {
          p.stylistSummary[stylistId] = { ...emptyStylistSummaryBase };
        }
        sumupSummaryBase(
          p.stylistSummary[stylistId],
          c.stylistSummary[stylistId]
        );
      });
      return p;
    },
    { summary: { ...emptySummaryBase }, stylistSummary: {} }
  );
}

// 共通して表示に使うオブジェクトを返す
export function buildDisplaySummaryBase(c: SummaryBase) {
  return {
    totalQuantity: c.totalQuantity,
    totalPrice: c.totalPrice,
    totalAdjustedPrice: c.totalAdjustedPrice,
    totalSalesPrice: c.totalSalesPrice,
    averageAdjustedPrice:
      c.totalQuantity > 0
        ? Math.round(c.totalAdjustedPrice / c.totalQuantity)
        : 0,
    averageSalesPrice:
      c.totalQuantity > 0 ? Math.round(c.totalSalesPrice / c.totalQuantity) : 0,
    discountPercentage:
      c.totalPrice > 0 ? 100 - (100 * c.totalSalesPrice) / c.totalPrice : 0,
  };
}
