import * as React from 'react';
import CChartPie, { ChartData } from '../atoms/CChartPie';

type OwnProps = {
  visitNumMap: { [visitNum: number]: number };
};

const sumBoundary = 5;

class CChartPieVisitNum extends React.PureComponent<OwnProps> {
  render() {
    const { visitNumMap } = this.props;

    const data: { [visitNum: number]: ChartData } = {};
    for (const visitNumString in visitNumMap) {
      const visitNum = Number(visitNumString);
      if (visitNum < sumBoundary) {
        data[visitNum] = {
          id: visitNum,
          name: `${visitNum}回`,
          value: visitNumMap[visitNum],
        };
      } else {
        if (!data[sumBoundary]) {
          data[sumBoundary] = {
            id: sumBoundary,
            name: `${sumBoundary}回以上`,
            value: 0,
          };
        }
        data[sumBoundary].value += visitNumMap[visitNum];
      }
    }

    return (
      <CChartPie
        data={Object.values(data)}
        formatter={(v) => `${v}人`}
        sortDataOrder="asc"
      />
    );
  }
}

export default CChartPieVisitNum;
