import { VisitResource } from '@karutekun/core/visit';
import { trpc } from '@karutekun/shared/data-access/api-base';

export function useFetchVisit(
  id: number,
  options?: { initialData?: VisitResource }
) {
  return trpc.visit.findById.useQuery(id, options);
}
useFetchVisit.useSetQueryData = function useSetQueryData() {
  const utils = trpc.useContext();
  return utils.visit.findById.setData;
};
