import { deprecated } from 'typesafe-actions';
import { BroadcastMessageListData } from '../../reducers/types';

const { createAction } = deprecated;

enum ActionNames {
  SetBroadcastMessageList = 'view/broadcastMessage/SetBroadcastMessageList',
}

export const setBroadcastMessageList = createAction(
  ActionNames.SetBroadcastMessageList,
  (action) => {
    return (listData: Partial<BroadcastMessageListData>) =>
      action({ listData });
  }
);
