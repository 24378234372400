import { PaymentMethod, PaymentMethodIcon } from '../../models/paymentMethod';
import CAvatar from '../atoms/CAvatar';

const ImageMap = {
  [PaymentMethodIcon.Cash]: '/static/images/icons/payment_methods/cash.png',
  [PaymentMethodIcon.Cash2]: '/static/images/icons/payment_methods/cash-2.png',
  [PaymentMethodIcon.Cash3]: '/static/images/icons/payment_methods/cash-3.png',
  [PaymentMethodIcon.Cash4]: '/static/images/icons/payment_methods/cash-4.png',
  [PaymentMethodIcon.Cash5]: '/static/images/icons/payment_methods/cash-5.png',
  [PaymentMethodIcon.Card]: '/static/images/icons/payment_methods/card.png',
  [PaymentMethodIcon.Card2]: '/static/images/icons/payment_methods/card-2.png',
  [PaymentMethodIcon.Card3]: '/static/images/icons/payment_methods/card-3.png',
  [PaymentMethodIcon.Card4]: '/static/images/icons/payment_methods/card-4.png',
  [PaymentMethodIcon.Card5]: '/static/images/icons/payment_methods/card-5.png',
  [PaymentMethodIcon.Point]: '/static/images/icons/payment_methods/point.png',
  [PaymentMethodIcon.Point2]:
    '/static/images/icons/payment_methods/point-2.png',
  [PaymentMethodIcon.Point3]:
    '/static/images/icons/payment_methods/point-3.png',
  [PaymentMethodIcon.Point4]:
    '/static/images/icons/payment_methods/point-4.png',
  [PaymentMethodIcon.Point5]:
    '/static/images/icons/payment_methods/point-5.png',
  [PaymentMethodIcon.Other]: '/static/images/icons/payment_methods/etc.png',
  [PaymentMethodIcon.Other2]: '/static/images/icons/payment_methods/etc-2.png',
  [PaymentMethodIcon.Other3]: '/static/images/icons/payment_methods/etc-3.png',
  [PaymentMethodIcon.Other4]: '/static/images/icons/payment_methods/etc-4.png',
  [PaymentMethodIcon.Other5]: '/static/images/icons/payment_methods/etc-5.png',
};
const DefaultIcon = ImageMap[PaymentMethodIcon.Other];

type OwnProps = {
  paymentMethod: Pick<PaymentMethod, 'icon'>;
  size?: number;
};

const CPaymentMethodIcon: FC<OwnProps> = (props) => {
  const {
    paymentMethod: { icon },
    size = 24,
  } = props;
  const source = ImageMap[icon] ?? DefaultIcon;

  return <CAvatar size={size} src={source} />;
};

export default CPaymentMethodIcon;
