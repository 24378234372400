import { useBoolean } from '@karutekun/shared/util/react-hooks';
import {
  Alert,
  Box,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { dispatchWithErrorHandling } from '../../../../actions/helper/dispatchWithErrorHandling';
import {
  fetchReservationBasicSettings,
  updateReservationReceptionSettings,
  updateReservationSalonInfoSettings,
} from '../../../../actions/salon/reservationSettingsAction';
import { Images } from '../../../../assets/images';
import CButton from '../../../../components_old/atoms/CButton';
import CProgressOverlay from '../../../../components_old/atoms/CProgressOverlay';
import CRadioGroup from '../../../../components_old/atoms/CRadioGroup';
import CSelect from '../../../../components_old/atoms/CSelect';
import CTimePicker from '../../../../components_old/atoms/CTimePicker';
import { ReservationStylist } from '../../../../models/reservationStylist';
import { MySalon } from '../../../../models/salon';
import {
  ReservationReceptionSetting,
  ReservationSalonInfoSetting,
} from '../../../../models/salonReservationSettings';
import { checkPermission } from '../../../../models/stylist';
import {
  selectActiveReservationStylists,
  selectReservationReceptionSetting,
  selectReservationSalonInfoSetting,
} from '../../../../selectors/reservationSettingsSelector';
import {
  useSelectMe,
  useSelectMySalon,
} from '../../../../selectors/salonSelector';
import {
  WithConfirmDialog,
  withConfirmDialog,
} from '../../../../templates/hoc/ConfirmDialogHOC';
import { useFlag } from '../../../../util/hooks/useFlag';
import { useThunkDispatch } from '../../../../util/hooks/useThunkDispatch';

type Props = WithConfirmDialog;

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: theme.spacing(2),
  },
  subtitle: {
    paddingBottom: theme.spacing(2),
  },
  acceptButtonOn: {
    color: theme.palette.primary.main,
  },
  acceptButtonOff: {
    color: theme.palette.grey[900],
  },
  table: {
    width: '100%',
  },
  cell: {
    padding: theme.spacing(1),
  },
  numberInput: {
    width: 40,
  },
  saveArea: {
    textAlign: 'right',
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  isManToMan: {
    maxHeight: 256,
    width: '95%',
    objectFit: 'contain',
  },
  isManToMan2: {
    maxHeight: 512,
    width: '95%',
    objectFit: 'contain',
  },
}));

const ReservationSalonInfoSettingsCard: FC<Props> = (props) => {
  const me = useSelectMe();
  const salon = useSelectMySalon();
  const selectedSalonInfoSettings = useSelector(
    selectReservationSalonInfoSetting
  );
  const selectedReceptionSettings = useSelector(
    selectReservationReceptionSetting
  );
  const reservationStylists = useSelector(selectActiveReservationStylists);
  const dispatch = useThunkDispatch();

  const hasPermission = checkPermission(me, 'canUpdateReservationSetting');

  const [isFetching, callWithFetching] = useFlag(false);
  const [isSavingAcceptance, callWithSavingAcceptance] = useFlag(false);
  const [isSavingSalonInfo, callWithSavingSalonInfo] = useFlag(false);
  const [isSavingReception, callWithSavingReception] = useFlag(false);
  const [salonInfoUpdates, setSalonInfoUpdates] = useState<
    Partial<ReservationSalonInfoSetting>
  >({});
  const [receptionUpdates, setReceptionUpdates] = useState<
    Partial<ReservationReceptionSetting>
  >({});

  useEffect(() => {
    callWithFetching(() => dispatch(fetchReservationBasicSettings()));
  }, [callWithFetching, dispatch]);

  const salonInfoSettings: ReservationSalonInfoSetting = useMemo(
    () => ({ ...selectedSalonInfoSettings, ...salonInfoUpdates }),
    [selectedSalonInfoSettings, salonInfoUpdates]
  );
  const receptionSettings: ReservationReceptionSetting = useMemo(
    () => ({ ...selectedReceptionSettings, ...receptionUpdates }),
    [selectedReceptionSettings, receptionUpdates]
  );

  const handleChangeSalonInfo = useCallback(
    (update: Partial<ReservationSalonInfoSetting>) => {
      setSalonInfoUpdates({ ...salonInfoUpdates, ...update });
    },
    [salonInfoUpdates]
  );

  const handleChangeReception = useCallback(
    (update: Partial<ReservationReceptionSetting>) => {
      setReceptionUpdates({ ...receptionUpdates, ...update });
    },
    [receptionUpdates]
  );

  const saveReceptionSettings = useCallback(
    async (settings: Partial<ReservationReceptionSetting>) => {
      await dispatchWithErrorHandling(
        dispatch,
        updateReservationReceptionSettings(settings),
        { success: '予約受付設定を保存しました' }
      );
    },
    [dispatch]
  );

  const handleSaveAcceptance = useCallback(
    (acceptReservations: boolean) => {
      props.openConfirmDialog({
        title: '確認',
        description: acceptReservations
          ? '予約受付を開始します。本当によろしいですか？'
          : '予約受付を停止します。本当によろしいですか？',
        onOk: () => {
          props.closeConfirmDialog();
          callWithSavingAcceptance(() =>
            saveReceptionSettings({ acceptReservations })
          );
        },
      });
    },
    [callWithSavingAcceptance, props, saveReceptionSettings]
  );

  const handleSaveSalonInfo = useCallback(() => {
    callWithSavingSalonInfo(async () => {
      await dispatchWithErrorHandling(
        dispatch,
        updateReservationSalonInfoSettings(salonInfoUpdates),
        { success: 'サロン情報を保存しました' }
      );
    }).then(() => setSalonInfoUpdates({}));
  }, [callWithSavingSalonInfo, dispatch, salonInfoUpdates]);

  const handleSaveReception = useCallback(() => {
    callWithSavingReception(() => saveReceptionSettings(receptionUpdates)).then(
      () => setReceptionUpdates({})
    );
  }, [callWithSavingReception, receptionUpdates, saveReceptionSettings]);

  return (
    <div style={{ position: 'relative' }}>
      {isFetching && <CProgressOverlay />}

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <AcceptReservationsSettingCard
            hasPermission={hasPermission}
            isFetching={isSavingAcceptance}
            acceptReservations={receptionSettings.acceptReservations}
            onClickSave={handleSaveAcceptance}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SalonInfoSettingsCard
            hasPermission={hasPermission}
            salon={salon}
            salonInfoSettings={salonInfoSettings}
            isFetching={isSavingSalonInfo}
            isEdited={Object.values(salonInfoUpdates).length > 0}
            onChange={handleChangeSalonInfo}
            onClickSave={handleSaveSalonInfo}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <ReceptionSettingsCard
            hasPermission={hasPermission}
            receptionSettings={receptionSettings}
            reservationStylists={reservationStylists}
            isFetching={isSavingReception}
            isEdited={Object.values(receptionUpdates).length > 0}
            onChange={handleChangeReception}
            onClickSave={handleSaveReception}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const AcceptReservationsSettingCard: FC<{
  hasPermission: boolean;
  isFetching: boolean;
  acceptReservations: boolean;
  onClickSave(acceptReservations: boolean): void;
}> = React.memo(function AcceptReservationsSetting(props) {
  const classes = useStyles();

  const { hasPermission, isFetching, acceptReservations, onClickSave } = props;

  const handleSave = useCallback(() => {
    onClickSave(!acceptReservations);
  }, [acceptReservations, onClickSave]);

  return (
    <Card className={classes.card}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          {acceptReservations ? (
            <Alert severity="success">予約を受け付けています</Alert>
          ) : (
            <Alert severity="error">予約の受け付けを停止しています</Alert>
          )}
        </Grid>
        {hasPermission && (
          <Grid item>
            <Box
              className={
                acceptReservations
                  ? classes.acceptButtonOff
                  : classes.acceptButtonOn
              }
            >
              <CButton
                color="inherit"
                variant="outlined"
                isLoading={isFetching}
                onClick={handleSave}
              >
                {acceptReservations
                  ? '予約受付を停止する'
                  : '予約受付を開始する'}
              </CButton>
            </Box>
          </Grid>
        )}
      </Grid>
    </Card>
  );
});

const SalonInfoSettingsCard: FC<{
  salon: MySalon;
  salonInfoSettings: ReservationSalonInfoSetting;
  hasPermission: boolean;
  isFetching: boolean;
  isEdited: boolean;
  onChange(update: Partial<ReservationSalonInfoSetting>): void;
  onClickSave(): void;
}> = React.memo(function SalonInfoSettingsCard(props) {
  const classes = useStyles();

  const {
    salon,
    salonInfoSettings,
    hasPermission,
    isFetching,
    isEdited,
    onChange,
    onClickSave,
  } = props;

  return (
    <Card className={classes.card}>
      <Typography variant="body1" className={classes.subtitle}>
        基本情報
      </Typography>
      <Table className={classes.table}>
        <TableBody>
          <TableRow>
            <TableCell align="left" className={classes.cell}>
              サロン名
            </TableCell>
            <TableCell align="left" className={classes.cell}>
              {salon.name}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" className={classes.cell}>
              住所
            </TableCell>
            <TableCell align="left" className={classes.cell}>
              <TextField
                variant="standard"
                multiline
                fullWidth
                disabled={!hasPermission}
                value={salonInfoSettings.address}
                inputProps={{ maxLength: 255 }}
                onChange={(e) =>
                  onChange({
                    address: e.target.value.replace(/\n/g, ''),
                  })
                }
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" className={classes.cell}>
              電話番号
            </TableCell>
            <TableCell align="left" className={classes.cell}>
              <TextField
                variant="standard"
                fullWidth
                disabled={!hasPermission}
                value={salonInfoSettings.phone}
                inputProps={{ maxLength: 255 }}
                onChange={(e) => onChange({ phone: e.target.value })}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Box mt={2}>
        <Typography variant="body2" gutterBottom>
          予約ホーム画面に表示するメッセージ
        </Typography>
        <TextField
          fullWidth
          multiline
          variant="outlined"
          disabled={!hasPermission}
          value={salonInfoSettings.homeMessage || ''}
          inputProps={{ maxLength: 255 }}
          onChange={(e) => onChange({ homeMessage: e.target.value })}
        />
      </Box>

      <Box mt={2}>
        <Typography variant="body2" gutterBottom>
          予約確定・変更時に通知するメッセージ
        </Typography>
        <TextField
          fullWidth
          multiline
          variant="outlined"
          disabled={!hasPermission}
          value={salonInfoSettings.confirmationMessage || ''}
          inputProps={{ maxLength: 255 }}
          onChange={(e) => onChange({ confirmationMessage: e.target.value })}
        />
      </Box>

      <Box mt={2}>
        <Typography variant="body2" gutterBottom>
          予約のリマインドで通知するメッセージ
        </Typography>
        <TextField
          fullWidth
          multiline
          variant="outlined"
          disabled={!hasPermission}
          value={salonInfoSettings.remindMessage || ''}
          inputProps={{ maxLength: 255 }}
          onChange={(e) => onChange({ remindMessage: e.target.value })}
        />
      </Box>

      {hasPermission && (
        <Box mt={2}>
          <div className={classes.saveArea}>
            <CButton
              isLoading={isFetching}
              disabled={!isEdited}
              onClick={onClickSave}
            >
              保存して反映
            </CButton>
          </div>
        </Box>
      )}
    </Card>
  );
});

const ReceptionSettingsCard: FC<{
  receptionSettings: ReservationReceptionSetting;
  reservationStylists: ReservationStylist[];
  hasPermission: boolean;
  isFetching: boolean;
  isEdited: boolean;
  onChange(update: Partial<ReservationReceptionSetting>): void;
  onClickSave(): void;
}> = React.memo(function ReceptionSettings(props) {
  const classes = useStyles();
  const [showManToManDetail, showManToManDetailMutations] = useBoolean();

  const {
    receptionSettings,
    reservationStylists,
    hasPermission,
    isFetching,
    isEdited,
    onChange,
    onClickSave,
  } = props;

  const isAvoidOverbookingEditable =
    hasPermission &&
    (receptionSettings.avoidOverbooking ||
      reservationStylists.find((r) => r.isVisible));
  return (
    <Card className={classes.card}>
      <Typography variant="body1" className={classes.subtitle}>
        予約受付
      </Typography>
      <Table className={classes.table}>
        <TableBody>
          <TableRow>
            <TableCell align="left" className={classes.cell}>
              受付開始
            </TableCell>
            <TableCell align="left" className={classes.cell}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <CSelect
                    value={receptionSettings.reservableWeeksInAdvance}
                    options={new Array(12).fill(0).map((_, i) => ({
                      value: i + 1,
                      element: `${i + 1}週間`,
                    }))}
                    onChange={(weeks) => {
                      if (weeks) {
                        onChange({ reservableWeeksInAdvance: weeks });
                      }
                    }}
                    disabled={!hasPermission}
                  />
                </Grid>
                <Grid item xs>
                  <Typography variant="body2">前から受付を開始する</Typography>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" className={classes.cell}>
              受付単位
            </TableCell>
            <TableCell align="left" className={classes.cell}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <CSelect<MinuteInterval>
                    value={receptionSettings.minuteInterval}
                    options={[5, 10, 15, 20, 30, 60].map((interval) => ({
                      value: interval as MinuteInterval,
                      element: `${interval}`,
                    }))}
                    onChange={(minuteInterval) => {
                      if (minuteInterval) {
                        onChange({ minuteInterval });
                      }
                    }}
                    disabled={!hasPermission}
                  />
                </Grid>
                <Grid item xs>
                  <Typography variant="body2">分単位</Typography>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" className={classes.cell}>
              受付締切
            </TableCell>
            <TableCell align="left" className={classes.cell}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <CSelect
                    value={
                      receptionSettings.reservationTimeLimitInTheDay !== null
                        ? 0
                        : receptionSettings.reservationDeadlineDays
                    }
                    options={['当日', '1日前', '2日前', '3日前'].map(
                      (text, i) => ({ value: i, element: text })
                    )}
                    onChange={(i) => {
                      if (i === 0) {
                        // 「当日」が選択されたときは「直前〜30分前」までの設定にする
                        onChange({ reservationTimeLimitInTheDay: 0 });
                      } else {
                        // 「N日前」が選択されたとき。 i がちょうど N 日前の数字です
                        onChange({
                          reservationTimeLimitInTheDay: null,
                          reservationDeadlineDays: i,
                        });
                      }
                    }}
                    disabled={!hasPermission}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="body2">の</Typography>
                </Grid>
                <Grid item>
                  {receptionSettings.reservationTimeLimitInTheDay !== null ? (
                    <CSelect
                      value={receptionSettings.reservationTimeLimitInTheDay}
                      options={new Array(8).fill(0).map((_, i) => {
                        const text =
                          i === 0
                            ? '直前'
                            : i === 1
                              ? '30分前'
                              : `${Math.floor(i / 2)}時間${
                                  i % 2 === 1 ? '30分' : ''
                                }前`;
                        return {
                          value: i * 30,
                          element: text,
                        };
                      })}
                      onChange={(limit) => {
                        onChange({ reservationTimeLimitInTheDay: limit });
                      }}
                      disabled={!hasPermission}
                    />
                  ) : (
                    <CTimePicker
                      value={receptionSettings.reservationDeadlineTime}
                      minuteInterval={30}
                      onChange={(hhmmss) =>
                        onChange({ reservationDeadlineTime: hhmmss })
                      }
                      disabled={!hasPermission}
                      hide24
                    />
                  )}
                </Grid>
                <Grid item>
                  <Typography variant="body2">まで</Typography>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" className={classes.cell}>
              指名予約
            </TableCell>
            <TableCell align="left" className={classes.cell}>
              <CRadioGroup
                currentValue={
                  !receptionSettings.acceptFreeReservations
                    ? 2
                    : !receptionSettings.acceptShimeiReservations
                      ? 3
                      : 1
                }
                options={[
                  { value: 1, label: 'すべての予約を受け付ける' },
                  { value: 2, label: '指名のある予約のみ受け付ける' },
                  {
                    value: 3,
                    label:
                      '指名のない予約のみ受け付ける（予約ページにスタッフが掲載されません）',
                  },
                ]}
                onChange={(selectedId) => {
                  if (selectedId === 2) {
                    onChange({
                      acceptFreeReservations: false,
                      acceptShimeiReservations: true,
                    });
                  } else if (selectedId === 3) {
                    onChange({
                      acceptFreeReservations: true,
                      acceptShimeiReservations: false,
                    });
                  } else {
                    onChange({
                      acceptFreeReservations: true,
                      acceptShimeiReservations: true,
                    });
                  }
                }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" className={classes.cell} colSpan={2}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <FormControlLabel
                    disabled={!hasPermission}
                    control={
                      <Checkbox
                        checked={receptionSettings.isManToMan}
                        onChange={(_, checked) =>
                          onChange({ isManToMan: checked })
                        }
                      />
                    }
                    label="1人のスタッフが担当できる場合のみ予約を受け付ける"
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-end" spacing={1}>
                <Button
                  onClick={() =>
                    showManToManDetailMutations.set(!showManToManDetail)
                  }
                >
                  {showManToManDetail ? '詳細を閉じる' : '詳細を開く'}
                </Button>
              </Grid>
              {showManToManDetail && (
                <Grid
                  container
                  spacing={5}
                  alignItems="center"
                  className={classes.imgContainer}
                >
                  <Grid item>
                    <Typography align="center" variant="body2">
                      例: 予約台帳で10:00~12:00に予約が入っている場合
                    </Typography>
                    <img
                      src={Images.settings.reservation.isManToMan1}
                      className={classes.isManToMan}
                      alt=""
                    />
                  </Grid>
                  <Grid item>
                    <Typography align="center" variant="body2">
                      チェックありの場合、予約画面では10:00と11:00は予約できません
                    </Typography>
                    <img
                      src={Images.settings.reservation.isManToMan2}
                      className={classes.isManToMan2}
                      alt=""
                    />
                    <Typography align="center" variant="body2">
                      チェックなしの場合、サロンの残り受付可能数があるので、10:00と11:00も予約可能です
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" className={classes.cell} colSpan={2}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <FormControlLabel
                    disabled={!isAvoidOverbookingEditable}
                    control={
                      <Checkbox
                        checked={receptionSettings.avoidOverbooking}
                        onChange={(_, checked) =>
                          onChange({ avoidOverbooking: checked })
                        }
                      />
                    }
                    label="オーバーブッキングを減らす"
                  />
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    掲載スタッフごとに受付可能数や対応可能メニューを設定しているサロンでおすすめです。
                  </Typography>
                </Grid>
                <Grid item>
                  {isAvoidOverbookingEditable ? (
                    <Typography variant="body2">
                      ＊注意:
                      掲載スタッフが未設定だと、担当者未定の予約が取れなくなります。
                    </Typography>
                  ) : (
                    <Alert severity="info">
                      掲載中のスタッフがいると編集できるようになります。
                    </Alert>
                  )}
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" className={classes.cell} colSpan={2}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <FormControlLabel
                    disabled={!hasPermission}
                    control={
                      <Checkbox
                        checked={receptionSettings.sendRemindMessage}
                        onChange={(_, checked) =>
                          onChange({ sendRemindMessage: checked })
                        }
                      />
                    }
                    label="予約前にLINEでリマインド通知する"
                  />
                  <Alert
                    severity="info"
                    action={
                      <Link
                        href={process.env.REACT_APP_LINE_MESSAGE_NUM_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        詳しく
                      </Link>
                    }
                  >
                    LINEの送信メッセージ数にカウントされます
                  </Alert>
                </Grid>
              </Grid>
              {receptionSettings.sendRemindMessage && (
                <Grid container alignItems="center" spacing={1}>
                  <Grid item> リマインド時間 </Grid>
                  <Grid item>
                    <CSelect
                      value={
                        receptionSettings.remindTimeInTheDay !== null
                          ? 0
                          : receptionSettings.remindDays
                      }
                      options={['当日', '1日前', '2日前', '3日前'].map(
                        (text, i) => ({ value: i, element: text })
                      )}
                      onChange={(i) => {
                        if (i === 0) {
                          // 「当日」が選択されたときは「1時間前」の設定にする
                          onChange({ remindTimeInTheDay: 60 });
                        } else {
                          // 「N日前」が選択されたとき。 i がちょうど N 日前の数字です
                          onChange({
                            remindTimeInTheDay: null,
                            remindDays: i,
                          });
                        }
                      }}
                      disabled={!hasPermission}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">の</Typography>
                  </Grid>
                  <Grid item>
                    {receptionSettings.remindTimeInTheDay !== null ? (
                      <CSelect
                        value={receptionSettings.remindTimeInTheDay}
                        options={new Array(8).fill(0).map((_, i) => {
                          const text = `${1 + Math.floor(i / 2)}時間${
                            i % 2 === 1 ? '30分' : ''
                          }前`;
                          return {
                            value: i * 30 + 60,
                            element: text,
                          };
                        })}
                        onChange={(limit) => {
                          onChange({ remindTimeInTheDay: limit });
                        }}
                        disabled={!hasPermission}
                      />
                    ) : (
                      <CTimePicker
                        value={receptionSettings.remindTime}
                        minuteInterval={30}
                        onChange={(hhmmss) => onChange({ remindTime: hhmmss })}
                        disabled={!hasPermission}
                        hide24
                      />
                    )}
                  </Grid>
                </Grid>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {hasPermission && (
        <Box mt={2}>
          <div className={classes.saveArea}>
            <CButton
              isLoading={isFetching}
              disabled={!isEdited}
              onClick={onClickSave}
            >
              保存して反映
            </CButton>
          </div>
        </Box>
      )}
    </Card>
  );
});

export default withConfirmDialog(ReservationSalonInfoSettingsCard);
