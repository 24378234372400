import { ActionType, getType } from 'typesafe-actions';
import * as actions from '../../actions/view/viewAnalyticsServiceAction';

export type ViewAnalyticsServiceState = {
  summary: {
    isFetching: boolean;
  };
};

const initialState: ViewAnalyticsServiceState = {
  summary: {
    isFetching: false,
  },
};

export type viewAnalyticsServiceActions = ActionType<typeof actions>;

function viewAnalyticsServiceReducer(
  state: ViewAnalyticsServiceState = initialState,
  action: viewAnalyticsServiceActions
) {
  switch (action.type) {
    case getType(actions.setViewAnalyticsServiceSummary): {
      const { isFetching } = action.payload;
      return {
        ...state,
        summary: {
          ...state.summary,
          isFetching,
        },
      };
    }
    default:
      return state;
  }
}

export default viewAnalyticsServiceReducer;
