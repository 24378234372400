import { InputLabel } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

interface OwnProps {
  label?: string;
  currentValue: number | undefined;
  options: { value: number; label: string }[];
  disabled?: boolean;
  clearable?: boolean;
  row?: boolean;

  onChange(value: number): void;
}

const CRadioGroup: FC<OwnProps> = (props) => {
  const { label, currentValue, disabled, options, row } = props;
  return (
    <>
      {label && <InputLabel shrink>{label}</InputLabel>}
      <RadioGroup
        value={currentValue === undefined ? '' : currentValue.toString()}
        onChange={(e, value) => props.onChange(Number(value))}
        row={row}
      >
        {options.map((option, i) => (
          <FormControlLabel
            key={i}
            value={option.value.toString()}
            control={<Radio />}
            label={option.label}
            disabled={disabled}
          />
        ))}
      </RadioGroup>
    </>
  );
};

export default CRadioGroup;
