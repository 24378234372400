import { ServiceType, ServiceTypes } from '@karutekun/core/salon-service';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import * as React from 'react';
import { ServiceCategory } from '../../models/service';
import { DefaultIconName } from '../../models/service';
import CServiceCategoryIcon from '../atoms/CServiceCategoryIcon';

type OwnProps = {
  categories: Partial<Pick<ServiceCategory, 'type' | 'iconName' | 'name'>>[];
  emptyComponent?: React.ReactElement;
  showType?: ServiceType;
  size?: number;
  iconStyle?: React.CSSProperties;
  style?: React.CSSProperties;
};

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'row',
    },
  };
});

const defaultIconStyle = { marginRight: 3 };

const CServiceCategoryIcons: FC<OwnProps> = (props) => {
  const classes = useStyles();
  const {
    categories,
    emptyComponent,
    showType,
    size = 32,
    iconStyle = defaultIconStyle,
    style,
  } = props;

  const iconNamesByType: {
    [key in ServiceType]: string[];
  } = ServiceTypes.reduce((p, type) => {
    p[type] = _.uniq(
      categories
        .filter((c) => c.type === type)
        .map((c) => c.iconName ?? DefaultIconName)
    );
    return p;
    // TODO 一時的に lint を無効化しています。気づいたベースで直してください
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }, {} as any);

  const Icons = [];
  for (const type of ServiceTypes) {
    if (!showType || type === showType) {
      for (const iconName of iconNamesByType[type]) {
        Icons.push(
          <CServiceCategoryIcon
            key={`${type}:${iconName}`}
            size={size}
            category={{ iconName, type }}
            style={iconStyle}
          />
        );
      }
    }
  }

  return (
    <div className={classes.container} style={style}>
      {Icons.length > 0 ? Icons : emptyComponent}
    </div>
  );
};

export default CServiceCategoryIcons;
