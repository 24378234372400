import { ServiceType } from '@karutekun/core/salon-service';
import { VoucherUtils } from '@karutekun/core/voucher';
import { Button, CircularProgress, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback, useMemo } from 'react';
import CPriceLine from '../../../../components_old/atoms/CPriceLine';
import { useSelectSalonServices } from '../../../../selectors/serviceSelector';
import { formatMoney } from '../../../../util/common';
import VoucherDiscountItem from './components/VoucherDiscountItem';
import VoucherLineItem from './components/VoucherLineItem';
import { VoucherPaymentArea } from './components/VoucherPaymentArea';
import {
  VoucherDiscountForPreview,
  VoucherForPreview,
  VoucherLineForPreview,
} from './types';

type Props = {
  voucher: VoucherForPreview;
  hideTotal?: boolean;
  showPayment?: boolean;
  sortLines?: boolean;

  onClickEditLine?(lineIndex: number): void;
  onClickDeleteLine?(lineIndex: number): void;
  onClickDeleteDiscount?(discountIndex: number): void;
};

const useStyles = makeStyles((theme: Theme) => ({
  voucherLineItem: {
    marginBottom: theme.spacing(1),
  },
  buttonContainer: {
    textAlign: 'right',
  },
  totalContainer: {
    padding: theme.spacing(1),
  },
}));

export const VoucherPreview: FC<Props> = (props) => {
  const classes = useStyles();
  const {
    voucher,
    hideTotal,
    showPayment,
    sortLines = true,
    onClickEditLine,
    onClickDeleteLine,
    onClickDeleteDiscount,
  } = props;

  const { serviceMap, categoryMap } = useSelectSalonServices(voucher.salonId);

  const { menuLines, productLines } = useMemo(
    () =>
      sortLines
        ? VoucherUtils.sortVoucherLinesByServiceOrder(voucher.lines, {
            serviceMap,
            categoryMap,
          })
        : {
            menuLines: voucher.lines.filter(
              (line) => line.serviceType === ServiceType.Menu
            ),
            productLines: voucher.lines.filter(
              (line) => line.serviceType === ServiceType.Product
            ),
          },
    [categoryMap, serviceMap, sortLines, voucher.lines]
  );

  const noMenu =
    menuLines.length === 0 &&
    productLines.length === 0 &&
    voucher.discounts.length === 0;

  const renderVoucherLine = useCallback(
    (line: VoucherLineForPreview, i: number) => {
      // line オブジェクトがクローンされていないことを前提に、リファレンスの比較で実装しています
      const originalLineIndex = voucher.lines.findIndex((l) => l === line);
      return (
        <div key={i} className={classes.voucherLineItem}>
          <VoucherLineItem voucherSalonId={voucher.salonId} line={line} />
          <div className={classes.buttonContainer}>
            {onClickEditLine && (
              <Button
                size="small"
                color="secondary"
                onClick={() => onClickEditLine(originalLineIndex)}
              >
                編集
              </Button>
            )}
            {onClickDeleteLine && (
              <Button
                size="small"
                onClick={() => onClickDeleteLine(originalLineIndex)}
              >
                削除
              </Button>
            )}
          </div>
        </div>
      );
    },
    [
      classes.buttonContainer,
      classes.voucherLineItem,
      onClickDeleteLine,
      onClickEditLine,
      voucher.lines,
      voucher.salonId,
    ]
  );

  const renderVoucherDiscount = useCallback(
    (discount: VoucherDiscountForPreview, i: number) => {
      return (
        <div key={i} className={classes.voucherLineItem}>
          <VoucherDiscountItem
            voucherSalonId={voucher.salonId}
            discount={discount}
          />
          <div className={classes.buttonContainer}>
            {onClickDeleteDiscount && (
              <Button
                size="small"
                onClick={() =>
                  // discount オブジェクトがクローンされていないことを前提に、リファレンスの比較で実装しています
                  onClickDeleteDiscount(
                    voucher.discounts.findIndex((d) => d === discount)
                  )
                }
              >
                削除
              </Button>
            )}
          </div>
        </div>
      );
    },
    [
      classes.buttonContainer,
      classes.voucherLineItem,
      onClickDeleteDiscount,
      voucher.discounts,
      voucher.salonId,
    ]
  );

  if (!voucher) {
    return <CircularProgress />;
  }

  return (
    <>
      {menuLines.length > 0 && (
        <>
          <Typography variant="body2" gutterBottom>
            施術
          </Typography>
          {menuLines.map(renderVoucherLine)}
        </>
      )}
      {productLines.length > 0 && (
        <>
          <Typography variant="body2" gutterBottom>
            店販
          </Typography>
          {productLines.map(renderVoucherLine)}
        </>
      )}
      {voucher.discounts.length > 0 && (
        <>
          <Typography variant="body2" gutterBottom>
            全体割引
          </Typography>
          {voucher.discounts.map(renderVoucherDiscount)}
        </>
      )}
      {noMenu && <Typography variant="body1">メニュー未選択</Typography>}
      {!hideTotal && (
        <div className={classes.totalContainer}>
          <CPriceLine>
            <Typography variant="body1">合計</Typography>
            <Typography variant="body1">
              {formatMoney(voucher.sales)}
            </Typography>
          </CPriceLine>

          <CPriceLine>
            <Typography variant="body2" color="textSecondary">
              内消費税等
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {formatMoney(voucher.taxAmount)}
            </Typography>
          </CPriceLine>
        </div>
      )}
      {showPayment &&
        voucher.status !== undefined &&
        voucher.paymentMethods.length > 0 && (
          <VoucherPaymentArea
            salonId={voucher.salonId}
            isPaid={VoucherUtils.isPaid(voucher)}
            paymentMethods={voucher.paymentMethods}
            paymentChange={voucher.paymentChange}
            paidAt={voucher.paidAt}
          />
        )}
    </>
  );
};
