import { dayjs } from '@karutekun/shared/util/datetime';
import { zDateString } from './date-string';
import { z } from './zod';

export const zDateRange = z
  .object({ from: zDateString, to: zDateString })
  .refine(({ from, to }) => {
    // to >= from であることをチェック
    return dayjs(to).diff(from, 'day') >= 0;
  });

export const zDateRangePartial = z
  .object({ from: zDateString.optional(), to: zDateString.optional() })
  .refine(({ from, to }) => {
    if (!from && !to) {
      return false;
    }

    // to >= from であることをチェック
    if (from && to) {
      return dayjs(to).diff(from, 'day') >= 0;
    }

    return true;
  });
