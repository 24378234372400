import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M25.237 4.2a1.75 1.75 0 1 0-2.474 2.475l4.198 4.199A62.322 62.322 0 0 0 24 10.812a16.75 16.75 0 1 0 16.75 16.75 1.75 1.75 0 1 0-3.5 0A13.25 13.25 0 1 1 24 14.313c1.56 0 2.709.041 3.548.103L22.763 19.2a1.75 1.75 0 1 0 2.474 2.475l7.5-7.5a1.75 1.75 0 0 0 0-2.475z"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgRefresh = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgRefresh);
export default Memo;
