import { dayjs } from './dayjs';

/**
 * 日付の差分を返す
 * 例:
 * dateDiff('2021-01-02', '2021-01-01') // -> 1
 * dateDiff('2021-01-01', '2021-01-01') // -> 0
 * dateDiff('2021-01-01', '2021-01-02') // -> -1
 */
export function dateDiff(base: string, target: string) {
  return dayjs(base).diff(dayjs(target), 'day');
}

/**
 * base > target なら true を返す
 */
export function isDateAfter(base: string, target: string) {
  return dateDiff(base, target) > 0;
}

/**
 * base < target なら true を返す
 */
export function isDateBefore(base: string, target: string) {
  return dateDiff(base, target) < 0;
}
