import { DiscountType } from '@karutekun/core/salon-service';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import CButton from '../../../../components_old/atoms/CButton';
import CMenuButton from '../../../../components_old/atoms/CMenuButton';
import CMoneyInput from '../../../../components_old/atoms/CMoneyInput';
import CRadioGroup from '../../../../components_old/atoms/CRadioGroup';
import {
  PlainServiceDiscount,
  ServiceDiscount,
  emptyPlainServiceDiscount,
} from '../../../../models/service';
import {
  WithConfirmDialog,
  withConfirmDialog,
} from '../../../../templates/hoc/ConfirmDialogHOC';

type Props = {
  open: boolean;
  discount?: ServiceDiscount;
  disabled?: boolean;
  onClose(): void;
  onSave(discount: PlainServiceDiscount, next?: boolean): Promise<void>;
  onDelete(discountId: number): void;
} & WithConfirmDialog;

const AddEditDiscountDialog: FC<Props> = React.memo(
  function AddEditDiscountDialog(props) {
    const {
      open,
      disabled,
      onClose,
      onSave,
      onDelete,
      openConfirmDialog,
      closeConfirmDialog,
    } = props;

    const [discount, setDiscount] = useState(
      emptyPlainServiceDiscount({ ...props.discount })
    );
    // 編集時の空欄を許容したいので、 value は別管理にしている
    const [value, setValue] = useState<number | null>(
      props.discount ? props.discount.value : null
    );

    useEffect(() => {
      if (open) {
        setDiscount(emptyPlainServiceDiscount({ ...props.discount }));
        setValue(props.discount ? props.discount.value : null);
      }
    }, [open, props.discount]);

    const handleSubmit = useCallback(() => {
      if (value !== null) {
        onSave({ ...discount, value });
      }
    }, [discount, onSave, value]);

    const handleSaveAndNext = useCallback(() => {
      if (value !== null) {
        onSave({ ...discount, value }, true).then(() => {
          setDiscount(emptyPlainServiceDiscount());
        });
      }
    }, [discount, onSave, value]);

    const handleDelete = useCallback(() => {
      openConfirmDialog({
        title: '確認',
        description: `本当に割引を削除しますか？`,
        onOk: () => {
          closeConfirmDialog();
          onDelete(discount.id);
        },
      });
    }, [closeConfirmDialog, discount.id, onDelete, openConfirmDialog]);

    const isEditMode = props.discount !== undefined;
    const formId = 'add-edit-discount-dialog';
    const isValid = discount.name.length > 0 && value !== null;
    const valueLabel =
      discount.type === DiscountType.Price ? '割引額' : '割引率(%)';

    return (
      <Dialog open={open} fullWidth maxWidth="xs" onClose={onClose}>
        <DialogTitle>割引の{isEditMode ? '編集' : '追加'}</DialogTitle>
        <DialogContent>
          <ValidatorForm
            id={formId}
            instantValidate={false}
            onSubmit={handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextValidator
                  variant="standard"
                  label="割引名"
                  fullWidth
                  value={discount.name}
                  inputProps={{ maxLength: 50 }}
                  onChange={(e) =>
                    setDiscount({ ...discount, name: e.target.value })
                  }
                  validators={['required']}
                  errorMessages={['割引名は必須です']}
                  autoFocus
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12}>
                <CRadioGroup
                  label="種類"
                  currentValue={discount.type}
                  options={[
                    { value: DiscountType.Price, label: '金額' },
                    { value: DiscountType.Percentage, label: '%' },
                  ]}
                  onChange={(type) => setDiscount({ ...discount, type })}
                  row
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12}>
                {discount.type === DiscountType.Price ? (
                  <CMoneyInput
                    label={valueLabel}
                    allowNegative={false}
                    value={value ?? ''}
                    onChangeAmount={setValue}
                    disabled={disabled}
                  />
                ) : (
                  <TextValidator
                    variant="standard"
                    label={valueLabel}
                    type="number"
                    value={value ?? ''}
                    inputProps={{ maxLength: 50 }}
                    onChange={(e) => {
                      const v = e.target.value.trim();
                      setValue(v === '' ? null : Number(v));
                    }}
                    validators={['required']}
                    errorMessages={[`${valueLabel}は必須です`]}
                    disabled={disabled}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <CRadioGroup
                  label="表示"
                  currentValue={discount.isVisible ? 1 : 0}
                  options={[
                    { value: 1, label: '表示' },
                    { value: 0, label: '非表示' },
                  ]}
                  onChange={(value) =>
                    setDiscount({ ...discount, isVisible: Boolean(value) })
                  }
                  row
                  disabled={disabled}
                />
              </Grid>
            </Grid>
          </ValidatorForm>
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid item xs>
              <CButton variant="outlined" onClick={onClose}>
                キャンセル
              </CButton>
            </Grid>
            <Grid item>
              {isEditMode ? (
                <Grid container spacing={2}>
                  <Grid item>
                    <CMenuButton
                      disabled={disabled}
                      variant="text"
                      menus={[
                        {
                          title: 'この割引を削除する',
                          onClick: handleDelete,
                        },
                      ]}
                    >
                      その他
                    </CMenuButton>
                  </Grid>
                  <Grid item>
                    <CButton
                      disabled={disabled || !isValid}
                      form={formId}
                      type="submit"
                    >
                      保存
                    </CButton>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={2}>
                  <Grid item>
                    <CButton
                      disabled={disabled || !isValid}
                      type="submit"
                      form={formId}
                    >
                      作成
                    </CButton>
                  </Grid>
                  <Grid item>
                    <CButton
                      disabled={disabled || !isValid}
                      onClick={handleSaveAndNext}
                    >
                      続けて作成
                    </CButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  }
);

export default withConfirmDialog(AddEditDiscountDialog);
