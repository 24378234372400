import * as React from 'react';
import { fmtVisitNum } from '../../util/chart';
import { formatMoney } from '../../util/common';
import CChartBar from '../atoms/CChartBar';

type OwnProps = {
  salesMap: { [sales: number]: number };
};

class CChartBarSalesVolume extends React.PureComponent<OwnProps> {
  render() {
    const { salesMap } = this.props;

    const salesKeys = Object.keys(salesMap).map(Number);
    const min = Math.min(...salesKeys);
    const max = Math.max(...salesKeys);

    const data = [];
    for (let sales = min; sales <= max; sales += 1000) {
      // 描画するデータ数が多くなると描画エラーが出ていたので10万以上はデータがある場合のみに間引いておく
      if (sales > 100000 && !salesMap[sales]) {
        continue;
      }
      data.push({
        label: sales === 0 ? `${formatMoney(1000)}未満` : formatMoney(sales),
        value: salesMap[sales] || 0,
      });
    }

    return (
      <CChartBar
        data={data}
        nameKey="label"
        formatter={fmtVisitNum}
        valueKeys={[
          {
            dataKey: 'value',
            name: '回数',
            color: '#20a8d8',
          },
        ]}
        showLegend={false}
      />
    );
  }
}

export default CChartBarSalesVolume;
