import {
  getRepeatRatio,
  getVisitNum,
} from '@karutekun/core/stats/stylist-stats';
import { trpc } from '@karutekun/shared/data-access/api-base';
import { formatDateForUser } from '@karutekun/shared/util/datetime';
import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { keepPreviousData } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
import { sendRequest } from '../../../actions/request';
import CButton from '../../../components_old/atoms/CButton';
import CInformation from '../../../components_old/atoms/CInformation';
import CMoneyTypography from '../../../components_old/atoms/CMoneyTypography';
import CSortTable from '../../../components_old/molecules/CSortTable';
import { PlainStylist } from '../../../models/stylist';
import { selectStylists } from '../../../selectors/salonSelector';
import { selectAnalyticsDisplayTaxIncludedPrice } from '../../../selectors/settingsSelector';
import { InformationText } from '../../../texts/infomation';
import { useFlag } from '../../../util/hooks/useFlag';
import { useThunkDispatch } from '../../../util/hooks/useThunkDispatch';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginBottom: theme.spacing(1),
  },
}));

type StylistSummarySortKey =
  | 'name'
  | 'sales'
  | 'mainSales'
  | 'mainShimeiSales'
  | 'mainNotShimeiSales'
  | 'menuShimeiSales'
  | 'menuNotShimeiSales'
  | 'productShimeiSales'
  | 'productNotShimeiSales'
  | 'averageSalesPerCustomer'
  | 'visitNum'
  | 'repeatRatio'
  | undefined;

type Props = {
  from: string;
  to: string;
};

export const StylistStatsTable: FC<Props> = React.memo((props) => {
  const classes = useStyles();
  const dispatch = useThunkDispatch();

  const { from, to } = props;

  const [isDownloading, callWithDownloading] = useFlag();
  const [sortKey, setSortKey] = useState<StylistSummarySortKey>();
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

  const displayTaxIncludedPrice = useSelector(
    selectAnalyticsDisplayTaxIncludedPrice
  );
  const stylists = useSelector(selectStylists);

  const { isRefetching, data: stylistStatsMap = {} } =
    trpc.analytics.stylistStats.aggregatedStatsForAll.useQuery(
      { range: { from, to } },
      { placeholderData: keepPreviousData }
    );

  const handleDownload = useCallback(async () => {
    const fromStr = formatDateForUser(from);
    const toStr = formatDateForUser(to);
    callWithDownloading(() =>
      dispatch(() =>
        sendRequest(dispatch, `analytics/stylists/download_aggregated_stats`, {
          params: { from, to },
          download: {
            filename: `スタッフデータ(${fromStr}〜${toStr}).csv`,
          },
        })
      )
    );
  }, [callWithDownloading, dispatch, from, to]);

  // 来店回数かメニュー・店販売上があるスタッフのみ表示する
  const sortedStylists = useMemo(
    () =>
      stylists
        .filter((s) => {
          const stats = stylistStatsMap[s.id];
          return (
            stats &&
            (getVisitNum(stats) > 0 ||
              stats.menuShimeiSales !== 0 ||
              stats.menuNotShimeiSales !== 0 ||
              stats.sales !== 0)
          );
        })
        .sort((a: PlainStylist, b: PlainStylist) => {
          const sA = stylistStatsMap[a.id];
          const sB = stylistStatsMap[b.id];
          let order;

          switch (sortKey) {
            case 'name':
              order = a.name > b.name ? 1 : -1;
              break;
            case 'sales':
              order = displayTaxIncludedPrice
                ? sA.sales - sB.sales
                : sA.salesWithoutTax - sB.salesWithoutTax;
              break;
            case 'mainSales':
              order = displayTaxIncludedPrice
                ? sA.mainSales - sB.mainSales
                : sA.mainSalesWithoutTax - sB.mainSalesWithoutTax;
              break;
            case 'mainShimeiSales':
              order = displayTaxIncludedPrice
                ? sA.mainShimeiSales - sB.mainShimeiSales
                : sA.mainShimeiSalesWithoutTax - sB.mainShimeiSalesWithoutTax;
              break;
            case 'mainNotShimeiSales':
              order = displayTaxIncludedPrice
                ? sA.mainNotShimeiSales - sB.mainNotShimeiSales
                : sA.mainNotShimeiSalesWithoutTax -
                  sB.mainNotShimeiSalesWithoutTax;
              break;
            case 'menuShimeiSales':
              order = displayTaxIncludedPrice
                ? sA.menuShimeiSales - sB.menuShimeiSales
                : sA.menuShimeiSalesWithoutTax - sB.menuShimeiSalesWithoutTax;
              break;
            case 'menuNotShimeiSales':
              order = displayTaxIncludedPrice
                ? sA.menuNotShimeiSales - sB.menuNotShimeiSales
                : sA.menuNotShimeiSalesWithoutTax -
                  sB.menuNotShimeiSalesWithoutTax;
              break;
            case 'productShimeiSales':
              order = displayTaxIncludedPrice
                ? sA.productShimeiSales - sB.productShimeiSales
                : sA.productShimeiSalesWithoutTax -
                  sB.productShimeiSalesWithoutTax;
              break;
            case 'productNotShimeiSales':
              order = displayTaxIncludedPrice
                ? sA.productNotShimeiSales - sB.productNotShimeiSales
                : sA.productNotShimeiSalesWithoutTax -
                  sB.productNotShimeiSalesWithoutTax;
              break;
            case 'averageSalesPerCustomer':
              order = displayTaxIncludedPrice
                ? sA.averageSalesPerCustomer - sB.averageSalesPerCustomer
                : sA.averageSalesPerCustomerWithoutTax -
                  sB.averageSalesPerCustomerWithoutTax;
              break;
            case 'visitNum':
              order = getVisitNum(sA) - getVisitNum(sB);
              break;
            case 'repeatRatio':
              order = getRepeatRatio(sA) - getRepeatRatio(sB);
              break;
            default:
              return 0;
          }

          return sortOrder === 'asc' ? order : -order;
        }),
    [displayTaxIncludedPrice, sortKey, sortOrder, stylistStatsMap, stylists]
  );

  return (
    <Card>
      <CardContent>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="space-between"
            className={classes.title}
          >
            <Grid item>
              <Typography variant="h6">
                スタッフデータ
                <CInformation
                  type="dialog"
                  content={InformationText.salon.stylistGrade}
                />
              </Typography>
            </Grid>
            <Grid item>
              <CButton
                size="small"
                startIcon={<SVGIcon name="cloud-download" size="sm" />}
                onClick={handleDownload}
                isLoading={isDownloading}
              >
                CSVダウンロード
              </CButton>
            </Grid>
          </Grid>
          {isRefetching && (
            <div
              style={{
                position: 'absolute',
                zIndex: 1000,
                width: '100%',
                height: '100%',
                opacity: 0.5,
                backgroundColor: 'white',
                textAlign: 'center',
              }}
            >
              <CircularProgress style={{ margin: 30 }} />
            </div>
          )}
          <Grid item justifyContent="center">
            <CSortTable
              rows={sortedStylists.map((s) => {
                const stats = stylistStatsMap[s.id];
                return {
                  id: s.id,
                  name: s.name,
                  sales: stats ? (
                    <CMoneyTypography
                      amount={
                        displayTaxIncludedPrice
                          ? stats.sales
                          : stats.salesWithoutTax
                      }
                    />
                  ) : (
                    '-'
                  ),
                  mainSales: stats ? (
                    <CMoneyTypography
                      amount={
                        displayTaxIncludedPrice
                          ? stats.mainSales
                          : stats.mainSalesWithoutTax
                      }
                    />
                  ) : (
                    '-'
                  ),
                  mainShimeiSales: stats ? (
                    <CMoneyTypography
                      amount={
                        displayTaxIncludedPrice
                          ? stats.mainShimeiSales
                          : stats.mainShimeiSalesWithoutTax
                      }
                    />
                  ) : (
                    '-'
                  ),
                  mainNotShimeiSales: stats ? (
                    <CMoneyTypography
                      amount={
                        displayTaxIncludedPrice
                          ? stats.mainNotShimeiSales
                          : stats.mainNotShimeiSalesWithoutTax
                      }
                    />
                  ) : (
                    '-'
                  ),
                  menuShimeiSales: stats ? (
                    <CMoneyTypography
                      amount={
                        displayTaxIncludedPrice
                          ? stats.menuShimeiSales
                          : stats.menuShimeiSalesWithoutTax
                      }
                    />
                  ) : (
                    '-'
                  ),
                  menuNotShimeiSales: stats ? (
                    <CMoneyTypography
                      amount={
                        displayTaxIncludedPrice
                          ? stats.menuNotShimeiSales
                          : stats.menuNotShimeiSalesWithoutTax
                      }
                    />
                  ) : (
                    '-'
                  ),
                  productShimeiSales: stats ? (
                    <CMoneyTypography
                      amount={
                        displayTaxIncludedPrice
                          ? stats.productShimeiSales
                          : stats.productShimeiSalesWithoutTax
                      }
                    />
                  ) : (
                    '-'
                  ),
                  productNotShimeiSales: stats ? (
                    <CMoneyTypography
                      amount={
                        displayTaxIncludedPrice
                          ? stats.productNotShimeiSales
                          : stats.productNotShimeiSalesWithoutTax
                      }
                    />
                  ) : (
                    '-'
                  ),
                  averageSalesPerCustomer: stats ? (
                    <CMoneyTypography
                      amount={
                        displayTaxIncludedPrice
                          ? stats.averageSalesPerCustomer
                          : stats.averageSalesPerCustomerWithoutTax
                      }
                    />
                  ) : (
                    '-'
                  ),
                  visitNum: stats
                    ? `${getVisitNum(stats)} (${stats.mainPaidVoucherNum})`
                    : '-',
                  repeatRatio: stats ? `${getRepeatRatio(stats)} %` : '-',
                  linkTo: `/analytics/stylists/${s.id}`,
                };
              })}
              columns={{
                name: { label: '名前', align: 'left', sorting: true },
                sales: {
                  label: '総売上',
                  align: 'right',
                  sorting: true,
                },
                mainSales: {
                  label: '主担当売上',
                  align: 'right',
                  sorting: true,
                },
                mainShimeiSales: {
                  label: '主担当売上(指名有り)',
                  align: 'right',
                  sorting: true,
                },
                mainNotShimeiSales: {
                  label: '主担当売上(指名無し)',
                  align: 'right',
                  sorting: true,
                },
                menuShimeiSales: {
                  label: '施術売上(指名有り)',
                  align: 'right',
                  sorting: true,
                },
                menuNotShimeiSales: {
                  label: '施術売上(指名無し)',
                  align: 'right',
                  sorting: true,
                },
                productShimeiSales: {
                  label: '店販売上(指名有り)',
                  align: 'right',
                  sorting: true,
                },
                productNotShimeiSales: {
                  label: '店販売上(指名無し)',
                  align: 'right',
                  sorting: true,
                },
                averageSalesPerCustomer: {
                  label: '単価',
                  align: 'right',
                  sorting: true,
                },
                visitNum: {
                  label: '担当回数 (会計済み)',
                  sorting: true,
                },
                repeatRatio: { label: '固定客比率', sorting: true },
              }}
              sortKey={sortKey}
              sortOrder={sortOrder}
              onOrderChange={(sortKey, sortOrder) => {
                setSortKey(sortKey);
                setSortOrder(sortOrder);
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
});
