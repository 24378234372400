import { CustomerLoyaltySegment } from '@karutekun/core/customer';
import { UIProvider } from '@karutekun/shared-fe/react-ui-old';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider } from 'react-redux';
import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { Store } from 'redux';
import { ApiProvider } from './ApiProvider';
import ErrorBoundary from './ErrorBoundaryComponent';
import Root from './RootContainer';
import { Header } from './components/Header';
import { SideMenu } from './components/sideMenu';
import { Dashboard } from './pages';
import { Admin } from './pages/admin';
import { FirstCustomer } from './pages/analytics/customers/first';
import { LostCustomer } from './pages/analytics/customers/lost';
import { RepeatCustomer } from './pages/analytics/customers/repeat';
import Service from './pages/analytics/services';
import { Stylist } from './pages/analytics/stylists';
import { Chat } from './pages/chat';
import Customers from './pages/customers';
import { Customer } from './pages/customers/[id]';
import { Login } from './pages/login';
import { Salon } from './pages/salon';
import { Schedules } from './pages/schedules/';
import { Settings } from './pages/settings';
import { Signup } from './pages/signup/';
import { EmailVerification } from './pages/signup/email_verification';
import { Setup } from './pages/signup/setup';
import { SetupJoin } from './pages/signup/setup/join';
import { SetupNew } from './pages/signup/setup/new';
import { VisitHistories } from './pages/visit_histories';
import { VisitHistory } from './pages/visit_histories/[id]';
import EmailVerificationRequried from './templates/auth/EmailVerificationRequired';
import LoginRequired from './templates/auth/LoginRequired';
import SalonSetupRequired from './templates/auth/SalonSetupRequired';
import WrappedSnackbarProvider from './templates/providers/WrappedSnackbarProvider';
import SalonStatusProvider from './templates/providers/salonStatus/SalonStatusProvider';
import SimpleDialogProvider from './templates/providers/simpleDialog/SimpleDialogProvider';
import SnackbarNotification from './templates/snackbarNotification/containers/SnackbarNotification';

interface AppProps {
  store: Store;
}

const App: FC<AppProps> = ({ store }) => {
  return (
    <Provider store={store}>
      <ApiProvider>
        <BrowserRouter>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <UIProvider>
              <SimpleDialogProvider>
                <ErrorBoundary>
                  <WrappedSnackbarProvider>
                    <SnackbarNotification />

                    <Root>
                      <Header />
                      <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route path="/signup" element={<Signup />} />
                        <Route path="*" element={<LoggedInRoutes />} />
                      </Routes>
                    </Root>
                  </WrappedSnackbarProvider>
                </ErrorBoundary>
              </SimpleDialogProvider>
            </UIProvider>
          </LocalizationProvider>
        </BrowserRouter>

        <ReactQueryDevtools />
      </ApiProvider>
    </Provider>
  );
};

const LoggedInRoutes: FC = () => {
  return (
    <LoginRequired>
      <Routes>
        <Route
          path="/signup/email_verification"
          element={<EmailVerification />}
        />
        <Route path="*" element={<EmailVerifiedRoutes />} />
      </Routes>
    </LoginRequired>
  );
};

const EmailVerifiedRoutes: FC = () => {
  return (
    <EmailVerificationRequried>
      <Routes>
        <Route path="/signup/setup" element={<Setup />} />
        <Route path="/signup/setup/new">
          <Route path=":step" element={<SetupNew />} />
          <Route path="" element={<SetupNew />} />
        </Route>
        <Route path="/signup/setup/new/:step" element={<SetupNew />} />
        <Route path="/signup/setup/join" element={<SetupJoin />} />
        <Route path="*" element={<MainRoutes />} />
      </Routes>
    </EmailVerificationRequried>
  );
};

const MainRoutes: FC = () => {
  return (
    <SalonSetupRequired>
      <SalonStatusProvider>
        <div style={{ display: 'flex' }}>
          <SideMenu />
          <div style={{ flex: 1, minWidth: 0, minHeight: 0 }}>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/customers" element={<Customers />} />
              <Route path="/customers/:customerId" element={<Customer />} />
              <Route path="/visit_histories" element={<VisitHistories />} />
              <Route
                path="/visit_histories/:visitId"
                element={<VisitHistory />}
              />
              <Route path="/schedules" element={<Schedules />} />
              <Route path="/chat/*" element={<Chat />} />
              <Route path="/salon" element={<Salon />} />
              <Route
                path="/analytics/stylists/:stylistId"
                element={<Stylist />}
              />
              <Route
                path="/analytics/customers/repeat"
                element={<RepeatCustomer />}
              />
              <Route
                path="/analytics/customers/repeatlost"
                element={
                  <LostCustomer
                    loyaltySegment={CustomerLoyaltySegment.RepeatLost}
                  />
                }
              />
              <Route
                path="/analytics/customers/newlost"
                element={
                  <LostCustomer
                    loyaltySegment={CustomerLoyaltySegment.NewLost}
                  />
                }
              />
              <Route
                path="/analytics/customers/first"
                element={<FirstCustomer />}
              />
              <Route path="/analytics/services" element={<Service />} />
              <Route path="/settings/*" element={<Settings />} />
              <Route path="/admin/*" element={<Admin />} />
            </Routes>
          </div>
        </div>
      </SalonStatusProvider>
    </SalonSetupRequired>
  );
};

export default App;
