import { VoucherResource } from '@karutekun/core/voucher';
import { trpc } from '@karutekun/shared/data-access/api-base';
import { useInvalidateVisits } from '@karutekun/shared/data-access/visit';
import { objectValues } from '@karutekun/shared/util/objects';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { pushSnackbarSuccess } from '../../../../actions/generalAction';
import CBackdrop from '../../../../components_old/atoms/CBackdrop';
import CMoneyInput from '../../../../components_old/atoms/CMoneyInput';
import CTextBox from '../../../../components_old/atoms/CTextBox';
import CPaymentMethodIcon from '../../../../components_old/molecules/CPaymentMethodIcon';
import { useSelectPaymentMethods } from '../../../../selectors/salonSelector';
import { formatMoney } from '../../../../util/common';
import { useThunkDispatch } from '../../../../util/hooks/useThunkDispatch';
import { useWidthDown } from '../../../../util/hooks/useWidth';
import { VoucherPreview } from '../VoucherPreview';

type Props = {
  open: boolean;
  voucher: VoucherResource;
  onClose(): void;
};

const useStyles = makeStyles((theme: Theme) => ({
  borderRight: {
    borderRight: `1px solid ${theme.palette.divider}`,
    paddingRight: theme.spacing(3),
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  paymentMethodContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  paymentMethodTitle: {
    marginLeft: theme.spacing(1),
  },
  paymentMethodTitleContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  paymentIconContainer: {
    minWidth: 30,
  },
}));

export const VoucherPaymentDialog: FC<Props> = (props) => {
  const classes = useStyles();
  const { open, onClose, voucher } = props;

  const paymentMethods = useSelectPaymentMethods(voucher.salonId, {
    includePaymentMethodIds: voucher.paymentMethods.map(
      (pm) => pm.paymentMethodId
    ),
  });

  const [paymentMap, setPaymentMap] = useState<{
    [paymentMethodId: number]: number;
  }>(
    voucher.paymentMethods.reduce((prev, pm) => {
      // TODO 一時的にルールを無効化しています。気づいたベースで直してください
      // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'number' can't be used to index type '{}'.
      prev[pm.paymentMethodId] = pm.amount;
      return prev;
    }, {})
  );

  const paidAmount = useMemo(
    () => objectValues(paymentMap).reduce((p, c) => p + c, 0),
    [paymentMap]
  );

  const dispatch = useThunkDispatch();
  const invalidateVisits = useInvalidateVisits();
  const { mutate: payment, isPending } = trpc.voucher.payment.useMutation({
    async onSuccess(data, variables) {
      const msg = variables.isDraft ? '下書き保存しました' : '会計しました';
      await invalidateVisits();
      dispatch(pushSnackbarSuccess(msg));
      onClose();
    },
  });

  const handleClickPay = async (isDraft: boolean) => {
    if (voucher) {
      payment({
        id: voucher.id,
        paymentMethods: Object.entries(paymentMap)
          .map(([paymentMethodId, amount]) => {
            return {
              paymentMethodId: Number(paymentMethodId),
              amount: Number(amount),
            };
          })
          .filter(({ amount }) => amount !== 0),
        paymentChange: -remainingAmount,
        isDraft,
      });
    }
  };

  const isVertical = useWidthDown('sm');

  if (!voucher) {
    return null;
  }

  const remainingAmount = voucher.sales - paidAmount;

  // 通常のお会計の場合は残金が0以下になるべき
  // 返金の場合(sales < 0)はピッタリになるべき
  const isRefund = voucher.sales < 0;
  const canPay = isRefund ? remainingAmount === 0 : remainingAmount <= 0;
  const canSaveDraft = paidAmount !== 0;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={isVertical}
      maxWidth="md"
      fullWidth
    >
      <CBackdrop open={isPending} />
      <DialogTitle>お会計</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            sm={6}
            className={clsx({
              [classes.borderRight]: !isVertical,
              [classes.borderBottom]: isVertical,
            })}
          >
            <VoucherPreview voucher={voucher} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" gutterBottom>
              お支払い
            </Typography>
            {paymentMethods.map((pm) => {
              const amount = paymentMap[pm.id] ?? 0;

              const showAdjust = !canPay && amount === 0;
              const showReset = !showAdjust && amount !== 0;

              return (
                <div key={pm.id} className={classes.paymentMethodContainer}>
                  <div className={classes.paymentMethodTitleContainer}>
                    <CPaymentMethodIcon paymentMethod={pm} size={20} />
                    <Typography
                      variant="body1"
                      className={classes.paymentMethodTitle}
                    >
                      {pm.name}
                    </Typography>
                  </div>
                  {showAdjust && (
                    <Button
                      size="small"
                      color="primary"
                      onClick={() =>
                        setPaymentMap({
                          ...paymentMap,
                          [pm.id]: remainingAmount,
                        })
                      }
                    >
                      ぴったり
                    </Button>
                  )}
                  {showReset && (
                    <Button
                      size="small"
                      color="primary"
                      onClick={() =>
                        setPaymentMap({
                          ...paymentMap,
                          [pm.id]: 0,
                        })
                      }
                    >
                      リセット
                    </Button>
                  )}
                  <CMoneyInput
                    style={{ width: 80 }}
                    value={amount}
                    onChangeAmount={(amount) =>
                      setPaymentMap({
                        ...paymentMap,
                        [pm.id]: Number(amount),
                      })
                    }
                    clearZeroOnFocus
                  />
                </div>
              );
            })}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container direction="column" alignItems="flex-end" spacing={1}>
          <Grid item xs={12}>
            <CTextBox type={canPay ? 'info' : 'error'}>
              {isRefund ? (
                !canPay ? (
                  <Typography>返金額が一致しません</Typography>
                ) : (
                  <Typography>
                    {formatMoney(-voucher.sales)} 返金します
                  </Typography>
                )
              ) : !canPay ? (
                <Typography>
                  未会計残金
                  {formatMoney(remainingAmount)}
                </Typography>
              ) : (
                <Typography>
                  おつり
                  {formatMoney(-remainingAmount)}
                </Typography>
              )}
            </CTextBox>
          </Grid>
          <Grid item xs={12}>
            <Box mr={3} display="inline">
              <Button variant="text" color="primary" onClick={onClose}>
                キャンセル
              </Button>
            </Box>
            <Box mr={1} display="inline">
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleClickPay(true)}
                disabled={!canSaveDraft}
              >
                下書き保存
              </Button>
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleClickPay(false)}
              disabled={!canPay}
            >
              会計する
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
