import { createSelector } from 'reselect';
import { GlobalState } from '../../store';

export const selectScheduleDrawerState = createSelector(
  (state: GlobalState) => state.view.schedule.drawer,
  (drawer) => {
    return {
      isReservationListOpen: drawer.isReservationListOpen,
      isSchedulePreviewOpen: drawer.isSchedulePreviewOpen,
    };
  }
);

export const selectScheduleDrawerPreview = (state: GlobalState) =>
  state.view.schedule.drawer.preview;
