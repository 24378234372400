import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import { ButtonProps, Menu, MenuItem } from '@mui/material';
import React from 'react';
import CButton from './CButton';

type OwnProps = ButtonProps & {
  menus: {
    title: string;
    onClick(): void;
  }[];
};

const CMenuButton: FC<OwnProps> = (props) => {
  const { menus, children, ...etc } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <div>
      <CButton
        endIcon={<SVGIcon name="caret-down" />}
        {...etc}
        onClick={handleClick}
      >
        {children}
      </CButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menus.map((menu, i) => (
          <MenuItem
            key={i}
            onClick={() => {
              handleClose();
              menu.onClick();
            }}
          >
            {menu.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default CMenuButton;
