import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      d="M38.92 31.1 27.35 24l11.57-7.1c.82-.5 1.08-1.58.58-2.41a1.76 1.76 0 0 0-2.41-.58l-11.33 6.96V7.57c0-.97-.78-1.75-1.75-1.75s-1.75.78-1.75 1.75v13.3l-11.33-6.96c-.82-.51-1.9-.25-2.41.58-.51.82-.25 1.9.58 2.41L20.67 24 9.1 31.1a1.76 1.76 0 0 0-.58 2.41c.33.54.9.83 1.49.83.31 0 .63-.08.91-.26l11.33-6.96v13.3c0 .97.78 1.75 1.75 1.75s1.75-.78 1.75-1.75v-13.3l11.33 6.96c.29.18.6.26.91.26a1.747 1.747 0 0 0 .91-3.24z"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgAsterisk = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgAsterisk);
export default Memo;
