import { deprecated } from 'typesafe-actions';

const { createAction } = deprecated;

enum ActionNames {
  SetViewAutoCompleteCustomerData = 'view/autoComplete/SetAutoCompleteCustomerData',
}

export const setViewAutoCompleteCustomerData = createAction(
  ActionNames.SetViewAutoCompleteCustomerData,
  (action) => {
    return (data: {
      customerIds?: number[];
      hasMore?: boolean;
      isFetching?: boolean;
    }) => action({ data });
  }
);
