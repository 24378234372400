import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import {
  Alert,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { fetchBroadcastMessages } from '../../../actions/broadcastMessageAction';
import { dispatchWithErrorHandling } from '../../../actions/helper/dispatchWithErrorHandling';
import { setBroadcastMessageList } from '../../../actions/view/viewBroadcastMessageAction';
import CButton from '../../../components_old/atoms/CButton';
import CHorizontalScrollHintContainer from '../../../components_old/atoms/CHorizontalScrollHintContainer';
import CInformation from '../../../components_old/atoms/CInformation';
import CTextBox from '../../../components_old/atoms/CTextBox';
import { checkPermission } from '../../../models/stylist';
import {
  useSelectMe,
  useSelectMySalon,
} from '../../../selectors/salonSelector';
import { selectBroadcastMessages } from '../../../selectors/view/viewBroadcastMessageSelector';
import { GlobalState } from '../../../store';
import { useSalonStatus } from '../../../templates/providers/salonStatus/salonStatusContext';
import { formatDate } from '../../../util/common';
import { useQueryParams } from '../../../util/hooks/router/useQueryParams';
import { useThunkDispatch } from '../../../util/hooks/useThunkDispatch';
import { BroadcastMessageCustomerFilterChips } from './_components/BroadcastMessageForm/BroadcastMessageCustomerFilterChips';
import { BroadcastMessageStateChip } from './_components/BroadcastMessageStatusTable/BroadcastMessageStateChip';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '76px 20px 20px 20px',
  },
  cellNospace: {
    padding: 0,
  },
  cellTitleLink: {
    display: 'block',
    textDecoration: 'none',
    minWidth: 100,
    padding: theme.spacing(2),
  },
}));

const rowsPerPage = 20;

export const BroadcastMessages: FC = () => {
  const classes = useStyles();

  const listData = useSelector(
    (state: GlobalState) => state.view.broadcastMessage.broadcastMessageList
  );
  const salon = useSelectMySalon();
  const me = useSelectMe();
  const count = listData.count;
  const broadcastMessages = useSelector(selectBroadcastMessages);
  const sentMessageNum = salon.monthlyChatStatistics?.sentMessageNum ?? 0;
  const dispatch = useThunkDispatch();

  const { checkAndOpenDialog } = useSalonStatus();
  const navigate = useNavigate();
  const params = useQueryParams();
  const page = Number(params.get('p'));

  useEffect(() => {
    const fetch = async (page: number, rowsPerPage: number) => {
      const result = await dispatchWithErrorHandling(
        dispatch,
        fetchBroadcastMessages({
          limit: rowsPerPage,
          offset: page * rowsPerPage,
        })
      );
      if (result) {
        dispatch(
          setBroadcastMessageList({
            ids: result.broadcastMessages.map((bm) => bm.id),
            count: result.count,
          })
        );
      }
    };

    fetch(page, rowsPerPage);
  }, [dispatch, page]);

  const hasPermission = checkPermission(me, 'canUpdateChatSetting');

  const handleClickCreate = useCallback(() => {
    if (!checkAndOpenDialog('line')) {
      return;
    }

    navigate('/chat/broadcast_messages/new');
  }, [checkAndOpenDialog, navigate]);

  const renderPagination = useCallback(() => {
    return (
      <TablePagination
        count={count}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
        // 読み込み前に先のページを与えると warning が吐き出される
        page={count === 0 ? 0 : page}
        onPageChange={(_, page) =>
          navigate(`/chat/broadcast_messages?p=${page}`)
        }
        labelDisplayedRows={({ from, to, count }) =>
          `${from} - ${to} (${count} 件中)`
        }
      />
    );
  }, [navigate, count, page]);

  return (
    <div className={classes.root}>
      <Typography variant="h6" gutterBottom>
        一斉配信
      </Typography>

      <Box mt={2} mb={2}>
        {hasPermission ? (
          <CButton
            variant="contained"
            color="primary"
            size="large"
            startIcon={<SVGIcon name="plus" />}
            onClick={handleClickCreate}
          >
            一斉配信メッセージを作成する
          </CButton>
        ) : (
          <Alert severity="warning">一斉配信の編集権限がありません</Alert>
        )}
      </Box>

      <Card>
        <CTextBox>
          今月のメッセージ送信数: {sentMessageNum} 通
          <CInformation
            type="tooltip"
            content={
              '今月送信したメッセージ数の目安です。\n配信予定のメッセージ数は含まれていません。'
            }
          />
        </CTextBox>
        {renderPagination()}
        <CHorizontalScrollHintContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">名前</TableCell>
                <TableCell align="center">配信ターゲット</TableCell>
                <TableCell align="center">配信状況</TableCell>
                <TableCell align="center">配信日時</TableCell>
                <TableCell align="center">作成日時</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {broadcastMessages.map((bm) => (
                <TableRow key={bm.id} hover>
                  <TableCell className={classes.cellNospace}>
                    <Link
                      to={`/chat/broadcast_messages/${bm.id}`}
                      className={classes.cellTitleLink}
                    >
                      <Typography variant="body1" color="textPrimary">
                        {bm.name}
                      </Typography>
                    </Link>
                  </TableCell>
                  <TableCell align="center">
                    <BroadcastMessageCustomerFilterChips
                      justifyContent="center"
                      filter={bm.customerFilter}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <BroadcastMessageStateChip state={bm.state} />
                  </TableCell>
                  <TableCell align="center">
                    {bm.publishedAt ? formatDate(bm.publishedAt, true) : '-'}
                  </TableCell>
                  <TableCell align="center">
                    {formatDate(bm.createdAt, true)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {broadcastMessages.length === 0 && (
            <CTextBox p={2}>
              <Typography variant="body1">
                一斉配信メッセージがありません
              </Typography>
            </CTextBox>
          )}
        </CHorizontalScrollHintContainer>
        {renderPagination()}
      </Card>
    </div>
  );
};
