import {
  PermissionRole,
  PermissionRoleEdit,
} from '@karutekun/core/permission-role';
import { Dispatch } from 'redux';
import { sendRequest } from '../request';
import { setSalonPermissionRole } from '../salonAction';

export function updatePermissionRole(roleId: number, role: PermissionRoleEdit) {
  return async function (dispatch: Dispatch) {
    const json = await sendRequest(
      dispatch,
      `salons/settings/permission_roles/${roleId}`,
      {
        method: 'POST',
        body: JSON.stringify({ salonPermissionRole: role }),
      }
    );

    const updated: PermissionRole = json;
    dispatch(setSalonPermissionRole(roleId, updated));

    return updated;
  };
}

export function createPermissionRole(role: PermissionRoleEdit) {
  return async function (dispatch: Dispatch) {
    const json = await sendRequest(
      dispatch,
      'salons/settings/permission_roles',
      {
        method: 'POST',
        body: JSON.stringify({ salonPermissionRole: role }),
      }
    );

    const created: PermissionRole = json;
    dispatch(setSalonPermissionRole(created.roleId, created));

    return created;
  };
}
