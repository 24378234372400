import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M23.188 6.641a1.75 1.75 0 0 0-1.896.343l-16.5 15.75a1.75 1.75 0 0 0 0 2.532l16.5 15.75a1.75 1.75 0 0 0 2.958-1.266v-7.22c4.322.154 7.273.894 9.58 2.086 2.604 1.347 4.564 3.36 6.79 6.211a1.75 1.75 0 0 0 3.13-1.077c0-5.726-.94-11.774-4.136-16.432-3.045-4.438-7.971-7.375-15.364-7.772V8.25c0-.7-.418-1.333-1.062-1.609M20.75 12.34v4.91c0 .966.784 1.75 1.75 1.75 7.417 0 11.706 2.622 14.228 6.299 1.8 2.623 2.794 5.916 3.236 9.499-1.339-1.288-2.802-2.399-4.527-3.29C32.159 29.812 28.117 29 22.5 29a1.75 1.75 0 0 0-1.75 1.75v4.91L8.534 24z"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgReply = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgReply);
export default Memo;
