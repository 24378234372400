import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M3.25 24C3.25 12.54 12.54 3.25 24 3.25S44.75 12.54 44.75 24 35.46 44.75 24 44.75 3.25 35.46 3.25 24M24 6.75C14.48 6.75 6.75 14.48 6.75 24S14.48 41.25 24 41.25 41.25 33.52 41.25 24 33.52 6.75 24 6.75m-.02 5.5c.31 0 .62.06.91.18.29.12.55.3.76.52.22.22.38.49.49.78.11.29.16.6.15.91l-.54 11.44A1.75 1.75 0 0 1 24 27.75c-.93 0-1.7-.73-1.75-1.67l-.54-11.44a2.274 2.274 0 0 1 .62-1.67c.21-.22.47-.4.75-.52s.59-.19.89-.19zM24 34.99c-.47 0-.93-.14-1.32-.4s-.69-.63-.87-1.07c-.18-.43-.23-.91-.14-1.37.09-.46.32-.88.65-1.22.33-.33.76-.56 1.22-.65.46-.09.94-.04 1.37.14.43.18.8.48 1.07.87a2.37 2.37 0 0 1-.3 3c-.45.45-1.05.7-1.68.7"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgExclamationCircle = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgExclamationCircle);
export default Memo;
