import {
  Analytics,
  getAnalytics,
  isSupported,
  logEvent,
  setCurrentScreen,
  setUserId,
  setUserProperties,
} from 'firebase/analytics';
import firebaseApp from './libs/firebase';

// 循環参照を回避するためにloggerを使わない
const env = process.env.REACT_APP_ENV;
const outputInfo = env === 'local' || env === 'dev';

class FirebaseAnalytics {
  analytics: Analytics | undefined = undefined;

  async initialize() {
    let logText = '';

    if (await isSupported()) {
      this.analytics = getAnalytics(firebaseApp);
      logText = '[FirebaseAnalytics] Initialize';
    } else {
      logText = '[FirebaseAnalytics] not supported environment';
    }

    if (outputInfo) {
      console.info(logText);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logEvent(name: string, props?: Record<string, any>) {
    if (!this.analytics) {
      return;
    }

    logEvent(this.analytics, name, props);
  }

  setUserId(id?: number) {
    if (!this.analytics) {
      return;
    }

    setUserId(this.analytics, id ? `${id}` : '');
  }

  setUserProperties(props: Record<string, string | null>) {
    if (!this.analytics) {
      return;
    }

    setUserProperties(this.analytics, props);
  }

  setCurrentScreen(screen: string) {
    if (!this.analytics) {
      return;
    }

    setCurrentScreen(this.analytics, screen);
  }
}

export default new FirebaseAnalytics();
