import {
  IService,
  IServiceCategory,
  ServiceType,
} from '@karutekun/core/salon-service';
import { notEmpty } from '@karutekun/shared/util/objects';
import { sortVoucherLinesByServiceOrder } from './sort-voucher-lines-by-service-order';

/**
 * voucher.lines から IService を抽出する
 */
export function extractVoucherLineServices<
  Line extends { serviceId: number; serviceType: ServiceType },
  ServiceLike extends Pick<IService, 'categoryId' | 'order'>,
  CategoryLike extends Pick<IServiceCategory, 'order'>
>(
  lines: Line[],
  {
    serviceMap,
    categoryMap,
  }: { serviceMap: IdMap<ServiceLike>; categoryMap: IdMap<CategoryLike> },
  serviceType?: ServiceType
): ServiceLike[] {
  const { menuLines, productLines } = sortVoucherLinesByServiceOrder(lines, {
    serviceMap,
    categoryMap,
  });

  // serviceType が指定されている場合は、その種別のみを抽出する
  // そうでない場合は、全ての種別を抽出する
  const list = serviceType
    ? serviceType === ServiceType.Menu
      ? menuLines
      : productLines
    : [...menuLines, ...productLines];

  return list.map((line) => serviceMap[line.serviceId]).filter(notEmpty);
}
