import { deprecated } from 'typesafe-actions';
import { CustomerListData } from '../../reducers/types';

const { createAction } = deprecated;

enum ActionNames {
  SetViewSelectCustomerList = 'view/selectCustomer/SetCustomerList',
}

export const setViewSelectCustomerList = createAction(
  ActionNames.SetViewSelectCustomerList,
  (action) => {
    return (listData: Partial<CustomerListData>) => action({ listData });
  }
);
