export enum ScheduleDisplayMode {
  Daily,
  Weekly,
  Monthly,
}

export type ScheduleDisplayFilter = {
  hiddenStylistIds: number[];
  showNonAssignedReserve: boolean;
};

export const NonAssignedReservationColor = '#aaa';
