import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M6.25 19.25v-13h13v13zM6 2.75A3.25 3.25 0 0 0 2.75 6v13.5A3.25 3.25 0 0 0 6 22.75h13.5a3.25 3.25 0 0 0 3.25-3.25V6a3.25 3.25 0 0 0-3.25-3.25zm22.75 16.5v-13h13v13zm-.25-16.5A3.25 3.25 0 0 0 25.25 6v13.5a3.25 3.25 0 0 0 3.25 3.25H42a3.25 3.25 0 0 0 3.25-3.25V6A3.25 3.25 0 0 0 42 2.75zM38.25 9h-6a.75.75 0 0 0-.75.75v6c0 .414.336.75.75.75h6a.75.75 0 0 0 .75-.75v-6a.75.75 0 0 0-.75-.75M31.5 26.25a.75.75 0 0 0-.75-.75h-4.5a.75.75 0 0 0-.75.75v4.5c0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75zm6.75 5.25h-6a.75.75 0 0 0-.75.75v6c0 .414.336.75.75.75h6a.75.75 0 0 0 .75-.75v-6a.75.75 0 0 0-.75-.75m.75 8.25a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-.75.75h-4.5a.75.75 0 0 1-.75-.75zm5.25-14.25h-3a.75.75 0 0 0-.75.75v3c0 .414.336.75.75.75h3a.75.75 0 0 0 .75-.75v-3a.75.75 0 0 0-.75-.75m-18 15h3a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-.75.75h-3a.75.75 0 0 1-.75-.75v-3a.75.75 0 0 1 .75-.75M15.75 9h-6a.75.75 0 0 0-.75.75v6c0 .414.336.75.75.75h6a.75.75 0 0 0 .75-.75v-6a.75.75 0 0 0-.75-.75m0 22.5h-6a.75.75 0 0 0-.75.75v6c0 .414.336.75.75.75h6a.75.75 0 0 0 .75-.75v-6a.75.75 0 0 0-.75-.75m-9.5 10.25v-13h13v13zM6 25.25a3.25 3.25 0 0 0-3.25 3.25V42A3.25 3.25 0 0 0 6 45.25h13.5A3.25 3.25 0 0 0 22.75 42V28.5a3.25 3.25 0 0 0-3.25-3.25z"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgQrcode = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgQrcode);
export default Memo;
