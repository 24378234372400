import { unixToDate } from '@karutekun/shared/util/datetime';

/**
 * 予約の日時が変更されたときに、お客様へのリマンドを再度送信するかどうか
 */
export function shouldResendReservationReminderToCustomer(
  beforeStartedAt: number,
  afterStartedAt: number
) {
  // 施術日時が未来の日にちに変更されたときは、再度リマインドを送信することとする
  const beforeDate = unixToDate(beforeStartedAt);
  const afterDate = unixToDate(afterStartedAt);
  return beforeStartedAt < afterStartedAt && beforeDate !== afterDate;
}
