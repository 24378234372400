import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M13.75 4.5a1.75 1.75 0 1 0-3.5 0v1.25H9A6.25 6.25 0 0 0 2.75 12v27A6.25 6.25 0 0 0 9 45.25h30A6.25 6.25 0 0 0 45.25 39V12A6.25 6.25 0 0 0 39 5.75h-1.25V4.5a1.75 1.75 0 1 0-3.5 0v1.25h-20.5zM36 9.25H9A2.75 2.75 0 0 0 6.25 12v1.25h35.5V12A2.75 2.75 0 0 0 39 9.25zM6.25 39V16.75h35.5V39A2.75 2.75 0 0 1 39 41.75H9A2.75 2.75 0 0 1 6.25 39M30 21.75a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0m7.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0m-9.75 9.75a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5m9.75-2.25a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0M12.75 31.5a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5m9.75-2.25a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0M12.75 39a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5m9.75-2.25a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0M27.75 39a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgCalendarAlt = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgCalendarAlt);
export default Memo;
