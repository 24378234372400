import { ServiceType, ServiceTypeName } from '@karutekun/core/salon-service';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import CButton from '../../../../components_old/atoms/CButton';
import CMenuButton from '../../../../components_old/atoms/CMenuButton';
import CRadioGroup from '../../../../components_old/atoms/CRadioGroup';
import CServiceCategoryIcon, {
  MenuCategoryIconNames,
  ProductCategoryIconNames,
} from '../../../../components_old/atoms/CServiceCategoryIcon';
import {
  DefaultIconName,
  PlainServiceCategory,
  ServiceCategory,
  emptyPlainServiceCategory,
} from '../../../../models/service';
import {
  WithConfirmDialog,
  withConfirmDialog,
} from '../../../../templates/hoc/ConfirmDialogHOC';

type Props = {
  open: boolean;
  type?: ServiceType;
  category?: ServiceCategory;
  onClose(): void;
  onSave(category: PlainServiceCategory, next?: boolean): Promise<void>;
  onDelete(categoryId: number): void;
} & WithConfirmDialog;

const useStyles = makeStyles((theme: Theme) => ({
  categoryIconList: {
    borderRadius: 4,
    border: `1px solid ${theme.palette.divider}`,
  },
  categoryIconListItem: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
    'cursor': 'pointer',
  },
  categoryIconListItemSelected: {
    backgroundColor: theme.palette.grey[300],
  },
}));

const AddEditCategoryDialog: FC<Props> = React.memo(
  function AddEditCategoryDialog(props) {
    const classes = useStyles();

    const {
      open,
      type,
      onClose,
      onSave,
      onDelete,
      openConfirmDialog,
      closeConfirmDialog,
    } = props;

    const [category, setCategory] = useState<PlainServiceCategory>(
      emptyPlainServiceCategory({
        type,
        iconName: DefaultIconName,
        ...props.category,
      })
    );

    useEffect(() => {
      if (open) {
        setCategory(
          emptyPlainServiceCategory({
            type,
            iconName: DefaultIconName,
            ...props.category,
          })
        );
      }
    }, [open, props.category, type]);

    const handleSubmit = useCallback(
      () => onSave(category),
      [category, onSave]
    );

    const handleSaveAndNext = useCallback(() => {
      onSave(category, true).then(() => {
        setCategory(
          emptyPlainServiceCategory({
            type: category.type,
            iconName: DefaultIconName,
          })
        );
      });
    }, [category, onSave]);

    const handleDelete = useCallback(() => {
      openConfirmDialog({
        title: '確認',
        description: `本当にカテゴリを削除しますか？\nカテゴリを削除すると、カテゴリ内の${
          ServiceTypeName[category.type]
        }がすべて削除されます。`,
        onOk: () => {
          closeConfirmDialog();
          onDelete(category.id);
        },
      });
    }, [closeConfirmDialog, onDelete, openConfirmDialog, category]);

    const isEditMode = props.category !== undefined;
    const formId = 'add-edit-category-dialog';
    const isValid = category.name.length > 0;

    return (
      <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose}>
        <DialogTitle>
          {ServiceTypeName[category.type]}カテゴリの
          {isEditMode ? '編集' : '追加'}
        </DialogTitle>
        <DialogContent>
          <ValidatorForm
            id={formId}
            instantValidate={false}
            onSubmit={handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextValidator
                  variant="standard"
                  label="カテゴリ名"
                  fullWidth
                  value={category.name}
                  inputProps={{ maxLength: 50 }}
                  onChange={(e) =>
                    setCategory({ ...category, name: e.target.value })
                  }
                  validators={['required']}
                  errorMessages={['名前は必須です']}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel shrink>アイコン</InputLabel>
                <Box mt={1} mb={1}>
                  <CServiceCategoryIcon category={category} />
                  <Box mt={1} p={1} className={classes.categoryIconList}>
                    <Grid container spacing={1}>
                      {(category.type === ServiceType.Menu
                        ? MenuCategoryIconNames
                        : ProductCategoryIconNames
                      ).map((iName) => (
                        <Grid
                          item
                          key={iName}
                          className={clsx(classes.categoryIconListItem, {
                            [classes.categoryIconListItemSelected]:
                              iName === category.iconName,
                          })}
                          onClick={() =>
                            setCategory({ ...category, iconName: iName })
                          }
                        >
                          <CServiceCategoryIcon
                            category={{ ...category, iconName: iName }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <CRadioGroup
                  label="表示"
                  currentValue={category.isVisible ? 1 : 0}
                  options={[
                    { value: 1, label: '表示' },
                    { value: 0, label: '非表示' },
                  ]}
                  onChange={(value) =>
                    setCategory({ ...category, isVisible: Boolean(value) })
                  }
                  row
                />
              </Grid>
            </Grid>
          </ValidatorForm>
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid item xs>
              <CButton variant="outlined" onClick={onClose}>
                キャンセル
              </CButton>
            </Grid>
            <Grid item>
              {isEditMode ? (
                <Grid container spacing={2}>
                  <Grid item>
                    <CMenuButton
                      variant="text"
                      menus={[
                        {
                          title: 'このカテゴリを削除する',
                          onClick: handleDelete,
                        },
                      ]}
                    >
                      その他
                    </CMenuButton>
                  </Grid>
                  <Grid item>
                    <CButton disabled={!isValid} form={formId} type="submit">
                      保存
                    </CButton>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={2}>
                  <Grid item>
                    <CButton disabled={!isValid} form={formId} type="submit">
                      作成
                    </CButton>
                  </Grid>
                  <Grid item>
                    <CButton disabled={!isValid} onClick={handleSaveAndNext}>
                      続けて作成
                    </CButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  }
);

export default withConfirmDialog(AddEditCategoryDialog);
