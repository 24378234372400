import { VisitImageType } from '../data';

export function getVisitImageFirebasePath(
  salonId: number,
  visitId: number,
  visitImageType: VisitImageType,
  displayNumber: number
) {
  return `images/salon/${salonId}/visits/${visitId}/${visitImageType}/${displayNumber}`;
}
