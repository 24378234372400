import { ChatMessageBody } from '../../models/chatMessageBody/entity';
import { ChatMessageBodyKarute as ChatMessageBodyKaruteSchema } from './schema';

export class ChatMessageBodyKarute implements ChatMessageBody {
  karuteId: number;
  text: string;
  urls: string[];

  constructor(args: ChatMessageBodyKaruteSchema) {
    this.karuteId = args.karuteId;
    this.text = args.text;
    this.urls = args.urls;
  }
}
