import { VisitResource } from '@karutekun/core/visit';
import { unixToSecondsInDay } from '@karutekun/shared/util/datetime';
import { moment } from '@karutekun/shared/util/datetime';
import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import {
  Button,
  Card,
  Collapse,
  Fade,
  Grid,
  IconButton,
  Popper,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import _ from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import CProgressOverlay from '../../../../components_old/atoms/CProgressOverlay';
import CTypographyIcon from '../../../../components_old/atoms/CTypographyIcon';
import {
  DailyBusinessInfoUpdate,
  ScheduleCapacityMap,
  getConstScheduleCapacity,
} from '../../../../models/salonBusinessInfo';
import { ScheduleCapacityMax } from '../../../../models/salonScheduleSettings';
import { useFlag } from '../../../../util/hooks/useFlag';
import { ScheduleContext, ScheduleZIndex } from './context';

type Props = {
  date: string;
  hasPermission: boolean;
  visits: VisitResource[];
  scheduleCapacity?: ScheduleCapacityMap;
  minuteInterval: MinuteInterval;
  onUpdateCapacity(update: DateMap<DailyBusinessInfoUpdate>): Promise<void>;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'sticky',
    width: 'fit-content',
    top: 0,
    zIndex: ScheduleZIndex.TimeHeader,
    backgroundColor: theme.palette.grey[100],
  },
  leftHeaderContainer: {
    position: 'sticky',
    left: 0,
    top: 0,
    zIndex: ScheduleZIndex.TimeHeader + 1,
    backgroundColor: theme.palette.grey[100],
    borderRight: `5px ${theme.palette.grey[300]} solid`,
    padding: theme.spacing(1),
  },
  leftHeaderContainerDense: {
    padding: 0,
  },
  collapseButton: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  rowDotted: {
    borderBottom: `1px ${theme.palette.grey[300]} dotted`,
  },
  rowSolid: {
    borderBottom: `1px ${theme.palette.grey[300]} solid`,
  },
  headerCell: {
    backgroundColor: theme.palette.grey[500],
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    color: theme.palette.background.paper,
    padding: theme.spacing(1),
  },
  capacityCell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[50],
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight,
  },
  capacityCellHovered: {
    backgroundColor: theme.palette.grey[200],
  },
  capacityCellFilled: {
    backgroundColor: theme.palette.grey[300],
  },
  popper: {
    zIndex: ScheduleZIndex.TimeHeader + 1,
  },
  popperContent: {
    position: 'relative',
    padding: theme.spacing(2),
  },
}));

export const ScheduleTimeHeader: FC<Props> = React.memo(
  function ScheduleTimeHeader(props) {
    const showReservationStuff = props.scheduleCapacity !== undefined;
    return showReservationStuff ? (
      <ReservationHeader {...props} />
    ) : (
      <SimpleHeader />
    );
  },
  (prev, next) => _.isEqual(prev, next)
);

const SimpleHeader: FC = () => {
  const classes = useStyles();
  const {
    getWidth,
    sizes: { headerWidth },
  } = useContext(ScheduleContext);
  return (
    <div className={classes.container}>
      <div className={clsx(classes.row, classes.rowSolid)}>
        <div
          className={classes.leftHeaderContainer}
          style={{ width: headerWidth, minWidth: headerWidth }}
        />
        {Array(24)
          .fill(0)
          .map((_, i) => {
            return (
              <div
                key={i}
                className={classes.headerCell}
                style={{ width: getWidth(1) }}
              >
                <Typography variant="body1">{i}:00</Typography>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const ReservationHeader: FC<Props> = (props) => {
  const classes = useStyles();

  const {
    date,
    hasPermission,
    scheduleCapacity,
    visits,
    minuteInterval,
    onUpdateCapacity,
  } = props;

  const [extended, setExtended] = useState(true);
  const [capacityEditAnchorEl, setCapacityEditAnchorEl] =
    React.useState<HTMLDivElement | null>(null);
  const [editingCapacityIndex, setEditingCapacityIndex] = useState<
    number | null
  >(null);

  const handleCapacityEditOpen = (e: HTMLDivElement, index: number) => {
    setCapacityEditAnchorEl(e);
    setEditingCapacityIndex(index);
  };

  const handleCapacityEditClose = () => {
    setCapacityEditAnchorEl(null);
    setEditingCapacityIndex(null);
  };

  const constCapacity = scheduleCapacity
    ? getConstScheduleCapacity(scheduleCapacity)
    : undefined;

  const capacityList = useMemo(() => {
    const list: (number | null)[] = [];
    for (let hour = 0; hour < 24; hour += 1) {
      for (let min = 0; min < 60; min += minuteInterval) {
        list.push(scheduleCapacity?.[hour]?.[min] ?? null);
      }
    }
    return list;
  }, [minuteInterval, scheduleCapacity]);

  const scheduleNumList = useMemo(() => {
    const list = new Array(24 * (60 / minuteInterval)).fill(0);

    const interval = minuteInterval * 60;
    for (const s of visits) {
      const startIndex = Math.floor(unixToSecondsInDay(s.startedAt) / interval);
      const finishIndex = Math.floor(
        (unixToSecondsInDay(s.finishedAt) - 1) / interval
      );
      for (let i = startIndex; i <= finishIndex; i++) {
        if (list[i] !== undefined) {
          list[i] += 1;
        }
      }
    }

    return list;
  }, [minuteInterval, visits]);

  const {
    sizes: { headerWidth },
    getWidth,
  } = useContext(ScheduleContext);

  const leftHeaderStyle = { width: headerWidth, minWidth: headerWidth };

  return (
    <div className={classes.container} onMouseLeave={handleCapacityEditClose}>
      <div className={clsx(classes.row, classes.rowSolid)}>
        <div
          className={clsx(
            classes.leftHeaderContainer,
            classes.leftHeaderContainerDense
          )}
          style={leftHeaderStyle}
        >
          <Tooltip title="店舗の同時受付可能数">
            <Button
              className={classes.collapseButton}
              endIcon={
                extended ? (
                  <SVGIcon name="angle-up" />
                ) : (
                  <SVGIcon name="angle-down" />
                )
              }
              onClick={() => setExtended(!extended)}
            >
              {constCapacity === null ? (
                <CTypographyIcon
                  icon={<SVGIcon name="user-friends" />}
                  variant="body2"
                >
                  時間帯別
                </CTypographyIcon>
              ) : constCapacity !== undefined ? (
                <CTypographyIcon
                  icon={<SVGIcon name="user-friends" />}
                  variant="body2"
                >
                  {constCapacity}
                </CTypographyIcon>
              ) : null}
            </Button>
          </Tooltip>
        </div>
        {Array(24)
          .fill(0)
          .map((_, i) => {
            return (
              <div
                key={i}
                className={classes.headerCell}
                style={{ width: getWidth(1) }}
              >
                <Typography variant="body1">{i}:00</Typography>
              </div>
            );
          })}
      </div>
      <Collapse in={extended}>
        <div className={clsx(classes.row, classes.rowDotted)}>
          <div className={classes.leftHeaderContainer} style={leftHeaderStyle}>
            予約数
          </div>
          {scheduleNumList.map((num, i) => (
            <div
              key={i}
              className={classes.capacityCell}
              style={{ width: getWidth(24 / ((24 * 60) / minuteInterval)) }}
            >
              {num}
            </div>
          ))}
        </div>
        <div className={clsx(classes.row, classes.rowSolid)}>
          <div className={classes.leftHeaderContainer} style={leftHeaderStyle}>
            残り
          </div>
          {scheduleNumList.map((num, i) => {
            const capacity = capacityList[i];
            const remain = (capacity || 0) - num;
            return (
              <div
                key={i}
                className={clsx(classes.capacityCell, {
                  [classes.capacityCellHovered]: editingCapacityIndex === i,
                  [classes.capacityCellFilled]:
                    capacity === null || remain <= 0,
                })}
                style={{
                  width: getWidth(24 / ((24 * 60) / minuteInterval)),
                }}
                onMouseEnter={
                  capacity !== null && hasPermission
                    ? (e) => {
                        handleCapacityEditOpen(e.currentTarget, i);
                      }
                    : handleCapacityEditClose
                }
              >
                {capacity === null ? '-' : remain}
              </div>
            );
          })}
        </div>
      </Collapse>

      {/* 受付可能数を変更するポッパー */}
      <CapacityEditPopper
        date={date}
        anchorEl={capacityEditAnchorEl}
        minuteInterval={minuteInterval}
        capacity={
          editingCapacityIndex !== null
            ? (capacityList[editingCapacityIndex] ?? 0)
            : 0
        }
        filled={
          editingCapacityIndex !== null
            ? (scheduleNumList[editingCapacityIndex] ?? 0)
            : 0
        }
        index={editingCapacityIndex ?? 0}
        onSave={onUpdateCapacity}
      />
    </div>
  );
};

const CapacityEditPopper: FC<{
  anchorEl: HTMLDivElement | null;
  date: string;
  minuteInterval: number;
  capacity: number;
  filled: number;
  index: number;
  onSave(update: DateMap<DailyBusinessInfoUpdate>): Promise<void>;
}> = (props) => {
  const classes = useStyles();
  const { anchorEl, date, minuteInterval, filled, index, onSave } = props;

  const [isSaving, callWithSaving] = useFlag(false);
  const [capacity, setCapacity] = useState(props.capacity);

  const divNum = 60 / minuteInterval;
  const time = moment(date)
    .hour(Math.floor(index / divNum))
    .minute((index % divNum) * minuteInterval);

  useEffect(() => setCapacity(props.capacity), [anchorEl, props.capacity]);

  const saveCapacity = useCallback(
    (capacity: number) => {
      const date = time.format('YYYY-MM-DD');
      const hour = time.hour();
      const minute = time.minute();
      callWithSaving(() =>
        onSave({
          [date]: {
            date,
            scheduleCapacity: {
              [hour]: {
                [minute]: capacity,
              },
            },
          },
        })
      );
    },
    [callWithSaving, onSave, time]
  );
  const handlePlus = useCallback(() => {
    if (capacity < ScheduleCapacityMax) {
      setCapacity(capacity + 1);
      saveCapacity(capacity + 1);
    }
  }, [capacity, saveCapacity]);
  const handleMinus = useCallback(() => {
    if (capacity - filled > 0) {
      setCapacity(capacity - 1);
      saveCapacity(capacity - 1);
    }
  }, [capacity, filled, saveCapacity]);

  return (
    <Popper
      className={classes.popper}
      open={anchorEl !== null}
      anchorEl={anchorEl}
      placement="bottom-start"
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={200}>
          <Card className={classes.popperContent}>
            {isSaving && <CProgressOverlay />}

            <Typography variant="body1" gutterBottom>
              {time.format('HH:mm')}
            </Typography>

            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Typography variant="body2">残り</Typography>
              </Grid>
              <Grid item>
                <IconButton size="small" onClick={handleMinus}>
                  <SVGIcon name="minus" size="sm" />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="body2">{capacity - filled}</Typography>
              </Grid>
              <Grid item>
                <IconButton size="small" onClick={handlePlus}>
                  <SVGIcon name="plus" size="sm" />
                </IconButton>
              </Grid>
            </Grid>
          </Card>
        </Fade>
      )}
    </Popper>
  );
};
