import { Action, Dispatch } from 'redux';

export function createCancellableAsyncAction<T>(
  func: (dispatch: Dispatch<Action>, signal: AbortSignal) => Promise<T>
) {
  return function (dispatch: Dispatch<Action>): {
    request: Promise<T>;
    controller: AbortController;
  } {
    const controller = new AbortController();
    const request = func(dispatch, controller.signal);
    return { request, controller };
  };
}
