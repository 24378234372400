import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {
  CustomerFilter,
  CustomerSortKey,
  CustomerSortOrder,
  downloadCustomerCsv,
  fetchCustomers,
} from '../../actions/customerAction';
import { setCustomerTable } from '../../actions/view/viewCustomersAction';
import CButton from '../../components_old/atoms/CButton';
import CDivider from '../../components_old/atoms/CDivider';
import CInformation from '../../components_old/atoms/CInformation';
import CTrialWillEndBanner from '../../components_old/molecules/CTrialWillEndBanner';
import CCustomerFilter from '../../components_old/organisms/CCustomerFilter';
import CCustomerTable from '../../components_old/organisms/CCustomerTable';
import { checkPermission } from '../../models/stylist';
import { selectCustomerMap } from '../../selectors/customerSelector';
import {
  selectHasGroupSalon,
  useSelectMe,
  useSelectMySalon,
  useSelectStylists,
} from '../../selectors/salonSelector';
import { GlobalState } from '../../store';
import {
  WithConfirmDialog,
  withConfirmDialog,
} from '../../templates/hoc/ConfirmDialogHOC';
import {
  WithCreateCustomerDialog,
  withCreateCustomerDialog,
} from '../../templates/hoc/CreateCustomerDialogHOC';
import { useSalonStatus } from '../../templates/providers/salonStatus/salonStatusContext';
import { InformationText } from '../../texts/infomation';
import { notEmpty } from '../../util/common';
import { useThunkDispatch } from '../../util/hooks/useThunkDispatch';

const useStyles = makeStyles((_: Theme) => ({
  root: {
    padding: '76px 20px 20px 20px',
  },
}));

type Props = WithCreateCustomerDialog & WithConfirmDialog;

const Customers: FC<Props> = (props) => {
  const classes = useStyles();

  const {
    closeCreateCustomerDialog,
    openCreateCustomerDialog,
    openConfirmDialog,
    closeConfirmDialog,
  } = props;

  const numCustomersPerPage = 50;
  const salon = useSelectMySalon();
  const hasGroupSalon = useSelector(selectHasGroupSalon);
  const stylists = useSelectStylists();
  const customerListData = useSelector(
    (state: GlobalState) => state.view.customers.customerList
  );

  const customerIds = useSelector(
    (state: GlobalState) => state.view.customers.customerList.ids
  );
  const customerMap = useSelector(selectCustomerMap);
  const customers = customerIds.map((id) => customerMap[id]).filter(notEmpty);
  const me = useSelectMe();
  const dispatch = useThunkDispatch();

  const { checkAndOpenDialog } = useSalonStatus();
  const navigate = useNavigate();

  const setPage = (page: number) => {
    dispatch(setCustomerTable({ page }));
  };

  const setFilter = (filter: CustomerFilter, useGroupData: boolean) => {
    dispatch(setCustomerTable({ filter, page: 0, useGroupData }));
  };

  const setSort = (sortKey: CustomerSortKey, sortOrder: CustomerSortOrder) => {
    dispatch(setCustomerTable({ sortKey, sortOrder, page: 0 }));
  };

  const onPressDownloadCustomerCsv = async (filter: CustomerFilter) => {
    dispatch(setCustomerTable({ isFetching: true }));

    await dispatch(downloadCustomerCsv(filter));

    dispatch(setCustomerTable({ isFetching: false }));
  };

  const handleFetchCustomers = (
    page: number,
    filter: CustomerFilter,
    sortKey: CustomerSortKey,
    sortOrder: CustomerSortOrder
  ) => {
    dispatch(setCustomerTable({ isFetching: true }));
    const { controller, request } = dispatch(
      fetchCustomers(
        {
          ...filter,
          limit: numCustomersPerPage,
          offset: page * numCustomersPerPage,
        },
        sortKey,
        sortOrder
      )
    );

    request.then((data) => {
      if (data !== null) {
        const { count, customers } = data;
        dispatch(
          setCustomerTable({
            count,
            ids: customers.map((c) => c.id),
            isFetching: false,
          })
        );
      }
    });

    return controller;
  };

  useDeepCompareEffect(() => {
    const controller = handleFetchCustomers(
      customerListData.page,
      customerListData.filter,
      customerListData.sortKey,
      customerListData.sortOrder
    );

    return function cleanup() {
      if (controller) {
        controller.abort();
      }
    };
  }, [
    fetchCustomers,
    customerListData.page,
    customerListData.filter,
    customerListData.sortKey,
    customerListData.sortOrder,
  ]);

  const handleCreateNewCustomer = () => {
    if (!checkAndOpenDialog()) {
      return;
    }
    openCreateCustomerDialog({
      onCreateAndView: (customerId) => {
        closeCreateCustomerDialog();
        navigate(`/customers/${customerId}`);
      },
      showContinuousCreate: true,
      onCanceled: closeCreateCustomerDialog,
    });
  };

  const handleDownloadCustomerCsv = () => {
    if (!checkPermission(me, 'canShowPersonalData')) {
      openConfirmDialog({
        description: '権限を持ったスタッフのみダウンロード可能です。',
        onOk: () => closeConfirmDialog(),
      });
      return;
    }

    openConfirmDialog({
      description: `カルテをダウンロードしますか?`,
      onOk: () => {
        if (onPressDownloadCustomerCsv) {
          onPressDownloadCustomerCsv(customerListData.filter);
        }
        closeConfirmDialog();
      },
    });
  };

  return (
    <div className={classes.root}>
      <CTrialWillEndBanner />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <CButton
          size="large"
          startIcon={<SVGIcon name="plus" />}
          onClick={handleCreateNewCustomer}
        >
          カルテ新規作成
        </CButton>
        <div style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
          <CButton
            size="small"
            startIcon={<SVGIcon name="cloud-download" size="sm" />}
            onClick={handleDownloadCustomerCsv}
            isLoading={customerListData.isFetching}
          >
            カルテダウンロード
          </CButton>
          <CInformation
            type="dialog"
            content={InformationText.customers.csvDownload}
            size="lg"
          />
        </div>
      </div>

      <CDivider spacing={3} />

      <CCustomerFilter
        salonId={salon.id}
        hasGroupSalon={hasGroupSalon}
        filter={customerListData.filter}
        useGroupData={customerListData.useGroupData}
        stylists={stylists}
        onChangeFilter={setFilter}
      />
      <CCustomerTable
        useGroupData={customerListData.useGroupData}
        hasGroupSalon={hasGroupSalon}
        customers={customers}
        customerListData={customerListData}
        rowsPerPage={numCustomersPerPage}
        onSortOrderChange={setSort}
        onPageChange={setPage}
      />
    </div>
  );
};

export default withConfirmDialog(withCreateCustomerDialog(Customers));
