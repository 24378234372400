import { moment } from '@karutekun/shared/util/datetime';
import { cloneDeep } from 'lodash';
import numeral from 'numeral';
import { ReactNode } from 'react';

/**
 * ローカルでユニークになるIDを返す
 * 返ってくるIDはマイナス値なので、サーバーで採番されるIDと衝突することはない
 */
export const localUniqueId: () => number = (() => {
  let tmpId = -1;
  return function () {
    tmpId -= 1;
    return tmpId;
  };
})();

export function notEmpty<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export function compOrder<T extends { order: number }>(a: T, b: T): number {
  return b.order - a.order;
}

export function merge<T>(oldObj: T, newObj: Partial<T>): T {
  return {
    ...cloneDeep(oldObj),
    ...cloneDeep(newObj),
  };
}

export function removeUndefField<T extends object>(obj: T): Partial<T> {
  // TODO 一時的にルールを無効化しています。気づいたベースで直してください
  //                   TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
  // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
  Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key]);
  return obj;
}

export function mergeIgnoringUndefField<T>(obj1: T, obj2: Partial<T>): T {
  return {
    ...obj1,
    ...removeUndefField(obj2 || {}),
  };
}

export function buildDayOfWeekMap<T>(
  buildFunc: (dayOfWeek: 1 | 2 | 3 | 4 | 5 | 6 | 7) => T
): DayOfWeekMap<T> {
  return {
    1: buildFunc(1),
    2: buildFunc(2),
    3: buildFunc(3),
    4: buildFunc(4),
    5: buildFunc(5),
    6: buildFunc(6),
    7: buildFunc(7),
  };
}

export function formatMoney(amount: number) {
  return `¥${numeral(amount).format('0,0')}`;
}
export function formatDate(unixtime: number, showTime?: boolean) {
  return moment.unix(unixtime).format(`YYYY/MM/DD${showTime ? ' HH:mm' : ''}`);
}
export function formatDateShort(m: moment.Moment) {
  const now = moment();
  if (m.year() < now.year()) {
    // 去年以前の場合は年月日と曜日
    return m.format('YYYY/M/D(ddd)');
  } else {
    // それ以外の場合は月日と曜日
    return m.format('M/D(ddd)');
  }
}

export function isReactText(node: unknown): node is ReactNode {
  return typeof node === 'string' || typeof node === 'number';
}
