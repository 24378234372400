import { Backdrop, CircularProgress, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

type OwnProps = {
  open: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: '#fff',
  },
}));

const CBackdrop: FC<OwnProps> = React.memo(function CBackdrop(props) {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={props.open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
});

export default CBackdrop;
