import { PermissionRoleEdit } from '@karutekun/core/permission-role';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import CButton from '../../../../../components_old/atoms/CButton';
import { selectPermissionRoleMap } from '../../../../../selectors/salonSelector';
import { useWidthDown } from '../../../../../util/hooks/useWidth';
import { useUpdatePermissionRole } from '../_hooks/useUpdatePermissionRole';
import { PermissionRoleForm } from './PermissionRoleForm';

type Props = {
  roleId: number;
  open: boolean;
  onClose(): void;
};

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    backgroundColor: theme.palette.background.default,
  },
}));

const formId = 'update-permission-role-form';

export const UpdatePermissionRoleDialog: FC<Props> = (props) => {
  const classes = useStyles();

  const { roleId, open, onClose } = props;
  const permissionRole = useSelector(selectPermissionRoleMap)[roleId];

  const { updatePermissionRole, isFetching } = useUpdatePermissionRole();
  const handleSubmit = useCallback(
    async (data: PermissionRoleEdit) => {
      await updatePermissionRole(roleId, data);
      onClose();
    },
    [updatePermissionRole, roleId, onClose]
  );

  const fullScreen = useWidthDown('sm');

  return (
    <Dialog
      classes={{ paper: classes.dialogContent }}
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>役割の編集</DialogTitle>
      <DialogContent dividers>
        <PermissionRoleForm
          formId={formId}
          defaultData={permissionRole}
          onSubmit={handleSubmit}
        />
      </DialogContent>
      <DialogActions>
        <CButton
          disabled={isFetching}
          variant="outlined"
          color="primary"
          onClick={onClose}
        >
          キャンセル
        </CButton>
        <CButton
          disabled={isFetching}
          type="submit"
          variant="contained"
          color="primary"
          form={formId}
        >
          保存
        </CButton>
      </DialogActions>
    </Dialog>
  );
};
