import { AppColor, Colors } from '@karutekun/shared-fe/design-token';
import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    default: Palette['primary'];
    accent: Palette['primary'];
    app: typeof AppColor;
  }
  interface PaletteOptions {
    default: PaletteOptions['primary'];
    accent: PaletteOptions['primary'];
    app: typeof AppColor;
  }
}

export const theme = createTheme({
  spacing: 8,
  palette: {
    primary: {
      main: Colors['gray-800'],
      contrastText: Colors['white'],
      50: Colors['gray-50'],
      100: Colors['gray-100'],
      200: Colors['gray-200'],
      300: Colors['gray-300'],
      400: Colors['gray-400'],
      500: Colors['gray-500'],
      600: Colors['gray-600'],
      700: Colors['gray-700'],
      800: Colors['gray-800'],
      900: Colors['gray-900'],
    },
    secondary: {
      main: Colors['blue-700'],
      contrastText: Colors['white'],
      50: Colors['blue-50'],
      100: Colors['blue-100'],
      200: Colors['blue-200'],
      300: Colors['blue-300'],
      400: Colors['blue-400'],
      500: Colors['blue-500'],
      600: Colors['blue-600'],
      700: Colors['blue-700'],
      800: Colors['blue-800'],
      900: Colors['blue-900'],
    },
    error: {
      light: Colors['red-10'],
      main: Colors['red-700'],
      dark: Colors['red-900'],
      contrastText: Colors['white'],
      50: '#ffebee',
    },
    background: {
      paper: Colors['white'],
      default: Colors['gray-10'],
    },
    text: {
      primary: Colors['gray-800'],
      secondary: Colors['gray-500'],
    },
    default: {
      main: Colors['gray-800'],
      contrastText: Colors['white'],
      50: Colors['gray-50'],
      100: Colors['gray-100'],
      200: Colors['gray-200'],
      300: Colors['gray-300'],
      400: Colors['gray-400'],
      500: Colors['gray-500'],
      600: Colors['gray-600'],
      700: Colors['gray-700'],
      800: Colors['gray-800'],
      900: Colors['gray-900'],
    },
    accent: {
      main: Colors['pink-700'],
      contrastText: Colors['white'],
      50: Colors['pink-50'],
      100: Colors['pink-100'],
      200: Colors['pink-200'],
      300: Colors['pink-300'],
      400: Colors['pink-400'],
      500: Colors['pink-500'],
      600: Colors['pink-600'],
      700: Colors['pink-700'],
      800: Colors['pink-800'],
      900: Colors['pink-900'],
    },
    divider: Colors['gray-100'],
    app: AppColor,
  },
  typography: {
    fontFamily: [
      'Open Sans',
      'Hiragino Sans',
      'ヒラギノ角ゴシック',
      'Roboto',
      'sans-serif',
    ].join(','),
    fontSize: 12,
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '.MuiFormLabel-asterisk': {
            color: Colors['pink-700'],
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: Colors['blue-700'],
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: Colors['blue-700'],
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          color: 'secondary',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: Colors['gray-200'],
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          color: Colors['gray-200'],
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${Colors['gray-100']}`,
        },
      },
    },
  },
});
