import { VisitResource } from '@karutekun/core/visit';
import { unixToSecondsInDay } from '@karutekun/shared/util/datetime';
import { moment } from '@karutekun/shared/util/datetime';
import { theme } from '@karutekun/shared-fe/react-ui-old';
import _ from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { XYCoord, useDrop } from 'react-dnd';
import {
  ScheduleRow,
  alignSchedulesIntoRows,
} from '../../../../components_old/organisms/schedules/scheduleLayoutRow';
import {
  DailyBusinessInfo,
  DailyBusinessInfoUpdate,
} from '../../../../models/salonBusinessInfo';
import {
  PlainStylist,
  StylistMe,
  checkPermission,
} from '../../../../models/stylist';
import DndProviderTemplate from '../../../../templates/DndProviderTemplate';
import { ScheduleUnavailableBackground } from './ScheduleBusinessHourBackground';
import { ScheduleCreatePreview } from './ScheduleCreatePreview';
import { ScheduleDragPreview } from './ScheduleDragPreview';
import { ScheduleItem } from './ScheduleItem';
import { ScheduleStylistRow } from './ScheduleStylistRow';
import { ScheduleTimeHeader } from './ScheduleTimeHeader';
import { DragItemData, Layout, ScheduleContext } from './context';

const NonAssignedStylistId = 0;

type Props = {
  date: string;
  me: StylistMe;
  currentTime: number;
  shownStylists: PlainStylist[];
  showNonAssignedReserve: boolean;
  visits: VisitResource[];
  businessInfoMap: DateMap<DailyBusinessInfo>;
  selectedVisitId: number | null;
  scale: number;
  minuteInterval: MinuteInterval;

  // 現在作成中の予約
  creatingReservation: Pick<
    VisitResource,
    'assignedStylistId' | 'startedAt' | 'finishedAt'
  > | null;

  onStartCreate(data: {
    assignedStylistId: number | null;
    startedAt: number;
    finishedAt: number;
    isShimei: boolean;
  }): void;
  onScheduleClick(visitId: number): void;
  onScheduleDragStart(visitId: number): void;
  onScheduleMove(
    visit: VisitResource,
    update: {
      assignedStylistId: number | null;
      startedAt: number;
      finishedAt: number;
    }
  ): void;
  onUpdateCapacity(update: DateMap<DailyBusinessInfoUpdate>): Promise<void>;
};

const gridWidth = 120;
const gridHeight = 90;
const headerWidth = 120;
const headerHeight = 0;

export const SchedulesDailyView: FC<Props> = React.memo<Props>(
  function SchedulesDailyViewAlpha(props) {
    const {
      date,
      me,
      minuteInterval,
      shownStylists,
      showNonAssignedReserve,
      visits,
      businessInfoMap,
      selectedVisitId,
      creatingReservation,
      onStartCreate,
      onScheduleClick,
      onScheduleDragStart,
      onScheduleMove,
      onUpdateCapacity,
    } = props;

    const stylistVisitsMap: IdMap<VisitResource[]> = useMemo(() => {
      return visits.reduce((p: IdMap<VisitResource[]>, c) => {
        const key = c.assignedStylistId || NonAssignedStylistId;
        if (!p[key]) {
          p[key] = [c];
        } else {
          p[key].push(c);
        }
        return p;
      }, {});
    }, [visits]);

    const stylistIds: number[] = useMemo(() => {
      const ids = shownStylists.map((s) => s.id);
      if (showNonAssignedReserve) {
        ids.push(NonAssignedStylistId);
      }
      return ids;
    }, [showNonAssignedReserve, shownStylists]);

    const stylistRowsMap: IdMap<ScheduleRow[]> = useMemo(() => {
      return stylistIds.reduce((p: IdMap<ScheduleRow[]>, id) => {
        p[id] = alignSchedulesIntoRows(stylistVisitsMap[id] || []);
        return p;
      }, {});
    }, [stylistIds, stylistVisitsMap]);

    const stylistLayoutMap: IdMap<{
      yIndexStart: number;
      yIndexEnd: number;
    }> = useMemo(() => {
      const map: IdMap<{ yIndexStart: number; yIndexEnd: number }> = {};
      let numRows = 0;
      for (const id of stylistIds) {
        const rows = Math.max(1, stylistRowsMap[id].length);
        map[id] = {
          yIndexStart: numRows,
          yIndexEnd: numRows + rows,
        };
        numRows += rows;
      }
      return map;
    }, [stylistIds, stylistRowsMap]);

    const scrollRef = useRef<HTMLDivElement | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);

    const sizes = useMemo(
      () => ({
        headerWidth,
        headerHeight,
        gridHeight,
        gridWidth: gridWidth * props.scale,
      }),
      [props.scale]
    );

    const getContainerOffset = useCallback((pageOffset: XYCoord) => {
      if (!containerRef.current) {
        return null;
      }
      const { left, top } = containerRef.current.getBoundingClientRect();
      return {
        x: pageOffset.x - left,
        y: pageOffset.y - top,
      };
    }, []);

    const getSnappedInformation = useCallback(
      (pageOffset: XYCoord) => {
        const offset = getContainerOffset(pageOffset);
        if (!offset) {
          return null;
        }

        // y 軸
        const yIndex = Math.max(
          0,
          Math.floor((offset.y - sizes.headerHeight) / sizes.gridHeight)
        );
        const stylistId = stylistIds.find((id) => {
          const layout = stylistLayoutMap[id];
          return layout.yIndexStart <= yIndex && yIndex < layout.yIndexEnd;
        });
        if (stylistId === undefined) {
          return null;
        }
        const layout = stylistLayoutMap[stylistId];
        const y = layout.yIndexStart * sizes.gridHeight;
        const h = (layout.yIndexEnd - layout.yIndexStart) * sizes.gridHeight;

        // x 軸は N 分刻み
        const snapWidth = sizes.gridWidth / (60 / minuteInterval);
        const snapBlocks = Math.round(
          (offset.x - sizes.headerWidth) / snapWidth
        );
        const x = snapBlocks * snapWidth;

        // 座標を秒数に変換
        const seconds = Math.round((86400 * x) / (sizes.gridWidth * 24));
        const startedAt = moment(date)
          .hour(0)
          .minute(0)
          .seconds(seconds)
          .unix();

        return {
          h,
          stylistId,
          startedAt,
          x: x + sizes.headerWidth,
          y: y + sizes.headerHeight,
        };
      },
      [
        getContainerOffset,
        sizes.headerHeight,
        sizes.gridHeight,
        sizes.gridWidth,
        sizes.headerWidth,
        stylistIds,
        stylistLayoutMap,
        minuteInterval,
        date,
      ]
    );

    const getOffsetY = useCallback(
      (yIndex: number) => yIndex * sizes.gridHeight + sizes.headerHeight,
      [sizes]
    );
    const getOffsetX = useCallback(
      (xIndex: number) => xIndex * sizes.gridWidth + sizes.headerWidth,
      [sizes]
    );
    const getHeight = useCallback(
      (grids: number) => grids * sizes.gridHeight,
      [sizes]
    );
    const getWidth = useCallback(
      (grids: number) => grids * sizes.gridWidth,
      [sizes]
    );
    const getOffsetXFromSeconds = useCallback(
      (secondsInDay: number) =>
        (secondsInDay / 3600) * sizes.gridWidth + sizes.headerWidth,
      [sizes]
    );
    const getWidthFromSeconds = useCallback(
      (seconds: number) => (seconds / 3600) * sizes.gridWidth,
      [sizes]
    );

    useEffect(() => {
      if (scrollRef && scrollRef.current) {
        // 現在時刻付近にスクロールする
        const grid = (unixToSecondsInDay(props.currentTime) / 86400) * 24 - 2;
        scrollRef.current.scrollTop = 0;
        scrollRef.current.scrollLeft = getWidth(grid);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClickBackground = useCallback(
      (stylistId: number | null, gridIndex: number) => {
        const hour = Math.floor(gridIndex);
        const minute = (gridIndex - hour) * 60;
        const mom = moment(date).hour(hour).minute(minute).seconds(0);
        const isShimei = !!stylistId;
        onStartCreate({
          assignedStylistId: stylistId ?? null,
          startedAt: mom.unix(),
          finishedAt: mom.add(1, 'hour').unix(),
          isShimei,
        });
      },
      [date, onStartCreate]
    );

    const handleDropSchedule = useCallback(
      (
        visit: VisitResource,
        stylistId: number | null,
        startedAt: number,
        finishedAt: number
      ) => {
        onScheduleMove(visit, {
          startedAt,
          finishedAt,
          assignedStylistId: stylistId,
        });
      },
      [onScheduleMove]
    );

    const scheduleLayoutMap: IdMap<Layout> = {};
    for (const id of stylistIds) {
      for (let i = 0; i < stylistRowsMap[id].length; i += 1) {
        for (const schedule of stylistRowsMap[id][i]) {
          scheduleLayoutMap[schedule.id] = {
            left: getOffsetXFromSeconds(unixToSecondsInDay(schedule.startedAt)),
            top: getOffsetY(stylistLayoutMap[id].yIndexStart + i) + 1,
            width: getWidthFromSeconds(
              schedule.finishedAt - schedule.startedAt
            ),
            height: getHeight(1) - 22,
          };
        }
      }
    }

    let createScheduleLayout = null;
    if (
      creatingReservation &&
      moment.unix(creatingReservation.startedAt).format('YYYY-MM-DD') ===
        props.date
    ) {
      const stylistLayout =
        stylistLayoutMap[
          creatingReservation.assignedStylistId || NonAssignedStylistId
        ];
      if (stylistLayout) {
        createScheduleLayout = {
          left: getOffsetXFromSeconds(
            unixToSecondsInDay(creatingReservation.startedAt)
          ),
          top: getOffsetY(stylistLayout.yIndexStart),
          height: getHeight(
            stylistLayout.yIndexEnd - stylistLayout.yIndexStart
          ),
          width: getWidthFromSeconds(
            creatingReservation.finishedAt - creatingReservation.startedAt
          ),
        };
      }
    }

    return (
      <DndProviderTemplate>
        <ScheduleContext.Provider
          value={{
            getContainerOffset,
            getSnappedInformation,
            getOffsetY,
            getOffsetX,
            getHeight,
            getWidth,
            getOffsetXFromSeconds,
            getWidthFromSeconds,
            sizes,
            minuteInterval,
          }}
        >
          <div
            ref={scrollRef}
            style={{
              height: '100%',
              width: '100%',
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
            }}
          >
            <ScheduleTimeHeader
              date={date}
              hasPermission={checkPermission(me, 'canUpdateScheduleSetting')}
              visits={visits}
              scheduleCapacity={businessInfoMap[date]?.scheduleCapacity}
              minuteInterval={minuteInterval}
              onUpdateCapacity={onUpdateCapacity}
            />
            <Container ref={containerRef} onDropSchedule={handleDropSchedule}>
              <ScheduleUnavailableBackground
                isClosed={businessInfoMap[date]?.businessHour.isNonBusinessDay}
                from={businessInfoMap[date]?.businessHour.openTime}
                to={businessInfoMap[date]?.businessHour.closeTime}
              />

              {stylistIds.map((id, i) => {
                const stylist = shownStylists.find((s) => s.id === id);
                return (
                  <ScheduleStylistRow
                    key={i}
                    stylist={stylist}
                    shift={
                      stylist
                        ? businessInfoMap[date]?.stylistShift[stylist.id]
                        : undefined
                    }
                    layout={stylistLayoutMap[id]}
                    onClickBackground={handleClickBackground}
                  />
                );
              })}

              {visits.map((visit) => {
                if (!scheduleLayoutMap[visit.id]) {
                  return null;
                }

                return (
                  <ScheduleItem
                    key={visit.id}
                    visit={visit}
                    emphasize={visit.id === selectedVisitId}
                    layout={scheduleLayoutMap[visit.id]}
                    onDragStart={onScheduleDragStart}
                    onClick={onScheduleClick}
                  />
                );
              })}

              {createScheduleLayout && (
                <ScheduleCreatePreview layout={createScheduleLayout} />
              )}

              {moment.unix(props.currentTime).format('YYYY-MM-DD') ===
                props.date && (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left:
                      (unixToSecondsInDay(props.currentTime) / 86400) *
                        getWidth(24) +
                      sizes.headerWidth -
                      1,
                    height: '100%',
                    borderLeft: `2px solid ${theme.palette.secondary.main}`,
                  }}
                />
              )}

              <ScheduleDragPreview />
            </Container>
          </div>
        </ScheduleContext.Provider>
      </DndProviderTemplate>
    );
  },
  (prev, next) => _.isEqual(prev, next)
);

const Container = React.forwardRef<
  HTMLDivElement,
  {
    children: React.ReactNode;
    onDropSchedule(
      visit: VisitResource,
      stylistId: number | null,
      startedAt: number,
      finishedAt: number
    ): void;
  }
>(function Container(props, ref) {
  const { getSnappedInformation, getWidth, sizes } =
    useContext(ScheduleContext);
  const [, dropRef] = useDrop<DragItemData>({
    accept: 'scheduleItem',
    drop(item: DragItemData, monitor) {
      const sourceOffset = monitor.getSourceClientOffset();
      const cursorOffset = monitor.getClientOffset();
      const didDrop = monitor.didDrop();
      if (didDrop || !sourceOffset || !cursorOffset) {
        return;
      }

      const info = getSnappedInformation({
        x: sourceOffset.x,
        y: cursorOffset.y,
      });
      if (!info) {
        return;
      }

      props.onDropSchedule(
        item.visit,
        info.stylistId === NonAssignedStylistId ? null : info.stylistId,
        info.startedAt,
        item.visit.finishedAt - item.visit.startedAt + info.startedAt
      );
    },
  });

  return (
    <div
      ref={ref}
      style={{
        width: getWidth(24) + sizes.headerWidth,
        position: 'relative',
      }}
    >
      <div ref={dropRef}>{props.children}</div>
    </div>
  );
});
