import {
  CustomerLoyaltySegmentText,
  CustomerLoyaltySegments,
} from '@karutekun/core/customer';
import { Grid } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as _ from 'lodash';
import { cloneDeep } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { CustomerFilter } from '../../actions/customerAction';
import { PlainStylist } from '../../models/stylist';
import { removeUndefField } from '../../util/common';
import CInformation from '../atoms/CInformation';
import CSelect from '../atoms/CSelect';
import CTextInput from '../atoms/CTextInput';
import CDateRange from '../molecules/CDateRange';
import CStylistSelect from '../molecules/CStylistSelect';

type OwnProps = {
  salonId: number;
  hasGroupSalon: boolean;
  filter: CustomerFilter;
  useGroupData: boolean;

  stylists: PlainStylist[];

  fixedFilter?: CustomerFilter;
  isUseGroupDataFixed?: boolean;

  onChangeFilter(filter: CustomerFilter, useGroupSalon: boolean): void;
};

type BaseKey = 'name' | 'memo' | 'birthdayMonth' | 'phone';
type CommonKey =
  | 'loyaltySegment'
  | 'firstVisitedAt'
  | 'lastVisitedAt'
  | 'lastStylistId';

const baseKeys = ['name', 'memo', 'birthdayMonth', 'phone'];
const commonKeys = [
  'loyaltySegment',
  'firstVisitedAt',
  'lastVisitedAt',
  'lastStylistId',
];

/**
 * グループ ⇔ サロン のフィルタを切り替える際に、既存のフィルタを適切に移す
 */
function convertFilter(
  salonId: number,
  useGroupData: boolean,
  filter: CustomerFilter
) {
  const newFilter: CustomerFilter = {};

  // customer自体のフィルタをコピー
  for (const key of baseKeys) {
    // TODO 一時的にルールを無効化しています。気づいたベースで直してください
    // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'CustomerFilter'.
    if (filter[key]) {
      // TODO 一時的にルールを無効化しています。気づいたベースで直してください
      //                   TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'CustomerFilter'.
      // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'CustomerFilter'.
      newFilter[key] = filter[key];
    }
  }

  if (useGroupData) {
    // グループのフィルタへ変換。 salon 配下の共通キーがあれば移す
    if (filter.salon) {
      for (const key of commonKeys) {
        // TODO 一時的にルールを無効化しています。気づいたベースで直してください
        // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ salonId: number; loyaltySegment?: CustomerLoyaltySegment | undefined; firstVisitedAt?: { from?: string | undefined; to?: string | undefined; } | undefined; lastVisitedAt?: { ...; } | undefined; lastStylistId?: number | undefined; lineStates?: SalonCustomerLineState[] | undefined; }'.
        if (filter.salon[key] !== undefined) {
          // TODO 一時的にルールを無効化しています。気づいたベースで直してください
          //                   TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ salonId: number; loyaltySegment?: CustomerLoyaltySegment | undefined; firstVisitedAt?: { from?: string | undefined; to?: string | undefined; } | undefined; lastVisitedAt?: { ...; } | undefined; lastStylistId?: number | undefined; lineStates?: SalonCustomerLineState[] | undefined; }'.
          // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'CustomerFilter'.
          newFilter[key] = filter.salon[key];
        }
      }
    }
  } else {
    // サロンのフィルタへ変換。 共通キーを salon 配下に移す
    newFilter.salon = { salonId };
    for (const key of commonKeys) {
      // TODO 一時的にルールを無効化しています。気づいたベースで直してください
      // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'CustomerFilter'.
      if (filter[key] !== undefined) {
        // TODO 一時的にルールを無効化しています。気づいたベースで直してください
        //                   TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'CustomerFilter'.
        // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ salonId: number; loyaltySegment?: CustomerLoyaltySegment | undefined; firstVisitedAt?: { from?: string | undefined; to?: string | undefined; } | undefined; lastVisitedAt?: { ...; } | undefined; lastStylistId?: number | undefined; lineStates?: SalonCustomerLineState[] | undefined; }'.
        newFilter.salon[key] = filter[key];
      }
    }
  }

  return newFilter;
}

/**
 * useGroupData の値によって共通キーを振り分けて設定する
 */
function changeCommonKey(
  salonId: number,
  useGroupData: boolean,
  filter: CustomerFilter,
  updates: Partial<{ [key in CommonKey]: CustomerFilter[key] }>
) {
  const newFilter = cloneDeep(filter);
  const keys = Object.keys(updates);

  if (useGroupData) {
    for (const key of keys) {
      // TODO 一時的にルールを無効化しています。気づいたベースで直してください
      // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Partial<{ firstVisitedAt: { from?: string | undefined; to?: string | undefined; } | undefined; lastVisitedAt: { from?: string | undefined; to?: string | undefined; } | undefined; lastStylistId: number | undefined; loyaltySegment: CustomerLoyaltySegment | undefined; }>'.
      if (updates[key]) {
        // TODO 一時的にルールを無効化しています。気づいたベースで直してください
        //                   TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Partial<{ firstVisitedAt: { from?: string | undefined; to?: string | undefined; } | undefined; lastVisitedAt: { from?: string | undefined; to?: string | undefined; } | undefined; lastStylistId: number | undefined; loyaltySegment: CustomerLoyaltySegment | undefined; }>'.
        // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'CustomerFilter'.
        newFilter[key] = updates[key];
      } else {
        // TODO 一時的にルールを無効化しています。気づいたベースで直してください
        // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'CustomerFilter'.
        delete newFilter[key];
      }
    }
  } else {
    if (!newFilter.salon) {
      newFilter.salon = { salonId };
    }
    for (const key of keys) {
      // TODO 一時的にルールを無効化しています。気づいたベースで直してください
      // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Partial<{ firstVisitedAt: { from?: string | undefined; to?: string | undefined; } | undefined; lastVisitedAt: { from?: string | undefined; to?: string | undefined; } | undefined; lastStylistId: number | undefined; loyaltySegment: CustomerLoyaltySegment | undefined; }>'.
      if (updates[key]) {
        // TODO 一時的にルールを無効化しています。気づいたベースで直してください
        //                   TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Partial<{ firstVisitedAt: { from?: string | undefined; to?: string | undefined; } | undefined; lastVisitedAt: { from?: string | undefined; to?: string | undefined; } | undefined; lastStylistId: number | undefined; loyaltySegment: CustomerLoyaltySegment | undefined; }>'.
        // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ salonId: number; loyaltySegment?: CustomerLoyaltySegment | undefined; firstVisitedAt?: { from?: string | undefined; to?: string | undefined; } | undefined; lastVisitedAt?: { ...; } | undefined; lastStylistId?: number | undefined; lineStates?: SalonCustomerLineState[] | undefined; }'.
        newFilter.salon[key] = updates[key];
      } else {
        // TODO 一時的にルールを無効化しています。気づいたベースで直してください
        // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ salonId: number; loyaltySegment?: CustomerLoyaltySegment | undefined; firstVisitedAt?: { from?: string | undefined; to?: string | undefined; } | undefined; lastVisitedAt?: { ...; } | undefined; lastStylistId?: number | undefined; lineStates?: SalonCustomerLineState[] | undefined; }'.
        delete newFilter.salon[key];
      }
    }
  }
  return newFilter;
}

const CCustomerFilter: FC<OwnProps> = React.memo(
  function CCustomerFilter(props) {
    const {
      salonId,
      fixedFilter,
      filter,
      hasGroupSalon,
      isUseGroupDataFixed = false,
      useGroupData,
      stylists,
      onChangeFilter,
    } = props;

    const mergedFilter: CustomerFilter = useMemo(
      () => _.merge({}, filter, fixedFilter),
      [filter, fixedFilter]
    );

    const handleToggleUseGroupData = useCallback(() => {
      const newUseGroupData = !useGroupData;
      onChangeFilter(
        convertFilter(salonId, newUseGroupData, mergedFilter),
        newUseGroupData
      );
    }, [mergedFilter, onChangeFilter, salonId, useGroupData]);

    const handleChangeBaseFilter = useCallback(
      (updates: Partial<{ [key in BaseKey]: CustomerFilter[key] }>) => {
        onChangeFilter(
          removeUndefField({ ...mergedFilter, ...updates }),
          useGroupData
        );
      },
      [mergedFilter, onChangeFilter, useGroupData]
    );

    const handleChangeCommonFilter = useCallback(
      (updates: Partial<{ [key in CommonKey]: CustomerFilter[key] }>) => {
        onChangeFilter(
          changeCommonKey(salonId, useGroupData, mergedFilter, updates),
          useGroupData
        );
      },
      [mergedFilter, onChangeFilter, salonId, useGroupData]
    );

    const loyaltySegment = useGroupData
      ? mergedFilter.loyaltySegment
      : mergedFilter.salon?.loyaltySegment;
    const firstVisitedAt = useGroupData
      ? mergedFilter.firstVisitedAt
      : mergedFilter.salon?.firstVisitedAt;
    const lastVisitedAt = useGroupData
      ? mergedFilter.lastVisitedAt
      : mergedFilter.salon?.lastVisitedAt;
    const lastStylistId = useGroupData
      ? mergedFilter.lastStylistId
      : mergedFilter.salon?.lastStylistId;

    return (
      <Grid container direction="row" spacing={2}>
        {hasGroupSalon && (
          <Grid item xs={12}>
            <FormControlLabel
              disabled={isUseGroupDataFixed}
              control={
                <Checkbox
                  checked={useGroupData}
                  onChange={handleToggleUseGroupData}
                />
              }
              label="グループのデータを表示"
            />
            <CInformation
              type="tooltip"
              ml={0}
              content={
                isUseGroupDataFixed
                  ? `ここでは${
                      useGroupData ? 'グループ全体' : '当サロン'
                    }の来店データのみ表示できます`
                  : '当サロンの来店データを表示するか、グループ全体での来店データを表示するか切り替えられます'
              }
            />
          </Grid>
        )}
        <Grid item xs={6} sm={3} md={2} lg={2} xl={2}>
          <CTextInput
            label="名前"
            value={mergedFilter.name || ''}
            onChange={(name) => handleChangeBaseFilter({ name: name.trim() })}
            disabled={fixedFilter?.name !== undefined}
          />
        </Grid>
        <Grid item container xs={6} sm={3} md={2} lg={2} xl={2}>
          <CSelect
            fullWidth
            label="顧客セグメント"
            options={CustomerLoyaltySegments.map((loyaltySegment) => ({
              value: loyaltySegment,
              element: CustomerLoyaltySegmentText[loyaltySegment],
            }))}
            value={loyaltySegment}
            onChange={(loyaltySegment) =>
              handleChangeCommonFilter({ loyaltySegment })
            }
            disabled={
              (useGroupData
                ? fixedFilter?.loyaltySegment
                : fixedFilter?.salon?.loyaltySegment) !== undefined
            }
            displayEmpty
          />
        </Grid>
        <Grid item container xs={6} sm={3} md={2} lg={2} xl={2}>
          <CStylistSelect
            fullWidth
            label="前回担当者"
            stylists={stylists}
            selected={lastStylistId}
            onChange={(lastStylistId) =>
              handleChangeCommonFilter({ lastStylistId })
            }
            disabled={
              (useGroupData
                ? fixedFilter?.lastStylistId
                : fixedFilter?.salon?.lastStylistId) !== undefined
            }
            displayEmpty
          />
        </Grid>
        <Grid item container xs={6} sm={3} md={2} lg={2} xl={2}>
          <CSelect
            fullWidth
            label="誕生月"
            options={Array(12)
              .fill(0)
              .map((_, i) => ({ value: `${i + 1}`, element: `${i + 1}月` }))}
            value={filter.birthdayMonth}
            onChange={(birthdayMonth) =>
              handleChangeBaseFilter({ birthdayMonth })
            }
            disabled={fixedFilter?.birthdayMonth !== undefined}
            displayEmpty
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2} lg={2} xl={2}>
          <CTextInput
            label="メモ"
            value={filter.memo || ''}
            onChange={(memo) => handleChangeBaseFilter({ memo: memo.trim() })}
            disabled={fixedFilter?.memo !== undefined}
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2} lg={2} xl={2}>
          <CTextInput
            label="電話番号"
            value={filter.phone || ''}
            placeholder="完全一致検索"
            onChange={(phone) =>
              handleChangeBaseFilter({ phone: phone.trim() })
            }
            disabled={fixedFilter?.phone !== undefined}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <CDateRange
            label="初回来店日"
            clearable={true}
            from={firstVisitedAt?.from}
            to={firstVisitedAt?.to}
            onChange={(from, to) => {
              const firstVisitedAt =
                from === undefined && to === undefined
                  ? undefined
                  : { from, to };
              handleChangeCommonFilter({ firstVisitedAt });
            }}
            disabled={
              (useGroupData
                ? fixedFilter?.firstVisitedAt
                : fixedFilter?.salon?.firstVisitedAt) !== undefined
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <CDateRange
            label="最終来店日"
            clearable={true}
            from={lastVisitedAt?.from}
            to={lastVisitedAt?.to}
            onChange={(from, to) => {
              const lastVisitedAt =
                from === undefined && to === undefined
                  ? undefined
                  : { from, to };
              handleChangeCommonFilter({ lastVisitedAt });
            }}
            disabled={
              (useGroupData
                ? fixedFilter?.lastVisitedAt
                : fixedFilter?.salon?.lastVisitedAt) !== undefined
            }
            fullWidth
          />
        </Grid>
      </Grid>
    );
  },
  (prev, next) => _.isEqual(prev, next)
);

export default CCustomerFilter;
