import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M4.75 6a1.75 1.75 0 1 0-3.5 0v35.5a3.25 3.25 0 0 0 3.25 3.25H45a1.75 1.75 0 1 0 0-3.5H4.75zm6 14.875c0-.069.056-.125.125-.125h3.75c.069 0 .125.056.125.125v14.25a.125.125 0 0 1-.125.125h-3.75a.125.125 0 0 1-.125-.125zm.125-3.625a3.625 3.625 0 0 0-3.625 3.625v14.25a3.625 3.625 0 0 0 3.625 3.625h3.75a3.625 3.625 0 0 0 3.625-3.625v-14.25a3.625 3.625 0 0 0-3.625-3.625zm13.5-1a.125.125 0 0 0-.125.125v18.75c0 .069.056.125.125.125h3.75a.125.125 0 0 0 .125-.125v-18.75a.125.125 0 0 0-.125-.125zm-3.625.125a3.625 3.625 0 0 1 3.625-3.625h3.75a3.625 3.625 0 0 1 3.625 3.625v18.75a3.625 3.625 0 0 1-3.625 3.625h-3.75a3.625 3.625 0 0 1-3.625-3.625zm16.966-6c0-.069.056-.125.125-.125h3.75c.07 0 .125.056.125.125v24.75a.125.125 0 0 1-.125.125h-3.75a.125.125 0 0 1-.125-.125zm.125-3.625a3.625 3.625 0 0 0-3.625 3.625v24.75a3.625 3.625 0 0 0 3.625 3.625h3.75a3.625 3.625 0 0 0 3.625-3.625v-24.75a3.625 3.625 0 0 0-3.625-3.625z"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgChartBar = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgChartBar);
export default Memo;
