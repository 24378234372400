import { Action } from 'redux';
import { ViewActions } from '../actions/viewAction';
import { ChatActions } from '../reducers/chatReducer';
import { GeneralActions } from '../reducers/generalReducer';
import { SalonActions } from '../reducers/salonReducer';
import { SettingsActions } from '../reducers/settingsReducer';
import { UIActions } from '../reducers/uiReducer';
import { UploadedPaperActions } from '../reducers/uploadedPaperReducer';

export enum ActionNames {
  ClearState = 'root/ClearState',
}

export interface ClearStateAction extends Action {
  type: ActionNames.ClearState;
  clearSettings?: boolean;
}

export const clearState = (clearSettings?: boolean): ClearStateAction => ({
  clearSettings,
  type: ActionNames.ClearState,
});

export type GlobalActions =
  | ClearStateAction
  | GeneralActions
  | SalonActions
  | ChatActions
  // CustomerActions
  | SettingsActions
  | UploadedPaperActions
  | ViewActions
  | UIActions;
