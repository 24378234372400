import Linkify from 'react-linkify';

const CLinkify: FC = (props) => {
  return (
    <Linkify
      componentDecorator={(
        decoratedHref: string,
        decoratedText: string,
        key: number
      ) => {
        return <Link key={key} href={decoratedHref} text={decoratedText} />;
      }}
    >
      {props.children}
    </Linkify>
  );
};

const Link: FC<{ href: string; text: string }> = (props) => {
  const { href, text } = props;
  return (
    <a href={href} target="_blank" rel="noreferrer">
      {text}
    </a>
  );
};

export default CLinkify;
