import { Card, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Sticky, StickyContainer } from 'react-sticky';
import { pushSnackbar } from '../../../actions/generalAction';
import { updateCounseling } from '../../../actions/salon/counselingAction';
import { headerHeight } from '../../../components/Header';
import CButton from '../../../components_old/atoms/CButton';
import CDivider from '../../../components_old/atoms/CDivider';
import CInformation from '../../../components_old/atoms/CInformation';
import CSticky from '../../../components_old/atoms/CSticky';
import CCounselingList from '../../../components_old/molecules/CCounselingList';
import CAddSectionArea from '../../../components_old/organisms/couseling/CAddSectionArea';
import CQuestionSection from '../../../components_old/organisms/couseling/CQuestionSection';
import {
  CounselingItem,
  CounselingQuestionOption,
  PartialCounselingQuestion,
  PartialCounselingSection,
} from '../../../models/counseling';
import { checkPermission } from '../../../models/stylist';
import {
  selectSortedActiveCounselingsBySalonId,
  useSelectMe,
  useSelectMySalon,
} from '../../../selectors/salonSelector';
import { GlobalState } from '../../../store';
import DndProviderTemplate from '../../../templates/DndProviderTemplate';
import {
  WithConfirmDialog,
  withConfirmDialog,
} from '../../../templates/hoc/ConfirmDialogHOC';
import { InformationText } from '../../../texts/infomation';
import { useThunkDispatch } from '../../../util/hooks/useThunkDispatch';
import { useWidthUp } from '../../../util/hooks/useWidth';

type Props = WithConfirmDialog;

// 文字入力のたびにcloneDeepが走るのどうにかできないか。。

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '76px 20px 20px 20px',
  },
  saveButtonContainer: {
    width: '30%',
    padding: theme.spacing(1),
  },
  section: {
    marginBottom: theme.spacing(2),
  },
  sectionWrapper: {
    marginBottom: theme.spacing(2),
  },
  previewExplanation: {
    padding: theme.spacing(2),
  },
  previewCounselingContainer: {
    height: `calc(100vh - ${headerHeight + 80}px)`,
    overflow: 'auto',
  },
}));

const CounselingBase: FC<Props> = (props) => {
  const classes = useStyles();

  const { openConfirmDialog, closeConfirmDialog } = props;

  const salon = useSelectMySalon();
  const me = useSelectMe();
  const counselings = useSelector((state: GlobalState) =>
    selectSortedActiveCounselingsBySalonId(state, salon.id)
  );
  const dispatch = useThunkDispatch();

  const canUpdate = checkPermission(me, 'canUpdateCustomerOpinion');
  const canDelete = checkPermission(me, 'canDeleteCustomerOpinion'); // isNewItemは権限がなくても削除できるようにしておく
  const [sections, setSections] = useState<CounselingItem[]>(counselings);
  useEffect(() => {
    setSections(counselings);
  }, [setSections, counselings]);

  const [isUpdated, setIsUpdated] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [previewMap, setPreviewMap] = useState<{
    [optionId: number]: { value: string | null };
  }>({});
  const [draggingSectionId, setDraggingSectionId] = useState<number | null>(
    null
  );

  const sectionsRef = useRef(sections);
  useEffect(() => {
    sectionsRef.current = sections;
  }, [sections]);

  const updateSection = useCallback(
    (updatedIndex: number, updatedSection: CounselingItem | null) => {
      const cloneSections = [...sectionsRef.current];
      if (updatedSection) {
        cloneSections[updatedIndex] = updatedSection;
      } else {
        cloneSections.splice(updatedIndex, 1);
      }
      setSections(cloneSections);
      setIsUpdated(true);
    },
    [sectionsRef]
  );

  // drag用の関数
  const moveSection = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragSection = sectionsRef.current[dragIndex];
      const cloneSections = [...sectionsRef.current];
      cloneSections.splice(dragIndex, 1);
      cloneSections.splice(hoverIndex, 0, dragSection);
      setSections(cloneSections);
      setIsUpdated(true);
    },
    [sectionsRef]
  );

  // drag監視関数
  const onChangeIsDragging = useCallback(
    (sectionId: number, isDragging: boolean) => {
      if (isDragging) {
        setDraggingSectionId(sectionId);
      } else if (draggingSectionId === sectionId) {
        setDraggingSectionId(null);
      }
    },
    [draggingSectionId, setDraggingSectionId]
  );

  const savedMaxOptionId = useMemo(
    () =>
      _.max(
        sections
          .filter((s) => !s.isNewItem)
          .map(
            (s) =>
              _.max(
                s.questions
                  .filter((q) => !q.isNewItem)
                  .map(
                    (q) =>
                      _.max(
                        q.options.filter((o) => !o.isNewItem).map((o) => o.id)
                      ) ?? 0
                  )
              ) ?? 0
          )
      ) ?? 0,
    [sections]
  );

  const renderSection = (section: CounselingItem, index: number) => {
    // isActive:falseのものも含めたindexを採番しておく
    if (!section.isActive) {
      return;
    }
    const previousActiveIndex =
      [...Array(index + 1)]
        .map((_, i) => index - i)
        .find((i) => sections[i].isActive && i < index) ?? -1;
    const nextActiveIndex = sections.findIndex(
      (s, i) => s.isActive && i > index
    );
    return (
      <div key={section.id} className={classes.section}>
        <CQuestionSection
          key={section.id}
          section={section}
          index={index}
          previousActiveIndex={previousActiveIndex}
          nextActiveIndex={nextActiveIndex}
          canDelete={canDelete}
          canUpdate={canUpdate}
          isLoading={isLoading}
          isCollapsed={
            draggingSectionId !== null && draggingSectionId !== section.id
          }
          savedMaxOptionId={savedMaxOptionId}
          onChangeIsDraging={onChangeIsDragging}
          moveSection={moveSection}
          updateSection={updateSection}
        />
      </div>
    );
  };

  // option.description === ''の場合はは保存させない
  const canSave = (): string | null => {
    for (const s of sections) {
      if (s.isActive && !s.title) {
        return 'タイトルが未入力のセクションがあります';
      }
      for (const q of s.questions) {
        if (q.isActive && !q.title) {
          return 'タイトルが未入力の質問があります';
        }
        for (const option of q.options) {
          if (option.isActive && !option.description) {
            return '未入力の質問項目があります';
          }
        }
      }
    }

    return null;
  };

  const handleSave = async () => {
    const error = canSave();
    if (error !== null) {
      openConfirmDialog({
        title: 'エラー',
        description: error,
        onOk: () => {
          closeConfirmDialog();
        },
      });
      return;
    }

    setIsLoading(true);

    // 中身を整形(id消してorder直す)してからsave
    const sectionsForSave = _.cloneDeep(sections).map((s, sectionIndex) => {
      const sectionForSave: PartialCounselingSection = {
        order: sections.length - sectionIndex,
        title: s.title,
        description: s.description,
        isActive: s.isActive,
        questions: [],
      };
      if (!s.isNewItem) {
        sectionForSave.id = s.id;
      }
      sectionForSave.questions = s.questions.map((q, questionIndex) => {
        const questionForSave: PartialCounselingQuestion = {
          counselingId: q.counselingId,
          order: s.questions.length - questionIndex,
          type: q.type,
          title: q.title,
          description: q.description,
          isActive: q.isActive,
          options: [],
        };
        if (!q.isNewItem) {
          questionForSave.id = q.id;
        }
        questionForSave.options = q.options.map((option, optionIndex) => {
          const optionForSave: Partial<CounselingQuestionOption> = {
            questionId: option.questionId,
            order: q.options.length - optionIndex,
            description: option.description,
            textPlaceholder: option.textPlaceholder,
            isActive: option.isActive,
          };
          if (!option.isNewItem) {
            optionForSave.id = option.id;
          }
          return optionForSave;
        });

        return questionForSave;
      });
      return sectionForSave;
    });

    await dispatch(updateCounseling(sectionsForSave));

    dispatch(
      pushSnackbar({
        message:
          '保存完了しました。アプリを再起動すれば保存内容が反映されます。',
        type: 'success',
      })
    );

    setIsLoading(false);
    setIsUpdated(false);
  };

  const isPreviewSticky = useWidthUp('md');
  const previewComponent = (
    <div>
      <div className={classes.previewExplanation}>
        <Typography variant="body1" align="center" color="textSecondary">
          こちらにカウンセリング項目の表示イメージが表示されます
        </Typography>
      </div>

      <Card className={classes.previewCounselingContainer}>
        <CCounselingList
          counselings={sections}
          map={previewMap}
          onChange={setPreviewMap}
        />
      </Card>
    </div>
  );

  const headerStickyOffset = headerHeight + 4;
  const saveButtonComponent = (
    <>
      <CInformation
        type="dialog"
        content={InformationText.counselings.overall}
        size="lg"
        mb={8}
      />

      <CButton
        disabled={!canUpdate || isLoading || !isUpdated}
        onClick={handleSave}
      >
        保存
      </CButton>
    </>
  );

  const handleAddSection = () => {
    const sectionWithMaxId = _.maxBy(sections, 'id');
    const tmpIndex = sections.length;
    const tmpSecitonId = sectionWithMaxId ? sectionWithMaxId.id + 1 : 1;
    updateSection(tmpIndex, {
      id: tmpSecitonId,
      order: 0,
      title: '',
      description: null,
      isNewItem: true,
      isActive: true,
      questions: [],
    });
  };

  return (
    <div className={classes.root}>
      <StickyContainer>
        {canUpdate ? (
          <Sticky topOffset={-headerStickyOffset}>
            {({ style, isSticky }) => (
              <div
                style={{
                  ...style,
                  zIndex: 100,
                  ...(isSticky ? { top: headerStickyOffset } : {}),
                }}
              >
                {isSticky ? (
                  <Card className={classes.saveButtonContainer} square>
                    {saveButtonComponent}
                  </Card>
                ) : (
                  <Card
                    className={classes.saveButtonContainer}
                    square
                    style={{ backgroundColor: 'transparent' }}
                    elevation={0}
                  >
                    {saveButtonComponent}
                  </Card>
                )}
              </div>
            )}
          </Sticky>
        ) : (
          <Typography variant="h5">※ 編集権限がありません。</Typography>
        )}

        <CDivider spacing={3} />

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <DndProviderTemplate>
              <div>
                <div className={classes.sectionWrapper}>
                  {sections.map((s, i) => renderSection(s, i))}
                </div>
                <CAddSectionArea
                  disabled={isLoading || !canUpdate}
                  onClick={handleAddSection}
                />
              </div>
            </DndProviderTemplate>
          </Grid>
          <Grid item xs={12} md={6}>
            {isPreviewSticky ? (
              <CSticky>{previewComponent}</CSticky>
            ) : (
              previewComponent
            )}
          </Grid>
        </Grid>
      </StickyContainer>
    </div>
  );
};
export const CounselingSettings = withConfirmDialog(CounselingBase);
