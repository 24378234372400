import { moment } from '@karutekun/shared/util/datetime';
import { BasicPlan, MySalon, OptionLinePlan } from '../models/salon';

/**
 * サロンの契約状態、設定状態をまとめるオブジェクト
 */
export type SalonStatus = {
  plan: {
    trial: boolean;
    basic: boolean;
    basicStandard: boolean;
    line: boolean;
  };
  settings: {
    line: boolean;
    reservation: boolean;
    businessInfo: boolean;
  };

  /**
   * プランに関わらずトライアル期間終了前かを判定する
   */
  isWithinTrialPeriod(): boolean;

  /**
   * トライアル中かどうか判定する
   */
  isTrialing(): boolean;

  /**
   * プランが有効かどうか判定。トライアルの場合も加味する
   */
  isAvailable(checkKey?: keyof SalonStatus['plan']): boolean;

  /**
   * 設定が済んでいるかどうか判定する
   */
  hasSetting(checkKey: keyof SalonStatus['settings']): boolean;
};

export function buildSalonStatus(
  info: Pick<
    MySalon,
    | 'isPlanDisabled'
    | 'basicPlan'
    | 'optionLinePlan'
    | 'trialFinishAt'
    | 'lineSetting'
    | 'reservationSettings'
    | 'scheduleSettings'
  >
): SalonStatus {
  const plan: SalonStatus['plan'] = info.isPlanDisabled
    ? {
        trial: false,
        basic: false,
        basicStandard: false,
        line: false,
      }
    : {
        trial: info.basicPlan === BasicPlan.Trial,
        basic:
          info.basicPlan === BasicPlan.Single ||
          info.basicPlan === BasicPlan.Standard ||
          info.basicPlan === BasicPlan.Unlimited,
        basicStandard:
          info.basicPlan === BasicPlan.Standard ||
          info.basicPlan === BasicPlan.Unlimited,
        line:
          info.optionLinePlan === OptionLinePlan.Single ||
          info.optionLinePlan === OptionLinePlan.Standard,
      };

  const settings: SalonStatus['settings'] = {
    line: info.lineSetting !== null,
    reservation: info.lineSetting !== null && info.reservationSettings !== null,
    businessInfo: info.scheduleSettings.basicSetting.showBusinessInfo,
  };

  return {
    plan,
    settings,
    isWithinTrialPeriod() {
      return info.trialFinishAt > moment().unix();
    },
    isTrialing() {
      return plan.trial && this.isWithinTrialPeriod();
    },
    isAvailable(checkKey = 'basic') {
      // 基本プランだけ契約した場合にトライアル期間終了までLINE機能が使えるようにしておく
      return (
        plan[checkKey] ||
        this.isTrialing() ||
        (checkKey === 'line' && this.isWithinTrialPeriod())
      );
    },
    hasSetting(checkKey) {
      return settings[checkKey];
    },
  };
}
