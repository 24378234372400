import Typography from '@mui/material/Typography';
import ImageEmpty from '../../assets/img/common/empty.png';

type OwnProps = {
  title: string;
};

const CEmpty: FC<OwnProps> = (props) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img src={ImageEmpty} alt="" style={{ width: 200, height: 200 }} />
      <Typography variant="subtitle1" align="center">
        {props.title}
      </Typography>
    </div>
  );
};

export default CEmpty;
