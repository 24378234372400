import { dayjs, thisMonth } from '@karutekun/shared/util/datetime';
import { zMonthRange } from './month-range';

export const zPastMonthRange = zMonthRange.transform(({ from, to }) => {
  const now = thisMonth();
  const fromDayjs = dayjs(from, 'YYYY-MM');
  const toDayjs = dayjs(to, 'YYYY-MM');
  if (fromDayjs.isAfter(now)) {
    from = now;
  }
  if (toDayjs.isAfter(now)) {
    to = now;
  }
  return { from, to };
});
