import { zodResolver } from '@hookform/resolvers/zod';
import { cancellationQuestionnaireSchema } from '@karutekun/shared/api-schema/salon';
import { z } from '@karutekun/shared/util/zod';
import { CButton } from '@karutekun/shared-fe/react-ui-old';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback, useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';

type Props = {
  open: boolean;
  onCancelPlan(data: z.infer<typeof cancellationQuestionnaireSchema>): void;
  onClose(): void;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  headline: {
    marginBottom: theme.spacing(4),
  },
  label: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1),
  },
  requiredAsterisk: {
    color: theme.palette.accent.main,
  },
}));

export const CancelSubscriptionDialog: FC<Props> = ({
  open,
  onClose,
  onCancelPlan,
}) => {
  const classes = useStyles();
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { isValid, isValidating, errors },
  } = useForm<z.infer<typeof cancellationQuestionnaireSchema>>({
    mode: 'onChange',
    resolver: zodResolver(cancellationQuestionnaireSchema),
    defaultValues: {
      checkboxes: {
        dissatisfiedWithFunctionality: false,
        dissatisfiedWithPrice: false,
        shopClosed: false,
        other: false,
      },
      otherReason: '',
      reasonDetail: '',
      otherServiceName: '',
      feedback: '',
    },
  });

  const isUserOtherService = useWatch({ control, name: 'isUseOtherService' });

  // NOTE: 他のサービスを利用しない場合は他のサービス名を記入するフォームの値をリセット
  useEffect(() => {
    if (isUserOtherService === 'no') {
      setValue('otherServiceName', '', { shouldValidate: true });
    }
  }, [isUserOtherService, setValue]);

  const onSubmit = (data: z.infer<typeof cancellationQuestionnaireSchema>) => {
    onCancelPlan(data);
  };

  const handleClose = useCallback(() => {
    onClose();
    reset();
  }, [onClose, reset]);

  const canSend = isValid && !isValidating;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Typography variant="h6">解約アンケート</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Stack spacing={4}>
          <FormControl required>
            <FormLabel className={classes.label}>
              解約のご理由をお選びください
            </FormLabel>
            <FormGroup>
              <Controller
                name="checkboxes.dissatisfiedWithFunctionality"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    label="機能に満足できなかった"
                    control={<Checkbox {...field} />}
                  />
                )}
              />
              <Controller
                name="checkboxes.dissatisfiedWithPrice"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    label="料金に満足できなかった"
                    control={<Checkbox {...field} />}
                  />
                )}
              />
              <Controller
                name="checkboxes.shopClosed"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    label="お店を休止することにした"
                    control={<Checkbox {...field} />}
                  />
                )}
              />
              <Stack direction="row">
                <Controller
                  name="checkboxes.other"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      label="その他"
                      control={<Checkbox {...field} />}
                    />
                  )}
                />
                <Controller
                  name="otherReason"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      variant="standard"
                      placeholder="回答を入力"
                      {...field}
                      sx={{ flex: 1 }}
                    />
                  )}
                />
              </Stack>
            </FormGroup>
            {errors.checkboxes && (
              <Typography color="error" variant="caption">
                {errors.checkboxes.message}
              </Typography>
            )}
          </FormControl>
          <FormControl required>
            <FormLabel className={classes.label}>
              解約理由の詳細をご記入ください
            </FormLabel>
            <Controller
              name="reasonDetail"
              control={control}
              render={({ field }) => (
                <TextField
                  multiline
                  variant="outlined"
                  placeholder="回答を入力"
                  {...field}
                />
              )}
            />
          </FormControl>
          <FormControl required>
            <FormLabel className={classes.label}>
              KaruteKunの解約後に、別の電子カルテやPOSサービス等をご利用されますか？
            </FormLabel>
            <Controller
              name="isUseOtherService"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="はい"
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio />}
                    label="いいえ"
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
          {isUserOtherService === 'yes' && (
            <FormControl required>
              <FormLabel className={classes.label}>
                新たにご利用されるサービスのお名前・会社名のご記入ください
              </FormLabel>
              <Controller
                name="otherServiceName"
                control={control}
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    placeholder="回答を入力"
                    {...field}
                  />
                )}
              />
            </FormControl>
          )}
          <FormControl required>
            <FormLabel className={classes.label}>
              今後ご不満であった箇所が改善された場合、再度KaruteKunをご利用される可能性はございますか？
            </FormLabel>
            <Controller
              name="isUseAgain"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <FormControlLabel
                    control={<Radio value="reconsider" />}
                    label="非常に前向きに再検討したい"
                  />
                  <FormControlLabel
                    control={<Radio value="possible" />}
                    label="可能性はある"
                  />
                  <FormControlLabel
                    control={<Radio value="difficult" />}
                    label="難しいと思うがなしではない"
                  />
                  <FormControlLabel
                    control={<Radio value="impossible" />}
                    label="可能性がないと思う"
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
          <FormControl>
            <FormLabel className={classes.label}>
              開発・運営チームに何かお伝えしたいことがあればご自由にご記入ください
            </FormLabel>
            <Controller
              name="feedback"
              control={control}
              render={({ field }) => (
                <TextField
                  multiline
                  variant="outlined"
                  placeholder="回答を入力"
                  {...field}
                />
              )}
            />
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <CButton variant="outlined" size="sm" onClick={handleClose}>
          キャンセル
        </CButton>
        <CButton
          variant="contained"
          size="sm"
          onClick={() => {
            handleSubmit(onSubmit)();
          }}
          disabled={!canSend}
        >
          アンケートに回答して解約
        </CButton>
      </DialogActions>
    </Dialog>
  );
};
