import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React, { CSSProperties, useContext } from 'react';
import CStylistAvatar from '../../../../components_old/atoms/CStylistAvatar';
import CTypographyIcon from '../../../../components_old/atoms/CTypographyIcon';
import { StylistShift } from '../../../../models/salonBusinessInfo';
import {
  WorkType,
  getShiftShortTitle,
} from '../../../../models/salonScheduleSettings';
import { PlainStylist } from '../../../../models/stylist';
import { NonAssignedReservationColor } from '../../../../models/view/viewSchedule';
import { ScheduleUnavailableBackground } from './ScheduleBusinessHourBackground';
import { ScheduleContext, ScheduleZIndex } from './context';

type Props = {
  stylist?: PlainStylist;
  shift?: StylistShift;
  layout: { yIndexStart: number; yIndexEnd: number };
  onClickBackground(stylistId: number | null, hour: number): void;
};

const useStyles = makeStyles((theme: Theme) => ({
  stylistHeader: {
    position: 'sticky',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    left: 0,
    zIndex: ScheduleZIndex.StylistHeader,
    backgroundColor: theme.palette.grey[100],
    overflow: 'hidden',
  },
  avatarContainer: {
    marginBottom: theme.spacing(1),
  },
}));

export const ScheduleStylistRow: FC<Props> = React.memo(
  function ScheduleStylistRow(props) {
    const classes = useStyles();
    const {
      getOffsetY,
      getOffsetX,
      getHeight,
      getWidth,
      sizes: { headerWidth },
      minuteInterval,
    } = useContext(ScheduleContext);

    const { stylist, shift, layout } = props;

    const horizontalGrids: React.ReactNode[] = [];
    for (let i = layout.yIndexStart; i < layout.yIndexEnd; i += 1) {
      horizontalGrids.push(
        <div
          key={i}
          style={{
            position: 'absolute',
            left: 0,
            top: getOffsetY(i),
            width: getWidth(24) + headerWidth,
            height: getHeight(1),
            borderBottom:
              i === layout.yIndexEnd - 1 ? '2px solid #bbb' : '1px solid #ccc',
          }}
        />
      );
    }

    const verticalGrids: React.ReactNode[] = [];
    const divs = 60 / minuteInterval;
    for (let i = 0; i < 24 * divs; i += 1) {
      const style: CSSProperties = {
        position: 'absolute',
        top: getOffsetY(layout.yIndexStart),
        left: getOffsetX(i / divs),
        width: getWidth(1) / divs,
        height: getHeight(layout.yIndexEnd - layout.yIndexStart),
        cursor: 'pointer',
      };
      if (i % divs === divs / 2 - 1) {
        style.borderRight = '1px dotted #ccc';
      } else if (i % divs === divs - 1) {
        style.borderRight = '1px solid #ccc';
      }

      verticalGrids.push(
        <div
          key={i}
          style={style}
          onClick={() => {
            if (stylist && !stylist.isActive) {
              return;
            }
            props.onClickBackground(stylist ? stylist.id : null, i / divs);
          }}
        />
      );
    }

    return (
      <>
        <div
          className={classes.stylistHeader}
          style={{
            left: 0,
            width: headerWidth,
            height: getHeight(layout.yIndexEnd - layout.yIndexStart),
            borderRight: `5px solid ${
              stylist ? stylist.color : NonAssignedReservationColor
            }`,
            borderBottom: '1px solid #ccc',
          }}
        >
          {stylist && (
            <div className={classes.avatarContainer}>
              <CStylistAvatar stylist={stylist} />
            </div>
          )}
          <Typography
            variant="body2"
            color={stylist && !stylist.isActive ? 'textSecondary' : 'initial'}
            noWrap
          >
            {stylist ? stylist.name : '担当なし'}
          </Typography>
          {shift && (
            <CTypographyIcon
              icon={<SVGIcon name="user-friends" size="sm" />}
              variant="caption"
              color="textSecondary"
            >
              {shift.capacity} / {getShiftShortTitle(shift)}
            </CTypographyIcon>
          )}
        </div>

        {shift && shift.workType !== WorkType.FullTime && (
          <ScheduleUnavailableBackground
            isClosed={shift.workType === WorkType.DayOff}
            from={shift.shiftPattern?.startTime}
            to={shift.shiftPattern?.endTime}
            yIndexStart={layout.yIndexStart}
            yIndexEnd={layout.yIndexEnd}
          />
        )}

        {horizontalGrids}
        {verticalGrids}
      </>
    );
  },
  (prev, next) => _.isEqual(prev, next)
);
