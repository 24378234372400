import { createSelector } from 'reselect';
import {
  BroadcastMessage,
  PlainBroadcastMessage,
} from '../models/broadcastMessage';
import { PlainStylist } from '../models/stylist';
import { selectPlainStylistMap } from '../selectors/salonSelector';
import { GlobalState } from '../store';

export const selectPlainBroadcastMessageMap = (
  state: GlobalState
): KeyMap<PlainBroadcastMessage> =>
  state.broadcastMessage.plainBroadcastMessageMap;

export const selectBroadcastMessageMap = createSelector(
  (state: GlobalState) => selectPlainBroadcastMessageMap(state),
  (state: GlobalState) => selectPlainStylistMap(state),
  (
    plainBoradcastMessageMap: KeyMap<PlainBroadcastMessage>,
    plainStylistMap: IdMap<PlainStylist>
  ): KeyMap<BroadcastMessage> => {
    const broadcastMessages = Object.values(plainBoradcastMessageMap);
    return broadcastMessages.reduce<KeyMap<BroadcastMessage>>(
      (prev, current) => {
        prev[current.id] = {
          ...current,
          editedStylist: current.editedStylistId
            ? (plainStylistMap[current.editedStylistId] ?? null)
            : null,
        };
        return prev;
      },
      {}
    );
  }
);
