import chroma from 'chroma-js';
import * as React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { tooltipProps, xAxisProps, yAxisProps } from '../../util/chart';
import CChartTooltip from './CChartTooltip';
import CEmpty from './CEmpty';

type ChartData = {
  id: number;
  name: string;
  value: number;
  color?: string;
};
export type GroupedChartData = ChartData & {
  groupId: number;
};
type OwnProps = {
  groupData: { [groupId: number]: { name: string; color: string } };
  data: GroupedChartData[];

  formatter?(value: number): string;

  sortGroupByValue?: boolean;
  sortGroupOrder?: 'asc' | 'desc';
  sortDataByValue?: boolean;
  sortDataOrder?: 'asc' | 'desc';

  // 各グループについて、この数を超えるデータ数ある場合は、以降がその他に集約される
  maxDataNumForEachGroup?: number;
};

export default class CChartBarGrouped extends React.PureComponent<OwnProps> {
  static defaultProps = {
    sortGroupOrder: 'desc',
    sortDataOrder: 'desc',
    maxDataNumForEachGroup: 10,
  };

  render() {
    const {
      groupData,
      formatter,
      sortGroupByValue,
      sortGroupOrder,
      sortDataByValue,
      sortDataOrder,
      maxDataNumForEachGroup,
    } = this.props;

    if (this.props.data.length === 0) {
      return <CEmpty title="該当データがありません" />;
    }

    const tooltipFormatter = formatter
      ? (value: string | number | (string | number)[]) =>
          formatter(Number(value))
      : undefined;

    // グループ自体のデータ
    const groupDataMap: {
      [groupId: number]: ChartData & { color: string };
    } = {};

    // グルーピングされた子データ
    const groupedDataMap: { [groupId: number]: ChartData[] } = {};

    for (const data of this.props.data) {
      // グループ自体のデータを作成
      if (!groupDataMap[data.groupId]) {
        groupDataMap[data.groupId] = {
          id: data.groupId,
          name: groupData[data.groupId].name,
          value: 0,
          color: groupData[data.groupId].color || '#8884d8',
        };
      }
      groupDataMap[data.groupId].value += data.value;

      // 小データをグルーピング
      if (!groupedDataMap[data.groupId]) {
        groupedDataMap[data.groupId] = [];
      }
      groupedDataMap[data.groupId].push(data);
    }

    // グループデータをソート
    const groupChartData = Object.values(groupDataMap);
    groupChartData.sort((a, b) => {
      const comp = sortGroupByValue ? b.value - a.value : b.id - a.id;
      return sortGroupOrder === 'desc' ? comp : -comp;
    });

    // グループの順番を生成
    const groupDataOrder = groupChartData.map((d) => d.id);

    // 最後にデータをソートしたりその他にまとめたりする
    const chartDataList: { groupId: number; [key: string]: number }[] = [];
    const chartDataMap: { [chartKey: string]: ChartData } = {};
    for (const groupId of groupDataOrder) {
      const groupedData = groupedDataMap[groupId];
      groupedData.sort((a, b) => {
        const comp = sortDataByValue ? b.value - a.value : b.id - a.id;
        return sortDataOrder === 'desc' ? comp : -comp;
      });

      // 子データが多い場合は "その他" に集約
      if (
        maxDataNumForEachGroup &&
        groupedData.length > maxDataNumForEachGroup
      ) {
        const tails = groupedData.splice(maxDataNumForEachGroup - 1);
        groupedData.push({
          id: tails[0].id,
          name: 'その他',
          value: tails.reduce((p, c) => p + c.value, 0),
        });
      }

      // 色を生成して設定
      const groupColor = chroma(groupDataMap[groupId].color);
      const brightColor = groupColor.brighten(2);
      chroma
        .scale([groupColor, brightColor])
        .mode('hcl')
        .colors(groupedData.length)
        .forEach((color, i) => {
          groupedData[i].color = color;
        });

      const keyValues = {};
      for (const data of groupedData) {
        const key = `data-${groupId}-${data.id}`;
        chartDataMap[key] = data;
        // TODO 一時的にルールを無効化しています。気づいたベースで直してください
        // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
        keyValues[key] = data.value;
      }
      chartDataList.push({ groupId, ...keyValues });
    }

    return (
      <ResponsiveContainer height="100%">
        <BarChart data={chartDataList} stackOffset="sign">
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis
            {...xAxisProps}
            dataKey="groupId"
            tickFormatter={(groupId) => groupData[groupId].name}
          />
          <YAxis {...yAxisProps} tickFormatter={this.props.formatter} />
          <Tooltip
            {...tooltipProps}
            labelFormatter={(groupId) => groupData[groupId].name}
            formatter={tooltipFormatter}
            content={<CChartTooltip showTotal />}
          />
          {Object.keys(chartDataMap).map((key) => (
            <Bar
              key={key}
              name={chartDataMap[key].name}
              dataKey={key}
              stackId="a"
              fill={chartDataMap[key].color}
              isAnimationActive={false}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
