import { getCalendar } from '@karutekun/shared/util/datetime';
import { moment } from '@karutekun/shared/util/datetime';
import { getCalendarDateColors } from '@karutekun/shared-fe/calendar';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import _ from 'lodash';
import React from 'react';
import CTimeRange from '../../../../../components_old/molecules/CTimeRange';
import {
  DailyBusinessInfoUpdate,
  PlainDailyBusinessInfo,
  getConstScheduleCapacity,
} from '../../../../../models/salonBusinessInfo';
import { ScheduleCapacitySelect } from '../ScheduleCapacitySettingCard/ScheduleCapacitySelect';

type Props = {
  year: number;
  month: number;
  businessInfoMap: DateMap<PlainDailyBusinessInfo>;
  updateMap: DateMap<DailyBusinessInfoUpdate>;
  hasPermission: boolean;
  onChange(date: string, updates: Partial<PlainDailyBusinessInfo>): void;
};

const useStyles = makeStyles((theme: Theme) => ({
  calendarContainer: {
    overflow: 'auto',
  },
  calendarHeader: {
    display: 'flex',
    flexDirection: 'row',
  },
  calendarWeekday: {
    flex: 1,
    minWidth: 100,
    maxWidth: 150,
    padding: theme.spacing(1),
    textAlign: 'center',
    backgroundColor: theme.palette.grey[500],
    color: '#fff',
  },
  calendarBody: {
    borderLeft: `1px ${theme.palette.divider} solid`,
  },
  calendarRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  dayContainer: {
    flex: 1,
    position: 'relative',
    minWidth: 100,
    maxWidth: 150,
    minHeight: 100,
    borderRight: `1px ${theme.palette.divider} solid`,
    borderBottom: `1px ${theme.palette.divider} solid`,
    padding: theme.spacing(0.5),
  },
  dayContainerEmpty: {
    backgroundColor: theme.palette.grey[50],
  },
  modifiedSymbol: {
    color: '#4caf50',
    fontWeight: theme.typography.fontWeightMedium,
  },
  dayTodayOverlay: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    border: `2px ${theme.palette.secondary.main} solid`,
    pointerEvents: 'none',
  },
  dayMarkerModifying: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    borderWidth: '12px 12px 0 0',
    borderColor: '#4caf50 transparent transparent transparent',
    borderStyle: 'solid',
  },
  dayHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  smallCheckBoxRoot: {
    padding: theme.spacing(0, 0.5, 0, 0),
  },
  checkboxLabel: {
    display: 'flex',
    flexWrap: 'nowrap',
    marginRight: 0,
  },
}));

export const BusinessInfoCalendar: FC<Props> = (props) => {
  const classes = useStyles();

  const { year, month, businessInfoMap, updateMap, hasPermission, onChange } =
    props;

  const now = moment();
  const weeks = getCalendar(year, month);

  return (
    <div className={classes.calendarContainer}>
      <div className={classes.calendarHeader}>
        {[1, 2, 3, 4, 5, 6, 7].map((dayOfWeek) => (
          <div key={dayOfWeek} className={classes.calendarWeekday}>
            {moment().isoWeekday(dayOfWeek).format('ddd')}
          </div>
        ))}
      </div>
      <div className={classes.calendarBody}>
        {weeks.map((week, i) => (
          <div key={i} className={classes.calendarRow}>
            {week.map((date, j) => {
              if (date === null) {
                return <DayEmpty key={j} />;
              }
              return (
                <Day
                  key={date}
                  date={date}
                  businessInfo={businessInfoMap[date]}
                  updates={updateMap[date]}
                  modifying={updateMap[date] !== undefined}
                  disabled={
                    !hasPermission || moment(date).endOf('day').isBefore(now)
                  }
                  isToday={now.format('YYYY-MM-DD') === date}
                  onChange={onChange}
                />
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

const Day: FC<{
  date: string;
  businessInfo: Nullable<PlainDailyBusinessInfo>;
  updates: Nullable<DailyBusinessInfoUpdate>;
  modifying: boolean;
  disabled: boolean;
  isToday: boolean;
  onChange(date: string, updates: Partial<DailyBusinessInfoUpdate>): void;
}> = React.memo(function Day(props) {
  const classes = useStyles();

  const {
    date,
    businessInfo,
    updates,
    modifying,
    disabled,
    isToday,
    onChange,
  } = props;

  const { color, backgroundColor } = getCalendarDateColors(date);

  if (!businessInfo) {
    return (
      <div className={clsx(classes.dayContainer, classes.dayContainerEmpty)}>
        <div className={classes.dayHeader}>
          <Box color={color}>
            <Typography variant="body1">{moment(date).format('D')}</Typography>
          </Box>
        </div>
        <Typography variant="caption" color="textSecondary">
          設定可能範囲外です
        </Typography>
      </div>
    );
  }

  const businessHour = updates?.businessHour || businessInfo.businessHour;
  const scheduleCapacity =
    typeof updates?.scheduleCapacity === 'number'
      ? updates?.scheduleCapacity
      : getConstScheduleCapacity(
          _.merge({}, businessInfo.scheduleCapacity, updates?.scheduleCapacity)
        );

  return (
    <div className={classes.dayContainer} style={{ backgroundColor }}>
      {isToday && <div className={clsx(classes.dayTodayOverlay)} />}
      {modifying && <div className={clsx(classes.dayMarkerModifying)} />}

      <div className={classes.dayHeader}>
        <Box color={color}>
          <Typography variant="body1">
            {moment(date).format('D')}{' '}
            {modifying && <span className={classes.modifiedSymbol}>*</span>}
          </Typography>
        </Box>
        <FormControlLabel
          className={classes.checkboxLabel}
          control={
            <Checkbox
              size="small"
              disabled={disabled}
              classes={{ root: classes.smallCheckBoxRoot }}
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              checked={businessHour.isNonBusinessDay}
              onChange={(_, checked) => {
                onChange(date, {
                  businessHour: {
                    ...businessHour,
                    isNonBusinessDay: checked,
                  },
                });
              }}
            />
          }
          label={<Typography variant="caption">休業日</Typography>}
        />
      </div>

      {!businessHour.isNonBusinessDay && (
        <>
          <Box mt={1}>
            <CTimeRange
              small={true}
              disabled={disabled}
              from={businessHour.openTime}
              to={businessHour.closeTime}
              minuteInterval={30}
              onChange={(from, to) => {
                onChange(date, {
                  businessHour: {
                    ...businessHour,
                    openTime: from,
                    closeTime: to,
                  },
                });
              }}
            />
          </Box>
          <Box mt={1}>
            <Tooltip title="同時受付可能数">
              <div>
                <ScheduleCapacitySelect
                  disabled={disabled}
                  capacity={scheduleCapacity}
                  onChange={(capacity) => {
                    onChange(date, { scheduleCapacity: capacity });
                  }}
                />
              </div>
            </Tooltip>
          </Box>
        </>
      )}
    </div>
  );
});

const DayEmpty: FC = React.memo(function DayEmpty() {
  const classes = useStyles();
  return (
    <div className={clsx(classes.dayContainer, classes.dayContainerEmpty)} />
  );
});
