import { VoucherLineFormData } from '@karutekun/shared/data-access/voucher';
import { useArray } from '@karutekun/shared/util/react-hooks';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectActiveStylists } from '../../../../../selectors/salonSelector';

const MaxStylistPerVoucherLine = 5;

type Props = {
  open: boolean;
  defaultIsShimei: boolean;
  onOk(stylists: VoucherLineFormData['stylists']): void;
  onCancel(): void;
};

const useStyles = makeStyles((_: Theme) => ({
  listItem: {
    width: 350,
  },
  listItemSecondaryAction: {
    paddingRight: 80,
  },
}));

const EditVoucherLineStylistDialog: FC<Props> = (props) => {
  const classes = useStyles();
  const { open, defaultIsShimei, onOk, onCancel } = props;

  const stylists = useSelector(selectActiveStylists);

  const [assign, assignMutations] = useArray<
    VoucherLineFormData['stylists'][number]
  >([]);

  useEffect(() => {
    if (open) {
      assignMutations.clear();
    }
  }, [assignMutations, open]);

  const handleClick = useCallback(
    (stylistId: number) => {
      assignMutations.toggle(
        { stylistId, isShimei: defaultIsShimei },
        (v) => v.stylistId === stylistId
      );
    },
    [assignMutations, defaultIsShimei]
  );
  const handleClickShimei = useCallback(
    (stylistId: number, isShimei: boolean) => {
      assignMutations.findAndReplace(
        { stylistId, isShimei },
        (as) => as.stylistId === stylistId
      );
    },
    [assignMutations]
  );

  const handleClickOk = useCallback(() => {
    onOk(assign);
  }, [assign, onOk]);

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogContent dividers>
        <List dense>
          {stylists.map((s) => {
            const as = assign.find((as) => as.stylistId === s.id);
            return (
              <ListItem
                dense
                key={s.id}
                classes={{
                  root: classes.listItem,
                  secondaryAction: classes.listItemSecondaryAction,
                }}
                button
                onClick={() => {
                  if (as || assign.length < MaxStylistPerVoucherLine) {
                    handleClick(s.id);
                  }
                }}
              >
                <ListItemIcon>
                  <Checkbox edge="start" checked={!!as} disableRipple />
                </ListItemIcon>
                <ListItemText primary={s.name} />
                {as && (
                  <ListItemSecondaryAction
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                    onClick={() => handleClickShimei(s.id, !as.isShimei)}
                  >
                    <Typography variant="body2">指名</Typography>
                    <Checkbox edge="end" checked={as.isShimei} />
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onCancel}>
          キャンセル
        </Button>
        <Button variant="contained" color="primary" onClick={handleClickOk}>
          決定
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditVoucherLineStylistDialog;
