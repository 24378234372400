import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M39.52 10.954 23.315 27.16l5.211 12.842zM20.84 24.685 7.99 19.475 37.045 8.48zm24.243-18.54-14.19 37.494-.01.026a2.5 2.5 0 0 1-4.668-.05l-6.302-15.529-15.522-6.293a2.5 2.5 0 0 1-.038-4.683l.147-.055-.14.053L41.855 2.917l.004-.002a2.5 2.5 0 0 1 3.225 3.226z"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgTelegramPlane = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgTelegramPlane);
export default Memo;
