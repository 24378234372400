import { ServiceType } from '@karutekun/core/salon-service';
import { sumBy } from '@karutekun/shared/util/objects';
import { IVoucherLine } from '../data';

/**
 * 指定した ServiceType の売上を計算する
 */
export function getServiceTypeSalesAndTax(
  voucher: {
    lines: Pick<IVoucherLine, 'serviceType' | 'sales' | 'taxAmount'>[];
  },
  serviceType: ServiceType
) {
  const targetLines = voucher.lines.filter(
    (l) => l.serviceType === serviceType
  );
  return {
    sales: sumBy(targetLines, 'sales'),
    taxAmount: sumBy(targetLines, 'taxAmount'),
  };
}
