import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M16.586 11.336A2 2 0 0 1 18 10.75h19.5a2 2 0 0 1 2 2v22.5a2 2 0 0 1-2 2H18a2 2 0 0 1-2-2V31.5a1.75 1.75 0 1 0-3.5 0v3.75a5.5 5.5 0 0 0 5.5 5.5h19.5a5.5 5.5 0 0 0 5.5-5.5v-22.5a5.5 5.5 0 0 0-5.5-5.5H18a5.5 5.5 0 0 0-5.5 5.5v3.75a1.75 1.75 0 1 0 3.5 0v-3.75a2 2 0 0 1 .586-1.414m7.901 3.927a1.75 1.75 0 1 0-2.474 2.474l4.512 4.513H6.25a1.75 1.75 0 1 0 0 3.5h20.275l-4.512 4.513a1.75 1.75 0 1 0 2.474 2.474l7.5-7.5a1.75 1.75 0 0 0 0-2.474z"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgEnter = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgEnter);
export default Memo;
