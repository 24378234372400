import { RelKey, StrictRel } from '@karutekun/shared/util/entity';

export type BusinessHour = {
  isNonBusinessDay: boolean;
  openTime: string;
  closeTime: string;
};

export type ScheduleCapacityMap = {
  [hour: string]: { [minute: string]: number };
};

export enum WorkType {
  DayOff, // 休日
  FullTime, // 全日出勤
  Shift, // シフト勤務
}
export const WorkTypes = [WorkType.DayOff, WorkType.FullTime, WorkType.Shift];

export type Shift = {
  workType: WorkType;
  shiftPatternId: number | null;
};

export type StylistShift = {
  capacity: number;
  workType: WorkType;
  shiftPatternId: number | null;
  note: string | null;
};

export interface ISalonShiftPattern {
  id: number;
  salonId: number;
  title: string;
  titleShort: string;
  startTime: string | null;
  endTime: string | null;
  isActive: boolean;
  createdAt: number;
  updatedAt: number;
}

export interface ISalonBasicBusinessHourSetting {
  salonId: number;
  isMondayNonBusinessDay: boolean;
  mondayOpenTime: string;
  mondayCloseTime: string;
  isTuesdayNonBusinessDay: boolean;
  tuesdayOpenTime: string;
  tuesdayCloseTime: string;
  isWednesdayNonBusinessDay: boolean;
  wednesdayOpenTime: string;
  wednesdayCloseTime: string;
  isThursdayNonBusinessDay: boolean;
  thursdayOpenTime: string;
  thursdayCloseTime: string;
  isFridayNonBusinessDay: boolean;
  fridayOpenTime: string;
  fridayCloseTime: string;
  isSaturdayNonBusinessDay: boolean;
  saturdayOpenTime: string;
  saturdayCloseTime: string;
  isSundayNonBusinessDay: boolean;
  sundayOpenTime: string;
  sundayCloseTime: string;
  isHolidayNonBusinessDay: boolean;
  holidayOpenTime: string;
  holidayCloseTime: string;
  createdAt: number;
  updatedAt: number;
}

export interface ISalonBasicScheduleCapacitySetting {
  salonId: number;
  monday: number;
  tuesday: number;
  wednesday: number;
  thursday: number;
  friday: number;
  saturday: number;
  sunday: number;
  holiday: number;
  createdAt: number;
  updatedAt: number;
}

export interface ISalonDailyBusinessHour {
  salonId: number;
  date: string;
  isNonBusinessDay: boolean;
  openTime: string;
  closeTime: string;
  isManuallyModified: boolean;
  note: string | null;
  createdAt: number;
  updatedAt: number;
}

export interface ISalonDailyScheduleCapacity {
  salonId: number;
  date: string;
  capacityDetails: ScheduleCapacityMap;
  isManuallyModified: boolean;
  createdAt: number;
  updatedAt: number;
}

export interface ISalonStylistBasicShiftSetting {
  salonId: number;
  stylistId: number;
  capacity: number;
  mondayWorkType: WorkType;
  mondayShiftPatternId: number | null;
  tuesdayWorkType: WorkType;
  tuesdayShiftPatternId: number | null;
  wednesdayWorkType: WorkType;
  wednesdayShiftPatternId: number | null;
  thursdayWorkType: WorkType;
  thursdayShiftPatternId: number | null;
  fridayWorkType: WorkType;
  fridayShiftPatternId: number | null;
  saturdayWorkType: WorkType;
  saturdayShiftPatternId: number | null;
  sundayWorkType: WorkType;
  sundayShiftPatternId: number | null;
  holidayWorkType: WorkType;
  holidayShiftPatternId: number | null;
  isActive: boolean;
  createdAt: number;
  updatedAt: number;
}

export interface _ISalonStylistDailyShift {
  salonId: number;
  stylistId: number;
  date: string;
  capacity: number;
  workType: WorkType;
  shiftPatternId: number | null;
  note: string | null;
  isManuallyModified: boolean;
  createdAt: number;
  updatedAt: number;
  shiftPattern?: ISalonShiftPattern | null;
}
export type ISalonStylistDailyShift<
  K extends RelKey<_ISalonStylistDailyShift> = never,
> = StrictRel<_ISalonStylistDailyShift, K>;
