import { Divider, useTheme } from '@mui/material';
import { DividerProps } from '@mui/material/Divider';

type OwnProps = DividerProps & {
  spacing?: number;
};

const CDivider: FC<OwnProps> = ({ spacing, ...props }) => {
  const theme = useTheme();

  const margin = theme.spacing(spacing === undefined ? 1 : spacing);

  return (
    <Divider style={{ marginTop: margin, marginBottom: margin }} {...props} />
  );
};

export default CDivider;
