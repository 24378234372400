import { dayjs } from './dayjs';

export function nowUnix() {
  return dayjs().unix();
}

export function nowMillis() {
  return dayjs().valueOf();
}

export function startOfDayUnix() {
  return dayjs().startOf('day').unix();
}

export function today(d = 0) {
  return d === 0
    ? dayjs().format('YYYY-MM-DD')
    : dayjs().add(d, 'day').format('YYYY-MM-DD');
}

export function thisMonth(d = 0) {
  return d === 0
    ? dayjs().format('YYYY-MM')
    : dayjs().add(d, 'month').format('YYYY-MM');
}
