import { CustomerSex } from '@karutekun/core/customer';
import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import React, { CSSProperties } from 'react';

type OwnProps = {
  sex: CustomerSex;
  style?: CSSProperties;
};

export default class CCustomerSexIcon extends React.Component<OwnProps> {
  render() {
    const { sex, style } = this.props;
    return (
      <SVGIcon
        name="user-fill"
        color={sex === CustomerSex.Female ? '#ffc0cb' : '#add8e6'}
        style={style}
        size="sm"
      />
    );
  }
}
