import { TablePagination } from '@mui/material';

export const CVisitListPaginationControl: FC<{
  page: number;
  count: number;
  numPerPage: number;
  onChange?(page: number): void;
}> = ({ page, count, numPerPage, onChange }) => {
  return (
    <TablePagination
      component="div"
      count={count}
      rowsPerPage={numPerPage}
      rowsPerPageOptions={[]}
      page={page}
      onPageChange={(_, page) => onChange?.(page)}
      labelDisplayedRows={({ from, to, count }) =>
        `${from} - ${to} (${count} 件中)`
      }
    />
  );
};
