import { ServiceType } from '@karutekun/core/salon-service';
import { formatMoneyShrink } from '@karutekun/shared/util/format';
import { Grid } from '@mui/material';
import { useState } from 'react';
import { fmtMenuNum, fmtProductNum } from '../../../util/chart';
import CRadioGroup from '../../atoms/CRadioGroup';
import CChartGroupedService from '../../molecules/CChartGroupedService';

type Props = {
  chartType: 'pie' | 'bar';
  serviceType: ServiceType.Menu | ServiceType.Product;

  serviceNumMap: { [serviceId: number]: number };
  serviceOriginalPriceSumMap: { [serviceId: number]: number };
  serviceSalesSumMap: { [serviceId: number]: number };
};
export const CChartGroupedServiceSwitchable: FC<Props> = (props) => {
  const {
    chartType,
    serviceType,
    serviceNumMap,
    serviceOriginalPriceSumMap,
    serviceSalesSumMap,
  } = props;

  const [currentView, setCurrentView] = useState(0);

  let serviceValueMap;
  let formatter;
  switch (currentView) {
    default:
    case 0:
      serviceValueMap = serviceNumMap;
      formatter = serviceType === ServiceType.Menu ? fmtMenuNum : fmtProductNum;
      break;
    case 1:
      serviceValueMap = serviceOriginalPriceSumMap;
      formatter = formatMoneyShrink;
      break;
    case 2:
      serviceValueMap = serviceSalesSumMap;
      formatter = formatMoneyShrink;
      break;
  }

  // 最低限の幅は持たせておく
  const minWidth = 400;
  return (
    <Grid
      wrap="nowrap"
      container
      direction="column"
      style={{ height: '100%', overflowY: 'clip', overflowX: 'auto' }}
    >
      <Grid item style={{ flex: 1, minWidth }}>
        <CChartGroupedService
          chartType={chartType}
          serviceType={serviceType}
          serviceValueMap={serviceValueMap}
          formatter={formatter}
        />
      </Grid>
      <Grid item style={{ textAlign: 'center', minWidth }}>
        <CRadioGroup
          currentValue={currentView}
          options={[
            { value: 0, label: '回数' },
            { value: 1, label: '売上(割引適用前)' },
            { value: 2, label: '売上(割引適用後)' },
          ]}
          onChange={setCurrentView}
          row
        />
      </Grid>
    </Grid>
  );
};

export default CChartGroupedServiceSwitchable;
