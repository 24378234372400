import { CustomerSex } from '@karutekun/core/customer';
import * as React from 'react';
import Chart from 'react-apexcharts';

type Props = {
  sexAgeGroupMap: Nullable<{
    [CustomerSex.Male]: { [ageGroup: number]: number; unknown: number };
    [CustomerSex.Female]: { [ageGroup: number]: number; unknown: number };
  }>;
};

const chartKeys = ['60', '50', '40', '30', '20', '10', '0', 'unknown'];

function buildAgeGroupMap() {
  return {
    0: 0,
    10: 0,
    20: 0,
    30: 0,
    40: 0,
    50: 0,
    60: 0,
    unknown: 0,
  };
}

export const CChartBarSexAgeGroup: FC<Props> = React.memo((props) => {
  const { sexAgeGroupMap } = props;

  const valuesMap = {
    [CustomerSex.Male]: buildAgeGroupMap(),
    [CustomerSex.Female]: buildAgeGroupMap(),
  };
  for (const sex of [CustomerSex.Male, CustomerSex.Female]) {
    for (const ageGroupStr in sexAgeGroupMap?.[sex] ?? {}) {
      let key: string;
      if (ageGroupStr === 'unknown') {
        key = 'unknown';
      } else {
        const ageGroup = Number(ageGroupStr);
        key = ageGroup >= 60 ? '60' : `${ageGroup}`;
      }
      // TODO 一時的にルールを無効化しています。気づいたベースで直してください
      //                   TS7015: Element implicitly has an 'any' type because index expression is not of type 'number'.
      // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ 0: number; 10: number; 20: number; 30: number; 40: number; 50: number; 60: number; unknown: number; }'.
      valuesMap[sex][key] += sexAgeGroupMap?.[sex]?.[ageGroupStr] ?? 0;
    }
  }

  return (
    <Chart
      options={{
        chart: {
          toolbar: {
            show: false,
          },
          stacked: true,
        },
        dataLabels: {
          enabled: false,
        },
        colors: ['#FF4560', '#008FFB'],
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '80%',
          },
        },
        tooltip: {
          shared: false,
          x: {
            formatter(val: string) {
              return val;
            },
          },
          y: {
            formatter(val: number) {
              return `${Math.abs(val).toFixed(0)}人`;
            },
          },
        },
        xaxis: {
          categories: [
            '60歳以上',
            '50代',
            '40代',
            '30代',
            '20代',
            '10代',
            '10歳未満',
            '未入力',
          ],
          labels: {
            formatter(val: number) {
              return `${Math.abs(val).toFixed(0)}人`;
            },
          },
        },
      }}
      series={[
        {
          name: '女性',
          // TODO 一時的にルールを無効化しています。気づいたベースで直してください
          // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ 0: number; 10: number; 20: number; 30: number; 40: number; 50: number; 60: number; unknown: number; }'.
          data: chartKeys.map((k) => -valuesMap[CustomerSex.Female][k]),
        },
        {
          name: '男性',
          // TODO 一時的にルールを無効化しています。気づいたベースで直してください
          // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ 0: number; 10: number; 20: number; 30: number; 40: number; 50: number; 60: number; unknown: number; }'.
          data: chartKeys.map((k) => valuesMap[CustomerSex.Male][k]),
        },
      ]}
      type="bar"
      height="100%"
    />
  );
});
