import { ServiceType, ServiceTypeName } from '@karutekun/core/salon-service';
import { Backdrop, Card, Container, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { dispatchWithErrorHandling } from '../../../actions/helper/dispatchWithErrorHandling';
import {
  createService,
  createServiceCategory,
  createServiceDiscount,
  deleteService,
  deleteServiceCategory,
  deleteServiceDiscount,
  updateService,
  updateServiceCategory,
  updateServiceCategoryOrder,
  updateServiceDiscount,
  updateServiceDiscountOrder,
  updateServiceOrder,
} from '../../../actions/salon/serviceAction';
import CProgress from '../../../components_old/atoms/CProgress';
import { ServiceSelect } from '../../../features/service/components/ServiceSelect';
import {
  PlainService,
  PlainServiceCategory,
  PlainServiceDiscount,
} from '../../../models/service';
import { checkPermission } from '../../../models/stylist';
import { useSelectMe } from '../../../selectors/salonSelector';
import {
  selectActiveServiceCategoriesGroupedByType,
  selectServiceCategoryMap,
  selectServiceDiscountMap,
  selectServiceMap,
} from '../../../selectors/serviceSelector';
import { useFlag } from '../../../util/hooks/useFlag';
import { useThunkDispatch } from '../../../util/hooks/useThunkDispatch';
import AddEditCategoryDialog from './_components/AddEditCategoryDialog';
import AddEditDiscountDialog from './_components/AddEditDiscountDialog';
import AddEditServiceDialog from './_components/AddEditServiceDialog';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1),
    paddingTop: 76,
    height: 'calc(var(--vh, 1vh) * 100)',
    fallbacks: {
      height: '100vh',
    },
  },
  container: {
    height: '100%',
    padding: 0,
  },
  card: {
    positon: 'relative',
    height: '100%',
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: '#fff',
  },
}));

export const ServiceSettings: FC = () => {
  const classes = useStyles();

  const me = useSelectMe();
  const categoriesByType = useSelector(
    selectActiveServiceCategoriesGroupedByType
  );
  const categoryMap = useSelector(selectServiceCategoryMap);
  const serviceMap = useSelector(selectServiceMap);
  const discountMap = useSelector(selectServiceDiscountMap);
  const dispatch = useThunkDispatch();

  const [isSaving, callWithSaving] = useFlag(false);
  const [categoryState, setCategoryState] = useState<{
    open: boolean;
    addType: ServiceType | null;
    editCategoryId: number | null;
  }>({ open: false, addType: null, editCategoryId: null });
  const [serviceState, setServiceState] = useState<{
    open: boolean;
    addCategoryId: number | null;
    editServiceId: number | null;
  }>({ open: false, addCategoryId: null, editServiceId: null });
  const [discountState, setDiscountState] = useState<{
    open: boolean;
    isAdding: boolean;
    editDiscountId: number | null;
  }>({ open: false, isAdding: false, editDiscountId: null });

  const editCategory = categoryState.editCategoryId
    ? categoryMap[categoryState.editCategoryId]
    : undefined;
  const editService = serviceState.editServiceId
    ? serviceMap[serviceState.editServiceId]
    : undefined;
  const addServiceTargetCategory = serviceState.addCategoryId
    ? categoryMap[serviceState.addCategoryId]
    : undefined;
  const editDiscount = discountState.editDiscountId
    ? discountMap[discountState.editDiscountId]
    : undefined;

  const hasPermission = checkPermission(me, 'canUpdateMenu');

  const closeCategoryDialog = useCallback(
    () => setCategoryState((s) => ({ ...s, open: false })),
    []
  );
  const handleClickAddCategory = useCallback(
    (type: ServiceType) =>
      setCategoryState({ open: true, addType: type, editCategoryId: null }),
    []
  );
  const handleClickEditCategory = useCallback(
    (categoryId: number) =>
      setCategoryState({
        open: true,
        addType: null,
        editCategoryId: categoryId,
      }),
    []
  );
  const handleSaveCategory = useCallback(
    async (category: PlainServiceCategory, next: boolean) =>
      callWithSaving(async () => {
        if (editCategory) {
          await dispatchWithErrorHandling(
            dispatch,
            updateServiceCategory(category),
            { success: 'カテゴリを保存しました' },
            true
          );
        } else {
          await dispatchWithErrorHandling(
            dispatch,
            createServiceCategory(category),
            { success: 'カテゴリを作成しました' },
            true
          );
        }
      })
        .then(() => {
          if (!next) {
            closeCategoryDialog();
          }
        })
        .catch(() => {
          // エラー時はダイアログ閉じない
        }),
    [callWithSaving, editCategory, dispatch, closeCategoryDialog]
  );
  const handleDeleteCategory = useCallback(
    async (categoryId: number) => {
      callWithSaving(async () => {
        await dispatchWithErrorHandling(
          dispatch,
          deleteServiceCategory(categoryId),
          { success: 'カテゴリを削除しました' },
          true
        ).then(closeCategoryDialog);
      });
    },
    [callWithSaving, dispatch, closeCategoryDialog]
  );
  const handleSaveCategoryOrder = useCallback(
    async (categoryIds: number[]) =>
      callWithSaving(async () => {
        await dispatchWithErrorHandling(
          dispatch,
          updateServiceCategoryOrder(categoryIds),
          { success: 'カテゴリの並び順を保存しました' },
          true
        );
      }),
    [callWithSaving, dispatch]
  );

  const closeServiceDialog = useCallback(
    () => setServiceState((s) => ({ ...s, open: false })),
    []
  );
  const handleClickAddService = useCallback(
    (categoryId: number) =>
      setServiceState({
        open: true,
        addCategoryId: categoryId,
        editServiceId: null,
      }),
    []
  );
  const handleClickEditService = useCallback(
    (serviceId: number) =>
      setServiceState({
        open: true,
        addCategoryId: null,
        editServiceId: serviceId,
      }),
    []
  );
  const handleSaveService = useCallback(
    async (service: PlainService, next: boolean) =>
      callWithSaving(async () => {
        if (editService) {
          await dispatchWithErrorHandling(
            dispatch,
            updateService(service),
            { success: `${ServiceTypeName[service.type]}を保存しました` },
            true
          );
        } else {
          await dispatchWithErrorHandling(
            dispatch,
            createService(service),
            { success: `${ServiceTypeName[service.type]}を作成しました` },
            true
          );
        }
      })
        .then(() => {
          if (!next) {
            closeServiceDialog();
          }
        })
        .catch(() => {
          // エラー時はダイアログ閉じない
        }),
    [callWithSaving, editService, dispatch, closeServiceDialog]
  );
  const handleDeleteService = useCallback(
    async (serviceId: number) => {
      callWithSaving(async () => {
        await dispatchWithErrorHandling(
          dispatch,
          deleteService(serviceId),
          { success: '削除しました' },
          true
        ).then(closeServiceDialog);
      });
    },
    [callWithSaving, dispatch, closeServiceDialog]
  );
  const handleSaveServiceOrder = useCallback(
    async (categoryId: number, serviceIds: number[]) =>
      callWithSaving(async () => {
        await dispatchWithErrorHandling(
          dispatch,
          updateServiceOrder(categoryId, serviceIds),
          { success: '並び順を保存しました' },
          true
        );
      }),
    [callWithSaving, dispatch]
  );

  const closeDiscountDialog = useCallback(
    () => setDiscountState((s) => ({ ...s, open: false })),
    []
  );
  const handleClickAddDiscount = useCallback(
    () =>
      setDiscountState({ open: true, isAdding: true, editDiscountId: null }),
    []
  );
  const handleClickEditDiscount = useCallback(
    (discountId: number) =>
      setDiscountState({
        open: true,
        isAdding: false,
        editDiscountId: discountId,
      }),
    []
  );
  const handleSaveDiscount = useCallback(
    async (discount: PlainServiceDiscount, next: boolean) =>
      callWithSaving(async () => {
        if (editDiscount) {
          await dispatchWithErrorHandling(
            dispatch,
            updateServiceDiscount(discount),
            { success: '割引を保存しました' },
            true
          );
        } else {
          await dispatchWithErrorHandling(
            dispatch,
            createServiceDiscount(discount),
            { success: '割引を作成しました' },
            true
          );
        }
      })
        .then(() => {
          if (!next) {
            closeDiscountDialog();
          }
        })
        .catch(() => {
          // エラー時はダイアログ閉じない
        }),
    [callWithSaving, editDiscount, dispatch, closeDiscountDialog]
  );
  const handleDeleteDiscount = useCallback(
    async (discountId: number) => {
      callWithSaving(async () => {
        await dispatchWithErrorHandling(
          dispatch,
          deleteServiceDiscount(discountId),
          { success: '割引を削除しました' },
          true
        ).then(closeDiscountDialog);
      });
    },
    [callWithSaving, dispatch, closeDiscountDialog]
  );
  const handleSaveDiscountOrder = useCallback(
    async (discountIds: number[]) =>
      callWithSaving(async () => {
        await dispatchWithErrorHandling(
          dispatch,
          updateServiceDiscountOrder(discountIds),
          { success: '割引の並び順を保存しました' },
          true
        );
      }),
    [callWithSaving, dispatch]
  );

  useEffect(() => {
    function handleResize() {
      // 高さを ViewPort いっぱいに広げるためにサイズを計算してCSSプロパティに設定
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    return function cleanup() {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={classes.root}>
      <AddEditCategoryDialog
        open={categoryState.open}
        category={editCategory}
        type={categoryState.addType ?? undefined}
        onClose={closeCategoryDialog}
        onSave={handleSaveCategory}
        onDelete={handleDeleteCategory}
      />
      <AddEditServiceDialog
        open={serviceState.open}
        category={addServiceTargetCategory}
        categoriesByType={categoriesByType}
        service={editService}
        disabled={!hasPermission}
        onClose={closeServiceDialog}
        onSave={handleSaveService}
        onDelete={handleDeleteService}
      />
      <AddEditDiscountDialog
        open={discountState.open}
        discount={editDiscount}
        disabled={!hasPermission}
        onClose={closeDiscountDialog}
        onSave={handleSaveDiscount}
        onDelete={handleDeleteDiscount}
      />
      <Container maxWidth="md" className={classes.container}>
        <Card className={classes.card}>
          <Backdrop className={classes.backdrop} open={isSaving} timeout={500}>
            <CProgress />
          </Backdrop>
          <ServiceSelect
            showInvisible
            editable={hasPermission}
            onClickService={handleClickEditService}
            onClickDiscount={handleClickEditDiscount}
            onClickAddCategory={handleClickAddCategory}
            onClickEditCategory={handleClickEditCategory}
            saveCategoryOrder={handleSaveCategoryOrder}
            onClickAddService={handleClickAddService}
            onClickEditService={handleClickEditService}
            saveServiceOrder={handleSaveServiceOrder}
            onClickAddDiscount={handleClickAddDiscount}
            onClickEditDiscount={handleClickEditDiscount}
            saveDiscountOrder={handleSaveDiscountOrder}
          />
        </Card>
      </Container>
    </div>
  );
};
