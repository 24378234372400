import { deprecated } from 'typesafe-actions';
import { CustomerListData } from '../../reducers/types';

const { createAction } = deprecated;

enum ActionNames {
  SetCustomerTable = 'view/customers/SetCustomerTable',
}

export const setCustomerTable = createAction(
  ActionNames.SetCustomerTable,
  (action) => {
    return (listData: Partial<CustomerListData>) => action({ listData });
  }
);
