import { Chip } from '@mui/material';
import { ChipProps } from '@mui/material/Chip';
import { forwardRef } from 'react';

const CChip: FC<ChipProps> = forwardRef((props, ref) => {
  return (
    <Chip
      ref={ref}
      size="small"
      color="secondary"
      variant="outlined"
      {...props}
    />
  );
});

export default CChip;
