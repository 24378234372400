import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import {
  Box,
  Card,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { pushSnackbar } from '../../../../../actions/generalAction';
import {
  fetchScheduleCapacitySettings,
  updateScheduleCapacitySettings,
} from '../../../../../actions/salon/scheduleSettingsAction';
import CButton from '../../../../../components_old/atoms/CButton';
import CProgressOverlay from '../../../../../components_old/atoms/CProgressOverlay';
import {
  OverwriteOption,
  ScheduleCapacityMax,
} from '../../../../../models/salonScheduleSettings';
import { checkPermission } from '../../../../../models/stylist';
import { useSelectMe } from '../../../../../selectors/salonSelector';
import { selectScheduleCapacitySetting } from '../../../../../selectors/scheduleSettingsSelector';
import { DateType, DateTypeNameMap, DateTypes } from '../../../../../util/date';
import { useFlag } from '../../../../../util/hooks/useFlag';
import { useThunkDispatch } from '../../../../../util/hooks/useThunkDispatch';
import { OverwriteConfirmationDialog } from '../BusinessHourSettingCard/OverwriteConfirmationDialog';
import { ScheduleCapacitySelect } from './ScheduleCapacitySelect';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    position: 'relative',
  },
  table: {
    width: '100%',
  },
  headerCell: {
    width: 80,
    padding: theme.spacing(1),
  },
  cell: {
    padding: theme.spacing(1),
  },
  numberInput: {
    width: 80,
  },
  saveArea: {
    textAlign: 'right',
    padding: theme.spacing(2),
  },
}));

export const ScheduleCapacitySettingCard: FC = () => {
  const classes = useStyles();
  const me = useSelectMe();
  const selectedScheduleCapacitySetting = useSelector(
    selectScheduleCapacitySetting
  );
  const dispatch = useThunkDispatch();

  const hasPermission = checkPermission(me, 'canUpdateScheduleSetting');

  const [isFetching, callWithFetching] = useFlag(false);
  const [isEdited, setIsEdited] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [scheduleCapacitySetting, setScheduleCapacitySetting] = useState(
    selectedScheduleCapacitySetting
  );

  useEffect(() => {
    callWithFetching(
      async () => await dispatch(fetchScheduleCapacitySettings())
    );
  }, [callWithFetching, dispatch]);

  useEffect(() => {
    setScheduleCapacitySetting(selectedScheduleCapacitySetting);
  }, [selectedScheduleCapacitySetting]);

  const handleChange = useCallback(
    (dateType: DateType, capacity: number) => {
      setIsEdited(true);
      setScheduleCapacitySetting({
        ...scheduleCapacitySetting,
        [dateType]: capacity,
      });
    },
    [scheduleCapacitySetting]
  );

  const handleClickSave = useCallback(() => setIsConfirmDialogOpen(true), []);
  const handleCancel = useCallback(() => setIsConfirmDialogOpen(false), []);
  const handleOk = useCallback(
    (option: OverwriteOption) => {
      setIsConfirmDialogOpen(false);
      callWithFetching(async () => {
        const updated = await dispatch(
          updateScheduleCapacitySettings(scheduleCapacitySetting, option)
        );
        if (updated) {
          dispatch(
            pushSnackbar({
              message: '受付可能数設定を保存しました',
              type: 'success',
            })
          );
        }
      }).then(() => setIsEdited(false));
    },
    [callWithFetching, dispatch, scheduleCapacitySetting]
  );

  return (
    <Card className={classes.card}>
      {isFetching && <CProgressOverlay />}

      <OverwriteConfirmationDialog
        open={isConfirmDialogOpen}
        description="受付可能数設定を更新します。設定済みの毎月の営業情報を同時に更新しますか？"
        onOk={handleOk}
        onCancel={handleCancel}
      />

      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="center" className={classes.headerCell}>
              曜日
            </TableCell>
            <TableCell align="center" className={classes.cell}>
              同時受付可能数
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {DateTypes.map((dateType) => {
            const capacity = scheduleCapacitySetting[dateType];
            return (
              <TableRow key={dateType}>
                <TableCell align="center" className={classes.headerCell}>
                  {DateTypeNameMap[dateType]}
                </TableCell>
                <TableCell align="center" className={classes.cell}>
                  <Grid container alignItems="center" justifyContent="center">
                    <IconButton
                      disabled={!hasPermission}
                      onClick={() => {
                        handleChange(dateType, Math.max(capacity - 1, 0));
                      }}
                      size="large"
                    >
                      <SVGIcon name="minus" />
                    </IconButton>
                    <Box mr={1} ml={1}>
                      <ScheduleCapacitySelect
                        capacity={capacity}
                        disabled={!hasPermission}
                        onChange={(capacity) =>
                          handleChange(dateType, capacity)
                        }
                      />
                    </Box>
                    <IconButton
                      disabled={!hasPermission}
                      onClick={() => {
                        handleChange(
                          dateType,
                          Math.min(capacity + 1, ScheduleCapacityMax)
                        );
                      }}
                      size="large"
                    >
                      <SVGIcon name="plus" />
                    </IconButton>
                  </Grid>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {hasPermission && (
        <div className={classes.saveArea}>
          <CButton
            isLoading={isFetching}
            disabled={!isEdited}
            onClick={handleClickSave}
          >
            確認
          </CButton>
        </div>
      )}
    </Card>
  );
};
