import { apiBaseReducer } from '@karutekun/shared/data-access/api-base';
import { featureFlagReducer } from '@karutekun/shared/data-access/state/feature-flag';
import {
  VisitReducerName,
  visitReducer,
} from '@karutekun/shared/data-access/state/visit';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { ActionNames } from '../actions';
import analyticsServiceReducer from '../reducers/analyticsServiceReducer';
import customerReducer from '../reducers/customerReducer';
import generalReducer from '../reducers/generalReducer';
import salonBusinessInfoReducer from '../reducers/salonBusinessInfoReducer';
import salonReducer from '../reducers/salonReducer';
import uiReducer from '../reducers/uiReducer';
import uploadedPaperReducer from '../reducers/uploadedPaperReducer';
import userReducer from '../reducers/userReducer';
import viewReducer from '../reducers/viewReducer';
import broadcastMessageReducer from './broadcastMessageReducer';
import chatReducer from './chatReducer';
import { persistConfig } from './persistTransformers/index';
import salonJoinRequestReducer from './salonRequestReducer';
import settingsReducer from './settingsReducer';

export const appReducer = combineReducers({
  general: generalReducer,
  analyticsService: analyticsServiceReducer,
  user: userReducer,
  salon: salonReducer,
  salonBusinessInfo: salonBusinessInfoReducer,
  joinRequest: salonJoinRequestReducer,
  customer: customerReducer,
  chat: chatReducer,
  broadcastMessage: broadcastMessageReducer,
  uploadedPaper: uploadedPaperReducer,
  view: viewReducer,
  ui: uiReducer,
  settings: settingsReducer,
  apiBase: apiBaseReducer,
  featureFlag: featureFlagReducer,
  [VisitReducerName]: visitReducer,
});

export const rootReducer = persistReducer(
  persistConfig,
  (state: ReturnType<typeof appReducer> | undefined, action) => {
    switch (action.type) {
      case ActionNames.ClearState: {
        // reducer の作り方がよろしくないので正しく型をつけられない
        // 一旦 any で回避
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const { clearSettings } = action as any;
        const newState = appReducer(undefined, action);
        if (!clearSettings && state) {
          newState.settings = state.settings;
        }

        return newState;
      }
      default:
        return appReducer(state, action);
    }
  }
);
