import { VisitSource, visitSourceText } from '@karutekun/core/visit';
import { formatDateTimeForUser } from '@karutekun/shared/util/datetime';
import {
  Box,
  Checkbox,
  FormControlLabel,
  InputLabel,
  Typography,
} from '@mui/material';
import { useSelectStylist } from '../../../../../selectors/salonSelector';

type Props = {
  salonId: number;
  source: VisitSource;
  isConfirmedByStaff: boolean;
  createdStylistId: number | null;
  createdAt: number;
  disabled?: boolean;
  onChange?(isConfirmedByStaff: boolean): void;
};

export const VisitFormSource: FC<Props> = ({
  salonId,
  source,
  isConfirmedByStaff,
  createdStylistId,
  createdAt,
  disabled,
  onChange,
}) => {
  const createdStylist = useSelectStylist(createdStylistId, salonId);
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box>
        <InputLabel shrink>予約経路</InputLabel>
        <Typography variant="body2">
          {visitSourceText(source, createdStylist?.name)}
        </Typography>
        {/* TODO: rsv お客様情報表示のリンクを作成 */}
        <Typography variant="caption" color="textSecondary">
          {formatDateTimeForUser(createdAt)}
        </Typography>
      </Box>
      <Box alignItems="center">
        <InputLabel shrink>予約の確認状況</InputLabel>
        <FormControlLabel
          label="確認済み"
          control={
            <Checkbox
              checked={isConfirmedByStaff}
              onChange={(e) => onChange?.(e.target.checked)}
              disabled={disabled}
            />
          }
        />
      </Box>
    </Box>
  );
};
