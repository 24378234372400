import { deprecated } from 'typesafe-actions';
import { SnackbarNotificationItem } from '../reducers/generalReducer';

const { createAction } = deprecated;

enum ActionNames {
  PushSnackbar = 'general/PushSnackbar',
  CloseSnackbar = 'general/CloseSnackbar',
  RemoveSnackbar = 'general/RemoveSnackbar',
}

export const pushSnackbar = createAction(ActionNames.PushSnackbar, (action) => {
  return (item: Partial<SnackbarNotificationItem>) => action(item);
});
export const pushSnackbarError = createAction(
  ActionNames.PushSnackbar,
  (action) => {
    return (message: string) =>
      action({ message, type: 'error' as SnackbarNotificationItem['type'] });
  }
);
export const pushSnackbarSuccess = createAction(
  ActionNames.PushSnackbar,
  (action) => {
    return (message: string) =>
      action({ message, type: 'success' as SnackbarNotificationItem['type'] });
  }
);
export const closeSnackbar = createAction(
  ActionNames.CloseSnackbar,
  (action) => {
    return (key: number) => action({ key });
  }
);
export const removeSnackbar = createAction(
  ActionNames.RemoveSnackbar,
  (action) => {
    return (key: number) => action({ key });
  }
);
