export * from './lib/data';
export * from './lib/resource';
export * from './lib/reservation';

import { canCancelCheckIn } from './lib/utils/can-cancel-check-in';
import { canCheckIn } from './lib/utils/can-check-in';
import { checkDeletePermission } from './lib/utils/check-delete-permission';
import { convertUrlsToVisitImages } from './lib/utils/convert-urls-to-visit-images';
import { getVisitImageFirebasePath } from './lib/utils/get-visit-image-firebase-path';
import { groupImagesByType } from './lib/utils/group-images-by-type';
import { isReservedByCustomer } from './lib/utils/is-reserved-by-customer';
import { pickMainImage } from './lib/utils/pick-main-image';
import { shouldResendReservationReminderToCustomer } from './lib/utils/should-resend-reservation-reminder-to-customer';
import { isReservation, isVisitHistory } from './lib/utils/visit-status';

export const VisitUtils = {
  canCancelCheckIn,
  canCheckIn,
  checkDeletePermission,
  convertUrlsToVisitImages,
  getVisitImageFirebasePath,
  groupImagesByType,
  isReservedByCustomer,
  pickMainImage,
  shouldResendReservationReminderToCustomer,
  isReservation,
  isVisitHistory,
};

export * from './lib/view/visit-source-text';
