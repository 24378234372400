import { Theme, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import * as React from 'react';
import { StylistMinimum } from '../../models/stylist';
import CAvatar from './CAvatar';
import CShimeiIcon from './CShimeiIcon';

type OwnProps = {
  stylist: Nullable<StylistMinimum>;
  size?: number;
  style?: React.CSSProperties;
  showShimeiIcon?: boolean;
};

const useStyles = makeStyles((_: Theme) => ({
  shimeiContainer: {
    position: 'relative',
    paddingRight: 10,
  },
  shimeiIcon: {
    position: 'absolute',
    right: 0,
    top: -5,
  },
}));

const CStylistAvatar: FC<OwnProps> = (props) => {
  const classes = useStyles();

  const { stylist, size = 32, style, showShimeiIcon } = props;

  const name = stylist ? stylist.name : '?';

  return (
    <Tooltip title={name} style={style}>
      <div className={clsx({ [classes.shimeiContainer]: showShimeiIcon })}>
        <CAvatar size={size} alt={name} src={stylist?.imageUrl ?? undefined}>
          {name[0]}
        </CAvatar>
        {showShimeiIcon && (
          <div className={classes.shimeiIcon}>
            <CShimeiIcon />
          </div>
        )}
      </div>
    </Tooltip>
  );
};

export default CStylistAvatar;
