import { isAdminRoleChangable } from '@karutekun/core/permission-role';
import { useCallback } from 'react';
import * as React from 'react';
import { useSelector } from 'react-redux';
import CSelect from '../../../../../components_old/atoms/CSelect';
import { isAdmin } from '../../../../../models/stylist';
import {
  selectPermissionRoles,
  useSelectMe,
} from '../../../../../selectors/salonSelector';

type Props = {
  value: number;
  disabled?: boolean;
  onChange(permissionRoleId: number): void;
};

export const PermissionRoleSelect: FC<Props> = React.memo(
  function ReservationShiftPatternSelect(props) {
    const { value, disabled, onChange } = props;

    const me = useSelectMe();
    const permissionRoles = useSelector(selectPermissionRoles);

    const handleChange = useCallback(
      (v: number | undefined) => {
        if (v && isAdminRoleChangable(isAdmin(me), value, v)) {
          onChange(v);
        }
      },
      [me, value, onChange]
    );

    return (
      <CSelect
        options={permissionRoles.map((r) => ({
          value: r.roleId,
          element: r.name,
          isInactive: !isAdminRoleChangable(isAdmin(me), value, r.roleId),
        }))}
        value={value}
        onChange={handleChange}
        disabled={disabled}
      />
    );
  }
);
