import { dayjs, today } from '@karutekun/shared/util/datetime';
import { ICustomer } from '../data/customer';

/**
 * 年齢を計算
 */
export function getAge(
  {
    birthdayYear,
    birthdayMonth,
    birthdayDay,
  }: Pick<ICustomer, 'birthdayYear'> &
    Partial<Pick<ICustomer, 'birthdayMonth' | 'birthdayDay'>>,
  baseDate = today()
) {
  if (birthdayYear === null) {
    return null;
  }
  const birthday = dayjs()
    .year(birthdayYear)
    // month は 0-11 で指定することに注意！
    .month((birthdayMonth ?? 1) - 1)
    .date(birthdayDay ?? 1);

  return birthday.isValid() ? dayjs(baseDate).diff(birthday, 'years') : null;
}

/**
 * 年齢の "oo代" を計算
 */
export function getAgeGroup(
  birthdayYear: string | number,
  baseDate?: string
): number;
export function getAgeGroup(
  birthdayYear: Nullable<string | number>,
  baseDate?: string
): number | null;
export function getAgeGroup(
  birthdayYear: Nullable<string | number>,
  baseDate = today()
) {
  const age = getAge(
    {
      birthdayYear:
        birthdayYear === null || birthdayYear === undefined
          ? null
          : Number(birthdayYear),
    },
    baseDate
  );
  return age === null ? null : Math.floor(age / 10) * 10;
}
