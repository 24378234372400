import {
  CustomerLoyaltySegment,
  SalonCustomerLineState,
} from '@karutekun/core/customer';
import { batch } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { deprecated } from 'typesafe-actions';
import { createCancellableAsyncAction } from '../actions/helper/asyncActionCreator';
import { sendRequest } from '../actions/request';
import { setUploadedPaper } from '../actions/uploadedPaperActions';
import Logger, { AnalyticsEvent } from '../logger';
import {
  Customer,
  CustomerSalonInformation,
  PlainCustomer,
  customerFromResource,
} from '../models/customer';

const { createAction } = deprecated;

export type CustomerFilter = {
  // 顧客固有のデータ
  name?: string;
  memo?: string;
  birthdayMonth?: string;
  phone?: string;

  // グループ全体の統計情報でのフィルタ
  loyaltySegment?: CustomerLoyaltySegment;
  firstVisitedAt?: { from?: string; to?: string };
  lastVisitedAt?: { from?: string; to?: string };
  lastStylistId?: number;

  // 特定サロンの統計情報でのフィルタ
  salon?: {
    salonId: number;
    loyaltySegment?: CustomerLoyaltySegment;
    firstVisitedAt?: { from?: string; to?: string };
    lastVisitedAt?: { from?: string; to?: string };
    lastStylistId?: number;
    lineStates?: SalonCustomerLineState[];
  };

  limit?: number;
  offset?: number;
};
export type CustomerSortKey =
  | 'name'
  | 'totalSales'
  | 'averageBudget'
  | 'totalVisitNum'
  | 'firstVisitedAt'
  | 'lastVisitedAt'
  | 'averageVisitPeriod'
  | 'nextVisit'
  | 'recentNextVisit'
  | 'createdAt'
  | undefined;
export type CustomerSortKeyForRequest =
  | CustomerSortKey
  | 'salon.totalSales'
  | 'salon.averageBudget'
  | 'salon.totalVisitNum'
  | 'salon.firstVisitedAt'
  | 'salon.lastVisitedAt'
  | 'salon.averageVisitPeriod'
  | 'salon.nextVisit';
export type CustomerSortOrder = 'asc' | 'desc' | undefined;

export enum ActionNames {
  SetCustomer = 'customer/SetCustomer',
  SetCustomers = 'customer/SetCustomers',
  UnsetCustomer = 'customer/UnsetCustomer',
}

export const setCustomer = createAction(ActionNames.SetCustomer, (action) => {
  return (customer: Partial<PlainCustomer> & { id: number }) =>
    action({ customer });
});
export const setCustomers = createAction(ActionNames.SetCustomers, (action) => {
  return (customers: (Partial<PlainCustomer> & { id: number })[]) =>
    action({ customers });
});
export const unsetCustomer = createAction(
  ActionNames.UnsetCustomer,
  (action) => {
    return (customerId: number) => action({ customerId });
  }
);

export function fetchCustomer(customerId: number) {
  return async function (dispatch: Dispatch<Action>) {
    const json = await sendRequest(dispatch, `customers/${customerId}`);

    const customer = json;

    const plainCustomer = customerFromResource(customer);

    batch(() => {
      dispatch(setCustomer(plainCustomer));

      const uploadedPaper = customer.uploadedPaper;
      if (uploadedPaper?.id) {
        dispatch(setUploadedPaper(uploadedPaper.id, uploadedPaper));
      }
    });

    return plainCustomer;
  };
}

/**
 * お客様を取得する
 */
export function fetchCustomers(
  filter: CustomerFilter = {},
  sortKey: CustomerSortKey,
  sortOrder: CustomerSortOrder
) {
  return createCancellableAsyncAction(async (dispatch, signal) => {
    /**
     * TODO
     * UI上は同じ見た目だが、グループサロンか自サロンかで filter や sort のキーが変わる
     * 例えば
     * フィルタ: filter.loyaltySegment ⇔ filter.salon.loyaltySegment
     * ソート: `totalSales` ⇔ `salon.totalSales`
     * など
     *
     * filter の方はコンポーネント上でハンドリングして、適切に filter オブジェクトをステート管理しているが、
     * sortKey の方はそれができておらず、コンポーネント上では CustomerSortKey の値のみ扱っている
     *
     * ここでは、 filter.salon が指定されている場合はサロンでの絞り込みになるので、
     * ソートのキーもサロンのキーを見るように変換処理を噛ましている
     *
     * 本来はコンポーネント上でステート管理をきちんとするべきだが、現状 UI が基本的には同じ見た目で、
     * グループ⇔サロンを切り替えるチェックボックスがあるだけになっていることで共通に書いてしまっている
     */
    const requestSortKey =
      (filter.salon ? convertSortKeyForSalon(sortKey) : sortKey) ??
      'lastVisitedAt';

    Logger.info(JSON.stringify(filter, null, 2), requestSortKey, sortOrder);

    const json = await sendRequest(dispatch, 'customers', {
      signal,
      params: {
        filter: JSON.stringify(filter),
        sort: requestSortKey,
        order: sortOrder ?? 'desc',
      },
    });

    if (!json || json.count === undefined || json.customers === undefined) {
      return null;
    }

    // TODO 一時的に lint を無効化しています。気づいたベースで直してください
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { count, customers }: { count: number; customers: any[] } = json;

    const plainCustomers = customers.map((c) => customerFromResource(c));

    dispatchCustomers(dispatch, plainCustomers);

    return { count, customers: plainCustomers };
  });
}

/**
 * お客様を新規作成する
 */
export function createCustomer(
  customer: PlainCustomer,
  customerSalonInformation: CustomerSalonInformation
) {
  return async (dispatch: Dispatch<Action>) => {
    const json = await sendRequest(dispatch, 'customers', {
      method: 'POST',
      body: JSON.stringify({
        name: customer.name,
        nameKana: customer.nameKana,
        sex: customer.sex,
        allergy: customer.allergy,
        memo: customer.memo,
        birthdayYear: customer.birthdayYear,
        birthdayMonth: customer.birthdayMonth,
        birthdayDay: customer.birthdayDay,
        job: customer.job,
        phone: customer.phone,
        email: customer.email,
        postalCode: customer.postalCode,
        address: customer.address,
        visitMotivationIds: customerSalonInformation.visitMotivationIds,
        counselingAnswers: customerSalonInformation.counselingAnswers,
      }),
      throwError: false,
    });

    if (!json || !json.id) {
      throw new Error('Failed to create customer.');
    }

    const createdCustomer = customerFromResource(json);

    dispatch(setCustomer(createdCustomer));
    Logger.logEvent(AnalyticsEvent.createCustomer);

    return createdCustomer;
  };
}

/**
 * お客様情報を更新する
 */
export function updateCustomerBasicInformation(
  customer: PlainCustomer,
  checkResourceConflict = true
) {
  return async (dispatch: Dispatch<Action>) => {
    const json = await sendRequest(dispatch, `customers/${customer.id}`, {
      method: 'POST',
      body: JSON.stringify({
        customerId: customer.id,
        name: customer.name,
        nameKana: customer.nameKana,
        sex: customer.sex,
        allergy: customer.allergy,
        memo: customer.memo,
        birthdayYear: customer.birthdayYear,
        birthdayMonth: customer.birthdayMonth,
        birthdayDay: customer.birthdayDay,
        job: customer.job,
        phone: customer.phone,
        email: customer.email,
        postalCode: customer.postalCode,
        address: customer.address,
        userUpdatedAt: checkResourceConflict
          ? customer.userUpdatedAt
          : undefined,
      }),
      throwError: false,
    });

    if (!json || !json.id) {
      throw new Error('Failed to update customer basic information.');
    }

    const updated: Customer = json;

    dispatch(setCustomer(customerFromResource(updated)));

    return updated;
  };
}

export function updateCustomerSalonInformation(
  customerId: number,
  salonId: number,
  customerSalonInformation: CustomerSalonInformation,
  userUpdatedAt?: number
) {
  return async (dispatch: Dispatch<Action>) => {
    const json = await sendRequest(
      dispatch,
      `customers/${customerId}/salon_information/${salonId}`,
      {
        method: 'POST',
        body: JSON.stringify({ ...customerSalonInformation, userUpdatedAt }),
        throwError: false,
      }
    );

    if (!json || !json.id) {
      throw new Error('Failed to update customer salon information.');
    }

    const updated: Customer = json;

    dispatch(setCustomer(customerFromResource(updated)));
  };
}

/**
 * お客様を削除する
 */
export function deleteCustomer(customerId: number) {
  return async (dispatch: Dispatch<Action>) => {
    await sendRequest(dispatch, `customers/${customerId}`, {
      method: 'DELETE',
      throwError: false,
    });

    dispatch(unsetCustomer(customerId));
    Logger.logEvent(AnalyticsEvent.deleteCustomer);
  };
}

/**
 * customer CSVダウンロード
 * サーバー側は自サロンのカルテのみを返すので注意
 */
export function downloadCustomerCsv(filter: CustomerFilter = {}) {
  return async function (dispatch: Dispatch<Action>) {
    await sendRequest(dispatch, `analytics/customers/download`, {
      params: {
        filter: JSON.stringify(filter),
      },
      download: {
        filename: `カルテデータ.csv`,
      },
    });
  };
}

/**
 * サロン指定のソートキーに置き換える
 */
function convertSortKeyForSalon(
  sortKey: CustomerSortKey
): CustomerSortKeyForRequest {
  if (!sortKey) {
    return sortKey;
  }

  if (
    [
      'totalSales',
      'averageBudget',
      'totalVisitNum',
      'firstVisitedAt',
      'lastVisitedAt',
      'averageVisitPeriod',
      'nextVisit',
    ].includes(sortKey)
  ) {
    return `salon.${sortKey}` as CustomerSortKeyForRequest;
  }

  return sortKey;
}

export function dispatchCustomers(
  dispatch: Dispatch<Action>,
  // TODO 一時的に lint を無効化しています。気づいたベースで直してください
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customers: any[]
) {
  if (!customers.length) {
    return;
  }

  dispatch(setCustomers(customers));
}
