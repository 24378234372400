import {
  VisitHistoryFilter,
  VisitHistorySort,
} from '@karutekun/shared/data-access/visit';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback } from 'react';
import CTrialWillEndBanner from '../../components_old/molecules/CTrialWillEndBanner';
import { CDownloadCsvButtons } from '../../components_old/organisms/visits/visitCardList/CDownloadCsvButtons';
import { CVisitHistoryList } from '../../components_old/organisms/visits/visitCardList/CVisitHistoryList';
import { useSyncedQueryParams } from '../../util/hooks/router/useSyncedQueryParams';

const useStyles = makeStyles(() => ({
  root: {
    padding: '76px 20px 20px 20px',
  },
}));

export const VisitHistories: FC = () => {
  const classes = useStyles();

  const [{ filter, sort, page }, setParams] = useSyncedQueryParams<{
    filter: VisitHistoryFilter;
    sort: VisitHistorySort;
    page: number;
  }>({ filter: {}, sort: 'startedAtDesc', page: 0 }, true);

  const handleChangeFilter = useCallback(
    (filter: VisitHistoryFilter) => setParams({ filter, page: 0 }),
    [setParams]
  );
  const handleChangeSort = useCallback(
    (sort: VisitHistorySort) => setParams({ sort, page: 0 }),
    [setParams]
  );
  const handleChangePage = useCallback(
    (page: number) => setParams({ page }),
    [setParams]
  );

  return (
    <div className={classes.root}>
      <CTrialWillEndBanner />
      <Box mb={1}>
        <CDownloadCsvButtons filter={filter} />
      </Box>
      <CVisitHistoryList
        filter={filter}
        sort={sort}
        page={page}
        onFilterChange={handleChangeFilter}
        onSortChange={handleChangeSort}
        onPageChange={handleChangePage}
      />
    </div>
  );
};
