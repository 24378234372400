import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M5.872 6.627C6.976 4.217 9.48 2.75 12.143 2.75h23.713c2.663 0 5.168 1.467 6.272 3.877l4.06 8.862c1.402 3.06.098 6.247-2.438 7.866V41.75H45a1.75 1.75 0 1 1 0 3.5H3a1.75 1.75 0 1 1 0-3.5h1.25V23.356C1.713 21.738.41 18.55 1.812 15.49zM7.75 24.436V41.75h17.5v-11.5a4 4 0 0 1 4-4h5a4 4 0 0 1 4 4v11.5h2V24.436h-.023l-.034.001H39.99c-2.073 0-4.009-.86-5.33-2.267-1.322 1.408-3.257 2.267-5.33 2.267a7.302 7.302 0 0 1-5.296-2.301c-1.32 1.428-3.268 2.302-5.359 2.302a7.304 7.304 0 0 1-5.33-2.338 7.303 7.303 0 0 1-5.33 2.337h-.241zm7.344-6.645c0 1.466 1.502 3.146 3.581 3.146 2.118 0 3.575-1.543 3.575-3.146a1.75 1.75 0 1 1 3.5 0c0 1.466 1.501 3.146 3.58 3.146 2.12 0 3.58-1.545 3.58-3.146a1.75 1.75 0 1 1 3.5 0c0 1.601 1.46 3.146 3.58 3.146h.153c2.434-.09 3.632-2.311 2.863-3.989l-4.06-8.863c-.482-1.052-1.667-1.835-3.09-1.835H12.143c-1.422 0-2.607.782-3.09 1.834l-4.059 8.864c-.77 1.68.43 3.9 2.862 3.99h.159c2.08 0 3.58-1.677 3.58-3.147a1.75 1.75 0 1 1 3.5 0M34.75 41.75v-11.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0-.5.5v11.5zm-21.5-12a.5.5 0 0 0-.5.5v5.5h7v-5.5a.5.5 0 0 0-.5-.5zm-2.828-2.328a4 4 0 0 1 2.828-1.172h6a4 4 0 0 1 4 4v7.25a1.75 1.75 0 0 1-1.75 1.75H11a1.75 1.75 0 0 1-1.75-1.75v-7.25a4 4 0 0 1 1.172-2.828"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgStore = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgStore);
export default Memo;
