import { ServiceType } from '@karutekun/core/salon-service';
import { Tooltip } from '@mui/material';
import * as React from 'react';
import { DefaultIconName, ServiceCategory } from '../../models/service';
import CAvatar from './CAvatar';

type OwnProps = {
  category: Nullable<
    Partial<Pick<ServiceCategory, 'type' | 'iconName' | 'name'>>
  >;
  size?: number;
  style?: React.CSSProperties;
};

const MenuCategoryImageMap = {
  1101: '/static/images/icons/menus/1101_cut.png',
  1102: '/static/images/icons/menus/1102_color.png',
  1103: '/static/images/icons/menus/1103_perm.png',
  1104: '/static/images/icons/menus/1104_straight.png',
  1105: '/static/images/icons/menus/1105_treatment.png',
  1106: '/static/images/icons/menus/1106_shampoo.png',
  1107: '/static/images/icons/menus/1107_set.png',
  1108: '/static/images/icons/menus/1108_etc.png',
  1201: '/static/images/icons/menus/1201_gel.png',
  1202: '/static/images/icons/menus/1202_sculpture.png',
  1203: '/static/images/icons/menus/1203_nailcare.png',
  1204: '/static/images/icons/menus/1204_footnail.png',
  1205: '/static/images/icons/menus/1205_nailoff.png',
  1301: '/static/images/icons/menus/1301_eyelash.png',
  1302: '/static/images/icons/menus/1302_eyebrow.png',
  1303: '/static/images/icons/menus/1303_eyelashoff.png',
  1401: '/static/images/icons/menus/1401_facial.png',
  1402: '/static/images/icons/menus/1402_head.png',
  1403: '/static/images/icons/menus/1403_body.png',
  1404: '/static/images/icons/menus/1404_epilation.png',
  1501: '/static/images/icons/menus/1501_bodycare.png',
  1502: '/static/images/icons/menus/1502_reflexology.png',
  1503: '/static/images/icons/menus/1503_headcare.png',
  9001: '/static/images/icons/menus/9001_common1.png',
  9002: '/static/images/icons/menus/9002_common2.png',
  9003: '/static/images/icons/menus/9003_common3.png',
  9004: '/static/images/icons/menus/9004_common4.png',
  9005: '/static/images/icons/menus/9005_common5.png',
  9006: '/static/images/icons/menus/9006_common6.png',
  9007: '/static/images/icons/menus/9007_common7.png',
  9008: '/static/images/icons/menus/9008_common8.png',
  9009: '/static/images/icons/menus/9009_common9.png',
  9010: '/static/images/icons/menus/9010_common10.png',
};
const ProductCategoryImageMap = {
  1101: '/static/images/icons/products/1101_shampoo.png',
  1102: '/static/images/icons/products/1102_treatment.png',
  1103: '/static/images/icons/products/1103_styling.png',
  1104: '/static/images/icons/products/1104_cosmetics.png',
  1105: '/static/images/icons/products/1105_etc.png',
  1201: '/static/images/icons/products/1201_nailcare.png',
  1202: '/static/images/icons/products/1202_handcare.png',
  1301: '/static/images/icons/products/1301_mascara.png',
  1302: '/static/images/icons/products/1302_eyelashserum.png',
  1303: '/static/images/icons/products/1303_eyeshampoo.png',
  1401: '/static/images/icons/products/1401_bodycosmetics.png',
  1501: '/static/images/icons/products/1501_gelpack.png',
  9001: '/static/images/icons/products/9001_common1.png',
  9002: '/static/images/icons/products/9002_common2.png',
  9003: '/static/images/icons/products/9003_common3.png',
  9004: '/static/images/icons/products/9004_common4.png',
  9005: '/static/images/icons/products/9005_common5.png',
  9006: '/static/images/icons/products/9006_common6.png',
  9007: '/static/images/icons/products/9007_common7.png',
  9008: '/static/images/icons/products/9008_common8.png',
  9009: '/static/images/icons/products/9009_common9.png',
  9010: '/static/images/icons/products/9010_common10.png',
};

export const MenuCategoryIconNames = Object.keys(MenuCategoryImageMap).sort();
export const ProductCategoryIconNames = Object.keys(
  ProductCategoryImageMap
).sort();

const CServiceCategoryIcon: FC<OwnProps> = (props) => {
  const { category, size = 32, style } = props;

  const imageMap =
    category?.type === ServiceType.Product
      ? ProductCategoryImageMap
      : MenuCategoryImageMap;

  const source =
    // TODO 一時的にルールを無効化しています。気づいたベースで直してください
    // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ 1101: string; 1102: string; 1103: string; 1104: string; 1105: string; 1201: string; 1202: string; 1301: string; 1302: string; 1303: string; 1401: string; 1501: string; 9001: string; 9002: string; 9003: string; ... 6 more ...; 9010: string; }'.
    category?.iconName && imageMap[category.iconName]
      ? // TODO 一時的にルールを無効化しています。気づいたベースで直してください
        // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ 1101: string; 1102: string; 1103: string; 1104: string; 1105: string; 1201: string; 1202: string; 1301: string; 1302: string; 1303: string; 1401: string; 1501: string; 9001: string; 9002: string; 9003: string; ... 6 more ...; 9010: string; }'.
        imageMap[category.iconName]
      : imageMap[DefaultIconName];

  return category?.name !== undefined ? (
    <Tooltip title={category.name} style={style}>
      <CAvatar size={size} src={source} />
    </Tooltip>
  ) : (
    <CAvatar style={style} size={size} src={source} />
  );
};

export default CServiceCategoryIcon;
