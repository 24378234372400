import { PlainReservationStylist } from './reservationStylist';
import { Service } from './service';

/**
 * ReservationMenus
 */
export type PlainReservationMenu = {
  id: number;
  name: string;
  description: string;
  showPriceFromSign: boolean;
  order: number;
  isVisible: boolean;
  isActive: boolean;
  serviceId: number;
  availableReservationStylistIds?: number[];
};

export type ReservationMenu = PlainReservationMenu & {
  service: Service;
  availableReservationStylists: PlainReservationStylist[];
};

export type EditablePlainReservationMenu = Pick<
  PlainReservationMenu,
  | 'serviceId'
  | 'name'
  | 'description'
  | 'showPriceFromSign'
  | 'isVisible'
  | 'availableReservationStylistIds'
>;

// TODO 一時的に lint を無効化しています。気づいたベースで直してください
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function reservationMenuFromResource(res: any): PlainReservationMenu {
  return {
    id: res.id,
    name: res.name,
    description: res.description,
    showPriceFromSign: res.showPriceFromSign,
    order: res.order,
    isVisible: res.isVisible,
    isActive: res.isActive,
    serviceId: res.serviceId,
    availableReservationStylistIds: res.availableReservationStylistIds,
  };
}
