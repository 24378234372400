import {
  IService,
  IServiceCategory,
  ServiceType,
} from '@karutekun/core/salon-service';
import { IVoucherLine } from '../data';

/**
 * voucher.lines から menuLines と productLines を抽出する
 * それぞれ、メニューの並び順と同じ順に並び替える
 */
export function sortVoucherLinesByServiceOrder<
  Line extends Pick<IVoucherLine, 'serviceId' | 'serviceType'>
>(
  lines: Line[],
  {
    serviceMap,
    categoryMap,
  }: {
    serviceMap: IdMap<Pick<IService, 'categoryId' | 'order'>>;
    categoryMap: IdMap<Pick<IServiceCategory, 'order'>>;
  }
) {
  const sortFunc = (a: Line, b: Line) => {
    const aService = serviceMap[a.serviceId];
    const bService = serviceMap[b.serviceId];
    if (!aService || !bService) {
      return 0;
    }

    const aCategory = categoryMap[aService.categoryId];
    const bCategory = categoryMap[bService.categoryId];
    if (!aCategory || !bCategory) {
      return 0;
    }

    // カテゴリーの並び順を優先。同じカテゴリーの場合はサービスの並び順を優先
    if (aCategory.order !== bCategory.order) {
      return bCategory.order - aCategory.order;
    } else {
      return bService.order - aService.order;
    }
  };

  return {
    menuLines: lines
      .filter((l) => l.serviceType === ServiceType.Menu)
      .sort(sortFunc),
    productLines: lines
      .filter((l) => l.serviceType === ServiceType.Product)
      .sort(sortFunc),
  };
}
