import { createSelector } from 'reselect';
import {
  SalonSettings,
  ScheduleSettings,
  initialScheduleSetting,
} from '../reducers/settingsReducer';
import { selectMySalon } from '../selectors/salonSelector';
import { GlobalState } from '../store';

export const selectSalonSettings = createSelector(
  (state: GlobalState) => selectMySalon(state).id,
  (state: GlobalState) => state.settings.salonMap,
  (salonId: number, salonMap): Nullable<SalonSettings> => {
    return salonMap[salonId];
  }
);

export const selectScheduleSettings = createSelector(
  (state: GlobalState) => selectSalonSettings(state),
  (settings: Nullable<SalonSettings>): ScheduleSettings => {
    if (!settings) {
      return initialScheduleSetting();
    }
    return settings.schedule;
  }
);

export const selectAnalyticsDisplayTaxIncludedPrice = (state: GlobalState) =>
  state.settings.analyticsdisplayTaxIncludedPrice ?? true;
