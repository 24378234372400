import { Box } from '@mui/material';
import Typography, { TypographyProps } from '@mui/material/Typography';
import React from 'react';
import { StylistMinimum } from '../../models/stylist';
import CStylistAvatar from '../atoms/CStylistAvatar';

type OwnProps = TypographyProps & {
  stylist: Nullable<StylistMinimum>;
  emptyText?: string;
};

const CStylist: FC<OwnProps> = React.memo(function CStylist(props) {
  const { stylist, emptyText = '担当者指定なし', ...typographyProps } = props;

  return (
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="nowrap"
      alignItems="center"
      overflow="hidden"
      textOverflow="ellipsis"
    >
      <CStylistAvatar size={18} style={{ marginRight: 6 }} stylist={stylist} />
      <Typography
        variant="subtitle2"
        color="textPrimary"
        noWrap
        {...typographyProps}
      >
        {stylist ? stylist.name : emptyText}
      </Typography>
    </Box>
  );
});

export default CStylist;
