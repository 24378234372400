import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { DialogActions } from '@mui/material';
import { Button } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useFlag } from '../../../util/hooks/useFlag';
import {
  SimpleDialogContext,
  SimpleDialogContextValue,
  SimpleDialogProps,
} from './simpleDialogContext';

type SimpleDialogState = { open: boolean } & SimpleDialogProps;

const SimpleDialogProvider: FC = (props) => {
  const [dialog, setDialog] = useState<SimpleDialogState>({
    open: false,
  });
  const [isCallbackRunning, callWithCallbackRunning] = useFlag(false);

  const handleOpen = useCallback((props: SimpleDialogProps) => {
    setDialog({ open: true, ...props });
  }, []);

  const handleCancel = useCallback(async () => {
    if (dialog.onCancel) {
      await callWithCallbackRunning(dialog.onCancel);
    }
    setDialog((c) => ({ ...c, open: false }));
  }, [callWithCallbackRunning, dialog.onCancel]);

  const handleOk = useCallback(async () => {
    if (dialog.onOk) {
      await callWithCallbackRunning(dialog.onOk);
    }
    setDialog((c) => ({ ...c, open: false }));
  }, [callWithCallbackRunning, dialog.onOk]);

  const value: SimpleDialogContextValue = useMemo(() => {
    return {
      open: handleOpen,
    };
  }, [handleOpen]);

  return (
    <>
      <Dialog open={dialog.open} onClose={handleCancel}>
        {dialog.title !== undefined && (
          <DialogTitle>{dialog.title}</DialogTitle>
        )}
        <DialogContent>
          {typeof dialog.content === 'string' ? (
            <DialogContentText>{dialog.content}</DialogContentText>
          ) : (
            dialog.content
          )}
        </DialogContent>
        {(dialog.onCancel !== undefined || dialog.onOk !== undefined) && (
          <DialogActions>
            <Button
              onClick={handleCancel}
              color="primary"
              disabled={isCallbackRunning}
            >
              キャンセル
            </Button>
            <Button
              onClick={handleOk}
              color="primary"
              autoFocus
              disabled={isCallbackRunning}
            >
              OK
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <SimpleDialogContext.Provider value={value}>
        {props.children}
      </SimpleDialogContext.Provider>
    </>
  );
};

export default SimpleDialogProvider;
