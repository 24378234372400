import { Switch, Typography } from '@mui/material';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { setSettingsAnalyticsDisplayTaxIncludedPrice } from '../../actions/settingsAction';
import Logger, { AnalyticsEvent } from '../../logger';
import { selectAnalyticsDisplayTaxIncludedPrice } from '../../selectors/settingsSelector';
import { GlobalState } from '../../store';
import { useThunkDispatch } from '../../util/hooks/useThunkDispatch';

const CAnalyticsDisplayTaxIncludedPriceSwitcher: FC = React.memo(
  function CAnalyticsDisplayTaxIncludedPriceSwitcher() {
    const displayTaxIncludedPrice = useSelector<GlobalState, boolean>((state) =>
      selectAnalyticsDisplayTaxIncludedPrice(state)
    );
    const dispatch = useThunkDispatch();

    return (
      <>
        <Switch
          color="secondary"
          checked={displayTaxIncludedPrice}
          onChange={() => {
            dispatch(
              setSettingsAnalyticsDisplayTaxIncludedPrice(
                !displayTaxIncludedPrice
              )
            );

            Logger.logEvent(AnalyticsEvent.changeTaxSwitcher, {
              isTaxIncluded: !displayTaxIncludedPrice,
            });
            Logger.logEvent(AnalyticsEvent.currentTaxSwitcher, {
              isTaxIncluded: !displayTaxIncludedPrice,
            });
          }}
        />
        <Typography variant="subtitle1">
          {displayTaxIncludedPrice ? '税込金額表示中' : '税抜金額表示中'}
        </Typography>
      </>
    );
  }
);

export default CAnalyticsDisplayTaxIncludedPriceSwitcher;
