import { deprecated } from 'typesafe-actions';

const { createAction } = deprecated;

export type ChatRoomsFilter = {
  fromId?: number;
};

export enum ActionNames {
  SetChatSalonId = 'view/chatRooms/SetChatSalonId',
}

export const setChatSalonId = createAction(
  ActionNames.SetChatSalonId,
  (action) => {
    return (salonId: number) => action({ salonId });
  }
);
