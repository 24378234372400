import { VisitResource, VisitUtils } from '@karutekun/core/visit';
import {
  VisitReservedFilter,
  VisitReservedSort,
  useFetchVisitsReservedByCustomerPaginated,
  useUpdateIsConfirmedByStaff,
} from '@karutekun/shared/data-access/visit';
import { moment } from '@karutekun/shared/util/datetime';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  List,
  ListItem,
  TablePagination,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { updateRecentReservationOpenedAt } from '../../../../actions/salonStylistAction';
import {
  setViewScheduleCurrentDate,
  setViewScheduleDrawerStatus,
  setViewSchedulePreviewVisitId,
} from '../../../../actions/view/viewScheduleAction';
import CSelect from '../../../../components_old/atoms/CSelect';
import CShimeiIcon from '../../../../components_old/atoms/CShimeiIcon';
import CServiceCategoryIcons from '../../../../components_old/molecules/CServiceCategoryIcons';
import CStylist from '../../../../components_old/molecules/CStylist';
import { selectStylistMap } from '../../../../selectors/salonSelector';
import { useSelectVoucherLineServiceCategories } from '../../../../selectors/voucherSelector';
import { useThunkDispatch } from '../../../../util/hooks/useThunkDispatch';

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      position: 'relative',
    },
    listItemGutter: {
      minWidth: 0,
      paddingLeft: theme.spacing(1),
      paddingRight: 0,
    },
    reservationDetailsContainer: {
      minWidth: 0,
    },
    reservationDetailsItem: {
      display: 'flex',
      flexDirection: 'row',
      paddingLeft: theme.spacing(0.5),
      marginTop: theme.spacing(0.5),
    },
    menuIconContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    reservationCheckButton: {
      minWidth: 0,
      padding: theme.spacing(1),
    },
    reservationDetailsReceptionText: {
      width: '100%',
      textAlign: 'right',
    },
  };
});

const numReservationsPerPage = 20;

export const SchedulesDrawerReservationList: FC = () => {
  const classes = useStyles();
  const dispatch = useThunkDispatch();

  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState<VisitReservedFilter>({});
  const [sort, setSort] = useState<VisitReservedSort>('createdAtDesc');

  const {
    data: { visits, count },
    refetch,
  } = useFetchVisitsReservedByCustomerPaginated(
    filter,
    sort,
    numReservationsPerPage,
    page
  );

  const { mutate: updateIsConfirmedByStaff } = useUpdateIsConfirmedByStaff();

  useEffect(() => {
    const updateOpenedAt = () => {
      dispatch(updateRecentReservationOpenedAt());
    };

    updateOpenedAt();

    // 新着の予約情報をポーリングする
    const timer = setInterval(() => {
      refetch();
      updateOpenedAt();
    }, 30000);

    return function cleanup() {
      clearInterval(timer);
    };
  }, [page, refetch, dispatch]);

  const handleChangePage = useCallback(
    (page: number) => setPage(page),
    [setPage]
  );

  const handleClickReservation = useCallback(
    (visitId: number, date: string) => {
      dispatch(setViewSchedulePreviewVisitId(visitId));
      dispatch(
        setViewScheduleDrawerStatus({
          schedulePreview: true,
        })
      );
      dispatch(setViewScheduleCurrentDate(date));
    },
    [dispatch]
  );

  const handleClickConfirm = useCallback(
    async (visit: VisitResource, isConfirmedByStaff: boolean) => {
      updateIsConfirmedByStaff(
        visit.id,
        visit.userUpdatedAt,
        isConfirmedByStaff
      );
    },
    [updateIsConfirmedByStaff]
  );

  return (
    <div className={classes.container}>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CSelect
              fullWidth
              label="並び順"
              value={sort}
              options={[
                { value: 'createdAtDesc', element: '受付日時順' },
                { value: 'startedAtDesc', element: '予約日時順' },
              ]}
              onChange={(sortOrder) => {
                if (sortOrder !== undefined) {
                  setSort(sortOrder);
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <CSelect
              fullWidth
              label="確認状況"
              value={
                filter.isConfirmedByStaff === undefined
                  ? 1
                  : filter.isConfirmedByStaff === true
                    ? 2
                    : 3
              }
              options={[
                { value: 1, element: 'すべて' },
                { value: 2, element: '確認済み' },
                { value: 3, element: '未確認' },
              ]}
              onChange={(v) =>
                setFilter({
                  isConfirmedByStaff:
                    v === 1 ? undefined : v === 2 ? true : false,
                })
              }
            />
          </Grid>
        </Grid>
      </Box>
      <List>
        {visits.map((visit) => (
          <ReservationItem
            key={visit.id}
            visit={visit}
            onClick={handleClickReservation}
            onClickConfirm={handleClickConfirm}
          />
        ))}
      </List>
      <Pagination
        page={page}
        count={count}
        numReservationPerPage={numReservationsPerPage}
        onChangePage={handleChangePage}
      />
    </div>
  );
};

const ReservationItem: FC<{
  visit: VisitResource;
  onClick(scheduleId: number, date: string): void;
  onClickConfirm(visit: VisitResource, isConfirmedByStaff: boolean): void;
}> = React.memo(function ReservationItem(props) {
  const classes = useStyles();
  const { visit, onClick, onClickConfirm } = props;

  const stylistMap = useSelector(selectStylistMap);
  const assignedStylist = visit.assignedStylistId
    ? (stylistMap[visit.assignedStylistId] ?? null)
    : null;
  const voucher = visit.voucher;
  const categories = useSelectVoucherLineServiceCategories(voucher);

  const [isConfirmedByStaff, setIsConfirmedByStaff] = useState(
    visit.isConfirmedByStaff
  );

  useEffect(
    () => setIsConfirmedByStaff(visit.isConfirmedByStaff),
    [visit.isConfirmedByStaff]
  );

  const handleClickCheck = useCallback(() => {
    setIsConfirmedByStaff(!isConfirmedByStaff);
    onClickConfirm(visit, !isConfirmedByStaff);
  }, [isConfirmedByStaff, onClickConfirm, visit]);

  const startMom = moment.unix(visit.startedAt);
  const finishMom = moment.unix(visit.finishedAt);

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <ListItem
        button
        classes={{ gutters: classes.listItemGutter }}
        onClick={() => onClick(visit.id, startMom.format('YYYY-MM-DD'))}
      >
        <Grid container alignItems="center" wrap="nowrap" spacing={2}>
          <Grid item>
            <Typography variant="body1">
              {startMom.format('M/D (ddd)')}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {startMom.format('H:mm')}
            </Typography>
            <Typography variant="body2" color="textSecondary" align="right">
              - {finishMom.format('H:mm')}
            </Typography>
          </Grid>
          <Grid item xs className={classes.reservationDetailsContainer}>
            <Typography variant="body1" noWrap>
              {visit.customer ? `${visit.customer.name} 様` : visit.title}
            </Typography>
            <div className={classes.reservationDetailsItem}>
              <CStylist stylist={assignedStylist} />
              {visit.isShimei && <CShimeiIcon />}
            </div>
            <Grid container className={classes.reservationDetailsItem}>
              <Grid item xs={12} className={classes.menuIconContainer}>
                <CServiceCategoryIcons size={16} categories={categories} />
              </Grid>
            </Grid>
            <div className={classes.reservationDetailsItem}>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.reservationDetailsReceptionText}
              >
                受付 {moment.unix(visit.createdAt).format('M月D日 H:mm')}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </ListItem>
      <Button
        className={classes.reservationCheckButton}
        onClick={handleClickCheck}
        disabled={!VisitUtils.isReservation(visit)}
      >
        <Checkbox disableRipple checked={isConfirmedByStaff} />
      </Button>
    </div>
  );
});

const Pagination: FC<{
  page: number;
  count: number;
  numReservationPerPage: number;
  onChangePage(page: number): void;
}> = React.memo(function Pagination(props) {
  const { page, count, numReservationPerPage, onChangePage } = props;
  return (
    <TablePagination
      count={count}
      rowsPerPage={numReservationPerPage}
      rowsPerPageOptions={[]}
      page={page}
      onPageChange={(_, page) => onChangePage(page)}
      labelDisplayedRows={({ from, to, count }) =>
        `${from} - ${to} (${count} 件中)`
      }
    />
  );
});
