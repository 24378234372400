import { DiscountType } from '@karutekun/core/salon-service';
import { VoucherLineFormData } from '@karutekun/shared/data-access/voucher';
import { useBoolean } from '@karutekun/shared/util/react-hooks';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback, useEffect, useState } from 'react';
import CMoneyInput from '../../../../../components_old/atoms/CMoneyInput';
import CPriceLine from '../../../../../components_old/atoms/CPriceLine';
import CRow from '../../../../../components_old/atoms/CRow';
import CShimeiIcon from '../../../../../components_old/atoms/CShimeiIcon';
import CStylistAvatar from '../../../../../components_old/atoms/CStylistAvatar';
import { useSelectStylist } from '../../../../../selectors/salonSelector';
import {
  useSelectSalonServices,
  useSelectService,
} from '../../../../../selectors/serviceSelector';
import { formatMoney } from '../../../../../util/common';
import EditVoucherLineStylistDialog from './EditVoucherLineStylistDialog';

type Props = {
  open: boolean;
  defaultLineData: VoucherLineFormData;
  defaultIsShimei: boolean;
  onOk(line: VoucherLineFormData): void;
  onDelete(): void;
  onCancel(): void;
};

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    marginTop: theme.spacing(2),
  },
  listItemSecondaryAction: {
    paddingRight: 80,
  },
  listItemAvatar: {
    minWidth: 40,
  },
  priceContainer: {
    padding: theme.spacing(2),
  },
  discount: {
    color: theme.palette.primary.main,
  },
  adjustPriceError: {
    marginTop: theme.spacing(1),
  },
}));

export const EditVoucherLineDialog: FC<Props> = (props) => {
  const classes = useStyles();
  const { open, defaultLineData, defaultIsShimei, onCancel, onOk } = props;

  const [line, setLine] = useState<VoucherLineFormData>(defaultLineData);
  const [stylistDialogOpen, stylistDialogOpenMutations] = useBoolean(false);

  const service = useSelectService(line?.serviceId ?? 0);
  const { discountMap } = useSelectSalonServices();

  useEffect(() => {
    if (open) {
      setLine(defaultLineData);
    }
  }, [open, defaultLineData]);

  const editLine = useCallback((updates: Partial<VoucherLineFormData>) => {
    setLine((line) => ({ ...line, ...updates }));
  }, []);

  const isValid = line.quantity !== 0;

  return (
    <>
      <Dialog open={open} onClose={onCancel} maxWidth="xs">
        <DialogTitle>{service?.name}</DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid item xs={6}>
              <CMoneyInput
                label="単価"
                style={{ width: 80 }}
                allowNegative={false}
                value={line.adjustedUnitPrice}
                onChangeAmount={(adjustedUnitPrice) => {
                  if (adjustedUnitPrice !== null) {
                    editLine({ adjustedUnitPrice });
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="standard"
                label="個数"
                value={line.quantity}
                type="number"
                onChange={(e) => {
                  const quantity = Number(e.target.value.trim());
                  editLine({ quantity });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.priceContainer}>
                <CPriceLine>
                  <Typography variant="body1" />
                  <Typography variant="body1">
                    {formatMoney(line.adjustedUnitPrice)}
                    {line.quantity !== 1 && ` × ${line.quantity}`}
                  </Typography>
                </CPriceLine>

                {line.discounts.map((vld, i) => (
                  <div key={i}>
                    <CPriceLine
                      onClickDelete={() =>
                        editLine({
                          discounts: line.discounts.filter(
                            (_, index) => index !== i
                          ),
                        })
                      }
                    >
                      <Typography variant="body1">
                        {discountMap[vld.discountId]?.name}
                      </Typography>
                      <Typography variant="body1" className={classes.discount}>
                        {vld.type === DiscountType.Percentage
                          ? `-${vld.value}%`
                          : `${formatMoney(-vld.value)}`}
                      </Typography>
                    </CPriceLine>
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>

          <Typography variant="body2" className={classes.header}>
            担当可能スタッフ
          </Typography>
          <List dense>
            {line.stylists.map((vls, i) => (
              <VoucherLineStylistListItem key={i} lineStylist={vls} />
            ))}
          </List>
          <Button
            color="secondary"
            variant="outlined"
            onClick={stylistDialogOpenMutations.setTrue}
          >
            担当者を選択
          </Button>

          <TextField
            fullWidth
            multiline
            className={classes.header}
            label="メモ"
            placeholder="メモが記載できます"
            inputProps={{ maxLength: 255 }}
            InputLabelProps={{ shrink: true }}
            value={line.note ?? ''}
            onChange={(e) => editLine({ note: e.target.value })}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={onCancel}>
            キャンセル
          </Button>
          <Button
            disabled={!isValid}
            variant="contained"
            color="primary"
            onClick={() => onOk(line)}
          >
            決定
          </Button>
        </DialogActions>
      </Dialog>
      <EditVoucherLineStylistDialog
        open={stylistDialogOpen}
        defaultIsShimei={defaultIsShimei}
        onOk={(stylists) => {
          stylistDialogOpenMutations.setFalse();
          editLine({ stylists });
        }}
        onCancel={stylistDialogOpenMutations.setFalse}
      />
    </>
  );
};

const VoucherLineStylistListItem: FC<{
  lineStylist: VoucherLineFormData['stylists'][number];
}> = (props) => {
  const classes = useStyles();

  const { lineStylist } = props;
  const stylist = useSelectStylist(lineStylist.stylistId);

  return (
    <ListItem
      dense
      classes={{
        secondaryAction: classes.listItemSecondaryAction,
      }}
    >
      <ListItemAvatar className={classes.listItemAvatar}>
        <CStylistAvatar stylist={stylist} size={20} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <CRow>
            <Typography variant="body2">{stylist?.name}</Typography>
            {lineStylist.isShimei && <CShimeiIcon ml={4} />}
          </CRow>
        }
      />
    </ListItem>
  );
};
