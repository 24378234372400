import { PermissionRole } from '@karutekun/core/permission-role';
import { mapById } from '@karutekun/shared/util/objects';
import { Dispatch } from 'redux';
import { sendRequest } from '../../actions/request';
import { setSalonStylist, setSalonStylistMap } from '../../actions/salonAction';
import { OverwriteOption } from '../../models/salonScheduleSettings';
import {
  PlainStylistShiftSetting,
  createPlainMySalonStylistFromResource,
  createStylistShiftSettingFromResource,
} from '../../models/stylist';

/**
 * スタイリスト一覧取得
 */
export function fetchStylists() {
  return async function (dispatch: Dispatch) {
    const json = await sendRequest(dispatch, 'stylists');

    const stylists = json.map(createPlainMySalonStylistFromResource);

    dispatch(setSalonStylistMap(mapById(stylists)));

    return stylists;
  };
}

/**
 * スタイリストのアクセス制限
 */
export function updateStylistAccess(stylistId: number, canAccess: boolean) {
  return async function (dispatch: Dispatch) {
    const json = await sendRequest(
      dispatch,
      `salons/settings/stylists/${stylistId}/access`,
      {
        method: 'POST',
        body: JSON.stringify({ canAccess }),
      }
    );

    const stylist = createPlainMySalonStylistFromResource(json);

    dispatch(setSalonStylistMap({ [stylist.id]: stylist }));

    return stylist;
  };
}

/**
 * スタイリストの役割変更
 */
export function updateStylistPermissionRole(
  stylistId: number,
  permissionRoleId: number
) {
  return async function (dispatch: Dispatch) {
    const json = await sendRequest(
      dispatch,
      `salons/settings/stylists/${stylistId}/role`,
      {
        method: 'POST',
        body: JSON.stringify({ roleId: permissionRoleId }),
      }
    );

    const permissionRole: PermissionRole = json;

    dispatch(
      setSalonStylist(stylistId, { permissionRoleId: permissionRole.roleId })
    );
  };
}

/**
 * スタイリストの所属解除
 */
export function deactivateStylist(stylistId: number) {
  return async function (dispatch: Dispatch) {
    const json = await sendRequest(
      dispatch,
      `salons/settings/stylists/${stylistId}/deactivate`,
      { method: 'POST' }
    );

    const stylist = createPlainMySalonStylistFromResource(json);

    dispatch(setSalonStylistMap({ [stylist.id]: stylist }));

    return stylist;
  };
}

/**
 * スタイリストのシフト変更
 */
export function updateStylistShift(
  stylistId: number,
  shiftSetting: PlainStylistShiftSetting,
  option: OverwriteOption
) {
  return async function (dispatch: Dispatch) {
    const json = await sendRequest(
      dispatch,
      `salons/settings/stylists/${stylistId}/shift`,
      {
        method: 'POST',
        body: JSON.stringify({ basicShiftSetting: shiftSetting, ...option }),
      }
    );

    const shift = createStylistShiftSettingFromResource(json);

    dispatch(setSalonStylist(stylistId, { basicShiftSetting: shift }));
  };
}

/**
 * スタイリストのシフト削除
 */
export function deleteStylistShift(stylistId: number) {
  return async function (dispatch: Dispatch) {
    await sendRequest(dispatch, `salons/settings/stylists/${stylistId}/shift`, {
      method: 'DELETE',
    });

    dispatch(setSalonStylist(stylistId, { basicShiftSetting: null }));
  };
}

/**
 * スタイリストの並び替え
 */
export function updateStylistOrder(order: number[]) {
  return async function (dispatch: Dispatch) {
    const json = await sendRequest(dispatch, `salons/settings/stylists/order`, {
      method: 'POST',
      body: JSON.stringify({ order }),
    });

    const stylists = json.map(createPlainMySalonStylistFromResource);
    dispatch(setSalonStylistMap(mapById(stylists)));

    return stylists;
  };
}
