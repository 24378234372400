import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { InformationText } from '../../../texts/infomation';
import CButton from '../../atoms/CButton';
import CInformation from '../../atoms/CInformation';

type OwnProps = {
  disabled: boolean;
  onClick(): void;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    borderWidth: 1,
    borderColor: theme.palette.grey[500],
    borderStyle: 'dotted',
    borderRadius: 8,
    backgroundColor: 'white',
    padding: theme.spacing(2),
  },
}));

const CAddSectionArea: FC<OwnProps> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <CButton
        disabled={props.disabled}
        variant="text"
        startIcon={<SVGIcon name="plus" />}
        onClick={props.onClick}
      >
        セクションを追加する
      </CButton>

      <CInformation
        type="dialog"
        content={InformationText.counselings.overall}
        size="lg"
        mb={8}
      />
    </div>
  );
};

export default CAddSectionArea;
