export function mapById<T extends { id: number }>(
  objects: T[]
): { [id: number]: T } {
  return objects.reduce((prev: { [id: number]: T }, current: T) => {
    prev[current.id] = current;
    return prev;
  }, {});
}

export function mapBy<T extends object>(
  objects: T[],
  key: keyof T
): { [key: string | number]: T } {
  return objects.reduce<{ [key in string | number]: T }>(
    (prev: { [key: string]: T }, current) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      prev[current[key] as any] = current;
      return prev;
    },
    {}
  );
}

export function mapByTwoKeys<T extends object>(
  objects: T[],
  key1: keyof T,
  key2: keyof T
): { [key1: string | number]: { [key2: string | number]: T } } {
  return objects.reduce<{
    [key in string | number]: { [key in string | number]: T };
  }>((prev: { [key: string]: { [key: string]: T } }, current) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!prev[current[key1] as any]) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      prev[current[key1] as any] = {};
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    prev[current[key1] as any][current[key2] as any] = current;
    return prev;
  }, {});
}
