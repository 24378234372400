import { dayjs } from './dayjs';

/**
 * ISO 標準の曜日を取得する
 * 1: 月曜日 〜 7: 日曜日
 */
export function isoWeekday(unixOrDate: string | number) {
  const d =
    typeof unixOrDate === 'number' ? dayjs.unix(unixOrDate) : dayjs(unixOrDate);
  return d.isoWeekday();
}
