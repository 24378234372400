import styled from '@emotion/styled';
import { useBoolean } from '@karutekun/shared/util/react-hooks';
import { SemanticColor } from '@karutekun/shared-fe/design-token';
import { SVGIcon, SVGName } from '@karutekun/shared-fe/icons/react';
import {
  ChangeEvent,
  InputHTMLAttributes,
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';

type Props = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'prefix' | 'onChange'
> & {
  value: string | undefined;
  onChange(value: string): void;
  placeholder?: string;
  prefixIconName?: SVGName;
  suffix?: string | JSX.Element;
  label?: string;
  errorText?: string;
  error?: boolean;
  required?: boolean;
  disabled?: boolean;
};

const Container = styled.div((props: { isError: boolean }) => ({
  width: '100%',
  borderRadius: '8px',
  backgroundColor: props.isError
    ? SemanticColor.alert
    : SemanticColor.backgroundWhite,
  overflow: 'hidden',
  transitionDelay: `${props.isError ? '0' : '0.3s'}`,
}));

const InputContainer = styled.div(
  (props: {
    isFocus: boolean;
    isError: boolean;
    hasText: boolean;
    disabled: boolean;
  }) => ({
    height: '56px',
    backgroundColor: `${
      props.disabled
        ? SemanticColor.backgroundSecondarySub
        : SemanticColor.backgroundWhite
    }`,
    borderWidth: `${props.isFocus ? '2px' : '1px'}`,
    borderStyle: 'solid',
    borderColor: `${
      props.disabled
        ? SemanticColor.borderThin
        : props.isError
          ? SemanticColor.alert
          : props.isFocus
            ? SemanticColor.textSecondary
            : SemanticColor.borderDark
    }`,
    borderRadius: '8px',
    padding: '0 16px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  })
);

const InputWrapper = styled.div(
  (props: { isFocus: boolean; hasText: boolean; hasLabel: boolean }) => ({
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    marginTop: `${
      (props.isFocus || props.hasText) && props.hasLabel ? '16px' : '0px'
    }`,
    position: 'relative',
  })
);

const Label = styled.label(
  (props: {
    isError: boolean;
    isFocus: boolean;
    hasText: boolean;
    disabled: boolean;
  }) => ({
    fontSize: `${props.isFocus || props.hasText ? '12px' : '16px'}`,
    lineHeight: `${props.isFocus || props.hasText ? '18px' : '24px'}`,
    color: `${
      props.disabled
        ? SemanticColor.textPrimaryDisabled
        : props.isError
          ? SemanticColor.alert
          : SemanticColor.textPrimarySub
    }`,
    position: `${props.isFocus || props.hasText ? 'absolute' : 'static'}`,
    flexShrink: 0,
    transform: `${
      props.isFocus || props.hasText ? 'translateY(-20px)' : 'translateY(0px)'
    }`,
    transitionDuration: '0.2s',
    transitionTimingFunction: 'ease-out',
  })
);

const Prefix = styled.span((props: { disabled: boolean }) => ({
  fontSize: '16px',
  lineHeight: '24px',
  marginRight: '4px',
  display: 'flex',
  alignItems: 'center',
  flexShrink: 0,
  color: `${
    props.disabled
      ? SemanticColor.textPrimaryDisabled
      : SemanticColor.textPrimarySub
  }`,
}));

const Input = styled.input(
  (props: { isError: boolean; isFocus: boolean; disabled: boolean }) => ({
    'width': '100%',
    'fontSize': '16px',
    'lineHeight': '24px',
    'color': props.disabled
      ? SemanticColor.textPrimaryDisabled
      : SemanticColor.textPrimaryMain,
    'outline': 'none',
    'border': 'none',
    'flexGrow': 1,
    '::placeholder': {
      color: `${
        props.isError ? SemanticColor.alert : SemanticColor.placeholder
      }`,
      position: 'relative',
      top: '0.05em',
    },
  })
);

const Suffix = styled.span((props: { disabled: boolean }) => ({
  fontSize: '16px',
  lineHeight: '24px',
  color: `${
    props.disabled
      ? SemanticColor.textPrimaryDisabled
      : SemanticColor.textPrimarySub
  }`,
}));

const Alert = styled.div((props: { showAlert: boolean }) => ({
  fontSize: '13px',
  lineHeight: '18px',
  backgroundColor: SemanticColor.alert,
  color: SemanticColor.textWhite,
  fontWeight: 600,
  transitionDuration: '0.2s',
  transitionProperty: 'height',
  transitionTimingFunction: 'ease-out',
  padding: `${props.showAlert ? '4px 0 4px 16px' : '0 0 0 16px'}`,
  height: `${props.showAlert ? '26px' : '0px'}`,
  boxSizing: 'border-box',
}));

export const CTextField = memo(
  forwardRef<HTMLInputElement, Props>((props, ref) => {
    const {
      value,
      onChange,
      prefixIconName,
      suffix,
      label,
      errorText,
      placeholder,
      required = false,
      error = false,
      disabled = false,
      style,
      ...textInputProps
    } = props;

    const [isFocus, setIsFocus] = useBoolean(false);
    const [isError, setIsError] = useBoolean(false);
    const textInputRef = useRef<HTMLInputElement | null>(null);
    useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(
      ref,
      () => textInputRef.current
    );

    const hasText = value ? value.length > 0 : false;
    const labelText = required
      ? `${label ? label : ''}(必須) `
      : label
        ? label
        : '';

    const handleOnPressContainer = useCallback(() => {
      if (disabled) {
        return;
      }
      textInputRef.current?.focus();
    }, [disabled, textInputRef]);

    const handleOnFocus = useCallback(() => {
      if (disabled) {
        return;
      }
      setIsFocus.setTrue();
    }, [disabled, setIsFocus]);

    const handleOnChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value);
      },
      [onChange]
    );

    const handleOnBlur = useCallback(() => {
      if (disabled) {
        return;
      }
      if (error) {
        setIsError.setTrue();
      } else {
        setIsError.setFalse();
      }
      setIsFocus.setFalse();
    }, [error, setIsError, setIsFocus, disabled]);

    return (
      <Container
        isError={isError}
        onClick={handleOnPressContainer}
        style={style}
      >
        <InputContainer
          isFocus={isFocus}
          isError={isError}
          hasText={hasText}
          disabled={disabled}
        >
          <InputWrapper
            isFocus={isFocus}
            hasText={hasText}
            hasLabel={!!label || required}
          >
            {prefixIconName && (
              <Prefix disabled={disabled}>
                <SVGIcon name={prefixIconName} />
              </Prefix>
            )}
            <Label
              isError={isError}
              isFocus={isFocus}
              hasText={hasText}
              disabled={disabled}
            >
              {labelText}
            </Label>
            <Input
              isError={isError}
              isFocus={isFocus}
              ref={textInputRef}
              value={value}
              placeholder={label && !isFocus ? '' : placeholder}
              onChange={handleOnChange}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
              disabled={disabled}
              {...textInputProps}
            />
          </InputWrapper>
          {suffix && <Suffix disabled={disabled}>{suffix}</Suffix>}
        </InputContainer>
        <Alert showAlert={isError && !!errorText}>{errorText}</Alert>
      </Container>
    );
  })
);
