import { cancellationQuestionnaireSchema } from '@karutekun/shared/api-schema/salon';
import { trpc } from '@karutekun/shared/data-access/api-base';
import { useBoolean } from '@karutekun/shared/util/react-hooks';
import { z } from '@karutekun/shared/util/zod';
import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import {
  Backdrop,
  Box,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router';
import { dispatchWithErrorHandling } from '../../../../../actions/helper/dispatchWithErrorHandling';
import {
  cancelSubscription,
  fetchSubscriptionInfo,
  updateSubscriptionOptions,
} from '../../../../../actions/subscriptionAction';
import CButton from '../../../../../components_old/atoms/CButton';
import CDivider from '../../../../../components_old/atoms/CDivider';
import CProgress from '../../../../../components_old/atoms/CProgress';
import CTextBox from '../../../../../components_old/atoms/CTextBox';
import { CancelSubscriptionDialog } from '../../../../../features/subscription/components/CancelSubscriptionDialog';
import {
  BasicPlan,
  BasicPlanNameMap,
  OptionLinePlan,
  OptionLinePlanName,
} from '../../../../../models/salon';
import {
  Subscription,
  SubscriptionOptionStatus,
  SubscriptionOptionType,
  getSubscriptionOption,
  isSubscriptionCancellable,
  isSubscriptionEditable,
} from '../../../../../models/subscription';
import { selectMySalon } from '../../../../../selectors/salonSelector';
import { withAdminPage } from '../../../../../templates/hoc/AdminPageHOC';
import {
  WithConfirmDialog,
  withConfirmDialog,
} from '../../../../../templates/hoc/ConfirmDialogHOC';
import { formatDate } from '../../../../../util/common';
import { useFlag } from '../../../../../util/hooks/useFlag';
import { useThunkDispatch } from '../../../../../util/hooks/useThunkDispatch';

type Props = WithConfirmDialog;

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const CancelSubscription: FC<Props> = (props) => {
  const classes = useStyles();

  const { openConfirmDialog, closeConfirmDialog } = props;

  const dispatch = useThunkDispatch();
  const navigate = useNavigate();

  const salon = useSelector(selectMySalon);

  const [isFetching, callWithIsFetching] = useFlag(false);
  const [isSubmitting, callWithIsSubmitting] = useFlag(false);

  const { mutateAsync } =
    trpc.salon.saveCancellationQuestionnaire.useMutation();

  useEffect(() => {
    callWithIsFetching(async () => dispatch(fetchSubscriptionInfo));
  }, [callWithIsFetching, dispatch]);

  const [isCancelDialogOpen, setIsCancelDialogOpen] = useBoolean(false);

  const handleCancelBasicPlan = useCallback(
    (data: z.infer<typeof cancellationQuestionnaireSchema>) => {
      openConfirmDialog({
        title: '確認',
        description:
          '基本プランと、すべてのオプションを解約します。本当によろしいですか？',
        onOk: async () => {
          closeConfirmDialog();
          setIsCancelDialogOpen.setFalse();
          await callWithIsSubmitting(async () => {
            await mutateAsync(data);
            await dispatchWithErrorHandling(dispatch, cancelSubscription(), {
              success: '解約処理が完了しました',
              onSuccess() {
                navigate('/admin/plan');
              },
            });
          });
        },
      });
    },
    [
      callWithIsSubmitting,
      closeConfirmDialog,
      dispatch,
      mutateAsync,
      navigate,
      openConfirmDialog,
      setIsCancelDialogOpen,
    ]
  );

  const handleCancelOptionLinePlan = useCallback(() => {
    openConfirmDialog({
      title: '確認',
      description: `${OptionLinePlanName}を解約します。本当によろしいですか？`,
      onOk: async () => {
        closeConfirmDialog();
        await callWithIsSubmitting(async () => {
          await dispatchWithErrorHandling(
            dispatch,
            updateSubscriptionOptions(OptionLinePlan.Trial),
            {
              success: '解約処理が完了しました',
              onSuccess() {
                navigate('/admin/plan');
              },
            }
          );
        });
      },
    });
  }, [
    callWithIsSubmitting,
    closeConfirmDialog,
    dispatch,
    navigate,
    openConfirmDialog,
  ]);

  if (!isFetching && !isSubscriptionEditable(salon.subscription)) {
    return <Navigate to="/admin/plan" replace />;
  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={isSubmitting}>
        <CProgress />
      </Backdrop>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">解約</Typography>
        </Grid>
        <Grid item xs={12}>
          <Card>
            {isFetching ? (
              <CProgress />
            ) : (
              <PlanCancelTable
                salonName={salon.name}
                basicPlan={salon.basicPlan}
                subscription={salon.subscription}
                isCancelDialogOpen={isCancelDialogOpen}
                onCancelBasicPlan={handleCancelBasicPlan}
                onClickCancelButton={setIsCancelDialogOpen.setTrue}
                onCancelOptionLinePlan={handleCancelOptionLinePlan}
                onCloseCancelDialog={setIsCancelDialogOpen.setFalse}
              />
            )}
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

const PlanCancelTable: FC<{
  salonName: string;
  basicPlan: BasicPlan;
  subscription: Subscription | null;
  isCancelDialogOpen: boolean;
  onClickCancelButton(): void;
  onCloseCancelDialog(): void;
  onCancelBasicPlan(
    data: z.infer<typeof cancellationQuestionnaireSchema>
  ): void;
  onCancelOptionLinePlan(): void;
}> = (props) => {
  const {
    salonName,
    basicPlan,
    subscription,
    isCancelDialogOpen,
    onClickCancelButton,
    onCloseCancelDialog,
    onCancelBasicPlan,
    onCancelOptionLinePlan,
  } = props;

  if (!subscription || !isSubscriptionCancellable(subscription)) {
    return <CTextBox type="error">契約状態が無効です</CTextBox>;
  }

  const lineOption = getSubscriptionOption(
    subscription,
    SubscriptionOptionType.Line
  );

  return (
    <>
      <CTextBox m={2}>
        ※
        解約後も、お支払いいただいた期間が終了するまでは引き続き現在のプランでご利用いただけます。
        {'\n'}※
        解約後お支払期間が終了しますと、自動的に現在のプランが解除され、機能が制限されます。
      </CTextBox>

      <CDivider spacing={0} />

      <Table>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography variant="subtitle1">サロン名</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1">{salonName}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography variant="subtitle1">基本プラン</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1" gutterBottom>
                {BasicPlanNameMap[basicPlan]}
              </Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                {formatDate(subscription.nextPaymentAt)} まで有効
              </Typography>

              <Box mt={1}>
                <CButton
                  variant="outlined"
                  startIcon={<SVGIcon name="times" />}
                  onClick={onClickCancelButton}
                >
                  基本プランとすべてのオプションを解約する
                </CButton>
                <CancelSubscriptionDialog
                  open={isCancelDialogOpen}
                  onCancelPlan={onCancelBasicPlan}
                  onClose={onCloseCancelDialog}
                />
              </Box>
            </TableCell>
          </TableRow>
          {lineOption &&
            lineOption.status !== SubscriptionOptionStatus.Canceled && (
              <TableRow>
                <TableCell component="th" scope="row">
                  <Typography variant="subtitle1">オプション</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" gutterBottom>
                    {OptionLinePlanName}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    gutterBottom
                  >
                    {formatDate(subscription.nextPaymentAt)} まで有効
                  </Typography>

                  {lineOption.status === SubscriptionOptionStatus.Active ? (
                    <Box mt={1}>
                      <CButton
                        variant="outlined"
                        startIcon={<SVGIcon name="times" />}
                        onClick={onCancelOptionLinePlan}
                      >
                        {OptionLinePlanName}を解約する
                      </CButton>
                    </Box>
                  ) : (
                    <CTextBox mt={1}>解約手続きは完了しています</CTextBox>
                  )}
                </TableCell>
              </TableRow>
            )}
        </TableBody>
      </Table>
    </>
  );
};

export default withConfirmDialog(withAdminPage(CancelSubscription));
