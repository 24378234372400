import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { selectUserLoginInfo } from '../../selectors/userSelector';

const LoginRequired: FC = ({ children }) => {
  const location = useLocation();
  const loginInfo = useSelector(selectUserLoginInfo);

  if (!loginInfo) {
    const params = new URLSearchParams(location.search);
    const email = params.get('email');
    params.delete('email');
    const newParams = new URLSearchParams({
      redirect: `${location.pathname}?${params.toString()}`,
    });
    if (email) {
      newParams.set('email', email);
    }
    return <Navigate to={`/login?${newParams.toString()}`} replace />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default LoginRequired;
