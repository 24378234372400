import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M12 2.75c.966 0 1.75.784 1.75 1.75v1.25h20.5V4.5a1.75 1.75 0 1 1 3.5 0v1.25H39A6.25 6.25 0 0 1 45.25 12v27A6.25 6.25 0 0 1 39 45.25H9A6.25 6.25 0 0 1 2.75 39V12A6.25 6.25 0 0 1 9 5.75h1.25V4.5c0-.966.784-1.75 1.75-1.75m0 6.5h27A2.75 2.75 0 0 1 41.75 12v1.25H6.25V12A2.75 2.75 0 0 1 9 9.25zm-5.75 7.5h35.5V39A2.75 2.75 0 0 1 39 41.75H9A2.75 2.75 0 0 1 6.25 39zm11.765 2.3c-2.704 0-4.376 1.488-5.055 2.634a1.75 1.75 0 1 0 3.013 1.782c.133-.225.734-.915 2.042-.915.892 0 1.617.246 2.074.594.41.312.666.74.666 1.396v.02a1.802 1.802 0 0 1-.53 1.297l-.016.016c-.286.293-.689.533-1.14.7-.458.17-.857.223-1.05.223a1.75 1.75 0 1 0 0 3.5c.249 0 .728.067 1.269.267.534.197 1.022.485 1.375.846l.002.002c.338.344.585.92.585 1.78 0 .76-.298 1.286-.773 1.662-.515.407-1.33.697-2.364.697-1.363 0-2.286-1.02-2.606-1.562a1.75 1.75 0 0 0-3.014 1.779c.595 1.008 2.452 3.283 5.62 3.283 1.647 0 3.276-.457 4.534-1.45 1.297-1.026 2.103-2.555 2.103-4.408 0-1.505-.439-3.061-1.585-4.23a6.34 6.34 0 0 0-.575-.52 5.306 5.306 0 0 0 1.665-3.91c-.003-1.79-.805-3.228-2.045-4.172-1.195-.91-2.715-1.31-4.195-1.31m14.587.376c-.374 0-.738.12-1.039.341l-4.07 3a1.75 1.75 0 0 0 2.076 2.817l1.712-1.261v12.603a1.75 1.75 0 1 0 3.5 0v-15.75a1.75 1.75 0 0 0-1.75-1.75z"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgCalendarDay = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgCalendarDay);
export default Memo;
