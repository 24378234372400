import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M18.25 5.45a.7.7 0 0 1 .7-.7h10.1a.7.7 0 0 1 .7.7v1.1a.7.7 0 0 1-.7.7h-10.1a.7.7 0 0 1-.7-.7zm-3.326 2.3H13.5a2.75 2.75 0 0 0-2.75 2.75v30a2.75 2.75 0 0 0 2.75 2.75h21a2.75 2.75 0 0 0 2.75-2.75v-30a2.75 2.75 0 0 0-2.75-2.75h-1.424a4.202 4.202 0 0 1-4.026 3h-10.1a4.202 4.202 0 0 1-4.026-3m18.152-3.5a4.202 4.202 0 0 0-4.026-3h-10.1a4.202 4.202 0 0 0-4.026 3H13.5a6.25 6.25 0 0 0-6.25 6.25v30a6.25 6.25 0 0 0 6.25 6.25h21a6.25 6.25 0 0 0 6.25-6.25v-30a6.25 6.25 0 0 0-6.25-6.25z"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgClipboard = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgClipboard);
export default Memo;
