import * as Sentry from '@sentry/browser';
import FirebaseAnalytics from './firebaseAnalytics';

const env = process.env.REACT_APP_ENV;

const useSentry = env === 'dev' || env === 'prd';

// info ログを出力するかどうか。本番では出さない
const outputInfo = env === 'local' || env === 'dev';

type User = {
  id?: number;
  username?: string;
  email?: string | null;
  firebaseUid?: string;
  salonId?: number;
  salonName?: string;
  basicPlan?: number;
  optionLinePlan?: number;
};

export enum AnalyticsEvent {
  acceptSalonRequest = 'accept_salon_request',
  createStylist = 'create_stylist',
  createSalon = 'create_salon',
  createSalonRequest = 'create_salon_request',
  createCustomer = 'create_customer',
  createServiceCategory = 'create_service_category',
  updateServiceCategory = 'update_service_category',
  updateServiceCategoryOrder = 'update_service_category_order',
  deleteServiceCategory = 'delete_service_category',
  createService = 'create_service',
  updateService = 'update_service',
  updateServiceOrder = 'update_service_order',
  createServiceDiscount = 'create_service_discount',
  updateServiceDiscount = 'update_service_discount',
  updateServiceDiscountOrder = 'update_service_discount_order',
  deleteServiceDiscount = 'delete_service_discount',
  login = 'login',
  switchSalon = 'switch_salon',
  deleteCustomer = 'delete_customer',
  viewCheckPlan = 'view_check_plan',
  viewCreateSubscription = 'view_create_subscription',
  createSubscription = 'create_subscription',
  payVoucher = 'pay_voucher',
  currentTaxSwitcher = 'current_tax_switcher',
  changeTaxSwitcher = 'change_tax_switcher',
}

class LoggerClass {
  user: User = {};

  constructor() {
    this.info('[Logger] Initialize');

    if (useSentry) {
      this.info('[Logger] Initialize Sentry');
      Sentry.init({
        dsn: 'https://1801c61d952541c3986a5196dfd2f212@sentry.io/1356809',
        environment: env,
      });
    }
  }

  setUser(user: User) {
    this.info('[Logger] Set user info', user);

    this.user = {
      ...this.user,
      ...user,
    };
    Sentry.configureScope((scope) => {
      scope.setUser({
        id: `${this.user.id}`,
        username: this.user.username,
        email: this.user.email ?? undefined,
        extra: {
          salonId: this.user.salonId,
          salonName: this.user.salonName,
          firebaseUid: this.user.firebaseUid,
        },
      });
    });

    this.setupFirebaseAnalytics();
  }

  unsetUser() {
    this.info('[Logger] Unset user info');

    this.user = {};
    Sentry.configureScope((scope) => scope.clear());
    this.setupFirebaseAnalytics();
  }

  // TODO 一時的に lint を無効化しています。気づいたベースで直してください
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logEvent(name: AnalyticsEvent, props?: Record<string, any>) {
    FirebaseAnalytics.logEvent(name as string, props);
  }

  // TODO 一時的に lint を無効化しています。気づいたベースで直してください
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  info(message?: any, ...optionalParams: any[]) {
    if (outputInfo) {
      console.info(message, optionalParams);
    }
  }

  // TODO 一時的に lint を無効化しています。気づいたベースで直してください
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sendErrorReport(error: Error, extra: Record<string, any> = {}) {
    if (useSentry) {
      Sentry.withScope((scope) => {
        Object.keys(extra).forEach((key) => {
          scope.setExtra(key, extra[key]);
        });
        Sentry.captureException(error);
      });
    }
  }

  private setupFirebaseAnalytics() {
    const { id, salonId, basicPlan, optionLinePlan } = this.user;

    FirebaseAnalytics.setUserId(id);
    FirebaseAnalytics.setUserProperties({
      salonId: salonId ? `${salonId}` : null,
      basicPlan: basicPlan ? `${basicPlan}` : null,
      optionLinePlan: optionLinePlan ? `${optionLinePlan}` : null,
    });
  }
}

// tslint:disable variable-name
const Logger = new LoggerClass();

export default Logger;
