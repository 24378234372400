import { ISalonMonthlyStats } from '@karutekun/core/stats/salon-stats';
import { trpc } from '@karutekun/shared/data-access/api-base';
import {
  endOfMonthDate,
  startOfMonthDate,
  thisMonth,
} from '@karutekun/shared/util/datetime';
import { Card } from '@mui/material';
import { keepPreviousData } from '@tanstack/react-query';
import { memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import CChartBar from '../../../components_old/atoms/CChartBar';
import CMonthRangeControl from '../../../components_old/molecules/CMonthRangeControl';
import CChartWrapper from '../../../components_old/organisms/charts/CChartWrapper';
import { selectAnalyticsDisplayTaxIncludedPrice } from '../../../selectors/settingsSelector';
import { InformationText } from '../../../texts/infomation';
import { fmtMonth, fmtSalesShrink } from '../../../util/chart';
import { getSalesChartKeys } from './salesChartUtil';

type Props = {
  onChangeDate(range: { from: string; to: string }): void;
};

export const SalonMonthlyStatsCard: FC<Props> = memo(({ onChangeDate }) => {
  const [{ from, to }, setRange] = useState({
    from: thisMonth(-12),
    to: thisMonth(),
  });

  const displayTaxIncludedPrice = useSelector(
    selectAnalyticsDisplayTaxIncludedPrice
  );

  const { isRefetching, data: monthlyStats = [] } =
    trpc.analytics.salonStats.monthlyStats.useQuery(
      { from, to },
      { placeholderData: keepPreviousData }
    );

  const handleClickChart = useCallback(
    (stats: ISalonMonthlyStats) => {
      onChangeDate({
        from: startOfMonthDate(stats.month),
        to: endOfMonthDate(stats.month),
      });
    },
    [onChangeDate]
  );

  return (
    <Card>
      <CChartWrapper
        title="月次売上 推移"
        rightComponent={
          <div style={{ flex: 1 }}>
            <CMonthRangeControl
              from={from}
              to={to}
              onChange={(from, to) => setRange({ from, to })}
            />
          </div>
        }
        isFetching={isRefetching}
        informationText={InformationText.salon.monthlySales}
      >
        <CChartBar
          data={monthlyStats}
          nameKey={(s) => fmtMonth(s.month)}
          formatter={fmtSalesShrink}
          valueKeys={getSalesChartKeys(displayTaxIncludedPrice)}
          stack
          onClick={handleClickChart}
        />
      </CChartWrapper>
    </Card>
  );
});
