import { moment } from '@karutekun/shared/util/datetime';
import { Box, InputLabel, Typography } from '@mui/material';
import { CSSProperties, makeStyles } from '@mui/styles';
import React, { useCallback } from 'react';
import CTimePicker from '../atoms/CTimePicker';

type OwnProps = {
  label?: string;

  // HH:mm:ss 形式
  from: string;
  to: string;

  small?: boolean;
  disabled?: boolean;

  minuteInterval?: MinuteInterval;

  justify?: CSSProperties['justifyContent'];
  // 24:00 を非表示にするかどうか
  hide24?: boolean;

  // HH:mm:ss 形式
  onChange(from: string, to: string): void;
};

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
}));

const CTimeRange: FC<OwnProps> = React.memo(function CTimeRage(props) {
  const classes = useStyles();

  const {
    from,
    to,
    small,
    hide24,
    onChange,
    disabled,
    minuteInterval = 15,
    justify = 'center',
  } = props;

  const handleChange = useCallback(
    (update: { from: string; to: string }) => {
      const f = update.from;
      let t = update.to;

      // 開始が終了よりあとの場合は、終了を開始に揃える
      if (moment(f, 'HH:mm:ss').isAfter(moment(t, 'HH:mm:ss'))) {
        t = f;
      }

      onChange(f, t);
    },
    [onChange]
  );

  const handleFromChange = useCallback(
    (from: string) => handleChange({ from, to: props.to }),
    [handleChange, props.to]
  );
  const handleToChange = useCallback(
    (to: string) => handleChange({ to, from: props.from }),
    [handleChange, props.from]
  );

  const spacing = small ? 0.3 : 1;

  return (
    <div>
      <InputLabel shrink>{props.label}</InputLabel>

      <div className={classes.container} style={{ justifyContent: justify }}>
        <Box mr={spacing}>
          <CTimePicker
            value={from}
            small={small}
            disabled={disabled}
            minuteInterval={minuteInterval}
            onChange={handleFromChange}
            hide24={hide24}
          />
        </Box>

        <Typography variant="body2">{small ? '-' : '〜'}</Typography>

        <Box ml={spacing}>
          <CTimePicker
            value={to}
            small={small}
            disabled={disabled}
            minuteInterval={minuteInterval}
            onChange={handleToChange}
            hide24={hide24}
          />
        </Box>
      </div>
    </div>
  );
});

export default CTimeRange;
