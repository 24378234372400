export const fontFamily = {
  web: [
    'Open Sans',
    'Hiragino Sans',
    'ヒラギノ角ゴシック',
    'Roboto',
    'sens-serif',
  ].join(','),
  ios: 'Hiragino Sans',
  android: 'Open Sans',
};

export const fontWeightMap = {
  regular: '400',
  bold: '700',
} as const;

const letterSpacing = 0.5;

export const FontSizeMap = {
  'xl': 32,
  'lg': 22,
  'md': 15,
  'sm': 14,
  'xs': 13,
  '2xs': 10,
} as const;

export const LineHeightMap = {
  'xl': 36,
  'lg': 24,
  'md': 24,
  'sm': 20,
  'xs': 18,
  '2xs': 16,
} as const;

export const Typography = {
  titlePrimary: {
    fontWeight: fontWeightMap.bold,
    fontSize: FontSizeMap['xl'],
    letterSpacing,
    lineHeight: LineHeightMap['xl'],
  },
  titleSecondary: {
    fontWeight: fontWeightMap.bold,
    fontSize: FontSizeMap['lg'],
    letterSpacing,
    lineHeight: LineHeightMap['lg'],
  },
  titleTertiary: {
    fontWeight: fontWeightMap.bold,
    fontSize: FontSizeMap['md'],
    letterSpacing,
    lineHeight: LineHeightMap['md'],
  },
  bodyText: {
    fontWeight: fontWeightMap.regular,
    fontSize: FontSizeMap['md'],
    letterSpacing,
    lineHeight: LineHeightMap['md'],
  },
  captionPrimaryBold: {
    fontWeight: fontWeightMap.bold,
    fontSize: FontSizeMap['sm'],
    letterSpacing,
    lineHeight: LineHeightMap['sm'],
  },
  captionPrimary: {
    fontWeight: fontWeightMap.regular,
    fontSize: FontSizeMap['sm'],
    letterSpacing,
    lineHeight: LineHeightMap['sm'],
  },
  captionSecondaryBold: {
    fontWeight: fontWeightMap.bold,
    fontSize: FontSizeMap['xs'],
    letterSpacing,
    lineHeight: LineHeightMap['xs'],
  },
  captionSecondary: {
    fontWeight: fontWeightMap.regular,
    fontSize: FontSizeMap['xs'],
    letterSpacing,
    lineHeight: LineHeightMap['xs'],
  },
  captionTertiary: {
    fontWeight: fontWeightMap.regular,
    fontSize: FontSizeMap['2xs'],
    letterSpacing,
    lineHeight: LineHeightMap['2xs'],
  },
  captionTertiaryBold: {
    fontWeight: fontWeightMap.bold,
    fontSize: FontSizeMap['2xs'],
    letterSpacing,
    lineHeight: LineHeightMap['2xs'],
  },
};
