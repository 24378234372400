import {
  Backdrop,
  Box,
  Button,
  Card,
  Container,
  Grid,
  Link,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormEvent, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { dispatchWithErrorHandling } from '../../actions/helper/dispatchWithErrorHandling';
import { signup } from '../../actions/userAction';
import { Images } from '../../assets/images';
import CProgress from '../../components_old/atoms/CProgress';
import CTextLink from '../../components_old/atoms/CTextLink';
import { selectUserLoginInfo } from '../../selectors/userSelector';
import { useRedirect } from '../../util/hooks/router/useRedirect';
import { useFlag } from '../../util/hooks/useFlag';
import { useThunkDispatch } from '../../util/hooks/useThunkDispatch';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  card: {
    backgroundColor: theme.palette.primary.main,
  },
  innerContainer: {
    padding: theme.spacing(2),
    backgroundColor: '#fff',
  },
  passwordResetContainer: {
    padding: theme.spacing(2),
    backgroundColor: '#fff',
  },
  footer: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
  },
  footerLink: {
    color: '#fff',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  link: {
    textDecoration: 'none',
  },
  appealMinute: {
    color: theme.palette.primary.main,
  },
}));

export const Signup: FC = () => {
  const classes = useStyles();

  const dispatch = useThunkDispatch();

  const [isFetching, callWithFetching] = useFlag(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmitValidatorForm = async (e: FormEvent) => {
    e.preventDefault();
    await callWithFetching(async () => {
      await dispatchWithErrorHandling(
        dispatch,
        signup({ name, email, phone, password })
      );
    });
  };

  // すでにログイン済みであればリダイレクト
  const loginInfo = useSelector(selectUserLoginInfo);
  useRedirect(loginInfo !== null);

  return (
    <Container className={classes.root} maxWidth="sm">
      <Backdrop className={classes.backdrop} open={isFetching}>
        <CProgress />
      </Backdrop>
      <Card className={classes.card}>
        <Grid
          container
          justifyContent="space-evenly"
          alignItems="center"
          direction="column"
          style={{
            paddingTop: 40,
            paddingBottom: 40,
            paddingLeft: 32,
            paddingRight: 32,
          }}
        >
          <img
            src={Images.common.logo}
            alt="ユーザー登録"
            style={{ width: '60%' }}
          />
          <Typography
            variant="h5"
            align="center"
            style={{ color: '#fff', paddingTop: 24 }}
          >
            ユーザー登録
          </Typography>
        </Grid>
        <ValidatorForm onSubmit={(e) => handleSubmitValidatorForm(e)}>
          <Grid
            className={classes.innerContainer}
            container
            justifyContent="space-evenly"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12} md={8}>
              <Typography variant="h6" align="center">
                ※ 簡単 <span className={classes.appealMinute}>1分</span> で完了!
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextValidator
                variant="standard"
                name="name"
                type="name"
                label="氏名"
                value={name}
                onChange={(e) => setName(e.target.value)}
                validators={['required']}
                errorMessages={['必須項目です']}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <TextValidator
                variant="standard"
                name="email"
                type="email"
                label="メールアドレス"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                validators={['required', 'isEmail']}
                errorMessages={['必須項目です', 'メールアドレスが不正です']}
                fullWidth
              />
              <Typography variant="caption">
                ※ こちらのメールアドレス宛に認証メールを送信します
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextValidator
                variant="standard"
                name="phone"
                type="tel"
                inputMode="tel"
                label="携帯電話番号"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                validators={['required', 'matchRegexp:^[0-9]{9,12}$']}
                errorMessages={[
                  '必須項目です',
                  '9~12桁の半角数字で入力してください',
                ]}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <TextValidator
                variant="standard"
                name="password"
                type="password"
                label="パスワード"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                validators={['required']}
                errorMessages={['必須項目です']}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Box p={1}>
                <Typography variant="body2" align="center" gutterBottom>
                  <CTextLink
                    text="利用規約"
                    linkTo="https://karutekun.com/terms/"
                  />{' '}
                  及び{' '}
                  <CTextLink
                    text="プライバシーポリシー"
                    linkTo="https://karutekun.com/privacy_policy/"
                  />{' '}
                  に
                </Typography>
                <Typography variant="body2" align="center">
                  ご同意いただいた場合に限りご利用ください。
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box textAlign="center">
                <Button variant="contained" color="primary" type="submit">
                  ユーザー登録
                </Button>
              </Box>
              <Box textAlign="center" mt={2}>
                <RouterLink to="/login" className={classes.link}>
                  <Button color="primary">ログインはこちら</Button>
                </RouterLink>
              </Box>
            </Grid>
          </Grid>
        </ValidatorForm>

        <Grid className={classes.footer} container justifyContent="center">
          <Link
            className={classes.footerLink}
            href="https://techners.jp/"
            target="_blank"
            rel="noopener"
          >
            powered by techners
          </Link>
        </Grid>
      </Card>
    </Container>
  );
};
