import { Typography } from '@mui/material';
import _ from 'lodash';
import React, { CSSProperties } from 'react';
import { Layout } from './context';

type Props = {
  layout: Layout;
};

export const ScheduleCreatePreview: FC<Props> = React.memo(
  function CScheduleCreatePreview(props) {
    const { layout } = props;

    const fgColor = 'white';
    const bgColor = 'black';

    const style: CSSProperties = {
      ...layout,
      padding: 2,
      opacity: 0.5,
      cursor: 'pointer',
      position: 'absolute',
      border: `1px solid ${fgColor}`,
      borderRadius: 3,
      backgroundColor: bgColor,
      color: fgColor,
      transition: 'all .3s',
    };

    return (
      <div style={style}>
        <Typography variant="body2">新規作成</Typography>
      </div>
    );
  },
  (prev, next) => _.isEqual(prev, next)
);
