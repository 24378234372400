import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      d="M38 8.25H10c-3.17 0-5.75 2.58-5.75 5.75v20c0 3.17 2.58 5.75 5.75 5.75h28c3.17 0 5.75-2.58 5.75-5.75V14c0-3.17-2.58-5.75-5.75-5.75m-12.25 3.5h5.5v24.5h-5.5zm-3.5 24.5h-5.5v-24.5h5.5zM7.75 34V14c0-1.24 1.01-2.25 2.25-2.25h3.25v24.5H10c-1.24 0-2.25-1.01-2.25-2.25m32.5 0c0 1.24-1.01 2.25-2.25 2.25h-3.25v-24.5H38c1.24 0 2.25 1.01 2.25 2.25z"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgWeekly = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgWeekly);
export default Memo;
