import { Breakpoint, Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

type BreakpointOrNull = Breakpoint | null;

/**
 * Be careful using this hook. It only works because the number of
 * breakpoints in theme is static. It will break once you change the number of
 * breakpoints. See https://reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level
 */
export function useWidth() {
  const theme: Theme = useTheme();
  const keys: Breakpoint[] = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output: BreakpointOrNull, key: Breakpoint) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key), { noSsr: true });
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}

export function useWidthUp(key: Breakpoint) {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up(key), { noSsr: true });
}

export function useWidthDown(key: Breakpoint) {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(key), { noSsr: true });
}
