import { Action, Dispatch } from 'redux';
import { pushSnackbarError } from '../actions/generalAction';
import { sendRequest } from '../actions/request';
import {
  setMySalon,
  setSalonSubscriptionPaymentProvider,
} from '../actions/salonAction';
import { BasicPlan, OptionLinePlan } from '../models/salon';
import {
  SetupSubscriptionResult,
  UpdatePaymentProviderResult,
  createSetupSubscriptionResultFromResource,
  createSubscriptionFromResource,
  createUpdatePaymentProviderResultFromResource,
} from '../models/subscription';

export function fetchSubscriptionInfo() {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    let json;
    try {
      json = await sendRequest(dispatch, `salons/settings/subscription`);
    } catch (e) {
      dispatch(pushSnackbarError('読み込みに失敗しました'));
      return;
    }

    dispatch(
      setMySalon({
        basicPlan: json.basicPlan,
        optionLinePlan: json.optionLinePlan,
        subscription: json.subscription
          ? createSubscriptionFromResource(json.subscription)
          : null,
      })
    );
  };
}

/**
 * サブスク新規登録（既存契約サロンのクレカ切り替えも含む）
 */
export function createSubscription(
  basicPlan: BasicPlan,
  optionLinePlan: OptionLinePlan,
  stripePaymentMethodId: string,
  billingEmail: string
) {
  return async (
    dispatch: Dispatch<Action>
  ): Promise<SetupSubscriptionResult> => {
    const json = await sendRequest(
      dispatch,
      `salons/settings/subscription/stripe`,
      {
        method: 'POST',
        body: JSON.stringify({
          basicPlan,
          optionLinePlan,
          stripePaymentMethodId,
          billingEmail,
        }),
      }
    );

    return createSetupSubscriptionResultFromResource(json);
  };
}

/**
 * サブスクのオプションの追加 or 削除
 */
export function updateSubscriptionOptions(optionLinePlan: OptionLinePlan) {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    const json = await sendRequest(
      dispatch,
      `salons/settings/subscription/options`,
      {
        method: 'POST',
        body: JSON.stringify({ optionLinePlan }),
      }
    );

    dispatch(
      setMySalon({
        basicPlan: json.basicPlan,
        optionLinePlan: json.optionLinePlan,
        subscription: json.subscription
          ? createSubscriptionFromResource(json.subscription)
          : null,
      })
    );
  };
}

export function updatePaymentProvider(updates: {
  stripePaymentMethodId?: string;
  billingEmail?: string;
}) {
  return async (
    dispatch: Dispatch<Action>
  ): Promise<UpdatePaymentProviderResult> => {
    const json = await sendRequest(
      dispatch,
      `salons/settings/subscription/payment_provider/stripe`,
      {
        method: 'POST',
        body: JSON.stringify(updates),
      }
    );

    const result = createUpdatePaymentProviderResultFromResource(json);

    dispatch(setSalonSubscriptionPaymentProvider(result.paymentProvider));

    if (result.isPaymentRequired && result.isPaid) {
      // incomplete状態で支払いが完了した場合はプランが有効になるので、ここで設定してあげる
      dispatch(setMySalon({ basicPlan: result.basicPlan }));
    }

    return result;
  };
}

export function createStripeSetupIntent() {
  return async (dispatch: Dispatch<Action>): Promise<string> => {
    const json = await sendRequest(
      dispatch,
      `salons/settings/subscription/payment_provider/stripe/setup_intent`,
      { method: 'POST' }
    );

    return json.stripeSetupIntentClientSecret;
  };
}

export function cancelSubscription() {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    const json = await sendRequest(
      dispatch,
      `salons/settings/subscription/cancel`,
      { method: 'POST' }
    );

    dispatch(
      setMySalon({
        subscription: createSubscriptionFromResource(json.subscription),
      })
    );
  };
}

export function deletePaymentProvider() {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    await sendRequest(
      dispatch,
      `salons/settings/subscription/payment_provider/stripe`,
      { method: 'DELETE' }
    );

    dispatch(setSalonSubscriptionPaymentProvider(null));
  };
}
