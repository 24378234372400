import { moment } from '@karutekun/shared/util/datetime';
import * as React from 'react';
import Chart from 'react-apexcharts';
import { fmtAvgCustomerNum } from '../../util/chart';

type SeriesItem = {
  name: string;
  data: { x: string; y: number }[];
};
type OwnProps = {
  dayOfWeekVisitTimeRatioMap: DayOfWeekMap<{ [hour: number]: number }>;
};

class CChartHeatmapDayOfWeekVisitTime extends React.PureComponent<OwnProps> {
  render() {
    const data = this.props.dayOfWeekVisitTimeRatioMap;

    const seriesList: SeriesItem[] = [];
    for (let hour = 22; hour >= 8; hour -= 1) {
      const series: SeriesItem = {
        name: `${hour < 10 ? '0' : ''}${hour}:00`,
        data: [],
      };
      for (let dayOfWeek = 1; dayOfWeek <= 7; dayOfWeek += 1) {
        series.data.push({
          x: moment().isoWeekday(dayOfWeek).format('dd'),
          // TODO 一時的にルールを無効化しています。気づいたベースで直してください
          // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'number' can't be used to index type 'DayOfWeekMap<{ [hour: number]: number; }>'.
          y: data[dayOfWeek][hour] || 0,
        });
      }
      seriesList.push(series);
    }
    return (
      <Chart
        options={{
          chart: {
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          tooltip: {
            y: {
              formatter: fmtAvgCustomerNum,
            },
          },
          colors: ['#008FFB'],
        }}
        series={seriesList}
        type="heatmap"
        height="100%"
      />
    );
  }
}

export default CChartHeatmapDayOfWeekVisitTime;
