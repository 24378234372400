import { DiscountType } from '@karutekun/core/salon-service';
import { formatMoney } from '@karutekun/shared/util/format';
import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CPriceLine from '../../../../../components_old/atoms/CPriceLine';
import CStylistAvatar from '../../../../../components_old/atoms/CStylistAvatar';
import { useSelectStylistMap } from '../../../../../selectors/salonSelector';
import { useSelectSalonServices } from '../../../../../selectors/serviceSelector';
import { VoucherLineForPreview } from '../types';

type Props = {
  voucherSalonId?: number;
  line: VoucherLineForPreview;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'block',
    width: '100%',
    padding: theme.spacing(1),
    borderRadius: 4,
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.grey[50],
    textAlign: 'left',
  },
  stylistAvatar: {
    marginRight: 3,
  },
  discount: {
    color: theme.palette.accent.main,
  },
  stylistsContainer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  note: {
    marginTop: theme.spacing(1),
    color: theme.palette.accent.main,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all',
  },
}));

const VoucherLineItem: FC<Props> = ({ line, voucherSalonId }) => {
  const classes = useStyles();
  const { serviceMap, discountMap } = useSelectSalonServices(voucherSalonId);
  const stylistMap = useSelectStylistMap(voucherSalonId);
  const service = serviceMap[line.serviceId];

  const taxText =
    line.taxRate === 0
      ? '非課税'
      : line.isTaxIncluded
      ? `(内税 ${line.taxRate}%)`
      : `+ 外税 ${line.taxRate}%`;

  return (
    <div className={classes.container}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography variant="body1">{service.name}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            {formatMoney(line.adjustedUnitPrice)}
            {line.quantity !== 1 && ` × ${line.quantity}`}
          </Typography>
        </Grid>
      </Grid>

      {line.discounts.map((vld, i) => (
        <div key={i}>
          <CPriceLine>
            <Typography variant="body1">
              {discountMap[vld.discountId]?.name}
            </Typography>
            <Typography variant="body1" className={classes.discount}>
              {vld.type === DiscountType.Percentage
                ? `-${vld.value}%`
                : `${formatMoney(-vld.value)}`}
            </Typography>
          </CPriceLine>
        </div>
      ))}

      <div className={classes.stylistsContainer}>
        {line.stylists.map((vls) => (
          <div key={vls.stylistId} className={classes.stylistAvatar}>
            <CStylistAvatar
              stylist={stylistMap[vls.stylistId]}
              size={18}
              showShimeiIcon={vls.isShimei}
            />
          </div>
        ))}
        <Typography variant="body2" color="textSecondary">
          {taxText}
        </Typography>
      </div>

      {line.note !== '' && (
        <Typography className={classes.note} variant="body2">
          {line.note}
        </Typography>
      )}
    </div>
  );
};

export default VoucherLineItem;
