import { type Moment } from '@karutekun/shared/util/datetime';
import { useBoolean } from '@karutekun/shared/util/react-hooks';
import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useCallback } from 'react';

type OwnProps = {
  label?: string;
  date?: string;
  disabled?: boolean;
  clearable?: boolean;
  fullWidth?: boolean;
  format?: string;
  textFieldProps?: TextFieldProps;

  onChange(date: string | undefined): void;
};

const CDatePicker: FC<OwnProps> = (props) => {
  const {
    label,
    date,
    disabled,
    fullWidth,
    clearable,
    format = 'YYYY/MM/DD',
    textFieldProps,
    onChange,
  } = props;

  const [open, openMut] = useBoolean(false);

  const handleChange = useCallback(
    (date: Moment | null) => {
      if (date) {
        onChange(date.format('YYYY-MM-DD'));
      } else {
        onChange(undefined);
      }
    },
    [onChange]
  );
  const handleClear = useCallback(() => onChange(undefined), [onChange]);

  return (
    <DatePicker
      toolbarFormat="YYYY年 M月"
      label={label}
      open={open}
      value={date === undefined ? null : date}
      onChange={handleChange}
      disabled={disabled}
      inputFormat={format}
      disableOpenPicker
      disableMaskedInput
      onClose={openMut.setFalse}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ minWidth: 150 }}
          variant="standard"
          fullWidth={fullWidth}
          inputProps={{
            ...params.inputProps,
            sx: { cursor: 'pointer' },
            placeholder: '未指定',
            onClick: openMut.setTrue,
            readOnly: true,
            ...textFieldProps?.inputProps,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment:
              date !== undefined && !disabled && clearable ? (
                <InputAdornment position="start">
                  <IconButton size="small" onClick={handleClear}>
                    <SVGIcon name="times" />
                  </IconButton>
                </InputAdornment>
              ) : undefined,
            ...textFieldProps?.InputProps,
          }}
          {...textFieldProps}
        />
      )}
    />
  );
};

export default CDatePicker;
