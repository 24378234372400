import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { selectUserLoginInfo } from '../../selectors/userSelector';

const EmailVerificationRequried: FC = ({ children }) => {
  const location = useLocation();
  const loginInfo = useSelector(selectUserLoginInfo);

  if (!loginInfo?.isEmailVerified) {
    return (
      <Navigate to={`/signup/email_verification?${location.search}`} replace />
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default EmailVerificationRequried;
