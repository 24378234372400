import { CustomerSex } from '@karutekun/core/customer';
import { dayOfWeek } from '@karutekun/shared/util/datetime';
import { IStylistDailyStats, IStylistStats } from './data';

export function getVisitNum(
  stats: Pick<IStylistStats, 'firstVisitNum' | 'repeatVisitNum'>
) {
  return stats.firstVisitNum + stats.repeatVisitNum;
}

export function getRepeatRatio(
  stats: Pick<IStylistStats, 'firstVisitNum' | 'repeatVisitNum'>
) {
  const total = getVisitNum(stats);
  return total === 0 ? 0 : Math.ceil((stats.repeatVisitNum / total) * 100);
}

export function buildStylistStats(
  salonId: number,
  stylistId: number
): IStylistStats {
  return {
    salonId,
    stylistId,
    sales: 0,
    salesWithoutTax: 0,
    mainSales: 0,
    mainSalesWithoutTax: 0,
    mainShimeiSales: 0,
    mainShimeiSalesWithoutTax: 0,
    mainNotShimeiSales: 0,
    mainNotShimeiSalesWithoutTax: 0,
    menuShimeiSales: 0,
    menuShimeiSalesWithoutTax: 0,
    menuNotShimeiSales: 0,
    menuNotShimeiSalesWithoutTax: 0,
    productShimeiSales: 0,
    productShimeiSalesWithoutTax: 0,
    productNotShimeiSales: 0,
    productNotShimeiSalesWithoutTax: 0,
    firstVisitNum: 0,
    repeatVisitNum: 0,
    mainPaidVoucherNum: 0,
    averageSalesPerCustomer: 0,
    averageSalesPerCustomerWithoutTax: 0,
    isShimeiMap: { 0: 0, 1: 0 },
    customerSexAgeGroupMap: {
      [CustomerSex.Male]: { unknown: 0 },
      [CustomerSex.Female]: { unknown: 0 },
    },
    serviceNumMap: {},
    serviceSalesMap: {},
    serviceSalesWithoutTaxMap: {},
    serviceOriginalUnitPriceSumMap: {},
    serviceOriginalUnitPriceSumWithoutTaxMap: {},
  };
}

export function buildStylistDailyStats(
  salonId: number,
  stylistId: number,
  date: string
): IStylistDailyStats {
  return {
    date,
    dayOfWeek: dayOfWeek(date),
    ...buildStylistStats(salonId, stylistId),
  };
}

export function aggregateStylistStats(
  salonId: number,
  stylistId: number,
  stats: IStylistStats[]
): IStylistStats {
  const result = buildStylistStats(salonId, stylistId);

  if (stats.length === 0) {
    return result;
  }

  for (const s of stats) {
    result.sales += s.sales;
    result.salesWithoutTax += s.salesWithoutTax;
    result.mainSales += s.mainSales;
    result.mainSalesWithoutTax += s.mainSalesWithoutTax;
    result.mainShimeiSales += s.mainShimeiSales;
    result.mainShimeiSalesWithoutTax += s.mainShimeiSalesWithoutTax;
    result.mainNotShimeiSales += s.mainNotShimeiSales;
    result.mainNotShimeiSalesWithoutTax += s.mainNotShimeiSalesWithoutTax;
    result.menuShimeiSales += s.menuShimeiSales;
    result.menuShimeiSalesWithoutTax += s.menuShimeiSalesWithoutTax;
    result.menuNotShimeiSales += s.menuNotShimeiSales;
    result.menuNotShimeiSalesWithoutTax += s.menuNotShimeiSalesWithoutTax;
    result.productShimeiSales += s.productShimeiSales;
    result.productShimeiSalesWithoutTax += s.productShimeiSalesWithoutTax;
    result.productNotShimeiSales += s.productNotShimeiSales;
    result.productNotShimeiSalesWithoutTax += s.productNotShimeiSalesWithoutTax;
    result.firstVisitNum += s.firstVisitNum;
    result.repeatVisitNum += s.repeatVisitNum;
    result.mainPaidVoucherNum += s.mainPaidVoucherNum;
    result.isShimeiMap[0] += s.isShimeiMap[0];
    result.isShimeiMap[1] += s.isShimeiMap[1];
    for (const key in s.customerSexAgeGroupMap[CustomerSex.Male]) {
      result.customerSexAgeGroupMap[CustomerSex.Male][key] =
        (result.customerSexAgeGroupMap[CustomerSex.Male][key] ?? 0) +
        s.customerSexAgeGroupMap[CustomerSex.Male][key];
    }
    for (const key in s.customerSexAgeGroupMap[CustomerSex.Female]) {
      result.customerSexAgeGroupMap[CustomerSex.Female][key] =
        (result.customerSexAgeGroupMap[CustomerSex.Female][key] ?? 0) +
        s.customerSexAgeGroupMap[CustomerSex.Female][key];
    }
    for (const key in s.serviceNumMap) {
      result.serviceNumMap[key] =
        (result.serviceNumMap[key] ?? 0) + s.serviceNumMap[key];
    }
    for (const key in s.serviceSalesMap) {
      result.serviceSalesMap[key] =
        (result.serviceSalesMap[key] ?? 0) + s.serviceSalesMap[key];
    }
    for (const key in s.serviceSalesWithoutTaxMap) {
      result.serviceSalesWithoutTaxMap[key] =
        (result.serviceSalesWithoutTaxMap[key] ?? 0) +
        s.serviceSalesWithoutTaxMap[key];
    }
    for (const key in s.serviceOriginalUnitPriceSumMap) {
      result.serviceOriginalUnitPriceSumMap[key] =
        (result.serviceOriginalUnitPriceSumMap[key] ?? 0) +
        s.serviceOriginalUnitPriceSumMap[key];
    }
    for (const key in s.serviceOriginalUnitPriceSumWithoutTaxMap) {
      result.serviceOriginalUnitPriceSumWithoutTaxMap[key] =
        (result.serviceOriginalUnitPriceSumWithoutTaxMap[key] ?? 0) +
        s.serviceOriginalUnitPriceSumWithoutTaxMap[key];
    }
  }

  result.averageSalesPerCustomer =
    result.mainPaidVoucherNum === 0
      ? 0
      : result.sales / result.mainPaidVoucherNum;
  result.averageSalesPerCustomerWithoutTax =
    result.mainPaidVoucherNum === 0
      ? 0
      : result.salesWithoutTax / result.mainPaidVoucherNum;

  return result;
}
