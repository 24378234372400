import './libs/firebase';
import './logger';

import React from 'react';
import * as ReactDOM from 'react-dom';
import { persistStore } from 'redux-persist';
import App from './App';
import { unregister } from './registerServiceWorker';
import { store } from './store';

// AbortController の polyfill
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';

persistStore(store, null, async () => {
  ReactDOM.render(
    <React.StrictMode>
      <App store={store} />
    </React.StrictMode>,
    document.getElementById('root') as HTMLElement
  );
});

// TODO 過去のサービスワーカーを削除。少ししたら消して良い
unregister();
