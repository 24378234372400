import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M37.75 5.75H39A6.25 6.25 0 0 1 45.25 12v27A6.25 6.25 0 0 1 39 45.25H9A6.25 6.25 0 0 1 2.75 39V12A6.25 6.25 0 0 1 9 5.75h1.25V4.5a1.75 1.75 0 0 1 3.5 0v1.25h20.5V4.5a1.75 1.75 0 0 1 3.5 0zm3.194 35.195A2.75 2.75 0 0 0 41.75 39V16.75H6.25V39A2.75 2.75 0 0 0 9 41.75h30c.73 0 1.429-.29 1.944-.806M6.25 13.25h35.5V12A2.75 2.75 0 0 0 39 9.25H9A2.75 2.75 0 0 0 6.25 12zm16 17.18h-5.41a1.75 1.75 0 1 1 0-3.5h5.41v-5.41a1.75 1.75 0 0 1 3.5 0v5.41h5.41a1.75 1.75 0 1 1 0 3.5h-5.41v5.41a1.75 1.75 0 1 1-3.5 0z"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgCalendarPlus = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgCalendarPlus);
export default Memo;
