import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

export type OwnProps = {
  open: boolean;

  title?: string;
  description: string;

  onCanceled(): void;
  onOk(): void;
};

const ConfirmDialog: FC<OwnProps> = (props) => {
  return (
    <Dialog open={props.open} onClose={props.onCanceled}>
      <DialogTitle id="alert-dialog-title">{props.title || '確認'}</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ whiteSpace: 'pre-line' }}>
          {props.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCanceled} color="primary">
          キャンセル
        </Button>
        <Button onClick={props.onOk} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
