import { Button, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

type OwnProps = {
  onClickDelete?(): void;
};

const useStyles = makeStyles((theme: Theme) => ({
  priceContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  priceContainerLeft: {
    flex: 1,
    textAlign: 'right',
  },
  priceContainerRight: {
    width: 90,
    textAlign: 'right',
  },
}));

const CPriceLine: FC<OwnProps> = (props) => {
  const classes = useStyles();
  const { onClickDelete, children } = props;
  return (
    <div className={classes.priceContainer}>
      {onClickDelete && (
        <Button size="small" onClick={onClickDelete}>
          削除
        </Button>
      )}
      {/* TODO 一時的にルールを無効化しています。気づいたベースで直してください */}
      {/* @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type '0' can't be used to index type 'string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal'. */}
      <div className={classes.priceContainerLeft}>{children?.[0]}</div>
      {/* TODO 一時的にルールを無効化しています。気づいたベースで直してください */}
      {/* @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type '1' can't be used to index type 'string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal'. */}
      <div className={classes.priceContainerRight}>{children?.[1]}</div>
    </div>
  );
};

export default CPriceLine;
