/**
 * 合計値が afterTotal に等しくなるように割合を調整した各数値の配列を返す
 * 0除算にならないよう、 numbers の合計値が 0 の場合は均等に按分される
 */
export function distributeTotal(
  afterTotal: number,
  numbers: number[]
): number[] {
  if (numbers.length === 0) {
    return [];
  }

  const currentTotal = numbers.reduce((p, c) => p + c, 0);
  const ratio =
    currentTotal === 0 ? 1 / numbers.length : afterTotal / currentTotal;

  // 割合をかけて大体の数値を出しておく
  const tmpNumbers = numbers.map((n) => Math.round(n * ratio));
  const tmpTotal = tmpNumbers.reduce((p, c) => p + c, 0);

  // 合計値が afterTotal に等しくなるように、1ずつ調整を入れる
  let diff = afterTotal - tmpTotal;
  const adjust = diff < 0 ? -1 : 1;
  let adjustIndex = 0;
  while (diff !== 0) {
    tmpNumbers[adjustIndex] += adjust;
    diff -= adjust;
    adjustIndex = (adjustIndex + 1) % tmpNumbers.length;
  }

  return tmpNumbers;
}
