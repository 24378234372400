import Typography, { TypographyProps } from '@mui/material/Typography';
import { formatMoney } from '../../util/common';

type OwnProps = TypographyProps & {
  amount: number;
};

const CMoneyTypography: FC<OwnProps> = (props) => {
  const { amount, ...otherProps } = props;
  const isMinus = amount < 0;
  return (
    <Typography {...(isMinus ? { color: 'error' } : {})} {...otherProps}>
      {formatMoney(amount)}
    </Typography>
  );
};

export default CMoneyTypography;
