import { SalonCustomerLineState } from '@karutekun/core/customer';
import { Alert, Box } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  useFetchMessages,
  useFetchNextMessages,
  useListenLatestChatMessagesForCustomer,
  useMarkReadForChatRoom,
  useSendMessage,
} from '../../hooks/chat';
import { Customer, emptyCustomerSalonLineSetting } from '../../models/customer';
import { OptionLinePlanName } from '../../models/salon';
import {
  selectChatSelectedSalonId,
  selectCustomerChatRoom,
} from '../../selectors/chatSelector';
import { selectMySalon, selectSalonById } from '../../selectors/salonSelector';
import { GlobalState } from '../../store';
import { useSalonStatus } from '../../templates/providers/salonStatus/salonStatusContext';
import CGuideLineFeature from '../organisms/CGuideLineFeature';
import ChatForm from './ChatForm';
import ChatMessageArea from './ChatMessageArea';
import SelectableSalons from './SelectableSalons';

type Props = {
  customer: Customer;
  isVisible: boolean;
};

const ChatRoom: FC<Props> = (props) => {
  const { customer, isVisible } = props;

  const mySalon = useSelector(selectMySalon);
  const selectedSalonId = useSelector(selectChatSelectedSalonId);
  const selectedSalon = useSelector(
    (state: GlobalState) => selectSalonById(state, selectedSalonId) ?? mySalon
  );
  const { salonStatus } = useSalonStatus();

  const isOnMySalon = mySalon.id === selectedSalonId;
  const customerLineSetting = emptyCustomerSalonLineSetting(
    customer.salonLineSettingMap[selectedSalonId]
  );
  const lineSetting = selectedSalon.lineSetting;

  const chatRoom = useSelector((state: GlobalState) =>
    selectCustomerChatRoom(state, customer.id)
  );
  const chatMessages = useFetchMessages(selectedSalonId, customer.id);
  const onSend = useSendMessage(selectedSalonId, customer.id);
  const markRead = useMarkReadForChatRoom(selectedSalonId, customer.id);

  useListenLatestChatMessagesForCustomer(selectedSalonId, customer.id);
  useEffect(() => {
    if (isVisible && chatRoom?.unreadNum && chatRoom.unreadNum > 0) {
      markRead();
    }
  }, [chatRoom?.unreadNum, isVisible, markRead]);

  const cursor =
    chatMessages.length > 0
      ? chatMessages[chatMessages.length - 1].documentId
      : undefined;
  const onEndReached = useFetchNextMessages(
    selectedSalonId,
    customer.id,
    cursor
  );

  return (
    <>
      <SelectableSalons />

      <Box mt={2}>
        {!lineSetting ? (
          <CGuideLineFeature />
        ) : customerLineSetting.lineState === SalonCustomerLineState.NotYet ? (
          <Alert severity="warning">
            まだお客様のLINEと連携できていません。
          </Alert>
        ) : (
          <>
            <ChatMessageArea
              chatMessages={chatMessages}
              onResendMessage={onSend}
              onEndReached={onEndReached}
            />
            <Box mt={1}>
              {customerLineSetting.lineState ===
              SalonCustomerLineState.Disconnected ? (
                <Alert severity="warning">LINE連携が解除されています</Alert>
              ) : customerLineSetting.lineState ===
                SalonCustomerLineState.Blocked ? (
                <Alert severity="warning">
                  お客様からブロックされているため、メッセージが送信できません
                </Alert>
              ) : !isOnMySalon ? (
                <Alert severity="warning">
                  他サロンのチャットのため、メッセージが送信できません
                </Alert>
              ) : !salonStatus.isAvailable('line') ? (
                <Alert severity="warning">
                  メッセージを送信するためには{OptionLinePlanName}
                  の契約が必要です
                </Alert>
              ) : (
                <>
                  <ChatForm onSend={onSend} />
                  {!lineSetting.acceptMessage && (
                    <Box mt={1}>
                      <Alert severity="info">
                        お客様からのメッセージを受信しない設定になっています
                      </Alert>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default ChatRoom;
