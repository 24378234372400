import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      d="M9.75 21.002c1.59 0 3.04-.55 4.2-1.46l3.89 1.58.64-1.18.03-.06s.07-.11.11-.19c.11-.17.2-.29.25-.35l1.34-1.35-3.86-2.34c.09-.45.14-.92.14-1.39 0-3.73-3.02-6.75-6.75-6.75s-6.75 3.02-6.75 6.75 3.02 6.75 6.75 6.75zm0-10.5c2.07 0 3.75 1.68 3.75 3.75 0 2.07-1.68 3.75-3.75 3.75-2.07 0-3.75-1.68-3.75-3.75 0-2.07 1.68-3.75 3.75-3.75M32.28 24.36l-7.86 4.51 2.93 1.39c1.76.85 6.42 3.1 11.18 3.38.33.02.69.04 1.05.04 1.24 0 2.57-.17 3.56-.87l1.95-1.38-12.82-7.07zm-1.36 4.24 1.39-.8 4.87 2.68c-2.32-.36-4.56-1.17-6.25-1.89z"
    />
    <path
      fill={props.color || 'currentColor' || '#000'}
      d="m44.98 15.861-.45-1.2c-.82-2.21-5.25-1.87-7.73-1.31-1.88.42-11.45 2.64-15.46 5.5-1.9 1.35-2.25 3.16-2.54 4.61-.26 1.33-.47 2.37-1.69 3.28-1.42 1.05-2.57 1.54-3.15 1.74a6.714 6.714 0 0 0-4.21-1.48c-3.73 0-6.75 3.02-6.75 6.75s3.02 6.75 6.75 6.75 6.75-3.02 6.75-6.75c0-.59-.08-1.16-.23-1.7zm-23.24 8.17c.27-1.37.45-2.12 1.33-2.74 2.75-1.95 9.46-3.92 14.38-5.01.36-.08.71-.14 1.03-.19l-17.16 9.67c.19-.6.31-1.19.42-1.73M9.75 37.501c-2.07 0-3.75-1.68-3.75-3.75 0-2.07 1.68-3.75 3.75-3.75 2.07 0 3.75 1.68 3.75 3.75 0 2.07-1.68 3.75-3.75 3.75"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgCut = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgCut);
export default Memo;
