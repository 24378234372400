const Routing = {
  customers() {
    return `/customers`;
  },
  customer(id: number) {
    return `/customers/${id}`;
  },
  karutes() {
    return `/karutes`;
  },
  karute(id: number) {
    return `/karutes/${id}`;
  },
};
export default Routing;
