import chroma from 'chroma-js';
import * as React from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  legendProps,
  tooltipProps,
  xAxisProps,
  yAxisProps,
} from '../../util/chart';
import CChartTooltip from './CChartTooltip';

type OwnProps<T> = {
  data: T[];
  nameKey: string | ((data: T) => string);
  valueKeys: {
    dataKey: string | ((data: T) => number);
    name: string;
    color?: string;
  }[];
  stack?: boolean;

  showLegend?: boolean;

  formatter?(value: number): string;

  onClick?(data: T): void;
};

class CChartArea<T extends {}> extends React.PureComponent<OwnProps<T>> {
  static defaultProps = {
    showLegend: true,
  };

  render() {
    const { data, nameKey, valueKeys, stack, showLegend, formatter } =
      this.props;

    const tooltipFormatter = formatter
      ? (value: string | number | (string | number)[]) =>
          formatter(Number(value))
      : undefined;

    return (
      <ResponsiveContainer>
        <AreaChart
          data={data}
          onClick={(data) => {
            if (
              data &&
              data.activePayload &&
              data.activePayload[0] &&
              data.activePayload[0].payload
            ) {
              if (this.props.onClick) {
                this.props.onClick(data.activePayload[0].payload);
              }
            }
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          {showLegend && <Legend {...legendProps} />}
          <Tooltip
            {...tooltipProps}
            formatter={tooltipFormatter}
            content={<CChartTooltip showTotal={stack} />}
          />
          {/* 一時的に lint を無効化しています。気づいたベースで直してください */}
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          <XAxis {...xAxisProps} dataKey={nameKey as any} />
          <YAxis {...yAxisProps} tickFormatter={this.props.formatter} />
          {valueKeys.map((valueKey, i) => (
            <Area
              key={i}
              {...(stack ? { stackId: 'a' } : {})}
              name={valueKey.name}
              dataKey={valueKey.dataKey}
              cursor={this.props.onClick ? 'cursor' : 'default'}
              stroke={valueKey.color || '#20a8d8'}
              fill={chroma(valueKey.color || '#20a8d8')
                .brighten(1)
                .hex()}
              isAnimationActive={false}
            />
          ))}
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}

export default CChartArea;
