import { useSelector } from 'react-redux';
import CDateTimeRange from '../../../../../components_old/molecules/CDateTimeRange';
import { selectMinuteInterval } from '../../../../../selectors/scheduleSettingsSelector';

type Props = {
  startedAt: number;
  finishedAt: number;
  disabled?: boolean;
  onChange(data: { startedAt: number; finishedAt: number }): void;
};

export const VisitFormDatetime: FC<Props> = ({
  startedAt,
  finishedAt,
  disabled,
  onChange,
}) => {
  const minuteInterval = useSelector(selectMinuteInterval);
  return (
    <CDateTimeRange
      label="日時"
      from={startedAt}
      to={finishedAt}
      minuteInterval={minuteInterval}
      onChange={(startedAt, finishedAt) => {
        onChange({ startedAt, finishedAt });
      }}
      disabled={disabled}
    />
  );
};
