import { Typography } from '@mui/material';
import { ChatMessageBodyText as ChatMessageBodyTextEntity } from '../../models/chatMessageBodyText/entity';
import CLinkify from '../atoms/CLinkify';

type Props = {
  chatMessageBody: ChatMessageBodyTextEntity;
};

const ChatMessageBodyText: FC<Props> = (props) => {
  return (
    <Typography variant="body1">
      <CLinkify>{props.chatMessageBody.text}</CLinkify>
    </Typography>
  );
};

export default ChatMessageBodyText;
