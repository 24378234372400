import { VisitStatus } from '@karutekun/core/visit';
import { useFetchVisitsByCustomerIdPaginated } from '@karutekun/shared/data-access/visit';
import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import { Card, CardContent, Grid, IconButton, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CEmpty from '../../../atoms/CEmpty';
import CProgressOverlay from '../../../atoms/CProgressOverlay';
import { CVisitCard } from './CVisitCard';
import { CVisitListPaginationControl } from './CVisitListPaginationControl';

type Props = {
  customerId: number;
  page: number;
  onPageChange(page: number): void;

  highlightedVisitId?: number;
};

const useStyles = makeStyles(() => ({
  sortPaginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

const numVisitsPerPage = 12;

export const CCustomerVisitHistoryList: FC<Props> = ({
  customerId,
  page,
  onPageChange,
  highlightedVisitId,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const {
    isFetching,
    data: { count, visits },
    refetch,
  } = useFetchVisitsByCustomerIdPaginated(
    customerId,
    VisitStatus.Visited,
    numVisitsPerPage,
    page
  );

  return (
    <div>
      {isFetching && <CProgressOverlay />}

      <div className={classes.sortPaginationContainer}>
        <CVisitListPaginationControl
          page={page}
          count={count}
          numPerPage={numVisitsPerPage}
          onChange={onPageChange}
        />
        <IconButton onClick={() => refetch()} size="large">
          <SVGIcon
            name="refresh"
            color={theme.palette.text.primary}
            size="sm"
          />
        </IconButton>
      </div>

      <Grid container alignItems="flex-start" spacing={2}>
        {!isFetching && visits.length === 0 ? (
          <Grid item>
            <Card>
              <CardContent>
                <CEmpty title="来店記録が存在しません" />
              </CardContent>
            </Card>
          </Grid>
        ) : (
          visits.map((visit) => (
            <Grid key={visit.id} item xs={12} sm={6} md={6} lg={4} xl={3}>
              <CVisitCard
                visit={visit}
                isHighlighted={highlightedVisitId === visit.id}
                linkTo={`/visit_histories/${visit.id}`}
              />
            </Grid>
          ))
        )}
      </Grid>

      <CVisitListPaginationControl
        page={page}
        count={count}
        numPerPage={numVisitsPerPage}
        onChange={onPageChange}
      />
    </div>
  );
};
