export enum SalonCustomerLineState {
  NotYet = 0,
  Friend = 1,
  Blocked = 2,
  Disconnected = 3,
}

export interface ISalonCustomerLineSetting {
  customerId: number;
  salonId: number;
  lineUserId: string | null;
  technersLineUserId: string | null;
  lineState: SalonCustomerLineState;
  createdAt: number;
  updatedAt: number;
}

export interface ICustomerImage {
  customerId: number;
  index: number;
  uuid: string;
  url: string;
}
