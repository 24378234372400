import * as _ from 'lodash';
import { createSelector } from 'reselect';
import { ReservationMenu } from '../models/reservationMenu';
import { ReservationStylist } from '../models/reservationStylist';
import {
  ReservationReceptionSetting,
  ReservationSalonInfoSetting,
  ReservationSettings,
  emptyReservationSettings,
} from '../models/salonReservationSettings';
import { Service } from '../models/service';
import { PlainStylist } from '../models/stylist';
import { selectStylistMap } from '../selectors/salonSelector';
import { GlobalState } from '../store';
import { notEmpty } from '../util/common';
import { selectServiceMap } from './serviceSelector';

export const selectReservationSettings = createSelector(
  (state: GlobalState) => state.salon.mySalon.reservationSettings,
  (settings: ReservationSettings | null): ReservationSettings => {
    return settings || emptyReservationSettings();
  }
);

export const selectReservationSalonInfoSetting = createSelector(
  selectReservationSettings,
  (settings: ReservationSettings): ReservationSalonInfoSetting => {
    const basicSettings = settings.basicSetting;
    return _.pick(basicSettings, [
      'address',
      'phone',
      'homeMessage',
      'confirmationMessage',
      'remindMessage',
    ]);
  }
);

export const selectReservationReceptionSetting = createSelector(
  selectReservationSettings,
  (settings: ReservationSettings): ReservationReceptionSetting => {
    const basicSettings = settings.basicSetting;
    return _.pick(basicSettings, [
      'acceptReservations',
      'acceptFreeReservations',
      'acceptShimeiReservations',
      'isManToMan',
      'avoidOverbooking',
      'minuteInterval',
      'reservableWeeksInAdvance',
      'reservationTimeLimitInTheDay',
      'reservationDeadlineDays',
      'reservationDeadlineTime',
      'sendRemindMessage',
      'remindTimeInTheDay',
      'remindDays',
      'remindTime',
    ]);
  }
);

/**
 * 予約スタッフ
 */
export const selectActiveReservationStylists = createSelector(
  selectReservationSettings,
  (state: GlobalState) => selectStylistMap(state),
  (
    reservationSettings: ReservationSettings,
    stylistMap: IdMap<PlainStylist>
  ): ReservationStylist[] => {
    const plainReservationStylists = Object.values(
      reservationSettings.reservationStylistMap
    )
      .filter(notEmpty)
      .filter((s) => s.isActive)
      .sort((a, b) => b.order - a.order);

    const reservationStylists: ReservationStylist[] = [];
    for (const rs of plainReservationStylists) {
      const stylist = stylistMap[rs.stylistId];
      if (!stylist) {
        continue;
      }

      const availableReservationMenuIds = rs.availableReservationMenuIds ?? [];
      reservationStylists.push({
        stylist,
        availableReservationMenuIds,
        id: rs.id,
        stylistId: rs.stylistId,
        name: rs.name,
        imageUrl: rs.imageUrl,
        description: rs.description,
        order: rs.order,
        isVisible: rs.isVisible,
        isActive: rs.isActive,
        availableReservationMenus: availableReservationMenuIds
          .map(
            (reservationMenuId) =>
              reservationSettings.reservationMenuMap[reservationMenuId]
          )
          .filter(notEmpty),
      });
    }

    return reservationStylists;
  }
);

/**
 * 予約メニュー
 */
export const selectActiveReservationMenus = createSelector(
  selectReservationSettings,
  (state: GlobalState) => selectServiceMap(state),
  (
    reservationSettings: ReservationSettings,
    serviceMap: IdMap<Service>
  ): ReservationMenu[] => {
    const plainReservationMenus = Object.values(
      reservationSettings.reservationMenuMap
    )
      .filter(notEmpty)
      .filter((s) => s.isActive)
      .sort((a, b) => b.order - a.order);

    const reservationMenus: ReservationMenu[] = [];
    for (const rm of plainReservationMenus) {
      const service = serviceMap[rm.serviceId];
      if (!service) {
        continue;
      }

      const availableReservationStylistIds =
        rm.availableReservationStylistIds ?? [];
      reservationMenus.push({
        service,
        availableReservationStylistIds,
        id: rm.id,
        serviceId: rm.serviceId,
        name: rm.name,
        description: rm.description,
        showPriceFromSign: rm.showPriceFromSign,
        order: rm.order,
        isVisible: rm.isVisible,
        isActive: rm.isActive,
        availableReservationStylists: availableReservationStylistIds
          .map(
            (reservationStylistId) =>
              reservationSettings.reservationStylistMap[reservationStylistId]
          )
          .filter(notEmpty),
      });
    }

    return reservationMenus;
  }
);
