import { DiscountType } from '@karutekun/core/salon-service';
import { TaxRoundingType } from '@karutekun/core/tax';
import { sortByOrder } from '@karutekun/shared/util/entity';
import { sumBy } from '@karutekun/shared/util/objects';
import { calculateSalesAfterOverallDiscounts } from '../internal/calculate-sales-after-overall-discount';
import { calculateVoucherLineApproxSellingUnitPrice } from '../internal/calculate-voucher-line-approx-selling-unit-price';
import { calculateVoucherLineSales } from '../internal/calculate-voucher-line-sales';
import { calculateVoucherLineStylistSales } from '../internal/calculate-voucher-line-stylist-sales';

// Input のデータはすべて Readonly にしておき、間違えて変更されないようにする
// 計算に必要最低限なデータのみ必須とし、その他の値はジェネリクスでカバー
type InputVoucherBase<V, L, LS, LD, D> = Readonly<
  V & {
    taxRoundingType: TaxRoundingType;
    lines: Readonly<
      L & {
        adjustedUnitPrice: number;
        quantity: number;
        isTaxIncluded: boolean;
        taxRate: number;
        stylists: Readonly<LS>[];
        discounts: Readonly<
          LD & { type: DiscountType; value: number; order: number }
        >[];
      }
    >[];
    discounts: Readonly<
      D & { type: DiscountType; value: number; order: number }
    >[];
  }
>;

// Input のデータを元にしつつ計算結果を埋め込めるようにしたデータ構造
// ジェネリクスで必須項目以外はそのまま返すようにしつつ、プラスで計算結果を保持するプロパティを追加している
type ResultData<
  V,
  L,
  LS,
  LD,
  D,
  IN extends InputVoucherBase<V, L, LS, LD, D>
> = Omit<IN, 'lines' | 'discounts'> & {
  sales: number;
  taxAmount: number;
  lines: (Omit<IN['lines'][number], 'stylists' | 'discounts'> & {
    sales: number;
    taxAmount: number;
    approxSellingUnitPrice: number;
    stylists: (IN['lines'][number]['stylists'][number] & {
      sales: number;
      taxAmount: number;
    })[];
    discounts: (IN['lines'][number]['discounts'][number] & {
      approxDiscountAmount: number;
      approxDiscountTaxAmount: number;
    })[];
  })[];
  discounts: (IN['discounts'][number] & {
    approxDiscountAmount: number;
    approxDiscountTaxAmount: number;
  })[];
};

/**
 * 伝票で売上の計算に必要なデータを受け取り、それを元に売上金額を計算してセットしたデータを返す
 */
export function calculateAndFillVoucherSales<
  V,
  L,
  LS,
  LD,
  D,
  IN extends InputVoucherBase<V, L, LS, LD, D>
>(data: IN) {
  // 割引の順番によって計算金額が変わってくるので、一番最初にソートしておく
  const result: ResultData<V, L, LS, LD, D, IN> = {
    ...data,
    sales: 0,
    taxAmount: 0,
    lines: data.lines.map((l) => ({
      ...l,
      sales: 0,
      taxAmount: 0,
      approxSellingUnitPrice: 0,
      stylists: l.stylists.map((ls) => ({
        ...ls,
        sales: 0,
        taxAmount: 0,
      })),
      discounts: sortByOrder(l.discounts).map((ld) => ({
        ...ld,
        approxDiscountAmount: 0,
        approxDiscountTaxAmount: 0,
      })),
    })),
    discounts: sortByOrder(data.discounts).map((vd) => ({
      ...vd,
      approxDiscountAmount: 0,
      approxDiscountTaxAmount: 0,
    })),
  };
  const taxRoundingType = data.taxRoundingType;

  // 各 line の sales, taxAmount を計算
  for (const line of result.lines) {
    const { sales, taxAmount, approxDiscountAmounts } =
      calculateVoucherLineSales(line, taxRoundingType);

    line.sales = sales;
    line.taxAmount = taxAmount;
    line.discounts.forEach((ld, i) => {
      ld.approxDiscountAmount = approxDiscountAmounts[i].amount;
      ld.approxDiscountTaxAmount = approxDiscountAmounts[i].taxAmount;
    });
  }

  // 全体割引を適用する
  if (result.discounts.length > 0) {
    const linesAfterOverallDiscount = calculateSalesAfterOverallDiscounts(
      result.lines,
      result.discounts,
      taxRoundingType
    );
    result.lines.forEach((line, i) => {
      line.sales = linesAfterOverallDiscount[i].sales;
      line.taxAmount = linesAfterOverallDiscount[i].taxAmount;
    });
  }

  // 合計金額を計算する
  result.sales = sumBy(result.lines, 'sales');
  result.taxAmount = sumBy(result.lines, 'taxAmount');

  for (const line of result.lines) {
    // 割引後の単価を計算してセット
    line.approxSellingUnitPrice =
      calculateVoucherLineApproxSellingUnitPrice(line);

    // 各 line について、スタッフ売上を計算する
    const stylistSales = calculateVoucherLineStylistSales(line);
    for (let i = 0; i < stylistSales.length; i += 1) {
      line.stylists[i].sales = stylistSales[i].sales;
      line.stylists[i].taxAmount = stylistSales[i].taxAmount;
    }
  }

  return result;
}
