import { useArray } from '@karutekun/shared/util/react-hooks';
import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import {
  Backdrop,
  Box,
  Card,
  Chip,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { dispatchWithErrorHandling } from '../../../../actions/helper/dispatchWithErrorHandling';
import {
  deleteReservationMenu,
  fetchReservationMenus,
  updateReservationMenuOrder,
} from '../../../../actions/salon/reservationSettingsAction';
import CButton from '../../../../components_old/atoms/CButton';
import CHorizontalScrollHintContainer from '../../../../components_old/atoms/CHorizontalScrollHintContainer';
import CIconButton from '../../../../components_old/atoms/CIconButton';
import CProgress from '../../../../components_old/atoms/CProgress';
import CProgressOverlay from '../../../../components_old/atoms/CProgressOverlay';
import CServiceCategoryIcon from '../../../../components_old/atoms/CServiceCategoryIcon';
import { ReservationMenu } from '../../../../models/reservationMenu';
import { checkPermission } from '../../../../models/stylist';
import { selectActiveReservationMenus } from '../../../../selectors/reservationSettingsSelector';
import { useSelectMe } from '../../../../selectors/salonSelector';
import DndProviderTemplate from '../../../../templates/DndProviderTemplate';
import {
  WithConfirmDialog,
  withConfirmDialog,
} from '../../../../templates/hoc/ConfirmDialogHOC';
import { formatMoney, notEmpty } from '../../../../util/common';
import { useFlag } from '../../../../util/hooks/useFlag';
import { useSortableItem } from '../../../../util/hooks/useSortableItem';
import { useThunkDispatch } from '../../../../util/hooks/useThunkDispatch';

type Props = WithConfirmDialog;

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    position: 'relative',
  },
  cellSort: {
    width: 60,
    padding: theme.spacing(1),
  },
  cellSortable: {
    'cursor': 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  cellDescription: {
    minWidth: 200,
    maxWidth: 300,
  },
  cell: {
    minWidth: 80,
    maxWidth: 300,
    maxHeight: 40,
    padding: theme.spacing(1),
  },
  separatorRow: {
    backgroundColor: theme.palette.grey[100],
  },
  description: {
    whiteSpace: 'pre-line',
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: '#fff',
  },
}));

const ReservationMenuSettingCard: FC<Props> = (props) => {
  const classes = useStyles();
  const { openConfirmDialog, closeConfirmDialog } = props;

  const me = useSelectMe();
  const reservationMenus = useSelector(selectActiveReservationMenus);
  const dispatch = useThunkDispatch();

  const hasPermission = checkPermission(me, 'canUpdateReservationSetting');

  const [order, orderMutations] = useArray<number>([]);
  const reorderedReservationMenus = order
    .map((id) => reservationMenus.find((rm) => rm.id === id))
    .filter(notEmpty);

  const [isFetching, callWithFetching] = useFlag(false);
  const [isSaving, callWithSaving] = useFlag(false);

  const resetOrder = useCallback(() => {
    orderMutations.set(reservationMenus.map((rm) => rm.id));
  }, [orderMutations, reservationMenus]);

  useEffect(() => {
    callWithFetching(async () => await dispatch(fetchReservationMenus()));
  }, [callWithFetching, dispatch]);

  useEffect(resetOrder, [resetOrder]);

  const isOrderChanged = useMemo(
    () =>
      _.isEqual(
        order,
        reservationMenus.map((rm) => rm.id)
      ),
    [order, reservationMenus]
  );

  const handleDelete = useCallback(
    (reservationMenuId: number) => {
      openConfirmDialog({
        description: '本当にメニューを削除しますか？',
        onOk: () => {
          closeConfirmDialog();
          callWithSaving(
            async () =>
              await dispatchWithErrorHandling(
                dispatch,
                deleteReservationMenu(reservationMenuId),
                { success: '掲載メニューを削除しました' },
                true
              )
          );
        },
      });
    },
    [callWithSaving, closeConfirmDialog, dispatch, openConfirmDialog]
  );

  const handleSaveOrder = useCallback(
    () =>
      callWithSaving(async () => {
        await dispatchWithErrorHandling(
          dispatch,
          updateReservationMenuOrder(order),
          { success: '掲載順を保存しました' },
          true
        );
      }),
    [callWithSaving, dispatch, order]
  );

  return (
    <>
      <Backdrop className={classes.backdrop} open={isSaving} timeout={500}>
        <CProgress />
      </Backdrop>

      <Box mt={2} mb={2}>
        <CButton
          linkTo="/settings/reservation/menu/new"
          disabled={!hasPermission}
          variant="contained"
          color="primary"
          startIcon={<SVGIcon name="plus" />}
        >
          掲載メニューを作成
        </CButton>
      </Box>
      <Card className={classes.card}>
        {isFetching && <CProgressOverlay />}

        <CHorizontalScrollHintContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.cellSort}>
                  <Typography variant="caption">掲載順</Typography>
                </TableCell>
                <TableCell align="center" className={classes.cellDescription}>
                  表示メニュー名
                </TableCell>
                <TableCell align="center" className={classes.cellDescription}>
                  説明文
                </TableCell>
                <TableCell align="center" className={classes.cell}>
                  掲載状況
                </TableCell>
                <TableCell align="center" className={classes.cellDescription}>
                  内部メニュー
                </TableCell>
                <TableCell align="center" className={classes.cell}>
                  価格
                </TableCell>
                <TableCell align="center" className={classes.cell}>
                  時間
                </TableCell>
                <TableCell align="center" className={classes.cell}>
                  編集
                </TableCell>
                <TableCell align="center" className={classes.cell}>
                  削除
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {reorderedReservationMenus.length > 0 ? (
                <>
                  <DndProviderTemplate>
                    {reorderedReservationMenus.map((m, i) => (
                      <MenuRow
                        key={m.id}
                        reservationMenu={m}
                        index={i}
                        disabled={!hasPermission}
                        onDelete={handleDelete}
                        onOrderChange={orderMutations.swap}
                      />
                    ))}
                  </DndProviderTemplate>

                  {hasPermission && (
                    <TableRow>
                      <TableCell colSpan={9} className={classes.separatorRow}>
                        <Grid container alignItems="center">
                          <Grid item>
                            <Typography variant="body2">
                              左のアイコン
                            </Typography>
                          </Grid>
                          <Grid item>
                            <SVGIcon
                              name="drag-indicator"
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <Typography variant="body2">
                              をドラッグして掲載順を変更することができます。
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Box ml={2}>
                              <CButton
                                disabled={isOrderChanged}
                                variant="outlined"
                                size="small"
                                onClick={resetOrder}
                              >
                                キャンセル
                              </CButton>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Box ml={2}>
                              <CButton
                                disabled={isOrderChanged}
                                size="small"
                                onClick={handleSaveOrder}
                              >
                                掲載順を保存
                              </CButton>
                            </Box>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  )}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={10}>掲載メニューがありません</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CHorizontalScrollHintContainer>
      </Card>
    </>
  );
};

const MenuRow: FC<{
  reservationMenu: ReservationMenu;
  index: number;
  disabled?: boolean;
  onDelete(stylistId: number): void;
  onOrderChange(dragIndex: number, hoverIndex: number): void;
}> = React.memo(function MenuRow(props) {
  const classes = useStyles();

  const { index, reservationMenu, disabled, onDelete, onOrderChange } = props;

  const ref = useRef<HTMLTableRowElement>(null);
  const { isDragging, drag, drop, preview, handlerId } = useSortableItem(
    'reservationMenu',
    ref,
    index,
    onOrderChange
  );
  preview(drop(ref));

  const opacity = isDragging ? 0.2 : 1;

  const handleDelete = useCallback(() => {
    onDelete(reservationMenu.id);
  }, [onDelete, reservationMenu.id]);

  return (
    <TableRow ref={ref} style={{ opacity }} data-handler-id={handlerId}>
      {disabled ? (
        <TableCell className={classes.cellSort} />
      ) : (
        <TableCell
          ref={drag}
          align="center"
          className={clsx(classes.cellSort, classes.cellSortable)}
        >
          <SVGIcon name="drag-indicator" />
        </TableCell>
      )}
      <TableCell align="left" className={classes.cellDescription}>
        <Typography variant="body1">{reservationMenu.name}</Typography>
      </TableCell>
      <TableCell align="left" className={classes.cellDescription}>
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.description}
        >
          {reservationMenu.description}
        </Typography>
      </TableCell>
      <TableCell align="center" className={classes.cell}>
        <Chip
          size="small"
          variant="outlined"
          color={reservationMenu.isVisible ? 'secondary' : 'default'}
          label={reservationMenu.isVisible ? '掲載中' : '掲載停止中'}
        />
      </TableCell>
      <TableCell align="center" className={classes.cellDescription}>
        <Grid container wrap="nowrap" alignItems="center" spacing={1}>
          <Grid item>
            <CServiceCategoryIcon
              size={20}
              category={reservationMenu.service.category}
            />
          </Grid>
          <Grid item>
            <Typography variant="body2">
              {reservationMenu.service.name}
            </Typography>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align="center" className={classes.cell}>
        {formatMoney(reservationMenu.service.price)}
        {reservationMenu.showPriceFromSign && '〜'}
      </TableCell>
      <TableCell align="center" className={classes.cell}>
        {reservationMenu.service.time}分
      </TableCell>
      <TableCell align="center" className={classes.cell}>
        <CIconButton
          disabled={disabled}
          linkTo={`/settings/reservation/menu/${reservationMenu.id}`}
        >
          <SVGIcon name="pen" />
        </CIconButton>
      </TableCell>
      <TableCell align="center" className={classes.cell}>
        <IconButton onClick={handleDelete} disabled={disabled} size="large">
          <SVGIcon name="delete" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
});

export default withConfirmDialog(ReservationMenuSettingCard);
