import { SalonMinimum } from './salon';
import { StylistMinimum } from './stylist';

export enum JoinRequestStatus {
  Pending = 0,
  Accepted = 1,
  Declined = 2,
  Canceled = 3,
}

export type JoinRequest = {
  salonId: number;
  stylistId: number;
  salon: SalonMinimum;
  stylist: StylistMinimum;
  status: JoinRequestStatus;
  createdAt: number;
  updatedAt: number;
};
