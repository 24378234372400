import Avatar, { AvatarProps } from '@mui/material/Avatar';
import { forwardRef } from 'react';

type OwnProps = AvatarProps & {
  size?: number;
};

const CAvatar: FC<OwnProps> = forwardRef((props, ref) => {
  const { size = 32, style, ...otherProps } = props;
  return (
    <Avatar
      ref={ref}
      style={{
        width: size,
        height: size,
        ...style,
      }}
      {...otherProps}
    />
  );
});

export default CAvatar;
