import { BasicPlan, OptionLinePlan } from '../models/salon';

export enum SubscriptionStatus {
  Active = 'active',
  Trialing = 'trialing',
  PastDue = 'past_due',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  Canceled = 'canceled',
  TemporarilyCanceled = 'temporarily_canceled',
  Scheduled = 'scheduled',
}

export enum SubscriptionOptionType {
  Line = 0,
}

export enum SubscriptionOptionStatus {
  Active = 'active',
  Canceled = 'canceled',
  TemporarilyCanceled = 'temporarily_canceled',
}

export enum PaymentProviderType {
  BankTransfer = 'bank_transfer',
  Stripe = 'stripe',
  AppleInAppPurchase = 'apple_in_app_purchase',
}

export type Subscription = {
  id: number;
  salonId: number;
  paymentProviderId: number;
  stripeIntentStatus: 'requires_action' | 'requires_payment_method' | null;
  apple_receipt: string | null;
  apple_original_transaction_id: string | null;
  apple_expiration_intent: string | null;
  status: SubscriptionStatus;
  startedAt: number;
  trialFinishAt: number | null;
  lastPaymentSucceededAt: number | null;
  paymentFailedAt: number | null;
  nextPaymentAt: number;
  temporarilyCanceledAt: number | null;
  canceledAt: number | null;

  paymentProvider: PaymentProvider | null;
  options: SubscriptionOption[];
};

export type SubscriptionOption = {
  id: number;
  salonId: number;
  type: SubscriptionOptionType;
  salonSubscriptionId: number;
  status: SubscriptionOptionStatus;
  temporarilyCanceledAt: number | null;
  canceledAt: number | null;
};

export type PaymentProvider = {
  id: number;
  salonId: number;
  paymentProviderType: PaymentProviderType;
  billingEmail: string | null;
  creditCardLast4: string | null;
};

export type SetupSubscriptionResult = {
  basicPlan: BasicPlan;
  optionLinePlan: OptionLinePlan;
  subscription: Subscription;
  stripePaymentIntentClientSecret: string | null;
  stripeSetupIntentClientSecret: string | null;
};

export type UpdatePaymentProviderResult = {
  paymentProvider: PaymentProvider;

  // 支払いに失敗している請求書がある場合は、カード変更時に決済が走る
  basicPlan: BasicPlan;
  optionLinePlan: OptionLinePlan;
  isPaymentRequired: boolean;
  isPaid: boolean;
  stripePaymentIntentClientSecret: string | null;
};

// TODO 一時的に lint を無効化しています。気づいたベースで直してください
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createSubscriptionFromResource(res: any): Subscription {
  return {
    id: res.id,
    salonId: res.salonId,
    paymentProviderId: res.paymentProviderId,
    stripeIntentStatus: res.stripeIntentStatus,
    apple_receipt: res.appleReceipt,
    apple_original_transaction_id: res.appleOriginalTransactionId,
    apple_expiration_intent: res.appleExpirationIntent,
    status: res.status,
    startedAt: res.startedAt,
    trialFinishAt: res.trialFinishAt,
    lastPaymentSucceededAt: res.lastPaymentSucceededAt,
    paymentFailedAt: res.paymentFailedAt,
    nextPaymentAt: res.nextPaymentAt,
    temporarilyCanceledAt: res.temporarilyCanceledAt,
    canceledAt: res.canceledAt,

    paymentProvider: res.paymentProvider
      ? createPaymentProviderFromResource(res.paymentProvider)
      : null,
    options: res.options
      ? res.options.map(createSubscriptionOptionResource)
      : [],
  };
}

// TODO 一時的に lint を無効化しています。気づいたベースで直してください
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createSubscriptionOptionResource(res: any): SubscriptionOption {
  return {
    id: res.id,
    salonId: res.salonId,
    type: res.type,
    salonSubscriptionId: res.salonSubscriptionId,
    status: res.status,
    temporarilyCanceledAt: res.temporarilyCanceledAt,
    canceledAt: res.canceledAt,
  };
}

// TODO 一時的に lint を無効化しています。気づいたベースで直してください
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createPaymentProviderFromResource(res: any): PaymentProvider {
  return {
    id: res.id,
    salonId: res.salonId,
    paymentProviderType: res.paymentProviderType,
    billingEmail: res.billingEmail,
    creditCardLast4: res.creditCardLast4,
  };
}

export function createSetupSubscriptionResultFromResource(
  // TODO 一時的に lint を無効化しています。気づいたベースで直してください
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  res: any
): SetupSubscriptionResult {
  return {
    basicPlan: res.basicPlan,
    optionLinePlan: res.optionLinePlan,
    subscription: createSubscriptionFromResource(res.subscription),
    stripePaymentIntentClientSecret: res.stripePaymentIntentClientSecret,
    stripeSetupIntentClientSecret: res.stripeSetupIntentClientSecret,
  };
}

export function createUpdatePaymentProviderResultFromResource(
  // TODO 一時的に lint を無効化しています。気づいたベースで直してください
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  res: any
): UpdatePaymentProviderResult {
  return {
    paymentProvider: createPaymentProviderFromResource(res.paymentProvider),
    basicPlan: res.basicPlan,
    optionLinePlan: res.optionLinePlan,
    isPaymentRequired: res.isPaymentRequired,
    isPaid: res.isPaid,
    stripePaymentIntentClientSecret: res.stripePaymentIntentClientSecret,
  };
}

/**
 * サブスクリプションが編集可能かどうか
 * Stripeでの契約に限って管理ボードから編集ができる
 */
export function isSubscriptionEditable(subscription: Subscription | null) {
  return (
    subscription?.paymentProvider?.paymentProviderType ===
    PaymentProviderType.Stripe
  );
}

/**
 * サブスクリプションが解約/暫定解約状態かどうか
 */
export function isSubscriptionCanceled(subscription: Subscription | null) {
  return (
    subscription === null ||
    subscription.status === SubscriptionStatus.Canceled ||
    subscription.status === SubscriptionStatus.TemporarilyCanceled ||
    subscription.status === SubscriptionStatus.IncompleteExpired
  );
}

/**
 * サブスクリプションが解約できる状態かどうか
 */
export function isSubscriptionCancellable(subscription: Subscription | null) {
  return (
    subscription?.status === SubscriptionStatus.Active ||
    subscription?.status === SubscriptionStatus.Trialing
  );
}

/**
 * お支払い方法が削除可能な状態かどうか
 * 有料のプランが有効な場合は削除できない
 */
export function isSubscriptionPaymentProviderDeletable(
  subscription: Subscription | null
) {
  return subscription !== null && isSubscriptionCanceled(subscription);
}

export function getSubscriptionOption(
  subscription: Subscription | null,
  optionType: SubscriptionOptionType
): SubscriptionOption | null {
  return subscription?.options.find((o) => o.type === optionType) ?? null;
}
