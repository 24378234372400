import { trpc } from '@karutekun/shared/data-access/api-base';
import { Card } from '@mui/material';
import { keepPreviousData } from '@tanstack/react-query';
import { memo, useMemo } from 'react';
import CChartArea from '../../../../components_old/atoms/CChartArea';
import CDateRange from '../../../../components_old/molecules/CDateRange';
import CChartWrapper from '../../../../components_old/organisms/charts/CChartWrapper';
import { InformationText } from '../../../../texts/infomation';
import { fmtCustomerNum, fmtDate } from '../../../../util/chart';

type Props = {
  stylistId: number;
  from: string;
  to: string;
  onChangeDate(from: string, to: string): void;
};

// TODO: グラフの色指定は他の箇所でも似たようなコードが散在するので、共通化する余地ある
const shimeiCustomerValueKeys = [
  {
    dataKey: 'shimeiCustomerNum',
    name: '指名有り',
    color: '#20a8d8',
  },
  {
    dataKey: 'notShimeiCustomerNum',
    name: '指名無し',
    color: '#ffab4f',
  },
];

export const StylistRepeatCustomerStatsCard: FC<Props> = memo(
  ({ stylistId, from, to, onChangeDate }) => {
    const { isRefetching, data: dailySnapshots = [] } =
      trpc.analytics.repeatCustomerStats.dailySnapshots.useQuery(
        {
          range: { from, to },
          interval: 7,
        },
        { placeholderData: keepPreviousData }
      );

    const chartData = useMemo(() => {
      return dailySnapshots.map((s) => ({
        date: fmtDate(s.date),
        shimeiCustomerNum: s.lastStylistShimeiMap[stylistId]?.[1] ?? 0,
        notShimeiCustomerNum: s.lastStylistShimeiMap[stylistId]?.[0] ?? 0,
      }));
    }, [dailySnapshots, stylistId]);

    return (
      <Card>
        <CChartWrapper
          title="固定客数 推移"
          isFetching={isRefetching}
          informationText={InformationText.stylists.dailyRepeat}
          rightComponent={
            <CDateRange
              from={from}
              to={to}
              onChange={(from, to) => {
                if (from !== undefined && to !== undefined) {
                  onChangeDate(from, to);
                }
              }}
            />
          }
        >
          <CChartArea
            data={chartData}
            nameKey="date"
            formatter={fmtCustomerNum}
            valueKeys={shimeiCustomerValueKeys}
            showLegend
            stack
          />
        </CChartWrapper>
      </Card>
    );
  }
);
