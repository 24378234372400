import { theme } from '@karutekun/shared-fe/react-ui-old';
import { makeStyles } from '@mui/styles';
import { SnackbarProvider } from 'notistack';

const useStyles = makeStyles({
  success: {
    backgroundColor: `${theme.palette.success.main} !important`,
  },
  error: {
    backgroundColor: `${theme.palette.error.main} !important`,
  },
  info: {
    backgroundColor: `${theme.palette.info.main} !important`,
  },
  warning: {
    backgroundColor: `${theme.palette.warning.main} !important`,
  },
});

const WrappedSnackbarProvider: FC = (props) => {
  const classes = useStyles();
  return (
    <SnackbarProvider
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantInfo: classes.info,
        variantWarning: classes.warning,
      }}
    >
      {props.children}
    </SnackbarProvider>
  );
};

export default WrappedSnackbarProvider;
