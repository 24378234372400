import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      d="M27 24.25c-5.08 0-9.57-4.77-10-10.62a10.16 10.16 0 0 1 2.6-7.76A10 10 0 0 1 27 2.75a10 10 0 0 1 7.39 3.13A10.17 10.17 0 0 1 37 13.63c-.44 5.86-4.92 10.62-10 10.62m0-18a6.5 6.5 0 0 0-4.84 2 6.66 6.66 0 0 0-1.66 5.12c.29 4 3.27 7.38 6.5 7.38s6.21-3.38 6.51-7.38a6.67 6.67 0 0 0-1.69-5.1A6.47 6.47 0 0 0 27 6.25"
    />
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M7.31 41.18c1.74-9.63 11-14.43 19.69-14.43 8.69 0 18 4.8 19.69 14.43a3.63 3.63 0 0 1-.54 2.69 3.15 3.15 0 0 1-2.65 1.38h-33a3.16 3.16 0 0 1-2.65-1.38 3.63 3.63 0 0 1-.54-2.69m3.46.57h32.46c-1.35-7.32-8.59-11.5-16.23-11.5-7.64 0-14.88 4.18-16.23 11.5"
      clipRule="evenodd"
    />
    <path
      fill={props.color || 'currentColor' || '#000'}
      d="M13.87 20.59a2.55 2.55 0 0 0-1.8-.75v-1.43a3.74 3.74 0 0 0-3.82-3.66 3.74 3.74 0 0 0-3.82 3.66v1.43a2.55 2.55 0 0 0-2.352 1.575 2.51 2.51 0 0 0-.188.975v3.81a2.51 2.51 0 0 0 .74 1.8 2.55 2.55 0 0 0 1.8.75h7.64a2.55 2.55 0 0 0 2.351-1.576c.128-.309.192-.64.189-.974v-3.81a2.51 2.51 0 0 0-.74-1.8M7 18.41a1.2 1.2 0 0 1 1.25-1.11 1.2 1.2 0 0 1 1.27 1.11v1.43H7z"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgUserLock = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgUserLock);
export default Memo;
