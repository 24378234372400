import { getAge } from '@karutekun/core/customer';
import { getPermissionName } from '@karutekun/core/permission-role';
import { Card, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import CAvatar from '../../../../components_old/atoms/CAvatar';
import CButton from '../../../../components_old/atoms/CButton';
import CCustomerSexIcon from '../../../../components_old/atoms/CCustomerSexIcon';
import CInformation from '../../../../components_old/atoms/CInformation';
import CLoyaltySegmentChip from '../../../../components_old/atoms/CLoyaltySegmentChip';
import CMenuButton from '../../../../components_old/atoms/CMenuButton';
import { Customer, useCustomerImage } from '../../../../models/customer';
import { checkPermission } from '../../../../models/stylist';
import {
  selectHasGroupSalon,
  selectMe,
} from '../../../../selectors/salonSelector';
import { useSalonStatus } from '../../../../templates/providers/salonStatus/salonStatusContext';

const useStyles = makeStyles((theme: Theme) => ({
  dividerRight: {
    borderRight: '1px solid #d9d9d9',
  },
  loyaltySegmentContainer: {
    padding: theme.spacing(1),
    textAlign: 'center',
  },
}));

export const ProfileCard: FC<{
  customer: Customer;
  onClickCreateKarute(): void;
  onClickDelete(): void;
}> = (props) => {
  const classes = useStyles();
  const { customer, onClickCreateKarute, onClickDelete } = props;

  const me = useSelector(selectMe);
  const hasGroupSalon = useSelector(selectHasGroupSalon);

  const age = getAge(customer);
  const { checkAndOpenDialog } = useSalonStatus();
  const profileImage = useCustomerImage(customer);

  return (
    <Card style={{ width: '100%', paddingTop: 10, paddingBottom: 10 }}>
      <Grid container spacing={2}>
        <Grid item container alignItems="center" justifyContent="center">
          <CAvatar size={200} src={profileImage} />
        </Grid>

        <Grid
          item
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography display="block" variant="subtitle2" color="textSecondary">
            {customer.nameKana}
          </Typography>
          <Typography display="block" variant="h5">
            {customer.name} 様
          </Typography>
          <Typography
            display="block"
            variant="subtitle1"
            color="textSecondary"
            gutterBottom
          >
            <CCustomerSexIcon sex={customer.sex} style={{ marginRight: 5 }} />
            {age !== null ? `${age} 歳` : '年齢不明'}
          </Typography>
          {hasGroupSalon ? (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                className={clsx(
                  classes.dividerRight,
                  classes.loyaltySegmentContainer
                )}
              >
                <CLoyaltySegmentChip loyaltySegment={customer.loyaltySegment} />
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  全サロン
                </Typography>
              </div>
              <div className={classes.loyaltySegmentContainer}>
                <CLoyaltySegmentChip
                  loyaltySegment={customer.mySalonStatistics.loyaltySegment}
                />
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  当サロン
                </Typography>
              </div>
            </div>
          ) : (
            <CLoyaltySegmentChip loyaltySegment={customer.loyaltySegment} />
          )}
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <Grid item container xs justifyContent="flex-end">
            <CButton
              onClick={() => {
                if (!checkAndOpenDialog()) {
                  return;
                }
                onClickCreateKarute();
              }}
            >
              来店記録を追加
            </CButton>
          </Grid>
          <Grid container direction="row" item xs>
            <CMenuButton
              fullWidth
              variant="outlined"
              menus={[
                {
                  title: 'カルテを削除する',
                  onClick: () => {
                    if (!checkAndOpenDialog()) {
                      return;
                    }

                    if (checkPermission(me, 'canDeleteCustomer')) {
                      onClickDelete();
                    }
                  },
                },
              ]}
            >
              その他
            </CMenuButton>
            <CInformation
              type="tooltip"
              content={`${getPermissionName(
                'canDeleteCustomer'
              )}権限を持ったスタッフのみ削除可能です。`}
            />
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
