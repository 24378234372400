import { PermissionRole } from '@karutekun/core/permission-role';
import { IVoucher, VoucherUtils } from '@karutekun/core/voucher';
import { IReservation, IVisitHistory } from '../data';
import { isVisitHistory } from './visit-status';

/**
 * 来店の削除権限があるかどうかチェックする
 * 会計済みの場合は、会計の取り消し権限も必要になる
 */
export function checkDeletePermission(
  visit:
    | (Pick<IVisitHistory, 'status'> & { voucher: Pick<IVoucher, 'status'> })
    | Pick<IReservation, 'status'>,
  permissionRole: PermissionRole
): boolean {
  if (isVisitHistory(visit) && VoucherUtils.isPaid(visit.voucher)) {
    return (
      permissionRole.canDeleteKarute && permissionRole.canPullbackPaymentKarute
    );
  }

  return permissionRole.canDeleteKarute;
}
