import { trpc } from '@karutekun/shared/data-access/api-base';
import { useCallback } from 'react';

type Options = {
  onSuccess?(): void;
};

export function useUpdateIsConfirmedByStaff({ onSuccess }: Options = {}) {
  const { mutate: updateReservation, ...queryVariables } =
    trpc.visit.updateReservation.useMutation({
      onSuccess,
    });

  /**
   * スタッフの確認を on/off するだけなので、conflict check をスキップする
   * userUpdatedAt は本来不要だが、API では必ず指定するようになっているので、仕方なく追加している
   */
  const mutate = useCallback(
    (id: number, userUpdatedAt: number, isConfirmedByStaff: boolean) => {
      updateReservation({
        id,
        userUpdatedAt,
        visit: { isConfirmedByStaff },
        skipAvailabilityCheck: true,
        skipConflictCheck: true,
      });
    },
    [updateReservation]
  );

  return { mutate, ...queryVariables };
}
