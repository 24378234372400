import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      d="M24 43.75a1.69 1.69 0 0 1-1.26-.55l-.11-.12c-2.22-2.77-5.95-5.33-18.12-5.33a3.277 3.277 0 0 1-3.25-3.43V7.5a3.09 3.09 0 0 1 .24-1.24 3.16 3.16 0 0 1 .7-1.06 3.35 3.35 0 0 1 1.06-.71 3 3 0 0 1 1.24-.24C14.62 4.3 21 6.39 24 10.78c3.05-4.39 9.38-6.48 19.49-6.53a3.26 3.26 0 0 1 1.24.24 3.23 3.23 0 0 1 2 3v27a3.24 3.24 0 0 1-3.25 3.25c-12.16 0-15.9 2.56-18.12 5.33l-.1.12-.1.1c-.32.29-.738.45-1.17.45zm1.75-28.52V38c3.78-2.6 9.22-3.73 17.5-3.75V7.75c-13.59.11-16.55 4.01-17.5 7.48m-21 19c8.28 0 13.72 1.15 17.5 3.75V15.23c-.95-3.48-3.91-7.37-17.52-7.48z"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgBookOpen = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgBookOpen);
export default Memo;
