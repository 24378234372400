export enum ServiceType {
  Menu = 1,
  Product = 2,
  Ticket = 3, // 回数券
}
export const ServiceTypes = [
  ServiceType.Menu,
  ServiceType.Product,
  ServiceType.Ticket,
];
export const ServiceTypeOrder = {
  [ServiceType.Menu]: 3,
  [ServiceType.Product]: 2,
  [ServiceType.Ticket]: 1,
};
export const ServiceTypeName = {
  [ServiceType.Menu]: '施術',
  [ServiceType.Product]: '店販',
  [ServiceType.Ticket]: '回数券',
};
export enum DiscountType {
  Price = 1,
  Percentage = 2,
}

export interface IServiceCategory {
  id: number;
  type: ServiceType;
  name: string;
  iconName: string;
  order: number;
  isActive: boolean;
  isVisible: boolean;
}

export interface IService {
  id: number;
  type: ServiceType;
  categoryId: number;
  name: string;
  time: number;
  price: number;
  isTaxIncluded: boolean;
  taxRate: number;
  janCode: string | null;
  ticketNum: number;
  ticketUnusedValidDays: number;
  ticketUsedValidDays: number;
  order: number;
  isVisible: boolean;
  isActive: boolean;
}

export interface IServiceDiscount {
  id: number;
  type: DiscountType;
  name: string;
  value: number;
  order: number;
  isVisible: boolean;
  isActive: boolean;
}
