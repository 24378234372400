import { DiscountType } from '@karutekun/core/salon-service';
import { formatMoney } from '@karutekun/shared/util/format';
import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CDiscountIcon } from '../../../../../components_old/atoms/CDiscountIcon';
import { useSelectSalonServices } from '../../../../../selectors/serviceSelector';
import { VoucherDiscountForPreview } from '../types';

type Props = {
  voucherSalonId?: number;
  discount: VoucherDiscountForPreview;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'block',
    width: '100%',
    padding: theme.spacing(1),
    borderRadius: 4,
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.grey[50],
    textAlign: 'left',
  },
  discount: {
    color: theme.palette.primary.main,
  },
}));

const VoucherDiscountItem: FC<Props> = (props) => {
  const classes = useStyles();
  const { discount } = props;
  const { discountMap } = useSelectSalonServices();

  return (
    <div className={classes.container}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <CDiscountIcon size={20} />
            </Grid>
            <Grid item xs>
              <Typography variant="body1">
                {discountMap[discount.discountId]?.name}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="body1" className={classes.discount}>
            {discount.type === DiscountType.Price
              ? formatMoney(-discount.value)
              : `${-discount.value}%`}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default VoucherDiscountItem;
