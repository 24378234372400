import chroma from 'chroma-js';
import * as React from 'react';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { legendProps, tooltipProps } from '../../util/chart';
import CChartTooltip from './CChartTooltip';
import CEmpty from './CEmpty';

export type ChartData = {
  id: number;
  name: string;
  value: number;
};
type OwnProps = {
  data: ChartData[];

  formatter?(value: number): React.ReactNode;

  sortDataByValue?: boolean;
  sortDataOrder?: 'asc' | 'desc';

  // この数を超えるデータ数ある場合は、以降がその他に集約される
  maxDataNum?: number;

  // 色味に関する設定項目
  startColor?: string;
  endColor?: string;
};

export default class CChartPie extends React.PureComponent<OwnProps> {
  render() {
    const { formatter, sortDataByValue, maxDataNum } = this.props;
    const {
      sortDataOrder = 'desc',
      startColor = '#20a8d8',
      endColor = '#ffc107',
    } = this.props;
    const data = [...this.props.data];

    if (this.props.data.length === 0) {
      return <CEmpty title="該当データがありません" />;
    }

    const tooltipFormatter = formatter
      ? (value: string | number | (string | number)[]) =>
          formatter(Number(value))
      : undefined;

    data.sort((a, b) => {
      const comp = sortDataByValue ? b.value - a.value : b.id - a.id;
      return sortDataOrder === 'desc' ? comp : -comp;
    });

    // 子データが多い場合は "その他" に集約
    if (maxDataNum && data.length > maxDataNum) {
      const tails = data.splice(maxDataNum - 1);
      data.push({
        id: tails[0].id,
        name: 'その他',
        value: tails.reduce((p, c) => p + c.value, 0),
      });
    }

    const colors = chroma
      .scale([startColor, endColor])
      .mode('hcl')
      .colors(data.length);

    return (
      <ResponsiveContainer>
        <PieChart>
          <Legend {...legendProps} />
          <Pie
            data={data}
            dataKey="value"
            startAngle={90}
            endAngle={-270}
            fill="#8884d8"
            isAnimationActive={false}
          >
            {data.map((_, i) => (
              <Cell key={`cell-${i}`} fill={colors[i]} />
            ))}
          </Pie>
          <Tooltip
            {...tooltipProps}
            formatter={tooltipFormatter}
            content={<CChartTooltip />}
          />
        </PieChart>
      </ResponsiveContainer>
    );
  }
}
