import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import { Card, IconButton, TextField, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  DragSourceMonitor,
  DropTargetMonitor,
  useDrag,
  useDrop,
} from 'react-dnd';
import {
  CounselingItem,
  QuestionItem,
  QuestionType,
  QuestionTypeText,
} from '../../../models/counseling';
import {
  WithConfirmDialog,
  withConfirmDialog,
} from '../../../templates/hoc/ConfirmDialogHOC';
import CMenuButton from '../../atoms/CMenuButton';
import CQuestion from './CQuestion';
import { DragItem, ItemTypes, isMovable } from './dragUtils';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    padding: theme.spacing(1),
  },
  deleteButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  required: {
    backgroundColor: theme.palette.error.light,
  },
  titleContainer: {
    marginBottom: theme.spacing(1),
    width: '90%',
  },
  title: {
    fontSize: theme.typography.h5.fontSize,
  },
  description: {
    fontSize: theme.typography.body2.fontSize,
  },
  questionWrapper: {
    marginTop: theme.spacing(4),
  },
  question: {
    marginBottom: theme.spacing(2),
  },
  handle: {
    width: '100%',
    display: 'inline-block',
    cursor: 'move',
    textAlign: 'center',
  },
}));

const CQuestionSection: FC<
  {
    section: CounselingItem;
    index: number;
    previousActiveIndex: number;
    nextActiveIndex: number;
    canDelete: boolean;
    canUpdate: boolean;
    isLoading: boolean;
    isCollapsed: boolean;
    savedMaxOptionId: number;
    onChangeIsDraging: (sectionId: number, isDragging: boolean) => void;
    moveSection: (dragIndex: number, hoverIndex: number) => void;
    updateSection: (
      updatedIndex: number,
      updatedSection: CounselingItem | null
    ) => void;
  } & WithConfirmDialog
> = (props) => {
  const {
    section,
    index,
    previousActiveIndex,
    nextActiveIndex,
    canDelete,
    canUpdate,
    isLoading,
    isCollapsed,
    savedMaxOptionId,
    onChangeIsDraging,
    moveSection,
    updateSection,
    openConfirmDialog,
    closeConfirmDialog,
  } = props;
  const { id, title, description, questions } = section;
  const classes = useStyles();

  const sectionRef = useRef(section);
  useEffect(() => {
    sectionRef.current = section;
  }, [section]);

  const ref = useRef<HTMLDivElement>(null);
  const [, drop] = useDrop<DragItem>({
    accept: ItemTypes.section,
    hover(item: DragItem, monitor: DropTargetMonitor) {
      const dragIndex = item.index;
      const hoverIndex = index;

      if (!isMovable(ref, monitor, dragIndex, hoverIndex)) {
        return;
      }
      moveSection(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.section,
    item: { id, index },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [draggingQuestionId, setDraggingQuestionId] = useState<number | null>(
    null
  );
  useEffect(() => {
    onChangeIsDraging(id, isDragging);
  }, [id, isDragging, onChangeIsDraging]);

  // drag監視関数
  const onChangeIsQuestionDragging = useCallback(
    (questionId: number, isDragging: boolean) => {
      if (isDragging) {
        setDraggingQuestionId(questionId);
      } else if (draggingQuestionId === questionId) {
        setDraggingQuestionId(null);
      }
    },
    [draggingQuestionId, setDraggingQuestionId]
  );

  const moveQuestion = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const cloneSection = _.cloneDeep(sectionRef.current);
      const dragQuestion = cloneSection.questions[dragIndex];
      cloneSection.questions.splice(dragIndex, 1);
      cloneSection.questions.splice(hoverIndex, 0, dragQuestion);
      updateSection(index, cloneSection);
    },
    [sectionRef, index, updateSection]
  );

  const updateQuestion = useCallback(
    (updatedIndex: number, updatedQuestion: QuestionItem | null) => {
      const cloneSection = _.cloneDeep(sectionRef.current);
      if (updatedQuestion) {
        cloneSection.questions[updatedIndex] = updatedQuestion;
      } else {
        cloneSection.questions.splice(updatedIndex, 1);
      }

      updateSection(index, cloneSection);
    },
    [sectionRef, index, updateSection]
  );

  const renderQuestion = (question: QuestionItem, index: number) => {
    // isActive:falseのものも含めたindexを採番しておく
    if (!question.isActive) {
      return;
    }
    const previousActiveIndex =
      [...Array(index + 1)]
        .map((_, i) => index - i)
        .find((i) => questions[i].isActive && i < index) ?? -1;
    const nextActiveIndex = questions.findIndex(
      (q, i) => q.isActive && i > index
    );
    return (
      <div key={question.id} className={classes.question}>
        <CQuestion
          question={question}
          index={index}
          previousActiveIndex={previousActiveIndex}
          nextActiveIndex={nextActiveIndex}
          canDelete={canDelete}
          canUpdate={canUpdate}
          isLoading={isLoading}
          isCollapsed={
            draggingQuestionId !== null && draggingQuestionId !== question.id
          }
          savedMaxOptionId={savedMaxOptionId}
          onChangeIsDraging={onChangeIsQuestionDragging}
          moveQuestion={moveQuestion}
          updateQuestion={updateQuestion}
        />
      </div>
    );
  };

  const opacity = isDragging ? 0 : 1;
  drop(ref);

  return (
    <Card className={classes.container} style={{ opacity }}>
      <div ref={ref}>
        <div ref={preview}>
          <div ref={drag} className={classes.handle}>
            <SVGIcon name="grip-lines-vertical" />
          </div>
          <div className={classes.deleteButton}>
            {previousActiveIndex !== -1 && (
              <IconButton
                disabled={isLoading}
                onClick={() => moveSection(index, previousActiveIndex)}
                size="large"
              >
                <SVGIcon name="arrow-upward" />
              </IconButton>
            )}
            {nextActiveIndex !== -1 && (
              <IconButton
                disabled={isLoading}
                onClick={() => moveSection(index, index + 1)}
                size="large"
              >
                <SVGIcon name="arrow-downward" />
              </IconButton>
            )}
            <IconButton
              disabled={isLoading || (!canDelete && !section.isNewItem)}
              onClick={() => {
                openConfirmDialog({
                  title: '確認',
                  description:
                    '本当に削除しますか？セクションに含まれる質問も削除されます。',
                  onOk: () => {
                    if (section.isNewItem) {
                      updateSection(index, null);
                    } else {
                      updateSection(index, {
                        ...section,
                        isActive: false,
                        questions: section.questions
                          .filter((q) => !q.isNewItem)
                          .map((q) => ({
                            ...q,
                            isActive: false,
                            options: q.options
                              .filter((o) => !o.isNewItem)
                              .map((o) => ({
                                ...o,
                                isActive: false,
                                description: o.description || '',
                              })),
                          })),
                      });
                      closeConfirmDialog();
                    }
                  },
                });
              }}
              size="large"
            >
              <SVGIcon name="delete" />
            </IconButton>
          </div>

          <TextField
            variant="standard"
            className={clsx(classes.titleContainer, {
              [classes.required]: !title,
            })}
            required
            label={title ? undefined : 'タイトルは必須です'}
            value={title}
            placeholder="タイトル"
            fullWidth
            InputProps={{
              classes: {
                input: classes.title,
              },
              inputProps: { maxLength: 240 },
            }}
            onChange={(e) => {
              updateSection(index, {
                ...section,
                title: e.target.value || '',
              });
            }}
          />

          <TextField
            variant="outlined"
            value={description || ''}
            placeholder="説明(省略可)"
            fullWidth
            multiline
            InputProps={{
              classes: {
                input: classes.description,
              },
            }}
            onChange={(e) => {
              updateSection(index, {
                ...section,
                description: e.target.value || null,
              });
            }}
          />
        </div>

        {!isDragging && !isCollapsed && (
          <div className={classes.questionWrapper}>
            {questions.map((quesiton, i) => renderQuestion(quesiton, i))}
          </div>
        )}

        <CMenuButton
          variant="outlined"
          disabled={!canUpdate || isLoading}
          menus={[
            QuestionType.Checkbox,
            QuestionType.Radiobutton,
            QuestionType.Pulldown,
            QuestionType.TextInput,
          ].map((type) => ({
            title: QuestionTypeText[type],
            onClick: () => {
              const questionWithMaxId = _.maxBy(questions, 'id');
              const tmpIndex = questions.length;
              const tmpQuestionId = questionWithMaxId
                ? questionWithMaxId.id + 1
                : 1;
              // preview用にほぼ(option,questionがそれぞれ100個未満なら)重複しないようにoptionIdを採番
              const optionId =
                savedMaxOptionId + id * 10000 + tmpQuestionId * 100;
              updateQuestion(tmpIndex, {
                type,
                counselingId: id,
                id: tmpQuestionId,
                order: 0,
                title: '',
                description: null,
                isNewItem: true,
                isActive: true,
                options: [
                  {
                    questionId: tmpQuestionId,
                    id: optionId,
                    order: 0,
                    description: '',
                    isNewItem: true,
                    isActive: true,
                    textPlaceholder: null,
                  },
                ],
              });
            },
          }))}
        >
          質問項目を追加する
        </CMenuButton>
      </div>
    </Card>
  );
};

export default React.memo(withConfirmDialog(CQuestionSection), (prev, next) =>
  _.isEqual(prev, next)
);
