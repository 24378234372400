import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      d="M36.275 20.438a1.052 1.052 0 0 1 0 2.102H33.35v1.875h2.925a1.05 1.05 0 1 1 0 2.098h-3.977a1.052 1.052 0 0 1-1.045-1.048v-7.952c0-.575.47-1.05 1.05-1.05h3.977a1.05 1.05 0 0 1-.005 2.1H33.35v1.875zm-6.425 5.027a1.049 1.049 0 0 1-1.052 1.045 1.03 1.03 0 0 1-.85-.417l-4.071-5.528v4.9a1.05 1.05 0 0 1-1.052 1.048 1.05 1.05 0 0 1-1.043-1.048v-7.952a1.044 1.044 0 0 1 1.04-1.047c.325 0 .625.174.825.424l4.103 5.55v-4.927c0-.575.47-1.05 1.05-1.05.575 0 1.05.475 1.05 1.05zm-9.568 0c0 .573-.47 1.048-1.052 1.048a1.052 1.052 0 0 1-1.045-1.048v-7.952c0-.575.47-1.05 1.05-1.05.577 0 1.047.475 1.047 1.05zm-4.11 1.048h-3.977c-.575 0-1.05-.475-1.05-1.048v-7.952c0-.575.475-1.05 1.05-1.05.58 0 1.05.475 1.05 1.05v6.902h2.927a1.05 1.05 0 0 1 0 2.098M44 21.19c0-8.952-8.975-16.237-20-16.237S4 12.238 4 21.19c0 8.018 7.117 14.736 16.725 16.013.652.137 1.538.43 1.763.983.2.502.132 1.277.064 1.8l-.274 1.7c-.075.502-.4 1.977 1.749 1.075 2.151-.898 11.526-6.796 15.726-11.625C42.627 27.989 44 24.764 44 21.19"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgLine = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgLine);
export default Memo;
