import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M20.09 6.516a3.5 3.5 0 1 0-1.344 6.734h3.504v-3.5a3.5 3.5 0 0 0-2.16-3.234m-7.807 5.913c.117.283.253.557.405.821H9A4.75 4.75 0 0 0 4.25 18v4.5a4.751 4.751 0 0 0 3 4.417V39a6.25 6.25 0 0 0 6.25 6.25h21A6.25 6.25 0 0 0 40.75 39V26.917a4.751 4.751 0 0 0 3-4.417V18A4.75 4.75 0 0 0 39 13.25h-3.688a7.007 7.007 0 0 0 .803-4.866A7 7 0 0 0 24 5.12a7 7 0 0 0-11.717 7.309M37.25 27.25h-11.5v14.5h8.75A2.75 2.75 0 0 0 37.25 39zm-15 0h-11.5V39a2.75 2.75 0 0 0 2.75 2.75h8.75zM9 23.75h13.25v-7H9c-.69 0-1.25.56-1.25 1.25v4.5c0 .69.56 1.25 1.25 1.25m16.75-7v7H39c.69 0 1.25-.56 1.25-1.25V18c0-.69-.56-1.25-1.25-1.25h-9.75zm5.445-4.09a3.5 3.5 0 0 1-1.94.59H25.75v-3.5a3.5 3.5 0 1 1 5.445 2.91"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgGift = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgGift);
export default Memo;
