import { getAge } from '@karutekun/core/customer';
import { VisitResource } from '@karutekun/core/visit';
import { VoucherResource, VoucherUtils } from '@karutekun/core/voucher';
import { moment } from '@karutekun/shared/util/datetime';
import {
  Box,
  Card,
  CardActionArea,
  Grid,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  selectAllSalonMap,
  selectHasGroupSalon,
  selectStylistMap,
} from '../../../../selectors/salonSelector';
import { useSelectVoucherLineServices } from '../../../../selectors/voucherSelector';
import { formatDateShort } from '../../../../util/common';
import CAvatar from '../../../atoms/CAvatar';
import CCustomerSexIcon from '../../../atoms/CCustomerSexIcon';
import CMoneyTypography from '../../../atoms/CMoneyTypography';
import CShimeiIcon from '../../../atoms/CShimeiIcon';
import CServiceNames from '../../../molecules/CServiceNames';
import CStylist from '../../../molecules/CStylist';

type Props = {
  visit: VisitResource;
  linkTo: string;
  isHighlighted?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  highlightedCard: {
    // TODO 一時的にルールを無効化しています。気づいたベースで直してください
    // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type '200' can't be used to index type 'PaletteColor'.
    border: `2px solid ${theme.palette.primary[200]}`,
  },
  timeHeader: {
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  salesText: {
    fontSize: theme.typography.h6.fontSize,
  },
}));

export const CVisitCard: FC<Props> = ({ visit, isHighlighted, linkTo }) => {
  const classes = useStyles();

  const stylistMap = useSelector(selectStylistMap);
  const customer = visit.customer;
  const stylist = visit?.assignedStylistId
    ? (stylistMap[visit.assignedStylistId] ?? null)
    : null;
  const showSalonName = useSelector(selectHasGroupSalon);
  const salonMap = useSelector(selectAllSalonMap);

  if (!visit || !customer) {
    return null;
  }

  const m = moment.unix(visit.startedAt);
  const date = formatDateShort(m);
  const timeFrom = m.format('HH:mm');
  const timeTo = moment.unix(visit.finishedAt).format('HH:mm');
  const age = getAge(customer);

  return (
    <Card className={clsx({ [classes.highlightedCard]: isHighlighted })}>
      <CardActionArea color="primary">
        <Link to={linkTo} style={{ textDecoration: 'none' }}>
          <div className={classes.timeHeader}>
            <Typography variant="body1" color="textPrimary">
              {date} {timeFrom} - {timeTo}
            </Typography>
            {showSalonName && (
              <Typography
                style={{ maxWidth: '40%' }}
                variant="body2"
                color="textSecondary"
                noWrap
              >
                {salonMap[visit.salonId]?.name}
              </Typography>
            )}
          </div>
          <Box p={1} className={clsx(classes.row, classes.borderBottom)}>
            <Box mr={1}>
              <CAvatar
                size={56}
                src={visit.imageUrl ?? '/static/images/noimage.png'}
              />
            </Box>
            <Box flex={1}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography variant="caption" color="textSecondary">
                    {customer.nameKana}
                  </Typography>
                  <Typography variant="h6" color="textPrimary">
                    {customer.name} 様
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle2" color="textSecondary">
                    <CCustomerSexIcon
                      sex={customer.sex}
                      style={{ marginRight: 5 }}
                    />
                    {age !== null ? `${age} 歳` : '年齢不明'}
                  </Typography>
                </Grid>
              </Grid>

              <Box className={classes.row} justifyContent="flex-end">
                <CStylist stylist={stylist} />
                {visit.isShimei && <CShimeiIcon ml={4} />}
              </Box>
            </Box>
          </Box>
          <Box p={1} className={classes.borderBottom}>
            <ServiceDetail voucher={visit.voucher} />
          </Box>
        </Link>
      </CardActionArea>
      <Box p={1}>
        <Typography
          variant="body2"
          color="textSecondary"
          style={{
            whiteSpace: 'pre-line',
            maxHeight: 100,
            overflow: 'auto',
          }}
        >
          {visit.treatmentMemo || 'メモなし'}
        </Typography>
      </Box>
    </Card>
  );
};

const ServiceDetail: FC<{ voucher: VoucherResource }> = ({ voucher }) => {
  const classes = useStyles();
  const { services, discounts } = useSelectVoucherLineServices(voucher);

  return (
    <>
      <CServiceNames
        services={services}
        discounts={discounts}
        emptyComponent={
          <Typography variant="subtitle1" color="textSecondary">
            無し
          </Typography>
        }
      />
      <Box mt={1} mr={1}>
        {VoucherUtils.isPaid(voucher) ? (
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                お会計
              </Typography>
            </Grid>
            <Grid item>
              <CMoneyTypography
                amount={voucher.sales}
                className={classes.salesText}
                color="textPrimary"
              />
            </Grid>
          </Grid>
        ) : (
          <Typography
            align="right"
            className={classes.salesText}
            color="primary"
          >
            未会計
          </Typography>
        )}
      </Box>
    </>
  );
};
