import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      d="M36.66 45.25c-1.87 0-4.49-.67-8.37-2.85a53.997 53.997 0 0 1-13-9.73 49.25 49.25 0 0 1-9.77-13C2 13.29 2.6 9.91 3.28 8.46a9 9 0 0 1 3.53-3.79 17.64 17.64 0 0 1 2.71-1.45l.27-.11c1.81-.81 2.73-.18 4.29 1.36A32.68 32.68 0 0 1 19 11.8c1.2 2.58 1.52 3.59-.26 6-.12.17-.25.33-.36.49-.72.94-.81 1.12-.73 1.49a17.45 17.45 0 0 0 4.27 6.29 16.73 16.73 0 0 0 6.22 4.16c.38.09.58 0 1.54-.75l.44-.32c2.54-1.89 3.48-1.56 6.09-.25a36.24 36.24 0 0 1 7.29 4.9c1.55 1.56 2.18 2.49 1.37 4.29l-.11.26c-.4.946-.885 1.853-1.45 2.71a9 9 0 0 1-3.8 3.53 6.6 6.6 0 0 1-2.85.65M11 6.39l-.15.06c-.734.32-1.44.7-2.11 1.14a5.67 5.67 0 0 0-2.29 2.35c-.11.23-1 2.43 2.11 8a45.788 45.788 0 0 0 9.19 12.21A50 50 0 0 0 30 39.35c5.7 3.19 7.47 2.37 8 2.1a5.619 5.619 0 0 0 2.36-2.29c.46-.684.858-1.406 1.19-2.16l.06-.14-.61-.56a32.253 32.253 0 0 0-6.39-4.24 8.574 8.574 0 0 0-1.54-.7s-.37.24-.89.63l-.39.3a5.09 5.09 0 0 1-4.4 1.39c-1.8-.39-5.11-2.26-8-5.11-2.89-2.85-4.83-6.28-5.21-8a5 5 0 0 1 1.36-4.34l.33-.44a4.19 4.19 0 0 0 .57-.89 10.935 10.935 0 0 0-.66-1.58A29.548 29.548 0 0 0 11.62 7c-.27-.3-.46-.49-.62-.61"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgPhone = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgPhone);
export default Memo;
