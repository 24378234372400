import { setAvailableFeatures } from '@karutekun/shared/data-access/state/feature-flag';
import { mapBy } from '@karutekun/shared/util/objects';
import {
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  sendEmailVerification as firebaseSendEmailVerification,
  sendPasswordResetEmail,
  setPersistence,
  signInWithCustomToken,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import * as _ from 'lodash';
import { batch } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { deprecated } from 'typesafe-actions';
import { clearState } from '../actions';
import { sendRequest } from '../actions/request';
import { ApplicationError } from '../actions/requestErrors';
import { setGroupSalons, setMySalon } from '../actions/salonAction';
import { setSalonJoinRequestMap } from '../actions/salonJoinRequestAction';
import { firebaseAuth } from '../libs/firebase';
import Logger, { AnalyticsEvent } from '../logger';
import { JoinRequest } from '../models/joinRequest';
import {
  SalonMinimum,
  createMySalonFromResource,
  createSalonFromResource,
} from '../models/salon';
import { Questionnaire } from '../models/salonCustomSetting';
import { MyInfo, createMyInfoFromResource } from '../models/stylist';
import { LoginInfo } from '../models/userStatus';
import { setChatSalonId } from './view/viewChatAction';

const { createAction } = deprecated;

const MenuCategoryIconNamesMap = {
  hair: ['1101', '1102', '1103', '1104', '1105', '1106', '1107'],
  nail: ['1201', '1202', '1203', '1204', '1205'],
  eyelash: ['1301', '1302', '1303'],
  esthetic: ['1401', '1402', '1403', '1404'],
  seitai: ['1501', '1502', '1503'],
  other: [],
};

export enum ActionNames {
  ClearUser = 'user/ClearUser',
  SetUserLoginInfo = 'user/SetLoginInfo',
  SetUserMyInfo = 'user/SetUserMyInfo',
  SetUserIsJoinedSalon = 'user/SetUserIsJoinedSalon',
  SetUserRequestingSalon = 'user/SetRequestingSalon',
}

export const setUserLoginInfo = createAction(
  ActionNames.SetUserLoginInfo,
  (action) => {
    return (loginInfo: LoginInfo | null) => action({ loginInfo });
  }
);
export const setUserMyInfo = createAction(
  ActionNames.SetUserMyInfo,
  (action) => {
    return (myInfo: { id: number } & MyInfo) => action({ myInfo });
  }
);
export const setUserIsJoinedSalon = createAction(
  ActionNames.SetUserIsJoinedSalon,
  (action) => {
    return (isJoinedSalon: boolean) => action({ isJoinedSalon });
  }
);
export const setUserRequestingSalon = createAction(
  ActionNames.SetUserRequestingSalon,
  (action) => {
    return (requestingSalon: SalonMinimum | null) =>
      action({ requestingSalon });
  }
);

/**
 * ユーザーの状態をクリアする
 */
export const clearUser = createAction(ActionNames.ClearUser);

/**
 * ユーザーが所属しているサロンを取得する
 */
export function fetchUserJoinedSalon() {
  return async function (dispatch: Dispatch<Action>) {
    const loginStatus = await sendRequest(dispatch, 'info/status', {
      noCache: true,
    });
    dispatch(setUserIsJoinedSalon(loginStatus.salonId !== null));
    if (loginStatus.stylistId) {
      Logger.setUser({ id: loginStatus.stylistId });
    }
  };
}

/**
 * ユーザーが所属申請しているサロンを取得する
 */
export function fetchUserRequestingSalon() {
  return async function (dispatch: Dispatch<Action>) {
    const connectInfo = await sendRequest(dispatch, 'info/connect');
    if (connectInfo?.data?.salonId) {
      dispatch(
        setUserRequestingSalon({
          id: connectInfo.data.salonId,
          name: connectInfo.data.name,
          imageUrl: connectInfo.data.url,
        })
      );
    } else {
      dispatch(setUserRequestingSalon(null));
    }
  };
}

/**
 * 秘密の言葉からサロンを検索する
 */
export function findSalonByKeyword(findKeyword: string) {
  return async function (
    dispatch: Dispatch<Action>
  ): Promise<SalonMinimum | null> {
    const json = await sendRequest(dispatch, 'salons/show', {
      params: { findKeyword },
    });

    if (!json?.data?.salonId) {
      return null;
    }

    return {
      id: json.data.salonId,
      name: json.data.salonName,
      imageUrl: json.data.salonImageURL,
    };
  };
}

export function fetchInitInfo() {
  return async function (dispatch: Dispatch<Action>) {
    const json = await sendRequest(dispatch, 'info/init', {
      forceRefreshToken: true,
      noCache: true,
    });

    const { salon, me } = json;

    // stylistId, salonId の情報が付与された customToken での認証を行う
    await signInWithCustomToken(firebaseAuth, json.customToken);

    // 自サロンの情報
    batch(() => {
      dispatch(setMySalon(createMySalonFromResource(salon)));

      // グループサロンの情報
      dispatch(
        setGroupSalons(
          // TODO 一時的に lint を無効化しています。気づいたベースで直してください
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (json.groupSalons || []).map((s: any) => createSalonFromResource(s))
        )
      );

      // 自身の情報
      dispatch(setUserMyInfo(createMyInfoFromResource(me)));

      // 所属申請
      dispatch(
        setSalonJoinRequestMap(
          mapBy(json.joinRequests as JoinRequest[], 'stylistId')
        )
      );

      // チャットのデフォルトサロン設定
      dispatch(setChatSalonId(json.salon.id));

      // フィーチャーフラグセット
      dispatch(setAvailableFeatures(json.availableFeatures));
    });

    Logger.setUser({
      id: me.id,
      username: me.name,
      salonId: salon.id,
      salonName: salon.name,
      basicPlan: salon.basicPlan,
      optionLinePlan: salon.optionLinePlan,
    });
  };
}

export function getCustomToken(salonId: number) {
  return async function (dispatch: Dispatch<Action>) {
    const json = await sendRequest(dispatch, 'stylists/token', {
      params: { salonId },
      throwError: false,
    });

    const { customToken }: { customToken: string } = json;

    return customToken;
  };
}

export function signup(info: {
  name: string;
  email: string;
  phone: string;
  password: string;
}) {
  return async function (dispatch: Dispatch<Action>) {
    const { name, email, phone, password } = info;
    await createUserWithEmailAndPassword(firebaseAuth, email, password).catch(
      (error) => {
        let alertMessage;
        const { code } = error;
        if (code === 'auth/weak-password') {
          alertMessage =
            'パスワードが簡単すぎます。6文字以上で、英数字を交えてください。';
        } else if (code === 'auth/email-already-in-use') {
          alertMessage = 'すでにこのメールアドレスは使われています。';
        } else if (code === 'auth/invalid-email') {
          alertMessage = '不正なメールアドレスです。';
        } else {
          alertMessage = 'エラーが発生しました';
        }
        console.error('Failed to create stylist on firebase.');
        throw new ApplicationError(alertMessage);
      }
    );

    let isSuccess = await insertStylist(dispatch, name, phone);
    if (!isSuccess) {
      // ここでエラーが出るとfirebaseUserはできるが、Stylistができないので詰むので再実行。
      isSuccess = await insertStylist(dispatch, name, phone);
      if (!isSuccess) {
        // 再失敗したら、Firebaseユーザー削除しておく。
        await firebaseAuth.currentUser?.delete();
        throw new ApplicationError(
          'エラーが発生しました。再度登録してください'
        );
      }
    }

    Logger.logEvent(AnalyticsEvent.createStylist);

    await sendEmailVerification();
  };
}

async function insertStylist(
  dispatch: Dispatch<Action>,
  name: string,
  phone: string
) {
  let json: { id: number } | null = null;
  try {
    json = await sendRequest(dispatch, 'stylists', {
      method: 'POST',
      body: JSON.stringify({
        name,
        phone,
      }),
    });
  } catch (e) {
    return false;
  }

  if (json?.id) {
    Logger.setUser({ id: json.id });
  }
  return true;
}

/**
 * サロンを新規作成する
 */
export function createSalon(salonName: string, questionnaire: Questionnaire) {
  return async function (dispatch: Dispatch<Action>) {
    // 管理ボードではUI上のカテゴリ選択を一時的に無しにしておく
    const menuCategoryIconNames = _.flatten(
      questionnaire.businessCategory
        ?.split(',')
        // TODO 一時的にルールを無効化しています。気づいたベースで直してください
        // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ hair: string[]; nail: string[]; eyelash: string[]; esthetic: string[]; seitai: string[]; other: never[]; }'.
        .map((business) => MenuCategoryIconNamesMap[business]) ?? []
    );
    await sendRequest(dispatch, 'salons', {
      method: 'POST',
      body: JSON.stringify({
        menuCategoryIconNames,
        name: salonName,
        questionnaire,
      }),
    });

    dispatch(setUserIsJoinedSalon(true));

    Logger.logEvent(AnalyticsEvent.createSalon);
  };
}

/**
 * ログインする
 */
export function login(email: string, password: string) {
  return async function (): Promise<void> {
    await setPersistence(firebaseAuth, browserLocalPersistence)
      .then(async () => {
        await signInWithEmailAndPassword(firebaseAuth, email, password);
        Logger.logEvent(AnalyticsEvent.login);
      })
      .catch(() => {
        throw new ApplicationError(
          'ログインに失敗しました。メールアドレスとパスワードを確認してください'
        );
      });
  };
}

/**
 * 別のサロンに切り替える
 */
export function switchLoginSalon(salonId: number) {
  return async function (dispatch: Dispatch<Action>): Promise<void> {
    // TODO 一時的に lint を無効化しています。気づいたベースで直してください
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const customToken = await dispatch(getCustomToken(salonId) as any);
    await signInWithCustomToken(firebaseAuth, customToken).catch(() => {
      throw new ApplicationError('サロンの切り替えができませんでした');
    });

    batch(() => {
      dispatch(clearState());

      const user = firebaseAuth.currentUser;
      if (user) {
        dispatch(
          setUserLoginInfo({
            email: user.email,
            isEmailVerified: user.emailVerified,
          })
        );
        Logger.setUser({
          firebaseUid: user.uid,
          email: user.email,
        });
        Logger.logEvent(AnalyticsEvent.switchSalon);
      }
    });
  };
}

/**
 * ログアウトする
 */
export function logout() {
  return async function (dispatch: Dispatch<Action>): Promise<void> {
    dispatch(clearState(true));
    await firebaseAuth.signOut();
  };
}

/**
 * メールアドレスを変更する
 */
export function resetPassword(email: string) {
  return async function (): Promise<void> {
    await sendPasswordResetEmail(firebaseAuth, email).catch((error) => {
      const { code } = error;
      let alertMessage = '';
      if (code === 'auth/invalid-credential') {
        return;
      } else if (code === 'auth/invalid-email') {
        alertMessage = '不正なメールアドレスです。';
      } else {
        alertMessage = 'エラーが発生しました';
      }
      throw new ApplicationError(alertMessage);
    });
  };
}

/**
 * 認証メールを送信する
 */
export async function sendEmailVerification() {
  const user = firebaseAuth.currentUser;
  if (user) {
    await firebaseSendEmailVerification(user);
  }
}

/**
 * firebaseのログイン状態を更新する
 */
export function reloadFirebaseStatus() {
  return async function (
    dispatch: Dispatch<Action>
  ): Promise<LoginInfo | null> {
    await firebaseAuth.currentUser?.reload();

    const user = firebaseAuth.currentUser;
    const newLoginInfo = user
      ? {
          email: user.email,
          isEmailVerified: user.emailVerified,
        }
      : null;

    dispatch(setUserLoginInfo(newLoginInfo));

    return newLoginInfo;
  };
}
