import { ReservedRoleIds } from './data';

export function isAdminRole(permissionRoleId: number) {
  return permissionRoleId === ReservedRoleIds.Admin;
}

/**
 * 管理者の役割に関して、役割の変更における特別な制約を判定する
 */
export function isAdminRoleChangable(
  isOperatorAdmin: boolean,
  fromRoleId: number,
  toRoleId: number
) {
  return (
    // 実行者が管理者なら何でも可能
    isOperatorAdmin ||
    // 実行者が管理者でないなら、from,toが管理者以外であれば可能
    (!isAdminRole(fromRoleId) && !isAdminRole(toRoleId))
  );
}
