import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      d="M20 45.81a2.78 2.78 0 0 1-2.72-2.23.519.519 0 0 0 0-.11l-.61-4.35a16.5 16.5 0 0 1-2-1.18l-4.08 1.64a2.75 2.75 0 0 1-3.39-1.16l-4-7a2.77 2.77 0 0 1-.33-1.87 2.82 2.82 0 0 1 1-1.65l3.39-2.7v-2.37L3.78 20.1a2.77 2.77 0 0 1-.66-3.54l4-6.94a2.79 2.79 0 0 1 3.38-1.19l4.12 1.66c.64-.438 1.307-.832 2-1.18l.62-4.36a2.83 2.83 0 0 1 .94-1.69A2.74 2.74 0 0 1 20 2.19h8a2.8 2.8 0 0 1 2.72 2.23.519.519 0 0 0 0 .11l.61 4.35c.695.343 1.363.738 2 1.18l4.09-1.64a2.82 2.82 0 0 1 1.92 0 2.77 2.77 0 0 1 1.47 1.2l4 7a2.77 2.77 0 0 1-.63 3.52l-3.51 2.76V25.25l3.47 2.73a2.75 2.75 0 0 1 .67 3.54l-4 6.94a2.76 2.76 0 0 1-1.48 1.21 2.67 2.67 0 0 1-1.89 0l-4.12-1.65a18.65 18.65 0 0 1-2 1.18l-.62 4.36a2.83 2.83 0 0 1-.94 1.69 2.72 2.72 0 0 1-1.75.67zm-5.34-11.4c.12-.01.24-.01.36 0a3.33 3.33 0 0 1 1.48.59c.564.378 1.155.712 1.77 1 .484.226.904.57 1.22 1 .317.434.523.938.6 1.47l.54 3.81h6.74l.54-3.84a3.22 3.22 0 0 1 .58-1.44 3.29 3.29 0 0 1 1.21-1c.625-.287 1.227-.62 1.8-1a3.22 3.22 0 0 1 1.5-.6 3.289 3.289 0 0 1 1.58.21l3.6 1.45 3.37-5.82-3.72-2.91a1.76 1.76 0 0 1-.65-1.63c.071-.564.108-1.132.11-1.7v-1a3.2 3.2 0 0 1 1.23-2.83l3.06-2.41-3.37-5.82-3.59 1.45a3.29 3.29 0 0 1-1.58.21 3.19 3.19 0 0 1-1.54-.6 13.787 13.787 0 0 0-1.78-1 3.24 3.24 0 0 1-1.81-2.5l-.54-3.81h-6.74l-.54 3.84A3.26 3.26 0 0 1 18.3 12c-.625.287-1.227.62-1.8 1a3.25 3.25 0 0 1-3.06.35l-3.6-1.45-3.37 5.86 3.05 2.4c.432.33.776.764 1 1.26.208.5.287 1.042.23 1.58v2a3.19 3.19 0 0 1-1.22 2.84l-3.06 2.43 3.36 5.82 3.6-1.45a3.19 3.19 0 0 1 1.22-.23zM24 31.75a8.654 8.654 0 0 1-1.34-.11 7.75 7.75 0 0 1 2.09-15.35 7.74 7.74 0 0 1 7 7A7.74 7.74 0 0 1 24 31.75m0-12a4.22 4.22 0 0 0-3.83 2.43 4.23 4.23 0 0 0-.35 2.55 4.24 4.24 0 0 0 3.46 3.46 4.252 4.252 0 0 0 4.897-3.624 4.25 4.25 0 0 0-3.757-4.796z"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgCog = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgCog);
export default Memo;
