import { ServiceType } from '@karutekun/core/salon-service';
import { buildFirstCustomerStats } from '@karutekun/core/stats/customer-analytics-stats';
import { trpc } from '@karutekun/shared/data-access/api-base';
import {
  endOfMonthDate,
  startOfMonthDate,
} from '@karutekun/shared/util/datetime';
import { Card, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { StickyContainer } from 'react-sticky';
import CDivider from '../../../../components_old/atoms/CDivider';
import CInformation from '../../../../components_old/atoms/CInformation';
import CAnalyticsDisplayTaxIncludedPriceSwitcher from '../../../../components_old/molecules/CAnalyticsDisplayTaxIncludedPriceSwitcher';
import CChartBarSalesVolume from '../../../../components_old/molecules/CChartBarSalesVolume';
import { CChartBarSexAgeGroup } from '../../../../components_old/molecules/CChartBarSexAgeGroup';
import CChartGroupedService from '../../../../components_old/molecules/CChartGroupedService';
import CChartPieShimei from '../../../../components_old/molecules/CChartPieShimei';
import CChartPieStylist from '../../../../components_old/molecules/CChartPieStylist';
import CChartPieVisitMotivation from '../../../../components_old/molecules/CChartPieVisitMotivation';
import CStickyDateRangeControl from '../../../../components_old/molecules/CStickyDateRangeControl';
import { CCustomerAnalyticsStatsSummaryCard } from '../../../../components_old/organisms/CLoyaltySegmentStatsSummaryCard';
import CChartWrapper from '../../../../components_old/organisms/charts/CChartWrapper';
import { CustomerListData } from '../../../../reducers/types';
import {
  selectMySalon,
  selectStylistMap,
} from '../../../../selectors/salonSelector';
import { selectAnalyticsDisplayTaxIncludedPrice } from '../../../../selectors/settingsSelector';
import { InformationText } from '../../../../texts/infomation';
import { useSyncedQueryParams } from '../../../../util/hooks/router/useSyncedQueryParams';
import { AnalyticsCustomerSection } from '../_components/AnalyticsCustomerSection';
import { FirstCustomerTransitionCard } from './_components/FirstCustomerTransitionCard';

const useStyles = makeStyles(() => ({
  root: {
    padding: '76px 20px 20px 20px',
  },
}));

export const FirstCustomer: FC = () => {
  const classes = useStyles();

  const salon = useSelector(selectMySalon);
  const stylistMap = useSelector(selectStylistMap);
  const displayTaxIncludedPrice = useSelector(
    selectAnalyticsDisplayTaxIncludedPrice
  );

  const [{ dateRange, customerList }, setParams] = useSyncedQueryParams(
    {
      dateRange: { from: startOfMonthDate(), to: endOfMonthDate() },
      customerList: {
        page: 0,
        sortKey: undefined,
        sortOrder: undefined,
        filter: {},
      } as Pick<CustomerListData, 'page' | 'sortKey' | 'sortOrder' | 'filter'>,
    },
    true
  );

  const { isRefetching, data: aggregatedStats } =
    trpc.analytics.firstCustomerStats.aggregatedStats.useQuery(dateRange, {
      placeholderData: (prevData) =>
        prevData ?? buildFirstCustomerStats(salon.id),
    });

  const fixedCustomerFilter = useMemo(() => {
    return {
      salon: {
        salonId: salon.id,
        firstVisitedAt: { from: dateRange.from, to: dateRange.to },
      },
    };
  }, [dateRange.from, dateRange.to, salon.id]);

  if (!aggregatedStats) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Grid container justifyContent="flex-end" alignItems="center">
        <CAnalyticsDisplayTaxIncludedPriceSwitcher />
        <CInformation
          type="dialog"
          content={InformationText.firstCustomer.overall}
          size="lg"
          mb={8}
        />
      </Grid>

      <Grid
        container
        justifyContent="space-evenly"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12}>
          <FirstCustomerTransitionCard
            onChangeDate={(from, to) => setParams({ dateRange: { from, to } })}
          />
        </Grid>
      </Grid>

      <CDivider />

      <StickyContainer>
        <Grid
          container
          justifyContent="space-evenly"
          alignItems="center"
          spacing={2}
        >
          <Grid item container xs={12}>
            <Grid item xs={12}>
              <CStickyDateRangeControl
                label="期間"
                from={dateRange.from}
                to={dateRange.to}
                onChange={(from, to) => setParams({ dateRange: { from, to } })}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} sm={8} md={4} lg={4}>
            <Card>
              <CCustomerAnalyticsStatsSummaryCard
                title="初回来店人数"
                data={aggregatedStats}
                displayTaxIncludedPrice={displayTaxIncludedPrice}
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Card>
              <CChartWrapper
                title="初回来店時 支払い分布"
                isFetching={isRefetching}
                informationText={
                  InformationText.firstCustomer.budgetDistribution
                }
              >
                <CChartBarSalesVolume
                  salesMap={aggregatedStats.salesRangeMap}
                />
              </CChartWrapper>
            </Card>
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={3}>
            <Card>
              <CChartWrapper
                title="初回来店時 指名"
                isFetching={isRefetching}
                informationText={InformationText.firstCustomer.shimei}
              >
                <CChartPieShimei isShimeiMap={aggregatedStats.isShimeiMap} />
              </CChartWrapper>
            </Card>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={3}>
            <Card>
              <CChartWrapper
                title="来店動機"
                isFetching={isRefetching}
                informationText={InformationText.firstCustomer.visitMotivation}
              >
                <CChartPieVisitMotivation
                  salonVisitMotivationMap={
                    salon.customInformation.visitMotivationMap
                  }
                  visitMotivationMap={aggregatedStats.visitMotivationMap}
                />
              </CChartWrapper>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Card>
              <CChartWrapper title="男女年齢分解" isFetching={isRefetching}>
                <CChartBarSexAgeGroup
                  sexAgeGroupMap={aggregatedStats.customerSexAgeGroupMap}
                />
              </CChartWrapper>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CChartWrapper
                title="初回来店時 メニュー分析"
                isFetching={isRefetching}
                informationText={InformationText.firstCustomer.menuAnalytics}
                height={300}
              >
                <CChartGroupedService
                  chartType="pie"
                  serviceType={ServiceType.Menu}
                  formatter={(v) => `${v}回`}
                  serviceValueMap={aggregatedStats.serviceNumMap}
                />
              </CChartWrapper>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CChartWrapper
                title="初回来店時 店販分析"
                isFetching={isRefetching}
                informationText={InformationText.firstCustomer.productAnalytics}
                height={300}
              >
                <CChartGroupedService
                  chartType="pie"
                  serviceType={ServiceType.Product}
                  formatter={(v) => `${v}回`}
                  serviceValueMap={aggregatedStats.serviceNumMap}
                />
              </CChartWrapper>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CChartWrapper
                title="初回来店時 担当者"
                isFetching={isRefetching}
              >
                <CChartPieStylist
                  salonStylistMap={stylistMap}
                  stylistMap={aggregatedStats.assignedStylistMap}
                />
              </CChartWrapper>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <AnalyticsCustomerSection
              title="初回来店客一覧"
              listData={customerList}
              fixedFilter={fixedCustomerFilter}
              onChangeListData={(customerList) => setParams({ customerList })}
            />
          </Grid>
        </Grid>
      </StickyContainer>
    </div>
  );
};
