import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';

type OwnProps = {
  className?: string;
};

const useStyles = makeStyles((_: Theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const CRow: FC<OwnProps> = React.memo(function CRow(props) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.row, props.className)}>{props.children}</div>
  );
});

export default CRow;
