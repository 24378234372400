import { InputBaseComponentProps, TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';
import {
  CSSProperties,
  ChangeEvent,
  CompositionEvent,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import NumberFormat, {
  NumberFormatProps,
  NumberFormatValues,
} from 'react-number-format';

const maxAmount = 99999999;

type OwnProps = TextFieldProps & {
  allowNegative?: boolean;
  clearZeroOnFocus?: boolean;
  onChangeAmount(amount: number | null): void;
};

type NumberFormatCustomProps = NumberFormatProps & {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { value: string } }) => void;
};

const NumberFormatCustom: FC<NumberFormatCustomProps> = forwardRef(
  (props, ref) => {
    const { inputRef, onChange, allowNegative, ...other } = props;

    const handleValueChange = useCallback(
      (values: NumberFormatValues) => {
        if (onChange) {
          onChange({
            target: {
              value: values.floatValue?.toString() ?? '',
            },
          });
        }
      },
      [onChange]
    );

    return (
      <NumberFormat
        {...other}
        allowNegative={allowNegative}
        getInputRef={ref}
        customInput={CompositionInput}
        onValueChange={handleValueChange}
        thousandSeparator
        prefix="¥"
      />
    );
  }
);

/**
 * 日本語入力が完了したときに始めて onChange を発火させる input コンポーネント
 *
 * 入力に対して随時フォーマットをかける react-number-format を使っていると
 * 日本語入力時に意図せぬ挙動が発生してしまう。
 * 解決として、日本語入力が完了したときに始めて onChange を発火させ
 * 入力が完了した文字列に対してフォーマットをかけるようにしている。
 *
 * こちらのライブラリを参考に実装
 * https://github.com/LeoEatle/react-composition-input
 */
const CompositionInput = forwardRef<HTMLInputElement, InputBaseComponentProps>(
  (props, ref) => {
    const { onChange } = props;
    const isOnComposition = useRef(false);
    const emittedEvent = useRef(false);

    const [value, setValue] = useState(props.value);

    useEffect(() => {
      setValue(props.value);
    }, [props.value]);

    const handleChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setValue(val);

        if (!isOnComposition.current) {
          if (onChange) {
            onChange(e);
          }
          emittedEvent.current = true;
        } else {
          emittedEvent.current = false;
        }
      },
      [onChange]
    );

    const handleComposition = useCallback(
      (e: CompositionEvent<HTMLInputElement>) => {
        if (e.type === 'compositionstart') {
          isOnComposition.current = true;
        } else if (e.type === 'compositionend') {
          isOnComposition.current = false;
          if (!emittedEvent.current) {
            handleChange(e as unknown as ChangeEvent<HTMLInputElement>);
          }
        }
      },
      [handleChange]
    );

    return (
      <input
        ref={ref}
        type="text"
        {...props}
        value={value}
        onChange={handleChange}
        onCompositionStart={handleComposition}
        onCompositionEnd={handleComposition}
      />
    );
  }
);

const CMoneyInput: FC<OwnProps> = (props) => {
  const {
    clearZeroOnFocus,
    allowNegative = true,
    value,
    onChangeAmount,
    ...other
  } = props;
  const [isFocused, setIsFocused] = useState(false);

  const inputStyle: CSSProperties = { textAlign: 'right' };
  if (value !== undefined && Number(props.value) < 0) {
    inputStyle.color = 'red';
  }
  const visibleValue =
    clearZeroOnFocus && value === 0 && isFocused ? '' : value;

  return (
    <TextField
      {...other}
      variant="standard"
      value={visibleValue}
      onChange={(e) => {
        const val = e.target.value;
        if (val === '') {
          onChangeAmount(null);
        } else {
          const num = Math.floor(
            Math.min(parseInt(e.target.value, 10), maxAmount)
          );
          const amount = isNaN(num) ? 0 : num;
          onChangeAmount(amount);
        }
      }}
      placeholder="¥1,000"
      InputProps={{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        inputComponent: NumberFormatCustom as any,
        inputProps: {
          allowNegative,
          style: inputStyle,
        },
      }}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    />
  );
};
export default CMoneyInput;
