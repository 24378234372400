import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

function parse(v: string) {
  try {
    return JSON.parse(v);
  } catch {
    return v;
  }
}

function serialize(v: unknown) {
  return typeof v === 'object' ? JSON.stringify(v) : `${v}`;
}

export function useSyncedQueryParams<T extends Record<string, unknown>>(
  _defaultValue: T,
  navigateByReplace?: boolean
): [T, (params: Partial<T>) => void, URLSearchParams] {
  const [defaultValue] = useState(_defaultValue);
  const [searchParams, setSearchParams] = useSearchParams();

  const params = useMemo(
    () => ({
      ...defaultValue,
      ...[...searchParams.entries()].reduce((acc, [key, value]) => {
        // TODO 一時的にルールを無効化しています。気づいたベースで直してください
        // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
        acc[key] = parse(value);
        return acc;
      }, {}),
    }),
    [defaultValue, searchParams]
  );

  const setParams = useCallback(
    (updates: Partial<T>) => {
      setSearchParams(
        Object.entries({ ...params, ...updates })
          // デフォルト値と等しいパラメータはクエリパラメータに含めない
          // 一部のパラメータのみ変更し、ほかはデフォルト値を使ってページを表示したい場合などに対応するため
          .filter(([key, value]) => !_.isEqual(defaultValue[key], value))
          .map(([key, value]) => [key, serialize(value)])
          .reduce((acc, [key, value]) => {
            // TODO 一時的にルールを無効化しています。気づいたベースで直してください
            // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
            acc[key] = value;
            return acc;
          }, {}),
        { replace: navigateByReplace }
      );
    },
    [defaultValue, navigateByReplace, params, setSearchParams]
  );

  return [params, setParams, searchParams];
}
