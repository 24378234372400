import { ActionType, getType } from 'typesafe-actions';
import * as actions from '../actions/salonJoinRequestAction';
import { JoinRequest } from '../models/joinRequest';

export type SalonJoinRequestActions = ActionType<typeof actions>;

export interface SalonJoinRequestState {
  joinRequestMap: IdMap<JoinRequest>;
}

const initialState: SalonJoinRequestState = {
  joinRequestMap: {},
};

function salonJoinRequestReducer(
  state: SalonJoinRequestState = initialState,
  action: SalonJoinRequestActions
): SalonJoinRequestState {
  switch (action.type) {
    case getType(actions.setSalonJoinRequestMap): {
      const { joinRequestMap } = action.payload;
      return { joinRequestMap };
    }
    case getType(actions.setSalonJoinRequest): {
      const { stylistId, joinRequest } = action.payload;
      return {
        ...state,
        joinRequestMap: {
          ...state.joinRequestMap,
          [stylistId]: {
            // TODO 一時的に lint を無効化しています。気づいたベースで直してください
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            ...state.joinRequestMap[stylistId]!,
            ...joinRequest,
          },
        },
      };
    }
    default:
      return state;
  }
}

export default salonJoinRequestReducer;
