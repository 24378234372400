import CStylistSelect from '../../../../../components_old/molecules/CStylistSelect';
import { useSelectStylists } from '../../../../../selectors/salonSelector';

type Props = {
  salonId: number;
  disabled?: boolean;
} & (
  | {
      allowEmpty: true;
      assignedStylistId: number | null;
      onChange(assignedStylistId: number | null): void;
    }
  | {
      allowEmpty?: false;
      assignedStylistId: number;
      onChange(assignedStylistId: number): void;
    }
);

export const VisitFormAssignedStylist: FC<Props> = ({
  salonId,
  assignedStylistId,
  disabled,
  allowEmpty,
  onChange,
}) => {
  const stylists = useSelectStylists(salonId, {
    includeStylistId: assignedStylistId,
  });
  return (
    <CStylistSelect
      label="担当者"
      selected={assignedStylistId ?? undefined}
      stylists={stylists}
      // @ts-expect-error allowEmptyによってonChangeの肩が変わる
      onChange={(stylistId) => onChange(stylistId ?? null)}
      disabled={disabled}
      emptyString="指定なし"
      displayEmpty={allowEmpty}
    />
  );
};
