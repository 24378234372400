import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M27 24.25c5.08 0 9.56-4.76 10-10.62a10.17 10.17 0 0 0-2.61-7.75A10 10 0 0 0 27 2.75a10 10 0 0 0-7.4 3.12 10.16 10.16 0 0 0-2.6 7.76c.43 5.85 4.92 10.62 10 10.62m-4.84-16a6.81 6.81 0 0 1 9.66 0 6.63 6.63 0 0 1 1.68 5.1c-.29 4-3.27 7.38-6.5 7.38s-6.21-3.38-6.5-7.38a6.662 6.662 0 0 1 1.66-5.1M46.69 41.18C45 31.55 35.65 26.75 27 26.75c-8.65 0-17.95 4.8-19.69 14.43a3.63 3.63 0 0 0 .54 2.69 3.16 3.16 0 0 0 2.65 1.38h33a3.15 3.15 0 0 0 2.65-1.38 3.63 3.63 0 0 0 .54-2.69m-35.92.57c1.35-7.32 8.59-11.5 16.23-11.5 7.64 0 14.88 4.18 16.23 11.5zM10 27v-3.5h3.5a1.75 1.75 0 0 0 0-3.5H10v-3.5a1.75 1.75 0 0 0-3.5 0V20H3a1.75 1.75 0 0 0 0 3.5h3.5V27a1.75 1.75 0 0 0 3.5 0"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgUserPlus = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgUserPlus);
export default Memo;
