import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  setIsMaintenance,
  setShouldForceLogout,
  setStylistCanAccess,
} from './redux-slice';
import {
  TrpcClientBuildOptions,
  createTrpcClient,
  isTRPCError,
  trpc,
} from './trpc-client';

type Props = {
  trpcClientOptions: TrpcClientBuildOptions;
  reactQueryOptions?: {
    onMutationError?: (e: unknown) => void;
  };
};

const MaxRetryCount = 3;

/**
 * リクエストをリトライする必要があるかどうかを判定する
 * 特集なエラーレスポンス(メンテナンスなど)の場合はリトライする必要がない
 */
function shouldRetryRequestDefault(failureCount: number, e: unknown) {
  if (failureCount >= MaxRetryCount - 1) {
    return false;
  }

  if (!isTRPCError(e)) {
    return true;
  }

  const isNotFound = e.data?.httpStatus === 404;
  const isBadRequest = e.data?.httpStatus === 400;
  const isConflict = e.data?.httpStatus === 409;
  const isMaintenance = e.data?.specialResponse === 'MAINTENANCE';
  const isStylistCannotAccess =
    e.data?.specialResponse === 'STYLIST_CANNOT_ACCESS';
  const isForceLogout = e.data?.specialResponse === 'FORCE_LOGOUT';

  const noRetry =
    isNotFound ||
    isBadRequest ||
    isConflict ||
    isMaintenance ||
    isStylistCannotAccess ||
    isForceLogout;

  return !noRetry;
}

/**
 * アプリでは react-native-navigation を利用している都合上、
 * スクリーン毎に Provider が初期化されてしまい、
 * Provider の中で毎回 QueryClient を生成するとキャッシュがばらばらになってしまう。
 * グローバルで一つの QueryClient を生成して、それを利用するようにしている。
 * (例えば管理ボードなど、アプリケーションのコンポーネントツリーに Provider が一つのみの場合は、不要な配慮)
 *
 * TODO
 * 本来 queryClient は useQueryClient で取得するべきだが、
 * サロン切り替えの実装が古いせいでコンポーネントの外(hooksが使えない箇所)で
 * 利用する必要があるため仕方なく export している
 */
export let globalQueryClient: QueryClient | null = null;

export const ApiBaseProvider: FC<Props> = ({
  trpcClientOptions,
  reactQueryOptions: { onMutationError } = {},
  children,
}) => {
  const dispatch = useDispatch();

  const [queryClient] = useState(() => {
    if (!globalQueryClient) {
      globalQueryClient = new QueryClient({
        queryCache: new QueryCache({
          onError: (e) => {
            if (isTRPCError(e)) {
              if (e.data?.type === 'MAINTENANCE') {
                dispatch(setIsMaintenance(true));
              } else if (e.data?.type === 'STYLIST_CANNOT_ACCESS') {
                dispatch(setStylistCanAccess(false));
              } else if (e.data?.type === 'FORCE_LOGOUT') {
                dispatch(setShouldForceLogout(true));
              }
            }
          },
        }),
        defaultOptions: {
          queries: { retry: shouldRetryRequestDefault },
          mutations: {
            retry: shouldRetryRequestDefault,
            onError: onMutationError,
          },
        },
      });
    }
    return globalQueryClient;
  });
  const [trpcClient] = useState(() => createTrpcClient(trpcClientOptions));

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  );
};
