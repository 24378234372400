import { connect } from 'react-redux';
import { fetchCustomers } from '../../actions/customerAction';
import { setViewAutoCompleteCustomerData } from '../../actions/view/viewAutoCompleteCustomerAction';
import { selectCustomer } from '../../selectors/customerSelector';
import { GlobalState, ThunkDispatch } from '../../store';
import AutoCompleteCustomerInput, {
  DispatchProps,
  StateProps,
} from '../../templates/components/AutoCompleteCustomerInput';
import { notEmpty } from '../../util/common';

const maxNum = 10;

const mapStateToProps = (state: GlobalState): StateProps => {
  const data = state.view.autoCompleteCustomer;
  return {
    isFetching: data.isFetching,
    hasMore: data.hasMore,
    customers: data.customerIds
      .map((id) => selectCustomer(state, id))
      .filter(notEmpty),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => {
  return {
    fetchCustomers(name: string) {
      dispatch(setViewAutoCompleteCustomerData({ isFetching: true }));

      // 次回来店が近い順で取得
      const { controller, request } = dispatch(
        fetchCustomers(
          { name, offset: 0, limit: maxNum },
          'recentNextVisit',
          'desc'
        )
      );

      request.then((data) => {
        if (data !== null) {
          const { count, customers } = data;
          dispatch(
            setViewAutoCompleteCustomerData({
              customerIds: customers.map((c) => c.id),
              hasMore: count > maxNum,
              isFetching: false,
            })
          );
        }
      });

      return controller;
    },

    clearCustomers() {
      dispatch(
        setViewAutoCompleteCustomerData({
          customerIds: [],
          isFetching: false,
          hasMore: false,
        })
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AutoCompleteCustomerInput);
