import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import {
  Box,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useSelector } from 'react-redux';
import { dispatchWithErrorHandling } from '../../../../../actions/helper/dispatchWithErrorHandling';
import {
  deleteStylistShift,
  fetchStylists,
  updateStylistShift,
} from '../../../../../actions/salon/stylistsSettingsAction';
import CButton from '../../../../../components_old/atoms/CButton';
import CHorizontalScrollHintContainer from '../../../../../components_old/atoms/CHorizontalScrollHintContainer';
import CProgressOverlay from '../../../../../components_old/atoms/CProgressOverlay';
import CStylistAvatar from '../../../../../components_old/atoms/CStylistAvatar';
import CTypographyIcon from '../../../../../components_old/atoms/CTypographyIcon';
import {
  OverwriteOption,
  PlainWorkShift,
  WorkType,
} from '../../../../../models/salonScheduleSettings';
import {
  MySalonStylist,
  PlainStylist,
  PlainStylistShiftSetting,
  StylistShiftSetting,
  checkPermission,
  emptyStylistShiftSetting,
} from '../../../../../models/stylist';
import {
  selectActiveStylists,
  useSelectMe,
} from '../../../../../selectors/salonSelector';
import {
  WithConfirmDialog,
  withConfirmDialog,
} from '../../../../../templates/hoc/ConfirmDialogHOC';
import { DateType, DateTypeNameMap, DateTypes } from '../../../../../util/date';
import { useFlag } from '../../../../../util/hooks/useFlag';
import { useThunkDispatch } from '../../../../../util/hooks/useThunkDispatch';
import { OverwriteConfirmationDialog } from '../BusinessHourSettingCard/OverwriteConfirmationDialog';
import { ScheduleCapacitySelect } from '../ScheduleCapacitySettingCard/ScheduleCapacitySelect';
import { ScheduleShiftPatternSelect } from './ScheduleShiftPatternSelect';

type Props = WithConfirmDialog;

const useStyles = makeStyles((_: Theme) => ({
  card: {
    position: 'relative',
  },
  shiftContainer: {
    display: 'flex',
    fledDirection: 'row',
  },
  shiftDayCell: {
    textAlign: 'center',
    minWidth: 70,
    width: 70,
  },
}));

const StylistShiftSettingCard: FC<Props> = (props) => {
  const classes = useStyles();
  const me = useSelectMe();
  const stylists = useSelector(selectActiveStylists);
  const dispatch = useThunkDispatch();

  const { openConfirmDialog, closeConfirmDialog } = props;

  const hasPermission = checkPermission(me, 'canUpdateScheduleSetting');

  const [isFetching, callWithFetching] = useFlag(false);

  useEffect(() => {
    callWithFetching(async () => await dispatch(fetchStylists()));
  }, [callWithFetching, dispatch]);

  const saveStylistShift = async (
    stylistId: number,
    shiftSetting: PlainStylistShiftSetting,
    option: OverwriteOption
  ) => {
    await dispatchWithErrorHandling(
      dispatch,
      updateStylistShift(stylistId, shiftSetting, option),
      { success: 'スタッフのシフト設定を保存しました' }
    );
  };

  const handleDelete = useCallback(
    (stylistId: number) => {
      openConfirmDialog({
        description:
          '該当スタッフの設定済みのシフト表も同時に削除されます。\n本当にスタッフのシフト設定を削除しますか？',
        onOk: () => {
          closeConfirmDialog();
          callWithFetching(
            async () =>
              await dispatchWithErrorHandling(
                dispatch,
                deleteStylistShift(stylistId),
                {
                  success: 'スタッフのシフト設定を削除しました',
                }
              )
          );
        },
      });
    },
    [callWithFetching, closeConfirmDialog, dispatch, openConfirmDialog]
  );

  return (
    <Card className={classes.card}>
      {isFetching && <CProgressOverlay />}
      <CHorizontalScrollHintContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">スタッフ</TableCell>
              <TableCell align="left">同時受付可能数</TableCell>
              <TableCell align="center">基本シフト</TableCell>
              <TableCell align="center">編集</TableCell>
              <TableCell align="center">削除</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {stylists.map((s) => (
              <StylistRow
                key={s.id}
                stylist={s}
                disabled={!hasPermission}
                onEdit={saveStylistShift}
                onDelete={handleDelete}
              />
            ))}
          </TableBody>
        </Table>
      </CHorizontalScrollHintContainer>
    </Card>
  );
};

const StylistRow: FC<{
  stylist: MySalonStylist;
  disabled?: boolean;
  onEdit(
    stylistId: number,
    shiftSetting: PlainStylistShiftSetting,
    option: OverwriteOption
  ): void;
  onDelete(stylistId: number): void;
}> = React.memo(function StylistRow(props) {
  const { stylist, disabled, onEdit, onDelete } = props;

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const handleSave = useCallback(
    (
      stylistId: number,
      shiftSetting: PlainStylistShiftSetting,
      option: OverwriteOption
    ) => {
      setIsEditDialogOpen(false);
      onEdit(stylistId, shiftSetting, option);
    },
    [onEdit]
  );
  const handleDelete = useCallback(() => {
    onDelete(stylist.id);
  }, [onDelete, stylist.id]);

  return (
    <TableRow>
      <TableCell align="left">
        <Grid container spacing={2} alignItems="center" wrap="nowrap">
          <Grid item>
            <CStylistAvatar size={32} stylist={stylist} />
          </Grid>
          <Grid item>
            <Typography variant="body1" noWrap>
              {stylist.name}
            </Typography>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align="left">
        {stylist.basicShiftSetting ? (
          <CTypographyIcon
            icon={<SVGIcon name="user-friends" size="sm" />}
            variant="body1"
          >
            {stylist.basicShiftSetting.capacity}
          </CTypographyIcon>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell align="center">
        {stylist.basicShiftSetting ? (
          <Shift setting={stylist.basicShiftSetting} />
        ) : (
          '未設定'
        )}
      </TableCell>
      <TableCell align="center">
        <IconButton
          onClick={() => setIsEditDialogOpen(true)}
          disabled={disabled}
          size="large"
        >
          <SVGIcon name="pen" />
        </IconButton>
        {!disabled && (
          <AddEditShiftDialog
            open={isEditDialogOpen}
            stylist={stylist}
            onClose={() => setIsEditDialogOpen(false)}
            onSave={handleSave}
          />
        )}
      </TableCell>
      <TableCell align="center">
        <IconButton
          onClick={handleDelete}
          disabled={disabled || !stylist.basicShiftSetting}
          size="large"
        >
          <SVGIcon name="delete" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
});

const AddEditShiftDialog: FC<{
  open: boolean;
  stylist: PlainStylist;
  onClose(): void;
  onSave(
    stylistId: number,
    shiftSetting: PlainStylistShiftSetting,
    option: OverwriteOption
  ): void;
}> = React.memo(function AddEditStylistDialog(props) {
  const classes = useStyles();
  const { open, stylist, onClose, onSave } = props;

  const hasShiftSetting = stylist.basicShiftSetting !== null;
  const [shiftSetting, setShiftSetting] = useState<PlainStylistShiftSetting>(
    stylist.basicShiftSetting ?? emptyStylistShiftSetting()
  );
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const isUpdated = useMemo(
    () => !_.isEqual(shiftSetting, stylist.basicShiftSetting),
    [shiftSetting, stylist.basicShiftSetting]
  );

  useEffect(() => {
    if (open) {
      setShiftSetting(stylist.basicShiftSetting ?? emptyStylistShiftSetting());
    }
  }, [open, stylist.basicShiftSetting]);

  const handleUpdate = useCallback((setting: PlainStylistShiftSetting) => {
    setShiftSetting(setting);
  }, []);

  const handleSave = useCallback(() => {
    if (hasShiftSetting) {
      setIsConfirmDialogOpen(true);
    } else {
      onSave(stylist.id, shiftSetting, { overwrite: false });
    }
  }, [hasShiftSetting, onSave, shiftSetting, stylist.id]);

  const handleConfirmOk = useCallback(
    (option: OverwriteOption) => {
      setIsConfirmDialogOpen(false);
      onSave(stylist.id, shiftSetting, option);
    },
    [onSave, shiftSetting, stylist.id]
  );

  const handleShiftUpdate = (
    dateType: DateType,
    dailyShift: PlainWorkShift
  ) => {
    handleUpdate({
      ...shiftSetting,
      shift: {
        ...shiftSetting.shift,
        [dateType]: dailyShift,
      },
    });
  };

  return (
    <>
      <OverwriteConfirmationDialog
        open={isConfirmDialogOpen}
        description="設定済みのシフト表を同時に更新しますか？"
        onOk={handleConfirmOk}
        onCancel={() => setIsConfirmDialogOpen(false)}
      />
      <Dialog open={open} onClose={onClose}>
        <ValidatorForm instantValidate={false} onSubmit={handleSave}>
          <DialogTitle>{stylist.name} の設定</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item>
                <InputLabel shrink>同時受付可能数</InputLabel>
                <ScheduleCapacitySelect
                  capacity={shiftSetting.capacity}
                  onChange={(capacity) =>
                    handleUpdate({
                      ...shiftSetting,
                      capacity,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel shrink>基本シフト</InputLabel>
                <Box mt={2} mb={2}>
                  <div className={classes.shiftContainer}>
                    {DateTypes.map((dateType) => {
                      return (
                        <div key={dateType} className={classes.shiftDayCell}>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            align="center"
                          >
                            {DateTypeNameMap[dateType]}
                          </Typography>
                        </div>
                      );
                    })}
                  </div>
                  <div className={classes.shiftContainer}>
                    {DateTypes.map((dateType) => {
                      return (
                        <div key={dateType} className={classes.shiftDayCell}>
                          <ScheduleShiftPatternSelect
                            workShift={shiftSetting.shift[dateType]}
                            onChange={(dailyShift) =>
                              handleShiftUpdate(dateType, dailyShift)
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <CButton variant="outlined" onClick={onClose}>
              キャンセル
            </CButton>
            <CButton type="submit" disabled={!isUpdated}>
              保存
            </CButton>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    </>
  );
});

const Shift: FC<{
  setting: StylistShiftSetting;
}> = React.memo(function Shift(props) {
  const { setting } = props;
  return (
    <>
      <Grid container spacing={1} wrap="nowrap">
        {DateTypes.map((dateType) => {
          return (
            <Grid item xs key={dateType}>
              <Typography variant="body2" color="textSecondary" align="center">
                {DateTypeNameMap[dateType]}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
      <Grid container spacing={1} wrap="nowrap">
        {DateTypes.map((dateType) => {
          const shift = setting.shift[dateType];
          return (
            <Grid item xs key={dateType}>
              <Typography
                variant="body1"
                align="center"
                color={
                  shift.workType === WorkType.FullTime
                    ? 'textPrimary'
                    : shift.workType === WorkType.DayOff
                      ? 'textSecondary'
                      : 'primary'
                }
              >
                {shift.workType === WorkType.FullTime
                  ? '出'
                  : shift.workType === WorkType.DayOff
                    ? '休'
                    : shift.shiftPattern?.titleShort || '?'}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
});

export default withConfirmDialog(StylistShiftSettingCard);
