import { SalonCustomerLineState } from '@karutekun/core/customer';
import { v4 as uuid } from 'uuid';
import { CustomerFilter } from '../actions/customerAction';
import { PlainStylist } from './stylist';

export enum PublishScheduleType {
  Scheduled = 0,
  Immediate = 1,
}

export enum State {
  Draft = 0,
  Reserved = 10,
  AudienceCreated = 20,
  Published = 30,
  Completed = 80,
  Error = 90,
}

export type LineNarrowcastResult = {
  targetCount: string;
  successCount: number;
  failureCount: number;
  failedDescription?: string;
  errorCode?: number;
};

export type PlainBroadcastMessage = {
  id: string; // uuid
  salonId: number;
  name: string;
  message: string;
  customerFilter: CustomerFilter;
  publishScheduleType: PublishScheduleType;
  publishScheduledAt: number | null;
  state: State;
  editedAt: number;
  editedStylistId: number;
  recipientCustomerNum: number;
  publishedAt: number | null;
  lineNarrowcastResult: LineNarrowcastResult | null;
  createdAt: number;
};

export type BroadcastMessage = PlainBroadcastMessage & {
  editedStylist: PlainStylist | null;
};

export function emptyBroadcastMessage(
  overwrite: Partial<PlainBroadcastMessage> = {}
): PlainBroadcastMessage {
  return {
    id: overwrite.id ?? '',
    salonId: overwrite.salonId ?? 0,
    name: overwrite.name ?? '',
    message: overwrite.name ?? '',
    customerFilter: overwrite.customerFilter ?? {},
    publishScheduleType:
      overwrite.publishScheduleType ?? PublishScheduleType.Immediate,
    publishScheduledAt: overwrite.publishScheduledAt ?? null,
    state: overwrite.state ?? State.Draft,
    editedAt: overwrite.editedAt ?? 0,
    editedStylistId: overwrite.editedStylistId ?? 0,
    recipientCustomerNum: overwrite.recipientCustomerNum ?? 0,
    publishedAt: overwrite.publishedAt ?? null,
    lineNarrowcastResult: overwrite.lineNarrowcastResult ?? null,
    createdAt: overwrite.createdAt ?? 0,
    ...overwrite,
  };
}

export function plainBroadcastMessageFromResource(
  // TODO 一時的に lint を無効化しています。気づいたベースで直してください
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  res: any
): PlainBroadcastMessage {
  return {
    ...emptyBroadcastMessage(res),
    editedStylistId: res.editedStylist ? res.editedStylist.id : null,
  };
}

export function createInitialBroadcastMessage(
  salonId: number
): PlainBroadcastMessage {
  return emptyBroadcastMessage({
    id: uuid(),
    customerFilter: {
      salon: {
        salonId,
        lineStates: [SalonCustomerLineState.Friend],
      },
    },
  });
}
