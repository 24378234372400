import { ServiceType, ServiceTypeName } from '@karutekun/core/salon-service';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import CButton from '../../../../components_old/atoms/CButton';
import CDivider from '../../../../components_old/atoms/CDivider';
import CMenuButton from '../../../../components_old/atoms/CMenuButton';
import CMoneyInput from '../../../../components_old/atoms/CMoneyInput';
import CRadioGroup from '../../../../components_old/atoms/CRadioGroup';
import CSelect from '../../../../components_old/atoms/CSelect';
import {
  PlainService,
  Service,
  ServiceCategory,
  emptyPlainService,
} from '../../../../models/service';
import {
  WithConfirmDialog,
  withConfirmDialog,
} from '../../../../templates/hoc/ConfirmDialogHOC';

type Props = {
  open: boolean;
  categoriesByType: { [type in ServiceType]: ServiceCategory[] };
  category?: ServiceCategory;
  service?: Service;
  disabled?: boolean;
  onClose(): void;
  onSave(service: PlainService, next?: boolean): Promise<void>;
  onDelete(serviceId: number): void;
} & WithConfirmDialog;

const AddEditServiceDialog: FC<Props> = React.memo(
  function AddEditServiceDialog(props) {
    const {
      open,
      categoriesByType,
      category,
      disabled,
      onClose,
      onSave,
      onDelete,
      openConfirmDialog,
      closeConfirmDialog,
    } = props;

    const [service, setService] = useState(
      emptyPlainService({
        type: category?.type,
        categoryId: category?.id,
        ...props.service,
      })
    );
    // 編集時の空欄を許容したいので、 price と time は別管理にしている
    const [price, setPrice] = useState<number | null>(
      props.service ? props.service.price : null
    );
    const [time, setTime] = useState<number | null>(
      props.service ? props.service.time : null
    );

    useEffect(() => {
      if (open) {
        setService(
          emptyPlainService({
            type: category?.type,
            categoryId: category?.id,
            ...props.service,
          })
        );
        setPrice(props.service ? props.service.price : null);
        setTime(props.service ? props.service.time : null);
      }
    }, [category, open, props.service]);

    const handleSubmit = useCallback(() => {
      if (
        price !== null &&
        (service.type !== ServiceType.Menu || time !== null)
      ) {
        onSave({ ...service, price, time: time ?? 0 });
      }
    }, [onSave, price, service, time]);

    const handleSaveAndNext = useCallback(() => {
      if (
        price !== null &&
        (service.type !== ServiceType.Menu || time !== null)
      ) {
        onSave({ ...service, price, time: time ?? 0 }, true).then(() => {
          setService(
            emptyPlainService({
              type: category?.type,
              categoryId: category?.id,
            })
          );
        });
      }
    }, [category, onSave, price, service, time]);

    const handleDelete = useCallback(() => {
      openConfirmDialog({
        title: '確認',
        description: `本当に${ServiceTypeName[service.type]}を削除しますか？`,
        onOk: () => {
          closeConfirmDialog();
          onDelete(service.id);
        },
      });
    }, [
      closeConfirmDialog,
      onDelete,
      openConfirmDialog,
      service.id,
      service.type,
    ]);

    const isEditMode = props.service !== undefined;
    const formId = 'add-edit-service-dialog';
    const isValid =
      service.name.length > 0 &&
      price !== null &&
      (service.type !== ServiceType.Menu || time !== null);

    return (
      <Dialog open={open} fullWidth maxWidth="xs" onClose={onClose}>
        <DialogTitle>
          {ServiceTypeName[service.type]}の{isEditMode ? '編集' : '追加'}
        </DialogTitle>
        <DialogContent>
          <ValidatorForm
            id={formId}
            instantValidate={false}
            onSubmit={handleSubmit}
          >
            <Grid container spacing={2}>
              {isEditMode && (
                <Grid item xs={12}>
                  <CSelect
                    fullWidth
                    label="カテゴリ"
                    options={categoriesByType[service.type]
                      .filter((c) => c.isActive)
                      .map((c) => ({ value: c.id, element: c.name }))}
                    value={service.categoryId}
                    onChange={(id) => {
                      if (id) {
                        setService({ ...service, categoryId: id });
                      }
                    }}
                    disabled={disabled || !isEditMode}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <TextValidator
                  variant="standard"
                  label="名前"
                  fullWidth
                  value={service.name}
                  inputProps={{ maxLength: 50 }}
                  onChange={(e) =>
                    setService({ ...service, name: e.target.value })
                  }
                  validators={['required']}
                  errorMessages={['名前は必須です']}
                  autoFocus
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12}>
                <CMoneyInput
                  label="価格"
                  allowNegative={false}
                  value={price ?? ''}
                  onChangeAmount={setPrice}
                  disabled={disabled}
                />
              </Grid>
              {service.type === ServiceType.Menu && (
                <Grid item xs={12}>
                  <TextValidator
                    variant="standard"
                    label="時間(分)"
                    value={time ?? ''}
                    type="number"
                    inputProps={{ maxLength: 50 }}
                    onChange={(e) => {
                      const v = e.target.value.trim();
                      setTime(v === '' ? null : Number(v));
                    }}
                    validators={['required', 'isNumber', 'minNumber:0']}
                    errorMessages={[
                      '時間は必須です',
                      '正の整数を入力してください',
                      '正の整数を入力してください',
                    ]}
                    disabled={disabled}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <CRadioGroup
                  label="表示"
                  currentValue={service.isVisible ? 1 : 0}
                  options={[
                    { value: 1, label: '表示' },
                    { value: 0, label: '非表示' },
                  ]}
                  onChange={(value) =>
                    setService({ ...service, isVisible: Boolean(value) })
                  }
                  row
                  disabled={disabled}
                />
              </Grid>
            </Grid>
            <CDivider spacing={2} />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CRadioGroup
                  label="消費税"
                  currentValue={service.isTaxIncluded ? 1 : 0}
                  options={[
                    { value: 1, label: '内税' },
                    { value: 0, label: '外税' },
                  ]}
                  onChange={(value) =>
                    setService({ ...service, isTaxIncluded: Boolean(value) })
                  }
                  row
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12}>
                <CRadioGroup
                  label="税率"
                  currentValue={service.taxRate}
                  options={[
                    { value: 10, label: '標準税率(10%)' },
                    { value: 8, label: '軽減税率(8%)' },
                    { value: 0, label: '非課税' },
                  ]}
                  onChange={(taxRate) => setService({ ...service, taxRate })}
                  row
                  disabled={disabled}
                />
              </Grid>
            </Grid>
          </ValidatorForm>
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid item xs>
              <CButton variant="outlined" onClick={onClose}>
                キャンセル
              </CButton>
            </Grid>
            <Grid item>
              {isEditMode ? (
                <Grid container spacing={2}>
                  <Grid item>
                    <CMenuButton
                      disabled={disabled}
                      variant="text"
                      menus={[
                        {
                          title: `この${
                            ServiceTypeName[service.type]
                          }を削除する`,
                          onClick: handleDelete,
                        },
                      ]}
                    >
                      その他
                    </CMenuButton>
                  </Grid>
                  <Grid item>
                    <CButton
                      disabled={disabled || !isValid}
                      form={formId}
                      type="submit"
                    >
                      保存
                    </CButton>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={2}>
                  <Grid item>
                    <CButton
                      disabled={disabled || !isValid}
                      type="submit"
                      form={formId}
                    >
                      作成
                    </CButton>
                  </Grid>
                  <Grid item>
                    <CButton
                      disabled={disabled || !isValid}
                      onClick={handleSaveAndNext}
                    >
                      続けて作成
                    </CButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  }
);

export default withConfirmDialog(AddEditServiceDialog);
