import { VisitResource } from '@karutekun/core/visit';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export const VisitReducerName = 'visit';

export const visitSlice = createSlice({
  name: VisitReducerName,
  initialState: {},
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setVisits: (state, { payload }: PayloadAction<VisitResource[]>) => {
      // visit 自体は redux 内で状態管理しない
      // リレーション(voucher) の reducer でそれぞれ VisitResource を参照して
      // それぞれの状態を更新している
    },
  },
});
export const { setVisits } = visitSlice.actions;
export const visitReducer = visitSlice.reducer;
