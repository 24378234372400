import historykana from 'historykana';
import { useCallback, useState } from 'react';

export function useKana(): (input: string) => string {
  const [history, setHistory] = useState<string[]>([]);

  return useCallback(
    (input: string) => {
      // 文字が変化してたら履歴に追加
      const newHistory =
        input !== history[history.length - 1]
          ? [...history, input]
          : [...history];

      // 変換後に変換対象の文字が、表示されて消えて再度表示されるという現象があったので回避しておく。
      const len = newHistory.length;
      if (len >= 3 && newHistory[len - 3] === newHistory[len - 1]) {
        newHistory.splice(len - 3, 2);
      }

      // 先頭以外に空文字があればそこまでは削除してしまう
      const emptyIndex = newHistory.findIndex((text) => text === '');
      if (emptyIndex !== -1 && emptyIndex !== newHistory.length - 1) {
        newHistory.splice(0, emptyIndex + 1);
      }

      setHistory(newHistory);
      return historykana(newHistory);
    },
    [history]
  );
}
