import { PermissionSectionStructure } from '@karutekun/core/permission-role';
import { useBoolean } from '@karutekun/shared/util/react-hooks';
import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import CButton from '../../../../components_old/atoms/CButton';
import { checkPermission } from '../../../../models/stylist';
import {
  selectMe,
  selectPermissionRoles,
} from '../../../../selectors/salonSelector';
import { CreatePermissionRoleDialog } from './_components/CreatePermissionRoleDialog';
import { PermissionSection } from './_components/PermissionSection';
import { UpdatePermissionRoleDialog } from './_components/UpdatePermissionRoleDialog';

const PermissionRoles: FC = () => {
  const me = useSelector(selectMe);
  const permissionRoles = useSelector(selectPermissionRoles);

  const canUpdate = checkPermission(me, 'canUpdatePermissionRole');

  const [createDialogOpen, setCreateDialogOpen] = useBoolean();
  const [updateDialogOpen, setUpdateDialogOpen] = useBoolean();
  const [updatingRoleId, setUpdatingRoleId] = useState<number | null>(null);

  return (
    <>
      <Box mt={4}>
        {canUpdate ? (
          <CButton
            size="large"
            startIcon={<SVGIcon name="plus" />}
            onClick={setCreateDialogOpen.setTrue}
          >
            役割を追加する
          </CButton>
        ) : (
          <Typography>※ 編集権限がありません</Typography>
        )}
      </Box>

      {PermissionSectionStructure.map((section, key) => (
        <PermissionSection
          key={key}
          title={section.title}
          permissions={section.permissions}
          permissionRoles={permissionRoles}
          canUpdate={canUpdate}
          onClickRole={(roleId) => {
            setUpdatingRoleId(roleId);
            setUpdateDialogOpen.setTrue();
          }}
        />
      ))}

      <CreatePermissionRoleDialog
        open={createDialogOpen}
        onClose={setCreateDialogOpen.setFalse}
      />
      <UpdatePermissionRoleDialog
        roleId={updatingRoleId ?? 0}
        open={updateDialogOpen}
        onClose={setUpdateDialogOpen.setFalse}
      />
    </>
  );
};

export default PermissionRoles;
