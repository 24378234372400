import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Property } from 'csstype';
import { Link } from 'react-router-dom';
import { isReactText } from '../../util/common';
import CEmpty from '../atoms/CEmpty';

type OwnProps = {
  columns: {
    [key: string]: {
      label: string;
      sorting?: boolean;
      align?: Property.TextAlign;
    };
  };
  rows: {
    id: number;
    linkTo?: string;
    onClick?(): void;
    // 型定義がうまくいっていないが、一旦 unknown 使ってしのいでる
    // [key: string]: React.ReactNode;
    [key: string]: unknown;
  }[];
  // TODO 一時的に lint を無効化しています。気づいたベースで直してください
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sortKey?: any;
  sortOrder?: 'asc' | 'desc';
  pagination?: {
    count: number;
    rowsPerPage: number;
    page: number;
    onPageChange(page: number): void;
  };

  headerCellClassName?: string;

  // TODO 一時的に lint を無効化しています。気づいたベースで直してください
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onOrderChange?(sortKey: any, sortOrder: 'asc' | 'desc'): void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 'inherit',
    overflowX: 'auto',
  },
  paper: {
    margin: 3,
    minWidth: 'fit-content',
  },
  headerCell: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.primary.contrastText,
    fontSize: '80%',
    fontWeight: 800,
    textAlign: 'center',
    padding: 3,
  },
  headerLabel: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  headerLabelIcon: {
    color: 'inherit !important',
  },
  headerLabelActive: {
    color: theme.palette.primary.main,
  },
  row: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.background.paper,
    },
    '&:nth-of-type(odd)': {
      // FIXME: 暫定的に色を変えています。コンポーネント差し替え時に修正する。
      backgroundColor: '#FBFBFF',
    },
  },
  headerText: {
    fontWeight: 'bold',
    padding: theme.spacing(1),
  },
}));

const CSortTable: FC<OwnProps> = (props) => {
  const classes = useStyles();

  const keys = Object.keys(props.columns);

  function renderPagination() {
    if (!props.pagination) {
      return null;
    }
    const pagination = props.pagination;

    return (
      <Grid item xs={12}>
        <Grid container style={{ width: '100%' }} justifyContent="flex-end">
          <TablePagination
            component="div"
            count={pagination.count}
            rowsPerPage={pagination.rowsPerPage}
            rowsPerPageOptions={[]}
            page={pagination.page}
            onPageChange={(e, page) => pagination.onPageChange(page)}
            labelDisplayedRows={({ from, to, count }) =>
              `${from} - ${to} (${count} 件中)`
            }
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <div className={classes.root}>
      {renderPagination()}
      <Paper elevation={2} className={classes.paper}>
        <Table component="div">
          <TableHead component="div">
            <TableRow component="div">
              {keys.map((key) => (
                <TableCell
                  component="div"
                  key={`column-${key}`}
                  colSpan={1}
                  className={clsx(
                    classes.headerCell,
                    props.headerCellClassName
                  )}
                >
                  {props.columns[key].sorting ? (
                    <TableSortLabel
                      active={props.sortKey === key}
                      direction={props.sortOrder}
                      classes={{
                        root: classes.headerLabel,
                        icon: classes.headerLabelIcon,
                        active: classes.headerLabelActive,
                      }}
                      onClick={() => {
                        if (props.onOrderChange) {
                          props.onOrderChange(
                            key,
                            props.sortKey === key
                              ? props.sortOrder === 'asc'
                                ? 'desc'
                                : 'asc'
                              : 'desc'
                          );
                        }
                      }}
                    >
                      <Typography
                        variant="body2"
                        className={classes.headerText}
                        noWrap
                      >
                        {props.columns[key].label}
                      </Typography>
                    </TableSortLabel>
                  ) : (
                    <Typography
                      variant="body2"
                      className={classes.headerText}
                      noWrap
                    >
                      {props.columns[key].label}
                    </Typography>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {props.rows.length > 0 ? (
            <TableBody component="div">
              {props.rows.map((row) => {
                // TODO 一時的に lint を無効化しています。気づいたベースで直してください
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                let rowProps: Partial<any> = {};
                if (row.onClick) {
                  rowProps = {
                    onClick: row.onClick,
                    style: { cursor: 'pointer', textDecoration: 'none' },
                  };
                } else if (row.linkTo) {
                  rowProps = {
                    // TODO 一時的に lint を無効化しています。気づいたベースで直してください
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    component: function RowLink(props: any) {
                      return <Link to={row.linkTo} {...props} />;
                    },
                    style: { cursor: 'pointer', textDecoration: 'none' },
                  };
                }

                return (
                  <TableRow
                    component="div"
                    {...rowProps}
                    className={classes.row}
                    key={`row-${row.id}`}
                    hover={true}
                  >
                    {keys.map((key) => {
                      const column = props.columns[key];
                      const content = row[key];
                      return (
                        <TableCell
                          component="div"
                          colSpan={1}
                          key={`row-${row.id}-${key}`}
                          style={{
                            textAlign: column.align || 'center',
                            fontSize: '80%',
                            padding: 4,
                          }}
                        >
                          {isReactText(content) ? (
                            <Typography variant="body2" color="textPrimary">
                              {content}
                            </Typography>
                          ) : (
                            (content as React.ReactElement)
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <TableCell
              component="div"
              style={{
                captionSide: 'bottom',
                display: 'table-caption',
              }}
            >
              <CEmpty title="データがありません" />
            </TableCell>
          )}
        </Table>
      </Paper>
      {renderPagination()}
    </div>
  );
};

export default CSortTable;
