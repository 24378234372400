import { getAge } from '@karutekun/core/customer';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import * as _ from 'lodash';
import React from 'react';
import { Customer, useCustomerImage } from '../../models/customer';
import CAvatar from '../atoms/CAvatar';
import CCustomerSexIcon from '../atoms/CCustomerSexIcon';

type OwnProps = {
  customer: Customer;
  openNewWindow?: boolean;
};

const CAutoCompleteCustomerItem: FC<OwnProps> = React.memo(
  function CAutoCompleteCustomerItem(props) {
    const { customer } = props;
    const age = getAge(customer);
    const profileImage = useCustomerImage(customer);

    return (
      <Grid container alignItems="center">
        <Grid item>
          <Grid container alignItems="flex-end">
            <CAvatar src={profileImage} />
            <CCustomerSexIcon
              sex={customer.sex}
              style={{ marginRight: 10, transform: 'scale(0.7)' }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <Typography variant="caption" color="textSecondary">
              {customer.nameKana}
            </Typography>
            <Typography variant="body2" color="textPrimary">
              {customer.name} 様
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" color="textSecondary">
              {age !== null ? `${age} 歳` : '年齢不明'}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" color="textSecondary">
              来店{customer.totalVisitNum}回
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  },
  (prev, next) => _.isEqual(prev, next)
);

export default CAutoCompleteCustomerItem;
