import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import {
  Alert,
  Box,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { dispatchWithErrorHandling } from '../../../../actions/helper/dispatchWithErrorHandling';
import {
  fetchScheduleBasicSetting,
  updateScheduleBasicSetting,
} from '../../../../actions/salon/scheduleSettingsAction';
import CButton from '../../../../components_old/atoms/CButton';
import CDivider from '../../../../components_old/atoms/CDivider';
import CInformation from '../../../../components_old/atoms/CInformation';
import CProgressOverlay from '../../../../components_old/atoms/CProgressOverlay';
import CSelect from '../../../../components_old/atoms/CSelect';
import { ScheduleBasicSetting } from '../../../../models/salonScheduleSettings';
import { checkPermission } from '../../../../models/stylist';
import { useSelectMe } from '../../../../selectors/salonSelector';
import { selectScheduleSettings } from '../../../../selectors/scheduleSettingsSelector';
import { withConfirmDialog } from '../../../../templates/hoc/ConfirmDialogHOC';
import { useFlag } from '../../../../util/hooks/useFlag';
import { useThunkDispatch } from '../../../../util/hooks/useThunkDispatch';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: theme.spacing(2),
  },
  subtitle: {
    paddingBottom: theme.spacing(2),
  },
  businessInfoButtonOn: {
    color: theme.palette.primary.main,
  },
  businessInfoButtonOff: {
    color: theme.palette.grey[900],
  },
  table: {
    width: '100%',
  },
  cell: {
    padding: theme.spacing(1),
  },
  saveArea: {
    textAlign: 'right',
  },
}));

const ScheduleBasicSettingCard: FC = () => {
  const me = useSelectMe();
  const basicSetting = useSelector(selectScheduleSettings).basicSetting;
  const dispatch = useThunkDispatch();

  const hasPermission = checkPermission(me, 'canUpdateScheduleSetting');

  const [isFetching, callWithFetching] = useFlag(false);
  const [isSavingBasicSetting, callWithSaving] = useFlag(false);

  useEffect(() => {
    callWithFetching(async () => await dispatch(fetchScheduleBasicSetting()));
  }, [callWithFetching, dispatch]);

  const handleSaveBasicSetting = useCallback(
    (updates: Partial<ScheduleBasicSetting>) => {
      callWithSaving(async () => {
        await dispatchWithErrorHandling(
          dispatch,
          updateScheduleBasicSetting(updates),
          { success: '設定を保存しました' }
        );
      });
    },
    [callWithSaving, dispatch]
  );

  return (
    <div style={{ position: 'relative' }}>
      {isFetching && <CProgressOverlay />}

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <BasicSettingsCard
            basicSetting={basicSetting}
            hasPermission={hasPermission}
            isFetching={isSavingBasicSetting}
            onClickSave={handleSaveBasicSetting}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <BusinessInfoSettingsCard
            basicSetting={basicSetting}
            hasPermission={hasPermission}
            isFetching={isSavingBasicSetting}
            onClickSave={handleSaveBasicSetting}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const BasicSettingsCard: FC<{
  basicSetting: ScheduleBasicSetting;
  hasPermission: boolean;
  isFetching: boolean;
  onClickSave(updates: Partial<ScheduleBasicSetting>): void;
}> = (props) => {
  const classes = useStyles();
  const { basicSetting, hasPermission, isFetching, onClickSave } = props;

  const [isEdited, setIsEdited] = useState(false);
  const [updates, setUpdates] = useState<Partial<ScheduleBasicSetting>>({});

  useEffect(() => {
    setUpdates({});
    setIsEdited(false);
  }, [basicSetting]);

  const handleUpdate = useCallback(
    (update: Partial<ScheduleBasicSetting>) => {
      setUpdates({ ...updates, ...update });
      setIsEdited(true);
    },
    [updates]
  );

  const handleSaveClick = useCallback(
    () => onClickSave(updates),
    [onClickSave, updates]
  );

  return (
    <Card className={classes.card}>
      <Typography variant="body1" className={classes.subtitle}>
        台帳設定
      </Typography>
      <Table className={classes.table}>
        <TableBody>
          <TableRow>
            <TableCell align="left" className={classes.cell}>
              台帳や来店記録の分刻み
            </TableCell>
            <TableCell align="left" className={classes.cell}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <CSelect<MinuteInterval>
                    value={
                      updates.minuteInterval ?? basicSetting.minuteInterval
                    }
                    options={[5, 10, 15, 20, 30, 60].map((interval) => ({
                      value: interval as MinuteInterval,
                      element: `${interval}`,
                    }))}
                    onChange={(minuteInterval) => {
                      if (minuteInterval) {
                        handleUpdate({ minuteInterval });
                      }
                    }}
                    disabled={!hasPermission}
                  />
                </Grid>
                <Grid item xs>
                  <Typography variant="body2">分刻み</Typography>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {hasPermission && (
        <Box mt={2}>
          <div className={classes.saveArea}>
            <CButton
              isLoading={isFetching}
              disabled={!isEdited}
              onClick={handleSaveClick}
            >
              保存
            </CButton>
          </div>
        </Box>
      )}
    </Card>
  );
};

const BusinessInfoSettingsCard: FC<{
  basicSetting: ScheduleBasicSetting;
  hasPermission: boolean;
  isFetching: boolean;
  onClickSave(updates: Partial<ScheduleBasicSetting>): void;
}> = (props) => {
  const classes = useStyles();
  const { basicSetting, hasPermission, isFetching, onClickSave } = props;

  const showBusinessInfo = basicSetting.showBusinessInfo;

  const [isEdited, setIsEdited] = useState(false);
  const [updates, setUpdates] = useState<Partial<ScheduleBasicSetting>>({});

  useEffect(() => {
    setUpdates({});
    setIsEdited(false);
  }, [basicSetting]);

  const handleToggleShowBusinessInfo = useCallback(() => {
    onClickSave({ showBusinessInfo: !showBusinessInfo });
  }, [onClickSave, showBusinessInfo]);

  const handleUpdate = useCallback(
    (update: Partial<ScheduleBasicSetting>) => {
      setUpdates({ ...updates, ...update });
      setIsEdited(true);
    },
    [updates]
  );

  const handleSaveClick = useCallback(
    () => onClickSave(updates),
    [onClickSave, updates]
  );

  return (
    <Card className={classes.card}>
      <Typography variant="body1" className={classes.subtitle}>
        営業情報設定
        <CInformation
          type="tooltip"
          content="営業情報設定を利用すると、店舗の営業時間やスタッフのシフトが設定できるようになり、設定内容が予約台帳に反映されます"
        />
      </Typography>

      <Grid container spacing={2} alignItems="center">
        <Grid item>
          {showBusinessInfo ? (
            <Alert severity="success">営業情報設定が有効です</Alert>
          ) : (
            <Alert severity="warning">営業情報設定は無効です</Alert>
          )}
        </Grid>
        {hasPermission && (
          <Grid item>
            <Box
              className={
                showBusinessInfo
                  ? classes.businessInfoButtonOff
                  : classes.businessInfoButtonOn
              }
            >
              <CButton
                color="inherit"
                variant="outlined"
                isLoading={isFetching}
                onClick={handleToggleShowBusinessInfo}
              >
                {showBusinessInfo
                  ? '営業情報設定を無効にする'
                  : '営業情報設定を有効にする'}
              </CButton>
            </Box>
          </Grid>
        )}
      </Grid>

      <CDivider spacing={2} />

      <FormControlLabel
        disabled={!showBusinessInfo || !hasPermission}
        control={
          <Checkbox
            checked={
              updates.shouldValidateCapacity ??
              basicSetting.shouldValidateCapacity
            }
            onChange={(_, checked) =>
              handleUpdate({ shouldValidateCapacity: checked })
            }
          />
        }
        label="予約が超過する場合に確認を表示する"
      />
      <div style={{ display: 'inline-block' }}>
        <CInformation
          type="tooltip"
          content="手動で予約を入力する際に、営業情報やスタッフのシフトに基づいて受付可能かどうか判定し、予約が超過する場合に確認を表示するかどうか選べます"
        />
      </div>

      {hasPermission && (
        <Box mt={2}>
          <div className={classes.saveArea}>
            <CButton
              isLoading={isFetching}
              disabled={!isEdited}
              onClick={handleSaveClick}
            >
              保存
            </CButton>
          </div>
        </Box>
      )}

      <CDivider spacing={2} />

      <Grid container spacing={2}>
        <Grid item>
          <CButton
            linkTo="/settings/schedule/business_hour"
            variant="outlined"
            endIcon={<SVGIcon name="angle-right" size="sm" />}
            disabled={!showBusinessInfo}
          >
            営業時間
          </CButton>
        </Grid>
        <Grid item>
          <CButton
            linkTo="/settings/schedule/shift_pattern"
            variant="outlined"
            endIcon={<SVGIcon name="angle-right" size="sm" />}
            disabled={!showBusinessInfo}
          >
            勤務パターン
          </CButton>
        </Grid>
        <Grid item>
          <CButton
            linkTo="/settings/schedule/stylist_shift"
            variant="outlined"
            endIcon={<SVGIcon name="angle-right" size="sm" />}
            disabled={!showBusinessInfo}
          >
            スタッフの基本シフト
          </CButton>
        </Grid>
        <Grid item>
          <CButton
            linkTo="/settings/schedule/business_info"
            variant="outlined"
            endIcon={<SVGIcon name="angle-right" size="sm" />}
            disabled={!showBusinessInfo}
          >
            毎月の営業情報
          </CButton>
        </Grid>
      </Grid>
    </Card>
  );
};

export default withConfirmDialog(ScheduleBasicSettingCard);
