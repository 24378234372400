import { dayjs } from './dayjs';

/**
 * 日付の範囲を生成する
 *
 * ex)
 * // 2019-04-01 以降本日まで
 * const range = dateRange({ from: '2019-04-01' })
 *
 * // 2019-04-01 から 2019-04-10 まで
 * const range = dateRange({ from: '2019-04-01', to: '2019-04-10' })
 *
 * // 2019-04-01 から 2019-04-10 まで2日おきに
 * const range = dateRange({ from: '2019-04-01', to: '2019-04-10' }, 2)
 * -> 2019-04-01, 2019-04-03, 2019-04-05, 2019-04-07, 2019-04-09
 *
 * for (let date of range) {
 *   // Do something
 * }
 */
export function* dateRange(
  range: { from?: string; to?: string },
  interval?: number
) {
  if (range.from === undefined && range.to === undefined) {
    return;
  }

  let from = range.from ? dayjs(range.from, 'YYYY-MM-DD') : dayjs();
  const to = range.to ? dayjs(range.to, 'YYYY-MM-DD').endOf('day') : dayjs();

  while (from.unix() <= to.unix()) {
    yield from.format('YYYY-MM-DD');
    from = from.add(interval && interval > 0 ? interval : 1, 'days');
  }
}

/**
 * 月の範囲を生成する
 *
 * ex)
 * // 2019-04 以降本日まで
 * const range = dateRange({ from: '2019-04' })
 *
 * // 2018-04 から 2019-04 まで
 * const range = dateRange({ from: '2018-04', to: '2019-04' })
 *
 * for (let month of range) {
 *   // Do something
 * }
 */
export function* monthRange(range: { from?: string; to?: string }) {
  if (range.from === undefined && range.to === undefined) {
    return;
  }

  let from = range.from ? dayjs(range.from, 'YYYY-MM') : dayjs();
  const to = range.to ? dayjs(range.to, 'YYYY-MM').endOf('month') : dayjs();

  while (from.unix() <= to.unix()) {
    yield from.format('YYYY-MM');
    from = from.add(1, 'months');
  }
}
