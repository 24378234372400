import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback } from 'react';
import CTextLink from '../../../../../../../components_old/atoms/CTextLink';
import CTypographyIcon from '../../../../../../../components_old/atoms/CTypographyIcon';
import {
  BasicPlan,
  BasicPlanNameMap,
  OptionLinePlan,
  OptionLinePlanName,
} from '../../../../../../../models/salon';
import { PlanSelectionButton } from './PlanSelectionButton';

export type Props = {
  basicPlan: BasicPlan;
  optionLinePlan: OptionLinePlan;
  onChange(basicPlan: BasicPlan, optionLinePlan: OptionLinePlan): void;
  isBasicPlanDisabled?: boolean;
  isOptionLinePlanDisabled?: boolean;
  isSingleDisabled?: boolean;
};

const useStyles = makeStyles(() => ({
  planSelectionButton: {
    width: '100%',
  },
}));

export const PlanSelectionForm: FC<Props> = (props) => {
  const classes = useStyles();
  const {
    basicPlan,
    optionLinePlan,
    onChange,
    isBasicPlanDisabled = false,
    isOptionLinePlanDisabled = false,
    isSingleDisabled = false,
  } = props;

  const handleChangeBasicPlan = useCallback(
    (basicPlan: BasicPlan) => {
      if (isBasicPlanDisabled) {
        return;
      }
      if (isSingleDisabled && basicPlan === BasicPlan.Single) {
        return;
      }

      onChange(
        basicPlan,
        // LINEオプションは基本プランによって値段が変わるので、
        // 基本プランが変更されたときはLINEオプションは一旦チェック外すようにする
        OptionLinePlan.Trial
      );
    },
    [isBasicPlanDisabled, isSingleDisabled, onChange]
  );

  const handleChangeOptionLinePlan = useCallback(
    (checked: boolean) => {
      if (isOptionLinePlanDisabled) {
        return;
      }

      onChange(
        basicPlan,
        checked
          ? basicPlan === BasicPlan.Single
            ? OptionLinePlan.Single
            : OptionLinePlan.Standard
          : OptionLinePlan.Trial
      );
    },
    [basicPlan, isOptionLinePlanDisabled, onChange]
  );

  return (
    <>
      <Typography variant="h6" gutterBottom>
        プラン
      </Typography>
      <Grid container spacing={2}>
        <Grid item>
          <CTextLink
            text="プランの詳細について"
            linkTo={process.env.REACT_APP_PLAN_DETAILS_URL}
          />
        </Grid>
        <Grid item>
          <CTextLink
            text="その他お問い合わせ"
            linkTo={process.env.REACT_APP_CONTACT_URL}
          />
        </Grid>
      </Grid>

      <Box p={2}>
        <CTypographyIcon
          icon={<SVGIcon name="angle-right" size="sm" />}
          variant="h6"
          gutterBottom
        >
          基本プラン
        </CTypographyIcon>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <PlanSelectionButton
              primary={BasicPlanNameMap[BasicPlan.Single]}
              secondary="月額 ¥5,000 (税抜)"
              checked={basicPlan === BasicPlan.Single}
              disabled={isBasicPlanDisabled || isSingleDisabled}
              onClick={() => handleChangeBasicPlan(BasicPlan.Single)}
              className={classes.planSelectionButton}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <PlanSelectionButton
              primary={BasicPlanNameMap[BasicPlan.Standard]}
              secondary="月額 ¥10,000 (税抜)"
              checked={basicPlan === BasicPlan.Standard}
              disabled={isBasicPlanDisabled}
              onClick={() => handleChangeBasicPlan(BasicPlan.Standard)}
              className={classes.planSelectionButton}
            />
          </Grid>
        </Grid>
        {isSingleDisabled && (
          <Box mt={2} mb={2}>
            <Typography variant="body2" color="error">
              ※
              スタッフが2名以上所属しているため、シングルプランはご利用いただけません。
            </Typography>
          </Box>
        )}

        <Box mt={2}>
          <CTypographyIcon
            icon={<SVGIcon name="angle-right" size="sm" />}
            variant="subtitle1"
            gutterBottom
          >
            LINEオプション
          </CTypographyIcon>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <PlanSelectionButton
              primary="利用しない"
              checked={optionLinePlan === OptionLinePlan.Trial}
              disabled={isOptionLinePlanDisabled}
              onClick={() => handleChangeOptionLinePlan(false)}
              className={classes.planSelectionButton}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <PlanSelectionButton
              primary={`${OptionLinePlanName}(${
                basicPlan === BasicPlan.Single ? 'シングル' : 'スタンダード'
              })`}
              secondary={`月額 ¥${
                basicPlan === BasicPlan.Single ? '5,000' : '10,000'
              } (税抜)`}
              checked={optionLinePlan !== OptionLinePlan.Trial}
              disabled={isOptionLinePlanDisabled}
              onClick={() => handleChangeOptionLinePlan(true)}
              className={classes.planSelectionButton}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
