import { ActionType, getType } from 'typesafe-actions';
import * as actions from '../../actions/view/viewChatAction';

export type ViewChatActions = ActionType<typeof actions>;

export interface ViewChatState {
  selectedSalonId: number;
}

export const initialState: ViewChatState = {
  selectedSalonId: 0,
};

function viewChatReducer(
  state = initialState,
  action: ViewChatActions
): ViewChatState {
  switch (action.type) {
    case getType(actions.setChatSalonId): {
      const { salonId } = action.payload;

      if (state.selectedSalonId === salonId) {
        return state;
      } else {
        return {
          ...state,
          selectedSalonId: salonId,
        };
      }
    }
    default:
      return state;
  }
}

export default viewChatReducer;
