import { TextField } from '@mui/material';

type Props = {
  treatmentMemo: string;
  disabled?: boolean;
  onChange(treatmentMemo: string): void;
  fullWidth?: boolean;
};

export const VisitFormTreatmentMemo: FC<Props> = ({
  treatmentMemo,
  disabled,
  onChange,
  fullWidth,
}) => {
  return (
    <TextField
      multiline
      label="施術メモ"
      value={treatmentMemo}
      onChange={(e) => onChange(e.target.value)}
      variant="outlined"
      disabled={disabled}
      fullWidth={fullWidth}
    />
  );
};
