import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { removeSnackbar } from '../../../actions/generalAction';
import { GlobalState } from '../../../store';
import SnackbarNotification, {
  DispatchProps,
  StateProps,
} from '../../../templates/snackbarNotification/components/SnackbarNotification';

const mapStateToProps = (state: GlobalState): StateProps => ({
  notifications: state.general.snackbarNotificationItems,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps =>
  bindActionCreators({ removeSnackbar }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SnackbarNotification);
