import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { Images } from './assets/images';
import CButton from './components_old/atoms/CButton';
import Logger from './logger';
import { useWidthUp } from './util/hooks/useWidth';

type Props = { children?: React.ReactNode };
type State = { hasError: boolean };

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    Logger.sendErrorReport(error, info);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return <FallbackComponent />;
    }

    return this.props.children;
  }
}

const FallbackComponent: FC = () => {
  const isWidthUp = useWidthUp('sm');

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="flex-start"
      style={{ minHeight: '100vh' }}
    >
      {isWidthUp ? (
        <Grid
          item
          container
          spacing={2}
          style={{
            maxWidth: 724,
            padding: 40,
            paddingTop: 64,
          }}
        >
          <Grid item container spacing={2} style={{ flex: 4 }}>
            <Grid item xs={12}>
              <Typography variant="h4" style={{ fontWeight: 700 }}>
                エラーが発生しました
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">
                ご利用の皆様にはご迷惑をおかけし、大変申し訳ございません。
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">以下をお試しください。</Typography>

              <Box mt={1} mb={1}>
                <Typography variant="h6">・ページの再読込</Typography>
              </Box>

              <Typography variant="h6">
                ・ブラウザを最新バージョンに更新(推奨ブラウザはChromeです)
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Box mt={3} mb={2}>
                <ReloadButton />
              </Box>
            </Grid>

            <Grid container item xs={12} spacing={1}>
              <Grid item>
                <TopPageLink />
              </Grid>
              <Grid item>
                <Typography color="primary">|</Typography>
              </Grid>
              <Grid item>
                <SupportLink />
              </Grid>
            </Grid>
          </Grid>

          <Grid item container alignItems="center" style={{ flex: 3 }}>
            <img
              src={Images.common.error}
              alt="error"
              style={{ width: '100%', maxWidth: 300 }}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid
          item
          container
          spacing={2}
          style={{
            padding: 16,
            paddingTop: 32,
          }}
        >
          <Grid item container justifyContent="center" xs={12}>
            <Typography variant="h4" style={{ fontWeight: 700 }}>
              エラーが発生しました
            </Typography>
          </Grid>

          <Grid item container justifyContent="center" xs={12}>
            <Box mt={4} mb={4}>
              <img
                src={Images.common.error}
                alt="error"
                style={{ width: '100%', maxWidth: 271 }}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">
              ご利用の皆様にはご迷惑をおかけし、大変申し訳ございません。
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">次をお試しください。</Typography>

            <Box mt={1} mb={1}>
              <Typography variant="h6">・ページの再読込</Typography>
            </Box>

            <Typography variant="h6">
              ・ブラウザを最新バージョンに更新(推奨ブラウザはChromeです)
            </Typography>
          </Grid>

          <Grid item container xs={12} justifyContent="center">
            <Box mt={1} mb={2}>
              <ReloadButton />
            </Box>
          </Grid>

          <Grid container item xs={12} justifyContent="center" spacing={1}>
            <Grid item>
              <TopPageLink />
            </Grid>
            <Grid item>
              <Typography color="primary">|</Typography>
            </Grid>
            <Grid item>
              <SupportLink />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const ReloadButton: FC = () => {
  return (
    <CButton
      onClick={() => window.document.location.reload()}
      style={{ height: 44, fontSize: 14, fontWeight: 700 }}
    >
      ページを再読み込み
    </CButton>
  );
};

const TopPageLink: FC = () => {
  return (
    <Button
      variant="text"
      color="primary"
      href="https://admin.karutekun.com"
      target="_blank"
      rel="noopener noreferrer"
      style={{ padding: 0, height: 24, fontSize: 14 }}
    >
      トップページに戻る
    </Button>
  );
};

const SupportLink: FC = () => {
  return (
    <Button
      variant="text"
      color="primary"
      href="https://karutekun.com/#contact-us"
      target="_blank"
      rel="noopener noreferrer"
      style={{ padding: 0, height: 24, fontSize: 14 }}
    >
      サポートへお問い合わせ
    </Button>
  );
};
