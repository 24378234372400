import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M19.82 6.912c1.79-3.33 6.57-3.33 8.36 0l15.98 29.67c.39.72.58 1.54.57 2.36a4.778 4.778 0 0 1-2.4 4.03c-.71.41-1.52.62-2.34.62H8.02c-.82 0-1.63-.21-2.34-.62a4.778 4.778 0 0 1-2.4-4.03c-.02-.82.18-1.63.57-2.36zm20.16 33.17c.22 0 .43-.06.62-.16.19-.11.35-.26.46-.45.11-.19.17-.4.18-.61 0-.22-.05-.43-.15-.62L25.1 8.572c-.47-.88-1.73-.88-2.2 0L6.92 38.242c-.1.19-.15.4-.15.62 0 .22.07.43.18.61a1.241 1.241 0 0 0 1.08.61zm-16-25.08c.31 0 .62.06.91.18.29.12.55.3.76.52.22.22.38.49.49.78.11.29.16.6.15.91l-.54 11.44a1.75 1.75 0 0 1-1.75 1.67c-.93 0-1.7-.73-1.75-1.67l-.54-11.44a2.274 2.274 0 0 1 .62-1.67c.21-.22.47-.4.75-.52s.59-.19.89-.19zm.02 22.74c-.47 0-.93-.14-1.32-.4s-.69-.63-.87-1.07c-.18-.43-.23-.91-.14-1.37.09-.46.32-.88.65-1.22.33-.33.76-.56 1.22-.65.46-.09.94-.04 1.37.14.43.18.8.48 1.07.87a2.37 2.37 0 0 1-.3 3c-.45.45-1.05.7-1.68.7"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgExclamationTriangle = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgExclamationTriangle);
export default Memo;
