import { FeatureName } from '@karutekun/core/feature-flag';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

interface FeatureFlagState {
  availableFeatures: FeatureName[];
}
interface ExpectedGlobalState {
  featureFlag: FeatureFlagState;
}

const initialState: FeatureFlagState = {
  availableFeatures: [],
};

export const featureFlagSlice = createSlice({
  name: 'featureFlag',
  initialState,
  reducers: {
    setAvailableFeatures: (state, action: PayloadAction<FeatureName[]>) => {
      state.availableFeatures = action.payload;
    },
  },
});

export const { setAvailableFeatures } = featureFlagSlice.actions;

export const selectAvailableFeatures = (state: ExpectedGlobalState) =>
  state.featureFlag.availableFeatures;

export const useSelectAvailableFeatures = (): FeatureName[] => {
  return useSelector(selectAvailableFeatures);
};
export const useIsFeatureAvailable = (featureName: FeatureName): boolean => {
  const availableFeatures = useSelector(selectAvailableFeatures);
  return availableFeatures.includes(featureName);
};

export const featureFlagReducer = featureFlagSlice.reducer;
