import { VoucherUtils } from '@karutekun/core/voucher';
import { RouterInput, trpc } from '@karutekun/shared/data-access/api-base';
import { useFetchVisitsByDateRange } from '@karutekun/shared/data-access/visit';
import { VoucherFormData } from '@karutekun/shared/data-access/voucher';
import {
  adjustUnixToMinuteInterval,
  nowUnix,
} from '@karutekun/shared/util/datetime';
import { useBoolean } from '@karutekun/shared/util/react-hooks';
import { Box, Button, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  pushSnackbarError,
  pushSnackbarSuccess,
} from '../../../../actions/generalAction';
import {
  setViewSchedulePreviewUpdates,
  setViewSchedulePreviewVisitId,
} from '../../../../actions/view/viewScheduleAction';
import CButton from '../../../../components_old/atoms/CButton';
import CDivider from '../../../../components_old/atoms/CDivider';
import { VisitFormAssignedStylist } from '../../../../features/visit/components/form/VisitFormAssignedStylist';
import VisitFormCustomerSearchInput from '../../../../features/visit/components/form/VisitFormCustomerSearchInput';
import { VisitFormDatetime } from '../../../../features/visit/components/form/VisitFormDatetime';
import { VisitFormIsShimei } from '../../../../features/visit/components/form/VisitFormIsShimei';
import { VisitFormReservationMemo } from '../../../../features/visit/components/form/VisitFormReservationMemo';
import { CreateVoucherDialog } from '../../../../features/voucher/components/CreateVoucherDialog';
import { VoucherPreview } from '../../../../features/voucher/components/VoucherPreview';
import { selectMySalon } from '../../../../selectors/salonSelector';
import { selectMinuteInterval } from '../../../../selectors/scheduleSettingsSelector';
import { selectScheduleDrawerPreview } from '../../../../selectors/view/viewScheduleSelector';
import { useSalonStatus } from '../../../../templates/providers/salonStatus/salonStatusContext';
import { useSimpleDialog } from '../../../../templates/providers/simpleDialog/simpleDialogContext';
import { useThunkDispatch } from '../../../../util/hooks/useThunkDispatch';
import { SchedulesDrawerRecentVisitHistories } from './_components/SchedulesDrawerRecentVisitHistories';

type FormData = RouterInput['visit']['createReservation'];

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(0),
  },
}));

export const SchedulesDrawerPreviewCreate: FC = () => {
  const classes = useStyles();
  const dispatch = useThunkDispatch();
  const { open } = useSimpleDialog();

  const salon = useSelector(selectMySalon);
  const minuteInterval = useSelector(selectMinuteInterval);
  const { visitUpdates } = useSelector(selectScheduleDrawerPreview);
  const { checkAndOpenDialog } = useSalonStatus();

  const [isVoucherDialogOpen, setIsVoucherDialogOpen] = useBoolean(false);

  const invalidate = useFetchVisitsByDateRange.useInvalidate();

  const {
    control,
    setValue,
    getValues,
    watch,
    handleSubmit,
    formState: { isDirty },
  } = useForm<FormData>({
    defaultValues: {
      visit: {
        customerId: null,
        assignedStylistId: visitUpdates.assignedStylistId ?? null,
        title: '',
        reservationMemo: '',
        startedAt:
          visitUpdates.startedAt ??
          adjustUnixToMinuteInterval(nowUnix(), minuteInterval),
        finishedAt:
          visitUpdates.finishedAt ??
          adjustUnixToMinuteInterval(nowUnix(), minuteInterval) + 3600,
        isShimei: visitUpdates.isShimei ?? false,
      },
      voucher: {
        taxRoundingType: salon.taxRoundingType,
        lines: [],
        discounts: [],
      },
    },
  });

  useEffect(() => {
    // 台帳画面のクリックで作成時刻や担当者が変更できるので、
    // 力技ではありますが、変更があった場合に form に反映されるようにしています
    for (const key of [
      'assignedStylistId',
      'startedAt',
      'finishedAt',
      'isShimei',
    ] as const) {
      const val = visitUpdates[key];
      if (val !== undefined && val !== getValues(`visit.${key}`)) {
        setValue(`visit.${key}`, val);
      }
    }
  }, [getValues, setValue, visitUpdates]);

  useEffect(() => {
    if (isDirty) {
      dispatch(setViewSchedulePreviewUpdates({}, false, true));
    }
  }, [dispatch, isDirty]);

  const { mutate: createReservation, isPending } =
    trpc.visit.createReservation.useMutation({
      async onSuccess(res) {
        await invalidate();

        dispatch(setViewSchedulePreviewVisitId(res.reservation.id));
        dispatch(setViewSchedulePreviewUpdates({}, true));

        const message = res.lineNotified
          ? '予約を作成し、お客様にLINEでお知らせしました'
          : `予約を作成しました`;
        dispatch(pushSnackbarSuccess(message));
      },
      onError(e, data) {
        if (e.data?.karutekunErrorCode === 'RESERVATION_UNAVAILABLE') {
          return open({
            title: '確認',
            content: `${e.data.messageForUser}\nこのまま作成しますか？`,
            async onOk() {
              createReservation({ ...data, skipAvailabilityCheck: true });
            },
          });
        }
        dispatch(
          pushSnackbarError(e.data?.messageForUser ?? 'エラーが発生しました')
        );
      },
    });

  const handleCreate = useCallback(() => {
    if (!checkAndOpenDialog()) {
      return;
    }

    handleSubmit((data) => {
      createReservation({
        visit: data.visit,
        voucher: data.voucher,
      });
    })();
  }, [checkAndOpenDialog, createReservation, handleSubmit]);

  const handleEditVoucher = useCallback(
    (voucher: VoucherFormData, totalMinutes: number) => {
      setIsVoucherDialogOpen.setFalse();
      setValue('voucher', voucher, { shouldDirty: true });

      // 予約新規作成時なので、終了時刻をメニューの時間に合わせる
      const finishedAt = adjustUnixToMinuteInterval(
        getValues('visit.startedAt') + totalMinutes * 60,
        minuteInterval
      );
      setValue('visit.finishedAt', finishedAt, { shouldDirty: true });
      dispatch(setViewSchedulePreviewUpdates({ finishedAt }));
    },
    [dispatch, getValues, minuteInterval, setIsVoucherDialogOpen, setValue]
  );

  const customerId = watch('visit.customerId');
  const isValid = watch('visit.title') !== '' || customerId !== null;

  const voucher = watch('voucher');
  const assignedStylistId = watch('visit.assignedStylistId');
  const isShimei = watch('visit.isShimei');
  const voucherForPreview = useMemo(
    () => ({
      salonId: salon.id,
      ...VoucherUtils.calculateAndFillVoucherSales(voucher),
    }),
    [salon.id, voucher]
  );
  const voucherDialogDefaults = useMemo(
    () => ({
      stylistId: assignedStylistId,
      isShimei,
      data: voucher,
    }),
    [assignedStylistId, isShimei, voucher]
  );

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="visit.customerId"
            render={({ field: { onChange, value } }) => (
              <VisitFormCustomerSearchInput
                title={watch('visit.title')}
                customerId={value}
                isUnassociatable={true}
                onSelect={onChange}
                onChangeTitle={(title) =>
                  setValue('visit.title', title, { shouldDirty: true })
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <VisitFormDatetime
            startedAt={watch('visit.startedAt')}
            finishedAt={watch('visit.finishedAt')}
            onChange={({ startedAt, finishedAt }) => {
              setValue('visit.startedAt', startedAt, { shouldDirty: true });
              setValue('visit.finishedAt', finishedAt, { shouldDirty: true });
              dispatch(
                setViewSchedulePreviewUpdates({ startedAt, finishedAt })
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="visit.isShimei"
            render={({ field: { onChange, value } }) => (
              <VisitFormIsShimei isShimei={value} onChange={onChange} />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="visit.assignedStylistId"
            render={({ field: { onChange, value } }) => (
              <VisitFormAssignedStylist
                salonId={salon.id}
                assignedStylistId={value}
                onChange={(assignedStylistId) => {
                  onChange(assignedStylistId);
                  dispatch(
                    setViewSchedulePreviewUpdates({ assignedStylistId })
                  );
                }}
                allowEmpty
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="visit.reservationMemo"
            render={({ field: { onChange, value } }) => (
              <VisitFormReservationMemo
                reservationMemo={value}
                onChange={onChange}
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>

      <CDivider spacing={2} />

      <VoucherPreview voucher={voucherForPreview} />
      <Box mt={2} display="flex" justifyContent="center">
        <CreateVoucherDialog
          open={isVoucherDialogOpen}
          defaults={voucherDialogDefaults}
          onCancel={setIsVoucherDialogOpen.setFalse}
          onComplete={handleEditVoucher}
        />
        <Button
          variant="outlined"
          color="secondary"
          onClick={setIsVoucherDialogOpen.setTrue}
        >
          提供予定のメニューを選択
        </Button>
      </Box>

      <CDivider spacing={2} />

      <Grid container justifyContent="flex-end">
        <CButton
          isLoading={isPending}
          disabled={!isDirty || !isValid}
          onClick={handleCreate}
        >
          作成
        </CButton>
      </Grid>
      {customerId && (
        <>
          <CDivider />
          <SchedulesDrawerRecentVisitHistories customerId={customerId} />
        </>
      )}
    </div>
  );
};
