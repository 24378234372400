import {
  type HTTPBatchLinkOptions,
  TRPCClientError as _TRPCClientError,
  TRPCClientErrorLike as _TRPCClientErrorLike,
  httpBatchLink,
} from '@trpc/client';
import { createTRPCReact } from '@trpc/react-query';
import type { inferRouterInputs, inferRouterOutputs } from '@trpc/server';

// eslint-disable-next-line @nx/enforce-module-boundaries
import type { AppRouter } from '../../../../../../apps/server/src/trpc/router';

export const trpc = createTRPCReact<AppRouter>();

export const createTrpcClient = ({
  httpBatchLinkOptions,
}: TrpcClientBuildOptions) => {
  return trpc.createClient({
    links: [httpBatchLink(httpBatchLinkOptions)],
  });
};

export function useRawTrpcClient() {
  return trpc.useContext().client;
}

export type TrpcClientBuildOptions = {
  httpBatchLinkOptions: HTTPBatchLinkOptions<
    AppRouter['_def']['_config']['$types']
  >;
};

export type TRPCClientError = _TRPCClientError<AppRouter>;
export type TRPCClientErrorLike = _TRPCClientErrorLike<AppRouter>;
export type RouterInput = inferRouterInputs<AppRouter>;
export type RouterOutput = inferRouterOutputs<AppRouter>;

export function isTRPCError(e: unknown): e is TRPCClientError {
  return e instanceof _TRPCClientError;
}
