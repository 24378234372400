import { trpc } from '@karutekun/shared/data-access/api-base';
import { keepPreviousData } from '@tanstack/react-query';
import { VisitHistoryFilter, VisitHistorySort } from './types';

export function useFetchVisitHistoriesPaginated(
  filter: VisitHistoryFilter,
  sort: VisitHistorySort,
  limit: number,
  page: number
) {
  const result = trpc.visit.findVisitHistories.useQuery(
    { filter, sort, limit, cursor: page * limit },
    { placeholderData: keepPreviousData }
  );
  return {
    ...result,
    data: {
      count: result.data?.count ?? 0,
      visits: result.data?.visits ?? [],
    },
  };
}
