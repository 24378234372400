import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M7.5 37.5a3 3 0 1 1 0-6 3 3 0 0 1 0 6m7.5-1.25a1.75 1.75 0 1 1 0-3.5h27a1.75 1.75 0 1 1 0 3.5zm0-10.5a1.75 1.75 0 1 1 0-3.5h27a1.75 1.75 0 1 1 0 3.5zm0-10.5a1.75 1.75 0 1 1 0-3.5h27a1.75 1.75 0 1 1 0 3.5zM4.5 24a3 3 0 1 0 6 0 3 3 0 0 0-6 0m3-7.5a3 3 0 1 1 0-6 3 3 0 0 1 0 6"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgTasks = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgTasks);
export default Memo;
