import { VisitUtils } from '@karutekun/core/visit';
import { useFetchVisit } from '@karutekun/shared/data-access/visit';
import {
  Alert,
  Card,
  CircularProgress,
  Grid,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CCustomerCard from '../../components_old/organisms/CCustomerCard';
import { CCustomerVisitHistoryList } from '../../components_old/organisms/visits/visitCardList/CCustomerVisitList';
import { VisitHistoryFormCard } from '../../features/visit/components/VisitHistoryFormCard';
import { VoucherFormCard } from '../../features/visit/components/VoucherFormCard';
import { selectMySalon } from '../../selectors/salonSelector';
import { useSyncedQueryParams } from '../../util/hooks/router/useSyncedQueryParams';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '76px 20px 20px 20px',
  },
  disabledAlert: {
    opacity: 0.8,
    marginBottom: theme.spacing(2),
  },
}));

export const VisitHistory: FC = () => {
  const classes = useStyles();

  const params = useParams<{ visitId: string }>();
  const visitId = Number(params.visitId);

  const salon = useSelector(selectMySalon);

  const { data: visit } = useFetchVisit(visitId);
  const customer = visit?.customer;

  const [{ visitListPage }, setParams] = useSyncedQueryParams<{
    visitListPage: number;
  }>({ visitListPage: 0 }, true);

  const handleChangePage = useCallback(
    (page: number) => setParams({ visitListPage: page }),
    [setParams]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [visitId]);

  if (!visit || !customer) {
    return (
      <div
        className={classes.root}
        style={{
          height: 300,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (!VisitUtils.isVisitHistory(visit)) {
    return null;
  }

  const canEdit = salon.id === visit.salonId;

  return (
    <div className={classes.root}>
      {!canEdit && (
        <Card square className={classes.disabledAlert}>
          <Alert severity="warning">
            他サロンの来店記録のため編集できません
          </Alert>
        </Card>
      )}

      <Grid
        container
        justifyContent="space-evenly"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid container item xs={12}>
          <CCustomerCard customer={customer} />
        </Grid>

        <Grid item xs={12} md={7}>
          <VisitHistoryFormCard visitHistory={visit} />
        </Grid>

        <Grid item xs={12} md={5}>
          <VoucherFormCard visit={visit} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" mt={2} mb={-4}>
            来店記録
          </Typography>
          <CCustomerVisitHistoryList
            customerId={customer.id}
            page={visitListPage}
            onPageChange={handleChangePage}
            highlightedVisitId={visit.id}
          />
        </Grid>
      </Grid>
    </div>
  );
};
