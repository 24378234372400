/**
 * ひらがなをカタカナに変換
 */
export function hiraToKana(str: string): string {
  return str.replace(/[\u3041-\u3096]/g, (s) =>
    String.fromCharCode(s.charCodeAt(0) + 0x60)
  );
}

/**
 * 半角カタカナ,英数を全角に変換
 */
export function hankana2zenkana(str: string): string {
  /* tslint:disable */
  const kanaMap = {
    'ｶﾞ': 'ガ',
    'ｷﾞ': 'ギ',
    'ｸﾞ': 'グ',
    'ｹﾞ': 'ゲ',
    'ｺﾞ': 'ゴ',
    'ｻﾞ': 'ザ',
    'ｼﾞ': 'ジ',
    'ｽﾞ': 'ズ',
    'ｾﾞ': 'ゼ',
    'ｿﾞ': 'ゾ',
    'ﾀﾞ': 'ダ',
    'ﾁﾞ': 'ヂ',
    'ﾂﾞ': 'ヅ',
    'ﾃﾞ': 'デ',
    'ﾄﾞ': 'ド',
    'ﾊﾞ': 'バ',
    'ﾋﾞ': 'ビ',
    'ﾌﾞ': 'ブ',
    'ﾍﾞ': 'ベ',
    'ﾎﾞ': 'ボ',
    'ﾊﾟ': 'パ',
    'ﾋﾟ': 'ピ',
    'ﾌﾟ': 'プ',
    'ﾍﾟ': 'ペ',
    'ﾎﾟ': 'ポ',
    'ｳﾞ': 'ヴ',
    'ﾜﾞ': 'ヷ',
    'ｦﾞ': 'ヺ',
    'ｱ': 'ア',
    'ｲ': 'イ',
    'ｳ': 'ウ',
    'ｴ': 'エ',
    'ｵ': 'オ',
    'ｶ': 'カ',
    'ｷ': 'キ',
    'ｸ': 'ク',
    'ｹ': 'ケ',
    'ｺ': 'コ',
    'ｻ': 'サ',
    'ｼ': 'シ',
    'ｽ': 'ス',
    'ｾ': 'セ',
    'ｿ': 'ソ',
    'ﾀ': 'タ',
    'ﾁ': 'チ',
    'ﾂ': 'ツ',
    'ﾃ': 'テ',
    'ﾄ': 'ト',
    'ﾅ': 'ナ',
    'ﾆ': 'ニ',
    'ﾇ': 'ヌ',
    'ﾈ': 'ネ',
    'ﾉ': 'ノ',
    'ﾊ': 'ハ',
    'ﾋ': 'ヒ',
    'ﾌ': 'フ',
    'ﾍ': 'ヘ',
    'ﾎ': 'ホ',
    'ﾏ': 'マ',
    'ﾐ': 'ミ',
    'ﾑ': 'ム',
    'ﾒ': 'メ',
    'ﾓ': 'モ',
    'ﾔ': 'ヤ',
    'ﾕ': 'ユ',
    'ﾖ': 'ヨ',
    'ﾗ': 'ラ',
    'ﾘ': 'リ',
    'ﾙ': 'ル',
    'ﾚ': 'レ',
    'ﾛ': 'ロ',
    'ﾜ': 'ワ',
    'ｦ': 'ヲ',
    'ﾝ': 'ン',
    'ｧ': 'ァ',
    'ｨ': 'ィ',
    'ｩ': 'ゥ',
    'ｪ': 'ェ',
    'ｫ': 'ォ',
    'ｯ': 'ッ',
    'ｬ': 'ャ',
    'ｭ': 'ュ',
    'ｮ': 'ョ',
    '｡': '。',
    '､': '、',
    'ｰ': 'ー',
    '｢': '「',
    '｣': '」',
    '･': '・',
  };
  /* tslint:enable */

  const reg = new RegExp(`(${Object.keys(kanaMap).join('|')})`, 'g');
  return (
    str
      // TODO 一時的にルールを無効化しています。気づいたベースで直してください
      // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ ｶﾞ: string; ｷﾞ: string; ｸﾞ: string; ｹﾞ: string; ｺﾞ: string; ｻﾞ: string; ｼﾞ: string; ｽﾞ: string; ｾﾞ: string; ｿﾞ: string; ﾀﾞ: string; ﾁﾞ: string; ﾂﾞ: string; ﾃﾞ: string; ﾄﾞ: string; ﾊﾞ: string; ﾋﾞ: string; ﾌﾞ: string; ... 70 more ...; '\uFF65': string; }'.
      .replace(reg, (match) => kanaMap[match])
      .replace(/[A-Za-z0-9]/g, (s) =>
        String.fromCharCode(s.charCodeAt(0) + 0xfee0)
      )
      .replace(/ﾞ/g, '゛')
      .replace(/ﾟ/g, '゜')
  );
}

export function formatTextForSearch(str: string): string {
  return hankana2zenkana(hiraToKana(str)).toLowerCase();
}
