import { moment } from '@karutekun/shared/util/datetime';
import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ChatMessageBodyReservationRemind as ChatMessageBodyReservationRemindEntity } from '../../models/chatMessageBodyReservationRemind/entity';

type Props = {
  chatMessageBody: ChatMessageBodyReservationRemindEntity;
};

const useStyles = makeStyles((theme: Theme) => ({
  bold: {
    fontWeight: 'bold',
  },
  header: {
    marginTop: theme.spacing(1),
  },
  indent: {
    marginLeft: theme.spacing(1),
  },
  separator: {
    borderBottom: `solid 1px ${theme.palette.divider}`,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const ChatMessageBodyReservationRemind: FC<Props> = (props) => {
  const classes = useStyles();

  const {
    title,
    startedAt,
    stylist,
    reservationMenus,
    totalPriceText,
    footerNote,
  } = props.chatMessageBody;

  const date = moment.unix(startedAt);

  return (
    <Grid container spacing={1} direction="column">
      <Grid item>
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.bold}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" className={classes.bold}>
          {date.format('M/D(ddd) H:mm')}
        </Typography>

        {reservationMenus.length > 0 && (
          <>
            <Typography
              variant="body2"
              color="textSecondary"
              className={classes.header}
            >
              メニュー
            </Typography>
            {reservationMenus.map((rm, i) => (
              <Typography variant="body1" key={i} className={classes.indent}>
                ・{rm.name}
              </Typography>
            ))}
          </>
        )}

        {stylist && (
          <>
            <Typography
              variant="body2"
              color="textSecondary"
              className={classes.header}
            >
              指名
            </Typography>
            <Typography variant="body1" className={classes.indent}>
              {stylist.name}
            </Typography>
          </>
        )}

        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.header}
        >
          金額(税込)
        </Typography>
        <Typography variant="body1" className={classes.indent}>
          {totalPriceText}
        </Typography>

        {footerNote && (
          <>
            <Separator />
            <Typography variant="body1">{footerNote}</Typography>
          </>
        )}
      </Grid>
    </Grid>
  );
};

const Separator: FC = () => {
  const classes = useStyles();
  return <div className={classes.separator} />;
};

export default ChatMessageBodyReservationRemind;
