import {
  selectIsMaintenance,
  selectShouldForceLogout,
  selectStylistCanAccess,
} from '@karutekun/shared/data-access/api-base';
import { onIdTokenChanged } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import {
  AccessForbiddenError,
  MaintenanceError,
} from './actions/requestErrors';
import { logout } from './actions/userAction';
import FirebaseAnalytics from './firebaseAnalytics';
import { firebaseAuth } from './libs/firebase';
import Logger from './logger';
import { selectMySalon } from './selectors/salonSelector';
import {
  selectUserIsJoinedSalon,
  selectUserLoginInfo,
} from './selectors/userSelector';
import LoadingPage from './templates/LoadingPage';
import NoAccessContainer from './templates/auth/NoAccessContainer';
import Maintenance from './templates/components/Maintenance';
import { useThunkDispatch } from './util/hooks/useThunkDispatch';
import { useWidthUp } from './util/hooks/useWidth';

export type OwnProps = {};
export type DispatchProps = {
  setupSideMenu(open: boolean): void;
  initializeFirebase(appStartLoginSalonId?: number): void;
  fetchUserJoinedSalon(): Promise<void>;
  fetchInitInfo(): Promise<void>;
};
type Props = OwnProps & DispatchProps;

const RootComponent: FC<Props> = (props) => {
  const { fetchUserJoinedSalon, fetchInitInfo } = props;

  const dispatch = useThunkDispatch();
  const loginInfo = useSelector(selectUserLoginInfo);
  const isJoinedSalon = useSelector(selectUserIsJoinedSalon);
  const mySalon = useSelector(selectMySalon);
  const isMaintenance = useSelector(selectIsMaintenance);
  const canAccess = useSelector(selectStylistCanAccess);
  const shouldForceLogout = useSelector(selectShouldForceLogout);

  const navigate = useNavigate();
  const location = useLocation();
  const showSideMenu = useWidthUp('sm');

  const params = new URLSearchParams(location.search);
  const loginSalonId = params.get('loginSalonId')
    ? Number(params.get('loginSalonId'))
    : undefined;

  useEffect(() => {
    Logger.info('[RootComponent] step1. Firebaseの初期化');
    props.initializeFirebase(loginSalonId);
    props.setupSideMenu(showSideMenu);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shouldForceLogout) {
      dispatch(logout());
    }
  }, [dispatch, shouldForceLogout]);

  useEffect(() => {
    return onIdTokenChanged(firebaseAuth, async (user) => {
      if (user) {
        // 別タブなどでログインサロンを切り替えると、もとのタブも idToken が変わる
        // store は前のサロンの状態のままなので、API などを叩くとデータの不整合が生じてしまう
        // ここではログインサロンの変更を検知して、リロード処理を行う
        const idTokenResult =
          await firebaseAuth.currentUser?.getIdTokenResult();
        const idTokenSalonId = idTokenResult?.claims?.salonId;

        if (
          idTokenSalonId &&
          mySalon.id !== 0 &&
          Number(idTokenSalonId) !== mySalon.id
        ) {
          window.location.reload();
        }
      }
    });
  }, [mySalon.id]);

  // errorBoundaryで非同期初期化処理のエラーを拾う
  const [, setError] = useState();
  useEffect(() => {
    if (loginInfo?.isEmailVerified) {
      if (isJoinedSalon === undefined) {
        Logger.info('[RootComponent] step2. すでにサロンに所属しているか取得');
        fetchUserJoinedSalon().catch((e) => {
          if (
            !(e instanceof MaintenanceError) &&
            !(e instanceof AccessForbiddenError)
          ) {
            setError(() => {
              throw e;
            });
          }
        });
      } else if (isJoinedSalon) {
        Logger.info('[RootComponent] step3. サロン情報を取得');
        fetchInitInfo().catch((e) => {
          if (
            !(e instanceof MaintenanceError) &&
            !(e instanceof AccessForbiddenError)
          ) {
            setError(() => {
              throw e;
            });
          }
        });
      }
    }
  }, [
    fetchInitInfo,
    fetchUserJoinedSalon,
    isJoinedSalon,
    loginInfo,
    loginSalonId,
  ]);

  useEffect(() => {
    Logger.info(`[RootComponent] page_view ${location.pathname}`);
    FirebaseAnalytics.setCurrentScreen(location.pathname);
    FirebaseAnalytics.logEvent('page_view', {
      page_path: location.pathname,
    });
  }, [location]);

  // 特殊なパラメータがついている場合は削除
  const specialParams = [...params.keys()].filter((key) =>
    ['source', 'loginSalonId'].includes(key)
  );
  if (specialParams.length > 0) {
    // 特殊なパラメータを削除したパスで replace
    specialParams.forEach((key) => params.delete(key));
    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  }

  return isMaintenance ? (
    <Maintenance />
  ) : loginInfo === undefined ? (
    // ログイン情報がまだ初期化されていない場合
    <LoadingPage title="読込中..." />
  ) : loginInfo?.isEmailVerified && isJoinedSalon === undefined ? (
    // ログインが完了しているが、ユーザーのサロン所属状態が初期化されていない状態
    <LoadingPage title="読込中..." />
  ) : loginInfo?.isEmailVerified && isJoinedSalon && !canAccess ? (
    // ユーザーがサロンに所属しているが、サロンからアクセス制限されている場合
    <NoAccessContainer />
  ) : loginInfo?.isEmailVerified && isJoinedSalon && mySalon.id === 0 ? (
    // ユーザーがサロンに所属しているが、まだサロン情報が取得されていない状態
    <LoadingPage title="サロン情報取得中..." />
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{props.children}</>
  );
};

export default RootComponent;
