import { dayjs, nowUnix } from '@karutekun/shared/util/datetime';
import { TaxRoundingType } from './data';

const taxRoundingFuncMap: {
  [type in TaxRoundingType]: (tax: number) => number;
} = {
  [TaxRoundingType.Floor]: Math.floor,
  [TaxRoundingType.Ceil]: Math.ceil,
  [TaxRoundingType.Round]: Math.round,
};

/**
 * 消費税を適用する
 */
export function applyTax(
  price: number,
  isTaxIncluded: boolean,
  taxRate: number,
  taxRoundingType: TaxRoundingType
): { priceWithTax: number; priceWithoutTax: number; tax: number } {
  // price の正負によって端数処理が対称にならないため、絶対値で計算してから最後に符号をつける
  const sign = price < 0 ? -1 : 1;
  const absPrice = Math.abs(price);

  let priceWithTax;
  let priceWithoutTax;
  let tax;
  const roundFunc = taxRoundingFuncMap[taxRoundingType];

  if (isTaxIncluded) {
    tax = roundFunc((absPrice * taxRate) / (100 + taxRate));
    priceWithTax = absPrice;
    priceWithoutTax = absPrice - tax;
  } else {
    tax = roundFunc((absPrice * taxRate) / 100);
    priceWithTax = absPrice + tax;
    priceWithoutTax = absPrice;
  }

  return {
    priceWithTax: priceWithTax * sign,
    priceWithoutTax: priceWithoutTax * sign,
    tax: tax * sign,
  };
}

/**
 * 外税の場合に、税率をかけた総額を計算する
 */
export function applyExcludedTax(
  price: number,
  taxRate: number,
  taxRoundingType: TaxRoundingType
): number {
  const { priceWithTax } = applyTax(price, false, taxRate, taxRoundingType);
  return priceWithTax;
}

/**
 * 外税の場合に、税額を計算する
 */
export function calculateExcludedTax(
  price: number,
  taxRate: number,
  taxRoundingType: TaxRoundingType
): number {
  const { tax } = applyTax(price, false, taxRate, taxRoundingType);
  return tax;
}

/**
 * 内税の場合に、総額から税金分を計算する
 */
export function calculateIncludedTax(
  price: number,
  taxRate: number,
  taxRoundingType: TaxRoundingType
): number {
  const { tax } = applyTax(price, true, taxRate, taxRoundingType);
  return tax;
}

/**
 * epochtimeを元に税率を返す
 */
export function getCurrentTaxRate(unix: number = nowUnix()) {
  const taxThresholdEpoch8Percent = dayjs('2014-04-01').unix();
  const taxThresholdEpoch10Percent = dayjs('2019-10-01').unix();

  if (unix < taxThresholdEpoch8Percent) {
    return 5;
  } else if (unix < taxThresholdEpoch10Percent) {
    return 8;
  } else {
    return 10;
  }
}
