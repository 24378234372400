import { PermissionRole } from '@karutekun/core/permission-role';
import { sortByOrder } from '@karutekun/shared/util/entity';
import { mapById, objectValues } from '@karutekun/shared/util/objects';
import * as _ from 'lodash';
import createCachedSelector from 're-reselect';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { CounselingSection } from '../models/counseling';
import { PaymentMethod } from '../models/paymentMethod';
import { MySalon, Salon } from '../models/salon';
import { ShiftPattern } from '../models/salonScheduleSettings';
import {
  MySalonStylist,
  PlainMySalonStylist,
  StylistMe,
  emptyPlainMySalonStylist,
} from '../models/stylist';
import { VisitMotivation } from '../models/visitMotivation';
import { GlobalState } from '../store';
import { DateTypes } from '../util/date';
import { selectShiftPatternMap } from './scheduleSettingsSelector';

/**
 * サロン
 */
export const selectMySalon = (state: GlobalState): MySalon =>
  state.salon.mySalon;
export const selectGroupSalonMap = (state: GlobalState): IdMap<Salon> =>
  state.salon.groupSalonMap;
export const selectGroupSalons = createSelector(
  selectGroupSalonMap,
  (groupSalonMap: IdMap<Salon>): Salon[] => {
    return Object.values(groupSalonMap);
  }
);
export const selectAllSalons = createSelector(
  selectMySalon,
  selectGroupSalons,
  (salon: MySalon, groupSalons: Salon[]): Salon[] => {
    return [salon, ...groupSalons];
  }
);
export const selectAllSalonMap = createSelector(
  selectAllSalons,
  (salons: Salon[]): IdMap<Salon> => {
    return salons.reduce((p, c) => {
      // TODO 一時的にルールを無効化しています。気づいたベースで直してください
      // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'number' can't be used to index type '{}'.
      p[c.id] = c;
      return p;
    }, {});
  }
);
export const selectSalonById = createCachedSelector(
  selectAllSalonMap,
  (state: GlobalState, salonId: number) => salonId,
  (salonMap: IdMap<Salon>, salonId: number): Salon | null => {
    return salonMap[salonId] || null;
  }
)((state: GlobalState, salonId: number) => {
  return salonId;
});
export const selectHasGroupSalon = createSelector(
  selectGroupSalons,
  (groupSalons: Salon[]) => groupSalons.length > 0
);

/**
 * 権限
 */
export const selectPermissionRoleMap = createSelector(
  selectMySalon,
  (salon: MySalon): IdMap<PermissionRole> => salon.permissionRoleMap
);
export const selectPermissionRoles = createSelector(
  (state: GlobalState) => selectPermissionRoleMap(state),
  (permissionRoleMap): PermissionRole[] =>
    Object.values(permissionRoleMap).sort((a, b) => a.roleId - b.roleId)
);

/**
 * 来店動機
 */
export const selectVisitMotivationMapBySalonId = createCachedSelector(
  (state: GlobalState) => selectAllSalonMap(state),
  (state: GlobalState, salonId: number) => salonId,
  (salonMap: IdMap<Salon>, salonId: number): IdMap<VisitMotivation> => {
    const salon = salonMap[salonId];
    if (!salon) {
      return {};
    }
    return salon.customInformation.visitMotivationMap;
  }
)((state: GlobalState, salonId: number) => {
  return salonId;
});
export const selectVisitMotivationsBySalonId = createCachedSelector(
  (state: GlobalState, salonId: number) =>
    selectVisitMotivationMapBySalonId(state, salonId),
  (visitMotivationMap): VisitMotivation[] => Object.values(visitMotivationMap)
)((state: GlobalState, salonId: number) => {
  return salonId;
});
export const selectActiveVisitMotivationsBySalonId = createCachedSelector(
  (state: GlobalState, salonId: number) =>
    selectVisitMotivationsBySalonId(state, salonId),
  (visitMotivations) => visitMotivations.filter((mot) => mot.isActive)
)((state: GlobalState, salonId: number) => {
  return salonId;
});

/**
 * カウンセリング
 */
export const selectCounselingMapBySalonId = createCachedSelector(
  (state: GlobalState) => selectAllSalonMap(state),
  (state: GlobalState, salonId: number) => salonId,
  (salonMap: IdMap<Salon>, salonId: number): IdMap<CounselingSection> => {
    const salon = salonMap[salonId];
    if (!salon) {
      return {};
    }
    return salon.customInformation.counselingMap;
  }
)((state: GlobalState, salonId: number) => {
  return salonId;
});
export const selectCounselingsBySalonId = createCachedSelector(
  (state: GlobalState, salonId: number) =>
    selectCounselingMapBySalonId(state, salonId),
  (counselingMap): CounselingSection[] => Object.values(counselingMap)
)((state: GlobalState, salonId: number) => {
  return salonId;
});
export const selectSortedActiveCounselingsBySalonId = createCachedSelector(
  (state: GlobalState, salonId: number) =>
    selectCounselingsBySalonId(state, salonId),
  (originalCounselings): CounselingSection[] => {
    const counselings = _.cloneDeep(
      originalCounselings.filter((c) => c.isActive)
    ).sort((a, b) => b.order - a.order);
    counselings.forEach((c) => {
      c.questions = c.questions
        .filter((g) => g.isActive)
        .sort((a, b) => b.order - a.order);
      c.questions.forEach((g) => {
        g.options = g.options
          .filter((q) => q.isActive)
          .sort((a, b) => b.order - a.order);
      });
    });
    return counselings;
  }
)((state: GlobalState, salonId: number) => {
  return salonId;
});

/**
 * 支払手段
 */
export const selectPaymentMethodMap = createSelector(
  selectMySalon,
  (salon: MySalon): IdMap<PaymentMethod> =>
    salon.customInformation.paymentMethodMap
);
export const selectPaymentMethods = createSelector(
  selectPaymentMethodMap,
  (paymentMethodMap: IdMap<PaymentMethod>): PaymentMethod[] =>
    Object.values(paymentMethodMap).sort(
      // TODO 一時的に lint を無効化しています。気づいたベースで直してください
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      (a, b) => b!.order - a!.order
    ) as PaymentMethod[]
);

/**
 * スタイリスト
 */
export const selectMe = createSelector(
  (state: GlobalState) => state.user.user.myInfo,
  (state: GlobalState) => selectStylistMap(state),
  (state: GlobalState) => selectPermissionRoleMap(state),
  (state: GlobalState) => selectShiftPatternMap(state),
  (
    myState,
    stylistMap: IdMap<MySalonStylist>,
    permissionRoleMap: IdMap<PermissionRole>,
    shiftPatternMap: IdMap<ShiftPattern>
  ): StylistMe => {
    return {
      ...buildMySalonStylist(
        (myState ? stylistMap[myState.id] : null) ?? emptyPlainMySalonStylist(),
        permissionRoleMap,
        shiftPatternMap
      ),
      phone: myState ? myState.phone : '',
      firebaseImageBasePath: myState ? myState.firebaseImageBasePath : '',
    };
  }
);
export const selectPlainStylistMap = createSelector(
  selectMySalon,
  (salon: MySalon): IdMap<PlainMySalonStylist> => salon.stylistMap
);
export const selectStylistMap = createSelector(
  (state: GlobalState) => selectPlainStylistMap(state),
  (state: GlobalState) => selectPermissionRoleMap(state),
  (state: GlobalState) => selectShiftPatternMap(state),
  (
    plainStylistMap,
    permissionRoleMap,
    shiftPatternMap
  ): IdMap<MySalonStylist> => {
    const stylists = Object.values(plainStylistMap).map((ps) =>
      buildMySalonStylist(ps, permissionRoleMap, shiftPatternMap)
    );
    return mapById(stylists);
  }
);
export const selectStylists = createSelector(
  (state: GlobalState) => selectStylistMap(state),
  (stylistMap): MySalonStylist[] =>
    Object.values(stylistMap).sort((a, b) => b.order - a.order)
);
export const selectActiveStylists = createSelector(
  (state: GlobalState) => selectStylists(state),
  (stylists) => stylists.filter((stylist) => stylist.isActive)
);
function buildMySalonStylist(
  plainStylist: PlainMySalonStylist,
  permissionRoleMap: IdMap<PermissionRole>,
  shiftPatternMap: IdMap<ShiftPattern>
): MySalonStylist {
  const basicShiftSetting = plainStylist.basicShiftSetting;
  return {
    ...plainStylist,
    permissionRole: permissionRoleMap[plainStylist.permissionRoleId],
    basicShiftSetting: basicShiftSetting
      ? {
          capacity: basicShiftSetting.capacity,
          shift: DateTypes.reduce((prev, dateType) => {
            const shift = basicShiftSetting.shift[dateType];
            prev[dateType] = {
              workType: shift.workType,
              shiftPatternId: shift.shiftPatternId,
              shiftPattern:
                shift.shiftPatternId !== null
                  ? shiftPatternMap[shift.shiftPatternId] || null
                  : null,
            };
            return prev;
            // TODO 一時的に lint を無効化しています。気づいたベースで直してください
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          }, {} as any),
        }
      : null,
  };
}

//----------- hooks -------------
export function useSelectMySalon(): MySalon {
  return useSelector(selectMySalon);
}
export function useSelectSalon(salonId: number): Salon | null {
  const salonMap = useSelector(selectAllSalonMap);
  return salonMap[salonId] ?? null;
}
export function useSelectMe(): StylistMe {
  return useSelector(selectMe);
}
export function useSelectStylistMap(salonId?: number) {
  const mySalonId = useSelectMySalon().id;
  const salon = useSelectSalon(salonId ?? mySalonId);
  return salon?.stylistMap ?? {};
}
/**
 * salonId を指定してスタッフ一覧を取得する
 * includeNonActive: 所属解除されているスタッフも取得する
 * includeStylistId: 指定したスタッフは所属解除されている場合でも取得する(所属解除されているが、UI上は表示したい場合に使う)
 */
export function useSelectStylists(
  salonId?: number,
  {
    includeNonActive,
    includeStylistId,
  }: { includeNonActive?: boolean; includeStylistId?: Nullable<number> } = {}
) {
  const stylistMap = useSelectStylistMap(salonId);
  return useMemo(() => {
    const stylists = sortByOrder(Object.values(stylistMap));
    return includeNonActive
      ? stylists
      : stylists.filter(
          (s) => s.isActive || (includeStylistId && s.id === includeStylistId)
        );
  }, [includeNonActive, includeStylistId, stylistMap]);
}

export function useSelectStylist(
  stylistId: Nullable<number>,
  salonId?: number
) {
  const stylistMap = useSelectStylistMap(salonId);
  return stylistId ? stylistMap[stylistId] ?? null : null;
}
export function useSelectPaymentMethodMap(salonId?: number) {
  const mySalonId = useSelectMySalon().id;
  const salon = useSelectSalon(salonId ?? mySalonId);
  return salon?.customInformation.paymentMethodMap ?? {};
}
export function useSelectPaymentMethods(
  salonId?: number,
  {
    includeNonActive,
    includePaymentMethodIds,
  }: {
    includeNonActive?: boolean;
    includePaymentMethodIds?: number[];
  } = {}
) {
  const paymentMethodMap = useSelectPaymentMethodMap(salonId);
  return useMemo(() => {
    const paymentMehods = sortByOrder(objectValues(paymentMethodMap));
    return includeNonActive
      ? paymentMehods
      : paymentMehods.filter(
          (pm) =>
            pm.isActive ||
            (includePaymentMethodIds && includePaymentMethodIds.includes(pm.id))
        );
  }, [includeNonActive, includePaymentMethodIds, paymentMethodMap]);
}
