import { useSelectAvailableFeatures } from '@karutekun/shared/data-access/state/feature-flag';
import { useBoolean } from '@karutekun/shared/util/react-hooks';
import {
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useSelector } from 'react-redux';
import RemoteConfig, { RemoteConfigData } from '../../remoteConfig';
import { selectMe, selectMySalon } from '../../selectors/salonSelector';
import { DebugToolService } from '../../service/debugToolService';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: theme.spacing(1),
  },
  table: {
    width: '100%',
    margin: theme.spacing(2),
  },
  cell: {
    padding: theme.spacing(1),
  },
  heading: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

const CDebugTool: FC = () => {
  const classes = useStyles();
  const [isDialogOpen, isDialogOpenMutations] = useBoolean(false);
  const [showError, showErrorMutations] = useBoolean(false);
  const me = useSelector(selectMe);
  const salon = useSelector(selectMySalon);
  // remoteConfig,DebugToolServiceの変更検知を雑に行う
  const [editCounter, setEditCounter] = React.useState(0);
  const availableFeatures = useSelectAvailableFeatures();

  if (!DebugToolService.isEnabled()) {
    return null;
  }

  if (showError) {
    throw new Error('Error from debug tool!');
  }

  const debugRemoteConfigData = DebugToolService.getRemoteConfigData();
  const remoteConfigData = RemoteConfig.getAll();

  return (
    <>
      <span
        onClick={isDialogOpenMutations.setTrue}
        style={{ cursor: 'pointer' }}
      >
        デバッグツール
      </span>
      <Dialog
        fullWidth
        onClose={isDialogOpenMutations.setFalse}
        open={isDialogOpen}
      >
        <DialogContent>
          <Typography variant="h6" className={classes.heading}>
            デバッグツール
          </Typography>
          <Card className={classes.card}>
            <Table className={classes.table}>
              <TableBody>
                <TableRow>
                  <TableCell align="left" className={classes.cell}>
                    サロン名
                  </TableCell>
                  <TableCell align="left" className={classes.cell}>
                    <Typography> {salon.name} </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" className={classes.cell}>
                    サロンID
                  </TableCell>
                  <TableCell align="left" className={classes.cell}>
                    <Typography> {salon.id} </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" className={classes.cell}>
                    スタイリスト名
                  </TableCell>
                  <TableCell align="left" className={classes.cell}>
                    <Typography> {me.name} </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" className={classes.cell}>
                    スタイリストID
                  </TableCell>
                  <TableCell align="left" className={classes.cell}>
                    <Typography> {me.id} </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Card>

          <Typography variant="h6" className={classes.heading}>
            フィーチャーフラグ
          </Typography>
          <Card className={classes.card}>
            {availableFeatures.map((feature) => (
              <Typography key={feature} variant="body1">
                {feature}
              </Typography>
            ))}
          </Card>

          <Typography variant="h6" className={classes.heading}>
            便利機能
          </Typography>
          <Card className={classes.card}>
            <Button
              onClick={showErrorMutations.setTrue}
              variant="contained"
              color="primary"
            >
              明示的にエラーを通知する
            </Button>
          </Card>

          {RemoteConfig.hasData && (
            <Card className={classes.card}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={DebugToolService.isRemoteConfigDebugEnabled()}
                    onChange={(_, checked) => {
                      DebugToolService.switchRemoteConfigDebug(checked);
                      setEditCounter(editCounter + 1);
                    }}
                  />
                }
                label="RemoteConfig Debug 有効化"
              />
              <Typography variant="body2">
                設定した値はリロードしたら消えるので注意
              </Typography>
              <Table className={classes.table}>
                <TableBody>
                  {Object.keys(remoteConfigData).map((key) => (
                    <TableRow key={key}>
                      <TableCell align="left" className={classes.cell}>
                        {key}
                      </TableCell>
                      <TableCell align="left" className={classes.cell}>
                        <TextField
                          variant="standard"
                          fullWidth
                          disabled={
                            !DebugToolService.isRemoteConfigDebugEnabled()
                          }
                          // TODO 一時的にルールを無効化しています。気づいたベースで直してください
                          // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'RemoteConfigData'.
                          value={remoteConfigData[key]}
                          inputProps={{ maxLength: 100 }}
                          onChange={(e) => {
                            DebugToolService.setData({
                              remoteConfigData: {
                                ...(debugRemoteConfigData as RemoteConfigData),
                                [key]: e.target.value,
                              },
                            });
                            setEditCounter(editCounter + 1);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Button
                onClick={() => {
                  DebugToolService.clear();
                  setEditCounter(editCounter + 1);
                }}
                color="primary"
              >
                RemoteConfig Debug クリア
              </Button>
            </Card>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={isDialogOpenMutations.setFalse} color="primary">
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CDebugTool;
