import { trpc } from '@karutekun/shared/data-access/api-base';
import { keepPreviousData } from '@tanstack/react-query';
import { VisitReservedFilter, VisitReservedSort } from './types';

export function useFetchVisitsReservedByCustomerPaginated(
  filter: VisitReservedFilter,
  sort: VisitReservedSort,
  limit: number,
  page: number
) {
  const result = trpc.visit.findReservedByCustomer.useQuery(
    { filter, sort, limit, cursor: page * limit },
    { placeholderData: keepPreviousData }
  );
  return {
    ...result,
    data: {
      count: result.data?.count ?? 0,
      visits: result.data?.visits ?? [],
    },
  };
}
