import { Card, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import CButton from '../../../../components_old/atoms/CButton';
import CDivider from '../../../../components_old/atoms/CDivider';
import CSelect from '../../../../components_old/atoms/CSelect';
import CSelectMulti from '../../../../components_old/atoms/CSelectMulti';
import CCounselingList, {
  Map as CounselingMap,
} from '../../../../components_old/molecules/CCounselingList';
import {
  Customer,
  CustomerSalonInformation as CustomerSalonInformationModel,
  emptyCustomerSalonInformation,
} from '../../../../models/customer';
import { CustomerCounselingAnswer } from '../../../../models/customerCounselingAnswer';
import {
  selectActiveVisitMotivationsBySalonId,
  selectAllSalons,
  selectHasGroupSalon,
  selectSortedActiveCounselingsBySalonId,
} from '../../../../selectors/salonSelector';
import { GlobalState } from '../../../../store';
import {
  WithConfirmDialog,
  withConfirmDialog,
} from '../../../../templates/hoc/ConfirmDialogHOC';
import { useSalonStatus } from '../../../../templates/providers/salonStatus/salonStatusContext';

export type Props = {
  visible: boolean;
  customer: Customer;

  onPressSave(
    editSalonId: number,
    customerSalonInformation: CustomerSalonInformationModel,
    userUpdatedAt: number
  ): Promise<void>;
} & WithConfirmDialog;

const useStyles = makeStyles(() => ({
  counselingCard: {
    maxHeight: '50vh',
    overflow: 'auto',
  },
}));

const CustomerSalonInformation: FC<Props> = (props) => {
  const classes = useStyles();
  const { customer, onPressSave } = props;

  const allSalons = useSelector(selectAllSalons);
  const hasGroupSalon = useSelector(selectHasGroupSalon);
  const selectCounselings = useSelector((state: GlobalState) => {
    return (salonId: number) =>
      selectSortedActiveCounselingsBySalonId(state, salonId);
  });
  const selectVisitMotivations = useSelector((state: GlobalState) => {
    return (salonId: number) =>
      selectActiveVisitMotivationsBySalonId(state, salonId);
  });

  const { checkAndOpenDialog } = useSalonStatus();

  // 顧客作成サロンを優先して最初に出す（基本的に作成サロンで回答しているはずなので）
  const [selectedSalonId, setSelectedSalonId] = useState(customer.salonId);

  const [isEdited, setIsEdited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const customerSalonInformation = useMemo(
    () =>
      emptyCustomerSalonInformation(
        customer.salonInformationMap[selectedSalonId]
      ),
    [customer, selectedSalonId]
  );
  const [visitMotivationIds, setVisitMotivationIds] = useState(
    customerSalonInformation.visitMotivationIds
  );
  const [counselingAnswers, setCounselingAnswers] = useState(
    customerSalonInformation.counselingAnswers
  );

  useEffect(() => {
    setVisitMotivationIds(customerSalonInformation.visitMotivationIds);
    setCounselingAnswers(customerSalonInformation.counselingAnswers);
    setIsEdited(false);
  }, [customerSalonInformation]);

  const visitMotivations = selectVisitMotivations(selectedSalonId);
  const counselingSections = selectCounselings(selectedSalonId);

  return (
    <div>
      {/* サロン切り替えSelectはグループサロンがあるときのみ表示すれば良い */}
      {hasGroupSalon && (
        <>
          <CSelect
            label="回答サロン"
            onChange={(id) => {
              if (id !== undefined) {
                if (isEdited) {
                  props.openConfirmDialog({
                    title: '保存されていない変更があります',
                    description:
                      'サロンを切り替えると変更が失われます。本当によろしいですか？',
                    onOk() {
                      setSelectedSalonId(id);
                      props.closeConfirmDialog();
                    },
                  });
                } else {
                  setSelectedSalonId(id);
                }
              }
            }}
            value={selectedSalonId}
            options={allSalons.map((salon) => ({
              value: salon.id,
              element: salon.name,
            }))}
          />
          <CDivider spacing={2} />
        </>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CSelectMulti
            fullWidth
            label="来店動機"
            onChange={(ids) => {
              setIsEdited(true);
              setVisitMotivationIds(ids);
            }}
            selected={visitMotivationIds}
            options={visitMotivations.map((m) => ({
              value: m.id,
              element: m.name,
            }))}
          />
        </Grid>

        <Grid item xs={12}>
          <Card className={classes.counselingCard}>
            <CCounselingList
              counselings={counselingSections}
              map={counselingAnswers.reduce(
                (prev: CounselingMap, current: CustomerCounselingAnswer) => {
                  prev[current.optionId] = { value: current.value };
                  return prev;
                },
                {}
              )}
              onChange={(map) => {
                setIsEdited(true);
                setCounselingAnswers(
                  Object.keys(map).map((questionId) => ({
                    optionId: Number(questionId),
                    // TODO 一時的にルールを無効化しています。気づいたベースで直してください
                    // @ts-expect-error: TS7015: Element implicitly has an 'any' type because index expression is not of type 'number'.
                    value: map[questionId].value,
                  }))
                );
              }}
            />
          </Card>
        </Grid>

        <Grid item container xs={12} justifyContent="flex-end">
          <CButton
            variant="contained"
            color="primary"
            isLoading={isLoading}
            disabled={!isEdited}
            onClick={async () => {
              if (!checkAndOpenDialog()) {
                return;
              }

              setIsLoading(true);
              await onPressSave(
                selectedSalonId,
                {
                  visitMotivationIds,
                  counselingAnswers,
                },
                customer.userUpdatedAt
              )
                .then(
                  () => setIsEdited(false),
                  (e) => console.log(e)
                )
                .finally(() => setIsLoading(false));
            }}
          >
            保存
          </CButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default withConfirmDialog(CustomerSalonInformation);
