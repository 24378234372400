import * as React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  legendProps,
  tooltipProps,
  xAxisProps,
  yAxisProps,
} from '../../util/chart';
import CChartTooltip from './CChartTooltip';
import CEmpty from './CEmpty';

type OwnProps<T> = {
  data: T[];
  nameKey: string | ((data: T) => string);
  valueKeys: {
    dataKey: string | ((data: T) => number);
    name: string;
    color?: string;
  }[];
  stack?: boolean;

  showLegend?: boolean;

  formatter?(value: number): string;

  onClick?(data: T): void;
};

class CChartBar<T extends {}> extends React.PureComponent<OwnProps<T>> {
  static defaultProps = {
    showLegend: true,
  };

  render() {
    const { data, nameKey, valueKeys, stack, showLegend, formatter } =
      this.props;

    if (this.props.data.length === 0) {
      return <CEmpty title="該当データがありません" />;
    }

    const tooltipFormatter = formatter
      ? (value: string | number | (string | number)[]) =>
          formatter(Number(value))
      : undefined;

    return (
      <ResponsiveContainer>
        <BarChart
          data={data}
          stackOffset="sign"
          onClick={(data) => {
            if (
              data &&
              data.activePayload &&
              data.activePayload[0] &&
              data.activePayload[0].payload
            ) {
              if (this.props.onClick) {
                this.props.onClick(data.activePayload[0].payload);
              }
            }
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          {showLegend && <Legend {...legendProps} />}
          <Tooltip
            {...tooltipProps}
            content={<CChartTooltip showTotal={stack} />}
            formatter={tooltipFormatter}
          />
          {/* 一時的に lint を無効化しています。気づいたベースで直してください */}
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          <XAxis {...xAxisProps} dataKey={nameKey as any} />
          <YAxis
            {...yAxisProps}
            tickFormatter={this.props.formatter}
            width={64}
          />
          {valueKeys.map((valueKey, i) => (
            <Bar
              key={i}
              {...(stack ? { stackId: 'a' } : {})}
              name={valueKey.name}
              dataKey={valueKey.dataKey}
              cursor={this.props.onClick ? 'pointer' : 'default'}
              fill={valueKey.color}
              isAnimationActive={false}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

export default CChartBar;
