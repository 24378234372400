import { MenuItem, TextField } from '@mui/material';
import * as React from 'react';
import { ChangeEvent } from 'react';

type OwnProps<T> = {
  label?: string;
  value?: T;
  options: {
    value: T;
    element: string;
    isInactive?: boolean;
  }[];
  emptyString?: string;
  dense?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  displayEmpty?: boolean;

  onChange(value: T | undefined): void;
};

const CSelect = <T extends number | string>(props: OwnProps<T>) => {
  const {
    label,
    value,
    options,
    emptyString = 'すべて',
    dense,
    disabled,
    fullWidth,
    displayEmpty,
    onChange,
  } = props;

  const handleChange = React.useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value === '' ? undefined : (e.target.value as T));
    },
    [onChange]
  );

  return (
    <TextField
      label={label}
      select
      variant="standard"
      value={value !== undefined ? value : ''}
      onChange={handleChange}
      SelectProps={{ displayEmpty }}
      InputLabelProps={{ shrink: true }}
      disabled={disabled}
      fullWidth={fullWidth}
    >
      {displayEmpty && (
        <MenuItem dense={dense} value="">
          {emptyString}
        </MenuItem>
      )}
      {options.map((option, i) => (
        <MenuItem
          key={i}
          dense={dense}
          value={option.value}
          style={option.isInactive ? { color: '#808080' } : {}}
        >
          {option.element}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default CSelect;
