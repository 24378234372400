import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import CChip from '../../../../../components_old/atoms/CChip';
import { State } from '../../../../../models/broadcastMessage';

type Props = {
  state: State;
};

const useStyles = makeStyles((theme: Theme) => ({
  chipGray: {
    borderColor: theme.palette.text.secondary,
    color: theme.palette.text.secondary,
  },
  chipBlue: {
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
  },
  chipYellow: {
    borderColor: '#ffa000',
    color: '#ffa000',
  },
  chipGreen: {
    borderColor: '#43a047',
    color: '#43a047',
  },
  chipRed: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));

const stateText = {
  [State.Draft]: '下書き',
  [State.Reserved]: '配信予約中',
  [State.AudienceCreated]: '配信準備中',
  [State.Published]: '配信中',
  [State.Completed]: '配信済み',
  [State.Error]: '配信失敗',
};

export const BroadcastMessageStateChip: FC<Props> = (props) => {
  const classes = useStyles();

  const { state } = props;

  return (
    <CChip
      label={stateText[state]}
      className={clsx({
        [classes.chipGray]: state === State.Draft,
        [classes.chipBlue]: state === State.Reserved,
        [classes.chipYellow]:
          state === State.AudienceCreated || state === State.Published,
        [classes.chipGreen]: state === State.Completed,
        [classes.chipRed]: state === State.Error,
      })}
    />
  );
};
