import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      d="M42 11.75H6c-.97 0-1.75-.78-1.75-1.75S5.03 8.25 6 8.25h36c.97 0 1.75.78 1.75 1.75s-.78 1.75-1.75 1.75M42 39.75H6c-.97 0-1.75-.78-1.75-1.75s.78-1.75 1.75-1.75h36c.97 0 1.75.78 1.75 1.75s-.78 1.75-1.75 1.75M38 33.52H10c-3.17 0-5.75-2.58-5.75-5.75v-7.54c0-3.17 2.58-5.75 5.75-5.75h28c3.17 0 5.75 2.58 5.75 5.75v7.54c0 3.17-2.58 5.75-5.75 5.75M10 17.98c-1.24 0-2.25 1.01-2.25 2.25v7.54c0 1.24 1.01 2.25 2.25 2.25h28c1.24 0 2.25-1.01 2.25-2.25v-7.54c0-1.24-1.01-2.25-2.25-2.25z"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgDaily = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgDaily);
export default Memo;
