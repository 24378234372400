import { ChatMessageBody } from '../../models/chatMessageBody/entity';
import { ChatMessageBodyReceipt as ChatMessageBodyReceiptSchema } from './schema';

export class ChatMessageBodyReceipt implements ChatMessageBody {
  karuteId?: number;
  voucherId: number;
  salonName: string;
  date: string;

  headerMemo?: string;
  address?: string;
  phoneNumber?: string;
  customerName?: string;
  footerMemo?: string;

  voucherLines: {
    name: string;
    price: string;
  }[];
  voucherDiscounts: {
    name: string;
    price: string;
  }[];
  prices: {
    name: string;
    price: string;
  }[];
  paymentMethods: {
    name: string;
    price: string;
  }[];
  payments: {
    name: string;
    price: string;
  }[];

  constructor(args: ChatMessageBodyReceiptSchema) {
    if (args.karuteId) {
      this.karuteId = args.karuteId;
    }

    this.voucherId = args.voucherId;
    this.salonName = args.salonName;
    this.date = args.date;

    if (args.headerMemo) {
      this.headerMemo = args.headerMemo;
    }
    if (args.address) {
      this.address = args.address;
    }
    if (args.phoneNumber) {
      this.phoneNumber = args.phoneNumber;
    }
    if (args.customerName) {
      this.customerName = args.customerName;
    }
    if (args.footerMemo) {
      this.footerMemo = args.footerMemo;
    }

    this.voucherLines = args.voucherLines;
    this.voucherDiscounts = args.voucherDiscounts;
    this.prices = args.prices;
    this.paymentMethods = args.paymentMethods;
    this.payments = args.payments;
  }
}
