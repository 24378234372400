import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import {
  Alert,
  Card,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { dispatchWithErrorHandling } from '../../../../actions/helper/dispatchWithErrorHandling';
import {
  acceptSalonRequest,
  declineSalonRequest,
  fetchSalonRequest,
} from '../../../../actions/salonJoinRequestAction';
import CHorizontalScrollHintContainer from '../../../../components_old/atoms/CHorizontalScrollHintContainer';
import CProgressOverlay from '../../../../components_old/atoms/CProgressOverlay';
import CStylistAvatar from '../../../../components_old/atoms/CStylistAvatar';
import { JoinRequest } from '../../../../models/joinRequest';
import { checkPermission } from '../../../../models/stylist';
import { selectPendingJoinRequests } from '../../../../selectors/joinRequestSelector';
import {
  useSelectMe,
  useSelectMySalon,
} from '../../../../selectors/salonSelector';
import {
  WithConfirmDialog,
  withConfirmDialog,
} from '../../../../templates/hoc/ConfirmDialogHOC';
import { formatDate } from '../../../../util/common';
import { useFlag } from '../../../../util/hooks/useFlag';
import { useThunkDispatch } from '../../../../util/hooks/useThunkDispatch';

type Props = WithConfirmDialog;

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: theme.spacing(2),
  },
}));

const JoinRequests: FC<Props> = (props) => {
  const classes = useStyles();
  const { openConfirmDialog, closeConfirmDialog } = props;

  const me = useSelectMe();
  const salon = useSelectMySalon();
  const joinRequests = useSelector(selectPendingJoinRequests);
  const dispatch = useThunkDispatch();

  const [isFetching, callWithFetching] = useFlag(true);

  const handleAccept = useCallback(
    (stylistId: number) => {
      openConfirmDialog({
        description: 'サロン所属申請を承認しますか？',
        onOk: () => {
          closeConfirmDialog();
          callWithFetching(async () => {
            await dispatchWithErrorHandling(
              dispatch,
              acceptSalonRequest(salon.id, stylistId),
              { success: 'サロン所属申請を許可しました' }
            );
          });
        },
      });
    },
    [
      openConfirmDialog,
      closeConfirmDialog,
      callWithFetching,
      dispatch,
      salon.id,
    ]
  );
  const handleDecline = useCallback(
    (stylistId: number) => {
      openConfirmDialog({
        description: 'サロン所属申請を却下しますか？',
        onOk: () => {
          closeConfirmDialog();
          callWithFetching(async () => {
            await dispatchWithErrorHandling(
              dispatch,
              declineSalonRequest(salon.id, stylistId),
              { success: 'サロン所属申請を拒否しました' }
            );
          });
        },
      });
    },
    [
      openConfirmDialog,
      closeConfirmDialog,
      callWithFetching,
      dispatch,
      salon.id,
    ]
  );

  useEffect(() => {
    callWithFetching(async () => {
      dispatch(fetchSalonRequest(salon.id));
    });
  }, [salon.id, callWithFetching, dispatch]);

  if (isFetching) {
    return <CProgressOverlay />;
  }
  const hasPermission = checkPermission(me, 'canAcceptSalonRequest');

  return (
    <div>
      <Card className={classes.card}>
        {!hasPermission && (
          <Alert severity="warning">
            サロン所属申請の承認・却下権限がありません
          </Alert>
        )}
        {joinRequests.length ? (
          <CHorizontalScrollHintContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">スタッフ</TableCell>
                  <TableCell align="left">受付日時</TableCell>
                  <TableCell align="center">承認</TableCell>
                  <TableCell align="center">却下</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {joinRequests.map((j) => (
                  <RequestRow
                    key={j.stylistId}
                    joinRequest={j}
                    disabled={!hasPermission}
                    onAccept={handleAccept}
                    onDecline={handleDecline}
                  />
                ))}
              </TableBody>
            </Table>
          </CHorizontalScrollHintContainer>
        ) : (
          <Alert severity="info">未承認のサロン所属申請はありません</Alert>
        )}
      </Card>
    </div>
  );
};

const RequestRow: FC<{
  joinRequest: JoinRequest;
  disabled?: boolean;
  onAccept(stylistId: number): void;
  onDecline(stylistId: number): void;
}> = React.memo(function StylistRow(props) {
  const { joinRequest, disabled, onAccept, onDecline } = props;

  const handleAccept = useCallback(() => {
    onAccept(joinRequest.stylistId);
  }, [onAccept, joinRequest.stylistId]);
  const handleDecline = useCallback(() => {
    onDecline(joinRequest.stylistId);
  }, [onDecline, joinRequest.stylistId]);

  return (
    <TableRow>
      <TableCell align="left">
        <Grid container spacing={2} alignItems="center" wrap="nowrap">
          <Grid item>
            <CStylistAvatar size={32} stylist={joinRequest.stylist} />
          </Grid>
          <Grid item>
            <Typography variant="body1" noWrap>
              {joinRequest.stylist.name}
            </Typography>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align="left">
        <Typography variant="body1" noWrap>
          {formatDate(joinRequest.createdAt, true)}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <IconButton onClick={handleAccept} disabled={disabled} size="large">
          <SVGIcon name="check" size="lg" />
        </IconButton>
      </TableCell>
      <TableCell align="center">
        <IconButton onClick={handleDecline} disabled={disabled} size="large">
          <SVGIcon name="times" size="lg" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
});

export default withConfirmDialog(JoinRequests);
