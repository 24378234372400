import { Box, Container, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Images } from '../../assets/images';
import { OptionLinePlanName } from '../../models/salon';
import CTextLink from '../atoms/CTextLink';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    textAlign: 'center',
  },
  titleImage: {
    width: 268,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: 64,
    [theme.breakpoints.down('sm')]: {
      width: 40,
    },
  },
}));

const CGuideLineFeature: FC = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="sm" className={classes.container}>
      <img
        src={Images.appeal.line.title}
        className={classes.titleImage}
        alt=""
      />
      <Box mt={2}>
        <Typography variant="body1">
          {OptionLinePlanName}ご利用者様限定の機能となります。
        </Typography>
        <Typography variant="body1">
          お申し込み後、初期設定が完了すると
          こちらからLINE連携機能がご利用できるようになります。
        </Typography>
      </Box>

      <Box mt={6} textAlign="left">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={3} sm={12} className={classes.iconContainer}>
                <img
                  src={Images.appeal.line.iconSchedule}
                  className={classes.icon}
                  alt=""
                />
              </Grid>
              <Grid item xs={9} sm={12}>
                <Typography variant="body1">
                  お客様のLINEから直接サロンの予約が可能に！
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={3} sm={12} className={classes.iconContainer}>
                <img
                  src={Images.appeal.line.iconChat}
                  className={classes.icon}
                  alt=""
                />
              </Grid>
              <Grid item xs={9} sm={12}>
                <Typography variant="body1">
                  KaruteKunからお客様のLINEにそのまま連絡！
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={3} sm={12} className={classes.iconContainer}>
                <img
                  src={Images.appeal.line.iconShare}
                  className={classes.icon}
                  alt=""
                />
              </Grid>
              <Grid item xs={9} sm={12}>
                <Typography variant="body1">
                  施術写真をお客様のLINEに共有可能！
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box mt={6}>
        <CTextLink
          text="LINE連携について詳しく"
          linkTo={process.env.REACT_APP_LINE_GUIDE_URL}
          typographyProps={{ variant: 'h5' }}
        />
      </Box>
      <Box mt={2}>
        <CTextLink
          text="お問い合わせ"
          linkTo={process.env.REACT_APP_CONTACT_LINE_URL}
          typographyProps={{ variant: 'h5' }}
        />
      </Box>
    </Container>
  );
};

export default CGuideLineFeature;
