import { deprecated } from 'typesafe-actions';
import { PlainUploadedPaper } from '../models/uploadedPaper';

const { createAction } = deprecated;

export enum ActionNames {
  SetUploadedPaperMap = 'uploadedPaper/SetUploadedPaperMap',
  SetUploadedPaper = 'uploadedPaper/SetUploadedPaper',
  SetUploadedPapersIsFetching = 'uploadedPaper/SetUploadedPapersIsFetching',
}

export const setUploadedPaperMap = createAction(
  ActionNames.SetUploadedPaperMap,
  (action) => {
    return (plainUploadedPaperMap: IdMap<PlainUploadedPaper>) =>
      action({ plainUploadedPaperMap });
  }
);
export const setUploadedPaper = createAction(
  ActionNames.SetUploadedPaper,
  (action) => {
    return (id: number, plainUploadedPaper: Partial<PlainUploadedPaper>) =>
      action({ id, plainUploadedPaper });
  }
);
export const setUploadedPapersIsFetching = createAction(
  ActionNames.SetUploadedPapersIsFetching,
  (action) => {
    return (isFetching: boolean) => action({ isFetching });
  }
);
