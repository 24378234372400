import { VisitHistorySort } from '@karutekun/shared/data-access/visit';
import CSelect from '../../../atoms/CSelect';

export const CVisitListSortControl: FC<{
  sort: VisitHistorySort;
  onChange?(sort: VisitHistorySort): void;
}> = ({ sort, onChange }) => {
  return (
    <CSelect
      options={[
        { value: 'startedAtDesc', element: '施術が新しい順' },
        { value: 'startedAtAsc', element: '施術が古い順' },
      ]}
      value={sort}
      onChange={(sort) => {
        if (sort) {
          onChange?.(sort);
        }
      }}
    />
  );
};
