import { ServiceType } from '@karutekun/core/salon-service';
import { VisitHistoryFilter } from '@karutekun/shared/data-access/visit';
import { Grid } from '@mui/material';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectStylists } from '../../../../selectors/salonSelector';
import { selectVisibleServiceCategoriesGroupedByType } from '../../../../selectors/serviceSelector';
import CSelect from '../../../atoms/CSelect';
import CTextInput from '../../../atoms/CTextInput';
import CDateRange from '../../../molecules/CDateRange';
import CStylistSelect from '../../../molecules/CStylistSelect';

export const CVisitListFilterControls: FC<{
  filter: VisitHistoryFilter;
  disabledFilters?: (keyof VisitHistoryFilter)[];
  onChange?(filter: VisitHistoryFilter): void;
}> = ({ filter, disabledFilters, onChange }) => {
  const updateFilter = useCallback(
    (update: Partial<VisitHistoryFilter>) => {
      onChange?.({ ...filter, ...update });
    },
    [filter, onChange]
  );

  const stylists = useSelector(selectStylists);
  const categoriesByType = useSelector(
    selectVisibleServiceCategoriesGroupedByType
  );

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <CDateRange
          label="来店日"
          clearable={true}
          from={filter.startedAt ? filter.startedAt.from : undefined}
          to={filter.startedAt ? filter.startedAt.to : undefined}
          onChange={(from, to) => {
            updateFilter({
              startedAt:
                from === undefined && to === undefined
                  ? undefined
                  : { from, to },
            });
          }}
          disabled={disabledFilters?.includes('startedAt')}
          fullWidth
        />
      </Grid>
      <Grid item container xs={6} sm={3} md={2} lg={2} xl={2}>
        <CSelect
          fullWidth
          label="会計状況"
          options={[
            { value: 1, element: '会計済み' },
            { value: 2, element: '未会計' },
          ]}
          value={
            filter.isPaid === undefined ? undefined : filter.isPaid ? 1 : 2
          }
          onChange={(val) => {
            updateFilter({
              isPaid: val === 1 ? true : val === 2 ? false : undefined,
            });
          }}
          disabled={disabledFilters?.includes('isPaid')}
          displayEmpty
        />
      </Grid>
      <Grid item container xs={6} sm={3} md={2} lg={2} xl={2}>
        <CSelect
          fullWidth
          label="主担当指名"
          options={[
            { value: 1, element: '指名有り' },
            { value: 2, element: '指名無し' },
          ]}
          value={
            filter.isShimei === undefined ? undefined : filter.isShimei ? 1 : 2
          }
          onChange={(val) => {
            updateFilter({
              isShimei: val === 1 ? true : val === 2 ? false : undefined,
            });
          }}
          disabled={disabledFilters?.includes('isShimei')}
          displayEmpty
        />
      </Grid>
      <Grid item container xs={6} sm={3} md={2} lg={2} xl={2}>
        <CStylistSelect
          fullWidth
          label="主担当"
          stylists={stylists}
          selected={filter.assignedStylistId}
          onChange={(id) => updateFilter({ assignedStylistId: id })}
          disabled={disabledFilters?.includes('assignedStylistId')}
          displayEmpty
        />
      </Grid>
      <Grid item container xs={6} sm={3} md={2} lg={2} xl={2}>
        <CStylistSelect
          fullWidth
          label="メニュー担当"
          stylists={stylists}
          selected={filter.voucherLineStylistId}
          onChange={(id) => updateFilter({ voucherLineStylistId: id })}
          disabled={disabledFilters?.includes('voucherLineStylistId')}
          displayEmpty
        />
      </Grid>
      <Grid item container xs={6} sm={3} md={2} lg={2} xl={2}>
        <CSelect
          fullWidth
          label="施術"
          options={categoriesByType[ServiceType.Menu].map((category) => ({
            value: category.id,
            element: category.name,
          }))}
          value={filter.menuCategoryId}
          onChange={(id) => updateFilter({ menuCategoryId: id })}
          disabled={disabledFilters?.includes('menuCategoryId')}
          displayEmpty
        />
      </Grid>
      <Grid item container xs={6} sm={3} md={2} lg={2} xl={2}>
        <CSelect
          fullWidth
          label="店販"
          options={categoriesByType[ServiceType.Product].map((category) => ({
            value: category.id,
            element: category.name,
          }))}
          value={filter.productCategoryId}
          onChange={(id) => updateFilter({ productCategoryId: id })}
          disabled={disabledFilters?.includes('productCategoryId')}
          displayEmpty
        />
      </Grid>
      <Grid item xs={6} sm={3} md={2} lg={2} xl={2}>
        <CTextInput
          label="施術メモ"
          value={filter.treatmentMemo ?? ''}
          onChange={(memo) => {
            updateFilter({ treatmentMemo: memo.trim() });
          }}
          disabled={disabledFilters?.includes('treatmentMemo')}
        />
      </Grid>
    </Grid>
  );
};
