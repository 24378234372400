import { VoucherUtils } from '@karutekun/core/voucher';
import {
  VoucherFormData,
  VoucherFormDefaults,
  useVoucherForm,
} from '@karutekun/shared/data-access/voucher';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect } from 'react';
import CButton from '../../../../components_old/atoms/CButton';
import { useSelectSalonServices } from '../../../../selectors/serviceSelector';
import { VoucherForm } from '../VoucherForm';

type Props = {
  open: boolean;
  defaults: VoucherFormDefaults;
  onCancel(): void;
  onComplete(voucher: VoucherFormData, totalMinutes: number): void;
};

const useStyles = makeStyles(() => ({
  dialogContent: {
    padding: 0,
  },
  dialogContentVertical: {
    flexDirection: 'column',
  },
}));

export const CreateVoucherDialog: FC<Props> = (props) => {
  const classes = useStyles();
  const { open, onCancel, onComplete, defaults } = props;

  const { serviceMap, categoryMap } = useSelectSalonServices();
  const [voucher, mutations, isEdited] = useVoucherForm(defaults, {
    serviceMap,
    categoryMap,
  });

  useEffect(() => {
    mutations.reset(defaults.data);
  }, [defaults, mutations]);

  return (
    <Dialog open={open} fullScreen onClose={onCancel}>
      <DialogContent dividers classes={{ root: classes.dialogContent }}>
        <VoucherForm
          voucher={voucher}
          voucherFormMutations={mutations}
          defaultIsShimei={defaults.isShimei}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onCancel}>
          キャンセル
        </Button>
        <CButton
          disabled={!isEdited}
          variant="contained"
          color="primary"
          onClick={() =>
            onComplete(
              voucher,
              VoucherUtils.calculateTotalServiceMinutes(
                voucher.lines,
                serviceMap
              )
            )
          }
        >
          完了
        </CButton>
      </DialogActions>
    </Dialog>
  );
};
