import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M12.5 3.25c-3.409 0-6.25 2.704-6.25 6.135v29.23c0 3.431 2.841 6.135 6.25 6.135h23c3.409 0 6.25-2.704 6.25-6.135V9.385c0-3.431-2.841-6.135-6.25-6.135zm4 8.5a1.75 1.75 0 1 0 0 3.5h15a1.75 1.75 0 1 0 0-3.5zm0 7.5a1.75 1.75 0 1 0 0 3.5h15a1.75 1.75 0 1 0 0-3.5zm0 7.5a1.75 1.75 0 1 0 0 3.5H24a1.75 1.75 0 1 0 0-3.5z"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgDocumentFill = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgDocumentFill);
export default Memo;
