import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M6 7.75c-.69 0-1.25.56-1.25 1.25v19.5h38.5V9c0-.69-.56-1.25-1.25-1.25zM1.25 9A4.75 4.75 0 0 1 6 4.25h36A4.75 4.75 0 0 1 46.75 9v24A4.75 4.75 0 0 1 42 37.75H29.48l.344 2.75H34.5a1.5 1.5 0 0 1 0 3h-21a1.5 1.5 0 0 1 0-3h4.676l.344-2.75H6A4.75 4.75 0 0 1 1.25 33zm21.917 24.247a1.5 1.5 0 1 0 1.667-2.495 1.5 1.5 0 0 0-1.667 2.495"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgDesktop = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgDesktop);
export default Memo;
