import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      d="M24.29 3.5A20.53 20.53 0 0 0 3.5 24.29 20.64 20.64 0 0 0 23.72 44.5H24a20.518 20.518 0 0 0 20.5-20.78A20.64 20.64 0 0 0 24.29 3.5M36.16 35A12.272 12.272 0 0 0 33 32.17a16.82 16.82 0 0 0-9-2.52 16.84 16.84 0 0 0-9 2.52A11.998 11.998 0 0 0 11.85 35 16.45 16.45 0 0 1 24 7.58 16.41 16.41 0 0 1 36.16 35"
    />
    <path
      fill={props.color || 'currentColor' || '#000'}
      d="M24 13.5a6.2 6.2 0 0 0-6.36 6.82C17.92 24 20.77 27 24 27c3.23 0 6.08-3 6.36-6.68A6.23 6.23 0 0 0 24 13.5"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgUserCircle = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgUserCircle);
export default Memo;
