import { IStylistStats } from '@karutekun/core/stats/stylist-stats';
import { Grid, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import { InformationText } from '../../../texts/infomation';
import CInformation from '../../atoms/CInformation';
import CMoneyTypography from '../../atoms/CMoneyTypography';
import CProgressOverlay from '../../atoms/CProgressOverlay';

type Props = {
  stats: Nullable<IStylistStats>;
  isFetching: boolean;
  displayTaxIncludedPrice: boolean;
};

export const CStylistCustomerStatsCard: FC<Props> = (props) => {
  const { isFetching, displayTaxIncludedPrice, stats } = props;

  const firstVisitNum = stats?.firstVisitNum ?? 0;
  const repeatVisitNum = stats?.repeatVisitNum ?? 0;
  const shimeiNum = stats?.isShimeiMap?.[1] ?? 0;
  const notShimeiNum = stats?.isShimeiMap?.[0] ?? 0;
  const averageSales =
    (displayTaxIncludedPrice
      ? stats?.averageSalesPerCustomer
      : stats?.averageSalesPerCustomerWithoutTax) ?? 0;

  return (
    <Card>
      <Grid
        container
        item
        xs={12}
        style={{ position: 'relative', textAlign: 'center' }}
      >
        {isFetching && <CProgressOverlay />}

        <CInformation
          type="dialog"
          content={InformationText.stylists.customersSummary}
          corner
          inverse
        />

        <Grid item xs={12} style={{ backgroundColor: '#ffc107', padding: 8 }}>
          <Typography variant="h4" style={{ color: '#fff' }}>
            {firstVisitNum + repeatVisitNum}回
          </Typography>
          <Typography variant="h6" style={{ color: '#fff' }}>
            主担当回数
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
        >
          <Grid container item xs={8}>
            <Grid container style={{ padding: 8 }}>
              <Grid item xs={6}>
                <Typography variant="h6">{firstVisitNum}回</Typography>
                <Typography color="textSecondary" variant="subtitle1">
                  新規
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">{repeatVisitNum}回</Typography>
                <Typography color="textSecondary" variant="subtitle1">
                  固定
                </Typography>
              </Grid>
            </Grid>

            <Divider variant="middle" style={{ flex: 1 }} />

            <Grid container style={{ padding: 8 }}>
              <Grid item xs={6}>
                <Typography variant="h6">{shimeiNum}回</Typography>
                <Typography color="textSecondary" variant="subtitle1">
                  指名有り
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">{notShimeiNum}回</Typography>
                <Typography color="textSecondary" variant="subtitle1">
                  指名無し
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="column"
            xs={4}
            style={{
              height: '80%',
              borderLeft: '1px solid #d9d9d9',
            }}
            justifyContent="center"
            alignItems="center"
          >
            <CMoneyTypography variant="h6" amount={averageSales} />
            <Typography color="textSecondary" variant="subtitle1">
              単価
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
