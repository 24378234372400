import { getAge } from '@karutekun/core/customer';
import { Grid } from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import * as _ from 'lodash';
import React from 'react';
import { Customer } from '../../models/customer';
import Routing from '../../util/routing';
import CButton from '../atoms/CButton';
import CCustomerSexIcon from '../atoms/CCustomerSexIcon';
import CLoyaltySegmentChip from '../atoms/CLoyaltySegmentChip';

type OwnProps = {
  customer: Pick<
    Customer,
    | 'id'
    | 'name'
    | 'nameKana'
    | 'sex'
    | 'loyaltySegment'
    | 'birthdayYear'
    | 'birthdayMonth'
    | 'birthdayDay'
  >;
  openNewWindow?: boolean;
};

const CCustomerCard: FC<OwnProps> = React.memo(
  function CCustomerCard(props) {
    const { customer } = props;
    const age = getAge(customer);

    return (
      <Card square>
        <CButton variant="text" linkTo={Routing.customer(customer.id)}>
          <Grid container alignItems="flex-end" spacing={2}>
            <Grid item>
              <Typography variant="caption" color="textSecondary">
                {customer.nameKana}
              </Typography>
              <Typography variant="h6" color="textPrimary">
                {customer.name} 様
              </Typography>
            </Grid>

            <Grid item>
              <Typography
                display="block"
                variant="subtitle2"
                color="textSecondary"
              >
                <CCustomerSexIcon
                  sex={customer.sex}
                  style={{ marginRight: 5 }}
                />
                {age !== null ? `${age} 歳` : '年齢不明'}
              </Typography>
            </Grid>

            <Grid item>
              <CLoyaltySegmentChip loyaltySegment={customer.loyaltySegment} />
            </Grid>
          </Grid>
        </CButton>
      </Card>
    );
  },
  (prev, next) => _.isEqual(prev, next)
);

export default CCustomerCard;
