import { getPermissionName } from '@karutekun/core/permission-role';
import {
  IVisitImage,
  VisitHistoryResource,
  VisitImageType,
  VisitUtils,
} from '@karutekun/core/visit';
import { VoucherUtils } from '@karutekun/core/voucher';
import { RouterInput, trpc } from '@karutekun/shared/data-access/api-base';
import { useInvalidateVisits } from '@karutekun/shared/data-access/visit';
import { objectKeys, pick } from '@karutekun/shared/util/objects';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import {
  pushSnackbarError,
  pushSnackbarSuccess,
} from '../../../../actions/generalAction';
import CButton from '../../../../components_old/atoms/CButton';
import CInformation from '../../../../components_old/atoms/CInformation';
import CMenuButton from '../../../../components_old/atoms/CMenuButton';
import {
  useSelectMe,
  useSelectMySalon,
} from '../../../../selectors/salonSelector';
import { useSalonStatus } from '../../../../templates/providers/salonStatus/salonStatusContext';
import { useSimpleDialog } from '../../../../templates/providers/simpleDialog/simpleDialogContext';
import { useThunkDispatch } from '../../../../util/hooks/useThunkDispatch';
import { VisitFormAssignedStylist } from '../form/VisitFormAssignedStylist';
import { VisitFormDatetime } from '../form/VisitFormDatetime';
import { VisitFormIsShimei } from '../form/VisitFormIsShimei';
import { VisitFormSource } from '../form/VisitFormSource';
import { VisitFormTreatmentMemo } from '../form/VisitFormTreatmentMemo';

type FormData = Required<RouterInput['visit']['updateVisitHistory']['visit']>;

export const VisitHistoryFormCard: FC<{
  visitHistory: VisitHistoryResource;
}> = (props) => {
  const { visitHistory } = props;

  const dispatch = useThunkDispatch();
  const { open } = useSimpleDialog();
  const { checkAndOpenDialog } = useSalonStatus();

  const me = useSelectMe();
  const salon = useSelectMySalon();
  const {
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { isDirty, dirtyFields },
  } = useForm<FormData>({
    values: {
      ...pick(visitHistory, [
        'assignedStylistId',
        'startedAt',
        'finishedAt',
        'isShimei',
      ]),
      treatmentMemo:
        visitHistory.treatmentMemo || salon.customSetting.initialKaruteMemo,
    },
  });

  const invalidateVisits = useInvalidateVisits();
  const { mutate: updateVisitHistory, isPending } =
    trpc.visit.updateVisitHistory.useMutation({
      async onSuccess(_, variables) {
        await invalidateVisits();
        dispatch(pushSnackbarSuccess('来店情報を保存しました'));
      },
      onError(e, data) {
        if (e.data?.karutekunErrorCode === 'CONFLICT') {
          open({
            content:
              '直前に他スタッフによって更新されています。このまま更新すると、直前の変更内容が失われる可能性があります。このまま更新しますか?',
            onOk: async () =>
              updateVisitHistory({
                ...data,
                skipConflictCheck: true,
              }),
          });
        }
        dispatch(
          pushSnackbarError(e.data?.messageForUser ?? 'エラーが発生しました')
        );
      },
    });

  const [selectedImageUrl, setSelectedImageUrl] = useState<string | null>(null);

  const isOtherSalon = visitHistory.salonId !== salon.id;
  const isVoucherPaid = VoucherUtils.isPaid(visitHistory.voucher);

  const disabled = isOtherSalon;
  const disabledAfterPayment = disabled || isVoucherPaid;

  useEffect(() => {
    setSelectedImageUrl(null);
  }, [visitHistory]);

  const handleSave = useCallback(() => {
    if (!checkAndOpenDialog()) {
      return;
    }

    handleSubmit((data) => {
      updateVisitHistory({
        id: visitHistory.id,
        userUpdatedAt: visitHistory.userUpdatedAt,
        visit: pick(data, objectKeys(dirtyFields)),
      });
    })();
  }, [
    checkAndOpenDialog,
    dirtyFields,
    handleSubmit,
    updateVisitHistory,
    visitHistory.id,
    visitHistory.userUpdatedAt,
  ]);

  const imagesByType = VisitUtils.groupImagesByType(visitHistory.images);

  const navigate = useNavigate();
  const { mutate: deleteVisitHistory } =
    trpc.visit.deleteVisitHistory.useMutation({
      async onSuccess() {
        dispatch(pushSnackbarSuccess('来店記録を削除しました'));
        navigate(`/visit_histories`, {
          replace: true,
        });
      },
    });

  const hasDeletePermission = VisitUtils.checkDeletePermission(
    visitHistory,
    me.permissionRole
  );

  return (
    <Card style={{ width: '100%' }}>
      <CardHeader title="来店記録" />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={12}>
            <VisitFormDatetime
              startedAt={watch('startedAt')}
              finishedAt={watch('finishedAt')}
              onChange={({ startedAt, finishedAt }) => {
                setValue('startedAt', startedAt, { shouldDirty: true });
                setValue('finishedAt', finishedAt, { shouldDirty: true });
              }}
              disabled={disabledAfterPayment}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={6}>
            <Controller
              name="isShimei"
              control={control}
              render={({ field: { value, onChange } }) => (
                <VisitFormIsShimei
                  isShimei={value}
                  onChange={onChange}
                  disabled={disabledAfterPayment}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={6}>
            <Controller
              name="assignedStylistId"
              control={control}
              render={({ field: { value, onChange } }) => (
                <VisitFormAssignedStylist
                  salonId={visitHistory.salonId}
                  assignedStylistId={value}
                  onChange={onChange}
                  disabled={disabledAfterPayment}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="treatmentMemo"
              control={control}
              render={({ field: { value, onChange } }) => (
                <VisitFormTreatmentMemo
                  fullWidth
                  treatmentMemo={value}
                  onChange={onChange}
                  disabled={disabled}
                />
              )}
            />
          </Grid>
        </Grid>

        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
          py={2}
        >
          <Box mr={2}>
            <CButton
              isLoading={isPending}
              color="primary"
              disabled={!isDirty}
              onClick={handleSave}
            >
              保存
            </CButton>
          </Box>
          <Box>
            <CMenuButton
              fullWidth
              variant="outlined"
              menus={[
                {
                  title: 'この来店記録を削除する',
                  onClick: () => {
                    open({
                      content: '本当にこの来店記録を削除しますか？',
                      onOk: async () =>
                        deleteVisitHistory({ id: visitHistory.id }),
                    });
                  },
                },
              ]}
              disabled={disabled || !hasDeletePermission}
            >
              その他
            </CMenuButton>
          </Box>
          <CInformation
            type="tooltip"
            content={`${getPermissionName(
              'canDeleteKarute'
            )}権限を持ったスタッフのみ削除可能です。`}
          />
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">予約情報</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              label="予約時メモ"
              value={visitHistory.reservationMemo || 'メモなし'}
              variant="outlined"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item minWidth={260}>
            <VisitFormSource
              salonId={salon.id}
              source={visitHistory.source}
              isConfirmedByStaff={visitHistory.isConfirmedByStaff}
              createdStylistId={visitHistory.createdStylistId}
              createdAt={visitHistory.createdAt}
              disabled
            />
          </Grid>
          <Grid item xs={6} />
        </Grid>

        <Grid container spacing={2} mt={2}>
          <Grid item xs={12}>
            <Typography variant="h6">施術写真</Typography>
          </Grid>
          <Grid container item xs={6}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">施術後</Typography>
            </Grid>
            <Grid container item xs={12}>
              <Thumbnails
                images={imagesByType[VisitImageType.After]}
                max={3}
                onChange={setSelectedImageUrl}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">施術前</Typography>
            </Grid>
            <Grid container item xs={12}>
              <Thumbnails
                images={imagesByType[VisitImageType.Before]}
                max={3}
                onChange={setSelectedImageUrl}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">その他</Typography>
            </Grid>
            <Grid container item xs={12}>
              <Thumbnails
                images={imagesByType[VisitImageType.Other]}
                max={2}
                onChange={setSelectedImageUrl}
              />
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <img
              src={
                selectedImageUrl ||
                visitHistory.imageUrl ||
                '/static/images/noimage.png'
              }
              alt=""
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'cover',
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const Thumbnails: FC<{
  images: IVisitImage[];
  max: number;
  onChange(url: string): void;
}> = (props) => {
  const { images, max, onChange } = props;
  return (
    <>
      {Array(max)
        .fill(1)
        .map((_, i) => (
          <Grid
            key={images[i] ? images[i].url : i}
            item
            xs={4}
            style={{ padding: 10 }}
          >
            <img
              {...(images[i]
                ? {
                    onClick: () => onChange(images[i].url),
                    onMouseOver: () => onChange(images[i].url),
                  }
                : {})}
              src={images[i] ? images[i].url : '/static/images/noimage.png'}
              alt=""
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'cover',
                cursor: images[i] ? 'pointer' : 'default',
              }}
            />
          </Grid>
        ))}
    </>
  );
};
