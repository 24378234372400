import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      d="M24 45.25a7.76 7.76 0 0 1-7.75-7.5H9.53a3.75 3.75 0 0 1-3.45-2.12 3.56 3.56 0 0 1 .47-3.79l.45-.56c2-2.45 3.25-3.93 3.26-10.9 0-6.51 2.6-10.76 8-13A6.21 6.21 0 0 1 24 2.75a6.21 6.21 0 0 1 5.77 4.65c5.36 2.21 8 6.46 8 13 0 7 1.22 8.45 3.25 10.9l.46.56a3.58 3.58 0 0 1 .47 3.8 3.77 3.77 0 0 1-3.44 2.11h-6.76A7.76 7.76 0 0 1 24 45.25m-4.24-7.5a4.25 4.25 0 0 0 8.48 0zm19-3.7-.45-.54c-2.51-3-4-5.3-4-13.13 0-6.52-3.12-8.64-5.84-9.76a3.42 3.42 0 0 1-1.95-2.11A2.88 2.88 0 0 0 24 6.25a2.86 2.86 0 0 0-2.44 2.25 3.41 3.41 0 0 1-1.95 2.12c-2.72 1.12-5.84 3.24-5.84 9.76 0 7.84-1.55 10.09-4.06 13.13l-.45.54c0 .16.12.2.27.2h28.95c.14 0 .27-.06.27-.11a.13.13 0 0 0-.01-.09z"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgBell = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgBell);
export default Memo;
