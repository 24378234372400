import { ActionType, getType } from 'typesafe-actions';
import * as actions from '../actions/analyticsServiceAction';
import { ServiceSummary, SummaryWithStylist } from '../models/serviceSummary';

export type AnalyticsServiceState = {
  menuSummaries?: ServiceSummary[];
  productSummaries?: ServiceSummary[];
  termSummary?: SummaryWithStylist;
};

const initialState: AnalyticsServiceState = {
  termSummary: undefined,
  menuSummaries: [],
  productSummaries: [],
};

export type AnalyticsServiceActions = ActionType<typeof actions>;

function analyticsServiceReducer(
  state: AnalyticsServiceState = initialState,
  action: AnalyticsServiceActions
) {
  switch (action.type) {
    case getType(actions.setMenuSummaries): {
      const { summaries } = action.payload;
      return {
        ...state,
        menuSummaries: summaries,
      };
    }
    case getType(actions.setProductSummaries): {
      const { summaries } = action.payload;
      return {
        ...state,
        productSummaries: summaries,
      };
    }
    case getType(actions.setTermSummary): {
      const { summary } = action.payload;
      return {
        ...state,
        termSummary: summary,
      };
    }
    default:
      return state;
  }
}

export default analyticsServiceReducer;
