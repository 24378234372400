import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import {
  Box,
  Checkbox,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Property } from 'csstype';
import React from 'react';
import { isReactText } from '../../util/common';
import CInformation from '../atoms/CInformation';

/**
 * ページング無し、渡されたデータでsortも含めて表示が完結するテーブル
 */
const defaultMinWidth = 60;
type SortOrder = 'asc' | 'desc';
type Row = {
  id: number;
  isChecked?: boolean;
  onClick?(): void;
  collapsedRows?: CollapsedRow[];
  // 型定義がうまくいっていないが、一旦 unknown 使ってしのいでる
  // [key: string]: React.ReactNode;
  [key: string]: unknown;
};

type CollapsedRow = {
  id: number;
  [key: string]: React.ReactNode;
};

export type Columns = {
  [key: string]: {
    label: string;
    cInformationContent?: string;
    sorting?: boolean; // Numberソート以上のことがやりたくなったら要拡張
    align?: Property.TextAlign;
    minWidth?: number;
    // TODO 一時的に lint を無効化しています。気づいたベースで直してください
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formatLabel?(value: any): string;
  };
};

type Props = {
  columns: Columns;
  collapsedColumnNames: Columns;
  rows: Row[];
  totalRow?: Row;
  onCheckButtonPressed?(id: number): void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 'inherit',
    overflowX: 'auto',
    maxHeight: 600,
  },
  headerCell: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.primary.contrastText,
    fontSize: '80%',
    fontWeight: 800,
    textAlign: 'center',
    padding: 3,
  },
  row: {
    // 折りたたみが偶数行に入るので奇数業を交互に色変更
    '&:nth-of-type(4n+1)': {
      backgroundColor: theme.palette.background.paper,
    },
    '&:nth-of-type(4n+3)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  headerText: {
    fontWeight: 'bold',
    padding: theme.spacing(1),
  },
  collapsedHeaderText: {
    fontWeight: 'bold',
  },
}));

// sort用の関数群
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

// TODO 一時的に lint を無効化しています。気づいたベースで直してください
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getComparator<Key extends keyof any>(
  order: SortOrder,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const CCollapsibleTable: FC<Props> = (props) => {
  const { rows, totalRow, columns, collapsedColumnNames } = props;
  const classes = useStyles();

  const keys = Object.keys(columns);
  const [sortOrder, setSortOrder] = React.useState<SortOrder | undefined>(
    'desc'
  );
  const [sortKey, setSortKey] = React.useState<string | undefined>(undefined);
  const sortedRows =
    sortKey && sortOrder
      ? (stableSort(
          rows as { [key: string]: string | number }[],
          getComparator(sortOrder, sortKey)
        ) as Row[])
      : rows;

  const hasCheckButton = props.onCheckButtonPressed !== undefined;
  return (
    <div className={classes.root}>
      <Table size="small">
        <TableHead>
          <TableRow>
            {hasCheckButton && (
              <TableCell
                padding="checkbox"
                className={classes.headerCell}
              ></TableCell>
            )}
            <TableCell
              className={classes.headerCell}
              padding="none"
            ></TableCell>
            {keys.map((key) => {
              const column = columns[key];
              const label = (
                <Typography
                  variant="body2"
                  className={classes.collapsedHeaderText}
                >
                  {column.label}
                  {column.cInformationContent !== undefined && (
                    <CInformation
                      inverse
                      type="tooltip"
                      content={column.cInformationContent}
                    />
                  )}
                </Typography>
              );
              return (
                <TableCell
                  key={`column-${key}`}
                  className={classes.headerCell}
                  style={{
                    minWidth:
                      column.minWidth !== undefined
                        ? column.minWidth
                        : defaultMinWidth,
                  }}
                >
                  {column.sorting ? (
                    <TableSortLabel
                      active={sortKey === key}
                      direction={sortOrder}
                      onClick={() => {
                        if (sortKey !== key) {
                          setSortKey(key);
                          setSortOrder('desc');
                        } else {
                          setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
                        }
                      }}
                    >
                      {label}
                    </TableSortLabel>
                  ) : (
                    label
                  )}
                </TableCell>
              );
            })}
          </TableRow>
          {totalRow !== undefined && (
            <RowComponent
              row={totalRow}
              columns={columns}
              collapsedColumnNames={collapsedColumnNames}
              showEmptyCheckBoxCell={true}
            />
          )}
        </TableHead>
        {sortedRows.length > 0 ? (
          <TableBody>
            {sortedRows.map((row) => (
              <RowComponent
                key={`${row.id}`}
                row={row}
                columns={columns}
                collapsedColumnNames={collapsedColumnNames}
                onCheckButtonPressed={props.onCheckButtonPressed}
              />
            ))}
          </TableBody>
        ) : (
          <caption>データが存在しません</caption>
        )}
      </Table>
    </div>
  );
};

const RowComponent: FC<{
  row: Row;
  columns: Columns;
  collapsedColumnNames: Columns;
  showEmptyCheckBoxCell?: boolean; // onCheckButtonPressed===undefinedの場合でもcellを表示するか
  onCheckButtonPressed?(id: number): void;
}> = React.memo(function RowComponent(props) {
  const classes = useStyles();
  const {
    row,
    columns,
    collapsedColumnNames,
    showEmptyCheckBoxCell,
    onCheckButtonPressed,
  } = props;

  const keys = Object.keys(columns);
  const hasCheckButtonCell =
    onCheckButtonPressed !== undefined || showEmptyCheckBoxCell;
  const [open, setOpen] = React.useState(false);

  // checkBox以外にonClickをつける
  const cellProps: Partial<TableCellProps> = {
    onClick: () => setOpen(!open),
    style: { cursor: 'pointer', textDecoration: 'none' },
  };

  return (
    <>
      <TableRow className={classes.row} key={`row-${row.id}`}>
        {hasCheckButtonCell && (
          <TableCell padding="checkbox">
            {onCheckButtonPressed !== undefined && (
              <Checkbox
                edge="start"
                checked={row.isChecked}
                onClick={() => onCheckButtonPressed(row.id)}
              />
            )}
          </TableCell>
        )}
        <TableCell padding="none" {...cellProps}>
          {open ? <SVGIcon name="angle-up" /> : <SVGIcon name="angle-down" />}
        </TableCell>
        {keys.map((key) => {
          const column = columns[key];
          const formatLabel = column.formatLabel;
          const content = row[key];
          return (
            <TableCell
              key={`row-${row.id}-${key}`}
              style={{
                textAlign: column.align || 'right',
                fontSize: '80%',
                padding: 4,
              }}
              {...cellProps}
            >
              {isReactText(content) ? (
                <Typography variant="body2" color="textPrimary">
                  {formatLabel ? formatLabel(content) : content}
                </Typography>
              ) : (
                (content as React.ReactElement)
              )}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={hasCheckButtonCell ? 2 : 1}
          style={{ padding: 0 }}
        ></TableCell>
        <TableCell style={{ padding: 0 }} colSpan={keys.length}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <CollapsedTable row={row} columns={collapsedColumnNames} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
});

const CollapsedTable: FC<{
  row: Row;
  columns: Columns;
}> = (props) => {
  const classes = useStyles();
  const { row, columns } = props;
  const keys = Object.keys(columns);

  const [sortOrder, setSortOrder] = React.useState<SortOrder | undefined>(
    'desc'
  );
  const [sortKey, setSortKey] = React.useState<string | undefined>(undefined);
  const collapsedRows = row.collapsedRows ?? [];
  const sortedRows =
    sortKey && sortOrder
      ? (stableSort(
          collapsedRows as { [key: string]: string | number }[],
          getComparator(sortOrder, sortKey)
        ) as Row[])
      : collapsedRows;

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          {keys.map((key) => {
            const column = columns[key];
            const label = (
              <Typography
                variant="body2"
                className={classes.collapsedHeaderText}
              >
                {column.label}
                {column.cInformationContent !== undefined && (
                  <CInformation
                    type="tooltip"
                    content={column.cInformationContent}
                  />
                )}
              </Typography>
            );
            return (
              <TableCell
                key={`column-${key}`}
                style={{
                  fontSize: '80%',
                  fontWeight: 800,
                  textAlign: 'center',
                  padding: 3,
                  minWidth:
                    column.minWidth !== undefined
                      ? column.minWidth
                      : defaultMinWidth,
                }}
              >
                {column.sorting ? (
                  <TableSortLabel
                    active={sortKey === key}
                    direction={sortOrder}
                    onClick={() => {
                      if (sortKey !== key) {
                        setSortKey(key);
                        setSortOrder('desc');
                      } else {
                        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
                      }
                    }}
                  >
                    {label}
                  </TableSortLabel>
                ) : (
                  label
                )}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedRows.map((collapsedRow) => (
          <TableRow key={collapsedRow.id}>
            {keys.map((key) => {
              const column = columns[key];
              const content = collapsedRow[key];
              return (
                <TableCell
                  key={`row-${collapsedRow.id}-${key}`}
                  style={{
                    textAlign: column.align || 'right',
                    fontSize: '80%',
                    padding: 4,
                  }}
                >
                  {isReactText(content) ? (
                    <Typography variant="body2" color="textPrimary">
                      {column.formatLabel
                        ? column.formatLabel(content)
                        : content}
                    </Typography>
                  ) : (
                    (content as React.ReactElement)
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default CCollapsibleTable;
