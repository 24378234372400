import { CustomerLoyaltySegment } from '@karutekun/core/customer';
import { trpc } from '@karutekun/shared/data-access/api-base';
import { monthRange } from '@karutekun/shared/util/datetime';
import { mapBy } from '@karutekun/shared/util/objects';
import { Card } from '@mui/material';
import { keepPreviousData } from '@tanstack/react-query';
import { memo, useMemo } from 'react';
import CChartBar from '../../../../components_old/atoms/CChartBar';
import CMonthRangeControl from '../../../../components_old/molecules/CMonthRangeControl';
import CChartWrapper from '../../../../components_old/organisms/charts/CChartWrapper';
import { InformationText } from '../../../../texts/infomation';
import { fmtCustomerNum, fmtMonth } from '../../../../util/chart';

type Props = {
  stylistId: number;
  from: string;
  to: string;
  onChangeMonth(from: string, to: string): void;
};

// TODO: グラフの色指定は他の箇所でも似たようなコードが散在するので、共通化する余地ある
const lostCustomerValueKeys = [
  {
    dataKey: 'repeatLost',
    name: '固定失客',
    color: '#ffab4f',
  },
  {
    dataKey: 'newLost',
    name: '新規失客',
    color: '#ffd34f',
  },
];

export const StylistLostCustomerStatsCard: FC<Props> = memo(
  ({ stylistId, from, to, onChangeMonth }) => {
    const newLostResult =
      trpc.analytics.lostCustomerStats.monthlyStats.useQuery(
        {
          loyaltySegment: CustomerLoyaltySegment.NewLost,
          range: { from, to },
        },
        { placeholderData: keepPreviousData }
      );
    const repeatLostResult =
      trpc.analytics.lostCustomerStats.monthlyStats.useQuery(
        {
          loyaltySegment: CustomerLoyaltySegment.RepeatLost,
          range: { from, to },
        },
        { placeholderData: keepPreviousData }
      );

    const chartData = useMemo(() => {
      const data = [];
      const newLostDataMap = mapBy(newLostResult.data ?? [], 'month');
      const repeatLostDataMap = mapBy(repeatLostResult.data ?? [], 'month');
      for (const month of monthRange({ from, to })) {
        data.push({
          month: fmtMonth(month),
          newLost: newLostDataMap[month]?.assignedStylistMap?.[stylistId] ?? 0,
          repeatLost:
            repeatLostDataMap[month]?.assignedStylistMap?.[stylistId] ?? 0,
        });
      }
      return data;
    }, [stylistId, from, to, newLostResult.data, repeatLostResult.data]);

    return (
      <Card>
        <CChartWrapper
          title="失客数 推移"
          isFetching={
            newLostResult.isRefetching || repeatLostResult.isRefetching
          }
          informationText={InformationText.stylists.monthlyLost}
          rightComponent={
            <div style={{ flex: 1 }}>
              <CMonthRangeControl
                from={from}
                to={to}
                onChange={(from, to) => onChangeMonth(from, to)}
              />
            </div>
          }
        >
          <CChartBar
            data={chartData}
            nameKey="month"
            formatter={fmtCustomerNum}
            valueKeys={lostCustomerValueKeys}
            stack
          />
        </CChartWrapper>
      </Card>
    );
  }
);
