import { ReservationResource } from '@karutekun/core/visit';
import { VoucherResource, VoucherUtils } from '@karutekun/core/voucher';
import { useInvalidateVisits } from '@karutekun/shared/data-access/visit';
import {
  useShouldConfirmChangeReservationTime,
  useUpdateVoucher,
  useVoucherForm,
} from '@karutekun/shared/data-access/voucher';
import { useBoolean } from '@karutekun/shared/util/react-hooks';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback, useEffect, useRef } from 'react';
import {
  pushSnackbarError,
  pushSnackbarSuccess,
} from '../../../../actions/generalAction';
import CBackdrop from '../../../../components_old/atoms/CBackdrop';
import CButton from '../../../../components_old/atoms/CButton';
import { useSelectSalonServices } from '../../../../selectors/serviceSelector';
import { useSimpleDialog } from '../../../../templates/providers/simpleDialog/simpleDialogContext';
import { useThunkDispatch } from '../../../../util/hooks/useThunkDispatch';
import { ConfirmChangeReservationTimeDialog } from '../ConfirmChangeReservationTimeDialog';
import { VoucherForm } from '../VoucherForm';

type Props = {
  open: boolean;
  voucher: VoucherResource;
  showPaymentButton?: boolean;
  onClose(showPaymentDialog?: boolean): void;

  // 関連する予約がある場合は指定する
  // 以下の処理を行うために必要になります。
  // - 会計前のチェックイン処理
  // - メニュー時間が変わる場合に、予約の終了時刻を変更するかどうかの確認
  reservation?: ReservationResource;
};

const useStyles = makeStyles(() => ({
  dialogContent: {
    padding: 0,
  },
  dialogContentVertical: {
    flexDirection: 'column',
  },
}));

export const EditVoucherDialog: FC<Props> = (props) => {
  const classes = useStyles();
  const dispatch = useThunkDispatch();
  const {
    open,
    onClose,
    voucher: originalVoucher,
    showPaymentButton = true,
    reservation,
  } = props;

  const simpleDialog = useSimpleDialog();

  const { serviceMap, categoryMap } = useSelectSalonServices();
  const [voucher, mutations, isEdited] = useVoucherForm(
    {
      stylistId: originalVoucher.stylistId,
      isShimei: originalVoucher.isShimei,
      data: originalVoucher,
    },
    { serviceMap, categoryMap }
  );

  const [
    isConfirmChangeReservationTimeModalVisible,
    isConfirmChangeReservationTimeModalVisibleMutations,
  ] = useBoolean();

  const { shouldConfirm, afterMinutes } = useShouldConfirmChangeReservationTime(
    reservation,
    {
      beforeLines: originalVoucher.lines,
      afterLines: voucher.lines,
      serviceMap,
    }
  );

  const invalidateVisits = useInvalidateVisits();

  const savingWithPayment = useRef(false);
  const { mutate: updateVoucher, isPending } = useUpdateVoucher({
    async onSuccess() {
      await invalidateVisits();
      onClose(savingWithPayment.current);
      dispatch(pushSnackbarSuccess('メニューを保存しました'));
    },
    showConfirmation({ title, message }, onOk) {
      simpleDialog.open({
        title,
        content: message,
        onOk: async () => onOk(),
      });
    },
    onUnhandledError(e) {
      pushSnackbarError(e.data?.messageForUser ?? 'エラーが発生しました');
    },
  });

  const save = useCallback(
    (gotoPayment: boolean) => {
      if (!isEdited) {
        onClose(gotoPayment);
      }

      if (shouldConfirm) {
        isConfirmChangeReservationTimeModalVisibleMutations.setTrue();
        return;
      }

      savingWithPayment.current = gotoPayment;
      updateVoucher({
        id: originalVoucher.id,
        userUpdatedAt: originalVoucher.userUpdatedAt,
        voucher,
      });
    },
    [
      isConfirmChangeReservationTimeModalVisibleMutations,
      isEdited,
      onClose,
      originalVoucher.id,
      originalVoucher.userUpdatedAt,
      shouldConfirm,
      updateVoucher,
      voucher,
    ]
  );

  const saveWithTimeAdjustment = useCallback(
    (adjustedFinishedAt?: number) => {
      isConfirmChangeReservationTimeModalVisibleMutations.setFalse();

      updateVoucher({
        id: originalVoucher.id,
        userUpdatedAt: originalVoucher.userUpdatedAt,
        voucher,
        adjustedVisitFinishedAt: adjustedFinishedAt,
      });
    },
    [
      isConfirmChangeReservationTimeModalVisibleMutations,
      originalVoucher.id,
      originalVoucher.userUpdatedAt,
      updateVoucher,
      voucher,
    ]
  );

  useEffect(() => {
    if (open) {
      mutations.set(originalVoucher);
    }
  }, [mutations, open, originalVoucher]);

  const payable = VoucherUtils.isPayable(voucher);

  return (
    <>
      <Dialog open={open} fullScreen onClose={() => onClose()}>
        <CBackdrop open={isPending} />
        <DialogContent dividers classes={{ root: classes.dialogContent }}>
          <VoucherForm
            voucher={voucher}
            voucherFormMutations={mutations}
            defaultIsShimei={originalVoucher.isShimei}
          />
        </DialogContent>
        <DialogActions>
          <CButton variant="outlined" color="primary" onClick={() => onClose()}>
            キャンセル
          </CButton>
          <CButton
            isLoading={isPending}
            disabled={!isEdited}
            variant="contained"
            color="primary"
            onClick={() => save(false)}
          >
            保存
          </CButton>
          {showPaymentButton && (
            <CButton
              isLoading={isPending}
              disabled={!payable}
              variant="contained"
              color="primary"
              onClick={() => save(true)}
            >
              お会計
            </CButton>
          )}
        </DialogActions>
      </Dialog>
      {reservation && (
        <ConfirmChangeReservationTimeDialog
          key={afterMinutes}
          open={isConfirmChangeReservationTimeModalVisible}
          afterMinutes={afterMinutes}
          startedAt={reservation.startedAt}
          finishedAt={reservation.finishedAt}
          onCancel={
            isConfirmChangeReservationTimeModalVisibleMutations.setFalse
          }
          onConfirm={saveWithTimeAdjustment}
        />
      )}
    </>
  );
};
