import { isoWeekday } from '@karutekun/shared/util/datetime';
import { HolidayMap } from '@karutekun/shared/util/holiday';
import { AppColor } from '@karutekun/shared-fe/design-token';

export function getCalendarDateColors(date: string) {
  const dateInfo = getDateInfo(date);
  const dateColor = dateInfo.isSaturday
    ? AppColor.calendar.saturday
    : dateInfo.isSunday || dateInfo.isHoliday
      ? AppColor.calendar.sunday
      : AppColor.calendar.weekday;

  return {
    color: dateColor.foreground,
    backgroundColor: dateColor.background,
  };
}
export function getCalendarDateColor(date: string) {
  return getCalendarDateColors(date).color;
}

function getDateInfo(date: string) {
  const dayOfWeek = isoWeekday(date);
  const isHoliday = HolidayMap[date] !== undefined;
  return {
    dayOfWeek,
    isHoliday,
    isSaturday: dayOfWeek === 6,
    isSunday: dayOfWeek === 7,
  };
}
