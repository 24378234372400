import { IVoucher, VoucherUtils } from '@karutekun/core/voucher';
import { IVisit } from '../data';
import { isVisitHistory } from './visit-status';

export function canCancelCheckIn(
  visit: Pick<IVisit, 'status' | 'assignedStylistId'> & {
    voucher: Pick<IVoucher, 'status'>;
  }
) {
  if (!visit.voucher) {
    throw new Error(`Voucher is not set.`);
  }

  return isVisitHistory(visit) && !VoucherUtils.isPaid(visit.voucher);
}
