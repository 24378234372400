import { DiscountType } from '@karutekun/core/salon-service';
import { distributeTotal } from '@karutekun/shared/util/math';
import { sumBy } from '@karutekun/shared/util/objects';

/**
 * 元の金額に、割引を与えられた順に適用する。
 *
 * 連続する % 割引はまとめて計算される。
 * 例: [-10% 割引, -20% 割引] は -30% 割引となる
 *
 * 割引後の金額と、各割引の割引額を返す(与えられた割引の順序と同じ順番)
 */
export function calculateDiscountAmounts(
  originalPrice: number,
  discounts: { type: DiscountType; value: number }[]
): { discountedPrice: number; discountAmounts: number[] } {
  let sales = originalPrice;

  // 連続する % 割引をまとめる
  const grouped: (
    | { type: DiscountType.Price; value: number }
    | { type: DiscountType.Percentage; value: number }[]
  )[] = [];
  for (const discount of discounts) {
    const last = grouped.length > 0 ? grouped[grouped.length - 1] : null;
    if (discount.type === DiscountType.Percentage) {
      if (Array.isArray(last)) {
        last.push({ type: DiscountType.Percentage, value: discount.value });
      } else {
        grouped.push([
          { type: DiscountType.Percentage, value: discount.value },
        ]);
      }
    } else {
      grouped.push({ type: DiscountType.Price, value: discount.value });
    }
  }

  let discountAmounts: number[] = [];
  for (const discountItem of grouped) {
    if (Array.isArray(discountItem)) {
      const result = applyPercentageDiscounts(sales, discountItem);
      discountAmounts = discountAmounts.concat(result.discountAmounts);
      sales -= result.totalDiscount;
    } else {
      const discountAmount = discountItem.value;
      discountAmounts.push(discountAmount);
      sales -= discountAmount;
    }
  }

  return { discountAmounts, discountedPrice: sales };
}

/**
 * %割引の割引額を計算する
 */
function applyPercentageDiscounts(
  sales: number,
  discounts: { type: DiscountType.Percentage; value: number }[]
): { totalDiscount: number; discountAmounts: number[] } {
  const totalPercentage = sumBy(discounts, 'value');
  const totalDiscount = Math.floor((sales * totalPercentage) / 100);
  return {
    totalDiscount,
    discountAmounts: distributeTotal(
      totalDiscount,
      discounts.map((d) => d.value)
    ),
  };
}
