import numeral from 'numeral';

export function formatMoney(v: number, showPriceFromSign = false) {
  return `¥${numeral(v).format('0,0')}${showPriceFromSign ? '〜' : ''}`;
}
export function formatMoneyShrink(v: number, showPriceFromSign = false) {
  return `${numeral(v / 10000).format('0,0.[0]')}万${
    showPriceFromSign ? '〜' : ''
  }`;
}
