import { DiscountType } from '@karutekun/core/salon-service';
import { TaxRoundingType, applyTax } from '@karutekun/core/tax';
import { distributeTotal } from '@karutekun/shared/util/math';
import { sumBy } from '@karutekun/shared/util/objects';
import { calculateDiscountAmounts } from './calculate-discount-amounts';

/**
 * 個別の売上計算が終わった voucherLines に対して、全体割引を適用する
 *
 * NOTE: 現在、全体割引については approxDiscountAmount, approxDiscountTaxAmount は計算されていない
 */
export function calculateSalesAfterOverallDiscounts(
  lines: Readonly<{ sales: number; taxAmount: number; taxRate: number }>[],
  discounts: Readonly<{ type: DiscountType; value: number }>[],
  taxRoundingType: TaxRoundingType
): { sales: number; taxAmount: number }[] {
  const salesBeforeDiscount = sumBy(lines, 'sales');

  const { discountedPrice: finalSales } = calculateDiscountAmounts(
    salesBeforeDiscount,
    discounts
  );

  /**
   * 全体割引した分を各 voucherLine に適用し戻す
   * 正負の voucherLine.sales が混ざったときに、絶対金額割引を何も考えずに按分すると
   * 結果が怪しくなるため、按分比は voucherLine.sales の絶対値に準ずるようにしている
   *
   * 例)
   * メニューA 250円 x 1
   * メニューB 750円 x -1
   * 全体割引 -100円
   *
   * の場合
   *
   * メニューA 225円
   * メニューB -825円
   * 合計 -600円
   * となる
   *
   * （なお、正負について考慮せずに按分すると
   * メニューA 300円
   * メニューB -900円
   * 合計 -600円
   * となり、メニューAは値段が上がってしまう）
   */
  const totalDiscount = salesBeforeDiscount - finalSales;
  const lineDistributedDiscounts = distributeTotal(
    totalDiscount,
    lines.map((pvl) => Math.abs(pvl.sales))
  );

  return lines.map((line, i) => {
    // 全体割引適用後の売上
    const sales = line.sales - lineDistributedDiscounts[i];

    // 消費税を計算し直す
    // line.sales は税込みの金額になっているので、内税として割り戻す形になる
    const { tax: taxAmount } = applyTax(
      sales,
      true,
      line.taxRate,
      taxRoundingType
    );

    return { sales, taxAmount };
  });
}
