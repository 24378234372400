import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M46.21 22.736 26.254 3.633c-.669-.678-1.572-.88-2.248-.881-.678-.001-1.585.199-2.259.88L1.79 22.736a1.75 1.75 0 1 0 2.42 2.529l1.54-1.475V42A3.25 3.25 0 0 0 9 45.25h9a1.75 1.75 0 0 0 1.75-1.75V30.75a.5.5 0 0 1 .5-.5h7.5a.5.5 0 0 1 .5.5V43.5c0 .966.784 1.75 1.75 1.75h9A3.25 3.25 0 0 0 42.25 42V23.79l1.54 1.475a1.75 1.75 0 0 0 2.42-2.529m-7.46-2.296L24 6.32 9.25 20.44v21.31h7v-11a4 4 0 0 1 4-4h7.5a4 4 0 0 1 4 4v11h7z"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgHome = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgHome);
export default Memo;
