import { Box, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ChatMessageBodyBroadcast as ChatMessageBodyBroadcastEntity } from '../../models/chatMessageBodyBroadcast/entity';
import CButton from '../atoms/CButton';

type Props = {
  chatMessageBody: ChatMessageBodyBroadcastEntity;
};

const useStyles = makeStyles((theme: Theme) => ({
  subTitle: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
  },
}));

const ChatMessageBodyBroadcast: FC<Props> = (props) => {
  const classes = useStyles();

  const { text, broadcastMessageId } = props.chatMessageBody;

  return (
    <Grid container spacing={1} direction="column">
      <Grid item>
        <Typography className={classes.subTitle}>
          メッセージを一斉配信しました
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">{text}</Typography>
      </Grid>
      <Grid item>
        <Box textAlign="right">
          <CButton
            size="small"
            variant="text"
            linkTo={`/chat/broadcast_messages/${broadcastMessageId}`}
          >
            詳細を確認
          </CButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ChatMessageBodyBroadcast;
