import Typography from '@mui/material/Typography';
import * as React from 'react';
import CInformation from '../../atoms/CInformation';
import CProgressOverlay from '../../atoms/CProgressOverlay';

type OwnProps = {
  title?: string;
  rightComponent?: React.ReactNode;
  isFetching: boolean;
  informationText?: string;
  noData?: boolean;
  height?: number;
};

const CChartWrapper: FC<OwnProps> = React.memo(function CChartWrapper(props) {
  const {
    title,
    rightComponent,
    informationText,
    isFetching,
    noData,
    children,
  } = props;

  return (
    <div style={{ padding: 8 }}>
      {title && (
        <div
          style={{
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <Typography
            variant="h6"
            noWrap
            style={{ padding: 8 }}
            display="inline"
          >
            {title}
            {informationText && (
              <CInformation type="dialog" content={informationText} />
            )}
          </Typography>
          {rightComponent}
        </div>
      )}
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: props.height || 250,
        }}
      >
        {isFetching && <CProgressOverlay />}
        {!isFetching && noData ? <div>no data</div> : children}
      </div>
    </div>
  );
});

export default CChartWrapper;
