import {
  CustomerFormSetting,
  ISalonCustomSetting,
  ISalonCustomSettingWithDefaults,
  SalonSetupQuestionnaire,
  buildSalonCustomSettingWithDefaults,
} from '@karutekun/core/salon';

export type SalonCustomSettingResource = ISalonCustomSetting;
export type SalonCustomSetting = ISalonCustomSetting;
export type Questionnaire = SalonSetupQuestionnaire;
export type CustomerForm = CustomerFormSetting;

export const BusinessCategories = {
  hair: 'ヘアサロン',
  nail: 'ネイル',
  eyelash: 'アイラッシュ',
  esthetic: 'エステ',
  seitai: '整体',
  other: 'その他',
};

export function emptySalonCustomSetting(
  overwrite: Partial<SalonCustomSettingResource> | null = {}
): ISalonCustomSettingWithDefaults {
  return buildSalonCustomSettingWithDefaults({ salonId: 0, ...overwrite });
}
