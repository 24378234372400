import {
  CustomerLoyaltySegmentText,
  CustomerLoyaltySegments,
} from '@karutekun/core/customer';
import {
  Alert,
  Box,
  CircularProgress,
  Grid,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {
  CustomerFilter,
  fetchCustomers,
} from '../../../../../actions/customerAction';
import CSelect from '../../../../../components_old/atoms/CSelect';
import CDateRange from '../../../../../components_old/molecules/CDateRange';
import CStylistSelect from '../../../../../components_old/molecules/CStylistSelect';
import {
  selectStylists,
  useSelectMySalon,
} from '../../../../../selectors/salonSelector';
import { removeUndefField } from '../../../../../util/common';
import { useThunkDispatch } from '../../../../../util/hooks/useThunkDispatch';

type Props = {
  filter: CustomerFilter;
  onChange(filter: CustomerFilter): void;
};

const useStyles = makeStyles((theme: Theme) => ({
  filterBox: {
    padding: theme.spacing(2),
    maxWidth: 400,
    backgroundColor: theme.palette.grey[100],
    borderRadius: 4,
  },
}));

enum CustomersPreviewStatus {
  Loading,
  Loaded,
  Error,
}

export const BroadcastMessageFormCustomerFilter: FC<Props> = (props) => {
  const classes = useStyles();

  const { filter, onChange } = props;

  const salon = useSelectMySalon();
  const stylists = useSelector(selectStylists);
  const dispatch = useThunkDispatch();

  const [customersPreview, setCustomersPreview] = useState<{
    count: number;
  } | null>(null);
  const [customersPreviewStatus, setCustomersPreviewStatus] = useState(
    CustomersPreviewStatus.Loading
  );

  useDeepCompareEffect(() => {
    setCustomersPreviewStatus(CustomersPreviewStatus.Loading);

    const { controller, request } = dispatch(
      fetchCustomers(
        { ...filter, limit: 1, offset: 0 },
        'lastVisitedAt',
        'desc'
      )
    );

    request
      .then((result) => {
        setCustomersPreview(result);
        setCustomersPreviewStatus(CustomersPreviewStatus.Loaded);
      })
      .catch(() => {
        setCustomersPreviewStatus(CustomersPreviewStatus.Error);
      });

    return function cleanup() {
      if (controller) {
        controller.abort();
      }
    };
  }, [filter]);

  const handleChangeCustomerBasicFilter = useCallback(
    (updates: Partial<CustomerFilter>) => {
      onChange(
        removeUndefField({
          ...filter,
          ...updates,
        })
      );
    },
    [filter, onChange]
  );

  const handleChangeCustomerSalonFilter = useCallback(
    (updates: Partial<CustomerFilter['salon']>) => {
      const newSalonFilter = removeUndefField({
        ...filter.salon,
        ...updates,
      });
      onChange({
        ...filter,
        salon: {
          salonId: salon.id,
          ...newSalonFilter,
        },
      });
    },
    [filter, onChange, salon.id]
  );

  return (
    <>
      <div className={classes.filterBox}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CSelect
              fullWidth
              label="顧客セグメント"
              options={CustomerLoyaltySegments.map((loyaltySegment) => ({
                value: loyaltySegment,
                element: CustomerLoyaltySegmentText[loyaltySegment],
              }))}
              value={filter.salon?.loyaltySegment}
              onChange={(loyaltySegment) =>
                handleChangeCustomerSalonFilter({ loyaltySegment })
              }
              displayEmpty
            />
          </Grid>
          <Grid item xs={6}>
            <CStylistSelect
              fullWidth
              label="前回担当者"
              stylists={stylists}
              selected={filter.salon?.lastStylistId}
              onChange={(lastStylistId) =>
                handleChangeCustomerSalonFilter({ lastStylistId })
              }
              displayEmpty
            />
          </Grid>
          <Grid item xs={12}>
            <CDateRange
              label="最終来店日"
              clearable
              from={filter.salon?.lastVisitedAt?.from}
              to={filter.salon?.lastVisitedAt?.to}
              onChange={(from, to) => {
                const lastVisitedAt =
                  from === undefined && to === undefined
                    ? undefined
                    : { from, to };
                handleChangeCustomerSalonFilter({ lastVisitedAt });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <CSelect
              fullWidth
              label="誕生月"
              options={Array(12)
                .fill(0)
                .map((_, i) => ({
                  value: `${i + 1}`,
                  element: `${i + 1}月`,
                }))}
              value={filter.birthdayMonth}
              onChange={(birthdayMonth) =>
                handleChangeCustomerBasicFilter({ birthdayMonth })
              }
              displayEmpty
            />
          </Grid>
        </Grid>
      </div>

      <Box mt={1}>
        {customersPreviewStatus === CustomersPreviewStatus.Loading ? (
          <CircularProgress />
        ) : customersPreviewStatus === CustomersPreviewStatus.Error ? (
          <Alert severity="error">
            <Typography variant="body2">
              配信ターゲットとなるお客様の情報が取得できませんでした。
            </Typography>
          </Alert>
        ) : customersPreview ? (
          <Alert severity="info">
            <Typography variant="body2" gutterBottom>
              {customersPreview.count > 0
                ? `約${customersPreview.count}名のお客様に配信される想定です。`
                : '配信ターゲットとなるお客様が存在しない可能性があります。'}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              ※
              この結果は現在時刻に基づくもので、実際に配信されるターゲットとは異なる可能性があります。
            </Typography>
          </Alert>
        ) : null}
      </Box>
    </>
  );
};
