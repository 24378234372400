import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      d="M27 43.25h-6c-.97 0-1.75.78-1.75 1.75s.78 1.75 1.75 1.75h6c.97 0 1.75-.78 1.75-1.75s-.78-1.75-1.75-1.75M28.5 38.25h-9c-.97 0-1.75.78-1.75 1.75s.78 1.75 1.75 1.75h9c.97 0 1.75-.78 1.75-1.75s-.78-1.75-1.75-1.75M24 .75c-1.99 0-3.98.38-5.84 1.15-1.86.77-3.53 1.88-4.96 3.31a15.206 15.206 0 0 0-3.31 4.95c-.77 1.86-1.16 3.83-1.15 5.84 0 4.44 1.7 8.59 4.67 11.4l.41.38c1.56 1.46 3.92 3.67 3.92 5.47V35c0 .97.78 1.75 1.75 1.75h9c.97 0 1.75-.78 1.75-1.75v-1.75c0-1.76 2.24-3.87 3.87-5.42l.46-.44c3.05-2.92 4.67-6.86 4.67-11.39C39.24 7.59 32.4.75 23.99.75zm8.16 24.11-.45.43c-2.09 1.98-4.96 4.7-4.96 7.96h-1v-8.33c1.38-.47 2.56-1.3 2.86-1.52.77-.58.93-1.67.36-2.44-.58-.77-1.67-.94-2.45-.37-.66.48-1.9 1.16-2.52 1.16-.62 0-1.86-.67-2.52-1.15-.78-.58-1.87-.42-2.45.36-.58.78-.42 1.87.36 2.45.29.22 1.48 1.05 2.86 1.52v8.33h-1c0-3.32-2.9-6.04-5.03-8.02l-.4-.37c-2.27-2.15-3.58-5.37-3.58-8.86 0-1.55.29-3.07.89-4.5.59-1.43 1.45-2.72 2.54-3.81a11.743 11.743 0 0 1 8.32-3.44c6.59 0 11.75 5.16 11.75 11.75 0 3.61-1.21 6.59-3.58 8.86z"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgLightbulb = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgLightbulb);
export default Memo;
