import { moment } from '@karutekun/shared/util/datetime';
import { Box, InputLabel, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import CDatePicker from './CDatePicker';
import CSelect from './CSelect';

type OwnProps = {
  label?: string;
  unixtime: number;
  disabled?: boolean;

  onChange(unixtime: number): void;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: 3,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  separator: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: theme.typography.caption.fontSize,
  },
}));

const step = 5;

const hours = _.times(24);
const minutes = _.times(60 / step, (num) => num * step);
const hoursOptions = hours.map((h) => {
  const hh = twoDigit(h);
  return {
    value: hh,
    element: hh,
  };
});
const minuteOptions = minutes.map((m) => {
  const mm = twoDigit(m);
  return {
    value: mm,
    element: mm,
  };
});

function twoDigit(n: number) {
  return n < 10 ? `0${n}` : `${n}`;
}

const CDateTimePicker: FC<OwnProps> = (props) => {
  const classes = useStyles();

  const { label, unixtime, disabled, onChange } = props;

  const mom = moment.unix(unixtime);
  const datePart = mom.format('YYYY-MM-DD');
  const hourPart = mom.format('kk');
  // 分は強制的にStep刻みに揃える
  const minutePart = twoDigit(
    Math.floor(Number(mom.format('m')) / step) * step
  );

  return (
    <>
      <InputLabel shrink>{label}</InputLabel>
      <div className={classes.container}>
        <Box mr={1}>
          <CDatePicker
            date={datePart}
            onChange={(date: string) => {
              onChange(moment(`${date} ${hourPart}:${minutePart}`).unix());
            }}
            disabled={disabled}
          />
        </Box>
        <CSelect
          value={hourPart}
          options={hoursOptions}
          onChange={(value) => {
            onChange(moment(`${datePart} ${value}:${minutePart}`).unix());
          }}
          dense
          disabled={disabled}
        />
        <Typography className={classes.separator}>時</Typography>
        <CSelect
          value={minutePart}
          options={minuteOptions}
          onChange={(value) => {
            onChange(moment(`${datePart} ${hourPart}:${value}`).unix());
          }}
          dense
          disabled={disabled}
        />
        <Typography className={classes.separator}>分</Typography>
      </div>
    </>
  );
};

export default CDateTimePicker;
