import { IService, ServiceType } from '@karutekun/core/salon-service';

/**
 * voucher.lines から提供メニューの合計時間を計算する
 */
export function calculateTotalServiceMinutes<
  Line extends {
    serviceId: number;
    serviceType: ServiceType;
    quantity: number;
  },
  ServiceLike extends Pick<IService, 'time'>
>(lines: Line[], serviceMap: IdMap<ServiceLike>): number {
  const times = lines
    .filter((line) => line.serviceType === ServiceType.Menu)
    .map((line) => {
      const service = serviceMap[line.serviceId];
      return service ? service.time * line.quantity : 0;
    });

  return times.reduce((p, c) => p + c, 0);
}
