import {
  Checkbox,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import CChip from './CChip';

type OwnProps<T> = {
  label?: string;
  selected: T[];
  options: {
    value: T;
    element: string;
  }[];
  emptyString?: string;
  disabled?: boolean;
  fullWidth?: boolean;

  onChange(value: T[]): void;
};

const useStyles = makeStyles(() => ({
  emptyText: {
    minHeight: 20,
  },
}));

const CSelectMulti = <T extends number | string>(props: OwnProps<T>) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleChange(event: any) {
    props.onChange(event.target.value as T[]);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleOpen() {
    setOpen(true);
  }

  function renderValue(v: unknown) {
    const selected = v as T[];
    if (selected.length === 0) {
      return (
        <Typography className={classes.emptyText}>
          {props.emptyString}
        </Typography>
      );
    }

    return (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {props.options.map((option) => {
          if (selected.includes(option.value)) {
            return (
              <CChip
                key={option.value}
                label={option.element}
                style={{ margin: 2 }}
              />
            );
          }
          return null;
        })}
      </div>
    );
  }
  return (
    <TextField
      label={props.label}
      variant="standard"
      select
      SelectProps={{
        open,
        multiple: true,
        onClose: handleClose,
        onOpen: handleOpen,
        renderValue: renderValue,
        displayEmpty: true,
      }}
      value={props.selected}
      onChange={handleChange}
      disabled={props.disabled}
      fullWidth={props.fullWidth}
    >
      <MenuItem disabled value="">
        <em>-</em>
      </MenuItem>
      {props.options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          <Checkbox checked={props.selected.indexOf(option.value) > -1} />
          <ListItemText
            primary={option.element}
            style={{ whiteSpace: 'normal' }}
          />
        </MenuItem>
      ))}
    </TextField>
  );
};

export default CSelectMulti;
