import { dayjs } from './dayjs';

/**
 * 指定月のカレンダーを返す
 *
 * @returns カレンダーを表現する2重配列(月曜日始まり)
 * e.x. 2020-07
 * [
 *   ["2020-06-29", "2020-06-30", "2020-07-01", "2020-07-02", ...],
 *   ["2020-07-06", "2020-07-07", "2020-07-08", "2020-07-09", ...],
 *   ...
 *   [..., "2020-07-30", "2020-07-31", "2020-08-01", "2020-08-02"]
 * ]
 */
export function getCalendar(year: number, month: number): string[][] {
  const weeks: string[][] = [];

  let currentDate = dayjs()
    .year(year)
    .month(month - 1)
    .startOf('month')
    .startOf('week');

  const endDate = dayjs()
    .year(year)
    .month(month - 1)
    .endOf('month')
    .endOf('week');

  let week: string[] = [];
  while (currentDate.isSameOrBefore(endDate)) {
    week.push(currentDate.format('YYYY-MM-DD'));

    if (week.length === 7) {
      weeks.push(week);
      week = [];
    }

    currentDate = currentDate.add(1, 'days');
  }

  return weeks;
}
