import { formatDateForUser } from '@karutekun/shared/util/datetime';
import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import { Grid } from '@mui/material';
import React, { useCallback } from 'react';
import { sendRequest } from '../../../actions/request';
import CButton from '../../../components_old/atoms/CButton';
import CInformation from '../../../components_old/atoms/CInformation';
import { InformationText } from '../../../texts/infomation';
import { useFlag } from '../../../util/hooks/useFlag';
import { useThunkDispatch } from '../../../util/hooks/useThunkDispatch';

type Props = {
  from: string;
  to: string;
};

export const SalonStatsDownloadSection: FC<Props> = React.memo((props) => {
  const dispatch = useThunkDispatch();

  const { from, to } = props;

  const [isDownloading, callWithDownloading] = useFlag();

  const handleDownload = useCallback(async () => {
    const fromStr = formatDateForUser(from);
    const toStr = formatDateForUser(to);
    // trpc で CSV のダウンロードの実装が難しそうなので、
    // 従来のAPIリクエストでダウンロードを行っている
    callWithDownloading(() =>
      dispatch(() =>
        sendRequest(dispatch, `analytics/salon/download_daily_sales_stats`, {
          params: { from, to },
          download: {
            filename: `日次売上(${fromStr}〜${toStr}).csv`,
          },
        })
      )
    );
  }, [callWithDownloading, dispatch, from, to]);

  return (
    <Grid container justifyContent="flex-end">
      <Grid item>
        <CButton
          size="small"
          startIcon={<SVGIcon name="cloud-download" size="sm" />}
          disabled={from === undefined || to === undefined}
          onClick={handleDownload}
          isLoading={isDownloading}
        >
          日次売上ダウンロード
        </CButton>
      </Grid>
      <Grid item>
        <CInformation
          type="dialog"
          content={InformationText.salon.downloadDailySalesSummary}
          size="lg"
        />
      </Grid>
    </Grid>
  );
});
