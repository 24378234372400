export type SalonLineSetting = {
  lineId: string;
  friendNum: number;
  blockNum: number;
  acceptMessage: boolean;
};

// TODO 一時的に lint を無効化しています。気づいたベースで直してください
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function lineSettingFromResource(res: any) {
  return {
    lineId: res.lineId,
    friendNum: res.friendNum,
    blockNum: res.blockNum,
    acceptMessage: res.acceptMessage,
  };
}

function generateCompleteToken(token: string) {
  return `******\n認証コード\n${token}\n******`;
}

export function generateLineConnectDeepLink(lineId: string, token: string) {
  const prefix = '認証コードをそのまま送信してください。';
  const completeToken = generateCompleteToken(token);
  const encoded = encodeURIComponent(`${completeToken}\n\n${prefix}`);
  return `https://line.me/R/oaMessage/${lineId}/?${encoded}`;
}

export function generateLineCreateCustomerDeepLink(
  lineId: string,
  token: string
) {
  const prefix = '認証コードをそのまま送信してください。';
  const completeToken = generateCompleteToken(token);
  const encoded = encodeURIComponent(`${completeToken}\n\n${prefix}`);
  return `https://line.me/R/oaMessage/${lineId}/?${encoded}`;
}
