import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M12.47 6.75a20.74 20.74 0 0 1 26.2 2.58c3.89 3.89 6.08 9.17 6.08 14.67a20.758 20.758 0 0 1-12.81 19.17 20.758 20.758 0 0 1-11.99 1.18 20.76 20.76 0 0 1-16.3-16.3c-.8-4.03-.39-8.2 1.18-11.99 1.57-3.79 4.23-7.03 7.64-9.31m11.53 0c-3.41 0-6.75 1.01-9.58 2.91-2.84 1.9-5.05 4.59-6.35 7.74a17.183 17.183 0 0 0-.98 9.97c.67 3.35 2.31 6.42 4.72 8.83 2.41 2.41 5.49 4.06 8.83 4.72 3.35.67 6.81.32 9.97-.98 3.15-1.31 5.85-3.52 7.74-6.35 1.9-2.84 2.91-6.17 2.91-9.58 0-4.58-1.82-8.96-5.05-12.2a17.274 17.274 0 0 0-12.2-5.05z"
      clipRule="evenodd"
    />
    <path
      fill={props.color || 'currentColor' || '#000'}
      d="M23.53 28.94c-.83 0-1.5-.67-1.5-1.5 0-2.12.97-3.81 2.97-5.15 1.95-1.31 2.75-1.99 2.75-3.52 0-.39 0-1.59-1.91-2.5-.22-.11-.87-.29-1.82-.27-1.06.02-1.87.27-2.49.77-1.16.94-1.27 1.95-1.28 1.99-.08.81-.78 1.43-1.6 1.37-.81-.06-1.43-.74-1.39-1.55.01-.24.17-2.37 2.39-4.15 1.15-.92 2.6-1.4 4.33-1.43 1.23-.02 2.37.19 3.15.56 2.33 1.11 3.62 2.96 3.62 5.21 0 3.28-2.26 4.79-4.08 6.01-1.44.97-1.64 1.85-1.64 2.66s-.67 1.5-1.5 1.5"
    />
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M23.44 35.69a2.38 2.38 0 0 0 2.38-2.38c0-1.32-1.06-2.38-2.38-2.38-1.32 0-2.38 1.06-2.38 2.38 0 1.32 1.06 2.38 2.38 2.38"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgQuestionCircle = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgQuestionCircle);
export default Memo;
