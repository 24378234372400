import {
  CustomerLoyaltySegment,
  CustomerLoyaltySegmentText,
  LostCustomerLoyaltySegment,
} from '@karutekun/core/customer';
import { trpc } from '@karutekun/shared/data-access/api-base';
import {
  endOfMonthDate,
  startOfMonthDate,
  thisMonth,
} from '@karutekun/shared/util/datetime';
import { Card } from '@mui/material';
import { keepPreviousData } from '@tanstack/react-query';
import { memo, useMemo, useState } from 'react';
import CChartBar from '../../../../../components_old/atoms/CChartBar';
import CMonthRangeControl from '../../../../../components_old/molecules/CMonthRangeControl';
import CChartWrapper from '../../../../../components_old/organisms/charts/CChartWrapper';
import { InformationText } from '../../../../../texts/infomation';
import { fmtCustomerNum, fmtMonth } from '../../../../../util/chart';

type Props = {
  loyaltySegment: LostCustomerLoyaltySegment;
  onChangeDate(from: string, to: string): void;
};

export const LostCustomerTransitionCard: FC<Props> = memo(
  ({ loyaltySegment, onChangeDate }) => {
    const [{ from, to }, setRange] = useState({
      from: thisMonth(-12),
      to: thisMonth(),
    });

    const { isRefetching, data: monthlyStats = [] } =
      trpc.analytics.lostCustomerStats.monthlyStats.useQuery(
        {
          loyaltySegment,
          range: { from, to },
        },
        { placeholderData: keepPreviousData }
      );

    const segmentText = CustomerLoyaltySegmentText[loyaltySegment];

    const valueKeys = useMemo(
      () => [
        { name: `${segmentText}数`, dataKey: 'customerNum', color: '#ffc107' },
      ],
      [segmentText]
    );

    return (
      <Card>
        <CChartWrapper
          title={`${segmentText}数 推移`}
          isFetching={isRefetching}
          informationText={
            loyaltySegment === CustomerLoyaltySegment.RepeatLost
              ? InformationText.repeatLostCustomer.monthlyCustomerNum
              : InformationText.newLostCustomer.monthlyCustomerNum
          }
          rightComponent={
            <div style={{ flex: 1 }}>
              <CMonthRangeControl
                from={from}
                to={to}
                onChange={(from, to) => setRange({ from, to })}
              />
            </div>
          }
        >
          <CChartBar
            data={monthlyStats}
            nameKey={(s) => fmtMonth(s.month)}
            formatter={fmtCustomerNum}
            valueKeys={valueKeys}
            onClick={({ month }) =>
              onChangeDate(startOfMonthDate(month), endOfMonthDate(month))
            }
          />
        </CChartWrapper>
      </Card>
    );
  }
);
