import { ISalonStats } from '@karutekun/core/stats/salon-stats';
import { formatMoney } from '@karutekun/shared/util/format';
import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import { Card, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { selectPaymentMethods } from '../../../selectors/salonSelector';
import { selectAnalyticsDisplayTaxIncludedPrice } from '../../../selectors/settingsSelector';
import CInformation from '../../atoms/CInformation';
import CMoneyTypography from '../../atoms/CMoneyTypography';
import CProgressOverlay from '../../atoms/CProgressOverlay';
import CSalonPaymentMethodSummaryCard from './CSalonPaymentMethodSummaryCard';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    flex: '1',
    textAlign: 'center',
    marginTop: theme.spacing(2),
    position: 'relative',
  },
  card: {
    overflow: 'inherit',
    minHeight: 52,
    backgroundColor: '#63c2de',
  },
  cardText: {
    color: '#fff',
  },
  title: {
    float: 'left',
    margin: '-20px 20px 0 15px',
    zIndex: 100,
    borderRadius: 3,
    padding: '5px 10px',
    backgroundColor: '#20a8d8',
  },
  icon: {
    color: theme.palette.common.white,
  },
}));

type Props = {
  title: string;
  stats: Nullable<
    Pick<
      ISalonStats,
      | 'menuSales'
      | 'menuSalesWithoutTax'
      | 'productSales'
      | 'productSalesWithoutTax'
      | 'taxAmount'
      | 'paymentMethodMap'
      | 'paymentChange'
    >
  >;
  isFetching: boolean;
  informationText: string;
};

export const CSalonSalesStatsCard: FC<Props> = (props) => {
  const classes = useStyles();

  const { isFetching, stats, title, informationText } = props;

  const displayTaxIncludedPrice = useSelector(
    selectAnalyticsDisplayTaxIncludedPrice
  );
  const paymentMethods = useSelector(selectPaymentMethods);

  const menuSales = stats?.menuSales ?? 0;
  const menuSalesWithoutTax = stats?.menuSalesWithoutTax ?? 0;
  const productSales = stats?.productSales ?? 0;
  const productSalesWithoutTax = stats?.productSalesWithoutTax ?? 0;
  const taxAmount = stats?.taxAmount ?? 0;

  const isTaxIncludedText1 = displayTaxIncludedPrice ? '税込' : '税抜';
  const isTaxIncludedText2 = displayTaxIncludedPrice ? '内税' : '外税';

  return (
    <div className={classes.main}>
      {isFetching && <CProgressOverlay />}

      <CInformation type="dialog" content={informationText} corner inverse />

      <Card className={classes.title}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ padding: 8 }}
        >
          <SVGIcon name="yen-sign" color="#fff" />
        </Grid>
      </Card>
      <Card className={classes.card}>
        <Grid
          container
          justifyContent="space-evenly"
          alignItems="center"
          direction="column"
          style={{ paddingBottom: 16 }}
        >
          <CMoneyTypography
            variant="h4"
            style={{ color: '#fff' }}
            amount={
              displayTaxIncludedPrice
                ? menuSales + productSales
                : menuSalesWithoutTax + productSalesWithoutTax
            }
          />
          <Typography variant="h6" className={classes.cardText}>
            {title}
          </Typography>
          <Typography variant="subtitle1" className={classes.cardText}>
            ({isTaxIncludedText2} {formatMoney(taxAmount)})
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent="space-evenly"
          alignItems="center"
          direction="row"
          style={{ backgroundColor: '#fff', padding: 8 }}
        >
          <Grid item>
            <CMoneyTypography
              variant="h6"
              amount={displayTaxIncludedPrice ? menuSales : menuSalesWithoutTax}
            />
            <Typography color="textSecondary" variant="subtitle1">
              施術({isTaxIncludedText1})
            </Typography>
            <Typography color="textSecondary" variant="subtitle1">
              ({isTaxIncludedText2}{' '}
              {formatMoney(menuSales - menuSalesWithoutTax)})
            </Typography>
          </Grid>
          <Grid item>
            <CMoneyTypography
              variant="h6"
              amount={
                displayTaxIncludedPrice ? productSales : productSalesWithoutTax
              }
            />
            <Typography color="textSecondary" variant="subtitle1">
              店販({isTaxIncludedText1})
            </Typography>
            <Typography color="textSecondary" variant="subtitle1">
              ({isTaxIncludedText2}{' '}
              {formatMoney(productSales - productSalesWithoutTax)})
            </Typography>
          </Grid>
        </Grid>
        <CSalonPaymentMethodSummaryCard
          paymentMethods={paymentMethods}
          paymentMethodMap={stats?.paymentMethodMap ?? {}}
          paymentChange={stats?.paymentChange ?? 0}
        />
      </Card>
    </div>
  );
};

export default CSalonSalesStatsCard;
