import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import {
  Box,
  CircularProgress,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuItemProps,
  Paper,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Downshift from 'downshift';
import React, { useCallback, useState } from 'react';
import CDivider from '../atoms/CDivider';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 10,
    left: 0,
    right: 0,
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
}));

type RenderInputProps = TextFieldProps & {
  classes: ReturnType<typeof useStyles>;
  ref?: React.Ref<HTMLDivElement>;
  isFetching: boolean;
};

function renderInput(inputProps: RenderInputProps) {
  const { InputProps, classes, ref, isFetching, ...other } = inputProps;

  return (
    <TextField
      variant="standard"
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        endAdornment: (
          <InputAdornment position="end">
            {isFetching && <CircularProgress size={16} disableShrink={true} />}
          </InputAdornment>
        ),
        ...InputProps,
      }}
      {...other}
    />
  );
}

interface RenderSuggestionProps {
  highlightedIndex: number | null;
  index: number;
  itemProps: MenuItemProps<'div', { button?: never }>;
  suggestion: OptionType;
}

function renderSuggestion(suggestionProps: RenderSuggestionProps) {
  const { suggestion, index, itemProps, highlightedIndex } = suggestionProps;

  const isHighlighted = highlightedIndex === index;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.id}
      selected={isHighlighted}
      component="div"
    >
      {suggestion.content ? suggestion.content : suggestion.label}
    </MenuItem>
  );
}

type OptionType = {
  id: number;
  label: string;
  content?: React.ReactNode;
};

type OwnProps = {
  label?: string;
  placeholder?: string;
  value: string;
  suggestions: OptionType[];
  isFetching: boolean;
  hasMore?: boolean;
  createNewOptionContent?: React.ReactNode;
  optionContent?: React.ReactNode;

  onSuggestionFetchRequested(value: string): void;
  onSuggestionClearRequested(): void;
  onChange(value: string): void;
  onSelected(id: number): void;
  onLoadMoreSelected?(): void;
  onCreateNewSelected?(): void;
  onOptionContentSelected?(): void;
};

const CAutoComplete: FC<OwnProps> = (props) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  const {
    label,
    placeholder,
    value,
    suggestions,
    isFetching,
    hasMore,
    createNewOptionContent,
    optionContent,
    onSelected,
  } = props;

  const handleOnSelect = useCallback(
    (item: OptionType | null) => {
      if (item) {
        onSelected(item.id);
      }
    },
    [onSelected]
  );

  return (
    <div className={classes.root}>
      <Downshift
        inputValue={value}
        onSelect={handleOnSelect}
        onOuterClick={() => setIsOpen(false)}
        isOpen={isOpen}
        itemToString={(item: OptionType | null) => {
          return item ? `${item.id}` : '';
        }}
      >
        {({
          clearSelection,
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          highlightedIndex,
          isOpen,
        }) => {
          const { onChange, onFocus, ...inputProps } = getInputProps({
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value;
              props.onChange(value);

              if (value === '') {
                clearSelection();
                props.onSuggestionClearRequested();
              } else {
                props.onSuggestionFetchRequested(value);
              }
            },
            onFocus: () => {
              setIsOpen(true);
              if (value === '') {
                props.onSuggestionClearRequested();
              } else {
                props.onSuggestionFetchRequested(value);
              }
            },
          });

          return (
            <div className={classes.container}>
              {renderInput({
                isFetching,
                placeholder,
                label,
                classes,
                inputProps,
                fullWidth: true,
                // TODO 一時的に lint を無効化しています。気づいたベースで直してください
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                InputLabelProps: getLabelProps({ shrink: true } as any),
                InputProps: { onChange, onFocus },
              })}
              <div {...getMenuProps()}>
                {isOpen ? (
                  <Paper className={classes.paper} square>
                    {suggestions.length === 0 && value !== '' && (
                      <ListItem>
                        <ListItemText
                          primary={
                            <Box fontStyle="italic">
                              <Typography color="textSecondary">
                                候補が見つかりませんでした
                              </Typography>
                            </Box>
                          }
                        />
                      </ListItem>
                    )}

                    {suggestions.map((suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        highlightedIndex,
                        itemProps: getItemProps({ item: suggestion }),
                      })
                    )}

                    {(hasMore || createNewOptionContent || optionContent) && (
                      <CDivider spacing={0} />
                    )}
                    {hasMore && (
                      <MenuItem onClick={props.onLoadMoreSelected}>
                        <ListItemIcon>
                          <SVGIcon name="ellipsis-h" />
                        </ListItemIcon>
                        もっと見る
                      </MenuItem>
                    )}
                    {createNewOptionContent && (
                      <MenuItem onClick={props.onCreateNewSelected}>
                        <ListItemIcon>
                          <SVGIcon name="user-plus" />
                        </ListItemIcon>
                        {createNewOptionContent}
                      </MenuItem>
                    )}
                    {optionContent && (
                      <MenuItem onClick={props.onOptionContentSelected}>
                        {optionContent}
                      </MenuItem>
                    )}
                  </Paper>
                ) : null}
              </div>
            </div>
          );
        }}
      </Downshift>
    </div>
  );
};

export default CAutoComplete;
