import { useCallback, useState } from 'react';

/**
 * Promiseの前後でフラグを立てたり落としたりするときに使用する便利hooks
 * @param initialState
 */
export function useFlag(
  initialState = false
): [boolean, <T>(func: () => Promise<T>) => Promise<T>, (f: boolean) => void] {
  const [flag, setFlag] = useState(initialState);

  const callWithFlag = useCallback(<T>(func: () => Promise<T>) => {
    setFlag(true);
    return func().finally(() => setFlag(false));
  }, []);

  return [flag, callWithFlag, setFlag];
}
