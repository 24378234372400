import { VisitResource } from '@karutekun/core/visit';
import { trpc } from '@karutekun/shared/data-access/api-base';
import { mapById } from '@karutekun/shared/util/objects';
import { keepPreviousData } from '@tanstack/react-query';
import { useCallback } from 'react';

export function useFetchVisitsByDateRange(range: { from: string; to: string }) {
  return trpc.visit.findByDateRange.useQuery(range, {
    placeholderData: keepPreviousData,
  });
}
// TODO: visit[リファクタリング] ちょっと暫定的にinvalidate処理をこんな感じに書いてる。あとで設計考えなおす
useFetchVisitsByDateRange.useInvalidate = function useInvalidate() {
  const utils = trpc.useContext();
  return useCallback(
    async (input?: { from: string; to: string }) =>
      utils.visit.findByDateRange.invalidate(input),
    [utils.visit.findByDateRange]
  );
};
useFetchVisitsByDateRange.useSetQueryData =
  function useSetQueryData(queryInput: { from: string; to: string }) {
    const utils = trpc.useContext();
    return useCallback(
      async (
        visits: (Pick<VisitResource, 'id'> & Partial<VisitResource>)[]
      ) => {
        const visitsById = mapById(visits);
        utils.visit.findByDateRange.setData(queryInput, (oldData) =>
          (oldData ?? []).map((v) =>
            visitsById[v.id] ? { ...v, ...visitsById[v.id] } : v
          )
        );
      },
      [queryInput, utils.visit.findByDateRange]
    );
  };
