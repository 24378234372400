import { Action, Dispatch } from 'redux';
import { deprecated } from 'typesafe-actions';
import { sendRequest } from '../actions/request';
import { ServiceSummary } from '../models/serviceSummary';

const { createAction } = deprecated;

enum ActionNames {
  SetMenuSummaries = 'analytics/SetMenuSummaries',
  SetProductSummaries = 'analytics/SetProductSummaries',
  SetTermSummary = 'analytics/SetTermSummary',
}

export const setMenuSummaries = createAction(
  ActionNames.SetMenuSummaries,
  (action) => {
    return (summaries: ServiceSummary[]) => action({ summaries });
  }
);

export const setProductSummaries = createAction(
  ActionNames.SetProductSummaries,
  (action) => {
    return (summaries: ServiceSummary[]) => action({ summaries });
  }
);
export const setTermSummary = createAction(
  ActionNames.SetTermSummary,
  (action) => {
    return (summary: ServiceSummary) => action({ summary });
  }
);

// リアルタイム集計のAPI
// 施術・店販分析
export function fetchSummary(
  from: string,
  to: string,
  menuCategoryIds: number[],
  productCategoryIds: number[]
) {
  return async function (dispatch: Dispatch<Action>) {
    const json = await sendRequest(dispatch, `analytics/services/sales`, {
      params: {
        filter: JSON.stringify({
          from,
          to,
          categoryIds: menuCategoryIds.concat(productCategoryIds),
        }),
      },
    });

    dispatch(setTermSummary(json.termSummary));
    dispatch(setMenuSummaries(json.menuSummaries));
    dispatch(setProductSummaries(json.productSummaries));
  };
}
