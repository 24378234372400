import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      d="M16.85 12.248c.24.24.64.09.67-.24.15-1.44.72-2.74 1.66-3.75 1.21-1.3 2.92-2.02 4.82-2.02 1.9 0 3.64.71 4.84 2 1.22 1.31 1.81 3.13 1.67 5.12-.24 3.25-2.25 6.09-4.72 7.04-.26.1-.33.45-.13.65l2.02 2.02c.13.13.32.16.48.07 3.17-1.74 5.54-5.35 5.85-9.52.22-2.97-.7-5.73-2.59-7.76-1.87-2.01-4.5-3.12-7.4-3.12-2.9 0-5.51 1.11-7.39 3.13a9.726 9.726 0 0 0-2.14 3.69c-.05.14 0 .3.1.41l2.27 2.27h-.01zM37.03 40.591H7.76c1.46-7.9 9.43-11.5 16.24-11.5.57 0 1.16.04 1.74.09l-3.41-3.41a.302.302 0 0 0-.24-.09c-7.75.64-16.13 5.19-17.78 14.35-.19 1.08.07 2.15.73 2.93.61.73 1.49 1.13 2.46 1.13h32.67s.11.02.16-.12c.05-.14-.02-.22-.02-.22l-3.07-3.07a.288.288 0 0 0-.21-.09M43.19 44.228c-.4 0-.79-.15-1.1-.45L5.6 7.288c-.6-.61-.6-1.59 0-2.19.61-.6 1.59-.6 2.19 0l36.5 36.49c.61.61.61 1.59 0 2.19-.3.3-.7.45-1.1.45"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgUserSlash = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgUserSlash);
export default Memo;
