import { setVisits } from '@karutekun/shared/data-access/state/visit';
import { notEmpty } from '@karutekun/shared/util/objects';
import * as _ from 'lodash';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from '../actions/customerAction';
import { PlainCustomer } from '../models/customer';
import { mergeIgnoringUndefField } from '../util/common';

export type CustomerActions = ActionType<typeof actions>;

export interface CustomerState {
  map: { [key: number]: PlainCustomer };
}

const initialState: CustomerState = {
  map: {},
};

function customerReducer(
  state: CustomerState = initialState,
  action: CustomerActions | ActionType<typeof setVisits>
): CustomerState {
  switch (action.type) {
    case getType(actions.setCustomer): {
      const { customer } = action.payload;
      const newCustomer = mergeIgnoringUndefField(
        state.map[customer.id],
        customer
      );

      if (_.isEqual(state.map[customer.id], newCustomer)) {
        return state;
      }

      return {
        map: {
          ...state.map,
          [customer.id]: newCustomer,
        },
      };
    }
    case getType(actions.setCustomers): {
      const { customers } = action.payload;
      // 一致確認を行う
      const newCustomers = customers.map((c) =>
        mergeIgnoringUndefField(state.map[c.id], c)
      );
      if (
        _.isEqual(
          customers.map((c) => state.map[c.id]),
          newCustomers
        )
      ) {
        return state;
      }

      return {
        map: {
          ...state.map,
          ...newCustomers.reduce((prev, current) => {
            // TODO 一時的にルールを無効化しています。気づいたベースで直してください
            // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'number' can't be used to index type '{}'.
            prev[current.id] = current;
            return prev;
          }, {}),
        },
      };
    }
    case getType(actions.unsetCustomer): {
      const { customerId } = action.payload;
      const map = state.map;

      delete map[customerId];

      return {
        ...state,
        map: { ...map },
      };
    }
    case setVisits.type: {
      const customers = action.payload.map((v) => v.customer).filter(notEmpty);
      return {
        map: {
          ...state.map,
          ...customers.reduce((prev, current) => {
            // TODO 一時的にルールを無効化しています。気づいたベースで直してください
            // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'number' can't be used to index type '{}'.
            prev[current.id] = mergeIgnoringUndefField(
              state.map[current.id],
              current
            );
            return prev;
          }, {}),
        },
      };
    }
    default:
      return state;
  }
}

export default customerReducer;
