import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { createCustomer } from '../../../actions/customerAction';
import { pushSnackbar } from '../../../actions/generalAction';
import {
  CustomerSalonInformation,
  PlainCustomer,
} from '../../../models/customer';
import {
  selectActiveVisitMotivationsBySalonId,
  selectMySalon,
  selectSortedActiveCounselingsBySalonId,
} from '../../../selectors/salonSelector';
import { GlobalState } from '../../../store';
import Routing from '../../../util/routing';
import CreateCustomerDialog, {
  DispatchProps,
  StateProps,
} from '../components/CreateCustomerDialog';

const mapStateToProps = (state: GlobalState): StateProps => {
  const salon = selectMySalon(state);
  return {
    counselings: selectSortedActiveCounselingsBySalonId(state, salon.id),
    visitMotivations: selectActiveVisitMotivationsBySalonId(state, salon.id),
    customSetting: salon.customSetting,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  async create(
    plainCustomer: PlainCustomer,
    customerSalonInformation: CustomerSalonInformation
  ): Promise<PlainCustomer> {
    // TODO typesafe-actions を redux-thunk とどのように組み合わせて型付けするのか調べる
    const customer = await dispatch(
      // TODO 一時的に lint を無効化しています。気づいたベースで直してください
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      createCustomer(plainCustomer, customerSalonInformation) as any
    );

    return customer;
  },

  pushSnackbar(id: number, name: string) {
    dispatch(
      pushSnackbar({
        message: `${name} 様のカルテを作成しました`,
        type: 'success',
        linkTo: Routing.customer(id),
      })
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateCustomerDialog);
