import {
  PermissionKey,
  PermissionRole,
  isAdminRole,
} from '@karutekun/core/permission-role';
import { DateType, DateTypes } from '../util/date';
import { PlainWorkShift, WorkShift, WorkType } from './salonScheduleSettings';

export type PlainStylistShiftSetting = {
  capacity: number;
  shift: { [key in DateType]: PlainWorkShift };
};
export type StylistShiftSetting = {
  capacity: number;
  shift: { [key in DateType]: WorkShift };
};
export type PlainStylist = {
  id: number;
  name: string;
  imageUrl: string | null;
  color: string;
  isActive: boolean;
  canAccess: boolean;
  order: number;
  basicShiftSetting: PlainStylistShiftSetting | null;
};

export type PlainMySalonStylist = PlainStylist & {
  permissionRoleId: number;
};

export type MyInfo = {
  phone: string;
  firebaseImageBasePath: string;
};

// 所属申請時などで使われる最小限の情報
export type StylistMinimum = Pick<PlainStylist, 'id' | 'name' | 'imageUrl'>;

// 自サロンのスタッフ
export type MySalonStylist = PlainMySalonStylist & {
  permissionRole: PermissionRole;
  basicShiftSetting: StylistShiftSetting | null;
};

// 自身の情報
export type PlainStylistMe = PlainMySalonStylist & MyInfo;
export type StylistMe = PlainStylistMe & {
  permissionRole: PermissionRole;
  basicShiftSetting: StylistShiftSetting | null;
};

// TODO 一時的に lint を無効化しています。気づいたベースで直してください
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createPlainStylistFromResource(res: any): PlainStylist {
  return {
    id: res.id,
    name: res.name,
    imageUrl: res.imageUrl,
    color: res.color,
    isActive: res.isActive,
    canAccess: res.canAccess,
    order: res.order,
    basicShiftSetting: res.basicShiftSetting
      ? createStylistShiftSettingFromResource(res.basicShiftSetting)
      : null,
  };
}

export function createPlainMySalonStylistFromResource(
  // TODO 一時的に lint を無効化しています。気づいたベースで直してください
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  res: any
): PlainMySalonStylist {
  return {
    ...createPlainStylistFromResource(res),
    permissionRoleId: res.permissionRole.roleId,
  };
}

// TODO 一時的に lint を無効化しています。気づいたベースで直してください
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createMyInfoFromResource(res: any): { id: number } & MyInfo {
  return {
    id: res.id,
    phone: res.phone,
    firebaseImageBasePath: res.firebaseImageBasePath,
  };
}

export function createStylistShiftSettingFromResource(
  // TODO 一時的に lint を無効化しています。気づいたベースで直してください
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  res: any
): PlainStylistShiftSetting {
  return {
    capacity: res.capacity,
    shift: DateTypes.reduce((prev, dateType) => {
      prev[dateType] = res.shift[dateType];
      return prev;
      // TODO 一時的に lint を無効化しています。気づいたベースで直してください
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }, {} as any),
  };
}

export function emptyPlainStylist(): PlainStylist {
  return {
    id: 0,
    name: '',
    imageUrl: null,
    color: '',
    isActive: true,
    canAccess: true,
    order: 0,
    basicShiftSetting: null,
  };
}
export function emptyPlainMySalonStylist(): PlainMySalonStylist {
  return {
    ...emptyPlainStylist(),
    permissionRoleId: 0,
  };
}
export function emptyStylistShiftSetting(): PlainStylistShiftSetting {
  return {
    capacity: 1,
    shift: DateTypes.reduce((prev, dateType) => {
      prev[dateType] = { workType: WorkType.FullTime, shiftPatternId: null };
      return prev;
      // TODO 一時的に lint を無効化しています。気づいたベースで直してください
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }, {} as any),
  };
}

export function isAdmin(stylist: PlainMySalonStylist) {
  return isAdminRole(stylist.permissionRoleId);
}
export function checkPermission(
  stylist: MySalonStylist,
  permission: PermissionKey
) {
  return stylist.permissionRole[permission];
}
