import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M15.75 4.25h16.505a5.511 5.511 0 0 1 5.495 5.495v.505h.505a5.512 5.512 0 0 1 5.495 5.495V30.755a5.512 5.512 0 0 1-5.495 5.495h-.505v3.47a4.03 4.03 0 0 1-4.03 4.03H14.28a4.03 4.03 0 0 1-4.03-4.03v-3.47h-.505a5.512 5.512 0 0 1-5.495-5.495V15.745a5.511 5.511 0 0 1 5.495-5.495h.505v-.505a5.511 5.511 0 0 1 5.495-5.495zm18.5 5.503v.497h-20.5v-.497a2.011 2.011 0 0 1 2.003-2.003h16.494a2.011 2.011 0 0 1 2.003 2.003m3.5 22.997h.497a2.011 2.011 0 0 0 2.003-2.003V15.753a2.011 2.011 0 0 0-2.003-2.003H9.753a2.011 2.011 0 0 0-2.003 2.003v14.994a2.011 2.011 0 0 0 2.003 2.003h.497v-7.97a4.03 4.03 0 0 1 4.03-4.03h19.44a4.03 4.03 0 0 1 4.03 4.03zm-24-7.97a.53.53 0 0 1 .53-.53h19.44a.53.53 0 0 1 .53.53v14.94a.53.53 0 0 1-.53.53H14.28a.53.53 0 0 1-.53-.53zM39 17.25a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgPrint = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgPrint);
export default Memo;
