// TODO: グラフの色指定は他の箇所でも似たようなコードが散在するので、共通化する余地ある
const salesChartKeys = [
  {
    dataKey: 'menuSales',
    name: '施術売上',
    color: '#20a8d8',
  },
  {
    dataKey: 'productSales',
    name: '店販売上',
    color: '#63c2de',
  },
];
const salesChartKeysWithoutTax = [
  {
    dataKey: 'menuSalesWithoutTax',
    name: '施術売上',
    color: '#20a8d8',
  },
  {
    dataKey: 'productSalesWithoutTax',
    name: '店販売上',
    color: '#63c2de',
  },
];
export const getSalesChartKeys = (isTaxIncluded: boolean) =>
  isTaxIncluded ? salesChartKeys : salesChartKeysWithoutTax;
