import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M16.304 8.856c.807-.94 1.99-1.606 3.384-1.606h8.625c1.393 0 2.576.666 3.383 1.606.055.064.105.132.15.204l2.417 3.817c.265.275.546.373.706.373H40.5A4.75 4.75 0 0 1 45.25 18v18a4.75 4.75 0 0 1-4.75 4.75h-33A4.75 4.75 0 0 1 2.75 36V18a4.75 4.75 0 0 1 4.75-4.75h.375v-.5a1.5 1.5 0 0 1 1.5-1.5h2.25a1.5 1.5 0 0 1 1.5 1.5v.5c.102 0 .31-.047.61-.367l2.419-3.823c.045-.072.095-.14.15-.204m2.727 2.203-2.415 3.816a1.74 1.74 0 0 1-.15.202c-.714.833-1.835 1.673-3.341 1.673H7.5A1.25 1.25 0 0 0 6.25 18v18a1.25 1.25 0 0 0 1.25 1.25h33A1.25 1.25 0 0 0 41.75 36V18a1.25 1.25 0 0 0-1.25-1.25h-5.531c-1.448 0-2.668-.778-3.435-1.673a1.74 1.74 0 0 1-.15-.202l-2.415-3.816c-.223-.228-.454-.309-.657-.309h-8.625c-.202 0-.433.081-.656.309M24 19.75a5.75 5.75 0 1 0 0 11.5 5.75 5.75 0 0 0 0-11.5m-9.25 5.75a9.25 9.25 0 1 1 18.5 0 9.25 9.25 0 0 1-18.5 0"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgCamera = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgCamera);
export default Memo;
