import { ChatMessageBodyImage as ChatMessageBodyImageEntity } from '../../models/chatMessageBodyImage/entity';
import ChatMessageBodyImagesContainer from './ChatMessageBodyImagesContainer';

type Props = {
  chatMessageBody: ChatMessageBodyImageEntity;
};

const ChatMessageBodyImage: FC<Props> = (props) => {
  const { urls } = props.chatMessageBody;

  return <ChatMessageBodyImagesContainer urls={urls} />;
};

export default ChatMessageBodyImage;
