import { ChatMessageBody } from '../../models/chatMessageBody/schema';
import { PlainStylist } from '../../models/stylist';

export enum ChatMessageType {
  Text = 'text',
  Image = 'image',
  Karute = 'karute',
  Reservation = 'reservation',
  Broadcast = 'broadcastMessage',
  Receipt = 'receipt',
  ReservationRemind = 'reservationRemind',
}

export enum ChatMessageStatus {
  Success = 0,
  Failure = 1,
  Sending = 100, // Localでのみ使用する値
}

export enum ChatMessageSender {
  Salon = 0,
  Customer = 1,
  System = 2,
}

export type ChatMessage = {
  documentId: string;
  salonId: number;
  customerId: number;
  fromId: number;
  status: ChatMessageStatus;
  type: ChatMessageType;
  body: ChatMessageBody;
  createdAtMs: number;
  stylist?: PlainStylist | null;
};
