import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M40.5 9.51h-8.75v-2a4.06 4.06 0 0 0-.3-1.54 3.81 3.81 0 0 0-.87-1.3 4 4 0 0 0-2.83-1.17h-7.49a4 4 0 0 0-1.54.3 4 4 0 0 0-2.17 2.17 4 4 0 0 0-.3 1.53v2H7.5a1.75 1.75 0 1 0 0 3.5h1.36l1.77 26.34A4.76 4.76 0 0 0 15.38 44h17.24a4.74 4.74 0 0 0 4.75-4.64L39.14 13h1.36a1.75 1.75 0 1 0 0-3.5zm-20.75-2a.391.391 0 0 1 0-.18.48.48 0 0 1 .1-.17.59.59 0 0 1 .17-.1 1.29 1.29 0 0 1 .19 0h7.51a.46.46 0 0 1 .19 0 .7.7 0 0 1 .16.1.48.48 0 0 1 .1.17.41.41 0 0 1 0 .19v2h-8.5zm14.13 31.64a1.301 1.301 0 0 1-1.26 1.36H15.38a1.29 1.29 0 0 1-1.26-1.36l-1.76-26.14h23.28zM18.5 19.51a1.75 1.75 0 1 1 3.5 0v13.5a1.75 1.75 0 1 1-3.5 0zm9.25-1.75A1.75 1.75 0 0 0 26 19.51v13.5a1.75 1.75 0 1 0 3.5 0v-13.5a1.75 1.75 0 0 0-1.75-1.75"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgDelete = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgDelete);
export default Memo;
