import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M22.73 24.23c4.05 0 7.61-3.76 7.93-8.38a8.07 8.07 0 0 0-2.03-6.15 8 8 0 0 0-5.89-2.46 7.92 7.92 0 0 0-5.88 2.47 8 8 0 0 0-2.06 6.15c.34 4.7 3.82 8.37 7.93 8.37m0-13.49H22.751zm.011 0a4.49 4.49 0 0 1 3.31 1.34 4.56 4.56 0 0 1 1.12 3.53c-.2 2.78-2.23 5.12-4.44 5.12-2.21 0-4.24-2.35-4.44-5.13a4.54 4.54 0 0 1 1.13-3.5 4.42 4.42 0 0 1 3.32-1.36m9.855 9.753a3.36 3.36 0 0 0 1.624.636c1.39 0 3.06-1.57 3.19-3.8a3.338 3.338 0 0 0-.82-2.58 3.42 3.42 0 0 0-4.46-.29 10.117 10.117 0 0 0-.87-3.56 6.879 6.879 0 0 1 2.91-.63 6.69 6.69 0 0 1 5 2.09 6.86 6.86 0 0 1 1.75 5.22c-.27 3.9-3.29 7.07-6.71 7.07a6.302 6.302 0 0 1-4.59-2.14 11.64 11.64 0 0 0 1.89-3.38 3.36 3.36 0 0 0 1.086 1.364M7.75 16.25A1.75 1.75 0 0 1 9.5 18v3.5H13a1.75 1.75 0 0 1 0 3.5H9.5v3.5a1.75 1.75 0 0 1-3.5 0V25H2.5a1.75 1.75 0 0 1 0-3.5H6V18a1.75 1.75 0 0 1 1.75-1.75m38.91 18.1c-1.26-5.07-6.26-8.47-12.45-8.47a16.191 16.191 0 0 0-3.74.4.1.1 0 0 0-.05.153.1.1 0 0 0 .05.037 16.909 16.909 0 0 1 4.59 2.93h.05c3 .21 6.44 1.54 7.8 4.7a.11.11 0 0 1-.05.136.11.11 0 0 1-.05.014h-4.25c.11.25.22.5.31.76l.48 1.52c.097.4.144.809.14 1.22h4.59a2.62 2.62 0 0 0 2.08-1 2.84 2.84 0 0 0 .5-2.4m-23.91-7.62c6.56 0 13.42 3.27 15.16 10.27a2.94 2.94 0 0 1-2.8 3.72H10.39A2.94 2.94 0 0 1 7.59 37c1.74-7.06 8.6-10.27 15.16-10.27m0 3.5c-5.43 0-10.14 2.47-11.59 7h23.17c-1.45-4.46-6.15-7-11.58-7"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgGroupAdd = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgGroupAdd);
export default Memo;
