import { ActionType, getType } from 'typesafe-actions';
import * as actions from '../actions/generalAction';

export type SnackbarNotificationItem = {
  key: number;
  message: string;
  dismissed: boolean;
  type: 'info' | 'warning' | 'error' | 'success' | 'default';
  persist: boolean;
  linkTo?: string;
};
export type GeneralActions = ActionType<typeof actions>;

export type GeneralState = {
  snackbarNotificationItems: SnackbarNotificationItem[];
};

const initialState: GeneralState = {
  snackbarNotificationItems: [],
};

function createSnackbarNotificationItem(
  item: Partial<SnackbarNotificationItem>
) {
  return {
    message: item.message || '',
    type: item.type || 'default',
    key: new Date().getTime() + Math.random(),
    dismissed: false,
    persist: item.persist || false,
    linkTo: item.linkTo,
  };
}

function generalReducer(
  state = initialState,
  action: GeneralActions
): GeneralState {
  switch (action.type) {
    case getType(actions.pushSnackbar): {
      return {
        ...state,
        snackbarNotificationItems: [
          ...state.snackbarNotificationItems,
          createSnackbarNotificationItem(action.payload),
        ],
      };
    }
    case getType(actions.closeSnackbar): {
      const { key } = action.payload;
      return {
        ...state,
        snackbarNotificationItems: state.snackbarNotificationItems.map(
          (item) => {
            if (key === item.key) {
              return { ...item, dismissed: true };
            }
            return item;
          }
        ),
      };
    }
    case getType(actions.removeSnackbar): {
      const { key } = action.payload;
      return {
        ...state,
        snackbarNotificationItems: state.snackbarNotificationItems.filter(
          (item) => item.key !== key
        ),
      };
    }
    default:
      return state;
  }
}

export default generalReducer;
