import { buildWithDefaults } from '@karutekun/shared/util/entity';
import { PermissionKey, PermissionRole } from './data';

export type PermissionRoleEdit = Omit<
  PermissionRole,
  'salonId' | 'roleId' | 'createdAt' | 'updatedAt'
>;

export function buildPermissionRoleEdit(
  overwrite: Partial<PermissionRole> = {}
): PermissionRoleEdit {
  const defaults: PermissionRoleEdit = {
    name: '',
    canPullbackPaymentKarute: false,
    canDeleteKarute: false,
    canDeleteSchedule: false,
    canShowPersonalData: false,
    canDeleteCustomer: false,
    canUpdateSalon: false,
    canAcceptSalonRequest: false,
    canDeactivateSalonStylist: false,
    canUpdateSalonStylistAccessibility: false,
    canUpdateSalonStylistRole: false,
    canUpdatePermissionRole: false,
    canUpdateIsTaxIncluded: false,
    canUpdateMenu: false,
    canDeleteMenu: false,
    canUpdateCustomerOpinion: false,
    canDeleteCustomerOpinion: false,
    canUpdateKaruteMemoTemplate: false,
    canDeleteKaruteMemoTemplate: false,
    canUpdatePrinter: false,
    canUpdateScheduleSetting: false,
    canUpdateReservationSetting: false,
    canUpdateChatSetting: false,
    canShowSalesOnSchedule: false,
  };
  return buildWithDefaults(defaults, overwrite);
}

const PermissionNameDescMap: {
  [key in PermissionKey]: { name: string; description: string };
} = {
  canPullbackPaymentKarute: {
    name: '会計の取り消し',
    description: '会計済の来店記録の会計を取り消すことができます。',
  },
  canDeleteKarute: {
    name: '削除',
    description: '',
  },
  canDeleteSchedule: {
    name: '削除',
    description: '',
  },
  canShowPersonalData: {
    name: '個人情報表示とカルテcsvダウンロード',
    description: '',
  },
  canDeleteCustomer: {
    name: '削除や統合など',
    description: 'カルテの削除や統合、LINE連携の解除ができます。',
  },
  canUpdateSalon: {
    name: 'サロン情報更新',
    description:
      'サロンの名前・画像・秘密の言葉と、カルテ・来店記録・支払い方法のカスタマイズ設定が更新できます。',
  },
  canAcceptSalonRequest: {
    name: 'サロン所属申請の承認・却下',
    description: '',
  },
  canDeactivateSalonStylist: {
    name: 'スタッフの所属解除',
    description: '管理者の所属解除ができるのは管理者のみです。',
  },
  canUpdateSalonStylistAccessibility: {
    name: 'スタッフのアクセス制限',
    description:
      'スタッフのアクセスの制限と許可ができ、アクセス制限の対象外となります。アクセス制限されるとアプリの使用ができなくなります。',
  },
  canUpdateSalonStylistRole: {
    name: 'スタッフの役割変更',
    description:
      '管理者の役割の付与と剥奪は管理者のみ可能です。また、サロンに管理者は必ず1人必要です。',
  },
  canUpdatePermissionRole: {
    name: '役割の編集',
    description:
      '役割の名前と個別権限の許可・不許可を変更できます。ただし、「管理者」の役割は編集できません。',
  },
  // TODO 適切な名前に変更したい.
  // 内税・外税切り替え権限として作られたが、現在は消費税の端数処理設定に使われている
  canUpdateIsTaxIncluded: {
    name: '消費税設定の変更',
    description:
      '消費税設定の変更ができます。変更してもそれまでに作成された来店記録のお会計内容は変更されません。',
  },
  canUpdateMenu: {
    name: 'メニューの編集',
    description: '新規追加、名前の変更・並び替えができます。',
  },
  canDeleteMenu: {
    name: 'メニューの削除',
    description: '削除後も過去の来店記録や各種レポートでは表示されます。',
  },
  canUpdateCustomerOpinion: {
    name: '来店動機・カウンセリング項目の編集',
    description: '新規追加、内容の変更・並び替えができます。',
  },
  canDeleteCustomerOpinion: {
    name: '来店動機・カウンセリング項目の削除',
    description: '',
  },
  canUpdateKaruteMemoTemplate: {
    name: '施術メモ定型文の編集',
    description: '新規追加、内容の変更・並び替えができます。',
  },
  canDeleteKaruteMemoTemplate: {
    name: '施術メモ定型文の削除',
    description: '',
  },
  canUpdatePrinter: {
    name: 'レシートプリンターの設定変更',
    description: '',
  },
  canUpdateReservationSetting: {
    name: '予約受付の設定更新',
    description: '予約受付の基本設定が更新できます。',
  },
  canUpdateScheduleSetting: {
    name: '予約台帳の設定変更',
    description:
      '予約台帳、営業時間、シフトの基本設定、日次の営業時間やシフト表が更新できます。',
  },
  canUpdateChatSetting: {
    name: 'LINE連携・チャットの重要項目変更',
    description: 'LINE連携の設定や一斉配信メッセージの作成ができます。',
  },
  canShowSalesOnSchedule: {
    name: `予約台帳で全スタッフの売上の閲覧
(※ベータ機能)`,
    description:
      '予約台帳ですべてのスタッフの売上が閲覧できます。この権限がない場合は、自分とサロン全体の売上のみ確認できます。(※ベータ機能)',
  },
};

export const PermissionSectionStructure: {
  title: string;
  permissions: PermissionKey[];
}[] = [
  {
    title: 'カルテの管理',
    permissions: ['canShowPersonalData', 'canDeleteCustomer'],
  },
  {
    title: '予約・来店記録の管理',
    permissions: ['canPullbackPaymentKarute', 'canDeleteKarute'],
  },
  {
    title: 'スタッフの管理',
    permissions: [
      'canAcceptSalonRequest',
      'canUpdateSalonStylistRole',
      'canUpdatePermissionRole',
      'canUpdateSalonStylistAccessibility',
      'canDeactivateSalonStylist',
    ],
  },
  {
    title: 'サロン情報の管理',
    permissions: [
      'canUpdateSalon',
      'canUpdateIsTaxIncluded',
      'canUpdateMenu',
      'canDeleteMenu',
      'canUpdateCustomerOpinion',
      'canDeleteCustomerOpinion',
      'canUpdateKaruteMemoTemplate',
      'canDeleteKaruteMemoTemplate',
      'canUpdatePrinter',
      'canUpdateReservationSetting',
      'canUpdateScheduleSetting',
      'canUpdateChatSetting',
    ],
  },
  {
    title: 'その他',
    permissions: ['canShowSalesOnSchedule'],
  },
];

export function getPermissionName(key: PermissionKey) {
  return PermissionNameDescMap[key].name;
}
export function getPermissionDescription(key: PermissionKey) {
  return PermissionNameDescMap[key].description;
}
