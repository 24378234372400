import { dayjs, today } from '@karutekun/shared/util/datetime';
import { zDateRange } from './date-range';

/**
 * to に関して、未来の日付は今日に書き換えて返す
 */
export const zPastDateRange = zDateRange.transform(({ from, to }) => {
  const now = today();
  const toDayjs = dayjs(to);
  if (toDayjs.isAfter(now)) {
    to = now;
  }
  return { from, to };
});
