import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M9.833 5.25h28.339a4.591 4.591 0 0 1 4.578 4.578V38.172a4.592 4.592 0 0 1-4.578 4.578H9.828a4.592 4.592 0 0 1-4.578-4.578V9.828A4.591 4.591 0 0 1 9.828 5.25zm.003 3.5A1.091 1.091 0 0 0 8.75 9.836v28.328a1.091 1.091 0 0 0 1.086 1.086h28.328a1.091 1.091 0 0 0 1.086-1.086V9.836a1.091 1.091 0 0 0-1.086-1.086z"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgSquare = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgSquare);
export default Memo;
