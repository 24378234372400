import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M24 3.25C12.544 3.25 3.25 12.544 3.25 24S12.544 44.75 24 44.75 44.75 35.456 44.75 24 35.456 3.25 24 3.25M6.75 24c0-9.523 7.727-17.25 17.25-17.25S41.25 14.477 41.25 24 33.523 41.25 24 41.25 6.75 33.523 6.75 24m19-12a1.75 1.75 0 1 0-3.5 0v13.5c0 .966.784 1.75 1.75 1.75h9a1.75 1.75 0 1 0 0-3.5h-7.25z"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgBusinessTime = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgBusinessTime);
export default Memo;
