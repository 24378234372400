import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M22.763 3.263a1.75 1.75 0 0 1 2.474 0l7.5 7.5a1.75 1.75 0 1 1-2.474 2.474L25.75 8.725v21.369a1.75 1.75 0 0 1-3.5 0V8.724l-4.513 4.513a1.75 1.75 0 1 1-2.474-2.474zM12.75 19.75a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h22.5a2 2 0 0 0 2-2v-18a2 2 0 0 0-2-2H31.5a1.75 1.75 0 1 1 0-3.5h3.75a5.5 5.5 0 0 1 5.5 5.5v18a5.5 5.5 0 0 1-5.5 5.5h-22.5a5.5 5.5 0 0 1-5.5-5.5v-18a5.5 5.5 0 0 1 5.5-5.5h3.75a1.75 1.75 0 1 1 0 3.5z"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgShare = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgShare);
export default Memo;
