import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M29.75 6c0-.966.784-1.75 1.75-1.75H42c.967 0 1.75.784 1.75 1.75v10.5a1.75 1.75 0 1 1-3.5 0v-6.275L22.237 28.237a1.75 1.75 0 1 1-2.474-2.474L37.775 7.75H31.5A1.75 1.75 0 0 1 29.75 6m-20 7.75a2 2 0 0 0-2 2v22.5a2 2 0 0 0 2 2h22.5a2 2 0 0 0 2-2V21a1.75 1.75 0 1 1 3.5 0v17.25a5.5 5.5 0 0 1-5.5 5.5H9.75a5.5 5.5 0 0 1-5.5-5.5v-22.5a5.5 5.5 0 0 1 5.5-5.5h15.701a1.75 1.75 0 0 1 0 3.5z"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgExternalLinkAlt = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgExternalLinkAlt);
export default Memo;
