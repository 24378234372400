import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Props = {
  urls: string[];
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: '#fff',
    padding: theme.spacing(1),
  },
  image: {
    display: 'block',
    maxWidth: 200,
    maxHeight: 200,
  },
}));

const ChatMessageBodyImagesContainer: FC<Props> = (props) => {
  const classes = useStyles();

  const { urls } = props;

  return (
    <div className={classes.container}>
      {urls.length === 0 ? (
        <Typography variant="body1">写真は送信されていません</Typography>
      ) : (
        <Grid container direction="column" wrap="nowrap" spacing={1}>
          {urls.map((url, i) => (
            <Grid item key={i}>
              <a href={url} target="_blank" rel="noreferrer">
                <img src={url} className={classes.image} alt="" />
              </a>
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
};

export default ChatMessageBodyImagesContainer;
