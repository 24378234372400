import {
  dayjs,
  formatTimeForUser,
  unixToDate,
  unixToTime,
} from '@karutekun/shared/util/datetime';
import { useBoolean } from '@karutekun/shared/util/react-hooks';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import CSelect from '../../../../components_old/atoms/CSelect';
import CTimePicker from '../../../../components_old/atoms/CTimePicker';
import { selectMinuteInterval } from '../../../../selectors/scheduleSettingsSelector';

type Props = {
  open: boolean;
  afterMinutes: number;
  startedAt: number;
  finishedAt: number;
  onConfirm(adjustedFinishedAt?: number): void;
  onCancel(): void;
};

export const ConfirmChangeReservationTimeDialog: FC<Props> = ({
  open,
  afterMinutes,
  startedAt,
  finishedAt,
  onConfirm,
  onCancel,
}) => {
  const minuteInterval = useSelector(selectMinuteInterval);
  const minutesOptions = useMemo(() => {
    const options = [];

    // ±１時間のオプションを表示する
    const half = Math.round(60 / minuteInterval);
    for (let i = half; i >= -half; i--) {
      const value = afterMinutes + i * minuteInterval;
      if (value > 0) {
        options.push({ value, element: `${value}分` });
      }
    }
    return options;
  }, [afterMinutes, minuteInterval]);

  const [adjustedFinishedAt, setAdjustedFinishedAt] = useState(
    // 初期値は変更後のメニュー時間をもとに算出
    startedAt + afterMinutes * 60
  );
  const adjustedMinutes = (adjustedFinishedAt - startedAt) / 60;

  const [willAdjust, setWillAdjust] = useBoolean(true);

  const handleConfirm = useCallback(() => {
    onConfirm(willAdjust ? adjustedFinishedAt : undefined);
  }, [adjustedFinishedAt, onConfirm, willAdjust]);

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>予約の時刻変更</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1" mb={2}>
          メニューの合計時間が変わります。予約の終了時刻を変更しますか？
        </Typography>

        <Typography variant="body2" gutterBottom>
          現在の予約時刻
        </Typography>
        <Stack direction="row" spacing={1}>
          <Typography variant="body1">
            {formatTimeForUser(startedAt)} 〜 {formatTimeForUser(finishedAt)}
          </Typography>
          <Typography variant="body1">
            ({(finishedAt - startedAt) / 60}分)
          </Typography>
        </Stack>

        <RadioGroup
          defaultValue="1"
          onChange={(e) =>
            setWillAdjust.set(e.target.value === '1' ? true : false)
          }
        >
          <FormControlLabel value="1" control={<Radio />} label="変更する" />
          {willAdjust && (
            <Stack pl={3} direction="row" alignItems="center" spacing={2}>
              <Typography variant="body1">
                {formatTimeForUser(startedAt)} 〜
              </Typography>
              <CTimePicker
                value={unixToTime(adjustedFinishedAt, true)}
                onChange={(hhmmss) => {
                  const finishedAt = dayjs(
                    `${unixToDate(startedAt)} ${hhmmss}`,
                    'YYYY-MM-DD HH:mm:ss'
                  ).unix();
                  setAdjustedFinishedAt(
                    finishedAt <= startedAt
                      ? startedAt + minuteInterval
                      : finishedAt
                  );
                }}
              />
              <CSelect
                value={adjustedMinutes}
                options={minutesOptions}
                onChange={(value) => {
                  if (value !== undefined) {
                    setAdjustedFinishedAt(startedAt + value * 60);
                  }
                }}
              />
            </Stack>
          )}
          <FormControlLabel value="0" control={<Radio />} label="変更しない" />
          {!willAdjust && (
            <Stack pl={3} direction="row" alignItems="center" spacing={1}>
              <Typography variant="body1">
                {formatTimeForUser(startedAt)} 〜{' '}
                {formatTimeForUser(finishedAt)}
              </Typography>
              <Typography variant="body1">
                ({(finishedAt - startedAt) / 60}分)
              </Typography>
            </Stack>
          )}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onCancel}>
          キャンセル
        </Button>
        <Button variant="contained" color="primary" onClick={handleConfirm}>
          保存
        </Button>
      </DialogActions>
    </Dialog>
  );
};
