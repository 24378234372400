import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M8.74 28.75H4.75v-3.99a5.984 5.984 0 0 1 2.493 1.497 5.999 5.999 0 0 1 1.496 2.493m3.088 0a8.998 8.998 0 0 0-7.078-7.078v-6.344a8.998 8.998 0 0 0 7.078-7.078h24.344a9 9 0 0 0 7.078 7.078v6.344a9 9 0 0 0-7.078 7.078zm27.433 0h3.989v-3.99a5.998 5.998 0 0 0-3.99 3.99m3.989-16.51V8.25h-3.99a6 6 0 0 0 3.99 3.99M8.74 8.25a6.002 6.002 0 0 1-3.99 3.99V8.25zm34.76 24A3.25 3.25 0 0 0 46.75 29V8a3.25 3.25 0 0 0-3.25-3.25h-39A3.25 3.25 0 0 0 1.25 8v21a3.25 3.25 0 0 0 3.25 3.25zm-37.5 2a1.75 1.75 0 1 0 0 3.5h36a1.75 1.75 0 1 0 0-3.5zm3 5.5a1.75 1.75 0 1 0 0 3.5h30a1.75 1.75 0 1 0 0-3.5zm15-27a5.75 5.75 0 1 0 0 11.5 5.75 5.75 0 0 0 0-11.5m-9.25 5.75a9.25 9.25 0 1 1 18.5 0 9.25 9.25 0 0 1-18.5 0"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgMoneyBill = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgMoneyBill);
export default Memo;
