import { useMemo, useState } from 'react';

type Mutations = {
  set(v: boolean): void;
  setTrue(): void;
  setFalse(): void;
  toggle(): void;
};

export function useBoolean(initialState = false): [boolean, Mutations] {
  const [flag, setFlag] = useState(initialState);

  const mutations: Mutations = useMemo(
    () => ({
      set: setFlag,
      setTrue() {
        setFlag(true);
      },
      setFalse() {
        setFlag(false);
      },
      toggle() {
        setFlag((current) => !current);
      },
    }),
    []
  );

  return [flag, mutations];
}
