import { PermissionRole } from '@karutekun/core/permission-role';
import { ISalonCustomSettingWithDefaults } from '@karutekun/core/salon';
import { deprecated } from 'typesafe-actions';
import { CounselingSection } from '../models/counseling';
import { MySalon, Salon } from '../models/salon';
import { SalonMonthlyChatStatistics } from '../models/salonMonthlyChatStatistics';
import { PartialReservationSettings } from '../models/salonReservationSettings';
import { PartialScheduleSettings } from '../models/salonScheduleSettings';
import {
  PlainService,
  PlainServiceCategory,
  PlainServiceDiscount,
} from '../models/service';
import { PlainMySalonStylist } from '../models/stylist';
import { PaymentProvider } from '../models/subscription';
import { ThunkDispatch } from '../store';
import { sendRequest } from './request';

const { createAction } = deprecated;

enum ActionNames {
  // 一括で自分のサロン情報をセット
  SetMySalon = 'salon/SetMySalon',

  // グループサロンの情報をセット
  SetGroupSalons = 'salon/SetGroupSalons',

  // スタッフ
  SetSalonStylistMap = 'salon/SetSalonStylistMap',
  SetSalonStylist = 'salon/SetSalonStylist',

  // 権限
  SetSalonPermissionRole = 'salon/SetSalonPermissionRole',
  SetSalonPermissionRoleMap = 'salon/SetSalonPermissionRoleMap',

  // カウンセリング
  SetSalonCounselingMap = 'salon/SetSalonCounselingMap',

  // メニュー
  SetSalonServiceCategoryMap = 'salon/SetSalonServiceCategoryMap',
  SetSalonServiceMap = 'salon/SetSalonServiceMap',
  SetSalonServiceDiscountMap = 'salon/SetSalonServiceDiscountMap',
  SetSalonMenuCategoryMap = 'salon/SetSalonMenuCategoryMap',
  SetSalonMenuMap = 'salon/SetSalonMenuMap',
  SetSalonMenuDiscountMap = 'salon/SetSalonMenuDiscountMap',
  SetSalonProductCategoryMap = 'salon/SetSalonProductCategoryMap',
  SetSalonProductMap = 'salon/SetSalonProductMap',
  SetSalonProductDiscountMap = 'salon/SetSalonProductDiscountMap',

  // サブスクリプションの決済手段更新
  SetSalonSubscriptionPaymentProvider = 'salon/SetSalonSubscriptionPaymentProvider',

  // チャット
  SetSalonChatStatistics = 'salon/SetSalonChatStatistics',

  // カスタマイズ設定
  SetSalonCustomSetting = 'salon/SetSalonCustomSetting',

  // 予約設定
  SetSalonReservationSettings = 'salon/SetSalonReservationSettings',

  // 台帳設定
  SetSalonScheduleSettings = 'salon/SetSalonScheduleSettings',
}

export const setMySalon = createAction(ActionNames.SetMySalon, (action) => {
  return (salon: Partial<MySalon>) => action({ salon });
});
export const setGroupSalons = createAction(
  ActionNames.SetGroupSalons,
  (action) => {
    return (salons: Salon[]) => action({ salons });
  }
);
export const setSalonStylistMap = createAction(
  ActionNames.SetSalonStylistMap,
  (action) => {
    return (stylistMap: IdMap<PlainMySalonStylist>) => action({ stylistMap });
  }
);
export const setSalonStylist = createAction(
  ActionNames.SetSalonStylist,
  (action) => {
    return (stylistId: number, stylist: Partial<PlainMySalonStylist>) =>
      action({ stylistId, stylist });
  }
);
export const setSalonPermissionRole = createAction(
  ActionNames.SetSalonPermissionRole,
  (action) => {
    return (id: number, permissionRole: Partial<PermissionRole>) =>
      action({ id, permissionRole });
  }
);
export const setSalonPermissionRoleMap = createAction(
  ActionNames.SetSalonPermissionRoleMap,
  (action) => {
    return (permissionRoleMap: IdMap<PermissionRole>) =>
      action({ permissionRoleMap });
  }
);

export const setSalonCounselingMap = createAction(
  ActionNames.SetSalonCounselingMap,
  (action) => {
    return (counselingMap: IdMap<CounselingSection>) =>
      action({ counselingMap });
  }
);

export const setSalonServiceCategoryMap = createAction(
  ActionNames.SetSalonServiceCategoryMap,
  (action) => {
    return (categoryMap: IdMap<PlainServiceCategory>) =>
      action({ categoryMap });
  }
);
export const setSalonServiceMap = createAction(
  ActionNames.SetSalonServiceMap,
  (action) => {
    return (serviceMap: IdMap<PlainService>) => action({ serviceMap });
  }
);
export const setSalonServiceDiscountMap = createAction(
  ActionNames.SetSalonServiceDiscountMap,
  (action) => {
    return (discountMap: IdMap<PlainServiceDiscount>) =>
      action({ discountMap });
  }
);

export const setSalonSubscriptionPaymentProvider = createAction(
  ActionNames.SetSalonSubscriptionPaymentProvider,
  (action) => {
    return (paymentProvider: PaymentProvider | null) =>
      action({ paymentProvider });
  }
);

export const setSalonCustomSetting = createAction(
  ActionNames.SetSalonCustomSetting,
  (action) => {
    return (customSetting: Partial<ISalonCustomSettingWithDefaults>) =>
      action({ customSetting });
  }
);

export const setSalonReservationSettings = createAction(
  ActionNames.SetSalonReservationSettings,
  (action) => {
    return (settings: PartialReservationSettings) => action({ settings });
  }
);

export const setSalonScheduleSettings = createAction(
  ActionNames.SetSalonScheduleSettings,
  (action) => {
    return (settings: PartialScheduleSettings) => action({ settings });
  }
);

export const setSalonChatStatistics = createAction(
  ActionNames.SetSalonChatStatistics,
  (action) => {
    return (salonId: number, statistics: SalonMonthlyChatStatistics) =>
      action({
        salonId,
        statistics,
      });
  }
);

export function updateSalonName(name: string) {
  return async function (dispatch: ThunkDispatch) {
    await sendRequest(dispatch, 'salons/update/name', {
      method: 'POST',
      body: JSON.stringify({ name }),
    });

    dispatch(setMySalon({ name }));
  };
}

export function updateSalonFindKeyword(findKeyword: string) {
  return async function (dispatch: ThunkDispatch) {
    await sendRequest(dispatch, 'salons/update/findKeyword', {
      method: 'POST',
      body: JSON.stringify({ findKeyword }),
    });

    dispatch(setMySalon({ findKeyword }));
  };
}
