import { CustomerSex } from '@karutekun/core/customer';
import { Grid, InputLabel, TextField, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback, useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import CButton from '../../../../components_old/atoms/CButton';
import CInformation from '../../../../components_old/atoms/CInformation';
import CRadioGroup from '../../../../components_old/atoms/CRadioGroup';
import { Customer, PlainCustomer } from '../../../../models/customer';
import { CustomerImage } from '../../../../models/customerImage';
import { SalonCustomSetting } from '../../../../models/salonCustomSetting';
import { PlainUploadedPaper } from '../../../../models/uploadedPaper';
import { useSalonStatus } from '../../../../templates/providers/salonStatus/salonStatusContext';
import { notEmpty } from '../../../../util/common';

type Props = {
  customer: Customer;
  showPersonalData: boolean;
  customSetting: SalonCustomSetting;

  onPressSave(customer: Partial<PlainCustomer>): Promise<unknown>;
};

const useStyles = makeStyles((theme: Theme) => ({
  thumbnail: {
    margin: theme.spacing(1),
    width: 120,
    height: 120,
    border: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.grey[100],
    objectFit: 'contain',
  },
}));

export const CustomerBasicInfo: FC<Props> = (props) => {
  const classes = useStyles();
  const { showPersonalData, onPressSave } = props;

  const { checkAndOpenDialog } = useSalonStatus();

  const [customer, setCustomer] = useState(props.customer);
  const [isValid, setIsValid] = useState(true);
  const [isEdited, setIsEdited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setCustomer(props.customer);
  }, [props.customer]);

  const update = useCallback(
    (update: Partial<PlainCustomer>) => {
      setIsEdited(true);
      setCustomer({ ...customer, ...update });
    },
    [customer]
  );

  const validatorListener = useCallback(
    (result: boolean) => {
      const isNameValid = customer.name !== '';
      const isNameKanaValid = customer.nameKana !== '';
      setIsValid(result && isNameValid && isNameKanaValid);
    },
    [customer]
  );

  function renderSexRadioGroup() {
    let sexOrder: CustomerSex[];
    const defaultSex =
      props.customSetting.customerForm?.sex?.default ?? CustomerSex.Female;
    switch (defaultSex) {
      case CustomerSex.Male:
        sexOrder = [CustomerSex.Male, CustomerSex.Female];
        break;
      default:
        sexOrder = [CustomerSex.Female, CustomerSex.Male];
    }

    const options = sexOrder.map((sex) => {
      return { value: sex, label: sex === CustomerSex.Male ? '男性' : '女性' };
    });

    return (
      <CRadioGroup
        label="性別"
        currentValue={customer.sex}
        options={options}
        onChange={(value) =>
          update({
            sex: value,
          })
        }
        row
      />
    );
  }

  function renderImages(urls: string[]) {
    return urls.map((url, i) => (
      <a key={i} target="_blank" rel="noopener noreferrer" href={url}>
        <img className={classes.thumbnail} src={url} alt={`${i + 1}枚目`} />
      </a>
    ));
  }

  function renderCustomerImages(images: CustomerImage[]) {
    const urls = images.map((image) => image.url);
    return renderImages(urls);
  }

  function renderUploadedPapers(uploadedPaper: PlainUploadedPaper) {
    const urls = [uploadedPaper.url1, uploadedPaper.url2, uploadedPaper.url3];
    const validUrls = urls.filter(notEmpty);
    return renderImages(validUrls);
  }

  function renderSaveButton() {
    return (
      <div style={{ textAlign: 'right' }}>
        <CButton
          variant="contained"
          color="primary"
          isLoading={isLoading}
          disabled={!isEdited || !isValid}
          onClick={async () => {
            if (!checkAndOpenDialog()) {
              return;
            }

            setIsLoading(true);
            await onPressSave(customer)
              .then(
                () => setIsEdited(false),
                (e) => console.log(e)
              )
              .finally(() => setIsLoading(false));
          }}
        >
          保存
        </CButton>
        {isEdited && !isValid && (
          <Typography display="block" color="error">
            入力に不備があります
          </Typography>
        )}
      </div>
    );
  }

  return (
    <div>
      <ValidatorForm
        onSubmit={() => {
          // 何もしない
        }}
      >
        <Grid container spacing={2}>
          <Grid container item spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">基本情報</Typography>
            </Grid>

            <Grid container item spacing={2}>
              <Grid item>
                <TextValidator
                  variant="standard"
                  label="お名前"
                  value={customer.name}
                  onChange={(e) => update({ name: e.target.value })}
                  validatorListener={validatorListener}
                  validators={['required']}
                  errorMessages={['必須項目です']}
                />
              </Grid>
              <Grid item>
                <TextValidator
                  variant="standard"
                  label="よみがな"
                  value={customer.nameKana}
                  onChange={(e) => update({ nameKana: e.target.value })}
                  validatorListener={validatorListener}
                  validators={['required']}
                  errorMessages={['必須項目です']}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              {renderSexRadioGroup()}
            </Grid>

            <Grid container item spacing={1} xs={12} md={6}>
              <Grid item xs={4}>
                <TextField
                  variant="standard"
                  label="生年月日(年)"
                  value={customer.birthdayYear || ''}
                  onChange={(e) =>
                    update({ birthdayYear: Number(e.target.value) })
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  variant="standard"
                  label="(月)"
                  value={customer.birthdayMonth || ''}
                  onChange={(e) => {
                    const month = Number(e.target.value);
                    update({
                      birthdayMonth: month >= 1 && month <= 12 ? month : null,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  variant="standard"
                  label="(日)"
                  value={customer.birthdayDay || ''}
                  onChange={(e) => {
                    const day = Number(e.target.value);
                    update({
                      birthdayDay: day >= 1 && day <= 31 ? day : null,
                    });
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="standard"
                label="職業"
                value={customer.job}
                onChange={(e) => update({ job: e.target.value })}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                style={{ width: '100%' }}
                multiline
                label="アレルギー等の注意事項"
                rows="5"
                value={customer.allergy}
                onChange={(e) => update({ allergy: e.target.value })}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                style={{ width: '100%' }}
                multiline
                label="メモ"
                rows="5"
                value={customer.memo}
                onChange={(e) => update({ memo: e.target.value })}
                variant="outlined"
              />
            </Grid>

            {customer.images?.length > 0 && (
              <Grid item xs={12}>
                <InputLabel shrink>写真</InputLabel>
                {renderCustomerImages(customer.images)}
              </Grid>
            )}

            <Grid item xs={12}>
              <Typography variant="h6" display="inline">
                個人情報
              </Typography>
              <CInformation
                type="tooltip"
                content="個人情報は権限を持ったスタッフのみ表示されます"
              />
            </Grid>

            {showPersonalData && (
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="standard"
                    fullWidth
                    label="住所"
                    value={customer.address || ''}
                    onChange={(e) => update({ address: e.target.value })}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextValidator
                    variant="standard"
                    label="郵便番号"
                    placeholder="0000000"
                    value={customer.postalCode || ''}
                    onChange={(e) => update({ postalCode: e.target.value })}
                    validatorListener={validatorListener}
                    validators={['matchRegexp:^[0-9]{3}-?[0-9]{4}$']}
                    errorMessages={[
                      'XXXXXXXかXXX-XXXXの形式の半角数字で入力してください',
                    ]}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    variant="standard"
                    label="電話番号"
                    placeholder="0000000000"
                    value={customer.phone || ''}
                    onChange={(e) => update({ phone: e.target.value })}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextValidator
                    variant="standard"
                    style={{ width: '100%' }}
                    placeholder="karutekun@example.com"
                    label="メールアドレス"
                    type="email"
                    value={customer.email || ''}
                    onChange={(e) => update({ email: e.target.value })}
                    validatorListener={validatorListener}
                    validators={['isEmail']}
                    errorMessages={['メールアドレスが不正です']}
                  />
                </Grid>

                {customer.uploadedPaper && (
                  <Grid item xs={12}>
                    <InputLabel shrink>紙カルテ</InputLabel>
                    {renderUploadedPapers(customer.uploadedPaper)}
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>

          <Grid item container xs={12} md={12} justifyContent="flex-end">
            {renderSaveButton()}
          </Grid>
        </Grid>
      </ValidatorForm>
    </div>
  );
};
