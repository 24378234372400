import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { ChatMessage } from '../models/chatMessage/entity';
import { ChatMessage as ChatMessageSchema } from '../models/chatMessage/schema';
import { ChatRoom as ChatRoomEntity } from '../models/chatRoom/entity';
import { ChatRoom as ChatRoomSchema } from '../models/chatRoom/schema';
import { MySalonStylist } from '../models/stylist';
import { GlobalState } from '../store';
import { notEmpty } from '../util/common';
import { selectStylistMap } from './salonSelector';

export const selectChatSelectedSalonId = (state: GlobalState) =>
  state.view.chat.selectedSalonId;

export const selectOrderedChatMessagesForCustomer = createCachedSelector(
  selectChatSelectedSalonId,
  (state: GlobalState) => state.chat.chatMessageMap,
  (state: GlobalState) => state.chat.localChatMessageMap,
  (state: GlobalState) => selectStylistMap(state),
  (state: GlobalState, customerId: number) => customerId,
  (
    salonId: number,
    chatMessageMap: IdMap<IdMap<KeyMap<ChatMessageSchema>>>,
    localChatMessageMap: IdMap<IdMap<KeyMap<ChatMessageSchema>>>,
    stylistMap: IdMap<MySalonStylist>,
    customerId: number
  ): ChatMessage[] => {
    const salonChatMap = chatMessageMap[salonId] || {};
    const salonLocalChatMap = localChatMessageMap[salonId] || {};

    // LocalのデータとFirestoreに保存されているデータがかぶる場合は、
    // 前者が優先されるような順序にしている
    // メッセージを再送する際に、即座に状態が反映されてほしいため
    const chatMessages = Object.values({
      ...salonChatMap[customerId],
      ...salonLocalChatMap[customerId],
    });

    return chatMessages
      .filter(notEmpty)
      .map(
        (m) =>
          new ChatMessage({
            ...m,
            stylist: stylistMap[m.fromId] ?? null,
          })
      )
      .sort((a, b) => b.createdAtMs - a.createdAtMs);
  }
)((state: GlobalState, customerId: number) => {
  return customerId;
});

export const selectCustomerChatRoomMap = createSelector(
  selectChatSelectedSalonId,
  (state: GlobalState) => state.chat.chatRoomMap,
  (
    salonId: number,
    chatRoomMap: IdMap<IdMap<ChatRoomSchema>>
  ): IdMap<ChatRoomEntity> => {
    const salonChatRoomMap = chatRoomMap[salonId];
    if (!salonChatRoomMap) {
      return {};
    }

    const salonChatRooms = Object.values(salonChatRoomMap);
    return salonChatRooms.reduce((map, chatRoom) => {
      // TODO 一時的にルールを無効化しています。気づいたベースで直してください
      // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'number' can't be used to index type '{}'.
      map[chatRoom.customerId] = new ChatRoomEntity(chatRoom);
      return map;
    }, {});
  }
);

export const selectCustomerChatRoom = createSelector(
  (state: GlobalState) => selectCustomerChatRoomMap(state),
  (_: GlobalState, customerId: number) => customerId,
  (
    salonChatRoomMap: IdMap<ChatRoomEntity>,
    customerId: number
  ): ChatRoomEntity | null => {
    return salonChatRoomMap[customerId] ?? null;
  }
);
