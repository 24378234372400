import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Link, LinkProps } from 'react-router-dom';

type OwnProps = LinkProps;

const useStyles = makeStyles((_: Theme) => ({
  link: {
    textDecoration: 'none',
  },
}));

const CLink: FC<OwnProps> = (props) => {
  const classes = useStyles();

  const { className, children, ...linkProps } = props;

  return (
    <Link {...linkProps} className={clsx(className, classes.link)}>
      <Typography variant="body1" color="primary">
        {children}
      </Typography>
    </Link>
  );
};

export default CLink;
