import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';
import { theme } from './theme';

export const UIProvider: FC = ({ children }) => {
  // 現状は material-ui に、依存しているが、利用するUIライブラリは着脱できるようにしたい
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
