import {
  pushSnackbarError,
  pushSnackbarSuccess,
} from '../../actions/generalAction';
import { ApplicationError } from '../../actions/requestErrors';
import { ThunkAction, ThunkDispatch } from '../../store';

export async function dispatchWithErrorHandling<T>(
  dispatch: ThunkDispatch,
  action: ThunkAction<T>,
  options: { success?: string; error?: string; onSuccess?(result: T): void },
  throwRawError: true
): Promise<T>;
export async function dispatchWithErrorHandling<T>(
  dispatch: ThunkDispatch,
  action: ThunkAction<T>,
  options?: { success?: string; error?: string; onSuccess?(result: T): void },
  throwRawError?: false
): Promise<T | null>;
export async function dispatchWithErrorHandling<T>(
  dispatch: ThunkDispatch,
  action: ThunkAction<T>,
  options: {
    success?: string;
    error?: string;
    onSuccess?(result: T): void;
  } = {},
  throwRawError = false
): Promise<T | null> {
  const { success, error = 'エラーが発生しました', onSuccess } = options;

  try {
    const result = await dispatch(action);
    if (success) {
      dispatch(pushSnackbarSuccess(success));
    }
    if (onSuccess) {
      onSuccess(result);
    }
    return result;
  } catch (e) {
    dispatch(
      pushSnackbarError(e instanceof ApplicationError ? e.message : error)
    );

    if (throwRawError) {
      throw e;
    }
  }

  return null;
}
