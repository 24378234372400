import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M17.532 13.5a4.5 4.5 0 0 1-8.064 0H5.75a1.75 1.75 0 1 1 0-3.5h3.506a4.502 4.502 0 0 1 8.488 0H42.25a1.75 1.75 0 1 1 0 3.5zM13.5 13a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3m12.032 24.5a4.5 4.5 0 0 1-8.064 0H5.75a1.75 1.75 0 1 1 0-3.5h11.506a4.502 4.502 0 0 1 8.488 0H42.25a1.75 1.75 0 1 1 0 3.5zM23 35.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m15.532-10a4.5 4.5 0 0 1-8.064 0H5.75a1.75 1.75 0 1 1 0-3.5h24.506a4.502 4.502 0 0 1 8.488 0h3.506a1.75 1.75 0 1 1 0 3.5zM36 23.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgFilter = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgFilter);
export default Memo;
