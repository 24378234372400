import { BoxProps } from '@mui/material';
import CTextBox from '../../../../../../components_old/atoms/CTextBox';
import {
  Subscription,
  SubscriptionOptionStatus,
  SubscriptionOptionType,
  SubscriptionStatus,
  getSubscriptionOption,
} from '../../../../../../models/subscription';
import { formatDate } from '../../../../../../util/common';

type Props = BoxProps & {
  subscription: Subscription | null;
};

export const TemporarilyCanceledDescriptionBox: FC<Props> = (props) => {
  const { subscription, ...boxProps } = props;

  if (!subscription) {
    return null;
  }

  if (subscription.status === SubscriptionStatus.TemporarilyCanceled) {
    return (
      <CTextBox type="error" {...boxProps}>
        基本プランとすべてのオプションは{' '}
        {formatDate(subscription.nextPaymentAt)}{' '}
        に解約され、機能が制限されます。
      </CTextBox>
    );
  } else if (
    getSubscriptionOption(subscription, SubscriptionOptionType.Line)?.status ===
    SubscriptionOptionStatus.TemporarilyCanceled
  ) {
    return (
      <CTextBox type="error" {...boxProps}>
        LINEオプションは {formatDate(subscription.nextPaymentAt)}{' '}
        に解約され、機能が制限されます。
      </CTextBox>
    );
  }

  return null;
};
