import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M36.022 7.201a3.25 3.25 0 0 1 4.595 0v.001a3.25 3.25 0 0 1 0 4.595l-2.12 2.121a1.75 1.75 0 0 1-2.476 0l-2.12-2.121a1.75 1.75 0 0 1 0-2.475zm-5.204 5.928c.464 0 .91.184 1.238.513l2.121 2.12a1.75 1.75 0 0 1-.002 2.477L13.454 38.902a1.749 1.749 0 0 1-.44.319l-4.217 2.156a1.75 1.75 0 0 1-2.355-2.355l2.156-4.218c.083-.163.19-.31.32-.44l20.662-20.72a1.75 1.75 0 0 1 1.238-.515"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgPen = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgPen);
export default Memo;
