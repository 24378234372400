import { VisitStatus } from '@karutekun/core/visit';
import { useFetchVisitsByCustomerIdPaginated } from '@karutekun/shared/data-access/visit';
import { Box, Grid, Typography } from '@mui/material';
import CProgressOverlay from '../../../../../components_old/atoms/CProgressOverlay';
import { CVisitCardSmall } from '../../../../../components_old/organisms/visits/CVisitCardSmall';

type Props = {
  customerId: number;
};

export const SchedulesDrawerRecentVisitHistories: FC<Props> = (props) => {
  const { customerId } = props;

  const {
    isLoading,
    data: { visits },
  } = useFetchVisitsByCustomerIdPaginated(
    customerId,
    VisitStatus.Visited,
    5,
    0
  );

  return (
    <>
      <Box mb={1}>
        <Typography variant="body1">直近の来店記録</Typography>
      </Box>
      <Grid container spacing={1}>
        {isLoading && <CProgressOverlay />}

        {visits.length > 0 ? (
          visits.map((visit) => (
            <Grid item xs={12} key={visit.id}>
              <CVisitCardSmall
                visit={visit}
                linkTo={`/visit_histories/${visit.id}`}
                openInNewWindow
              />
            </Grid>
          ))
        ) : !isLoading ? (
          <Grid item>
            <Typography variant="body2">直近の来店記録がありません</Typography>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};
