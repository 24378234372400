export type PresetPaymentMethods = [];

/**
 * PaymentMethodIconはサロン作成時に作られる現金以外での利用を禁止. UI上お預かり、お釣り等の併記を行う。
 */
export enum PaymentMethodIcon {
  Cash = 'cash',
  Cash2 = 'cash2',
  Cash3 = 'cash3',
  Cash4 = 'cash4',
  Cash5 = 'cash5',
  Card = 'card',
  Card2 = 'card2',
  Card3 = 'card3',
  Card4 = 'card4',
  Card5 = 'card5',
  Point = 'point',
  Point2 = 'point2',
  Point3 = 'point3',
  Point4 = 'point4',
  Point5 = 'point5',
  Other = 'other',
  Other2 = 'other2',
  Other3 = 'other3',
  Other4 = 'other4',
  Other5 = 'other5',
}
export const PaymentMethodIcons: PaymentMethodIcon[] = [
  PaymentMethodIcon.Cash,
  PaymentMethodIcon.Cash2,
  PaymentMethodIcon.Cash3,
  PaymentMethodIcon.Cash4,
  PaymentMethodIcon.Cash5,
  PaymentMethodIcon.Card,
  PaymentMethodIcon.Card2,
  PaymentMethodIcon.Card3,
  PaymentMethodIcon.Card4,
  PaymentMethodIcon.Card5,
  PaymentMethodIcon.Point,
  PaymentMethodIcon.Point2,
  PaymentMethodIcon.Point3,
  PaymentMethodIcon.Point4,
  PaymentMethodIcon.Point5,
  PaymentMethodIcon.Other,
  PaymentMethodIcon.Other2,
  PaymentMethodIcon.Other3,
  PaymentMethodIcon.Other4,
  PaymentMethodIcon.Other5,
];

export type PaymentMethod = {
  id: number;
  icon: PaymentMethodIcon;
  name: string;
  order: number;
  isVisible: boolean;
  isActive: boolean;
};
