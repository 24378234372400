import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { selectUserIsJoinedSalon } from '../../selectors/userSelector';

const SalonSetupRequired: FC = ({ children }) => {
  const location = useLocation();
  const isJoinedSalon = useSelector(selectUserIsJoinedSalon);

  if (!isJoinedSalon) {
    return <Navigate to={`/signup/setup?${location.search}`} replace />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default SalonSetupRequired;
