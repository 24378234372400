import { VisitStatus } from '@karutekun/core/visit';
import { theme } from '@karutekun/shared-fe/react-ui-old';
import { Chip, ChipProps } from '@mui/material';

type Props = ChipProps & {
  status: VisitStatus;
  isPaid: boolean;
};

export const VisitStatusTag: FC<Props> = (props) => {
  const { status, isPaid, ...chipProps } = props;

  const [backgroundColor, label] = isPaid
    ? [theme.palette.app.visit.paid, '会計済み']
    : status === VisitStatus.Reserved
      ? [theme.palette.app.visit.reserved, '予約']
      : [theme.palette.app.visit.visited, '来店済み'];

  return (
    <Chip
      label={label}
      sx={{ color: 'white', backgroundColor }}
      {...chipProps}
    />
  );
};
