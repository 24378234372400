import { moment } from '@karutekun/shared/util/datetime';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import {
  fetchChatMessages,
  listenChatRoomForCustomer,
  listenLatestChatMessagesForCustomer,
  markReadForChatRoom,
  sendMessage,
} from '../actions/chatAction';
import { pushSnackbarError } from '../actions/generalAction';
import { ApplicationError, ErrorCode } from '../actions/requestErrors';
import { setChatSalonId } from '../actions/view/viewChatAction';
import { ChatMessage as ChatMessageEntity } from '../models/chatMessage/entity';
import {
  ChatMessageStatus,
  ChatMessageType,
} from '../models/chatMessage/schema';
import { ChatMessageBody } from '../models/chatMessageBody/schema';
import { selectOrderedChatMessagesForCustomer } from '../selectors/chatSelector';
import { selectMe } from '../selectors/salonSelector';
import { GlobalState } from '../store';
import { useThunkDispatch } from '../util/hooks/useThunkDispatch';

export function useFetchMessages(salonId: number, customerId: number) {
  const dispatch = useThunkDispatch();

  const chatMessages: ChatMessageEntity[] = useSelector((state: GlobalState) =>
    selectOrderedChatMessagesForCustomer(state, customerId)
  );

  useEffect(() => {
    dispatch(setChatSalonId(salonId));
    dispatch(fetchChatMessages(salonId, customerId));
  }, [dispatch, salonId, customerId]);

  return chatMessages;
}

export function useFetchNextMessages(
  salonId: number,
  customerId: number,
  cursor?: string
) {
  const dispatch = useThunkDispatch();

  return useCallback(async () => {
    dispatch(fetchChatMessages(salonId, customerId, cursor));
  }, [dispatch, salonId, customerId, cursor]);
}

export function useSendMessage(salonId: number, customerId: number) {
  const dispatch = useThunkDispatch();

  const me = useSelector((state: GlobalState) => selectMe(state));
  const fromId = me.id;

  return useCallback(
    async (
      type: ChatMessageType,
      body: ChatMessageBody,
      resendDocumentId?: string
    ) => {
      const chatMessage = new ChatMessageEntity({
        salonId,
        customerId,
        fromId,
        type,
        body,
        documentId: resendDocumentId || uuid(),
        status: ChatMessageStatus.Sending,
        createdAtMs: moment().valueOf(),
      });

      try {
        await dispatch(sendMessage(chatMessage));
      } catch (e) {
        let errorMessage = 'エラーが発生しました';
        if (e instanceof ApplicationError) {
          switch (e.code) {
            case ErrorCode.ChatLineBlocked:
              errorMessage =
                'お客様からブロックされているため、メッセージが送信できませんでした。';
              break;
            case ErrorCode.ChatLineReachedMonthlyLimit:
              errorMessage = `LINE上の送信数上限に到達したためメッセージが送信できませんでした。\nお使いのLINE公式アカウントのプラン状況をご確認ください。`;
              break;
          }
        }
        dispatch(pushSnackbarError(errorMessage));
      }
    },
    [dispatch, salonId, customerId, fromId]
  );
}

export function useListenLatestChatMessagesForCustomer(
  salonId: number,
  customerId: number
): void {
  const dispatch = useThunkDispatch();

  useEffect(() => {
    const unsubscribe = dispatch(
      listenLatestChatMessagesForCustomer(salonId, customerId)
    );

    return function cleanup() {
      unsubscribe();
    };
  }, [dispatch, salonId, customerId]);
}

export function useListenChatRoomForCustomer(
  salonId: number,
  customerId: number
): void {
  const dispatch = useThunkDispatch();

  useEffect(() => {
    const unsubscribe = dispatch(
      listenChatRoomForCustomer(salonId, customerId)
    );

    return function cleanup() {
      unsubscribe();
    };
  }, [dispatch, salonId, customerId]);
}

export function useMarkReadForChatRoom(
  salonId: number,
  customerId: number
): () => void {
  const dispatch = useThunkDispatch();

  return useCallback(() => {
    dispatch(markReadForChatRoom(salonId, customerId));
  }, [dispatch, salonId, customerId]);
}
