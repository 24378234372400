import { SalonMinimum } from '../models/salon';
import { LoginInfo } from '../models/userStatus';
import { GlobalState } from '../store';

export const selectUserLoginInfo = (
  state: GlobalState
): LoginInfo | null | undefined => state.user.user.loginInfo;

export const selectUserIsJoinedSalon = (
  state: GlobalState
): boolean | undefined => state.user.user.isJoinedSalon;

export const selectUserRequestingSalon = (
  state: GlobalState
): SalonMinimum | null => state.user.user.requestingSalon;

export const selectUserEmail = (state: GlobalState): string | null =>
  state.user.user.loginInfo?.email ?? null;
