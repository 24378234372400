import { mapById } from '@karutekun/shared/util/objects';
import { Dispatch } from 'redux';
import { sendRequest } from '../../actions/request';
import { setSalonReservationSettings } from '../../actions/salonAction';
import {
  EditablePlainReservationMenu,
  PlainReservationMenu,
  reservationMenuFromResource,
} from '../../models/reservationMenu';
import {
  EditablePlainReservationStylist,
  PlainReservationStylist,
  reservationStylistFromResource,
} from '../../models/reservationStylist';
import {
  ReservationBasicSetting,
  ReservationReceptionSetting,
  ReservationSalonInfoSetting,
  reservationBasicSettingFromResource,
} from '../../models/salonReservationSettings';
import { ThunkAction } from '../../store';

/**
 * 予約基本設定の取得
 */
export function fetchReservationBasicSettings(): ThunkAction<ReservationBasicSetting> {
  return async function (dispatch: Dispatch) {
    const json = await sendRequest(
      dispatch,
      `salons/settings/reservation/basic`
    );

    const basicSetting: ReservationBasicSetting =
      reservationBasicSettingFromResource(json);

    dispatch(setSalonReservationSettings({ basicSetting }));

    return basicSetting;
  };
}

/**
 * 予約基本設定（サロン情報）の保存
 */
export function updateReservationSalonInfoSettings(
  setting: Partial<ReservationSalonInfoSetting>
): ThunkAction<ReservationBasicSetting> {
  return async function (dispatch: Dispatch) {
    const json = await sendRequest(
      dispatch,
      'salons/settings/reservation/basic',
      {
        method: 'POST',
        body: JSON.stringify(setting),
      }
    );

    const updated: ReservationBasicSetting =
      reservationBasicSettingFromResource(json);

    dispatch(setSalonReservationSettings({ basicSetting: updated }));

    return updated;
  };
}

/**
 * 予約基本設定（予約受付設定）の保存
 */
export function updateReservationReceptionSettings(
  setting: Partial<ReservationReceptionSetting>
): ThunkAction<ReservationReceptionSetting> {
  return async function (dispatch: Dispatch) {
    const json = await sendRequest(
      dispatch,
      'salons/settings/reservation/basic',
      {
        method: 'POST',
        body: JSON.stringify(setting),
      }
    );

    const updated: ReservationBasicSetting =
      reservationBasicSettingFromResource(json);

    dispatch(setSalonReservationSettings({ basicSetting: updated }));

    return updated;
  };
}

/**
 * 予約掲載スタイリストの取得
 */
export function fetchReservationStylists(): ThunkAction<
  PlainReservationStylist[]
> {
  return async function (dispatch: Dispatch) {
    const json = await sendRequest(
      dispatch,
      `salons/settings/reservation/stylists`
    );

    const reservationStylists = json.map(reservationStylistFromResource);

    dispatch(
      setSalonReservationSettings({
        reservationStylistMap: mapById(reservationStylists),
      })
    );

    return reservationStylists;
  };
}

/**
 * 予約掲載スタイリストの作成
 */
export function createReservationStylist(
  stylistId: number,
  values: EditablePlainReservationStylist
): ThunkAction<PlainReservationStylist> {
  return async function (dispatch: Dispatch) {
    const json = await sendRequest(
      dispatch,
      `salons/settings/reservation/stylists`,
      {
        method: 'POST',
        body: JSON.stringify({ stylistId, ...values }),
      }
    );

    const created: PlainReservationStylist =
      reservationStylistFromResource(json);

    dispatch(
      setSalonReservationSettings({
        reservationStylistMap: { [created.id]: created },
      })
    );

    return created;
  };
}

/**
 * 予約掲載スタイリストの変更
 */
export function updateReservationStylist(
  stylistId: number,
  updates: Partial<EditablePlainReservationStylist>
): ThunkAction<PlainReservationStylist> {
  return async function (dispatch: Dispatch) {
    const json = await sendRequest(
      dispatch,
      `salons/settings/reservation/stylists/${stylistId}`,
      {
        method: 'POST',
        body: JSON.stringify(updates),
      }
    );

    const updated: PlainReservationStylist =
      reservationStylistFromResource(json);

    dispatch(
      setSalonReservationSettings({
        reservationStylistMap: { [updated.id]: updated },
      })
    );

    return updated;
  };
}

/**
 * 予約掲載スタイリストの削除
 */
export function deleteReservationStylist(
  stylistId: number
): ThunkAction<PlainReservationStylist> {
  return async function (dispatch: Dispatch) {
    const json = await sendRequest(
      dispatch,
      `salons/settings/reservation/stylists/${stylistId}`,
      { method: 'DELETE' }
    );

    const deleted: PlainReservationStylist =
      reservationStylistFromResource(json);

    dispatch(
      setSalonReservationSettings({
        reservationStylistMap: { [deleted.id]: deleted },
      })
    );

    return deleted;
  };
}

/**
 * 予約掲載スタイリストの並び替え変更
 */
export function updateReservationStylistOrder(
  order: number[]
): ThunkAction<PlainReservationStylist[]> {
  return async function (dispatch: Dispatch) {
    const json = await sendRequest(
      dispatch,
      `salons/settings/reservation/stylists/order`,
      {
        method: 'POST',
        body: JSON.stringify({ order }),
      }
    );

    const reservationStylists = json.map(reservationStylistFromResource);

    dispatch(
      setSalonReservationSettings({
        reservationStylistMap: mapById(reservationStylists),
      })
    );

    return reservationStylists;
  };
}

/**
 * 掲載メニューの取得
 */
export function fetchReservationMenus(): ThunkAction<PlainReservationMenu[]> {
  return async function (dispatch: Dispatch) {
    const json = await sendRequest(
      dispatch,
      `salons/settings/reservation/menus`
    );

    const reservationMenus = json.map(reservationMenuFromResource);

    dispatch(
      setSalonReservationSettings({
        reservationMenuMap: mapById(reservationMenus),
      })
    );

    return reservationMenus;
  };
}

/**
 * 掲載メニューの作成
 */
export function createReservationMenu(
  values: EditablePlainReservationMenu
): ThunkAction<PlainReservationMenu> {
  return async function (dispatch: Dispatch) {
    const json = await sendRequest(
      dispatch,
      `salons/settings/reservation/menus`,
      {
        method: 'POST',
        body: JSON.stringify(values),
      }
    );

    const created: PlainReservationMenu = reservationMenuFromResource(json);

    dispatch(
      setSalonReservationSettings({
        reservationMenuMap: { [created.id]: created },
      })
    );

    return created;
  };
}

/**
 * 掲載メニューの変更
 */
export function updateReservationMenu(
  reservationMenuId: number,
  updates: Partial<EditablePlainReservationMenu>
): ThunkAction<PlainReservationMenu> {
  return async function (dispatch: Dispatch) {
    const json = await sendRequest(
      dispatch,
      `salons/settings/reservation/menus/${reservationMenuId}`,
      {
        method: 'POST',
        body: JSON.stringify(updates),
      }
    );

    const updated: PlainReservationMenu = reservationMenuFromResource(json);

    dispatch(
      setSalonReservationSettings({
        reservationMenuMap: { [updated.id]: updated },
      })
    );

    return updated;
  };
}

/**
 * 掲載メニューの削除
 */
export function deleteReservationMenu(
  reservationMenuId: number
): ThunkAction<PlainReservationMenu> {
  return async function (dispatch: Dispatch) {
    const json = await sendRequest(
      dispatch,
      `salons/settings/reservation/menus/${reservationMenuId}`,
      { method: 'DELETE' }
    );

    const deleted: PlainReservationMenu = reservationMenuFromResource(json);

    dispatch(
      setSalonReservationSettings({
        reservationMenuMap: { [deleted.id]: deleted },
      })
    );

    return deleted;
  };
}

/**
 * 掲載メニューの並び替え変更
 */
export function updateReservationMenuOrder(
  order: number[]
): ThunkAction<PlainReservationMenu[]> {
  return async function (dispatch: Dispatch) {
    const json = await sendRequest(
      dispatch,
      `salons/settings/reservation/menus/order`,
      {
        method: 'POST',
        body: JSON.stringify({ order }),
      }
    );

    const reservationMenus = json.map(reservationMenuFromResource);

    dispatch(
      setSalonReservationSettings({
        reservationMenuMap: mapById(reservationMenus),
      })
    );

    return reservationMenus;
  };
}
