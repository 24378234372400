import chroma from 'chroma-js';
import * as React from 'react';
import {
  Cell,
  Legend,
  LegendType,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { legendProps, tooltipProps } from '../../util/chart';
import CChartTooltip from './CChartTooltip';
import CEmpty from './CEmpty';

type ChartData = {
  id: number;
  name: string;
  value: number;
  color?: string;
};
export type GroupedChartData = ChartData & {
  groupId: number;
};
type OwnProps = {
  groupData: { [groupId: number]: { name: string; color: string } };
  data: GroupedChartData[];

  formatter?(value: number): React.ReactNode;

  sortGroupByValue?: boolean;
  sortGroupOrder?: 'asc' | 'desc';
  sortDataByValue?: boolean;
  sortDataOrder?: 'asc' | 'desc';

  // 各グループについて、この数を超えるデータ数ある場合は、以降がその他に集約される
  maxDataNumForEachGroup?: number;
};

export default class CChartPieGrouped extends React.PureComponent<OwnProps> {
  static defaultProps = {
    sortGroupOrder: 'desc',
    sortDataOrder: 'desc',
    maxLegendNumForEachGroup: 10,
  };

  render() {
    const {
      groupData,
      formatter,
      sortGroupByValue,
      sortGroupOrder,
      sortDataByValue,
      sortDataOrder,
      maxDataNumForEachGroup,
    } = this.props;

    if (this.props.data.length === 0) {
      return <CEmpty title="該当データがありません" />;
    }

    const tooltipFormatter = formatter
      ? (value: string | number | (string | number)[]) =>
          formatter(Number(value))
      : undefined;

    // グループ自体のデータ
    const groupDataMap: {
      [groupId: number]: ChartData & { color: string };
    } = {};

    // グルーピングされた子データ
    const groupedDataMap: { [groupId: number]: ChartData[] } = {};

    for (const data of this.props.data) {
      // グループ自体のデータを作成
      if (!groupDataMap[data.groupId]) {
        groupDataMap[data.groupId] = {
          id: data.groupId,
          name: groupData[data.groupId].name,
          value: 0,
          color: groupData[data.groupId].color || '#8884d8',
        };
      }
      groupDataMap[data.groupId].value += data.value;

      // 小データをグルーピング
      if (!groupedDataMap[data.groupId]) {
        groupedDataMap[data.groupId] = [];
      }
      groupedDataMap[data.groupId].push(data);
    }

    // グループデータをソート
    const groupChartData = Object.values(groupDataMap);
    groupChartData.sort((a, b) => {
      const comp = sortGroupByValue ? b.value - a.value : b.id - a.id;
      return sortGroupOrder === 'desc' ? comp : -comp;
    });

    // グループの順番を生成
    const groupDataOrder = groupChartData.map((d) => d.id);

    // 最後に小データのリストを生成する
    let chartData: ChartData[] = [];
    for (const groupId of groupDataOrder) {
      const groupedData = groupedDataMap[groupId];
      groupedData.sort((a, b) => {
        const comp = sortDataByValue ? b.value - a.value : b.id - a.id;
        return sortDataOrder === 'desc' ? comp : -comp;
      });

      // 子データが多い場合は "その他" に集約
      if (
        maxDataNumForEachGroup &&
        groupedData.length > maxDataNumForEachGroup
      ) {
        const tails = groupedData.splice(maxDataNumForEachGroup - 1);
        groupedData.push({
          id: tails[0].id,
          name: 'その他',
          value: tails.reduce((p, c) => p + c.value, 0),
        });
      }

      // 色を生成して設定
      const groupColor = chroma(groupDataMap[groupId].color);
      const brightColor = groupColor.brighten(2.6);
      chroma
        .scale([groupColor, brightColor])
        .mode('hcl')
        .colors(groupedData.length)
        .forEach((color, i) => {
          groupedData[i].color = color;
        });

      chartData = chartData.concat(groupedData);
    }

    return (
      <ResponsiveContainer>
        <PieChart>
          <Legend
            {...legendProps}
            payload={groupChartData.map((data) => ({
              id: data.id.toString(),
              value: data.name,
              type: 'circle' as LegendType,
              color: data.color,
            }))}
          />
          <Pie
            data={groupChartData}
            dataKey="value"
            startAngle={90}
            endAngle={-270}
            legendType="none"
            isAnimationActive={false}
          >
            {groupChartData.map((entry, i) => (
              <Cell
                key={`groupcell-${i}`}
                fill={groupData[entry.id].color || '#8884d8'}
              />
            ))}
          </Pie>
          <Pie
            data={chartData}
            dataKey="value"
            startAngle={90}
            endAngle={-270}
            innerRadius="50%"
            legendType="none"
            isAnimationActive={false}
          >
            {chartData.map((entry, i) => (
              <Cell key={`cell-${i}`} fill={entry.color} />
            ))}
          </Pie>
          <Tooltip
            {...tooltipProps}
            formatter={tooltipFormatter}
            content={<CChartTooltip />}
          />
        </PieChart>
      </ResponsiveContainer>
    );
  }
}
