import { CTextField } from '@karutekun/shared-fe/react-ui-old';
import {
  Alert,
  Box,
  Card,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { dispatchWithErrorHandling } from '../../../actions/helper/dispatchWithErrorHandling';
import {
  updateSalonFindKeyword,
  updateSalonName,
} from '../../../actions/salonAction';
import CButton from '../../../components_old/atoms/CButton';
import CProgressOverlay from '../../../components_old/atoms/CProgressOverlay';
import { Salon } from '../../../models/salon';
import { checkPermission } from '../../../models/stylist';
import {
  useSelectMe,
  useSelectMySalon,
} from '../../../selectors/salonSelector';
import { useThunkDispatch } from '../../../util/hooks/useThunkDispatch';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '76px 20px 20px 20px',
  },
  card: {
    padding: theme.spacing(2),
  },
  subtitle: {
    paddingBottom: theme.spacing(2),
  },
  table: {
    width: '100%',
  },
  cell: {
    padding: theme.spacing(1),
  },
  saveArea: {
    textAlign: 'right',
  },
}));

export const SalonSettings: FC = () => {
  const classes = useStyles();

  const me = useSelectMe();
  const salon = useSelectMySalon();
  const dispatch = useThunkDispatch();

  const handleUpdateSalonName = async (name: string) => {
    await dispatchWithErrorHandling(dispatch, updateSalonName(name), {
      success: 'サロン情報を保存しました',
    });
  };
  const handleUpdateSalonFindKeyword = async (findKeyword: string) => {
    await dispatchWithErrorHandling(
      dispatch,
      updateSalonFindKeyword(findKeyword),
      { success: 'サロン情報を保存しました' }
    );
  };

  if (!me || !salon) {
    return <CProgressOverlay />;
  }

  const hasPermission = checkPermission(me, 'canUpdateSalon');

  return (
    <Container maxWidth="sm" className={classes.root}>
      {!hasPermission && (
        <Alert severity="warning">サロンの編集権限がありません</Alert>
      )}

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            基本設定
          </Typography>
          <NameCard
            salon={salon}
            hasPermission={hasPermission}
            updateSalonName={handleUpdateSalonName}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            秘密の言葉
          </Typography>
          <FindKeywordCard
            salon={salon}
            hasPermission={hasPermission}
            updateSalonFindKeyword={handleUpdateSalonFindKeyword}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

const NameCard: FC<{
  salon: Salon;
  hasPermission: boolean;
  updateSalonName(name: string): Promise<void>;
}> = (props) => {
  const classes = useStyles();
  const { salon, hasPermission, updateSalonName } = props;

  const [isEdited, setIsEdited] = useState(false);
  const [name, setName] = useState(salon.name);

  useEffect(() => {
    setName(salon.name);
    setIsEdited(false);
  }, [salon.name]);

  return (
    <Card className={classes.card}>
      <Table className={classes.table}>
        <TableBody>
          <TableRow>
            <TableCell align="left" className={classes.cell}>
              サロン名
            </TableCell>
            <TableCell align="left" className={classes.cell}>
              <CTextField
                value={name}
                maxLength={255}
                disabled={!hasPermission}
                onChange={(name) => {
                  setName(name);
                  setIsEdited(salon.name !== name);
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {hasPermission && (
        <Box mt={2}>
          <div className={classes.saveArea}>
            <CButton
              disabled={!isEdited || !name}
              onClick={() => updateSalonName(name)}
            >
              保存
            </CButton>
          </div>
        </Box>
      )}
    </Card>
  );
};

const FindKeywordCard: FC<{
  salon: Salon;
  hasPermission: boolean;
  updateSalonFindKeyword(findKeyword: string): Promise<void>;
}> = (props) => {
  const classes = useStyles();
  const { salon, hasPermission, updateSalonFindKeyword } = props;

  const [isEdited, setIsEdited] = useState(false);
  const [findKeyword, setFindKeyword] = useState(salon.findKeyword);

  return (
    <Card className={classes.card}>
      <Typography variant="body1" className={classes.subtitle}>
        他のスタッフをこのサロンに招待したい場合、以下の秘密の言葉を招待したいスタッフにお伝え下さい。
      </Typography>
      <Table className={classes.table}>
        <TableBody>
          <TableRow>
            <TableCell align="left" className={classes.cell}>
              秘密の言葉
            </TableCell>
            <TableCell align="left" className={classes.cell}>
              <CTextField
                value={findKeyword}
                disabled={!hasPermission}
                maxLength={255}
                onChange={(keyword) => {
                  setFindKeyword(keyword);
                  setIsEdited(salon.findKeyword !== keyword);
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Box mt={1}>
        <Typography variant="body2">
          ※ 所属申請は、スタッフページの所属申請タブで表示されます。
          申請が許可されない限り、そのサロンに加入できません。
        </Typography>
      </Box>

      {hasPermission && (
        <Box mt={2}>
          <div className={classes.saveArea}>
            <CButton
              disabled={!isEdited || !findKeyword}
              onClick={() => updateSalonFindKeyword(findKeyword)}
            >
              保存
            </CButton>
          </div>
        </Box>
      )}
    </Card>
  );
};
