import {
  ISalonCustomSetting,
  ISalonCustomSettingWithDefaults,
} from '../data/salon-custom-setting';

const DefaultTreatmentMemo = `【施術・薬剤】

【会話内容】
`;

export function buildSalonCustomSettingWithDefaults(
  setting: Partial<ISalonCustomSetting> & { salonId: number }
): ISalonCustomSettingWithDefaults {
  return {
    salonId: setting.salonId,
    firstNextVisitFrom: setting.firstNextVisitFrom ?? 30,
    firstNextVisitTo: setting.firstNextVisitTo ?? 45,
    repeatNextVisitCycleBefore: setting.repeatNextVisitCycleBefore ?? 7,
    repeatNextVisitCycleAfter: setting.repeatNextVisitCycleAfter ?? 7,
    initialKaruteIsShimei: setting.initialKaruteIsShimei ?? false,
    initialKaruteMemo: setting.initialKaruteMemo ?? DefaultTreatmentMemo,
    questionnaire: setting.questionnaire ?? null,
    cancellationQuestionnaire: setting.cancellationQuestionnaire ?? null,
    customerForm: setting.customerForm ?? null,
  };
}
