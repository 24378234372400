import { useSelector } from 'react-redux';
import CStylistSelect from '../../../../components_old/molecules/CStylistSelect';
import { selectStylists } from '../../../../selectors/salonSelector';

type Props = {
  selectedStylistId: number;
  onChange(stylistId: number): void;
};

export const StylistSelect: FC<Props> = (props) => {
  const { selectedStylistId, onChange } = props;

  const stylists = useSelector(selectStylists);

  return (
    <CStylistSelect
      label="スタッフ"
      stylists={stylists}
      selected={selectedStylistId}
      onChange={(id) => {
        if (id) {
          onChange(id);
        }
      }}
    />
  );
};
