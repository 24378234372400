import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      d="M15.75 13.5c.367 4.957 4.125 9 8.25 9s7.889-4.043 8.25-9c.375-5.156-3.281-9-8.25-9-4.969 0-8.625 3.938-8.25 9"
    />
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M33.995 13.627C34.444 7.462 29.991 2.75 24 2.75c-6.002 0-10.444 4.818-9.995 10.88.416 5.61 4.715 10.62 9.995 10.62 5.278 0 9.586-5.007 9.995-10.623M43.689 41.183C41.951 31.553 32.652 26.75 24 26.75S6.048 31.554 4.31 41.183c-.163.908-.006 1.888.541 2.687A3.162 3.162 0 0 0 7.5 45.25h33a3.16 3.16 0 0 0 2.648-1.38c.547-.799.704-1.779.54-2.687"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgUserFill = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgUserFill);
export default Memo;
