import { VisitResource, VisitUtils } from '@karutekun/core/visit';
import {
  useFetchNewReservationCount,
  useFetchVisitsByDateRange,
  useInvalidateVisits,
  useUpdateReservation,
  useUpdateVisitHistory,
} from '@karutekun/shared/data-access/visit';
import {
  manipulateDate,
  nowUnix,
  unixToDate,
} from '@karutekun/shared/util/datetime';
import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import { Dialog, DialogTitle, Grid, IconButton, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  pushSnackbarError,
  pushSnackbarSuccess,
} from '../../actions/generalAction';
import {
  fetchBusinessInfo,
  updateBusinessInfo,
} from '../../actions/salonBusinessInfoAction';
import { setSettingsSchedule } from '../../actions/settingsAction';
import {
  setViewScheduleCurrentDate,
  setViewScheduleDrawerStatus,
  setViewSchedulePreviewUpdates,
  setViewSchedulePreviewVisitId,
} from '../../actions/view/viewScheduleAction';
import { headerHeight } from '../../components/Header';
import CTrialWillEndBanner from '../../components_old/molecules/CTrialWillEndBanner';
import CSchedulesHeader from '../../components_old/organisms/schedules/CSchedulesHeader';
import { DailyBusinessInfoUpdate } from '../../models/salonBusinessInfo';
import {
  ScheduleDisplayFilter,
  ScheduleDisplayMode,
} from '../../models/view/viewSchedule';
import { selectBusinessInfoMap } from '../../selectors/businessInfoSelector';
import {
  selectMe,
  selectMySalon,
  selectStylists,
} from '../../selectors/salonSelector';
import { selectMinuteInterval } from '../../selectors/scheduleSettingsSelector';
import { selectScheduleSettings } from '../../selectors/settingsSelector';
import {
  selectScheduleDrawerPreview,
  selectScheduleDrawerState,
} from '../../selectors/view/viewScheduleSelector';
import { GlobalState } from '../../store';
import { useSalonStatus } from '../../templates/providers/salonStatus/salonStatusContext';
import { useSimpleDialog } from '../../templates/providers/simpleDialog/simpleDialogContext';
import { useThunkDispatch } from '../../util/hooks/useThunkDispatch';
import { useWidthDown } from '../../util/hooks/useWidth';
import BusinessInfo from '../settings/schedule/_components/BusinessInfo';
import { SchedulesDailyView } from './_components/SchedulesDailyView';
import {
  SchedulesDrawer,
  scheduleDrawerWidth,
} from './_components/SchedulesDrawer';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: `${headerHeight + 5}px 0px 0px 0px`,
    height: 'calc(var(--vh, 1vh) * 100)',
    fallbacks: {
      height: '100vh',
    },
    width: '100%',
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    paddingRight: 0,
  },
  containerShift: {
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    paddingRight: scheduleDrawerWidth,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  header: {
    flex: 0,
  },
  viewer: {
    flex: 1,
    minHeight: 0,
  },
}));

export const Schedules: FC = () => {
  const classes = useStyles();
  const dispatch = useThunkDispatch();

  const { open: openConfirmDialog } = useSimpleDialog();
  const [currentTime, setCurrentTime] = useState(nowUnix());
  const [isBusinessInfoOpen, setIsBusinessInfoOpen] = useState(false);

  const { salonStatus } = useSalonStatus();
  const salon = useSelector(selectMySalon);
  const me = useSelector(selectMe);
  const stylists = useSelector(selectStylists);
  const minuteInterval = useSelector(selectMinuteInterval);
  const { displayMode, filter, scale } = useSelector(selectScheduleSettings);
  const currentDate = useSelector(
    (state: GlobalState) => state.view.schedule.currentDate
  );
  const businessInfoMap = useSelector(selectBusinessInfoMap);

  const showBusinessInfo = salonStatus.hasSetting('businessInfo');
  const showReservationStuff = salonStatus.hasSetting('reservation');

  const { data: newReservationCount = 0, refetch: refetchNewReservationCount } =
    useFetchNewReservationCount(showReservationStuff);

  const drawerState = useSelector(selectScheduleDrawerState);
  const drawerPreview = useSelector(selectScheduleDrawerPreview);

  const shownStylists = useMemo(
    () => stylists.filter((s) => !filter.hiddenStylistIds.includes(s.id)),
    [filter.hiddenStylistIds, stylists]
  );

  const range = useMemo(
    () => ({
      from: manipulateDate(currentDate, -1, 'day'),
      to: manipulateDate(currentDate, 1, 'day'),
    }),
    [currentDate]
  );

  const invalidateVisits = useInvalidateVisits();
  const {
    data: fetchedVisits = [],
    isFetching,
    refetch: refetchVisits,
  } = useFetchVisitsByDateRange(range);

  const visits = useMemo(() => {
    return fetchedVisits.filter((v) => unixToDate(v.startedAt) === currentDate);
  }, [currentDate, fetchedVisits]);

  // 予約情報をポーリングする
  useEffect(() => {
    const timer = setInterval(refetchVisits, 30000);
    return () => clearInterval(timer);
  }, [refetchVisits]);

  // 営業情報と新着予約をポーリングする
  useEffect(() => {
    const fetch = () => {
      if (showBusinessInfo) {
        dispatch(fetchBusinessInfo(range));
      }
      refetchNewReservationCount();
    };

    fetch();

    const timer = setInterval(fetch, 30000);

    return () => clearInterval(timer);
  }, [dispatch, range, refetchNewReservationCount, showBusinessInfo]);

  // 1分ごとに現在時刻を更新する
  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(nowUnix()), 60000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    function handleResize() {
      // 高さを ViewPort いっぱいに広げるためにサイズを計算してCSSプロパティに設定
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    return function cleanup() {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const callWithEditConfirmation = useCallback(
    (func: () => void) => {
      if (drawerPreview.isEdited) {
        openConfirmDialog({
          content:
            '予約の編集内容が保存されていません。編集内容を破棄しますか？',
          onOk: async () => {
            dispatch(setViewSchedulePreviewUpdates({}, true));
            func();
          },
        });
      } else {
        dispatch(setViewSchedulePreviewUpdates({}, true));
        func();
      }
    },
    [dispatch, drawerPreview.isEdited, openConfirmDialog]
  );

  const handleScheduleClick = useCallback(
    (visitId: number) => {
      callWithEditConfirmation(() => {
        if (visitId !== drawerPreview.selectedVisitId) {
          dispatch(setViewSchedulePreviewVisitId(visitId));
          dispatch(setViewScheduleDrawerStatus({ schedulePreview: true }));
        } else {
          dispatch(setViewSchedulePreviewVisitId(null));
          dispatch(setViewScheduleDrawerStatus({ schedulePreview: false }));
        }
      });
    },
    [callWithEditConfirmation, dispatch, drawerPreview.selectedVisitId]
  );

  const closePreview = useCallback(() => {
    callWithEditConfirmation(() => {
      dispatch(setViewSchedulePreviewVisitId(null));
      dispatch(setViewScheduleDrawerStatus({ schedulePreview: false }));
    });
  }, [callWithEditConfirmation, dispatch]);

  const handleStartCreate = useCallback(
    (initial: Partial<VisitResource>) => {
      dispatch(setViewSchedulePreviewUpdates(initial, false, false));
      dispatch(setViewScheduleDrawerStatus({ schedulePreview: true }));
      dispatch(setViewSchedulePreviewVisitId(null));
    },
    [dispatch]
  );

  const handleShowBusinessInfo = useCallback(
    () => setIsBusinessInfoOpen(true),
    []
  );

  const handleShowRecentReservationList = useCallback(() => {
    callWithEditConfirmation(() => {
      dispatch(
        setViewScheduleDrawerStatus({
          schedulePreview: false,
          reservationList: true,
        })
      );
    });
  }, [callWithEditConfirmation, dispatch]);

  const { mutate: updateVisitHistory } = useUpdateVisitHistory({
    onSuccess() {
      invalidateVisits();
      dispatch(pushSnackbarSuccess('来店情報を保存しました'));
    },
    showConfirmation(options, onOk) {
      openConfirmDialog({
        title: options.title,
        content: options.message,
        onOk: async () => onOk(),
      });
    },
    onUnhandledError(e) {
      dispatch(
        pushSnackbarError(e.data?.messageForUser ?? '保存に失敗しました')
      );
    },
  });

  const { mutate: updateReservation } = useUpdateReservation({
    onSuccess(res) {
      invalidateVisits();
      const lineNotified = res.lineNotified;
      const message = lineNotified
        ? '予約を変更し、お客様にLINEでお知らせしました'
        : `予約を変更しました`;
      dispatch(pushSnackbarSuccess(message));
    },
    showConfirmation(options, onOk) {
      openConfirmDialog({
        title: options.title,
        content: options.message,
        onOk: async () => onOk(),
      });
    },
    onUnhandledError(e) {
      dispatch(
        pushSnackbarError(e.data?.messageForUser ?? '保存に失敗しました')
      );
    },
  });

  const handleScheduleMove = useCallback(
    (
      visit: VisitResource,
      update: {
        assignedStylistId: number | null;
        startedAt: number;
        finishedAt: number;
      }
    ) => {
      const exec = async (
        visit: VisitResource,
        update: {
          assignedStylistId: number | null;
          startedAt: number;
          finishedAt: number;
        }
      ) => {
        const req = {
          id: visit.id,
          visit: {
            startedAt: update.startedAt,
            finishedAt: update.finishedAt,
            assignedStylistId: update.assignedStylistId ?? undefined,
          },
          userUpdatedAt: visit.userUpdatedAt,
        };
        if (VisitUtils.isReservation(visit)) {
          updateReservation(req);
        } else if (VisitUtils.isVisitHistory(visit)) {
          updateVisitHistory(req);
        }
        dispatch(setViewSchedulePreviewUpdates({}, true));
      };

      const confirmMessage = VisitUtils.isReservation(visit)
        ? '本当に予約を移動しますか？'
        : VisitUtils.isVisitHistory(visit)
          ? '本当に来店記録を移動しますか？'
          : '';
      openConfirmDialog({
        content: confirmMessage,
        onOk: async () => {
          await exec(visit, update);
        },
      });
    },
    [openConfirmDialog, dispatch, updateReservation, updateVisitHistory]
  );

  const handleChangeDate = useCallback(
    (date: string) => {
      dispatch(setViewScheduleCurrentDate(date));
    },
    [dispatch]
  );
  const handleZoomIn = useCallback(() => {
    if (scale < 2.0) {
      dispatch(setSettingsSchedule(salon.id, { scale: scale + 0.2 }));
    }
  }, [dispatch, salon.id, scale]);
  const handleZoomOut = useCallback(() => {
    if (scale > 0.7) {
      dispatch(setSettingsSchedule(salon.id, { scale: scale - 0.2 }));
    }
  }, [scale, dispatch, salon.id]);
  const handleDisplayMode = useCallback(
    (displayMode: ScheduleDisplayMode) =>
      dispatch(setSettingsSchedule(salon.id, { displayMode })),
    [dispatch, salon.id]
  );
  const handleFilter = useCallback(
    (filter: ScheduleDisplayFilter) =>
      dispatch(setSettingsSchedule(salon.id, { filter })),
    [dispatch, salon.id]
  );

  const handleUpdateBusinessInfo = useCallback(
    async (update: DateMap<DailyBusinessInfoUpdate>) => {
      await dispatch(updateBusinessInfo(update));
    },
    [dispatch]
  );

  const creatingReservation = useMemo(() => {
    if (drawerPreview.selectedVisitId || !drawerState.isSchedulePreviewOpen) {
      return null;
    }
    return {
      assignedStylistId: drawerPreview.visitUpdates.assignedStylistId ?? null,
      startedAt: drawerPreview.visitUpdates.startedAt ?? 0,
      finishedAt: drawerPreview.visitUpdates.finishedAt ?? 0,
    };
  }, [
    drawerPreview.selectedVisitId,
    drawerPreview.visitUpdates.assignedStylistId,
    drawerPreview.visitUpdates.finishedAt,
    drawerPreview.visitUpdates.startedAt,
    drawerState.isSchedulePreviewOpen,
  ]);

  // Drawer に関する処理
  const isDrawerOpen =
    drawerState.isReservationListOpen || drawerState.isSchedulePreviewOpen;
  const isDrawerDialogMode = useWidthDown('sm');

  return (
    <div
      className={clsx(classes.container, {
        [classes.containerShift]: isDrawerOpen && !isDrawerDialogMode,
      })}
    >
      <CTrialWillEndBanner />
      <Dialog
        fullScreen={useWidthDown('sm')}
        maxWidth="lg"
        open={isBusinessInfoOpen}
        onClose={() => setIsBusinessInfoOpen(false)}
      >
        <DialogTitle>
          <Grid container alignItems="center">
            <Grid item xs>
              毎月の営業情報
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => setIsBusinessInfoOpen(false)}
                size="large"
              >
                <SVGIcon name="times" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <BusinessInfo />
      </Dialog>

      <div className={classes.contentContainer}>
        <div className={classes.header}>
          <CSchedulesHeader
            date={currentDate}
            displayMode={displayMode}
            stylists={stylists}
            filter={filter}
            isFetching={isFetching}
            showReservation={showReservationStuff}
            showBusinessInfo={showBusinessInfo}
            newReservationCount={newReservationCount}
            onChangeDisplayMode={handleDisplayMode}
            onChangeDate={handleChangeDate}
            onChangeFilter={handleFilter}
            onPressBusinessInfo={handleShowBusinessInfo}
            onPressRecentReservationList={handleShowRecentReservationList}
            onPressZoomIn={handleZoomIn}
            onPressZoomOut={handleZoomOut}
            onPressReload={refetchVisits}
          />
        </div>

        <div className={classes.viewer}>
          <SchedulesDailyView
            date={currentDate}
            me={me}
            currentTime={currentTime}
            shownStylists={shownStylists}
            showNonAssignedReserve={filter.showNonAssignedReserve}
            visits={visits}
            businessInfoMap={businessInfoMap}
            selectedVisitId={drawerPreview.selectedVisitId}
            scale={scale}
            minuteInterval={minuteInterval}
            creatingReservation={creatingReservation}
            onStartCreate={handleStartCreate}
            onScheduleClick={handleScheduleClick}
            onScheduleDragStart={closePreview}
            onScheduleMove={handleScheduleMove}
            onUpdateCapacity={handleUpdateBusinessInfo}
          />
        </div>
      </div>

      <SchedulesDrawer onClosePreview={closePreview} />
    </div>
  );
};
