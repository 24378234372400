import { trpc } from '@karutekun/shared/data-access/api-base';
import {
  endOfMonthDate,
  startOfMonthDate,
  thisMonth,
} from '@karutekun/shared/util/datetime';
import { Card } from '@mui/material';
import { keepPreviousData } from '@tanstack/react-query';
import { memo, useState } from 'react';
import CChartBar from '../../../../../components_old/atoms/CChartBar';
import CMonthRangeControl from '../../../../../components_old/molecules/CMonthRangeControl';
import CChartWrapper from '../../../../../components_old/organisms/charts/CChartWrapper';
import { InformationText } from '../../../../../texts/infomation';
import { fmtCustomerNum, fmtMonth } from '../../../../../util/chart';

type Props = {
  onChangeDate(from: string, to: string): void;
};

const valueKeys = [
  { name: `初回来店数`, dataKey: 'customerNum', color: '#ffc107' },
];

export const FirstCustomerTransitionCard: FC<Props> = memo(
  ({ onChangeDate }) => {
    const [{ from, to }, setRange] = useState({
      from: thisMonth(-12),
      to: thisMonth(),
    });

    const { isRefetching, data: monthlyStats = [] } =
      trpc.analytics.firstCustomerStats.monthlyStats.useQuery(
        { from, to },
        { placeholderData: keepPreviousData }
      );

    return (
      <Card>
        <CChartWrapper
          title="初回来店数 推移"
          isFetching={isRefetching}
          informationText={InformationText.firstCustomer.monthlyCustomerNum}
          rightComponent={
            <div style={{ flex: 1 }}>
              <CMonthRangeControl
                from={from}
                to={to}
                onChange={(from, to) => setRange({ from, to })}
              />
            </div>
          }
        >
          <CChartBar
            data={monthlyStats}
            nameKey={(s) => fmtMonth(s.month)}
            formatter={fmtCustomerNum}
            valueKeys={valueKeys}
            onClick={({ month }) =>
              onChangeDate(startOfMonthDate(month), endOfMonthDate(month))
            }
          />
        </CChartWrapper>
      </Card>
    );
  }
);
