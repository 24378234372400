import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      d="M38.25 7.25H9.75a7 7 0 0 0-7 7v19.5a7 7 0 0 0 7 7h28.5a7 7 0 0 0 7-7v-19.5a7 7 0 0 0-7-7m3.5 26.5a3.5 3.5 0 0 1-3.5 3.5H9.75a3.5 3.5 0 0 1-3.5-3.5v-19.5a3.5 3.5 0 0 1 3.5-3.5h28.5a3.5 3.5 0 0 1 3.5 3.5z"
    />
    <path
      fill={props.color || 'currentColor' || '#000'}
      d="M16 23.34a3.51 3.51 0 0 0 3.19-3.41 3.222 3.222 0 0 0-.83-2.48 3.13 3.13 0 0 0-1.08-.75 3 3 0 0 0-1.28-.25 3.2 3.2 0 0 0-2.37 1 3.25 3.25 0 0 0-.83 2.49 3.51 3.51 0 0 0 3.2 3.4M20 25.93a7.69 7.69 0 0 0-8.09 0 5.1 5.1 0 0 0-2.35 3.42 1 1 0 0 0 0 .46c.04.15.111.29.21.41a1.001 1.001 0 0 0 .75.34h10.87c.142 0 .281-.031.41-.09a.94.94 0 0 0 .34-.25c.099-.12.17-.26.21-.41a.999.999 0 0 0 0-.46A5.099 5.099 0 0 0 20 25.93M37 19.4H24.73a1.5 1.5 0 0 0 0 3H37a1.5 1.5 0 1 0 0-3M37 26.1h-7a1.5 1.5 0 1 0 0 3h7a1.5 1.5 0 0 0 0-3"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgAddressCard = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgAddressCard);
export default Memo;
