import { moment } from '@karutekun/shared/util/datetime';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useMemo, useRef } from 'react';
import { SectionList } from 'react-native';
import { ChatMessage as ChatMessageEntity } from '../../models/chatMessage/entity';
import { ChatMessageType } from '../../models/chatMessage/schema';
import { ChatMessageBody } from '../../models/chatMessageBody/schema';
import ChatDateSeparator from './ChatDateSeparator';
import ChatMessage from './ChatMessage';

type Props = {
  chatMessages: ChatMessageEntity[];
  onResendMessage(
    type: ChatMessageType,
    body: ChatMessageBody,
    resendDocumentId: string
  ): Promise<void>;
  onEndReached: () => Promise<void>;
};

const sectionListStyle = {
  height: 450,
  backgroundColor: '#feffff',
};

const useStyles = makeStyles((theme: Theme) => ({
  separator: {
    height: theme.spacing(1),
  },
}));

const ChatMessageArea: FC<Props> = (props) => {
  const { chatMessages, onResendMessage, onEndReached } = props;

  const sectionListRef = useRef<SectionList>(null);
  useEffect(() => {
    // https://github.com/necolas/react-native-web/issues/995
    // SectionList で inverted を利用すると、マウスホイールによるスクロールも逆転してしまい、使いづらい
    // issue が上がっているので、経過観察中
    // ワークアラウンドとして、スクロールイベントをジャックして反転させる実装を入れている

    // SectionList の型定義が実態と異なるため、
    // 型を矯正しながら undef アクセスが発生しないように慎重に実装している

    const scrollNode = sectionListRef?.current?.getScrollableNode() as unknown;
    if (
      !scrollNode ||
      (scrollNode as HTMLDivElement).addEventListener === undefined ||
      (scrollNode as HTMLDivElement).removeEventListener === undefined ||
      (scrollNode as HTMLDivElement).scrollTop === undefined
    ) {
      return;
    }

    const listener = (e: WheelEvent) => {
      (scrollNode as HTMLDivElement).scrollTop -= e.deltaY;
      e.preventDefault();
    };
    (scrollNode as HTMLDivElement).addEventListener('wheel', listener, {
      passive: false,
    });

    return function cleanup() {
      (scrollNode as HTMLDivElement).removeEventListener('wheel', listener);
    };
  });

  const dateMap = useMemo(() => {
    return chatMessages.reduce<{ [key: string]: ChatMessageEntity[] }>(
      (prev, chatMessage) => {
        const date = moment(chatMessage.createdAtMs).format('YYYY-MM-DD');
        if (!prev[date]) {
          prev[date] = [chatMessage];
        } else {
          prev[date].push(chatMessage);
        }
        return prev;
      },
      {}
    );
  }, [chatMessages]);

  const sections = useMemo(() => {
    const dates = Object.keys(dateMap).sort((a, b) =>
      moment(a).isBefore(b) ? 1 : -1
    );
    return dates.map((date) => ({ date, data: dateMap[date] }));
  }, [dateMap]);

  return (
    <SectionList
      ref={sectionListRef}
      style={sectionListStyle}
      sections={sections}
      keyExtractor={(item) => item.documentId}
      inverted={true}
      // https://github.com/necolas/react-native-web/issues/1254
      // invertedのスクロールにバグがあり、disableVirtualizationを有効にしないとうまく動作しない
      disableVirtualization
      stickySectionHeadersEnabled={true}
      renderItem={({ item }) => (
        <ChatMessage
          chatMessage={item}
          onResendMessage={() =>
            onResendMessage(
              item.type,
              item.body as ChatMessageBody,
              item.documentId
            )
          }
        />
      )}
      renderSectionFooter={({ section: { date } }) => (
        <ChatDateSeparator date={date} />
      )}
      SectionSeparatorComponent={Separator}
      ItemSeparatorComponent={Separator}
      onEndReached={onEndReached}
      onEndReachedThreshold={0.5}
    />
  );
};

const Separator: FC = () => {
  const classes = useStyles();
  return <div className={classes.separator} />;
};

export default ChatMessageArea;
