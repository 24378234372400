import {
  Backdrop,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { FormEvent, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import {
  pushSnackbarError,
  pushSnackbarSuccess,
} from '../../../../../../../actions/generalAction';
import { dispatchWithErrorHandling } from '../../../../../../../actions/helper/dispatchWithErrorHandling';
import {
  createStripeSetupIntent,
  updatePaymentProvider,
} from '../../../../../../../actions/subscriptionAction';
import CButton from '../../../../../../../components_old/atoms/CButton';
import CProgress from '../../../../../../../components_old/atoms/CProgress';
import {
  PaymentProvider,
  UpdatePaymentProviderResult,
} from '../../../../../../../models/subscription';
import { withStripe } from '../../../../../../../templates/hoc/StripeHOC';
import { useFlag } from '../../../../../../../util/hooks/useFlag';
import { useThunkDispatch } from '../../../../../../../util/hooks/useThunkDispatch';

type Props = {
  paymentProvider: PaymentProvider | null;
  open: boolean;
  onClose(): void;
};

const useStyles = makeStyles((theme: Theme) => ({
  formRoot: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  buttonContainer: {
    textAlign: 'right',
    marginTop: theme.spacing(2),
  },
  cardContainer: {
    minWidth: 320,
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const UpdatePaymentProviderDialog: FC<Props> = (props) => {
  const { open, onClose, paymentProvider } = props;

  const dispatch = useThunkDispatch();

  const handleCreateStripeSetupIntent = async (): Promise<string> => {
    return await dispatch(createStripeSetupIntent());
  };

  const handleUpdatePaymentProvider = async (updates: {
    billingEmail?: string;
    stripePaymentMethodId?: string;
  }): Promise<UpdatePaymentProviderResult | null> => {
    return await dispatchWithErrorHandling(
      dispatch,
      updatePaymentProvider(updates)
    );
  };

  const handleUpdateFinished = (result: UpdatePaymentProviderResult) => {
    if (result.isPaymentRequired) {
      if (result.isPaid) {
        dispatch(
          pushSnackbarSuccess(
            '新しいお支払い情報で決済が完了しました。\n反映に時間がかかる場合がございます。'
          )
        );
      } else {
        dispatch(pushSnackbarError('新しいお支払い情報で決済できませんでした'));
      }
    } else {
      dispatch(pushSnackbarSuccess('お支払い情報を更新しました'));
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>お支払い情報の変更</DialogTitle>
      <DialogContent>
        {paymentProvider ? (
          <UpdatePaymentProviderForm
            paymentProvider={paymentProvider}
            createStripeSetupIntent={handleCreateStripeSetupIntent}
            updatePaymentProvider={handleUpdatePaymentProvider}
            updateFinished={(result) => {
              handleUpdateFinished(result);
              props.onClose();
            }}
          />
        ) : (
          <Typography>お支払い情報が登録されていません</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

const UpdatePaymentProviderForm: FC<{
  paymentProvider: PaymentProvider;
  createStripeSetupIntent(): Promise<string>;
  updatePaymentProvider(updates: {
    stripePaymentMethodId?: string;
    billingEmail?: string;
  }): Promise<UpdatePaymentProviderResult | null>;
  updateFinished(result: UpdatePaymentProviderResult): void;
}> = (props) => {
  const classes = useStyles();

  const { paymentProvider, updatePaymentProvider, updateFinished } = props;

  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useThunkDispatch();

  const [isFetching, callWithIsFetching] = useFlag(false);
  const [billingEmail, setBillingEmail] = useState(
    paymentProvider.billingEmail ?? ''
  );
  const [cardError, setCardError] = useState<string | null>(null);

  const saveCreditCard = async (ev: FormEvent) => {
    ev.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      return;
    }

    await callWithIsFetching(async () => {
      const stripeSetupIntentClientSecret = await dispatch(
        createStripeSetupIntent()
      );

      const { setupIntent, error } = await stripe.confirmCardSetup(
        stripeSetupIntentClientSecret,
        {
          payment_method: { card: cardElement },
        }
      );

      if (!setupIntent || !setupIntent.payment_method || error) {
        setCardError(error?.message || 'こちらのカードはご利用いただけません');
        return;
      }

      // ここまで処理が来た段階でブラウザが閉じられてしまうと、
      // Stripe上でカード登録が成功しているのにもかかわらず、DB上の情報が更新されないが、
      // 単にStripe上での登録カードが増えるだけでデフォルトの決済手段には設定されないので、実害はありません
      const result = await updatePaymentProvider({
        stripePaymentMethodId:
          typeof setupIntent.payment_method === 'string'
            ? setupIntent.payment_method
            : setupIntent.payment_method.id,
      });

      // APIコールが失敗
      if (!result) {
        return;
      }

      if (result.isPaymentRequired) {
        // 決済の認証が必要な場合。認証を走らせて成功した場合は決済成功フラグをたてる
        if (result.stripePaymentIntentClientSecret) {
          const { paymentIntent } = await stripe.confirmCardPayment(
            result.stripePaymentIntentClientSecret
          );
          if (paymentIntent && paymentIntent.status === 'succeeded') {
            result.isPaid = true;
          }
        }
      }

      updateFinished(result);
    });
  };

  const saveBillingEmail = () => {
    return callWithIsFetching(() => updatePaymentProvider({ billingEmail }));
  };

  const isReady = stripe && elements && !isFetching;
  const isEmailEdited = paymentProvider.billingEmail !== billingEmail;

  return (
    <div className={classes.formRoot}>
      <Backdrop className={classes.backdrop} open={isFetching}>
        <CProgress />
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ValidatorForm instantValidate={false} onSubmit={saveBillingEmail}>
            <TextValidator
              variant="standard"
              fullWidth
              label="請求先メールアドレス"
              type="email"
              inputProps={{ inputMode: 'email', maxLength: 256 }}
              value={billingEmail}
              onChange={(e) => setBillingEmail(e.target.value)}
              validators={['required', 'isEmail']}
              errorMessages={[
                'メールアドレスは必須です',
                'メールアドレスが不正です',
              ]}
            />
            <div className={classes.buttonContainer}>
              <CButton type="submit" disabled={!isReady || !isEmailEdited}>
                保存
              </CButton>
            </div>
          </ValidatorForm>
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={saveCreditCard}>
            <InputLabel shrink>クレジットカード</InputLabel>
            <div className={classes.cardContainer}>
              <CardElement onChange={() => setCardError(null)} />
              {cardError !== null && (
                <Typography color="error">{cardError}</Typography>
              )}
            </div>
            <div className={classes.buttonContainer}>
              <CButton type="submit" disabled={!isReady || cardError !== null}>
                保存
              </CButton>
            </div>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStripe(UpdatePaymentProviderDialog);
