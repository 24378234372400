import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import { Card, IconButton, TextField, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import * as _ from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import {
  DragSourceMonitor,
  DropTargetMonitor,
  useDrag,
  useDrop,
} from 'react-dnd';
import { OptionItem, QuestionItem } from '../../../models/counseling';
import {
  WithConfirmDialog,
  withConfirmDialog,
} from '../../../templates/hoc/ConfirmDialogHOC';
import CButton from '../../atoms/CButton';
import CQuestionOption from './CQuestionOption';
import { DragItem, ItemTypes, isMovable } from './dragUtils';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    backgroundColor: 'white',
    borderLeftWidth: 4,
    // TODO 一時的にルールを無効化しています。気づいたベースで直してください
    // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type '100' can't be used to index type 'PaletteColor'.
    borderLeftColor: theme.palette.secondary[100],
    borderLeftStyle: 'solid',
  },
  required: {
    backgroundColor: theme.palette.error.light,
  },
  deleteButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  titleContainer: {
    marginBottom: theme.spacing(1),
  },
  title: {
    fontSize: theme.typography.h6.fontSize,
  },
  description: {
    fontSize: theme.typography.body2.fontSize,
  },
  questionWrapper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  handle: {
    width: '100%',
    display: 'inline-block',
    cursor: 'move',
    textAlign: 'center',
  },
}));

const CQuestion: FC<
  {
    question: QuestionItem;
    index: number;
    previousActiveIndex: number;
    nextActiveIndex: number;
    canDelete: boolean;
    canUpdate: boolean;
    isLoading: boolean;
    isCollapsed: boolean;
    savedMaxOptionId: number;
    onChangeIsDraging: (questionId: number, isDragging: boolean) => void;
    moveQuestion: (dragIndex: number, hoverIndex: number) => void;
    updateQuestion: (
      updatedIndex: number,
      updatedQuestion: QuestionItem | null
    ) => void;
  } & WithConfirmDialog
> = (props) => {
  const {
    question,
    index,
    previousActiveIndex,
    nextActiveIndex,
    canDelete,
    canUpdate,
    isLoading,
    isCollapsed,
    savedMaxOptionId,
    onChangeIsDraging,
    moveQuestion,
    updateQuestion,
    openConfirmDialog,
    closeConfirmDialog,
  } = props;
  const { id, title, description, counselingId, type, options } = question;
  const classes = useStyles();

  const questionRef = useRef(question);
  useEffect(() => {
    questionRef.current = question;
  }, [question]);

  const ref = useRef<HTMLDivElement>(null);
  const [, drop] = useDrop<DragItem>({
    accept: `${ItemTypes.question}:${counselingId}`,
    hover(item: DragItem, monitor: DropTargetMonitor) {
      const dragIndex = item.index;
      const hoverIndex = index;

      if (!isMovable(ref, monitor, dragIndex, hoverIndex)) {
        return;
      }
      moveQuestion(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: `${ItemTypes.question}:${counselingId}`,
    item: { id, index },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    onChangeIsDraging(id, isDragging);
  }, [id, isDragging, onChangeIsDraging]);

  const moveOption = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const cloneQuestion = _.cloneDeep(questionRef.current);
      const dragQuestion = cloneQuestion.options[dragIndex];
      cloneQuestion.options.splice(dragIndex, 1);
      cloneQuestion.options.splice(hoverIndex, 0, dragQuestion);
      updateQuestion(index, cloneQuestion);
    },
    [questionRef, index, updateQuestion]
  );

  const updateOption = useCallback(
    (updatedIndex: number, updatedOption: OptionItem | null) => {
      const cloneQuestion = _.cloneDeep(questionRef.current);
      if (updatedOption) {
        cloneQuestion.options[updatedIndex] = updatedOption;
      } else {
        cloneQuestion.options.splice(updatedIndex, 1);
      }

      updateQuestion(index, cloneQuestion);
    },
    [questionRef, index, updateQuestion]
  );

  const renderOption = (option: OptionItem, index: number) => {
    if (!option.isActive) {
      return;
    }
    return (
      <CQuestionOption
        option={option}
        key={option.id}
        index={index}
        canDelete={canDelete}
        isLoading={isLoading}
        type={type}
        moveOption={moveOption}
        updateOption={updateOption}
      />
    );
  };

  const opacity = isDragging ? 0 : 1;
  drop(ref);

  return (
    <Card ref={ref} style={{ opacity }} className={classes.container}>
      <div ref={preview}>
        <div ref={drag} className={classes.handle}>
          <SVGIcon name="grip-lines-vertical" />
        </div>

        <div className={classes.deleteButton}>
          {previousActiveIndex !== -1 && (
            <IconButton
              disabled={isLoading}
              onClick={() => moveQuestion(index, previousActiveIndex)}
              size="large"
            >
              <SVGIcon name="arrow-upward" />
            </IconButton>
          )}
          {nextActiveIndex !== -1 && (
            <IconButton
              disabled={isLoading}
              onClick={() => moveQuestion(index, index + 1)}
              size="large"
            >
              <SVGIcon name="arrow-downward" />
            </IconButton>
          )}
          <IconButton
            disabled={isLoading || (!canDelete && !question.isNewItem)}
            onClick={() => {
              openConfirmDialog({
                title: '確認',
                description: '本当に削除しますか？質問項目も削除されます。',
                onOk: () => {
                  if (question.isNewItem) {
                    updateQuestion(index, null);
                  } else {
                    updateQuestion(index, {
                      ...question,
                      isActive: false,
                      options: question.options
                        .filter((o) => !o.isNewItem)
                        .map((o) => ({
                          ...o,
                          isActive: false,
                        })),
                    });
                    closeConfirmDialog();
                  }
                },
              });
            }}
            size="large"
          >
            <SVGIcon name="delete" />
          </IconButton>
        </div>

        <TextField
          variant="standard"
          className={clsx(classes.titleContainer, {
            [classes.required]: !title,
          })}
          required
          label={title ? undefined : '質問タイトルは必須です'}
          value={title}
          placeholder="質問タイトル"
          style={{ width: '70%' }}
          InputProps={{
            classes: {
              input: classes.title,
            },
            inputProps: { maxLength: 240 },
          }}
          onChange={(e) => {
            updateQuestion(index, {
              ...question,
              title: e.target.value || '',
            });
          }}
        />
        <TextField
          variant="outlined"
          value={description || ''}
          placeholder="説明(省略可)"
          multiline
          style={{ width: '95%' }}
          InputProps={{
            classes: {
              input: classes.description,
            },
          }}
          onChange={(e) => {
            updateQuestion(index, {
              ...question,
              description: e.target.value || null,
            });
          }}
        />
      </div>

      {!isDragging && !isCollapsed && (
        <div className={classes.questionWrapper}>
          {options.map((option, i) => renderOption(option, i))}
        </div>
      )}

      {!isCollapsed && (
        <CButton
          startIcon={<SVGIcon name="plus" />}
          disabled={isLoading || !canUpdate}
          variant="text"
          onClick={() => {
            const optionWithMaxId = _.maxBy(options, 'id');
            const tmpIndex = options.length;

            const maxOptionIdInQuestion = optionWithMaxId?.id ?? 0;
            // preview用にほぼ(option,questionがそれぞれ100個未満なら)重複しないようにoptionIdを採番
            const optionId =
              savedMaxOptionId +
              counselingId * 10000 +
              id * 100 +
              maxOptionIdInQuestion +
              1;

            updateOption(tmpIndex, {
              questionId: id,
              id: optionId,
              order: 0,
              description: '',
              textPlaceholder: null,
              isNewItem: true,
              isActive: true,
            });
          }}
        >
          項目追加
        </CButton>
      )}
    </Card>
  );
};

export default React.memo(withConfirmDialog(CQuestion), (prev, next) =>
  _.isEqual(prev, next)
);
