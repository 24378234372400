import { CustomerLoyaltySegment } from '@karutekun/core/customer';
import { ServiceType } from '@karutekun/core/salon-service';
import { buildRepeatCustomerDailyStatsSnapshot } from '@karutekun/core/stats/customer-analytics-stats';
import { trpc } from '@karutekun/shared/data-access/api-base';
import { today } from '@karutekun/shared/util/datetime';
import { Card, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import CInformation from '../../../../components_old/atoms/CInformation';
import CAnalyticsDisplayTaxIncludedPriceSwitcher from '../../../../components_old/molecules/CAnalyticsDisplayTaxIncludedPriceSwitcher';
import CChartBarSalesVolume from '../../../../components_old/molecules/CChartBarSalesVolume';
import { CChartBarSexAgeGroup } from '../../../../components_old/molecules/CChartBarSexAgeGroup';
import CChartGroupedService from '../../../../components_old/molecules/CChartGroupedService';
import CChartPieAverageVisitPeriod from '../../../../components_old/molecules/CChartPieAverageVisitPeriod';
import CChartPieShimei from '../../../../components_old/molecules/CChartPieShimei';
import CChartPieStylist from '../../../../components_old/molecules/CChartPieStylist';
import CChartPieVisitMotivation from '../../../../components_old/molecules/CChartPieVisitMotivation';
import CChartPieVisitNum from '../../../../components_old/molecules/CChartPieVisitNum';
import { CCustomerAnalyticsStatsSummaryCard } from '../../../../components_old/organisms/CLoyaltySegmentStatsSummaryCard';
import CChartWrapper from '../../../../components_old/organisms/charts/CChartWrapper';
import { CustomerListData } from '../../../../reducers/types';
import {
  selectMySalon,
  selectStylistMap,
} from '../../../../selectors/salonSelector';
import { selectAnalyticsDisplayTaxIncludedPrice } from '../../../../selectors/settingsSelector';
import { InformationText } from '../../../../texts/infomation';
import { useSyncedQueryParams } from '../../../../util/hooks/router/useSyncedQueryParams';
import { AnalyticsCustomerSection } from '../_components/AnalyticsCustomerSection';
import { RepeatCustomerTransitionCard } from './_components/RepeatCustomerTransitionCard';

const useStyles = makeStyles(() => ({
  root: {
    padding: '76px 20px 20px 20px',
  },
}));

export const RepeatCustomer: FC = () => {
  const classes = useStyles();

  const salon = useSelector(selectMySalon);
  const stylistMap = useSelector(selectStylistMap);
  const displayTaxIncludedPrice = useSelector(
    selectAnalyticsDisplayTaxIncludedPrice
  );

  const [{ transitionDateRange, customerList }, setParams] =
    useSyncedQueryParams(
      {
        transitionDateRange: { from: today(-180), to: today(-1) },
        customerList: {
          page: 0,
          sortKey: undefined,
          sortOrder: undefined,
          filter: {},
        } as Pick<
          CustomerListData,
          'page' | 'sortKey' | 'sortOrder' | 'filter'
        >,
      },
      true
    );

  const { isRefetching, data: latestSnapshot } =
    trpc.analytics.repeatCustomerStats.latestSnapshot.useQuery(undefined, {
      placeholderData: (prevData) =>
        prevData ?? buildRepeatCustomerDailyStatsSnapshot(salon.id, today()),
    });

  const lastStylistMap = useMemo(() => {
    return Object.entries(latestSnapshot?.lastStylistShimeiMap ?? {}).reduce(
      (prev, [stylistId, isShimeiMap]) => {
        prev[Number(stylistId)] = isShimeiMap[0] + isShimeiMap[1];
        return prev;
      },
      {} as IdMap<number>
    );
  }, [latestSnapshot?.lastStylistShimeiMap]);

  const fixedCustomerFilter = useMemo(() => {
    return {
      salon: {
        salonId: salon.id,
        loyaltySegment: CustomerLoyaltySegment.Repeat,
      },
    };
  }, [salon.id]);

  if (!latestSnapshot) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Grid container justifyContent="flex-end" alignItems="center">
        <CAnalyticsDisplayTaxIncludedPriceSwitcher />
        <CInformation
          type="dialog"
          content={InformationText.repeatCustomer.overall}
          size="lg"
          mb={8}
        />
      </Grid>

      <Grid
        container
        justifyContent="space-evenly"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <RepeatCustomerTransitionCard
            from={transitionDateRange.from}
            to={transitionDateRange.to}
            onChangeDate={(from, to) =>
              setParams({ transitionDateRange: { from, to } })
            }
          />
        </Grid>

        <Grid item xs={12} sm={8} md={4} lg={4}>
          <CCustomerAnalyticsStatsSummaryCard
            title="固定客"
            data={latestSnapshot}
            displayTaxIncludedPrice={displayTaxIncludedPrice}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={6} lg={3}>
          <Card>
            <CChartWrapper
              title="来店回数"
              isFetching={isRefetching}
              informationText={InformationText.repeatCustomer.visitNum}
            >
              <CChartPieVisitNum visitNumMap={latestSnapshot.visitNumMap} />
            </CChartWrapper>
          </Card>
        </Grid>

        <Grid item xs={6} sm={6} md={6} lg={3}>
          <Card>
            <CChartWrapper
              title="来店周期"
              isFetching={isRefetching}
              informationText={InformationText.repeatCustomer.visitPeriod}
            >
              <CChartPieAverageVisitPeriod
                averageVisitPeriodMap={latestSnapshot.averageVisitPeriodMap}
              />
            </CChartWrapper>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Card>
            <CChartWrapper
              title="来店時支払い分布"
              isFetching={isRefetching}
              informationText={
                InformationText.customerCommon.budgetDistribution
              }
            >
              <CChartBarSalesVolume salesMap={latestSnapshot.salesRangeMap} />
            </CChartWrapper>
          </Card>
        </Grid>

        <Grid item xs={6} sm={6} md={6} lg={3}>
          <Card>
            <CChartWrapper
              title="指名"
              isFetching={isRefetching}
              informationText={InformationText.repeatCustomer.shimei}
            >
              <CChartPieShimei isShimeiMap={latestSnapshot.isShimeiMap} />
            </CChartWrapper>
          </Card>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={3}>
          <Card>
            <CChartWrapper
              title="来店動機"
              isFetching={isRefetching}
              informationText={InformationText.customerCommon.visitMotivation}
            >
              <CChartPieVisitMotivation
                salonVisitMotivationMap={
                  salon.customInformation.visitMotivationMap
                }
                visitMotivationMap={latestSnapshot.visitMotivationMap}
              />
            </CChartWrapper>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Card>
            <CChartWrapper title="男女年齢分解" isFetching={isRefetching}>
              <CChartBarSexAgeGroup
                sexAgeGroupMap={latestSnapshot.customerSexAgeGroupMap}
              />
            </CChartWrapper>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={4}>
          <Card>
            <CChartWrapper
              title="メニュー分析"
              isFetching={isRefetching}
              informationText={InformationText.customerCommon.menuAnalytics}
              height={300}
            >
              <CChartGroupedService
                chartType="pie"
                serviceType={ServiceType.Menu}
                formatter={(v) => `${v}回`}
                serviceValueMap={latestSnapshot.serviceNumMap}
              />
            </CChartWrapper>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <Card>
            <CChartWrapper
              title="店販分析"
              isFetching={isRefetching}
              informationText={InformationText.customerCommon.productAnalytics}
              height={300}
            >
              <CChartGroupedService
                chartType="pie"
                serviceType={ServiceType.Product}
                formatter={(v) => `${v}回`}
                serviceValueMap={latestSnapshot.serviceNumMap}
              />
            </CChartWrapper>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <Card>
            <CChartWrapper title="前回担当者" isFetching={isRefetching}>
              <CChartPieStylist
                salonStylistMap={stylistMap}
                stylistMap={lastStylistMap}
              />
            </CChartWrapper>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <AnalyticsCustomerSection
            title="固定客一覧"
            listData={customerList}
            fixedFilter={fixedCustomerFilter}
            onChangeListData={(customerList) => setParams({ customerList })}
          />
        </Grid>
      </Grid>
    </div>
  );
};
