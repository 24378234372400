import { ActionType, getType } from 'typesafe-actions';
import * as actions from '../actions/userAction';
import { SalonMinimum } from '../models/salon';
import { MyInfo } from '../models/stylist';
import { LoginInfo } from '../models/userStatus';

export type UserActions = ActionType<typeof actions>;

export type User = {
  // Firebaseのログイン情報
  // 初期化が終わっていないときには undefined が入っている
  loginInfo: LoginInfo | null | undefined;

  // ユーザーがすでにいずれかのサロンに所属しているかどうか
  // 初期化が終わっていないときには undefined が入っている
  isJoinedSalon: boolean | undefined;

  myInfo: ({ id: number } & MyInfo) | null;
  requestingSalon: SalonMinimum | null;
};

export type UserState = {
  user: User;
};

const initialState: UserState = {
  user: {
    loginInfo: undefined,
    isJoinedSalon: undefined,
    myInfo: null,
    requestingSalon: null,
  },
};

function userReducer(state = initialState, action: UserActions): UserState {
  switch (action.type) {
    case getType(actions.setUserLoginInfo): {
      const { loginInfo } = action.payload;
      return { ...state, user: { ...state.user, loginInfo } };
    }
    case getType(actions.setUserMyInfo): {
      const { myInfo } = action.payload;
      return { user: { ...state.user, myInfo } };
    }
    case getType(actions.setUserIsJoinedSalon): {
      const { isJoinedSalon } = action.payload;
      return { user: { ...state.user, isJoinedSalon } };
    }
    case getType(actions.setUserRequestingSalon): {
      const { requestingSalon } = action.payload;
      return { user: { ...state.user, requestingSalon } };
    }
    case getType(actions.clearUser): {
      return {
        user: {
          loginInfo: null,
          isJoinedSalon: undefined,
          myInfo: null,
          requestingSalon: null,
        },
      };
    }
    default:
      return state;
  }
}

export default userReducer;
