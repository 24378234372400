import { CSSProperties } from 'react';
import { Sticky } from 'react-sticky';
import { headerHeight } from '../../components/Header';

type OwnProps = {
  stickyStyle?: CSSProperties;
};

/**
 * スクロールしてもページの上に張り付くコンポーネント
 * 親に <StickyContainer> コンポーネントがいないといけません！
 */
const CSticky: FC<OwnProps> = (props) => {
  const { stickyStyle, children } = props;
  const offset = headerHeight + 4;
  return (
    <Sticky topOffset={-offset}>
      {({ style, isSticky }) => (
        <div
          style={{
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ...(style as any),
            zIndex: 100,
            ...(isSticky ? { top: offset, ...stickyStyle } : {}),
          }}
        >
          {children}
        </div>
      )}
    </Sticky>
  );
};

export default CSticky;
