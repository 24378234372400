import { ActionType, getType } from 'typesafe-actions';
import * as actions from '../actions/salonBusinessInfoAction';
import { PlainDailyBusinessInfo } from '../models/salonBusinessInfo';

export type SalonBusinessInfoActions = ActionType<typeof actions>;

export interface SalonBusinessInfoState {
  /**
   * {
   *   [YYYY-MM-DD]: BusinessInfo,
   *   ...
   * }
   */
  dailyBusinessInfoMap: DateMap<PlainDailyBusinessInfo>;
}

const initialState: SalonBusinessInfoState = {
  dailyBusinessInfoMap: {},
};

function salonBusinessInfoReducer(
  state = initialState,
  action: SalonBusinessInfoActions
): SalonBusinessInfoState {
  switch (action.type) {
    case getType(actions.setSalonBusinessInfoMap): {
      const { map } = action.payload;

      const dates = Object.keys(map);
      return {
        ...state,
        dailyBusinessInfoMap: {
          ...state.dailyBusinessInfoMap,
          ...dates.reduce((prev, date) => {
            // TODO 一時的にルールを無効化しています。気づいたベースで直してください
            // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
            prev[date] = {
              ...state.dailyBusinessInfoMap[date],
              ...map[date],
            };
            return prev;
          }, {}),
        },
      };
    }
    default:
      return state;
  }
}

export default salonBusinessInfoReducer;
