export function uniqueBy<T, K extends keyof T>(values: T[], key: K): T[] {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const map: any = {};
  const result: T[] = [];
  for (const value of values) {
    if (!map[value[key]]) {
      result.push(value);
      map[value[key]] = true;
    }
  }
  return result;
}
