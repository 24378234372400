import {
  Backdrop,
  Card,
  CardContent,
  Grid,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentIntent, SetupIntent, StripeError } from '@stripe/stripe-js';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router';
import {
  pushSnackbar,
  pushSnackbarError,
  pushSnackbarSuccess,
} from '../../../../../actions/generalAction';
import { dispatchWithErrorHandling } from '../../../../../actions/helper/dispatchWithErrorHandling';
import { setMySalon } from '../../../../../actions/salonAction';
import {
  createSubscription,
  fetchSubscriptionInfo,
} from '../../../../../actions/subscriptionAction';
import CButton from '../../../../../components_old/atoms/CButton';
import CProgress from '../../../../../components_old/atoms/CProgress';
import CTextBox from '../../../../../components_old/atoms/CTextBox';
import CTextLink from '../../../../../components_old/atoms/CTextLink';
import Logger, { AnalyticsEvent } from '../../../../../logger';
import { BasicPlan, OptionLinePlan } from '../../../../../models/salon';
import {
  SetupSubscriptionResult,
  SubscriptionStatus,
} from '../../../../../models/subscription';
import {
  selectActiveStylists,
  selectMySalon,
} from '../../../../../selectors/salonSelector';
import { selectUserEmail } from '../../../../../selectors/userSelector';
import { withAdminPage } from '../../../../../templates/hoc/AdminPageHOC';
import { withStripe } from '../../../../../templates/hoc/StripeHOC';
import { useFlag } from '../../../../../util/hooks/useFlag';
import { useThunkDispatch } from '../../../../../util/hooks/useThunkDispatch';
import { PlanSelectionForm } from './_components/PlanSelectionForm';
import { RegisterPaymentProviderForm } from './_components/RegisterPaymentProviderForm';

const useStyles = makeStyles((theme: Theme) => ({
  mainCard: {
    minHeight: 100,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

enum PageMode {
  // 新規プラン登録
  RegisterSubscription,

  // 支払い方法のクレカ切り替え
  SwitchPaymentMethod,
}

const CreateSubscription: FC = () => {
  const classes = useStyles();

  const dispatch = useThunkDispatch();
  const navigate = useNavigate();

  const salon = useSelector(selectMySalon);
  const email = useSelector(selectUserEmail) ?? '';
  const hasMultipleStylists = useSelector(selectActiveStylists).length > 1;

  const stripe = useStripe();
  const elements = useElements();

  const [isFetching, callWithIsFetching] = useFlag(false);
  const [isSubmitting, callWithIsSubmitting] = useFlag(false);

  const [basicPlan, setBasicPlan] = useState<BasicPlan>(
    salon.basicPlan === BasicPlan.Trial ? BasicPlan.Standard : salon.basicPlan
  );
  const [optionLinePlan, setOptionLinePlan] = useState<OptionLinePlan>(
    salon.basicPlan === BasicPlan.Trial
      ? OptionLinePlan.Trial
      : salon.optionLinePlan
  );
  const [billingEmail, setBillingEmail] = useState(email);
  const [cardError, setCardError] = useState<string | null>(null);

  useEffect(() => {
    Logger.logEvent(AnalyticsEvent.viewCreateSubscription);
  }, []);

  useEffect(() => {
    callWithIsFetching(async () => dispatch(fetchSubscriptionInfo()));
  }, [callWithIsFetching, dispatch]);

  const handlePlanChange = useCallback(
    (basicPlan: BasicPlan, optionLinePlan: OptionLinePlan) => {
      setBasicPlan(basicPlan);
      setOptionLinePlan(optionLinePlan);
    },
    []
  );

  const finishCreateProcess = useCallback(
    async (result: SetupSubscriptionResult, confirmedIntent?: boolean) => {
      navigate('/admin/plan');

      if (result.subscription.status === SubscriptionStatus.Incomplete) {
        if (confirmedIntent === undefined) {
          dispatch(pushSnackbarError('お支払いに失敗しました'));
        } else if (confirmedIntent) {
          dispatch(
            pushSnackbar({
              message:
                'お支払い方法を認証しました。決済に時間がかかる場合がございます',
              type: 'info',
            })
          );
        } else {
          dispatch(pushSnackbarError('お支払い方法が認証できませんでした'));
        }
      } else {
        dispatch(pushSnackbarSuccess('登録が完了しました'));

        Logger.logEvent(AnalyticsEvent.createSubscription);
      }

      dispatch(
        setMySalon({
          basicPlan: result.basicPlan,
          subscription: result.subscription,
        })
      );
    },
    [dispatch, navigate]
  );

  const handleSubmit = useCallback(
    async (ev: FormEvent) => {
      ev.preventDefault();

      if (!stripe || !elements) {
        return;
      }

      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        return;
      }

      await callWithIsSubmitting(async () => {
        const { paymentMethod, error } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        });
        if (!paymentMethod || error) {
          setCardError(
            error?.message || 'こちらのカードはご利用いただけません'
          );
          return;
        }

        const result = await dispatchWithErrorHandling(
          dispatch,
          createSubscription(
            basicPlan,
            optionLinePlan,
            paymentMethod.id,
            billingEmail
          )
        );
        if (!result) {
          return;
        }

        // 必要であれば、各種 intent のユーザー認証をする
        if (result.subscription.stripeIntentStatus === 'requires_action') {
          let intent: PaymentIntent | SetupIntent | undefined;
          let error: StripeError | undefined;

          if (result.stripePaymentIntentClientSecret !== null) {
            const intentResult = await stripe.confirmCardPayment(
              result.stripePaymentIntentClientSecret
            );
            intent = intentResult.paymentIntent;
            error = intentResult.error;
          } else if (result.stripeSetupIntentClientSecret !== null) {
            const intentResult = await stripe.confirmCardSetup(
              result.stripeSetupIntentClientSecret
            );
            intent = intentResult.setupIntent;
            error = intentResult.error;
          }

          const confirmedIntent = intent !== undefined && error === undefined;
          finishCreateProcess(result, confirmedIntent);

          return;
        }

        finishCreateProcess(result);
      });
    },
    [
      basicPlan,
      billingEmail,
      callWithIsSubmitting,
      dispatch,
      elements,
      finishCreateProcess,
      optionLinePlan,
      stripe,
    ]
  );

  const { subscription } = salon;

  if (subscription !== null) {
    return <Navigate to="/admin/plan" replace />;
  }

  const pageMode =
    salon.basicPlan === BasicPlan.Trial
      ? PageMode.RegisterSubscription
      : PageMode.SwitchPaymentMethod;

  const isSingleDisabled =
    pageMode === PageMode.RegisterSubscription && hasMultipleStylists;

  return (
    <>
      <Backdrop className={classes.backdrop} open={isSubmitting}>
        <CProgress />
      </Backdrop>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">
            {pageMode === PageMode.RegisterSubscription
              ? 'プランのご契約'
              : 'お支払い方法の切り替え'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Card className={classes.mainCard}>
            <CardContent>
              {isFetching ? (
                <CProgress />
              ) : (
                <ValidatorForm instantValidate={false} onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <PlanSelectionForm
                        basicPlan={basicPlan}
                        optionLinePlan={optionLinePlan}
                        onChange={handlePlanChange}
                        isBasicPlanDisabled={
                          pageMode === PageMode.SwitchPaymentMethod
                        }
                        isOptionLinePlanDisabled={
                          pageMode === PageMode.SwitchPaymentMethod
                        }
                        isSingleDisabled={isSingleDisabled}
                      />
                      {pageMode === PageMode.SwitchPaymentMethod && (
                        <CTextBox mt={1} mb={2}>
                          ※
                          お支払い方法の切り替え時はプランを変更することはできません。
                          {'\n'}
                          プランを変更したい場合は、お手数ですが、
                          <CTextLink
                            text="こちら"
                            linkTo={process.env.REACT_APP_CONTACT_URL}
                          />
                          よりお問い合わせください。
                        </CTextBox>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom>
                        お支払い情報
                      </Typography>
                      <RegisterPaymentProviderForm
                        billingEmail={billingEmail}
                        cardError={cardError}
                        clearCardError={() => setCardError(null)}
                        onChangeBillingEmail={setBillingEmail}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {pageMode === PageMode.SwitchPaymentMethod && (
                        <CTextBox mt={2} mb={2}>
                          <Typography variant="body1">注意事項</Typography>
                          {`※ 毎月25日までのクレジットカード登録で、翌々月のご利用料金からクレジットカードでのお支払いに切り替わります。
                          ※ 25日以降のご登録に関しては、請求書発行期間と重なりますので、クレジットカードのご登録を行わないよう、お願い致します。
                          ※ お手数ですが、翌月分までのご利用料金は引き続き銀行振込でお支払いください。`}
                        </CTextBox>
                      )}

                      <CButton
                        type="submit"
                        disabled={
                          !stripe || !elements || isFetching || isSubmitting
                        }
                      >
                        {pageMode === PageMode.RegisterSubscription
                          ? '登録'
                          : 'クレジットカードでの支払いに切り替える'}
                      </CButton>
                    </Grid>
                  </Grid>
                </ValidatorForm>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default withStripe(withAdminPage(CreateSubscription));
