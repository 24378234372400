import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useCallback, useState } from 'react';
import { ChatMessageType } from '../../models/chatMessage/schema';
import { ChatMessageBody } from '../../models/chatMessageBody/schema';
import CButton from '../atoms/CButton';

type Props = {
  onSend: (
    type: ChatMessageType,
    body: ChatMessageBody,
    resendDocumentId?: string
  ) => Promise<void>;
};

const ChatForm: FC<Props> = (props) => {
  const { onSend } = props;

  const [message, setMessage] = useState<string>('');

  const handleSend = useCallback(() => {
    onSend(ChatMessageType.Text, { text: message });
    setMessage('');
  }, [onSend, message]);

  return (
    <Grid container alignItems="flex-end" spacing={2}>
      <Grid item xs>
        <TextField
          fullWidth
          multiline
          placeholder="メッセージを入力"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          variant="outlined"
        />
      </Grid>
      <Grid item>
        <CButton
          onClick={handleSend}
          disabled={message.trim() === ''}
          endIcon={<SVGIcon name="send" size="sm" />}
        >
          送信
        </CButton>
      </Grid>
    </Grid>
  );
};

export default ChatForm;
