import { Box, Button, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Images } from '../../assets/images';
import { useWidthUp } from '../../util/hooks/useWidth';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '100vh',
  },
  container: {
    [theme.breakpoints.down('md')]: {
      padding: 16,
      paddingTop: 32,
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: 724,
      padding: 40,
      paddingTop: 64,
    },
  },
  image: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      maxWidth: 252,
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: 300,
    },
  },
  button: { height: 44, fontSize: 14, fontWeight: 700 },
}));

const Maintenance: FC = () => {
  const classes = useStyles();

  const isWidthUp = useWidthUp('sm');
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="flex-start"
      className={classes.root}
    >
      {isWidthUp ? (
        <Grid item container spacing={3} className={classes.container}>
          <Grid item container style={{ flex: 4 }}>
            <Typography variant="h4" style={{ fontWeight: 700 }}>
              メンテナンス中です
            </Typography>

            <Box mt={2} mb={5}>
              <Typography variant="h6">
                ご利用の皆様にはご迷惑をおかけし、大変申し訳ございません。
              </Typography>
              <Box mt={1}>
                <Typography variant="h6">
                  メンテナンス終了までしばらくお待ちください。
                </Typography>
              </Box>
            </Box>

            <MaintenanceButton />
          </Grid>

          <Grid item container alignItems="center" style={{ flex: 3 }}>
            <MainImage />
          </Grid>
        </Grid>
      ) : (
        <Grid item container spacing={3} className={classes.container}>
          <Grid item container justifyContent="center" xs={12}>
            <Typography variant="h4" style={{ fontWeight: 700 }}>
              メンテナンス中です
            </Typography>
          </Grid>

          <Grid item container justifyContent="center" xs={12}>
            <Box mt={4} mb={4}>
              <MainImage />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">
              ご利用の皆様にはご迷惑をおかけし、大変申し訳ございません。
            </Typography>
            <Box mt={1}>
              <Typography variant="h6">
                メンテナンス終了までしばらくお待ちください。
              </Typography>
            </Box>
          </Grid>

          <Grid item container xs={12} justifyContent="center">
            <MaintenanceButton />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const MainImage: FC = () => {
  const classes = useStyles();
  return (
    <img
      src={Images.common.maintenance}
      alt="maintenance"
      className={classes.image}
    />
  );
};

const MaintenanceButton: FC = () => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      color="primary"
      href="https://karutekun.com/announcements/maintenance/"
      target="_blank"
      rel="noopener noreferrer"
      className={classes.button}
    >
      今回のメンテナンスについて
    </Button>
  );
};

export default Maintenance;
