import { createSelector } from 'reselect';
import {
  DailyBusinessInfo,
  PlainDailyBusinessInfo,
  StylistShift,
} from '../models/salonBusinessInfo';
import { ShiftPattern } from '../models/salonScheduleSettings';
import { selectSalonStatus } from '../selectors/salonStatusSelector';
import { GlobalState } from '../store';
import { selectShiftPatternMap } from './scheduleSettingsSelector';

/**
 * 営業情報の取得
 * 営業情報設定が有効の場合のみデータを返す
 */
export const selectPlainBusinessInfoMap = createSelector(
  (state: GlobalState) => selectSalonStatus(state),
  (state: GlobalState) => state.salonBusinessInfo.dailyBusinessInfoMap,
  (salonStatus, businessInfoMap) =>
    salonStatus.hasSetting('businessInfo') ? businessInfoMap : {}
);

export const selectBusinessInfoMap = createSelector(
  (state: GlobalState) => selectPlainBusinessInfoMap(state),
  (state: GlobalState) => selectShiftPatternMap(state),
  (
    businessInfoMap: DateMap<PlainDailyBusinessInfo>,
    shiftPatternMap: IdMap<ShiftPattern>
  ) => {
    const dates = Object.keys(businessInfoMap);
    return dates.reduce<DateMap<DailyBusinessInfo>>((prev, date) => {
      const businessInfo = businessInfoMap[date];
      if (!businessInfo) {
        return prev;
      }

      const stylistIds = Object.keys(businessInfo.stylistShift);
      const stylistShift = stylistIds.reduce<IdMap<StylistShift>>(
        (prev, stylistId) => {
          const shift = businessInfo.stylistShift[Number(stylistId)];
          if (!shift) {
            return prev;
          }

          const shiftPattern = shift.shiftPatternId
            ? shiftPatternMap[shift.shiftPatternId] || null
            : null;

          // TODO 一時的にルールを無効化しています。気づいたベースで直してください
          // @ts-expect-error: TS7015: Element implicitly has an 'any' type because index expression is not of type 'number'.
          prev[stylistId] = { ...shift, shiftPattern };
          return prev;
        },
        {}
      );
      prev[date] = {
        ...businessInfo,
        stylistShift,
      };
      return prev;
    }, {});
  }
);
