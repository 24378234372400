import { ActionType, getType } from 'typesafe-actions';
import * as actions from '../../actions/view/viewAutoCompleteCustomerAction';

export type ViewAutoCompleteCustomerState = {
  customerIds: number[];
  hasMore: boolean;
  isFetching: boolean;
};

const initialState: ViewAutoCompleteCustomerState = {
  customerIds: [],
  hasMore: false,
  isFetching: false,
};

export type ViewAutoCompleteCustomerActions = ActionType<typeof actions>;

function viewAutoCompleteCustomerReducer(
  state: ViewAutoCompleteCustomerState = initialState,
  action: ViewAutoCompleteCustomerActions
): ViewAutoCompleteCustomerState {
  switch (action.type) {
    case getType(actions.setViewAutoCompleteCustomerData): {
      const { data } = action.payload;
      return {
        ...state,
        ...data,
      };
    }
    default:
      return state;
  }
}

export default viewAutoCompleteCustomerReducer;
