import { useArray } from '@karutekun/shared/util/react-hooks';
import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import {
  Box,
  Card,
  Chip,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { dispatchWithErrorHandling } from '../../../../actions/helper/dispatchWithErrorHandling';
import {
  deleteReservationStylist,
  fetchReservationStylists,
  updateReservationStylistOrder,
} from '../../../../actions/salon/reservationSettingsAction';
import CButton from '../../../../components_old/atoms/CButton';
import CHorizontalScrollHintContainer from '../../../../components_old/atoms/CHorizontalScrollHintContainer';
import CIconButton from '../../../../components_old/atoms/CIconButton';
import CProgressOverlay from '../../../../components_old/atoms/CProgressOverlay';
import CStylistAvatar from '../../../../components_old/atoms/CStylistAvatar';
import { ReservationStylist } from '../../../../models/reservationStylist';
import { PlainStylist, checkPermission } from '../../../../models/stylist';
import { selectActiveReservationStylists } from '../../../../selectors/reservationSettingsSelector';
import {
  selectActiveStylists,
  useSelectMe,
} from '../../../../selectors/salonSelector';
import DndProviderTemplate from '../../../../templates/DndProviderTemplate';
import {
  WithConfirmDialog,
  withConfirmDialog,
} from '../../../../templates/hoc/ConfirmDialogHOC';
import { notEmpty } from '../../../../util/common';
import { useFlag } from '../../../../util/hooks/useFlag';
import { useSortableItem } from '../../../../util/hooks/useSortableItem';
import { useThunkDispatch } from '../../../../util/hooks/useThunkDispatch';

type Props = WithConfirmDialog;

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    position: 'relative',
  },
  cellSort: {
    width: 60,
    padding: theme.spacing(1),
  },
  cellSortable: {
    'cursor': 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  cellDescription: {
    minWidth: 200,
    maxWidth: 400,
  },
  cell: {
    minWidth: 80,
    maxHeight: 40,
    padding: theme.spacing(1),
  },
  separatorRow: {
    backgroundColor: theme.palette.grey[100],
  },
  description: {
    whiteSpace: 'pre-line',
  },
}));

const ReservationStylistSettingCard: FC<Props> = (props) => {
  const classes = useStyles();
  const { openConfirmDialog, closeConfirmDialog } = props;

  const me = useSelectMe();
  const stylists = useSelector(selectActiveStylists);
  const reservationStylists = useSelector(selectActiveReservationStylists);
  const dispatch = useThunkDispatch();

  const unpreparedStylists = stylists.filter(
    (s) => reservationStylists.findIndex((rs) => rs.stylistId === s.id) === -1
  );

  const hasPermission = checkPermission(me, 'canUpdateReservationSetting');

  const [order, orderMutations] = useArray<number>([]);
  const reorderedReservationStylists = order
    .map((id) => reservationStylists.find((rs) => rs.stylistId === id))
    .filter(notEmpty);

  const [isFetching, callWithFetching] = useFlag(false);

  const resetOrder = useCallback(() => {
    orderMutations.set(reservationStylists.map((rs) => rs.stylistId));
  }, [orderMutations, reservationStylists]);

  useEffect(() => {
    callWithFetching(async () => await dispatch(fetchReservationStylists()));
  }, [callWithFetching, dispatch]);

  useEffect(resetOrder, [resetOrder]);

  const isOrderChanged = useMemo(
    () =>
      _.isEqual(
        order,
        reservationStylists.map((rs) => rs.stylistId)
      ),
    [order, reservationStylists]
  );

  const handleDelete = useCallback(
    (stylistId: number) => {
      openConfirmDialog({
        description: '本当にスタッフの予約設定を削除しますか？',
        onOk: () => {
          closeConfirmDialog();
          callWithFetching(async () => {
            await dispatchWithErrorHandling(
              dispatch,
              deleteReservationStylist(stylistId),
              {
                success: 'スタッフの予約設定を削除しました',
              }
            );
          });
        },
      });
    },
    [callWithFetching, closeConfirmDialog, dispatch, openConfirmDialog]
  );
  const handleSaveOrder = useCallback(
    () =>
      callWithFetching(async () => {
        await dispatchWithErrorHandling(
          dispatch,
          updateReservationStylistOrder(order),
          {
            success: '掲載順を保存しました',
          }
        );
      }),
    [callWithFetching, dispatch, order]
  );

  return (
    <Card className={classes.card}>
      {isFetching && <CProgressOverlay />}
      <CHorizontalScrollHintContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" className={classes.cellSort}>
                <Typography variant="caption">掲載順</Typography>
              </TableCell>
              <TableCell align="center" className={classes.cell}>
                スタッフ名
              </TableCell>
              <TableCell align="center" className={classes.cellDescription}>
                紹介文
              </TableCell>
              <TableCell align="center" className={classes.cell}>
                掲載状況
              </TableCell>
              <TableCell align="center" className={classes.cell}>
                編集
              </TableCell>
              <TableCell align="center" className={classes.cell}>
                削除
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {reorderedReservationStylists.length > 0 && (
              <>
                <DndProviderTemplate>
                  {reorderedReservationStylists.map((s, i) => (
                    <StylistRow
                      key={s.id}
                      reservationStylist={s}
                      index={i}
                      disabled={!hasPermission}
                      onDelete={handleDelete}
                      onOrderChange={orderMutations.swap}
                    />
                  ))}
                </DndProviderTemplate>

                {hasPermission && (
                  <TableRow>
                    <TableCell colSpan={8} className={classes.separatorRow}>
                      <Grid container alignItems="center">
                        <Grid item>
                          <Typography variant="body2">左のアイコン</Typography>
                        </Grid>
                        <Grid item>
                          <SVGIcon
                            name="drag-indicator"
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography variant="body2">
                            をドラッグして掲載順を変更することができます。
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Box ml={2}>
                            <CButton
                              disabled={isOrderChanged}
                              variant="outlined"
                              size="small"
                              onClick={resetOrder}
                            >
                              キャンセル
                            </CButton>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box ml={2}>
                            <CButton
                              disabled={isOrderChanged}
                              size="small"
                              onClick={handleSaveOrder}
                            >
                              掲載順を保存
                            </CButton>
                          </Box>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}

            {unpreparedStylists.map((s) => (
              <UnpreparedStylistRow
                key={s.id}
                stylist={s}
                disabled={!hasPermission}
              />
            ))}
          </TableBody>
        </Table>
      </CHorizontalScrollHintContainer>
    </Card>
  );
};

const StylistRow: FC<{
  reservationStylist: ReservationStylist;
  index: number;
  disabled?: boolean;
  onDelete(stylistId: number): void;
  onOrderChange(dragIndex: number, hoverIndex: number): void;
}> = React.memo(function StylistRow(props) {
  const classes = useStyles();

  const { index, reservationStylist, disabled, onDelete, onOrderChange } =
    props;
  const stylist = reservationStylist.stylist;

  const ref = useRef<HTMLTableRowElement>(null);
  const { isDragging, drag, drop, preview, handlerId } = useSortableItem(
    'reservationStylist',
    ref,
    index,
    onOrderChange
  );
  preview(drop(ref));

  const opacity = isDragging ? 0.2 : 1;

  const handleDelete = useCallback(() => {
    onDelete(stylist.id);
  }, [onDelete, stylist.id]);

  return (
    <TableRow ref={ref} style={{ opacity }} data-handler-id={handlerId}>
      {disabled ? (
        <TableCell className={classes.cellSort} />
      ) : (
        <TableCell
          ref={drag}
          align="center"
          className={clsx(classes.cellSort, classes.cellSortable)}
        >
          <SVGIcon name="drag-indicator" />
        </TableCell>
      )}
      <TableCell align="left" className={classes.cell}>
        <Grid container spacing={2} alignItems="center" wrap="nowrap">
          <Grid item>
            <CStylistAvatar size={32} stylist={reservationStylist.stylist} />
          </Grid>
          <Grid item>
            <Typography variant="body1" noWrap>
              {reservationStylist.name}
            </Typography>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align="left" className={classes.cellDescription}>
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.description}
          noWrap
        >
          {reservationStylist.description}
        </Typography>
      </TableCell>
      <TableCell align="center" className={classes.cell}>
        <Chip
          size="small"
          variant="outlined"
          color={reservationStylist.isVisible ? 'secondary' : 'default'}
          label={reservationStylist.isVisible ? '掲載中' : '掲載停止中'}
        />
      </TableCell>
      <TableCell align="center" className={classes.cell}>
        <CIconButton
          disabled={disabled}
          linkTo={`/settings/reservation/stylist/${reservationStylist.id}`}
        >
          <SVGIcon name="pen" />
        </CIconButton>
      </TableCell>
      <TableCell align="center" className={classes.cell}>
        <IconButton onClick={handleDelete} disabled={disabled} size="large">
          <SVGIcon name="delete" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
});

const UnpreparedStylistRow: FC<{
  stylist: PlainStylist;
  disabled?: boolean;
}> = React.memo(function StylistCard(props) {
  const classes = useStyles();

  const { stylist, disabled } = props;

  return (
    <TableRow>
      <TableCell className={classes.cellSort} />
      <TableCell align="left" className={classes.cell}>
        <Grid container spacing={1} alignItems="center" wrap="nowrap">
          <Grid item>
            <CStylistAvatar size={32} stylist={stylist} />
          </Grid>
          <Grid item>
            <Typography variant="body1" noWrap>
              {stylist.name}
            </Typography>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align="left" className={classes.cellDescription} colSpan={6}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              未設定
            </Typography>
          </Grid>
          <Grid item>
            <CButton
              linkTo={`/settings/reservation/stylist/new?id=${stylist.id}`}
              disabled={disabled}
            >
              設定
            </CButton>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
});

export default withConfirmDialog(ReservationStylistSettingCard);
