import {
  PermissionKey,
  PermissionRoleEdit,
  PermissionSectionStructure,
  buildPermissionRoleEdit,
  getPermissionDescription,
  getPermissionName,
} from '@karutekun/core/permission-role';
import {
  Box,
  Card,
  Checkbox,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    marginTop: theme.spacing(4),
  },
  card: {
    marginTop: theme.spacing(1),
  },
  listItemText: {
    width: 400,
    paddingRight: theme.spacing(4),
  },
}));

type Props = {
  formId: string;
  defaultData?: PermissionRoleEdit;
  onSubmit(permissionRole: Partial<PermissionRoleEdit>): void;
};

export const PermissionRoleForm: FC<Props> = (props) => {
  const classes = useStyles();

  const { formId, defaultData = buildPermissionRoleEdit(), onSubmit } = props;

  const [data, setData] = useState(buildPermissionRoleEdit(defaultData));

  const handleSubmit = useCallback(() => {
    onSubmit(data);
  }, [data, onSubmit]);

  const togglePermission = useCallback((permission: PermissionKey) => {
    setData((c) => ({
      ...c,
      [permission]: !c[permission],
    }));
  }, []);

  return (
    <ValidatorForm id={formId} onSubmit={handleSubmit}>
      <TextValidator
        variant="standard"
        required
        label="役割名"
        value={data.name}
        placeholder="店長"
        fullWidth={true}
        onChange={(e) => setData((c) => ({ ...c, name: e.target.value }))}
        validators={['required', 'maxStringLength:16']}
        errorMessages={['必須項目です', '最大16文字で入力してください']}
      />
      {PermissionSectionStructure.map((section, key) => (
        <div className={classes.section} key={key}>
          <Typography variant="body1">{section.title}</Typography>
          <Card className={classes.card} elevation={1}>
            <List>
              {section.permissions.map((permission, i) => {
                const edited = data[permission] !== defaultData[permission];
                return (
                  <ListItem
                    key={i}
                    button
                    selected={edited}
                    onClick={() => togglePermission(permission)}
                  >
                    <ListItemText
                      className={classes.listItemText}
                      primary={getPermissionName(permission)}
                      secondary={getPermissionDescription(permission)}
                    />
                    <ListItemSecondaryAction>
                      <Box color="primary.main" display="inline">
                        {edited && '*'}
                      </Box>
                      <Checkbox
                        checked={data[permission]}
                        onClick={() => togglePermission(permission)}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </Card>
        </div>
      ))}
    </ValidatorForm>
  );
};
