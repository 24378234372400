export type CounselingSection = {
  id: number;
  salonId: number;
  order: number;
  title: string;
  description: string | null;
  isActive: boolean;
  createdAt: number;
  updatedAt: number;
  questions: CounselingQuestion[];
};

// カウンセリング編集ページで用いる
export type CounselingItem = {
  id: number;
  order: number;
  title: string;
  description: string | null;
  isNewItem?: boolean; // サーバーに送る際はidを削除して送る。
  isActive: boolean;
  questions: QuestionItem[];
};

export enum QuestionType {
  Checkbox = 1,
  Radiobutton = 2,
  Pulldown = 3,
  TextInput = 4,
}

export const QuestionTypeText = {
  [QuestionType.Checkbox]: '複数選択',
  [QuestionType.Radiobutton]: '択一質問',
  [QuestionType.Pulldown]: 'プルダウン',
  [QuestionType.TextInput]: '記述式',
};

export type CounselingQuestion = {
  id: number;
  counselingId: number;
  order: number;
  type: QuestionType;
  title: string;
  description: string | null;
  isActive: boolean;
  createdAt: number;
  updatedAt: number;
  options: CounselingQuestionOption[];
};

// カウンセリング編集ページで用いる
export type QuestionItem = {
  id: number;
  counselingId: number;
  type: QuestionType;
  order: number;
  title: string;
  description: string | null;
  isActive: boolean;
  isNewItem?: boolean; // サーバーに送る際はidを削除して送る。
  options: OptionItem[];
};

export type CounselingQuestionOption = {
  id: number;
  questionId: number;
  order: number;
  description: string;
  textPlaceholder: string | null;
  isActive: boolean;
  createdAt: number;
  updatedAt: number;
};

// カウンセリング編集ページで用いる
export type OptionItem = {
  id: number;
  questionId: number;
  order: number;
  description: string;
  textPlaceholder: string | null;
  isActive: boolean;
  isNewItem?: boolean; // サーバーに送る際はidを削除して送る。
};

export type PartialCounselingSection = Omit<
  Partial<CounselingSection>,
  'questions'
> & {
  questions: PartialCounselingQuestion[];
};
export type PartialCounselingQuestion = Omit<
  Partial<CounselingQuestion>,
  'options'
> & {
  options: Partial<CounselingQuestionOption>[];
};
