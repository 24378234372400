import { useBoolean } from '@karutekun/shared/util/react-hooks';
import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import {
  deleteCustomer,
  fetchCustomer,
  updateCustomerBasicInformation,
  updateCustomerSalonInformation,
} from '../../../actions/customerAction';
import { pushSnackbar } from '../../../actions/generalAction';
import { dispatchWithErrorHandling } from '../../../actions/helper/dispatchWithErrorHandling';
import { ResourceConflictError } from '../../../actions/requestErrors';
import CTabView, {
  CTabViewRoute,
} from '../../../components_old/molecules/CTabView';
import ChatRoom from '../../../components_old/molecules/ChatRoom';
import CCustomerStatsSummaryCard from '../../../components_old/organisms/CCustomerStatsSummaryCard';
import { CCustomerVisitHistoryList } from '../../../components_old/organisms/visits/visitCardList/CCustomerVisitList';
import { CreateVisitDialog } from '../../../features/visit/components/CreateVisitDialog';
import { useListenChatRoomForCustomer } from '../../../hooks/chat';
import {
  Customer as CustomerModel,
  CustomerSalonInformation as CustomerSalonInformationModel,
  PlainCustomer,
} from '../../../models/customer';
import { checkPermission } from '../../../models/stylist';
import {
  selectChatSelectedSalonId,
  selectCustomerChatRoom,
} from '../../../selectors/chatSelector';
import { selectCustomer } from '../../../selectors/customerSelector';
import {
  selectHasGroupSalon,
  selectMe,
  selectMySalon,
} from '../../../selectors/salonSelector';
import { GlobalState } from '../../../store';
import { useSimpleDialog } from '../../../templates/providers/simpleDialog/simpleDialogContext';
import { useSyncedQueryParams } from '../../../util/hooks/router/useSyncedQueryParams';
import { useThunkDispatch } from '../../../util/hooks/useThunkDispatch';
import { CustomerBasicInfo } from './_components/CustomerBasicInfo';
import CustomerSalonInformation from './_components/CustomerSalonInformation';
import { ProfileCard } from './_components/ProfileCard';

const useStyles = makeStyles(() => ({
  root: {
    padding: '76px 20px 20px 20px',
  },
}));

export const Customer: FC = () => {
  const classes = useStyles();

  const dispatch = useThunkDispatch();
  const navigate = useNavigate();
  const { open } = useSimpleDialog();
  const params = useParams();
  const customerId = Number(params.customerId);
  const customer = useSelector((state: GlobalState) =>
    selectCustomer(state, customerId)
  );
  const salon = useSelector(selectMySalon);
  const chatUnreadNum = useSelector(
    (state: GlobalState) =>
      selectCustomerChatRoom(state, customerId)?.unreadNum ?? 0
  );
  const me = useSelector(selectMe);
  const hasGroupSalon = useSelector(selectHasGroupSalon);

  const [isVisitDialogOpen, setIsVisitDialogOpen] = useBoolean(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchCustomer(customerId));
  }, [customerId, dispatch]);

  const [{ visitListPage }, setParams] = useSyncedQueryParams<{
    visitListPage: number;
  }>({ visitListPage: 0 }, true);

  const handleChangePage = useCallback(
    (page: number) => setParams({ visitListPage: page }),
    [setParams]
  );

  const saveCustomer = useCallback(
    async (updates: Partial<PlainCustomer>) => {
      if (!customer) {
        return;
      }
      const update = async (checkResourceConflict?: boolean) =>
        dispatchWithErrorHandling(
          dispatch,
          updateCustomerBasicInformation(
            { ...customer, ...updates },
            checkResourceConflict
          ),
          { success: 'カルテ情報を更新しました' },
          true
        );
      return new Promise((resolve, reject) => {
        update()
          .then(resolve)
          .catch((e) => {
            if (e instanceof ResourceConflictError) {
              open({
                content:
                  '直前に他スタッフによって更新されています。このまま更新すると、直前の変更内容が失われる可能性があります。このまま更新しますか?',
                onCancel: async () => {
                  reject();
                },
                onOk: async () => {
                  await update(false).then(resolve, reject);
                },
              });
            }
          });
      });
    },
    [customer, dispatch, open]
  );

  const saveCustomerSalonInformation = useCallback(
    async (
      salonId: number,
      customerSalonInformation: CustomerSalonInformationModel,
      userUpdatedAt: number
    ) => {
      if (!customer) {
        return;
      }
      const update = async (userUpdatedAt?: number) => {
        return dispatchWithErrorHandling(
          dispatch,
          updateCustomerSalonInformation(
            customer.id,
            salonId,
            customerSalonInformation,
            userUpdatedAt
          ),
          { success: 'カルテ情報を更新しました' },
          true
        );
      };
      return new Promise<void>((resolve, reject) => {
        update(userUpdatedAt)
          .then(resolve)
          .catch((e) => {
            if (e instanceof ResourceConflictError) {
              open({
                content:
                  '直前に他スタッフによって更新されています。このまま更新すると、直前の変更内容が失われる可能性があります。このまま更新しますか?',
                onCancel: async () => {
                  reject();
                },
                onOk: async () => {
                  await update().then(resolve, reject);
                },
              });
            }
          });
      });
    },
    [customer, dispatch, open]
  );

  const handlePressDelete = useCallback(async () => {
    open({
      content: '来店記録もすべて削除されます。本当にこのカルテを削除しますか？',
      onOk: async () => {
        await dispatch(deleteCustomer(customerId));
        navigate('/customers');
        dispatch(pushSnackbar({ message: 'カルテを削除しました' }));
      },
    });
  }, [customerId, dispatch, navigate, open]);

  const renderScene = (scene: { route: CTabViewRoute; isVisible: boolean }) => {
    if (!customer) {
      return null;
    }

    switch (scene.route.key) {
      case 'basicInfo':
        return (
          <CardContent>
            <CustomerBasicInfo
              customer={customer}
              showPersonalData={checkPermission(me, 'canShowPersonalData')}
              onPressSave={saveCustomer}
              customSetting={salon.customSetting}
            />
          </CardContent>
        );
      case 'salonInformation':
        // 表示/非表示が切り替わる際には強制的にレンダリングさせたいので、
        // 使用されないpropsであるvisibleを渡している
        // redux container は prop が変わらないと component の再レンダリングが起きない
        // 再レンダリングさせないと高さが定まらず、中のUIが崩れたりしちゃう
        return (
          <CardContent>
            <CustomerSalonInformation
              visible={scene.isVisible}
              customer={customer}
              onPressSave={saveCustomerSalonInformation}
            />
          </CardContent>
        );
      case 'chat':
        return <ChatTab customer={customer} isVisible={scene.isVisible} />;
    }

    return null;
  };

  if (!customer) {
    return (
      <div
        style={{
          height: 300,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <CreateVisitDialog
            open={isVisitDialogOpen}
            customerId={customer.id}
            onClose={setIsVisitDialogOpen.setFalse}
          />
          <ProfileCard
            customer={customer}
            onClickCreateKarute={setIsVisitDialogOpen.setTrue}
            onClickDelete={handlePressDelete}
          />
        </Grid>

        <Grid container item xs={12} md={8}>
          <Grid container spacing={2}>
            {hasGroupSalon ? (
              <>
                <Grid item xs={12}>
                  <CCustomerStatsSummaryCard
                    title="来店情報(全サロン)"
                    stats={customer}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CCustomerStatsSummaryCard
                    title="来店情報(当サロン)"
                    stats={customer.mySalonStatistics}
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <CCustomerStatsSummaryCard title="来店情報" stats={customer} />
              </Grid>
            )}

            <Grid item xs={12}>
              <Card style={{ width: '100%' }}>
                <CTabView
                  defaultTabIndex={0}
                  routes={[
                    { key: 'basicInfo', title: '基本情報' },
                    { key: 'salonInformation', title: 'その他情報' },
                    { key: 'chat', title: 'チャット' },
                  ]}
                  renderScene={renderScene}
                  badges={{ chat: chatUnreadNum }}
                />
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" mt={2} mb={-4}>
          来店記録
        </Typography>
        <CCustomerVisitHistoryList
          customerId={customerId}
          page={visitListPage}
          onPageChange={handleChangePage}
        />
      </Grid>
    </div>
  );
};

const ChatTab: FC<{
  customer: CustomerModel;
  isVisible: boolean;
}> = (props) => {
  const { customer, isVisible } = props;

  const selectedSalonId = useSelector(selectChatSelectedSalonId);

  useListenChatRoomForCustomer(selectedSalonId, customer.id);

  return (
    <CardContent>
      <ChatRoom customer={customer} isVisible={isVisible} />
    </CardContent>
  );
};
