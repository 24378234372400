import { connect } from 'react-redux';
import {
  CustomerFilter,
  CustomerSortKey,
  CustomerSortOrder,
  fetchCustomers,
} from '../../../actions/customerAction';
import { setViewSelectCustomerList } from '../../../actions/view/viewSelectCustomerAction';
import { selectCustomer } from '../../../selectors/customerSelector';
import {
  selectHasGroupSalon,
  selectMySalon,
  selectStylists,
} from '../../../selectors/salonSelector';
import { GlobalState, ThunkDispatch } from '../../../store';
import { notEmpty } from '../../../util/common';
import SelectCustomerDialog, {
  DispatchProps,
  StateProps,
} from '../components/SelectCustomerDialog';

const numCustomersPerPage = 20;

const mapStateToProps = (state: GlobalState): StateProps => ({
  numCustomersPerPage,
  salon: selectMySalon(state),
  hasGroupSalon: selectHasGroupSalon(state),
  stylists: selectStylists(state),
  customerListData: state.view.selectCustomer.customerList,
  customers: state.view.selectCustomer.customerList.ids
    .map((id) => selectCustomer(state, id))
    .filter(notEmpty),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  initialize(defaultFilter?: CustomerFilter) {
    const sortKey = 'lastVisitedAt';
    const sortOrder = 'desc';
    dispatch(
      setViewSelectCustomerList({
        sortKey,
        sortOrder,
        ids: [],
        count: 0,
        page: 0,
        isFetching: false,
        filter: defaultFilter || {},
      })
    );
  },

  setUseGroupData(useGroupData: boolean) {
    dispatch(setViewSelectCustomerList({ useGroupData }));
  },
  setPage(page: number) {
    dispatch(setViewSelectCustomerList({ page }));
  },
  setFilter(filter: CustomerFilter, useGroupData: boolean) {
    dispatch(
      setViewSelectCustomerList({
        filter,
        page: 0,
        useGroupData,
      })
    );
  },
  setSort(sortKey: CustomerSortKey, sortOrder: CustomerSortOrder) {
    dispatch(
      setViewSelectCustomerList({
        sortKey,
        sortOrder,
        page: 0,
      })
    );
  },
  fetchCustomers(
    page: number,
    filter: CustomerFilter,
    sortKey: CustomerSortKey,
    sortOrder: CustomerSortOrder
  ) {
    dispatch(
      setViewSelectCustomerList({
        isFetching: true,
      })
    );
    const { controller, request } = dispatch(
      fetchCustomers(
        {
          ...filter,
          limit: numCustomersPerPage,
          offset: page * numCustomersPerPage,
        },
        sortKey,
        sortOrder
      )
    );

    request.then((data) => {
      if (data !== null) {
        const { count, customers } = data;
        dispatch(
          setViewSelectCustomerList({
            count,
            ids: customers.map((c) => c.id),
            isFetching: false,
          })
        );
      }
    });

    return controller;
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectCustomerDialog);
