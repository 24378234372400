import { CustomerLoyaltySegmentText } from '@karutekun/core/customer';
import { Grid, GridProps } from '@mui/material';
import { useSelector } from 'react-redux';
import { CustomerFilter } from '../../../../../actions/customerAction';
import CChip from '../../../../../components_old/atoms/CChip';
import { selectStylistMap } from '../../../../../selectors/salonSelector';

type Props = GridProps & {
  filter: CustomerFilter;
};

export const BroadcastMessageCustomerFilterChips: FC<Props> = (props) => {
  const stylistMap = useSelector(selectStylistMap);

  const { filter, ...gridProps } = props;

  const chipTexts: string[] = [];

  if (filter.salon?.loyaltySegment) {
    chipTexts.push(
      `セグメント: ${CustomerLoyaltySegmentText[filter.salon.loyaltySegment]}`
    );
  }
  if (filter.salon?.lastStylistId) {
    const stylist = stylistMap[filter.salon.lastStylistId];
    chipTexts.push(`前回担当: ${stylist?.name ?? '不明'}`);
  }
  if (filter.salon?.lastVisitedAt) {
    const { from, to } = filter.salon.lastVisitedAt;
    chipTexts.push(`最終来店: ${from ?? '指定なし'} 〜 ${to ?? '指定なし'}`);
  }
  if (filter.birthdayMonth) {
    chipTexts.push(`誕生月: ${filter.birthdayMonth}月`);
  }
  if (chipTexts.length === 0) {
    chipTexts.push('すべてのお客様');
  }

  return (
    <Grid container spacing={1} {...gridProps}>
      {chipTexts.map((text, i) => (
        <Grid item key={i}>
          <CChip label={text} color="default" />
        </Grid>
      ))}
    </Grid>
  );
};
