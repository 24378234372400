import { getPermissionName } from '@karutekun/core/permission-role';
import { VisitResource } from '@karutekun/core/visit';
import { VoucherUtils } from '@karutekun/core/voucher';
import { trpc } from '@karutekun/shared/data-access/api-base';
import { useInvalidateVisits } from '@karutekun/shared/data-access/visit';
import { useBoolean } from '@karutekun/shared/util/react-hooks';
import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { pushSnackbarSuccess } from '../../../../actions/generalAction';
import CButton from '../../../../components_old/atoms/CButton';
import CInformation from '../../../../components_old/atoms/CInformation';
import { checkPermission } from '../../../../models/stylist';
import {
  useSelectMe,
  useSelectMySalon,
} from '../../../../selectors/salonSelector';
import { useThunkDispatch } from '../../../../util/hooks/useThunkDispatch';
import { EditVoucherDialog } from '../../../voucher/components/EditVoucherDialog';
import { VoucherPaymentDialog } from '../../../voucher/components/VoucherPaymentDialog';
import { VoucherPreview } from '../../../voucher/components/VoucherPreview';

type Props = {
  visit: VisitResource;
};

const useStyles = makeStyles((_: Theme) => ({
  header: {
    paddingBottom: 0,
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
}));

export const VoucherFormCard: FC<Props> = (props) => {
  const classes = useStyles();

  const { visit } = props;

  const salon = useSelectMySalon();
  const me = useSelectMe();

  const [isEditDialogOpen, isEditDialogOpenMutations] = useBoolean(false);
  const [isPaymentDialogOpen, isPaymentDialogOpenMutations] = useBoolean(false);

  const canPullbackPayment = checkPermission(me, 'canPullbackPaymentKarute');

  const voucher = visit.voucher;

  const isOtherSalon = visit.salonId !== salon.id;
  const disabled = isOtherSalon;

  const isVoucherPaid = VoucherUtils.isPaid(voucher);
  const isVoucherPayable = VoucherUtils.isPayable(voucher);

  const dispatch = useThunkDispatch();
  const invalidateVisits = useInvalidateVisits();
  const { mutate: pullBackPayment, isPending } =
    trpc.voucher.pullBackPayment.useMutation({
      async onSuccess() {
        await invalidateVisits();
        dispatch(pushSnackbarSuccess('お会計を取り消しました'));
      },
    });

  return (
    <Card>
      <EditVoucherDialog
        open={isEditDialogOpen}
        voucher={voucher}
        onClose={(showPaymentDialog?: boolean) => {
          isEditDialogOpenMutations.setFalse();
          if (showPaymentDialog) {
            isPaymentDialogOpenMutations.setTrue();
          }
        }}
      />
      <VoucherPaymentDialog
        open={isPaymentDialogOpen}
        voucher={voucher}
        onClose={isPaymentDialogOpenMutations.setFalse}
      />

      <CardHeader className={classes.header} title="メニュー" />

      <CardContent>
        <VoucherPreview voucher={voucher} showPayment />
      </CardContent>

      {!disabled && (
        <CardActions className={classes.cardActions}>
          <Button
            startIcon={<SVGIcon name="pen" />}
            disabled={isVoucherPaid}
            variant="outlined"
            color="primary"
            onClick={isEditDialogOpenMutations.setTrue}
          >
            編集
          </Button>
          {isVoucherPaid ? (
            <>
              <CButton
                isLoading={isPending}
                variant="outlined"
                disabled={!canPullbackPayment}
                onClick={() => pullBackPayment(voucher.id)}
              >
                会計を取り消す
              </CButton>
              <CInformation
                type="tooltip"
                content={`${getPermissionName(
                  'canPullbackPaymentKarute'
                )}権限を持ったスタッフのみ会計を取り消せます。`}
              />
            </>
          ) : (
            <Button
              disabled={!isVoucherPayable}
              startIcon={<SVGIcon name="money-bill" />}
              variant="outlined"
              color="primary"
              onClick={isPaymentDialogOpenMutations.setTrue}
            >
              お会計
            </Button>
          )}
        </CardActions>
      )}
    </Card>
  );
};
