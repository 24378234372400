import {
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Grid,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC, useCallback, useEffect, useState } from 'react';
import { Navigate } from 'react-router';
import { dispatchWithErrorHandling } from '../../../../../actions/helper/dispatchWithErrorHandling';
import {
  deletePaymentProvider,
  fetchSubscriptionInfo,
  updateSubscriptionOptions,
} from '../../../../../actions/subscriptionAction';
import CButton from '../../../../../components_old/atoms/CButton';
import CProgress from '../../../../../components_old/atoms/CProgress';
import CTextBox from '../../../../../components_old/atoms/CTextBox';
import CTextLink from '../../../../../components_old/atoms/CTextLink';
import { BasicPlan, OptionLinePlan } from '../../../../../models/salon';
import {
  Subscription,
  SubscriptionStatus,
  isSubscriptionCancellable,
  isSubscriptionEditable,
  isSubscriptionPaymentProviderDeletable,
} from '../../../../../models/subscription';
import { useSelectMySalon } from '../../../../../selectors/salonSelector';
import { withAdminPage } from '../../../../../templates/hoc/AdminPageHOC';
import {
  WithConfirmDialog,
  withConfirmDialog,
} from '../../../../../templates/hoc/ConfirmDialogHOC';
import { formatDate } from '../../../../../util/common';
import { useFlag } from '../../../../../util/hooks/useFlag';
import { useThunkDispatch } from '../../../../../util/hooks/useThunkDispatch';
import { PlanSelectionForm } from '../new/_components/PlanSelectionForm';
import { TemporarilyCanceledDescriptionBox } from './_components/TemporarilyCanceledDescriptionBox';
import UpdatePaymentProviderForm from './_components/UpdatePaymentProviderForm';

type Props = WithConfirmDialog;

const useStyles = makeStyles((theme: Theme) => ({
  mainCard: {
    minHeight: 100,
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: '#fff',
  },
}));

const UpdateSubscription: FC<Props> = (props) => {
  const classes = useStyles();

  const { openConfirmDialog, closeConfirmDialog } = props;

  const salon = useSelectMySalon();
  const dispatch = useThunkDispatch();

  const [isFetching, callWithIsFetching] = useFlag(true);
  const [isSubmitting, callWithIsSubmitting] = useFlag(false);

  const handleDeletePaymentProvider = async (): Promise<void> => {
    await dispatchWithErrorHandling(dispatch, deletePaymentProvider(), {
      success: 'お支払い情報を削除しました',
    });
  };

  useEffect(() => {
    callWithIsFetching(() => dispatch(fetchSubscriptionInfo()));
  }, [callWithIsFetching, dispatch]);

  const handleSubmitPlanChange = useCallback(
    (optionLinePlan: OptionLinePlan) => {
      openConfirmDialog({
        title: '確認',
        description: 'LINEオプションを追加契約します。本当によろしいですか？',
        onOk: async () => {
          callWithIsSubmitting(async () => {
            if (optionLinePlan === OptionLinePlan.Trial) {
              // ここでは新規登録のみなので、Trialで呼ばれることはないが一応ガード
              return;
            }

            await dispatchWithErrorHandling(
              dispatch,
              updateSubscriptionOptions(optionLinePlan),
              { success: 'LINEオプションを追加契約しました' }
            );
          }).finally(() => closeConfirmDialog());
        },
      });
    },
    [callWithIsSubmitting, closeConfirmDialog, dispatch, openConfirmDialog]
  );

  const { subscription } = salon;

  if (!isFetching && !isSubscriptionEditable(subscription)) {
    return <Navigate to="/admin/plan" replace />;
  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={isSubmitting}>
        <CProgress />
      </Backdrop>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">プランの詳細</Typography>
        </Grid>
        <Grid item xs={12}>
          <Card className={classes.mainCard}>
            <CardContent>
              {isFetching ? (
                <CProgress />
              ) : subscription !== null ? (
                <SubscriptionForm
                  basicPlan={salon.basicPlan}
                  optionLinePlan={salon.optionLinePlan}
                  subscription={subscription}
                  onSubmitPlanChange={handleSubmitPlanChange}
                  onDeletePaymentProvider={handleDeletePaymentProvider}
                />
              ) : null}
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Box mt={6} />

      {isSubscriptionCancellable(subscription) && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">解約</Typography>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="body1">
                  プランを解約する場合は以下のページからお手続きください。
                </Typography>
              </CardContent>
              <CardActions>
                <CButton
                  variant="text"
                  linkTo="/admin/plan/subscription/cancel"
                >
                  解約手続きへ
                </CButton>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const SubscriptionForm: FC<{
  basicPlan: BasicPlan;
  optionLinePlan: OptionLinePlan;
  subscription: Subscription;
  onSubmitPlanChange(optionLinePlan: OptionLinePlan): void;
  onDeletePaymentProvider(): Promise<void>;
}> = (props) => {
  const {
    basicPlan,
    subscription,
    onSubmitPlanChange,
    onDeletePaymentProvider,
  } = props;

  const [optionLinePlan, setOptionLinePlan] = useState(props.optionLinePlan);
  const isOptionLinePlanChanged =
    optionLinePlan !== props.optionLinePlan &&
    optionLinePlan !== OptionLinePlan.Trial;

  const handlePlanChange = useCallback(
    (_: BasicPlan, optionLinePlan: OptionLinePlan) => {
      setOptionLinePlan(optionLinePlan);
    },
    []
  );

  const handleSubmitPlanChange = useCallback(() => {
    onSubmitPlanChange(optionLinePlan);
  }, [onSubmitPlanChange, optionLinePlan]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PlanSelectionForm
          basicPlan={basicPlan}
          optionLinePlan={optionLinePlan}
          onChange={handlePlanChange}
          isBasicPlanDisabled={true}
          isOptionLinePlanDisabled={
            // すでにLINEオプションを契約済みのとき、ここでは解約できないようにしている
            props.optionLinePlan !== OptionLinePlan.Trial
          }
        />
        <Collapse in={isOptionLinePlanChanged}>
          <Box p={2} pt={0}>
            {subscription.status === SubscriptionStatus.Scheduled ? (
              <CTextBox>
                ※ お支払い方法が切り替わるまで( 〜
                {formatDate(subscription.nextPaymentAt)}
                )は管理ボード上からオプションを変更することはできません。{'\n'}
                オプションを変更したい場合は、お手数ですが、
                <CTextLink
                  text="こちら"
                  linkTo={process.env.REACT_APP_CONTACT_URL}
                />
                よりお問い合わせください。
              </CTextBox>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmitPlanChange}
              >
                LINEオプションを追加契約する
              </Button>
            )}
          </Box>
        </Collapse>

        <TemporarilyCanceledDescriptionBox subscription={subscription} />
      </Grid>

      <Grid item xs={12}>
        <Box mt={2}>
          <Typography variant="h6" gutterBottom>
            お支払い情報
          </Typography>
        </Box>
        <UpdatePaymentProviderForm
          paymentProvider={subscription.paymentProvider}
          canDelete={isSubscriptionPaymentProviderDeletable(subscription)}
          onDelete={onDeletePaymentProvider}
        />
      </Grid>
    </Grid>
  );
};

export default withConfirmDialog(withAdminPage(UpdateSubscription));
