import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import {
  SemanticColor,
  fontFamily,
  fontWeightMap,
} from '@karutekun/shared-fe/design-token';
import { SVGIcon, SVGName } from '@karutekun/shared-fe/icons/react';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import type { RequireExactlyOne } from 'type-fest';

type BaseProps = {
  isLoading?: boolean;
  openInNewWindow?: boolean;
  children: React.ReactNode;
  linkTo?: string;
  onClick?: () => void;
  variant?: 'contained' | 'outlined' | 'ghost';
  color?: 'primary' | 'secondary' | 'danger';
  disabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
  icon?: SVGName;
  // TODO next.js projectのbuildでこけるのでanyに
  // TODO: 一時的に無効化。後で直したい！
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  css?: any;
};

export type Props = RequireExactlyOne<BaseProps, 'onClick' | 'linkTo'>;

type SurfaceStylePattern = {
  [Variant in NonNullable<Props['variant']>]: {
    [Color in NonNullable<Props['color']>]: {
      wrapper: object;
      text: object;
    };
  };
};

type SizeStylePattern = {
  [Size in NonNullable<Props['size']>]: {
    wrapper: object;
    text: object;
  };
};

const surfaceStylePattern: SurfaceStylePattern = {
  contained: {
    primary: {
      wrapper: {
        'backgroundColor': SemanticColor.backgroundPrimary,
        '&:active': {
          backgroundColor: SemanticColor.backgroundPrimaryActive,
        },
        '&[disabled]': {
          backgroundColor: SemanticColor.backgroundPrimaryDisabled,
        },
        '&[disabled] > span': {
          color: SemanticColor.textPrimaryDisabled,
        },
      },
      text: {
        color: SemanticColor.textWhite,
      },
    },
    secondary: {
      wrapper: {
        'backgroundColor': SemanticColor.backgroundSecondary,
        '&:active': {
          backgroundColor: SemanticColor.backgroundSecondaryActive,
        },
        '&[disabled]': {
          backgroundColor: SemanticColor.backgroundSecondaryDisabled,
        },
        '&[disabled] > span': {
          color: SemanticColor.textSecondaryDisabled,
        },
      },
      text: {
        color: SemanticColor.textWhite,
      },
    },
    // MEMO: 現状使わないけど、将来的に追加されるかも
    danger: {
      wrapper: {},
      text: {},
    },
  },
  outlined: {
    primary: {
      wrapper: {
        'border': `1px solid ${SemanticColor.borderDark}`,
        'backgroundColor': SemanticColor.backgroundWhite,
        '&:active': {
          backgroundColor: SemanticColor.backgroundGray,
        },
        '&[disabled]': {
          border: `1px solid ${SemanticColor.textPrimaryDisabled}`,
          backgroundColor: SemanticColor.backgroundWhite,
        },
        '&[disabled] > span': {
          color: SemanticColor.textPrimaryDisabled,
        },
      },
      text: {
        color: SemanticColor.textPrimaryMain,
      },
    },
    secondary: {
      wrapper: {
        'border': `1px solid ${SemanticColor.textSecondary}`,
        'backgroundColor': SemanticColor.backgroundWhite,
        '&:active': {
          backgroundColor: SemanticColor.backgroundGray,
        },
        '&[disabled]': {
          border: `1px solid ${SemanticColor.textPrimaryDisabled}`,
          backgroundColor: SemanticColor.backgroundWhite,
        },
        '&[disabled] > span': {
          color: SemanticColor.textSecondaryDisabled,
        },
      },
      text: {
        color: SemanticColor.textSecondary,
      },
    },
    danger: {
      wrapper: {
        'border': `1px solid ${SemanticColor.textDanger}`,
        'backgroundColor': SemanticColor.backgroundWhite,
        '&:active': {
          backgroundColor: SemanticColor.backgroundDanger,
        },
        '&[disabled]': {
          border: `1px solid ${SemanticColor.textDangerDisabled}`,
          backgroundColor: SemanticColor.backgroundWhite,
        },
        '&[disabled] > span': {
          color: SemanticColor.textDangerDisabled,
        },
      },
      text: {
        color: SemanticColor.textDanger,
      },
    },
  },
  ghost: {
    primary: {
      wrapper: {
        'backgroundColor': SemanticColor.backgroundWhite,
        '&:active': {
          backgroundColor: SemanticColor.backgroundGray,
        },
        '&[disabled] > span': {
          color: SemanticColor.textPrimaryDisabled,
        },
      },
      text: {
        color: SemanticColor.textPrimaryMain,
      },
    },
    secondary: {
      wrapper: {
        'backgroundColor': SemanticColor.backgroundWhite,
        '&:active': {
          backgroundColor: SemanticColor.backgroundGray,
        },
        '&[disabled] > span': {
          color: SemanticColor.textSecondaryDisabled,
        },
      },
      text: {
        color: SemanticColor.textSecondary,
      },
    },
    danger: {
      wrapper: {
        'backgroundColor': SemanticColor.backgroundWhite,
        '&:active': {
          backgroundColor: SemanticColor.backgroundDanger,
        },
        '&[disabled] > span': {
          color: SemanticColor.textDangerDisabled,
        },
      },
      text: {
        color: SemanticColor.textDanger,
      },
    },
  },
};

const sizeStylePattern: SizeStylePattern = {
  sm: {
    wrapper: { height: 32, borderRadius: 6 },
    text: { fontSize: 13 },
  },
  md: {
    wrapper: { height: 44, borderRadius: 8 },
    text: { fontSize: 16 },
  },
  lg: {
    wrapper: { height: 56, borderRadius: 10 },
    text: { fontSize: 16 },
  },
};

export const CButton: React.FC<Props> = (props) => {
  const {
    children,
    onClick,
    variant = 'contained',
    color = 'primary',
    disabled = false,
    size = 'md',
    icon,
    isLoading,
    linkTo,
    openInNewWindow,
    css = {},
    ...buttonProps
  } = props;

  const basicStyles = useMemo(() => sizeStylePattern[size], [size]);

  const surfaceStyles = useMemo(() => {
    return surfaceStylePattern[variant][color];
  }, [variant, color]);

  return linkTo !== undefined ? (
    <LinkWrapper
      surfaceStyles={surfaceStyles.wrapper}
      basicStyles={basicStyles.wrapper}
      css={css}
      {...buttonProps}
    >
      <StyledLink to={linkTo} target={openInNewWindow ? '_blank' : '_self'}>
        {icon && (
          <Icon
            surfaceStyles={surfaceStyles.text}
            basicStyles={basicStyles.text}
          >
            <SVGIcon name={icon} />
          </Icon>
        )}
        <Text surfaceStyles={surfaceStyles.text} basicStyles={basicStyles.text}>
          {children}
        </Text>
      </StyledLink>
    </LinkWrapper>
  ) : (
    <Button
      surfaceStyles={surfaceStyles.wrapper}
      basicStyles={basicStyles.wrapper}
      css={css}
      onClick={onClick}
      {...buttonProps}
      disabled={disabled}
      data-loading={isLoading}
    >
      {icon && (
        <Icon surfaceStyles={surfaceStyles.text} basicStyles={basicStyles.text}>
          <SVGIcon name={icon} />
        </Icon>
      )}
      {!isLoading ? (
        <Text surfaceStyles={surfaceStyles.text} basicStyles={basicStyles.text}>
          {children}
        </Text>
      ) : (
        <StyledLoading
          surfaceStyles={surfaceStyles.text}
          basicStyles={basicStyles.text}
        >
          <SVGIcon name="loading" size="xl" />
        </StyledLoading>
      )}
    </Button>
  );
};

const Button = styled.button(
  (props: { basicStyles: object; surfaceStyles: object; css: object }) => ({
    'position': 'relative',
    'display': 'flex',
    'flexDirection': 'row',
    'alignItems': 'center',
    'justifyContent': 'center',
    'width': 'max-content',
    'padding': '0 16px',
    'cursor': 'pointer',
    'appearance': 'none',
    'border': 'none',
    'transition': 'all 0.2s cubic-bezier(0.6, 0, 0.6, 0.6)',
    '&:not([disabled],[data-loading="true"])': {
      '&:hover': {
        boxShadow: '0px 2px 15px rgba(63, 91, 117, 0.24)',
      },
    },
    '&:focus-visible': {
      outline: 'none',
      boxShadow: `0px 0px 0px 2px ${SemanticColor.focus}`,
    },
    '&[disabled]': {
      cursor: 'not-allowed',
    },
    '&[data-loading="true"]': {
      cursor: 'wait',
    },
    ...props.basicStyles,
    ...props.surfaceStyles,
    ...props.css,
  })
);

const LinkWrapper = Button.withComponent('div');

const StyledLink = styled(Link)`
  display: contents;
`;

const Text = styled.span(
  (props: { basicStyles: object; surfaceStyles: object }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontWeight: fontWeightMap.bold,
    fontFamily: fontFamily.web,
    ...props.basicStyles,
    ...props.surfaceStyles,
  })
);

const loop = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Icon = styled(Text)`
  margin-right: 4px;
`;

const StyledLoading = styled(Text)`
  animation: ${loop} 1s linear infinite;
`;
