import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import {
  Link,
  LinkProps,
  Theme,
  Typography,
  TypographyProps,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

type OwnProps = LinkProps & {
  text: string;
  linkTo: string;
  typographyProps?: TypographyProps;
};

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
  },
}));

const CTextLink: FC<OwnProps> = (props) => {
  const { text, linkTo, typographyProps, ...etc } = props;
  const classes = useStyles();
  return (
    <Link
      target="_blank"
      rel="noopener"
      href={linkTo}
      className={classes.link}
      {...etc}
    >
      <Typography display="inline" color="secondary" {...typographyProps}>
        {text}
        <SVGIcon name="external-link-alt" size="sm" />
      </Typography>
    </Link>
  );
};

export default CTextLink;
