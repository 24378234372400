import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ChatMessageBodyKarute as ChatMessageBodyKaruteEntity } from '../../models/chatMessageBodyKarute/entity';
import ChatMessageBodyImagesContainer from './ChatMessageBodyImagesContainer';

type Props = {
  chatMessageBody: ChatMessageBodyKaruteEntity;
};

const useStyles = makeStyles((theme: Theme) => ({
  subTitle: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
  },
  image: {
    display: 'block',
    maxWidth: 200,
    maxHeight: 200,
  },
}));

const ChatMessageBodyKarute: FC<Props> = (props) => {
  const classes = useStyles();

  const { urls, text } = props.chatMessageBody;

  return (
    <Grid container spacing={1} direction="column">
      <Grid item>
        <Typography className={classes.subTitle}>
          来店記録を共有しました
        </Typography>
      </Grid>
      <Grid item>
        <ChatMessageBodyImagesContainer urls={urls} />
      </Grid>
      <Grid item>
        <Typography variant="body1">{text}</Typography>
      </Grid>
    </Grid>
  );
};

export default ChatMessageBodyKarute;
