import { dateRange } from '@karutekun/shared/util/datetime';
import { moment } from '@karutekun/shared/util/datetime';
import { getCalendarDateColors } from '@karutekun/shared-fe/calendar';
import {
  Box,
  Popover,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import CStylistAvatar from '../../../../../components_old/atoms/CStylistAvatar';
import {
  DailyBusinessInfoUpdate,
  PlainDailyBusinessInfo,
  PlainStylistShift,
} from '../../../../../models/salonBusinessInfo';
import { WorkType } from '../../../../../models/salonScheduleSettings';
import { MySalonStylist } from '../../../../../models/stylist';
import { ScheduleCapacitySelect } from '../ScheduleCapacitySettingCard/ScheduleCapacitySelect';
import { ScheduleShiftPatternSelect } from '../StylistShiftSettingCard/ScheduleShiftPatternSelect';

type Props = {
  year: number;
  month: number;
  stylists: MySalonStylist[];
  businessInfoMap: DateMap<PlainDailyBusinessInfo>;
  updateMap: DateMap<DailyBusinessInfoUpdate>;
  hasPermission: boolean;
  onChange(date: string, updates: Partial<PlainDailyBusinessInfo>): void;
};

const rowHeight = 100;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'row',
    // 6人分の表示以上はスクロールさせる
    maxHeight: rowHeight * 6,
  },
  header: {
    position: 'sticky',
    height: 60,
    top: 0,
    zIndex: 1,
    display: 'flex',
    flexDirection: 'row',
    borderBottom: `3px ${theme.palette.divider} solid`,
  },
  headerDate: {
    zIndex: 1,
    backgroundColor: '#fff',
    width: '100%',
    height: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: `1px ${theme.palette.divider} solid`,
  },
  headerBusinessInfo: {
    zIndex: 1,
    backgroundColor: '#fff',
    width: '100%',
    height: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    height: rowHeight,
    borderBottom: `1px ${theme.palette.divider} solid`,
  },
  rowHeader: {
    position: 'sticky',
    left: 0,
    width: 120,
    height: 'fit-content',
    backgroundColor: theme.palette.grey[50],
    zIndex: 2,
    borderRight: `1px ${theme.palette.divider} solid`,
  },
  stylistHeaderCell: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  datesContainer: {
    height: 'fit-content',
  },
  cell: {
    position: 'relative',
    width: 90,
    borderRight: `1px ${theme.palette.divider} solid`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cellDisabled: {
    backgroundColor: theme.palette.grey[200],
  },
  cellMarkerModifying: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    borderWidth: '12px 12px 0 0',
    borderColor: '#4caf50 transparent transparent transparent',
    borderStyle: 'solid',
  },
  note: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontSize: theme.typography.caption.fontSize,
  },
  hasNote: {
    color: theme.palette.primary.main,
  },
  noteField: {
    width: 250,
  },
}));

export const ShiftTable: FC<Props> = (props) => {
  const classes = useStyles();

  const {
    year,
    month,
    stylists,
    businessInfoMap,
    updateMap,
    hasPermission,
    onChange,
  } = props;

  const displayedStylists = useMemo(
    () => stylists.filter((s) => s.basicShiftSetting !== null),
    [stylists]
  );

  const dates = useMemo(() => {
    const m = moment()
      .year(year)
      .month(month - 1);
    const from = m.startOf('month').format('YYYY-MM-DD');
    const to = m.endOf('month').format('YYYY-MM-DD');
    return [...dateRange({ from, to })];
  }, [month, year]);

  const handleShiftUpdate = useCallback(
    (stylistId: number, date: string, update: PlainStylistShift) => {
      onChange(date, {
        stylistShift: { [stylistId]: update },
      });
    },
    [onChange]
  );

  return (
    <>
      <div className={classes.container}>
        <div className={classes.rowHeader}>
          <div className={classes.header} />
          {displayedStylists.map((s) => (
            <div
              key={s.id}
              className={clsx(classes.row, classes.stylistHeaderCell)}
            >
              <Box mb={1}>
                <CStylistAvatar stylist={s} />
              </Box>
              <Typography variant="body2" noWrap>
                {s.name}
              </Typography>
            </div>
          ))}
        </div>

        <div className={classes.datesContainer}>
          <div className={classes.header}>
            {dates.map((date) => {
              const str = moment(date).format('D (ddd)');
              const businessHour =
                updateMap[date]?.businessHour ||
                businessInfoMap[date]?.businessHour;
              const { color, backgroundColor } = getCalendarDateColors(date);

              return (
                <div key={date} className={classes.cell}>
                  <div
                    className={classes.headerDate}
                    style={{ color, backgroundColor }}
                  >
                    <Typography variant="body2">{str}</Typography>
                  </div>
                  <div className={classes.headerBusinessInfo}>
                    <Typography variant="body2">
                      {businessHour
                        ? businessHour.isNonBusinessDay
                          ? '休業日'
                          : `${moment(businessHour.openTime, 'HH:mm:ss').format(
                              'H:mm'
                            )} - ${moment(
                              businessHour.closeTime,
                              'HH:mm:ss'
                            ).format('H:mm')}`
                        : '-'}
                    </Typography>
                  </div>
                </div>
              );
            })}
          </div>

          {displayedStylists.map((s) => (
            <StylistRow
              key={s.id}
              dates={dates}
              stylistId={s.id}
              businessInfoMap={businessInfoMap}
              updateMap={updateMap}
              hasPermission={hasPermission}
              onChange={handleShiftUpdate}
            />
          ))}
        </div>
      </div>

      <Box p={1}>
        <Typography variant="body2" color="textSecondary">
          ※ 基本シフトの設定が完了しているスタッフのみ表示されます
        </Typography>
      </Box>
    </>
  );
};

const StylistRow: FC<{
  dates: string[];
  stylistId: number;
  businessInfoMap: DateMap<PlainDailyBusinessInfo>;
  updateMap: DateMap<DailyBusinessInfoUpdate>;
  hasPermission: boolean;
  onChange(stylistId: number, date: string, update: PlainStylistShift): void;
}> = React.memo(function StylistRow(props) {
  const classes = useStyles();

  const {
    dates,
    stylistId,
    businessInfoMap,
    updateMap,
    hasPermission,
    onChange,
  } = props;

  const now = moment();

  const handleChange = useCallback(
    (date: string, update: PlainStylistShift) => {
      onChange(stylistId, date, update);
    },
    [onChange, stylistId]
  );

  return (
    <div className={classes.row}>
      {dates.map((date) => {
        const original = businessInfoMap[date]?.stylistShift[stylistId];
        const updates = updateMap[date]?.stylistShift?.[stylistId];
        const stylistShift = original ? { ...original, ...updates } : undefined;
        const businessHour =
          updateMap[date]?.businessHour || businessInfoMap[date]?.businessHour;

        return (
          <ShiftCell
            key={date}
            stylistShift={stylistShift}
            date={date}
            isNonBusinessDay={Boolean(businessHour?.isNonBusinessDay)}
            modifying={updates !== undefined}
            disabled={!hasPermission || moment(date).endOf('day').isBefore(now)}
            onChange={handleChange}
          />
        );
      })}
    </div>
  );
});

const ShiftCell: FC<{
  date: string;
  stylistShift?: PlainStylistShift;
  isNonBusinessDay: boolean;
  modifying: boolean;
  disabled: boolean;
  onChange(date: string, update: PlainStylistShift): void;
}> = React.memo(
  function ShiftCell(props) {
    const classes = useStyles();
    const {
      date,
      stylistShift,
      isNonBusinessDay,
      modifying,
      disabled,
      onChange,
    } = props;
    const isDayOff = stylistShift?.workType === WorkType.DayOff;

    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    return (
      <div
        className={clsx(classes.cell, {
          [classes.cellDisabled]:
            isDayOff || disabled || isNonBusinessDay || !stylistShift,
        })}
      >
        {modifying && <div className={clsx(classes.cellMarkerModifying)} />}
        {isNonBusinessDay ? (
          <Typography variant="caption" color="textSecondary">
            -
          </Typography>
        ) : stylistShift ? (
          <>
            <ScheduleShiftPatternSelect
              disabled={disabled}
              workShift={stylistShift}
              onChange={(dailyShift) =>
                onChange(date, {
                  ...stylistShift,
                  ...dailyShift,
                })
              }
            />
            <Tooltip title="同時受付可能数">
              <div>
                <ScheduleCapacitySelect
                  disabled={isDayOff || disabled}
                  capacity={stylistShift.capacity}
                  onChange={(capacity) =>
                    onChange(date, { ...stylistShift, capacity })
                  }
                />
              </div>
            </Tooltip>
            <div
              className={clsx(classes.note, {
                [classes.hasNote]: stylistShift.note !== null,
              })}
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              {stylistShift.note === null
                ? 'メモを追加...'
                : `${stylistShift.note.substr(0, 5)}...`}
            </div>
            <Popover
              open={anchorEl !== null}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
            >
              <TextField
                variant="standard"
                autoFocus
                multiline
                disabled={disabled}
                size="small"
                placeholder="メモを追加"
                className={classes.noteField}
                value={stylistShift.note || ''}
                inputProps={{ maxLength: 256 }}
                onChange={(e) => {
                  const note = e.target.value;
                  onChange(date, {
                    ...stylistShift,
                    note: note === '' ? null : note,
                  });
                }}
              />
            </Popover>
          </>
        ) : (
          <Box p={1}>
            <Typography variant="caption" color="textSecondary">
              設定可能範囲外です
            </Typography>
          </Box>
        )}
      </div>
    );
  },
  (prev, next) => _.isEqual(prev, next)
);
