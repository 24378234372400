import { VisitStatus } from '@karutekun/core/visit';
import { trpc } from '@karutekun/shared/data-access/api-base';
import { keepPreviousData } from '@tanstack/react-query';

export function useFetchVisitsByCustomerIdPaginated(
  customerId: number,
  status: VisitStatus | undefined,
  limit: number,
  page: number
) {
  const result = trpc.visit.findVisitsByCustomerId.useQuery(
    { customerId, status, limit, cursor: page * limit },
    { placeholderData: keepPreviousData }
  );
  return {
    ...result,
    data: {
      count: result.data?.count ?? 0,
      visits: result.data?.visits ?? [],
    },
  };
}
