import { ChatMessageBody } from '../../models/chatMessageBody/entity';
import {
  ChatMessageBodyReservation as ChatMessageBodyReservationSchema,
  DateTime,
  ReservationMenu,
  Stylist,
} from './schema';

export class ChatMessageBodyReservation implements ChatMessageBody {
  scheduleId: number;
  datetime: DateTime;
  stylist: Stylist | null;
  reservationMenus: ReservationMenu[];
  memo: string;

  constructor(args: ChatMessageBodyReservationSchema) {
    this.scheduleId = args.scheduleId;
    this.datetime = args.datetime;
    this.stylist = args.stylist;
    this.reservationMenus = args.reservationMenus;
    this.memo = args.memo;
  }
}
