import { useLocation, useResolvedPath } from 'react-router';

/**
 * 現在のコンポーネントのパスの一番最後の / 以降のパーツを返す
 * Tab のアクティブ表示の切り替えなどに使える
 *
 * 現在のコンポーネントのベースパスが /a/b のとき
 * /a/b -> ""
 * /a/b/c -> "c"
 *
 * @returns
 */
export function useResolvedLastPath() {
  const { pathname } = useLocation();
  const { pathname: resolvedPath } = useResolvedPath('');
  const searchFrom = pathname.indexOf(resolvedPath) + resolvedPath.length;
  const n = pathname.indexOf('/', searchFrom);
  if (n === -1) {
    return '';
  }
  return pathname.substring(n + 1);
}
