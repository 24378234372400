import { Route, Routes } from 'react-router';
import { ServiceSettings } from '../../pages/settings/service';
import { StylistSettings } from '../../pages/settings/stylist';
import { CounselingSettings } from './counceling';
import { ReservationSettings } from './reservation';
import { AddReservationMenu, EditReservationMenu } from './reservation/menu';
import {
  AddReservationStylist,
  EditReservationStylist,
} from './reservation/stylist';
import { SalonSettings } from './salon';
import { ScheduleSettings } from './schedule';

export const Settings: FC = () => {
  return (
    <Routes>
      <Route path="/salon" element={<SalonSettings />} />
      <Route path="/stylist/*" element={<StylistSettings />} />
      <Route path="/service" element={<ServiceSettings />} />
      <Route path="/counseling" element={<CounselingSettings />} />
      <Route path="/reservation/*" element={<ReservationSettingsIndex />} />
      <Route path="/schedule/*" element={<ScheduleSettings />} />
    </Routes>
  );
};

const ReservationSettingsIndex: FC = () => {
  return (
    <Routes>
      <Route path="/menu/new" element={<AddReservationMenu />} />
      <Route
        path="/menu/:reservationMenuId"
        element={<EditReservationMenu />}
      />
      <Route path="/stylist/new" element={<AddReservationStylist />} />
      <Route
        path="/stylist/:reservationStylistId"
        element={<EditReservationStylist />}
      />
      <Route path="/*" element={<ReservationSettings />} />
    </Routes>
  );
};
