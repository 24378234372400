import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import { theme } from '@karutekun/shared-fe/react-ui-old';
import {
  AccordionDetails,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  Theme,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles, withStyles } from '@mui/styles';
import React from 'react';
import {
  PaymentMethod,
  PaymentMethodIcon,
} from '../../../models/paymentMethod';
import CMoneyTypography from '../../atoms/CMoneyTypography';
import CPaymentMethodIcon from '../../molecules/CPaymentMethodIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textCenter: {
      textAlign: 'center',
    },
    panelSummary: {
      alignItems: 'center',
    },
    summaryText: {
      marginLeft: theme.spacing(2),
    },
  })
);

const Accordion = withStyles({
  root: {
    'borderTop': `1px solid ${theme.palette.divider}`,
    'boxShadow': 'none',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    'minHeight': 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    'alignItems': 'center',
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

type OwnProps = {
  paymentMethods: PaymentMethod[];
  paymentMethodMap: { [paymentMethodId: string]: number };
  paymentChange: number;
};

const CSalonPaymentMethodSummaryCard: FC<OwnProps> = (props) => {
  const classes = useStyles();

  const { paymentMethods, paymentMethodMap, paymentChange } = props;

  function renderItem(paymentMethod: PaymentMethod, amount: number) {
    return (
      <ListItem key={paymentMethod.id}>
        <ListItemAvatar>
          <CPaymentMethodIcon size={24} paymentMethod={paymentMethod} />
        </ListItemAvatar>
        <ListItemText primary={paymentMethod.name} />
        <ListItemSecondaryAction>
          <CMoneyTypography amount={amount} variant="body1" />
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
  const validPaymentMethods = paymentMethods.filter(
    (paymentMethod) =>
      (paymentMethod.isActive && paymentMethod.isVisible) ||
      paymentMethodMap[paymentMethod.id]
  );

  return (
    <Accordion>
      <AccordionSummary expandIcon={<SVGIcon name="angle-down" />}>
        <CPaymentMethodIcon
          size={24}
          paymentMethod={{ icon: PaymentMethodIcon.Cash }}
        />
        <Typography variant="body1" className={classes.summaryText}>
          支払い内訳
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List dense style={{ width: '100%' }}>
          {validPaymentMethods.map((pm) => {
            const amount = paymentMethodMap[pm.id] ?? 0;

            /**
             * 現金の支払いに関しては、お釣りを差し引いた値を表示したい
             * 現金でのみ利用可能な icon を見て判定している
             */
            if (pm.icon === PaymentMethodIcon.Cash) {
              return (
                <React.Fragment key={pm.id}>
                  {renderItem(pm, amount - paymentChange)}
                  <ListItem dense={true}>
                    <Grid container justifyContent="flex-end" spacing={4}>
                      <Grid item className={classes.textCenter}>
                        <CMoneyTypography variant="body2" amount={amount} />
                        <Typography variant="body2" color="textSecondary">
                          お預かり
                        </Typography>
                      </Grid>
                      <Grid item className={classes.textCenter}>
                        <CMoneyTypography
                          variant="body2"
                          amount={paymentChange}
                        />
                        <Typography variant="body2" color="textSecondary">
                          お釣り
                        </Typography>
                      </Grid>
                    </Grid>
                  </ListItem>
                </React.Fragment>
              );
            } else {
              return renderItem(pm, amount);
            }
          })}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default CSalonPaymentMethodSummaryCard;
