import { useBoolean } from '@karutekun/shared/util/react-hooks';
import { SVGIcon, SVGName } from '@karutekun/shared-fe/icons/react';
import { theme } from '@karutekun/shared-fe/react-ui-old';
import {
  Badge,
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { openSideMenu } from '../../actions/uiAction';
import CDivider from '../../components_old/atoms/CDivider';
import CDebugTool from '../../components_old/organisms/CDebugTool';
import { isAdmin } from '../../models/stylist';
import { selectPendingJoinRequestNum } from '../../selectors/joinRequestSelector';
import { selectMe } from '../../selectors/salonSelector';
import { selectIsSideMenuOpen } from '../../selectors/uiSelector';
import { DebugToolService } from '../../service/debugToolService';
import { useThunkDispatch } from '../../util/hooks/useThunkDispatch';
import { useWidthDown } from '../../util/hooks/useWidth';
import { LoginSalon } from './LoginSalon';

const sidebarWidth = 240;

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    paddingTop: `calc(56px + ${theme.spacing(1)})`,
    whiteSpace: 'nowrap',
    width: sidebarWidth,
  },
  drawerOpen: {
    width: sidebarWidth,
  },
  drawerClose: {
    width: 0,
    overflowX: 'hidden',
  },
  inactiveMenuItem: {
    display: 'flex',
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
  menuItem: {
    'display': 'flex',
    'textDecoration': 'none',
    'color': theme.palette.text.primary,
    '&:focus, &:hover': {
      // TODO 一時的にルールを無効化しています。気づいたベースで直してください
      // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type '50' can't be used to index type 'PaletteColor'.
      backgroundColor: theme.palette.primary[50],
    },
  },
  menuItemActive: {
    // TODO 一時的にルールを無効化しています。気づいたベースで直してください
    // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type '50' can't be used to index type 'PaletteColor'.
    backgroundColor: theme.palette.primary[50],
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  icon: { minWidth: 46, padding: 0 },
  listItemRipple: {
    backgroundColor: theme.palette.primary.main,
  },
  anchorLink: {
    display: 'flex',
    textDecoration: 'none',
  },
  listTitle: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary,
  },
  badge: {
    transform: 'translate(100%, -50%)',
  },
}));

export const SideMenu: FC = () => {
  const classes = useStyles();
  const dispatch = useThunkDispatch();

  const me = useSelector(selectMe);
  const joinRequestsNum = useSelector(selectPendingJoinRequestNum);
  const isOpen = useSelector(selectIsSideMenuOpen);

  const location = useLocation();

  const [isChatOpen, isChatOpenMutations] = useBoolean(
    location.pathname.startsWith('/chat/')
  );
  const [isCustomerReportOpen, isCustomerReportOpenMutations] = useBoolean(
    location.pathname.startsWith('/analytics/customers/')
  );
  const [isSettingsOpen, isSettingsOpenMutations] = useBoolean(
    location.pathname.startsWith('/settings/')
  );
  const [isAdminMenuOpen, isAdminMenuOpenMutations] = useBoolean(
    location.pathname.startsWith('/admin/')
  );

  const isDownXS = useWidthDown('sm');

  const closeSideMenu = useCallback(() => {
    dispatch(openSideMenu(false));
  }, [dispatch]);

  const handleMenuClick = useCallback(() => {
    if (isDownXS) {
      closeSideMenu();
    }
  }, [closeSideMenu, isDownXS]);

  return (
    <Drawer
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: isOpen,
        [classes.drawerClose]: !isOpen,
      })}
      variant={isDownXS ? 'temporary' : 'permanent'}
      classes={{
        paper: clsx(classes.drawer, {
          [classes.drawerOpen]: isOpen,
          [classes.drawerClose]: !isOpen,
        }),
      }}
      onClose={closeSideMenu}
      open={isOpen}
    >
      <LoginSalon />

      <CDivider spacing={0} />

      <List>
        <MenuLink
          title="ダッシュボード"
          path="/"
          end
          icon="home"
          onClick={handleMenuClick}
        />
        <MenuLink
          title="カルテ一覧"
          path="/customers"
          icon="document"
          onClick={handleMenuClick}
        />
        <MenuLink
          title="来店記録一覧"
          path="/visit_histories"
          icon="cut"
          onClick={handleMenuClick}
        />
        <MenuLink
          title="予約台帳"
          path="/schedules"
          icon="calendar-alt"
          onClick={handleMenuClick}
        />
        <ListItem
          button
          onClick={isChatOpenMutations.toggle}
          className={classes.menuItem}
          TouchRippleProps={{ classes: { child: classes.listItemRipple } }}
        >
          <ListItemIcon className={classes.icon}>
            <SVGIcon name="comment-dots" color={theme.palette.text.primary} />
          </ListItemIcon>
          <ListItemText primary="チャット" />
          <SVGIcon
            name={isChatOpen ? 'angle-up' : 'angle-down'}
            color={theme.palette.text.primary}
          />
        </ListItem>
        <Collapse in={isChatOpen} timeout="auto" unmountOnExit>
          <MenuLink
            nested
            title="一斉配信"
            path="/chat/broadcast_messages"
            end
            icon="angle-right"
            onClick={handleMenuClick}
          />
        </Collapse>
      </List>

      <Typography className={classes.listTitle}>分析</Typography>
      <List>
        <MenuLink
          title="店舗分析"
          path="/salon"
          end
          icon="store"
          onClick={handleMenuClick}
        />
        <MenuLink
          title="スタッフ分析"
          path={`/analytics/stylists/${me.id}`}
          end
          icon="chalkboard-teacher"
          onClick={handleMenuClick}
        />
        <MenuLink
          title="メニュー分析"
          path="/analytics/services"
          end
          icon="book-open"
          onClick={handleMenuClick}
        />
        <ListItem
          button
          onClick={isCustomerReportOpenMutations.toggle}
          className={classes.menuItem}
          TouchRippleProps={{ classes: { child: classes.listItemRipple } }}
        >
          <ListItemIcon className={classes.icon}>
            <SVGIcon name="user-friends" color={theme.palette.text.primary} />
          </ListItemIcon>
          <ListItemText primary="顧客分析" />
          <SVGIcon
            name={isCustomerReportOpen ? 'angle-up' : 'angle-down'}
            color={theme.palette.text.primary}
          />
        </ListItem>
        <Collapse in={isCustomerReportOpen} timeout="auto" unmountOnExit>
          <MenuLink
            nested
            title="固定客セグメント"
            path="/analytics/customers/repeat"
            end
            icon="angle-right"
            onClick={handleMenuClick}
          />
          <MenuLink
            nested
            title="固定失客セグメント"
            path="/analytics/customers/repeatlost"
            end
            icon="angle-right"
            onClick={handleMenuClick}
          />
          <MenuLink
            nested
            title="新規失客セグメント"
            path="/analytics/customers/newlost"
            end
            icon="angle-right"
            onClick={handleMenuClick}
          />
          <MenuLink
            nested
            title="初回来店レポート"
            path="/analytics/customers/first"
            end
            icon="angle-right"
            onClick={handleMenuClick}
          />
        </Collapse>
      </List>

      <Typography className={classes.listTitle}>設定</Typography>
      <List>
        <ListItem
          button
          onClick={isSettingsOpenMutations.toggle}
          className={classes.menuItem}
          TouchRippleProps={{ classes: { child: classes.listItemRipple } }}
        >
          <ListItemIcon className={classes.icon}>
            <Badge badgeContent={joinRequestsNum} color="primary" variant="dot">
              <SVGIcon name="cog" color={theme.palette.text.primary} />
            </Badge>
          </ListItemIcon>
          <ListItemText primary="設定" />
          <SVGIcon
            name={isSettingsOpen ? 'angle-up' : 'angle-down'}
            color={theme.palette.text.primary}
          />
        </ListItem>
        <Collapse in={isSettingsOpen} timeout="auto" unmountOnExit>
          <MenuLink
            nested
            title="サロン"
            path="/settings/salon"
            icon="angle-right"
            onClick={handleMenuClick}
          />
          <MenuLink
            nested
            title="スタッフ"
            path="/settings/stylist"
            icon="angle-right"
            badgeNum={joinRequestsNum}
            onClick={handleMenuClick}
          />
          <MenuLink
            nested
            title="メニュー"
            path="/settings/service"
            icon="angle-right"
            onClick={handleMenuClick}
          />
          <MenuLink
            nested
            title="カウンセリング"
            path="/settings/counseling"
            icon="angle-right"
            onClick={handleMenuClick}
          />
          <MenuLink
            nested
            title="予約受付"
            path="/settings/reservation"
            icon="angle-right"
            onClick={handleMenuClick}
          />
          <MenuLink
            nested
            title="予約台帳"
            path="/settings/schedule"
            icon="angle-right"
            onClick={handleMenuClick}
          />
        </Collapse>

        {isAdmin(me) && (
          <>
            <ListItem
              button
              onClick={isAdminMenuOpenMutations.toggle}
              className={classes.menuItem}
              TouchRippleProps={{ classes: { child: classes.listItemRipple } }}
            >
              <ListItemIcon className={classes.icon}>
                <SVGIcon name="shield" color={theme.palette.text.primary} />
              </ListItemIcon>
              <ListItemText primary="管理者メニュー" />
              <SVGIcon
                name={isAdminMenuOpen ? 'angle-up' : 'angle-down'}
                color={theme.palette.text.primary}
              />
            </ListItem>
            <Collapse in={isAdminMenuOpen} timeout="auto" unmountOnExit>
              <MenuLink
                nested
                title="プランとお支払い"
                path="/admin/plan"
                icon="angle-right"
                onClick={handleMenuClick}
              />
            </Collapse>
          </>
        )}
      </List>

      <Typography className={classes.listTitle}>ヘルプ</Typography>
      <List>
        <a
          className={classes.anchorLink}
          href={process.env.REACT_APP_FAQ_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ListItem
            button
            className={clsx(classes.menuItem)}
            TouchRippleProps={{ classes: { child: classes.listItemRipple } }}
          >
            <ListItemIcon className={classes.icon}>
              <SVGIcon
                name="question-circle"
                color={theme.palette.text.primary}
              />
            </ListItemIcon>
            <ListItemText primary="ご利用ガイド" />
          </ListItem>
        </a>
        <a
          className={classes.anchorLink}
          href={process.env.REACT_APP_CONTACT_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ListItem
            button
            className={clsx(classes.menuItem)}
            TouchRippleProps={{ classes: { child: classes.listItemRipple } }}
          >
            <ListItemIcon className={classes.icon}>
              <SVGIcon
                name="question-circle"
                color={theme.palette.text.primary}
              />
            </ListItemIcon>
            <ListItemText primary="お問い合わせ" />
          </ListItem>
        </a>
        {DebugToolService.isEnabled() && (
          <ListItem className={clsx(classes.menuItem)}>
            <CDebugTool />
          </ListItem>
        )}
      </List>
    </Drawer>
  );
};

const MenuLink: FC<{
  title: string;
  path: string;
  end?: boolean;
  icon?: SVGName;
  nested?: boolean;
  badgeNum?: number;
  onClick?(): void;
}> = React.memo(function MenuLink(props) {
  const classes = useStyles();

  const { title, path, end, icon, nested, badgeNum = 0, onClick } = props;

  return (
    <NavLink
      end={end}
      to={path}
      onClick={onClick}
      className={({ isActive }) =>
        isActive
          ? clsx(classes.menuItemActive, classes.menuItem)
          : classes.menuItem
      }
    >
      <ListItem
        button
        TouchRippleProps={{ classes: { child: classes.listItemRipple } }}
        className={clsx(classes.menuItem, { [classes.nested]: nested })}
      >
        {icon && (
          <ListItemIcon className={classes.icon}>
            <SVGIcon name={icon} color={theme.palette.text.primary} />
          </ListItemIcon>
        )}

        <ListItemText
          primary={
            <Badge
              classes={{ badge: classes.badge }}
              badgeContent={badgeNum}
              max={99}
              color="primary"
            >
              {title}
            </Badge>
          }
        />
      </ListItem>
    </NavLink>
  );
});
