import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import { Box, Button, Card, Container, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectUserIsJoinedSalon } from '../../../selectors/userSelector';
import { useRedirect } from '../../../util/hooks/router/useRedirect';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: 76,
  },
  card: {
    padding: theme.spacing(2),
  },
  topLogo: {
    margin: theme.spacing(2),
    height: 48,
    textAlign: 'center',
    color: theme.palette.secondary.dark,
  },
  link: {
    textDecoration: 'none',
  },
}));

export const Setup: FC = () => {
  const classes = useStyles();

  // すでにサロン所属されていたらリダイレクト
  const isJoinedSalon = useSelector(selectUserIsJoinedSalon);
  useRedirect(!!isJoinedSalon);

  return (
    <Container className={classes.root} maxWidth="sm">
      <Card className={classes.card}>
        <Box textAlign="center">
          <div className={classes.topLogo}>
            <SVGIcon
              name="check-circle"
              size="lg"
              style={{ transform: 'scale(2)' }}
            />
          </div>
          <Typography variant="h6" gutterBottom>
            ユーザー登録が完了しました
          </Typography>
          <Typography variant="h6" gutterBottom>
            サロンのセットアップをしてすぐに利用開始しましょう
          </Typography>
        </Box>

        <Box mt={4} textAlign="center">
          <Link to="/signup/setup/new" className={classes.link}>
            <Button size="large" variant="contained" color="primary">
              サロンのセットアップへ
            </Button>
          </Link>
        </Box>

        <Box mt={6} textAlign="center">
          <Typography variant="subtitle1" gutterBottom>
            すでに他のスタッフがサロンを作っていますか？
          </Typography>
          <Link to="/signup/setup/join" className={classes.link}>
            <Button variant="outlined" color="primary">
              作成済みのサロンに所属する
            </Button>
          </Link>
        </Box>
      </Card>
    </Container>
  );
};
