import { OptionsObject, WithSnackbarProps, withSnackbar } from 'notistack';
import * as React from 'react';
import CButton from '../../../components_old/atoms/CButton';
import { SnackbarNotificationItem } from '../../../reducers/generalReducer';

export type OwnProps = {};
export type StateProps = {
  notifications: SnackbarNotificationItem[];
};
export type DispatchProps = {
  removeSnackbar(key: number): void;
};
type Props = WithSnackbarProps & OwnProps & StateProps & DispatchProps;

type OwnState = {};

class SnackbarNotification extends React.Component<Props, OwnState> {
  displayed: number[] = [];

  storeDisplayed = (id: number) => {
    this.displayed = [...this.displayed, id];
  };

  createOptions(item: SnackbarNotificationItem): OptionsObject {
    return {
      key: item.key,
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'bottom',
      },
      action: (
        <React.Fragment>
          {item.linkTo && (
            <CButton
              variant="text"
              color="inherit"
              onClick={() => this.props.closeSnackbar(item.key)}
              linkTo={item.linkTo}
            >
              確認
            </CButton>
          )}
          <CButton
            variant="text"
            color="inherit"
            onClick={() => this.props.closeSnackbar(item.key)}
          >
            閉じる
          </CButton>
        </React.Fragment>
      ),
      variant: item.type,
      onClose: () => this.props.removeSnackbar(item.key),
    };
  }

  shouldComponentUpdate({ notifications: newSnacks = [] }: Props) {
    if (!newSnacks.length) {
      this.displayed = [];
      return false;
    }

    const { notifications: currentSnacks } = this.props;
    let notExists = false;
    for (let i = 0; i < newSnacks.length; i += 1) {
      const newSnack = newSnacks[i];
      if (newSnack.dismissed) {
        this.props.closeSnackbar(newSnack.key);
        this.props.removeSnackbar(newSnack.key);
      }

      if (notExists) {
        continue;
      }
      notExists =
        notExists ||
        !currentSnacks.filter(({ key }) => newSnack.key === key).length;
    }
    return notExists;
  }

  componentDidUpdate() {
    const { notifications = [] } = this.props;

    notifications.forEach((item) => {
      // Do nothing if snackbar is already displayed
      if (this.displayed.includes(item.key)) {
        return;
      }
      // Display snackbar using notistack
      this.props.enqueueSnackbar(item.message, this.createOptions(item));
      // Keep track of snackbars that we've displayed
      this.storeDisplayed(item.key);
    });
  }

  render() {
    return null;
  }
}

export default withSnackbar(SnackbarNotification);
