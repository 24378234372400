import { IVisitImage, VisitImageType } from '../data';
import { groupImagesByType } from './group-images-by-type';

/**
 * 来店のメイン画像となるものを選択する
 * After -> Before -> etc の順で優先して探して１枚探す
 */
export function pickMainImage<T extends Omit<IVisitImage, 'visitId'>>(
  images: T[]
): T | null {
  const imagesByType = groupImagesByType(images);
  for (const type of [
    VisitImageType.After,
    VisitImageType.Before,
    VisitImageType.Other,
  ]) {
    if (imagesByType[type].length > 0) {
      return imagesByType[type][0];
    }
  }
  return null;
}
