import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import {
  Checkbox,
  Grid,
  IconButton,
  Radio,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import * as _ from 'lodash';
import React, { useRef, useState } from 'react';
import {
  DragSourceMonitor,
  DropTargetMonitor,
  useDrag,
  useDrop,
} from 'react-dnd';
import { OptionItem, QuestionType } from '../../../models/counseling';
import {
  WithConfirmDialog,
  withConfirmDialog,
} from '../../../templates/hoc/ConfirmDialogHOC';
import { DragItem, ItemTypes, isMovable } from './dragUtils';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(1),
  },
  required: {
    backgroundColor: theme.palette.error.light,
  },
  extraTextInputContainer: {
    paddingLeft: 60,
    marginBottom: theme.spacing(1),
  },
  handleVertical: {
    width: 30,
    height: '100%',
    display: 'flex',
    cursor: 'move',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const CQuestionOption: FC<
  {
    option: OptionItem;
    index: number;
    type: QuestionType;
    canDelete: boolean;
    isLoading: boolean;
    moveOption: (dragIndex: number, hoverIndex: number) => void;
    updateOption: (
      updatedIndex: number,
      updatedOption: OptionItem | null
    ) => void;
  } & WithConfirmDialog
> = (props) => {
  const {
    option,
    index,
    type,
    canDelete,
    isLoading,
    moveOption,
    updateOption,
    openConfirmDialog,
    closeConfirmDialog,
  } = props;
  const { id, questionId, description, textPlaceholder } = option;

  const classes = useStyles();
  const ref = useRef<HTMLDivElement>(null);
  const [, drop] = useDrop<DragItem>({
    accept: `${ItemTypes.option}:${questionId}`,
    hover(item: DragItem, monitor: DropTargetMonitor) {
      const dragIndex = item.index;
      const hoverIndex = index;

      if (!isMovable(ref, monitor, dragIndex, hoverIndex)) {
        return;
      }
      moveOption(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: `${ItemTypes.option}:${questionId}`,
    item: { id, index },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [isExtraTextFieldVisible, setIsExtraTextFieldVisible] =
    useState<boolean>(textPlaceholder !== null);

  const renderDescriptionTextInput = (placeholder = '選択肢') => {
    return (
      <TextField
        variant="standard"
        className={clsx({
          [classes.required]: !description,
        })}
        required
        label={description ? undefined : `${placeholder}は必須です`}
        inputProps={{ maxLength: 240 }}
        value={description}
        placeholder={placeholder}
        fullWidth
        onChange={(e) => {
          updateOption(index, {
            ...option,
            description: e.target.value || '',
          });
        }}
      />
    );
  };

  // CheckBox, RadioButtonで追加できる自由記述の描画
  const renderExtraTextInput = () => {
    return (
      <TextField
        variant="standard"
        inputProps={{ maxLength: 240 }}
        value={textPlaceholder || ''}
        placeholder="説明(「その他の詳細をご記入ください」等)"
        fullWidth
        onChange={(e) => {
          updateOption(index, {
            ...option,
            textPlaceholder: e.target.value || null,
          });
        }}
      />
    );
  };

  const renderExtraCheckbox = (textPlaceholder: string | null) => {
    return (
      <>
        <Checkbox
          checked={isExtraTextFieldVisible}
          onClick={() => {
            if (textPlaceholder && isExtraTextFieldVisible) {
              openConfirmDialog({
                title: '確認',
                description: '追加質問を本当に削除しますか？',
                onOk: () => {
                  updateOption(index, { ...option, textPlaceholder: null });
                  setIsExtraTextFieldVisible(!isExtraTextFieldVisible);
                  closeConfirmDialog();
                },
              });
            } else {
              setIsExtraTextFieldVisible(!isExtraTextFieldVisible);
            }
          }}
        />
        <Typography variant="caption">記述式質問追加</Typography>
      </>
    );
  };

  const renderOptionItem = (option: OptionItem) => {
    switch (type) {
      case QuestionType.Checkbox: {
        return (
          <div>
            <Grid container>
              <Grid item>
                <Checkbox
                  disabled
                  onClick={() => {
                    // 何もしない
                  }}
                />
              </Grid>
              <Grid item xs>
                {renderDescriptionTextInput()}
              </Grid>
              <Grid item>{renderExtraCheckbox(option.textPlaceholder)}</Grid>
            </Grid>
            {isExtraTextFieldVisible && (
              <Grid container className={classes.extraTextInputContainer}>
                {renderExtraTextInput()}
              </Grid>
            )}
          </div>
        );
      }
      case QuestionType.Radiobutton: {
        return (
          <div>
            <Grid container>
              <Grid item>
                <Radio disabled />
              </Grid>
              <Grid item xs>
                {renderDescriptionTextInput()}
              </Grid>
              <Grid item>{renderExtraCheckbox(option.textPlaceholder)}</Grid>
            </Grid>
            {isExtraTextFieldVisible && (
              <Grid container className={classes.extraTextInputContainer}>
                {renderExtraTextInput()}
              </Grid>
            )}
          </div>
        );
      }
      case QuestionType.Pulldown: {
        return (
          <div style={{ display: 'inline-block', marginLeft: 10 }}>
            {renderDescriptionTextInput()}
          </div>
        );
      }
      case QuestionType.TextInput: {
        return (
          <Grid container>{renderDescriptionTextInput('質問(記述式)')}</Grid>
        );
      }
      default: {
        return;
      }
    }
  };

  const opacity = isDragging ? 0 : 1;
  drop(ref);

  return (
    <Grid
      ref={ref}
      className={classes.container}
      container
      alignItems="center"
      justifyContent="flex-start"
      style={{ opacity }}
    >
      <Grid container ref={preview} style={{ opacity }} alignItems="center">
        <Grid item>
          <div ref={drag} className={classes.handleVertical}>
            <SVGIcon name="grip-lines-vertical" />
          </div>
        </Grid>
        <Grid item xs>
          {renderOptionItem(option)}
        </Grid>
        <Grid item>
          <IconButton
            disabled={isLoading || (!canDelete && !option.isNewItem)}
            onClick={() => {
              openConfirmDialog({
                title: '確認',
                description: '本当に削除しますか？',
                onOk: () => {
                  if (option.isNewItem) {
                    updateOption(index, null);
                  } else {
                    updateOption(index, {
                      ...option,
                      isActive: false,
                      description: option.description || '',
                    });
                    closeConfirmDialog();
                  }
                },
              });
            }}
            size="large"
          >
            <SVGIcon name="times" />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(withConfirmDialog(CQuestionOption), (prev, next) =>
  _.isEqual(prev, next)
);
