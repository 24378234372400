import * as React from 'react';
import CChartPie, { ChartData } from '../atoms/CChartPie';

type OwnProps = {
  averageVisitPeriodMap: { [visitNum: number]: number };
};

const sumBoundary = 3;

class CChartPieAverageVisitPeriod extends React.PureComponent<OwnProps> {
  render() {
    const { averageVisitPeriodMap } = this.props;

    const data: { [averageVisitPeriod: number]: ChartData } = {};
    for (const periodString in averageVisitPeriodMap) {
      const period = Number(periodString);
      if (period < sumBoundary) {
        data[period] = {
          id: period,
          name:
            period === 0
              ? `1ヶ月未満`
              : `${period}ヶ月以上〜${period + 1}ヶ月未満`,
          value: averageVisitPeriodMap[period],
        };
      } else {
        if (!data[sumBoundary]) {
          data[sumBoundary] = {
            id: sumBoundary,
            name: `${sumBoundary}ヶ月以上`,
            value: 0,
          };
        }
        data[sumBoundary].value += averageVisitPeriodMap[period];
      }
    }

    return (
      <CChartPie
        data={Object.values(data)}
        formatter={(v) => `${v}人`}
        sortDataOrder="asc"
      />
    );
  }
}

export default CChartPieAverageVisitPeriod;
