export enum ErrorCode {
  // どのサロンにも所属していない時
  NotJoinedAnySalon = 'NotJoinedAnySalon',

  ResourceConflict = 'ResourceConflict',
  ChatLineReachedMonthlyLimit = 'Chat:LineReachedMonthlyLimit',
  ChatLineBlocked = 'Chat:LineBlocked',
  ScheduleAvailabilityLimit = 'Schedule:AvailabilityLimit',
}

export class ApplicationError extends Error {
  public code: ErrorCode | '';
  public status: number;

  constructor(
    messageForUser?: string,
    status = 500,
    code: ErrorCode | '' = ''
  ) {
    super(messageForUser || 'エラーが発生しました');
    this.status = status;
    this.code = code;
  }
}
export class UnknownServerError extends ApplicationError {
  constructor() {
    super('エラーが発生しました', 500);
  }
}
export class MaintenanceError extends ApplicationError {
  constructor() {
    super(
      '現在メンテナンス中です。しばらくしてから再度アクセスしてください。',
      503
    );
  }
}
export class VersionUpdateRequiredError extends ApplicationError {
  constructor() {
    super('新しいアップデートがあります', 400);
  }
}
export class ForceSignOutError extends ApplicationError {
  constructor() {
    super('ログアウトが必要です', 400);
  }
}
export class AccessForbiddenError extends ApplicationError {
  constructor() {
    super('管理者からアクセスが制限されています', 403);
  }
}
// 更新前に他ユーザーによって更新済の場合などに使用
export class ResourceConflictError extends ApplicationError {
  constructor() {
    super('他の端末による更新があります', 409, ErrorCode.ResourceConflict);
  }
}
