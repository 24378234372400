import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M13.6 8.85c-2.396 0-4.75 2.332-4.75 5.75 0 3.42 2.354 5.75 4.75 5.75 2.397 0 4.75-2.33 4.75-5.75 0-3.418-2.353-5.75-4.75-5.75M5.35 14.6c0-4.865 3.468-9.25 8.25-9.25 4.784 0 8.25 4.385 8.25 9.25 0 4.866-3.466 9.25-8.25 9.25-4.782 0-8.25-4.384-8.25-9.25m33.862-7.54a1.75 1.75 0 0 1 .155 2.47L11.682 40.906a1.75 1.75 0 0 1-2.624-2.316L36.742 7.214a1.75 1.75 0 0 1 2.47-.154M29.65 33.4c0-3.419 2.354-5.75 4.75-5.75 2.397 0 4.75 2.331 4.75 5.75 0 3.42-2.353 5.75-4.75 5.75-2.396 0-4.75-2.33-4.75-5.75m4.75-9.25c-4.783 0-8.25 4.385-8.25 9.25s3.467 9.25 8.25 9.25c4.784 0 8.25-4.385 8.25-9.25s-3.466-9.25-8.25-9.25"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgPercent = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgPercent);
export default Memo;
