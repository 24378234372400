import { Button, Radio, Theme, Typography, alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

type Props = {
  primary: string;
  secondary?: string;
  checked: boolean;
  disabled?: boolean;
  onClick?(): void;
  className?: string;
};

const useStyles = makeStyles((theme: Theme) => {
  const buttonSelected = {
    borderColor: theme.palette.secondary.main,
    backgroundColor: alpha(theme.palette.secondary.light, 0.03),
  };

  return {
    planBox: {
      'display': 'flex',
      'flexDirection': 'row',
      'justifyContent': 'flex-start',
      'textAlign': 'left',
      'border': `1px solid ${theme.palette.grey[300]}`,
      'borderRadius': 4,
      'padding': theme.spacing(1),
      'cursor': 'pointer',
      '&:hover': buttonSelected,
    },
    rippleVisible: {
      color: theme.palette.secondary.main,
    },
    planBoxSelected: buttonSelected,
    planBoxDisabled: {
      cursor: 'default',
    },
  };
});

export const PlanSelectionButton: FC<Props> = (props) => {
  const classes = useStyles();
  const { primary, secondary, checked, disabled, onClick, className } = props;
  return (
    <Button
      TouchRippleProps={{
        classes: { rippleVisible: classes.rippleVisible },
      }}
      className={clsx(className, classes.planBox, {
        [classes.planBoxSelected]: checked,
        [classes.planBoxDisabled]: disabled,
      })}
      onClick={onClick}
      disabled={disabled}
    >
      <Radio checked={checked} disabled={disabled} />
      <div>
        <Typography variant="body1" color="textPrimary">
          {primary}
        </Typography>
        {secondary && (
          <Typography variant="body2" color="textSecondary">
            {secondary}
          </Typography>
        )}
      </div>
    </Button>
  );
};
