import { ICustomer } from '@karutekun/core/customer';
import { DiscountType, ServiceType } from '@karutekun/core/salon-service';
import { TaxRoundingType } from '@karutekun/core/tax';
import { RelKey, StrictRel } from '@karutekun/shared/util/entity';

export enum VoucherStatus {
  // TODO: visit[Voucherの掃除] αリリース後は Temporary のスタータスはなくなる
  Temporary = 0,
  InDraft = 1,
  Paid = 2,
}

interface _IVoucher {
  id: number;
  publicId: string;
  salonId: number;
  customerId: number | null;
  stylistId: number | null;
  isShimei: boolean;
  sales: number;
  taxAmount: number;
  taxRoundingType: TaxRoundingType;
  paymentChange: number;
  decreasedAdvanceReceived: number;
  advanceReceived: number;
  status: VoucherStatus;
  createdAt: number;
  updatedAt: number;
  soldAt: number;
  paidAt: number;
  userUpdatedAt: number;

  lines: IVoucherLine[];
  paymentMethods: IVoucherPaymentMethod[];
  discounts: IVoucherDiscount[];

  customer?: ICustomer<'lineSettings'> | null;
}

export type VoucherRelationKeys = RelKey<_IVoucher>;
export type IVoucher<K extends VoucherRelationKeys = never> = StrictRel<
  _IVoucher,
  K
>;

export interface IVoucherLine {
  id: number;
  salonId: number;
  voucherId: number;
  serviceId: number;
  serviceType: ServiceType;
  originalUnitPrice: number;
  adjustedUnitPrice: number;
  approxSellingUnitPrice: number;
  isTaxIncluded: boolean;
  taxRate: number;
  quantity: number;
  sales: number;
  taxAmount: number;
  note: string | null;
  createdAt: number;
  updatedAt: number;
  stylists: IVoucherLineStylist[];
  discounts: IVoucherLineDiscount[];
}

export interface IVoucherPaymentMethod {
  voucherId: number;
  paymentMethodId: number;
  salonId: number;
  amount: number;
  createdAt: number;
  updatedAt: number;
}
export interface IVoucherDiscount {
  id: number;
  salonId: number;
  voucherId: number;
  discountId: number;
  type: DiscountType;
  value: number;
  order: number;
  approxDiscountAmount: number;
  approxDiscountTaxAmount: number;
  createdAt: number;
  updatedAt: number;
}
export interface IVoucherLineStylist {
  voucherLineId: number;
  stylistId: number;
  salonId: number;
  isShimei: boolean;
  sales: number;
  taxAmount: number;
  createdAt: number;
  updatedAt: number;
}
export interface IVoucherLineDiscount {
  id: number;
  salonId: number;
  voucherLineId: number;
  discountId: number;
  type: DiscountType;
  value: number;
  order: number;
  approxDiscountAmount: number;
  approxDiscountTaxAmount: number;
  createdAt: number;
  updatedAt: number;
}
