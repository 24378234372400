import { ActionType, getType } from 'typesafe-actions';
import * as actions from '../../actions/view/viewSelectCustomerAction';
import { CustomerListData } from '../types';

export type ViewSelectCustomerState = {
  customerList: CustomerListData;
};

const initialState: ViewSelectCustomerState = {
  customerList: {
    ids: [],
    count: 0,
    page: 0,
    isFetching: false,
    useGroupData: true,
    sortKey: 'lastVisitedAt',
    sortOrder: 'desc',
    filter: {},
  },
};

export type viewSelectCustomerActions = ActionType<typeof actions>;

function viewSelectCustomerReducer(
  state: ViewSelectCustomerState = initialState,
  action: viewSelectCustomerActions
) {
  switch (action.type) {
    case getType(actions.setViewSelectCustomerList): {
      const { listData } = action.payload;
      return {
        ...state,
        customerList: {
          ...state.customerList,
          ...listData,
        },
      };
    }
    default:
      return state;
  }
}

export default viewSelectCustomerReducer;
