import {
  VisitHistoryFilter,
  VisitHistorySort,
} from '@karutekun/shared/data-access/visit';
import { memo, useCallback, useMemo } from 'react';
import { CVisitHistoryList } from '../../../../components_old/organisms/visits/visitCardList/CVisitHistoryList';

type ListData = {
  filter?: VisitHistoryFilter;
  sort?: VisitHistorySort;
  page?: number;
};

type Props = {
  stylistId: number;
  from: string;
  to: string;
  listData: ListData;
  onChangeListData: (listData: ListData) => void;
};

export const VisitListSection: FC<Props> = memo(
  ({ stylistId, listData, from, to, onChangeListData }) => {
    const { filter, page, sort } = listData;

    const mergedFilter = useMemo(
      () => ({
        ...filter,
        assignedStylistId: stylistId,
        startedAt: { from, to },
      }),
      [filter, from, stylistId, to]
    );

    const handleChangeFilter = useCallback(
      (filter: VisitHistoryFilter) =>
        onChangeListData({ ...listData, filter, page: 0 }),
      [listData, onChangeListData]
    );
    const handleChangeSort = useCallback(
      (sort: VisitHistorySort) =>
        onChangeListData({ ...listData, sort, page: 0 }),
      [listData, onChangeListData]
    );
    const handleChangePage = useCallback(
      (page: number) => onChangeListData({ ...listData, page }),
      [listData, onChangeListData]
    );

    return (
      <CVisitHistoryList
        filter={mergedFilter}
        sort={sort}
        page={page}
        numVisitsPerPage={12}
        disabledFilters={['assignedStylistId', 'startedAt']}
        onFilterChange={handleChangeFilter}
        onSortChange={handleChangeSort}
        onPageChange={handleChangePage}
      />
    );
  }
);
