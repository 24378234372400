import { distributeTotal } from '@karutekun/shared/util/math';

/**
 * voucherLine の担当スタッフの売上を計算する
 * 現時点では、売上は均等に各スタッフに割り振るようになっている
 * スタッフごとに売上の割り振り比率を変える場合はここでそれを考慮してください
 */
export function calculateVoucherLineStylistSales(
  line: Readonly<{ sales: number; taxAmount: number; stylists: unknown[] }>
): { sales: number; taxAmount: number }[] {
  const stylistNum = line.stylists.length;
  if (stylistNum === 0) {
    return [];
  }

  const ratioList = new Array(stylistNum).fill(1 / stylistNum);
  const afterSalesList = distributeTotal(line.sales, ratioList);
  const afterTaxList = distributeTotal(line.taxAmount, ratioList);

  return line.stylists.map((_, i) => ({
    sales: afterSalesList[i],
    taxAmount: afterTaxList[i],
  }));
}
