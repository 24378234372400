import {
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { pushSnackbar } from '../../../../../actions/generalAction';
import {
  fetchBusinessHourSettings,
  updateBusinessHourSettings,
} from '../../../../../actions/salon/scheduleSettingsAction';
import CButton from '../../../../../components_old/atoms/CButton';
import CProgressOverlay from '../../../../../components_old/atoms/CProgressOverlay';
import CTimeRange from '../../../../../components_old/molecules/CTimeRange';
import {
  BusinessHour,
  OverwriteOption,
} from '../../../../../models/salonScheduleSettings';
import { checkPermission } from '../../../../../models/stylist';
import { useSelectMe } from '../../../../../selectors/salonSelector';
import { selectBusinessHourSetting } from '../../../../../selectors/scheduleSettingsSelector';
import { DateType, DateTypeNameMap, DateTypes } from '../../../../../util/date';
import { useFlag } from '../../../../../util/hooks/useFlag';
import { useThunkDispatch } from '../../../../../util/hooks/useThunkDispatch';
import { OverwriteConfirmationDialog } from './OverwriteConfirmationDialog';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    position: 'relative',
  },
  table: {
    width: '100%',
  },
  cell: {
    minWidth: 80,
    padding: theme.spacing(1),
  },
  cellDisabled: {
    backgroundColor: theme.palette.grey[100],
  },
  saveArea: {
    textAlign: 'right',
    padding: theme.spacing(2),
  },
}));

export const BusinessHourSettingCard: FC = () => {
  const classes = useStyles();

  const me = useSelectMe();
  const selectedBusinessHourSetting = useSelector(selectBusinessHourSetting);
  const dispatch = useThunkDispatch();

  const hasPermission = checkPermission(me, 'canUpdateScheduleSetting');

  const [isFetching, callWithFetching] = useFlag(false);
  const [isEdited, setIsEdited] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [businessHourSetting, setBusinessHourSetting] = useState(
    selectedBusinessHourSetting
  );

  useEffect(() => {
    callWithFetching(async () => await dispatch(fetchBusinessHourSettings()));
  }, [callWithFetching, dispatch]);

  useEffect(() => {
    setBusinessHourSetting(selectedBusinessHourSetting);
  }, [selectedBusinessHourSetting]);

  const handleChange = useCallback(
    (dateType: DateType, businessHour: BusinessHour) => {
      setIsEdited(true);
      setBusinessHourSetting({
        ...businessHourSetting,
        [dateType]: businessHour,
      });
    },
    [businessHourSetting]
  );

  const handleClickSave = useCallback(() => setIsConfirmDialogOpen(true), []);
  const handleCancel = useCallback(() => setIsConfirmDialogOpen(false), []);
  const handleOk = useCallback(
    (option: OverwriteOption) => {
      setIsConfirmDialogOpen(false);
      callWithFetching(async () => {
        const updated = await dispatch(
          updateBusinessHourSettings(businessHourSetting, option)
        );

        if (updated) {
          dispatch(
            pushSnackbar({
              message: '営業時間設定を保存しました',
              type: 'success',
            })
          );
        }
      }).then(() => setIsEdited(false));
    },
    [businessHourSetting, callWithFetching, dispatch]
  );

  return (
    <Card className={classes.card}>
      {isFetching && <CProgressOverlay />}

      <OverwriteConfirmationDialog
        open={isConfirmDialogOpen}
        description="営業時間設定を更新します。設定済みの毎月の営業情報を同時に更新しますか？"
        onOk={handleOk}
        onCancel={handleCancel}
      />

      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="center" className={classes.cell}>
              曜日
            </TableCell>
            <TableCell align="center" className={classes.cell}>
              営業時間
            </TableCell>
            <TableCell align="center" className={classes.cell}>
              定休日
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {DateTypes.map((dateType) => {
            const businessHour = businessHourSetting[dateType];
            return (
              <BusinessHourTableRow
                key={dateType}
                dateType={dateType}
                businessHour={businessHour}
                disabled={!hasPermission}
                onChange={handleChange}
              />
            );
          })}
        </TableBody>
      </Table>

      {hasPermission && (
        <div className={classes.saveArea}>
          <CButton
            isLoading={isFetching}
            disabled={!isEdited}
            onClick={handleClickSave}
          >
            確認
          </CButton>
        </div>
      )}
    </Card>
  );
};

const BusinessHourTableRow: FC<{
  dateType: DateType;
  businessHour: BusinessHour;
  disabled?: boolean;
  onChange(dateType: DateType, businessHour: BusinessHour): void;
}> = React.memo(function BusinessHourTableRow(props) {
  const classes = useStyles();
  const { dateType, businessHour, disabled, onChange } = props;

  const cellClassName = clsx(classes.cell, {
    [classes.cellDisabled]: businessHour.isNonBusinessDay,
  });

  const handleTimeChange = useCallback(
    (from: string, to: string) => {
      onChange(dateType, {
        ...businessHour,
        openTime: from,
        closeTime: to,
      });
    },
    [businessHour, dateType, onChange]
  );
  const handleCheckClick = useCallback(() => {
    onChange(dateType, {
      ...businessHour,
      isNonBusinessDay: !businessHour.isNonBusinessDay,
    });
  }, [businessHour, dateType, onChange]);

  return (
    <TableRow>
      <TableCell align="center" className={cellClassName}>
        {DateTypeNameMap[dateType]}
      </TableCell>
      <TableCell align="center" className={cellClassName}>
        <CTimeRange
          disabled={disabled || businessHour.isNonBusinessDay}
          from={businessHour.openTime}
          to={businessHour.closeTime}
          minuteInterval={30}
          onChange={handleTimeChange}
        />
      </TableCell>
      <TableCell align="center" className={cellClassName}>
        <Checkbox
          disabled={disabled}
          checked={businessHour.isNonBusinessDay}
          onClick={handleCheckClick}
        />
      </TableCell>
    </TableRow>
  );
});
