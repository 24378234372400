import { IVisit, VisitSource } from '../data';

export function visitSourceText(
  source: IVisit['source'],
  createdStylistName: Nullable<string>
): string {
  switch (source) {
    case VisitSource.Line:
      return 'LINE';
    case VisitSource.Web:
      return 'Web';
    case VisitSource.Staff:
      return `${createdStylistName ?? '?'}が作成`;
  }
}
