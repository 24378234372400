import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import React from 'react';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

export const withStripe = <OriginalProps extends {}>(
  WrappedComponent: React.ComponentType<OriginalProps>
) => {
  return function WithStripeHOC(props: OriginalProps) {
    return (
      <Elements stripe={stripePromise}>
        <WrappedComponent {...props} />
      </Elements>
    );
  };
};
