export function buildWithDefaults<T extends Record<string, unknown>>(
  defaults: T,
  overwrite: Partial<T>
): T {
  return Object.keys(overwrite).reduce(
    (prev, current) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (prev as any)[current] = overwrite[current];
      return prev;
    },
    { ...defaults }
  );
}
