import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M11.533 12.173C13.318 7.97 17.627 4.05 24 4.05c6.879 0 12.745 4.632 14.134 12.108 1.69.234 3.49.758 5.016 1.727 2.054 1.306 3.6 3.432 3.6 6.5 0 2.948-1.263 5.155-3.349 6.547-1.985 1.326-4.563 1.818-7.151 1.818H29.6a1.75 1.75 0 1 1 0-3.5h6.65c2.225 0 4.021-.436 5.208-1.228 1.086-.726 1.792-1.826 1.792-3.637 0-1.691-.773-2.78-1.978-3.545-1.286-.818-3.056-1.238-4.759-1.322a1.75 1.75 0 0 1-1.654-1.566C34.173 11.376 29.469 7.55 24 7.55c-5.162 0-8.455 3.399-9.534 6.764a1.75 1.75 0 0 1-1.5 1.208c-2.414.23-4.507.94-5.955 2.076-1.387 1.087-2.261 2.614-2.261 4.792 0 2.172.913 3.82 2.404 4.97 1.532 1.183 3.755 1.89 6.346 1.89h4.9a1.75 1.75 0 1 1 0 3.5h-4.9c-3.184 0-6.211-.864-8.485-2.618C2.7 28.344 1.25 25.688 1.25 22.39c0-3.293 1.4-5.821 3.601-7.547 1.862-1.46 4.23-2.3 6.682-2.67M24 19.45c.966 0 1.75.783 1.75 1.75v16.78l2.613-2.61a1.75 1.75 0 1 1 2.474 2.477l-5.6 5.591a1.75 1.75 0 0 1-2.473 0l-5.6-5.591a1.75 1.75 0 0 1 2.473-2.477l2.613 2.61V21.2c0-.967.784-1.75 1.75-1.75"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgCloudDownload = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgCloudDownload);
export default Memo;
