import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import {
  Avatar,
  Box,
  Button,
  Card,
  Collapse,
  Container,
  Fade,
  Grid,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { dispatchWithErrorHandling } from '../../../../actions/helper/dispatchWithErrorHandling';
import {
  cancelSalonRequest,
  sendSalonRequest,
} from '../../../../actions/salonJoinRequestAction';
import {
  fetchUserRequestingSalon,
  findSalonByKeyword,
} from '../../../../actions/userAction';
import { Images } from '../../../../assets/images';
import CButton from '../../../../components_old/atoms/CButton';
import CProgress from '../../../../components_old/atoms/CProgress';
import { SalonMinimum } from '../../../../models/salon';
import {
  selectUserIsJoinedSalon,
  selectUserRequestingSalon,
} from '../../../../selectors/userSelector';
import { useRedirect } from '../../../../util/hooks/router/useRedirect';
import { useFlag } from '../../../../util/hooks/useFlag';
import { useThunkDispatch } from '../../../../util/hooks/useThunkDispatch';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: 76,
  },
  card: {
    padding: theme.spacing(2),
  },
  topLogo: {
    margin: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 48,
    height: 48,
    color: theme.palette.primary.dark,
  },
  header: {
    marginBottom: theme.spacing(4),
  },
  salonImage: {
    width: 86,
    height: 86,
    marginBottom: theme.spacing(1),
  },
  findKeywordField: {
    margin: theme.spacing(2),
    maxWidth: 200,
  },
  link: {
    textDecoration: 'none',
  },
}));

export const SetupJoin: FC = () => {
  const classes = useStyles();
  const requestingSalon = useSelector(selectUserRequestingSalon);

  const dispatch = useThunkDispatch();

  // すでにサロン所属しているならリダイレクト
  const isJoinedSalon = useSelector(selectUserIsJoinedSalon);
  useRedirect(!!isJoinedSalon);

  const [isFetching, callWithFetching] = useFlag(false);
  const [isInitialized, setIsInitialized] = useState(false);

  // サロン検索結果
  // undefined -> 初期値。未検索状態
  // null -> 検索結果が見つからなかった場合
  const [findResult, setFindResult] = useState<SalonMinimum | null | undefined>(
    undefined
  );
  const [findKeyword, setFindKeyword] = useState('');

  useEffect(() => {
    dispatchWithErrorHandling(dispatch, fetchUserRequestingSalon()).then(() => {
      setIsInitialized(true);
    });
  }, [dispatch]);

  const handleCancelRequest = useCallback(async () => {
    if (requestingSalon) {
      await callWithFetching(async () => {
        await dispatchWithErrorHandling(
          dispatch,
          cancelSalonRequest(requestingSalon.id),
          {
            success: '所属申請を取り消しました',
          }
        );
      });
      setFindResult(undefined);
      setFindKeyword('');
    }
  }, [callWithFetching, dispatch, requestingSalon]);

  const handleFindSalon = useCallback(
    async (findKeyword: string) => {
      const result = await callWithFetching(async () => {
        return await dispatchWithErrorHandling(
          dispatch,
          findSalonByKeyword(findKeyword)
        );
      });
      setFindResult(result);
    },
    [callWithFetching, dispatch]
  );

  const handleSendRequest = useCallback(async () => {
    if (findResult) {
      await callWithFetching(async () => {
        await dispatchWithErrorHandling(
          dispatch,
          sendSalonRequest(findResult.id),
          {
            success: '所属申請を送りました',
          }
        );
      });
    }
  }, [callWithFetching, dispatch, findResult]);

  return (
    <Container className={classes.root} maxWidth="sm">
      <Card className={classes.card}>
        {!isInitialized ? (
          <CProgress />
        ) : requestingSalon ? (
          <Box textAlign="center">
            <div className={classes.topLogo}>
              <SVGIcon
                name="group-add"
                size="lg"
                style={{ transform: 'scale(2)' }}
              />
            </div>
            <Typography variant="h6" className={classes.header}>
              以下のサロンに所属申請をしています
            </Typography>

            <Typography variant="body1" gutterBottom>
              管理者に所属申請を許可してもらいましょう
            </Typography>

            <SalonPreview salon={requestingSalon} />
            <Button
              color="primary"
              variant="contained"
              onClick={() => window.document.location.reload()}
            >
              再読み込み
            </Button>
            <Box mt={2}>
              <Button variant="outlined" onClick={handleCancelRequest}>
                申請を取り消す
              </Button>
            </Box>
          </Box>
        ) : (
          <Box textAlign="center">
            <div className={classes.topLogo}>
              <SVGIcon
                name="group-add"
                size="lg"
                style={{ transform: 'scale(2)' }}
              />
            </div>
            <Typography variant="h6" className={classes.header}>
              作成済みのサロンに所属します
            </Typography>

            <Collapse in={!findResult}>
              <Fade in={!findResult}>
                <form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    handleFindSalon(findKeyword);
                  }}
                >
                  <Typography variant="body1" gutterBottom>
                    管理者よりサロンの「秘密の言葉」を教わりましょう
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        variant="standard"
                        className={classes.findKeywordField}
                        label="秘密の言葉を入力してください"
                        value={findKeyword}
                        onChange={(e) => setFindKeyword(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CButton
                        isLoading={isFetching}
                        disabled={findKeyword === ''}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        検索する
                      </CButton>
                      <Box mt={4}>
                        <Link to="/signup" className={classes.link}>
                          <Button color="primary">戻る</Button>
                        </Link>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              </Fade>
            </Collapse>

            <Box mt={2}>
              {findResult ? (
                <>
                  <SalonPreview salon={findResult} />
                  <CButton
                    isLoading={isFetching}
                    variant="contained"
                    onClick={handleSendRequest}
                  >
                    このサロンに所属申請する
                  </CButton>
                  <Box mt={2}>
                    <Button
                      color="primary"
                      onClick={() => setFindResult(undefined)}
                    >
                      キャンセル
                    </Button>
                  </Box>
                </>
              ) : findResult === null ? (
                <Typography color="error">
                  お探しのサロンは見つかりませんでした。秘密の言葉が正しいか確認してください。
                </Typography>
              ) : null}
            </Box>
          </Box>
        )}
      </Card>
    </Container>
  );
};

const SalonPreview: FC<{ salon: SalonMinimum }> = (props) => {
  const classes = useStyles();
  const { salon } = props;
  return (
    <Box display="flex" flexDirection="column" alignItems="center" m={4}>
      <Avatar
        className={classes.salonImage}
        src={salon.imageUrl ?? Images.common.salonNoImage}
      />
      <Typography variant="h6">{salon.name}</Typography>
    </Box>
  );
};
