import { useArray } from '@karutekun/shared/util/react-hooks';
import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import {
  Box,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import _ from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { dispatchWithErrorHandling } from '../../../../../actions/helper/dispatchWithErrorHandling';
import {
  deactivateStylist,
  fetchStylists,
  updateStylistAccess,
  updateStylistOrder,
  updateStylistPermissionRole,
} from '../../../../../actions/salon/stylistsSettingsAction';
import CButton from '../../../../../components_old/atoms/CButton';
import CDivider from '../../../../../components_old/atoms/CDivider';
import CMenuButton from '../../../../../components_old/atoms/CMenuButton';
import CProgressOverlay from '../../../../../components_old/atoms/CProgressOverlay';
import CRadioGroup from '../../../../../components_old/atoms/CRadioGroup';
import CStylistAvatar from '../../../../../components_old/atoms/CStylistAvatar';
import {
  MySalonStylist,
  StylistMe,
  checkPermission,
} from '../../../../../models/stylist';
import {
  selectActiveStylists,
  useSelectMe,
} from '../../../../../selectors/salonSelector';
import DndProviderTemplate from '../../../../../templates/DndProviderTemplate';
import {
  WithConfirmDialog,
  withConfirmDialog,
} from '../../../../../templates/hoc/ConfirmDialogHOC';
import { notEmpty } from '../../../../../util/common';
import { useFlag } from '../../../../../util/hooks/useFlag';
import { useSortableItem } from '../../../../../util/hooks/useSortableItem';
import { useThunkDispatch } from '../../../../../util/hooks/useThunkDispatch';
import { PermissionRoleSelect } from './PermissionRoleSelect';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    position: 'relative',
    minWidth: 'fit-content',
  },
  cellSort: {
    width: 60,
    padding: theme.spacing(1),
  },
  cellSortable: {
    'cursor': 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  cellName: {
    minWidth: 140,
    maxHeight: 40,
    padding: theme.spacing(1),
  },
  cell: {
    minWidth: 80,
    maxHeight: 40,
    padding: theme.spacing(1),
  },
  cellIcon: {
    color: theme.palette.primary.main,
  },
  separatorRow: {
    backgroundColor: theme.palette.grey[100],
  },
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
}));

export const StylistList: FC = () => {
  const classes = useStyles();

  const me = useSelectMe();
  const stylists = useSelector(selectActiveStylists);
  const dispatch = useThunkDispatch();

  const [isFetching, callWithFetching] = useFlag(false);

  const [order, orderMutations] = useArray<number>([]);
  const reorderedStylists = order
    .map((id) => stylists.find((s) => s.id === id))
    .filter(notEmpty);

  const resetOrder = useCallback(() => {
    orderMutations.set(stylists.map((s) => s.id));
  }, [orderMutations, stylists]);

  useEffect(() => {
    callWithFetching(async () => {
      await dispatch(fetchStylists());
    });
  }, [callWithFetching, dispatch]);

  useEffect(resetOrder, [resetOrder]);

  const isOrderChanged = useMemo(
    () =>
      _.isEqual(
        order,
        stylists.map((s) => s.id)
      ),
    [order, stylists]
  );

  const handleSaveOrder = useCallback(
    () =>
      callWithFetching(async () => {
        await dispatchWithErrorHandling(dispatch, updateStylistOrder(order), {
          success: '表示順を保存しました',
        });
      }),
    [callWithFetching, dispatch, order]
  );

  const handleSavePermissionRole = async (
    stylistId: number,
    permissionRoleId: number
  ) => {
    await dispatchWithErrorHandling(
      dispatch,
      updateStylistPermissionRole(stylistId, permissionRoleId),
      { success: '役割を更新しました' }
    );
  };

  const handleSaveCanAccess = async (stylistId: number, canAccess: boolean) => {
    await dispatchWithErrorHandling(
      dispatch,
      updateStylistAccess(stylistId, canAccess),
      {
        success: canAccess
          ? 'アクセス制限を解除しました'
          : 'アクセス制限しました',
      }
    );
  };

  const handleDeactivateStylist = async (stylistId: number) => {
    await dispatchWithErrorHandling(dispatch, deactivateStylist(stylistId), {
      success: 'スタッフを所属解除しました',
    });
  };

  return (
    <>
      <Typography variant="h6" gutterBottom>
        スタッフ一覧
      </Typography>
      <Card className={classes.card}>
        {isFetching && <CProgressOverlay />}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" className={classes.cellSort}>
                <Typography variant="caption">掲載順</Typography>
              </TableCell>
              <TableCell align="center" className={classes.cellName}>
                スタッフ名
              </TableCell>
              <TableCell align="center" className={classes.cell}>
                役割
              </TableCell>
              <TableCell align="center" className={classes.cell}>
                アクセス制限
              </TableCell>
              <TableCell align="center" className={classes.cell}>
                編集
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {reorderedStylists.length > 0 && (
              <>
                <DndProviderTemplate>
                  {reorderedStylists.map((s, i) => (
                    <StylistRow
                      key={s.id}
                      index={i}
                      me={me}
                      stylist={s}
                      onSavePermissionRole={handleSavePermissionRole}
                      onSaveCanAccess={handleSaveCanAccess}
                      onDeactivate={handleDeactivateStylist}
                      onOrderChange={orderMutations.swap}
                    />
                  ))}
                </DndProviderTemplate>
                <TableRow>
                  <TableCell colSpan={8} className={classes.separatorRow}>
                    <Grid container alignItems="center">
                      <Grid item>
                        <Typography variant="body2">左のアイコン</Typography>
                      </Grid>
                      <Grid item>
                        <SVGIcon
                          name="drag-indicator"
                          style={{ display: 'flex', alignItems: 'center' }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="body2">
                          をドラッグして表示順を変更することができます。
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Box ml={2}>
                          <CButton
                            disabled={isOrderChanged}
                            variant="outlined"
                            size="small"
                            onClick={resetOrder}
                          >
                            キャンセル
                          </CButton>
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box ml={2}>
                          <CButton
                            disabled={isOrderChanged}
                            size="small"
                            onClick={handleSaveOrder}
                          >
                            表示順を保存
                          </CButton>
                        </Box>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </Card>
    </>
  );
};

const StylistRow: FC<{
  me: StylistMe;
  index: number;
  stylist: MySalonStylist;
  disabled?: boolean;
  onSavePermissionRole(stylistId: number, permissionRoleId: number): void;
  onSaveCanAccess(stylistId: number, canAccess: boolean): void;
  onDeactivate(stylistId: number): void;
  onOrderChange(dragIndex: number, hoverIndex: number): void;
}> = React.memo(function StylistRow(props) {
  const classes = useStyles();

  const {
    me,
    index,
    stylist,
    disabled,
    onSavePermissionRole,
    onSaveCanAccess,
    onDeactivate,
    onOrderChange,
  } = props;

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const ref = useRef<HTMLTableRowElement>(null);
  const { isDragging, drag, drop, preview, handlerId } = useSortableItem(
    'stylist',
    ref,
    index,
    onOrderChange
  );
  preview(drop(ref));

  const opacity = isDragging ? 0.2 : 1;

  const handleCloseDialog = useCallback(() => setIsEditDialogOpen(false), []);

  return (
    <TableRow ref={ref} style={{ opacity }} data-handler-id={handlerId}>
      <TableCell
        ref={drag}
        align="center"
        className={clsx(classes.cellSort, classes.cellSortable)}
      >
        <SVGIcon name="drag-indicator" />
      </TableCell>
      <TableCell align="left" className={classes.cellName}>
        <Grid container spacing={2} alignItems="center" wrap="nowrap">
          <Grid item>
            <CStylistAvatar size={32} stylist={stylist} />
          </Grid>
          <Grid item>
            <Typography variant="body1" noWrap>
              {stylist.name}
            </Typography>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align="center" className={classes.cell}>
        {stylist.permissionRole.name}
      </TableCell>
      <TableCell align="center" className={classes.cell}>
        {checkPermission(stylist, 'canUpdateSalonStylistAccessibility') ? (
          '-'
        ) : (
          <Chip
            size="small"
            variant="outlined"
            color={stylist.canAccess ? 'secondary' : 'default'}
            label={stylist.canAccess ? '許可中' : '制限中'}
          />
        )}
      </TableCell>
      <TableCell align="center" className={classes.cell}>
        <IconButton
          onClick={() => setIsEditDialogOpen(true)}
          disabled={disabled}
          className={classes.cellIcon}
          size="large"
        >
          <SVGIcon name="pen" />
        </IconButton>
        <EditStylistDialog
          open={isEditDialogOpen}
          me={me}
          stylist={stylist}
          onSavePermissionRole={onSavePermissionRole}
          onSaveCanAccess={onSaveCanAccess}
          onDeactivate={onDeactivate}
          onClose={handleCloseDialog}
        />
      </TableCell>
    </TableRow>
  );
});

const EditStylistDialog = React.memo(
  withConfirmDialog(function EditStylistDialog(
    props: {
      open: boolean;
      me: StylistMe;
      stylist: MySalonStylist;
      onSavePermissionRole(stylistId: number, permissionRoleId: number): void;
      onSaveCanAccess(stylistId: number, canAccess: boolean): void;
      onDeactivate(stylistId: number): void;
      onClose(): void;
    } & WithConfirmDialog
  ) {
    const classes = useStyles();
    const {
      open,
      me,
      stylist,
      onSavePermissionRole,
      onSaveCanAccess,
      onDeactivate,
      onClose,
      openConfirmDialog,
      closeConfirmDialog,
    } = props;

    const [permissionRoleId, setPermissionRoleId] = useState(
      stylist.permissionRoleId
    );
    const [canAccess, setCanAccess] = useState(stylist.canAccess);

    useEffect(() => {
      if (open) {
        setPermissionRoleId(stylist.permissionRoleId);
        setCanAccess(stylist.canAccess);
      }
    }, [open, stylist.canAccess, stylist.permissionRoleId]);

    const handleSavePermissionRole = useCallback(() => {
      onSavePermissionRole(stylist.id, permissionRoleId);
    }, [onSavePermissionRole, permissionRoleId, stylist.id]);

    const handleSaveCanAccess = useCallback(() => {
      onSaveCanAccess(stylist.id, canAccess);
    }, [canAccess, onSaveCanAccess, stylist.id]);

    const handleClickDeactivate = useCallback(() => {
      openConfirmDialog({
        title: '確認',
        description: '本当にスタッフを所属解除しますか？',
        onOk: () => {
          closeConfirmDialog();
          onDeactivate(stylist.id);
        },
      });
    }, [closeConfirmDialog, onDeactivate, openConfirmDialog, stylist.id]);

    const canEditRole = checkPermission(me, 'canUpdateSalonStylistRole');
    const canEditAccess = checkPermission(
      me,
      'canUpdateSalonStylistAccessibility'
    );
    const canBeEditedAccess = !checkPermission(
      stylist,
      'canUpdateSalonStylistAccessibility'
    );

    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
        <DialogTitle>{stylist.name} の設定</DialogTitle>
        <DialogContent>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs>
              <InputLabel shrink>役割</InputLabel>
              <PermissionRoleSelect
                value={permissionRoleId}
                onChange={setPermissionRoleId}
                disabled={!canEditRole}
              />
            </Grid>
            <Grid item>
              {!canEditRole ? (
                <Typography variant="body2" color="error">
                  役割を変更する権限がありません
                </Typography>
              ) : (
                <CButton
                  disabled={stylist.permissionRoleId === permissionRoleId}
                  onClick={handleSavePermissionRole}
                >
                  保存
                </CButton>
              )}
            </Grid>
          </Grid>

          <CDivider spacing={2} />

          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs>
              <InputLabel shrink>アクセス制限</InputLabel>
              {canBeEditedAccess ? (
                <CRadioGroup
                  currentValue={canAccess ? 1 : 0}
                  onChange={(v) => setCanAccess(v === 1)}
                  options={[
                    { value: 1, label: '許可' },
                    { value: 0, label: '制限' },
                  ]}
                  disabled={!canEditAccess}
                  row
                />
              ) : (
                <Typography>-</Typography>
              )}
            </Grid>
            <Grid item>
              {!canBeEditedAccess ? (
                <Typography variant="body2" color="error">
                  {stylist.permissionRole.name}はアクセス制限できません
                </Typography>
              ) : !canEditAccess ? (
                <Typography variant="body2" color="error">
                  アクセス制限の権限がありません
                </Typography>
              ) : (
                <CButton
                  size="small"
                  disabled={stylist.canAccess === canAccess}
                  onClick={handleSaveCanAccess}
                >
                  保存
                </CButton>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <CButton variant="text" onClick={onClose}>
            キャンセル
          </CButton>
          {checkPermission(me, 'canDeactivateSalonStylist') && (
            <CMenuButton
              variant="text"
              menus={[
                {
                  title: 'このスタッフを所属解除する',
                  onClick: handleClickDeactivate,
                },
              ]}
            >
              その他
            </CMenuButton>
          )}
        </DialogActions>
      </Dialog>
    );
  })
);
