import { ICustomer } from '@karutekun/core/customer';
import { IStylist } from '@karutekun/core/stylist';
import { IVoucher } from '@karutekun/core/voucher';
import { RelKey, StrictRel } from '@karutekun/shared/util/entity';
import { Simplify } from 'type-fest';

export enum VisitSource {
  Staff = 0,
  Line = 1,
  Web = 2,
}

export enum VisitStatus {
  Reserved = 0,
  Visited = 1,
}

export enum VisitImageType {
  Before = 1,
  After = 2,
  Other = 3,
}

export interface IVisitImage {
  visitId: number;
  type: VisitImageType;
  displayNumber: number;
  url: string;
}

interface _IVisit {
  id: number;
  salonId: number;
  customerId: number | null;
  voucherId: number;
  assignedStylistId: number | null;
  createdStylistId: number | null;
  visitorId: number | null;
  status: VisitStatus;
  source: VisitSource;
  title: string;
  imageUrl: string | null;
  isShimei: boolean;
  startedAt: number;
  finishedAt: number;
  treatmentMemo: string;
  reservationMemo: string;
  noteFromCustomer: string;
  isConfirmedByStaff: boolean;
  isReminderSentToCustomer: boolean;
  visitNum: number;
  groupVisitNum: number;
  isDeleted: boolean;
  userUpdatedAt: number;
  createdAt: number;
  updatedAt: number;

  customer?: ICustomer<'lineSettings'> | null;
  voucher?: IVoucher;
  images?: IVisitImage[];
  assignedStylist?: IStylist | null;
}
type _IVisitHistory = Simplify<
  _IVisit & {
    status: VisitStatus.Visited;
    customerId: number;
    assignedStylistId: number;
    customer?: ICustomer<'lineSettings'>;
    assignedStylist?: IStylist;
  }
>;
type _IReservation = Simplify<
  _IVisit & {
    status: VisitStatus.Reserved;
    customerId: number | null;
    assignedStylistId: number | null;
    customer?: ICustomer<'lineSettings'> | null;
    assignedStylist?: IStylist | null;
  }
>;

export type VisitRelationKeys = RelKey<_IVisit>;
export type IVisitHistory<K extends VisitRelationKeys = never> = StrictRel<
  _IVisitHistory,
  K
>;
export type IReservation<K extends VisitRelationKeys = never> = StrictRel<
  _IReservation,
  K
>;
export type IVisit<K extends VisitRelationKeys = never> = StrictRel<_IVisit, K>;
