import { trpc } from '@karutekun/shared/data-access/api-base';

type ConfirmationOptions = {
  title: string;
  message: string;
};

type UpdateVisitHistoryMutationOptions = Exclude<
  Parameters<typeof trpc.visit.updateVisitHistory.useMutation>[0],
  undefined
>;

type UseUpdateVisitHistoryOptions = {
  showConfirmation: (options: ConfirmationOptions, onOk: () => void) => void;
  onUnhandledError?: UpdateVisitHistoryMutationOptions['onError'];
  onSuccess?: UpdateVisitHistoryMutationOptions['onSuccess'];
};

export function useUpdateVisitHistory({
  showConfirmation,
  onUnhandledError,
  onSuccess,
}: UseUpdateVisitHistoryOptions) {
  const { mutate, isPending } = trpc.visit.updateVisitHistory.useMutation({
    onSuccess,
    onError(e, data, context) {
      if (e.data?.karutekunErrorCode === 'CONFLICT') {
        showConfirmation(
          {
            title: '確認',
            message: `直前に他の端末による更新があります。このまま保存してよろしいですか？`,
          },
          async () => {
            mutate({ ...data, skipConflictCheck: true });
          }
        );
      } else {
        onUnhandledError?.(e, data, context);
      }
    },
  });

  return { mutate, isPending };
}
