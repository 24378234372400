import { mapById } from '@karutekun/shared/util/objects';
import {
  PlainReservationMenu,
  reservationMenuFromResource,
} from './reservationMenu';
import {
  PlainReservationStylist,
  reservationStylistFromResource,
} from './reservationStylist';

/**
 * BasicSettings
 */
export type ReservationSalonInfoSetting = {
  address: string;
  phone: string;
  homeMessage: string | null;
  confirmationMessage: string | null;
  remindMessage: string | null;
};
export type ReservationReceptionSetting = {
  acceptReservations: boolean;
  acceptFreeReservations: boolean;
  acceptShimeiReservations: boolean;
  isManToMan: boolean;
  avoidOverbooking: boolean;
  minuteInterval: MinuteInterval;
  reservableWeeksInAdvance: number;
  reservationTimeLimitInTheDay: number | null;
  reservationDeadlineDays: number;
  reservationDeadlineTime: string;
  sendRemindMessage: boolean;
  remindTimeInTheDay: number | null;
  remindDays: number;
  remindTime: string;
};
export type ReservationBasicSetting = ReservationSalonInfoSetting &
  ReservationReceptionSetting;

export function emptyReservationBasicSetting(
  overwrite: Partial<ReservationBasicSetting> = {}
): ReservationBasicSetting {
  return {
    acceptReservations: true,
    acceptFreeReservations: true,
    acceptShimeiReservations: true,
    isManToMan: false,
    avoidOverbooking: false,
    address: '',
    phone: '',
    homeMessage: null,
    confirmationMessage: null,
    remindMessage: null,
    minuteInterval: 30,
    reservableWeeksInAdvance: 12,
    reservationTimeLimitInTheDay: null,
    reservationDeadlineDays: 1,
    reservationDeadlineTime: '16:00:00',
    sendRemindMessage: true,
    remindTimeInTheDay: null,
    remindDays: 1,
    remindTime: '20:00:00',
    ...overwrite,
  };
}
export function reservationBasicSettingFromResource(
  // TODO 一時的に lint を無効化しています。気づいたベースで直してください
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  res: any
): ReservationBasicSetting {
  return {
    acceptReservations: res.acceptReservations,
    acceptFreeReservations: res.acceptFreeReservations,
    acceptShimeiReservations: res.acceptShimeiReservations,
    isManToMan: res.isManToMan,
    avoidOverbooking: res.avoidOverbooking,
    address: res.address,
    phone: res.phone,
    homeMessage: res.homeMessage,
    confirmationMessage: res.confirmationMessage,
    remindMessage: res.remindMessage,
    minuteInterval: res.minuteInterval,
    reservableWeeksInAdvance: res.reservableWeeksInAdvance,
    reservationTimeLimitInTheDay: res.reservationTimeLimitInTheDay,
    reservationDeadlineDays: res.reservationDeadlineDays,
    reservationDeadlineTime: res.reservationDeadlineTime,
    sendRemindMessage: res.sendRemindMessage,
    remindTimeInTheDay: res.remindTimeInTheDay,
    remindDays: res.remindDays,
    remindTime: res.remindTime,
  };
}

/**
 * All
 */
export type ReservationSettings = {
  basicSetting: ReservationBasicSetting;
  reservationStylistMap: IdMap<PlainReservationStylist>;
  reservationMenuMap: IdMap<PlainReservationMenu>;
};
export type PartialReservationSettings = Partial<{
  basicSetting: Partial<ReservationBasicSetting>;
  reservationStylistMap: IdMap<PlainReservationStylist>;
  reservationMenuMap: IdMap<PlainReservationMenu>;
}>;

export function emptyReservationSettings(
  overwrite: PartialReservationSettings = {}
): ReservationSettings {
  return {
    basicSetting: emptyReservationBasicSetting(overwrite.basicSetting),
    reservationStylistMap: overwrite.reservationStylistMap || {},
    reservationMenuMap: overwrite.reservationMenuMap || {},
  };
}
// TODO 一時的に lint を無効化しています。気づいたベースで直してください
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function reservationSettingsFromResource(res: any): ReservationSettings {
  return {
    basicSetting: reservationBasicSettingFromResource(res.basicSetting),
    reservationStylistMap: res.reservationStylists
      ? mapById(res.reservationStylists.map(reservationStylistFromResource))
      : {},
    reservationMenuMap: res.reservationMenus
      ? mapById(res.reservationMenus.map(reservationMenuFromResource))
      : {},
  };
}
