import {
  DiscountType,
  ServiceType,
  ServiceTypeName,
} from '@karutekun/core/salon-service';
import { notEmpty } from '@karutekun/shared/util/objects';
import { useArray } from '@karutekun/shared/util/react-hooks';
import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import { theme } from '@karutekun/shared-fe/react-ui-old';
import {
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Tab,
  Tabs,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import CServiceCategoryIcon from '../../../../components_old/atoms/CServiceCategoryIcon';
import {
  Service,
  ServiceCategory,
  ServiceDiscount,
} from '../../../../models/service';
import {
  selectActiveServiceCategoriesGroupedByType,
  selectActiveServiceDiscounts,
  selectActiveServices,
  selectVisibleServiceCategoriesGroupedByType,
  selectVisibleServiceDiscounts,
  selectVisibleServices,
} from '../../../../selectors/serviceSelector';
import DndProviderTemplate from '../../../../templates/DndProviderTemplate';
import { formatMoney } from '../../../../util/common';
import { useSortableItem } from '../../../../util/hooks/useSortableItem';
import { useWidthDown } from '../../../../util/hooks/useWidth';

type Props = {
  showMenuOnly?: boolean;
  showInvisible?: boolean;

  onClickService?(serviceId: number): void;
  onClickDiscount?(discountId: number): void;

  // 編集できるかどうか
  // editable = true なら、以下の編集系のコールバックが必要です
  editable?: boolean;

  onClickAddCategory?(type: ServiceType): void;
  onClickEditCategory?(categoryId: number): void;
  saveCategoryOrder?(categoryIds: number[]): Promise<void>;

  onClickAddService?(categoryId: number): void;
  onClickEditService?(serviceId: number): void;
  saveServiceOrder?(categoryId: number, serviceIds: number[]): Promise<void>;

  onClickAddDiscount?(): void;
  onClickEditDiscount?(discountId: number): void;
  saveDiscountOrder?(discountIds: number[]): Promise<void>;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  tabContainer: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  servicesContainer: {
    flex: 1,
    minHeight: 0,
    display: 'flex',
    flexDirection: 'row',
  },
  list: {
    padding: 0,
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    transition: 'all .3s',
  },
  listItemContainer: {
    '&:hover $listItemAction': {
      opacity: 1,
    },
  },
  listItemAction: {
    'position': 'absolute',
    'right': 0,
    'padding': theme.spacing(1),
    'opacity': 0,
    '&:hover': {
      opacity: 1,
    },
  },
  listItemText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  listItemIcon: {
    minWidth: 40,
  },
  listItemIconDrag: {
    cursor: 'pointer',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[50],
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  dividerLeft: {
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
}));

function sortWithOrder<T extends { id: number }>(
  items: T[],
  order: number[]
): T[] {
  return order
    .map((id) => items.find((item) => item.id === id))
    .filter(notEmpty);
}

async function defaultFunc() {
  //
}

export const ServiceSelect: FC<Props> = (props) => {
  const classes = useStyles();

  const {
    showInvisible,
    showMenuOnly,
    editable,
    onClickService,
    onClickDiscount,
    onClickAddCategory = defaultFunc,
    onClickAddService = defaultFunc,
    onClickAddDiscount = defaultFunc,
    onClickEditCategory = defaultFunc,
    onClickEditService = defaultFunc,
    onClickEditDiscount = defaultFunc,
    saveCategoryOrder = defaultFunc,
    saveServiceOrder = defaultFunc,
    saveDiscountOrder = defaultFunc,
  } = props;

  const categoriesByType = useSelector(
    showInvisible
      ? selectActiveServiceCategoriesGroupedByType
      : selectVisibleServiceCategoriesGroupedByType
  );
  const services = useSelector(
    showInvisible ? selectActiveServices : selectVisibleServices
  );
  const discounts = useSelector(
    showInvisible ? selectActiveServiceDiscounts : selectVisibleServiceDiscounts
  );

  // 小さい画面ではカテゴリ・アイテム欄の横幅が変動するようにしている
  const isSmallMode = useWidthDown('sm');
  const [isLeftWide, setIsLeftWide] = useState(true);
  const leftFlex = isSmallMode && isLeftWide ? 2 : 1;
  const rightFlex = isSmallMode && !isLeftWide ? 2 : 1;

  const [tabIndex, setTabIndex] = useState(0);
  const type: ServiceType | null =
    tabIndex === 0
      ? ServiceType.Menu
      : tabIndex === 1
        ? ServiceType.Product
        : null;

  const categories = useMemo(() => {
    if (type === null) {
      return [];
    }
    return categoriesByType[type];
  }, [categoriesByType, type]);
  const [openCategoryId, setOpenCategoryId] = useState<number | null>(
    categories[0]?.id ?? null
  );

  const displayedServices = useMemo<Service[]>(
    () =>
      openCategoryId
        ? services.filter((s) => s.categoryId === openCategoryId)
        : [],
    [openCategoryId, services]
  );

  const [isCategorySorting, setIsCategorySorting] = useState(false);
  const [isServiceSorting, setIsServiceSorting] = useState(false);
  const [isDiscountSorting, setIsDiscountSorting] = useState(false);
  const [categoryOrder, categoryOrderMutations] = useArray<number>();
  const [serviceOrder, serviceOrderMutations] = useArray<number>();
  const [discountOrder, discountOrderMutations] = useArray<number>();

  const resetCategoryOrder = useCallback(() => {
    categoryOrderMutations.set(categories.map((category) => category.id));
  }, [categories, categoryOrderMutations]);
  const resetServiceOrder = useCallback(() => {
    serviceOrderMutations.set(displayedServices.map((service) => service.id));
  }, [serviceOrderMutations, displayedServices]);
  const resetDiscountOrder = useCallback(() => {
    discountOrderMutations.set(discounts.map((discount) => discount.id));
  }, [discountOrderMutations, discounts]);

  useEffect(resetCategoryOrder, [resetCategoryOrder]);
  useEffect(resetServiceOrder, [resetServiceOrder]);
  useEffect(resetDiscountOrder, [resetDiscountOrder]);

  useEffect(() => setOpenCategoryId(categories[0]?.id ?? null), [categories]);
  useEffect(() => setIsLeftWide(false), [openCategoryId]);

  const handleClickAddService = useCallback(() => {
    if (openCategoryId) {
      onClickAddService(openCategoryId);
    }
  }, [onClickAddService, openCategoryId]);

  const handleSaveCategoryOrder = useCallback(async () => {
    saveCategoryOrder(categoryOrder).then(() => setIsCategorySorting(false));
  }, [categoryOrder, saveCategoryOrder]);

  const handleSaveServiceOrder = useCallback(async () => {
    if (openCategoryId) {
      saveServiceOrder(openCategoryId, serviceOrder).then(() =>
        setIsServiceSorting(false)
      );
    }
  }, [openCategoryId, saveServiceOrder, serviceOrder]);
  const handleSaveDiscountOrder = useCallback(
    async () =>
      saveDiscountOrder(discountOrder).then(() => setIsDiscountSorting(false)),
    [discountOrder, saveDiscountOrder]
  );

  const sortedCategories = isCategorySorting
    ? sortWithOrder(categories, categoryOrder)
    : categories;
  const sortedServices = isServiceSorting
    ? sortWithOrder(displayedServices, serviceOrder)
    : displayedServices;
  const sortedDiscounts = isDiscountSorting
    ? sortWithOrder(discounts, discountOrder)
    : discounts;

  return (
    <DndProviderTemplate>
      <div className={classes.container}>
        <Tabs
          className={classes.tabContainer}
          value={tabIndex}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_, tabIndex: number) => setTabIndex(tabIndex)}
        >
          <Tab label={ServiceTypeName[ServiceType.Menu]} />
          <Tab
            disabled={showMenuOnly}
            label={ServiceTypeName[ServiceType.Product]}
          />
          <Tab disabled={showMenuOnly} label="割引" />
        </Tabs>
        <div className={classes.servicesContainer}>
          {type === null ? (
            <List className={classes.list} style={{ flex: 1 }}>
              <ListStickyHeader
                title="割引"
                editable={editable}
                sorting={isDiscountSorting}
                onClickAdd={onClickAddDiscount}
                onClickSort={() => {
                  setIsDiscountSorting(true);
                }}
                onClickCancelSort={() => {
                  resetDiscountOrder();
                  setIsDiscountSorting(false);
                }}
                saveOrder={handleSaveDiscountOrder}
              />
              {sortedDiscounts.map((discount, i) => (
                <DiscountListItem
                  key={discount.id}
                  index={i}
                  item={discount}
                  editable={editable}
                  sorting={isDiscountSorting}
                  onClick={onClickDiscount}
                  onClickEdit={onClickEditDiscount}
                  onOrderChange={discountOrderMutations.swap}
                />
              ))}
            </List>
          ) : (
            <>
              <List
                className={classes.list}
                style={{ flex: leftFlex }}
                onTouchMove={() => setIsLeftWide(true)}
              >
                <ListStickyHeader
                  title="カテゴリ"
                  isSmallMode={isSmallMode}
                  editable={editable}
                  sorting={isCategorySorting}
                  onClickAdd={() => onClickAddCategory(type)}
                  onClickSort={() => {
                    setIsCategorySorting(true);
                  }}
                  onClickCancelSort={() => {
                    resetCategoryOrder();
                    setIsCategorySorting(false);
                  }}
                  saveOrder={handleSaveCategoryOrder}
                />
                {sortedCategories.map((category, i) => (
                  <CategoryListItem
                    key={category.id}
                    index={i}
                    item={category}
                    selected={category.id === openCategoryId}
                    editable={editable}
                    sorting={isCategorySorting}
                    onClick={setOpenCategoryId}
                    onClickEdit={onClickEditCategory}
                    onOrderChange={categoryOrderMutations.swap}
                  />
                ))}
              </List>

              <List
                className={clsx(classes.list, classes.dividerLeft)}
                style={{ flex: rightFlex }}
                onTouchMove={() => setIsLeftWide(false)}
                onClick={() => setIsLeftWide(false)}
              >
                <ListStickyHeader
                  title={ServiceTypeName[type]}
                  isSmallMode={isSmallMode}
                  editable={editable && openCategoryId !== null}
                  sorting={isServiceSorting}
                  onClickAdd={handleClickAddService}
                  onClickSort={() => {
                    setIsServiceSorting(true);
                  }}
                  onClickCancelSort={() => {
                    resetServiceOrder();
                    setIsServiceSorting(false);
                  }}
                  saveOrder={handleSaveServiceOrder}
                />
                {sortedServices.length === 0 ? (
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography variant="body2" color="textSecondary">
                          {ServiceTypeName[type]}が存在しません
                        </Typography>
                      }
                    />
                  </ListItem>
                ) : (
                  sortedServices.map((service, i) => (
                    <ServiceListItem
                      key={service.id}
                      index={i}
                      item={service}
                      editable={editable}
                      sorting={isServiceSorting}
                      onClick={onClickService}
                      onClickEdit={onClickEditService}
                      onOrderChange={serviceOrderMutations.swap}
                    />
                  ))
                )}
              </List>
            </>
          )}
        </div>
      </div>
    </DndProviderTemplate>
  );
};

const ListStickyHeader: FC<{
  title: string;
  isSmallMode?: boolean;
  editable?: boolean;
  sorting?: boolean;
  onClickAdd?(): void;
  onClickSort?(): void;
  onClickCancelSort?(): void;
  saveOrder?(): void;
}> = (props) => {
  const classes = useStyles();
  const {
    title,
    isSmallMode,
    editable,
    sorting,
    onClickAdd,
    onClickSort,
    onClickCancelSort,
    saveOrder,
  } = props;

  return (
    <ListSubheader className={classes.header}>
      <Box flex={1} mt={2} mb={2}>
        <Typography variant="body2" noWrap>
          {title}
        </Typography>
      </Box>

      {sorting ? (
        <>
          <Button
            size="small"
            variant="text"
            color="primary"
            onClick={saveOrder}
          >
            保存
          </Button>
          <Button
            size="small"
            variant="text"
            color="inherit"
            onClick={onClickCancelSort}
          >
            キャンセル
          </Button>
        </>
      ) : editable ? (
        isSmallMode ? (
          <>
            <IconButton size="small" color="primary" onClick={onClickAdd}>
              <SVGIcon name="plus" />
            </IconButton>
            <IconButton size="small" color="primary" onClick={onClickSort}>
              <SVGIcon name="sort" />
            </IconButton>
          </>
        ) : (
          <>
            <Box mr={1}>
              <Button
                size="small"
                variant="text"
                startIcon={<SVGIcon name="plus" size="sm" />}
                color="primary"
                onClick={onClickAdd}
              >
                追加
              </Button>
            </Box>
            <Button
              size="small"
              variant="text"
              startIcon={<SVGIcon name="sort" size="sm" />}
              color="primary"
              onClick={onClickSort}
            >
              並べ替え
            </Button>
          </>
        )
      ) : null}
    </ListSubheader>
  );
};

const CategoryListItem: FC<{
  index: number;
  item: ServiceCategory;
  selected?: boolean;
  editable?: boolean;
  sorting?: boolean;
  onClick?(categoryId: number): void;
  onClickEdit?(categoryId: number): void;
  onOrderChange(dragIndex: number, hoverIndex: number): void;
}> = React.memo(function CategoryListItem(props) {
  const classes = useStyles();

  const {
    index,
    item,
    selected,
    editable,
    sorting,
    onClick,
    onClickEdit,
    onOrderChange,
  } = props;

  const ref = useRef<HTMLDivElement>(null);
  const { isDragging, drag, drop, preview, handlerId } = useSortableItem(
    'category',
    ref,
    index,
    onOrderChange,
    !sorting
  );
  preview(drop(ref));

  const opacity = isDragging ? 0.2 : 1;

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(item.id);
    }
  }, [item.id, onClick]);
  const handleClickEdit = useCallback(() => {
    if (onClickEdit) {
      onClickEdit(item.id);
    }
  }, [item.id, onClickEdit]);

  return (
    <div ref={ref} data-handler-id={handlerId}>
      <ListItem
        style={{ opacity }}
        className={classes.listItemContainer}
        // TODO 一時的に lint を無効化しています。気づいたベースで直してください
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        button={!sorting as any}
        selected={!sorting ? selected : false}
        onClick={!sorting ? handleClick : undefined}
      >
        {sorting ? (
          <ListItemIcon
            ref={drag}
            className={clsx(classes.listItemIcon, classes.listItemIconDrag)}
          >
            <SVGIcon name="drag-indicator" />
          </ListItemIcon>
        ) : (
          <ListItemIcon className={classes.listItemIcon}>
            <CServiceCategoryIcon category={item} size={24} />
          </ListItemIcon>
        )}
        <ListItemText
          classes={{ primary: classes.listItemText }}
          primary={
            <>
              <Typography variant="body2" noWrap>
                {item.name}
              </Typography>
              {!item.isVisible && (
                <SVGIcon
                  name="eye-slash"
                  color={theme.palette.text.secondary}
                  style={{
                    marginLeft: theme.spacing(1),
                  }}
                  size="sm"
                />
              )}
            </>
          }
        />
        {editable && !sorting && (
          <div className={classes.listItemAction}>
            <IconButton edge="end" onClick={handleClickEdit} size="large">
              <SVGIcon name="pen" />
            </IconButton>
          </div>
        )}
      </ListItem>
    </div>
  );
});

const ServiceListItem: FC<{
  index: number;
  item: Service;
  editable?: boolean;
  sorting?: boolean;
  onClick?(id: number): void;
  onClickEdit?(id: number): void;
  onOrderChange(dragIndex: number, hoverIndex: number): void;
}> = React.memo(function ServiceListItem(props) {
  const classes = useStyles();

  const {
    index,
    item,
    editable,
    sorting,
    onClick,
    onClickEdit,
    onOrderChange,
  } = props;

  const ref = useRef<HTMLDivElement>(null);
  const { isDragging, drag, drop, preview, handlerId } = useSortableItem(
    'service',
    ref,
    index,
    onOrderChange,
    !sorting
  );
  preview(drop(ref));

  const opacity = isDragging ? 0.2 : 1;

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(item.id);
    }
  }, [item.id, onClick]);
  const handleClickEdit = useCallback(() => {
    if (onClickEdit) {
      onClickEdit(item.id);
    }
  }, [item.id, onClickEdit]);

  function renderSecondary() {
    if (item.type === ServiceType.Menu) {
      // Menu
      return (
        <>
          <Typography variant="body2" color="textSecondary" align="right">
            {formatMoney(item.price)}
          </Typography>
          <Typography variant="body2" color="textSecondary" align="right">
            {item.time}分
          </Typography>
        </>
      );
    } else {
      // Product
      return (
        <Typography variant="body2" color="textSecondary" align="right">
          {formatMoney(item.price)}
        </Typography>
      );
    }
  }

  return (
    <div ref={ref} data-handler-id={handlerId}>
      <ListItem
        style={{ opacity }}
        className={classes.listItemContainer}
        // TODO 一時的に lint を無効化しています。気づいたベースで直してください
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        button={!sorting as any}
        onClick={!sorting ? handleClick : undefined}
      >
        {sorting && (
          <ListItemIcon
            ref={drag}
            className={clsx(classes.listItemIcon, classes.listItemIconDrag)}
          >
            <SVGIcon name="drag-indicator" />
          </ListItemIcon>
        )}
        <Grid container alignItems="center">
          <Grid item xs style={{ minWidth: 0 }}>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary={
                <>
                  <Typography variant="body2" noWrap>
                    {item.name}
                  </Typography>
                  {!item.isVisible && (
                    <SVGIcon
                      name="eye-slash"
                      color={theme.palette.text.secondary}
                      style={{
                        marginLeft: theme.spacing(1),
                      }}
                      size="sm"
                    />
                  )}
                </>
              }
            />
          </Grid>
          <Grid item>{renderSecondary()}</Grid>
        </Grid>
        {editable && !sorting && (
          <div className={classes.listItemAction}>
            <IconButton edge="end" onClick={handleClickEdit} size="large">
              <SVGIcon name="pen" />
            </IconButton>
          </div>
        )}
      </ListItem>
    </div>
  );
});

const DiscountListItem: FC<{
  index: number;
  item: ServiceDiscount;
  editable?: boolean;
  sorting?: boolean;
  onClick?(id: number): void;
  onClickEdit?(id: number): void;
  onOrderChange(dragIndex: number, hoverIndex: number): void;
}> = React.memo(function DiscountListItem(props) {
  const classes = useStyles();

  const {
    index,
    item,
    editable,
    sorting,
    onClick,
    onClickEdit,
    onOrderChange,
  } = props;

  const ref = useRef<HTMLDivElement>(null);
  const { isDragging, drag, drop, preview, handlerId } = useSortableItem(
    'discount',
    ref,
    index,
    onOrderChange,
    !sorting
  );
  preview(drop(ref));

  const opacity = isDragging ? 0.2 : 1;

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(item.id);
    }
  }, [item.id, onClick]);
  const handleClickEdit = useCallback(() => {
    if (onClickEdit) {
      onClickEdit(item.id);
    }
  }, [item.id, onClickEdit]);

  function renderSecondary() {
    return (
      <Typography variant="body2" color="textSecondary" align="right">
        {item.type === DiscountType.Percentage
          ? `${item.value}%`
          : formatMoney(item.value)}
      </Typography>
    );
  }

  return (
    <div ref={ref} data-handler-id={handlerId}>
      <ListItem
        style={{ opacity }}
        className={classes.listItemContainer}
        // TODO 一時的に lint を無効化しています。気づいたベースで直してください
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        button={!sorting as any}
        onClick={!sorting ? handleClick : undefined}
      >
        {sorting && (
          <ListItemIcon
            ref={drag}
            className={clsx(classes.listItemIcon, classes.listItemIconDrag)}
          >
            <SVGIcon name="drag-indicator" />
          </ListItemIcon>
        )}
        <Grid container alignItems="center">
          <Grid item xs>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary={
                <>
                  <Typography variant="body2" noWrap>
                    {item.name}
                  </Typography>
                  {!item.isVisible && (
                    <SVGIcon
                      name="eye-slash"
                      color={theme.palette.text.secondary}
                      style={{
                        marginLeft: theme.spacing(1),
                      }}
                      size="sm"
                    />
                  )}
                </>
              }
            />
          </Grid>
          <Grid item>{renderSecondary()}</Grid>
        </Grid>
        {editable && !sorting && (
          <div className={classes.listItemAction}>
            <IconButton edge="end" onClick={handleClickEdit} size="large">
              <SVGIcon name="pen" />
            </IconButton>
          </div>
        )}
      </ListItem>
    </div>
  );
});
