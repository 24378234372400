import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M23.952 5.469a18.531 18.531 0 0 0-14.508 6.973 1.75 1.75 0 0 0 2.736 2.183A15.03 15.03 0 0 1 23.948 8.97h.003c8.29 0 15.011 6.688 15.05 14.941l-1.229-1.175a1.75 1.75 0 1 0-2.42 2.53l4.313 4.125a1.75 1.75 0 0 0 2.447-.028l4.125-4.125a1.75 1.75 0 1 0-2.474-2.474L42.5 24.025v-.045c0-10.225-8.31-18.51-18.55-18.511M5.45 24.025l-1.213 1.212a1.75 1.75 0 1 1-2.474-2.474l4.125-4.125a1.75 1.75 0 0 1 2.447-.028l4.312 4.125a1.75 1.75 0 1 1-2.42 2.53L8.95 24.042c.015 8.284 6.74 14.989 15.049 14.99a15.168 15.168 0 0 0 11.766-5.625 1.75 1.75 0 0 1 2.72 2.202 18.667 18.667 0 0 1-14.482 6.922H24c-10.24 0-18.545-8.274-18.55-18.506"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgSync = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgSync);
export default Memo;
