import { Box, Card, CardContent, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { createBroadcastMessage } from '../../../../actions/broadcastMessageAction';
import { dispatchWithErrorHandling } from '../../../../actions/helper/dispatchWithErrorHandling';
import CLink from '../../../../components_old/atoms/CLink';
import {
  PublishScheduleType,
  createInitialBroadcastMessage,
} from '../../../../models/broadcastMessage';
import { checkPermission } from '../../../../models/stylist';
import { selectMe, selectMySalon } from '../../../../selectors/salonSelector';
import { useSalonStatus } from '../../../../templates/providers/salonStatus/salonStatusContext';
import { useRedirect } from '../../../../util/hooks/router/useRedirect';
import { useThunkDispatch } from '../../../../util/hooks/useThunkDispatch';
import { BroadcastMessageForm } from '../_components/BroadcastMessageForm';

const useStyles = makeStyles((_: Theme) => ({
  root: {
    padding: '76px 20px 20px 20px',
  },
}));

export const CreateBroadcastMessage: FC = (props) => {
  const classes = useStyles();
  const { checkAndOpenDialog } = useSalonStatus();

  const dispatch = useThunkDispatch();
  const navigate = useNavigate();

  const me = useSelector(selectMe);
  const salon = useSelector(selectMySalon);

  const [broadcastMessage, setBroadcastMessage] = useState(
    createInitialBroadcastMessage(salon.id)
  );

  const handleSubmit = useCallback(
    async (isDraft: boolean) => {
      if (!checkAndOpenDialog('line')) {
        return;
      }

      await dispatchWithErrorHandling(
        dispatch,
        createBroadcastMessage(broadcastMessage, isDraft),
        {
          success: isDraft
            ? '下書き保存しました'
            : broadcastMessage.publishScheduleType ===
              PublishScheduleType.Immediate
            ? '配信の準備を開始しました'
            : '配信を予約しました',
          onSuccess() {
            navigate(`/chat/broadcast_messages/${broadcastMessage.id}`, {
              replace: true,
            });
          },
        }
      );
    },
    [broadcastMessage, checkAndOpenDialog, dispatch, navigate]
  );

  const hasPermission = checkPermission(me, 'canUpdateChatSetting');

  useRedirect(!hasPermission, '/chat/broadcast_messages');

  return (
    <div className={classes.root}>
      <Typography variant="h6" gutterBottom>
        一斉配信メッセージの作成
      </Typography>

      <Box mt={1} mb={2}>
        <CLink to="/chat/broadcast_messages">&lt; 一覧に戻る</CLink>
      </Box>

      <Card>
        <CardContent>
          <BroadcastMessageForm
            broadcastMessage={broadcastMessage}
            onChange={setBroadcastMessage}
            onSubmit={handleSubmit}
          />
        </CardContent>
      </Card>
    </div>
  );
};
