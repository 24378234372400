import { XYCoord } from 'dnd-core';
import React from 'react';
import { DropTargetMonitor } from 'react-dnd';

export const ItemTypes = {
  section: 'section',
  question: 'question', // "question:${counselingId}"
  option: 'option', // "option:${questionId}"
};

export interface DragItem {
  index: number;
  id: string;
  type: string;
}

export function isMovable(
  ref: React.RefObject<HTMLDivElement>,
  monitor: DropTargetMonitor,
  dragIndex: number,
  hoverIndex: number
): boolean {
  if (!ref.current) {
    return false;
  }

  if (dragIndex === hoverIndex) {
    return false;
  }

  // Determine rectangle on screen
  const hoverBoundingRect = ref.current.getBoundingClientRect();

  // Get vertical middle
  const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

  // Determine mouse position
  const clientOffset = monitor.getClientOffset();

  // Get pixels to the top
  const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

  // Dragging downwards
  if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
    return false;
  }

  // Dragging upwards
  if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
    return false;
  }

  return true;
}
