import { applyMiddleware, legacy_createStore as createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import {
  ThunkAction as ReduxThunkAction,
  ThunkDispatch as ReduxThunkDispatch,
} from 'redux-thunk';
import { GlobalActions } from './actions';
import { rootReducer } from './reducers';

/**
 * Production でも使うと便利だよとドキュメントに書いてあるが、
 * まだ何をしてくれるツールなのかはっきり理解していないので、とりあえずは dev only で使ってみる
 * @link https://github.com/zalmoxisus/redux-devtools-extension
 */

export const store = createStore(
  rootReducer,
  undefined,
  composeWithDevTools(applyMiddleware(thunk))
);

export type GlobalState = ReturnType<typeof store.getState>;

export type ThunkAction<T = void> = ReduxThunkAction<
  Promise<T>,
  GlobalState,
  undefined,
  GlobalActions
>;
export type ThunkDispatch = ReduxThunkDispatch<
  GlobalState,
  undefined,
  GlobalActions
>;
