import { ServiceType } from '@karutekun/core/salon-service';
import { moment } from '@karutekun/shared/util/datetime';
import { useArray } from '@karutekun/shared/util/react-hooks';
import {
  Card,
  CardContent,
  Checkbox,
  Grid,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import numeral from 'numeral';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchSummary } from '../../../actions/analyticsServiceAction';
import { pushSnackbarError } from '../../../actions/generalAction';
import { setViewAnalyticsServiceSummary } from '../../../actions/view/viewAnalyticsServiceAction';
import CButton from '../../../components_old/atoms/CButton';
import CInformation from '../../../components_old/atoms/CInformation';
import CSelectMulti from '../../../components_old/atoms/CSelectMulti';
import CDateRange from '../../../components_old/molecules/CDateRange';
import CCollapsibleTable, {
  Columns as TableColumns,
} from '../../../components_old/organisms/CCollapsibleTable';
import { PlainService, ServiceCategory } from '../../../models/service';
import {
  ServiceSummary,
  buildDisplaySummaryBase,
  calcTotalSummaryWithStylist,
} from '../../../models/serviceSummary';
import { PlainStylist } from '../../../models/stylist';
import { selectStylistMap } from '../../../selectors/salonSelector';
import {
  selectPlainServiceMap,
  selectServiceCategoryMap,
} from '../../../selectors/serviceSelector';
import { GlobalState } from '../../../store';
import {
  WithConfirmDialog,
  withConfirmDialog,
} from '../../../templates/hoc/ConfirmDialogHOC';
import { InformationText } from '../../../texts/infomation';
import { formatMoney, notEmpty } from '../../../util/common';
import { useThunkDispatch } from '../../../util/hooks/useThunkDispatch';
import { applyTax } from '../../../util/tax';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '76px 20px 20px 20px',
  },
  tableArea: {
    position: 'relative',
    width: '100%',
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  cardContent: {
    marginRight: 0,
    marginLeft: 0,
    marginBottom: -10,
  },
}));

const columnNames: TableColumns = {
  name: { label: '名前', align: 'left', minWidth: 140 },
  categoryName: { label: 'カテゴリ', align: 'center', minWidth: 80 },
  totalQuantity: {
    label: '数量',
    sorting: true,
    formatLabel: (value) => numeral(value).format('0,0'),
  },
  price: {
    label: '現在の定価',
    sorting: true,
    formatLabel: formatMoney,
    minWidth: 80,
  },
  totalPrice: {
    label: '提供時の定価合計',
    sorting: true,
    formatLabel: formatMoney,
    minWidth: 80,
  },
  averageAdjustedPrice: {
    label: '価格調整後の平均価格',
    sorting: true,
    formatLabel: formatMoney,
    minWidth: 100,
  },
  totalAdjustedPrice: {
    label: '価格調整後の合計価格',
    cInformationContent: InformationText.services.columns.totalAdjustedPrice,
    sorting: true,
    formatLabel: formatMoney,
    minWidth: 120,
  },
  averageSalesPrice: {
    label: '割引按分後の平均価格',
    sorting: true,
    formatLabel: formatMoney,
    minWidth: 100,
  },
  totalSalesPrice: {
    label: '割引按分後の合計価格',
    cInformationContent: InformationText.services.columns.totalSalesPrice,
    sorting: true,
    formatLabel: formatMoney,
    minWidth: 120,
  },
  discountPercentage: {
    label: '定価比',
    cInformationContent: InformationText.services.columns.discountPercentage,
    sorting: true,
    minWidth: 100,
    formatLabel: (value) => {
      if (value > 0) {
        return `${value.toFixed(1)}%OFF`;
      } else if (value < 0) {
        return `${(-value).toFixed(1)}%UP`;
      } else {
        return '定価';
      }
    },
  },
  totalVoucherNum: {
    label: '来店回数',
    cInformationContent: InformationText.services.columns.totalVoucherNum,
    sorting: true,
    formatLabel: (value) =>
      Number.isInteger(value) ? numeral(value).format('0,0') : value,
    minWidth: 100,
  },
};

const stylistColumnNames: TableColumns = {
  totalSalesPerStylist: {
    label: 'スタッフ按分後の売上',
    cInformationContent: InformationText.services.columns.totalSalesPerStylist,
    sorting: true,
    formatLabel: formatMoney,
    minWidth: 120,
  },
};
const collapsedColumnNames: TableColumns = {
  ...columnNames,
  ...stylistColumnNames,
};

const termSummaryColumnNames: TableColumns = {
  name: { label: '名前', align: 'left', minWidth: 100 },
  totalQuantity: columnNames.totalQuantity,
  totalPrice: columnNames.totalPrice,
  pricePerVisit: {
    label: '客単価(定価)',
    cInformationContent: InformationText.services.columns.pricePerVisit,
    sorting: true,
    formatLabel: formatMoney,
    minWidth: 100,
  },
  totalAdjustedPrice: columnNames.totalAdjustedPrice,
  adjustedPricePerVisit: {
    label: '客単価(価格調整後)',
    cInformationContent: InformationText.services.columns.adjustedPricePerVisit,
    sorting: true,
    formatLabel: formatMoney,
    minWidth: 100,
  },
  totalSalesPrice: columnNames.totalSalesPrice,
  salesPerVisit: {
    label: '客単価(割引按分後)',
    cInformationContent: InformationText.services.columns.salesPerVisit,
    sorting: true,
    formatLabel: formatMoney,
    minWidth: 100,
  },
  discountPercentage: columnNames.discountPercentage,
  totalVoucherNum: columnNames.totalVoucherNum,
};
const termSummaryCollapsedColumnNames: TableColumns = {
  ...termSummaryColumnNames,
  ...stylistColumnNames,
};

type Props = WithConfirmDialog;

const Service: FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Header {...props} />
        <Content {...props} />
      </Grid>
    </div>
  );
};

const Header: FC<Props> = (props) => {
  const isFetching = useSelector(
    (state: GlobalState) => state.view.analyticsService.summary.isFetching
  );
  const serviceCategoryMap = useSelector(selectServiceCategoryMap);
  const dispatch = useThunkDispatch();

  const [filterMenuCategoryIds, menuCategoryIdMutations] = useArray<number>([]);
  const [filterProductCategoryIds, productCategoryIdMutations] =
    useArray<number>([]);
  const [filterFrom, setFilterFrom] = useState<string>(
    moment().startOf('month').format('YYYY-MM-DD')
  );
  const [filterTo, setFilterTo] = useState<string>(
    moment().format('YYYY-MM-DD')
  );

  const targetMenuCategories: { id: number; name: string }[] = Object.values(
    serviceCategoryMap
  )
    .filter(notEmpty)
    .filter((c) => c.type === ServiceType.Menu);
  const targetProductCategories: { id: number; name: string }[] = Object.values(
    serviceCategoryMap
  )
    .filter(notEmpty)
    .filter((c) => c.type === ServiceType.Product);

  const isMenuCategoryChecked =
    targetMenuCategories.length === filterMenuCategoryIds.length;
  const isProductCategoryChecked =
    targetProductCategories.length === filterProductCategoryIds.length;

  const handleFetchSummary = async (
    from: string,
    to: string,
    menuCategoryIds: number[],
    productCategoryIds: number[]
  ) => {
    dispatch(setViewAnalyticsServiceSummary({ isFetching: true }));

    try {
      await dispatch(
        fetchSummary(from, to, menuCategoryIds, productCategoryIds)
      );
    } catch (e) {
      dispatch(pushSnackbarError('エラーが発生しました'));
    }

    dispatch(setViewAnalyticsServiceSummary({ isFetching: false }));
  };

  const handleCheckedAllMenuCategory = () => {
    if (isMenuCategoryChecked) {
      menuCategoryIdMutations.set([]);
    } else {
      menuCategoryIdMutations.set(targetMenuCategories.map((m) => m.id));
    }
  };

  const handleCheckedAllProductCategory = () => {
    if (isProductCategoryChecked) {
      productCategoryIdMutations.set([]);
    } else {
      productCategoryIdMutations.set(targetProductCategories.map((m) => m.id));
    }
  };

  const handleStartCounting = () => {
    if (
      filterFrom &&
      filterTo &&
      moment(filterTo).diff(moment(filterFrom), 'year') === 0
    ) {
      handleFetchSummary(
        filterFrom,
        filterTo,
        filterMenuCategoryIds,
        filterProductCategoryIds
      );
    } else {
      props.openConfirmDialog({
        description: '来店日は最大で1年間まで指定できます',
        onOk: props.closeConfirmDialog,
      });
    }
  };

  return (
    <Grid
      container
      item
      justifyContent="space-evenly"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid container justifyContent="flex-end" alignItems="center">
        <CInformation
          type="dialog"
          content={InformationText.services.overall}
          size="sm"
          mb={8}
        />
      </Grid>

      <Grid container justifyContent="flex-start" alignItems="center">
        <Grid item>
          <CDateRange
            label="来店日"
            from={filterFrom}
            to={filterTo}
            onChange={(from, to) => {
              if (from && to) {
                setFilterFrom(from);
                setFilterTo(to);
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <CSelectMulti
          fullWidth
          label="施術カテゴリ"
          onChange={(ids: number[]) => menuCategoryIdMutations.set(ids)}
          selected={filterMenuCategoryIds}
          options={targetMenuCategories.map((m) => ({
            value: m.id,
            element: m.name,
          }))}
        />
        <Checkbox
          checked={isMenuCategoryChecked}
          onChange={handleCheckedAllMenuCategory}
        />
        全選択
      </Grid>
      <Grid item xs={6}>
        <CSelectMulti
          fullWidth
          label="店販カテゴリ"
          onChange={(ids: number[]) => productCategoryIdMutations.set(ids)}
          selected={filterProductCategoryIds}
          options={targetProductCategories.map((m) => ({
            value: m.id,
            element: m.name,
          }))}
        />
        <Checkbox
          checked={isProductCategoryChecked}
          onChange={handleCheckedAllProductCategory}
        />
        全選択
      </Grid>

      <Grid container justifyContent="flex-end">
        <Grid item>
          <CButton
            size="large"
            onClick={handleStartCounting}
            isLoading={isFetching}
          >
            集計開始
          </CButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

const Content: FC = () => {
  const classes = useStyles();

  const serviceCategoryMap = useSelector(selectServiceCategoryMap);
  const stylistMap = useSelector(selectStylistMap);
  const menuSummaries = useSelector(
    (state: GlobalState) => state.analyticsService.menuSummaries || []
  );
  const productSummaries = useSelector(
    (state: GlobalState) => state.analyticsService.productSummaries || []
  );
  const termSummary = useSelector(
    (state: GlobalState) => state.analyticsService.termSummary
  );
  const serviceMap = useSelector(selectPlainServiceMap);

  const termTotalVoucherNum = termSummary
    ? termSummary.summary.totalVoucherNum
    : 0;
  return (
    <Grid
      container
      item
      justifyContent="space-evenly"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid item xs={12}>
        <Card className={classes.tableArea}>
          <CardContent className={classes.cardContent}>
            <Grid item xs={12} className={classes.title}>
              <Typography variant="h6">施術集計結果</Typography>
            </Grid>
            <Grid container style={{ width: '100%' }} justifyContent="center">
              <ServiceTable
                stylistMap={stylistMap}
                serviceSummaries={menuSummaries}
                serviceCategoryMap={serviceCategoryMap}
                serviceMap={serviceMap}
              />
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card className={classes.tableArea}>
          <CardContent className={classes.cardContent}>
            <Grid item xs={12} className={classes.title}>
              <Typography variant="h6">店販集計結果 </Typography>
            </Grid>
            <Grid container style={{ width: '100%' }} justifyContent="center">
              <ServiceTable
                stylistMap={stylistMap}
                serviceSummaries={productSummaries}
                serviceCategoryMap={serviceCategoryMap}
                serviceMap={serviceMap}
              />
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card className={classes.tableArea}>
          <CardContent className={classes.cardContent}>
            <Grid container direction="row" item xs>
              <Typography variant="h6">全カテゴリ合計売上データ</Typography>
              <CInformation
                type="dialog"
                size="sm"
                content={InformationText.services.termSummary.overall}
              />
            </Grid>
            <Grid
              container
              style={{ width: '100%' }}
              justifyContent="center"
            ></Grid>
            <CCollapsibleTable
              rows={
                termSummary !== undefined
                  ? [
                      {
                        ...buildDisplaySummaryBase(termSummary.summary),
                        totalVoucherNum: termTotalVoucherNum,
                        id: 1, //keyに使うので入れておく
                        name: '合計',
                        pricePerVisit:
                          termTotalVoucherNum > 0
                            ? termSummary.summary.totalPrice /
                              termTotalVoucherNum
                            : 0,
                        adjustedPricePerVisit:
                          termTotalVoucherNum > 0
                            ? termSummary.summary.totalAdjustedPrice /
                              termTotalVoucherNum
                            : 0,
                        salesPerVisit:
                          termTotalVoucherNum > 0
                            ? termSummary.summary.totalSalesPrice /
                              termTotalVoucherNum
                            : 0,
                        collapsedRows: Object.keys(termSummary.stylistSummary)
                          .map(Number)
                          .filter((id) => stylistMap[id])
                          .map((id) => {
                            const stylistSummary =
                              termSummary.stylistSummary[id];
                            const stylist = stylistMap[id] as PlainStylist;
                            const totalVoucherNum =
                              stylistSummary.totalVoucherNum;

                            return {
                              ...buildDisplaySummaryBase(stylistSummary),
                              id,
                              totalVoucherNum,
                              name: stylist.name,
                              pricePerVisit:
                                totalVoucherNum > 0
                                  ? stylistSummary.totalPrice / totalVoucherNum
                                  : 0,
                              adjustedPricePerVisit:
                                totalVoucherNum > 0
                                  ? stylistSummary.totalAdjustedPrice /
                                    totalVoucherNum
                                  : 0,
                              salesPerVisit:
                                totalVoucherNum > 0
                                  ? stylistSummary.totalSalesPrice /
                                    totalVoucherNum
                                  : 0,
                              totalSalesPerStylist:
                                stylistSummary.totalSalesPerStylist,
                            };
                          }),
                      },
                    ]
                  : []
              }
              columns={termSummaryColumnNames}
              collapsedColumnNames={termSummaryCollapsedColumnNames}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

const ServiceTable: FC<{
  stylistMap: IdMap<PlainStylist>;
  serviceSummaries: ServiceSummary[];
  serviceCategoryMap: IdMap<ServiceCategory>;
  serviceMap: IdMap<PlainService>;
}> = (props) => {
  const { serviceCategoryMap, serviceSummaries, serviceMap, stylistMap } =
    props;

  const allServiceIds = serviceSummaries.map((m) => m.id).sort();
  const [checkedServiceIds, serviceIdMutations] =
    useArray<number>(allServiceIds);
  useEffect(() => {
    serviceIdMutations.set(allServiceIds);
    // 配列の中身が変わったときのみ再実行する
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allServiceIds.join(':'), serviceIdMutations]);

  // チェックされたものの合計を表示
  const { summary: totalSummary, stylistSummary: totalStylistSummary } =
    calcTotalSummaryWithStylist(
      serviceSummaries.filter((m) => checkedServiceIds.includes(m.id))
    );

  return (
    <CCollapsibleTable
      onCheckButtonPressed={(id: number) => {
        serviceIdMutations.toggle(id, (item) => item === id);
      }}
      rows={serviceSummaries
        .map((serviceSummary) => {
          const service = serviceMap[serviceSummary.id];
          if (!service) {
            return null;
          }

          const category = serviceCategoryMap[service.categoryId];
          if (!category) {
            return null;
          }

          const categoryName = category.name;
          const { summary, stylistSummary } = serviceSummary;

          return {
            ...buildDisplaySummaryBase(summary),
            categoryName,
            price: service.isTaxIncluded
              ? service.price
              : applyTax(service.price, service.taxRate),
            totalVoucherNum: summary.totalVoucherNum,
            id: serviceSummary.id,
            name: service.name,
            isChecked: checkedServiceIds.includes(serviceSummary.id),
            collapsedRows: Object.keys(stylistSummary)
              .map(Number)
              .filter((id) => stylistMap[id])
              .map((id) => ({
                ...buildDisplaySummaryBase(stylistSummary[id]),
                id,
                categoryName,
                price: service.price,
                totalVoucherNum: stylistSummary[id].totalVoucherNum,
                name: (stylistMap[id] as PlainStylist).name,
                totalSalesPerStylist: stylistSummary[id].totalSalesPerStylist,
              })),
          };
        })
        .filter(notEmpty)}
      totalRow={{
        ...buildDisplaySummaryBase(totalSummary),
        categoryName: '-',
        price:
          totalSummary.totalQuantity > 0
            ? Math.round(totalSummary.totalPrice / totalSummary.totalQuantity)
            : 0,
        totalVoucherNum: totalSummary.voucherIds.length,
        id: 1,
        name: 'チェック合計',
        collapsedRows: Object.keys(totalStylistSummary)
          .map(Number)
          .filter((id) => stylistMap[id])
          .map((id) => ({
            ...buildDisplaySummaryBase(totalStylistSummary[id]),
            id,
            categoryName: '-',
            price:
              totalStylistSummary[id].totalQuantity > 0
                ? Math.round(
                    totalStylistSummary[id].totalPrice /
                      totalStylistSummary[id].totalQuantity
                  )
                : 0,
            totalVoucherNum: totalStylistSummary[id].voucherIds.length,
            name: (stylistMap[id] as PlainStylist).name,
            totalSalesPerStylist: totalStylistSummary[id].totalSalesPerStylist,
          })),
      }}
      columns={columnNames}
      collapsedColumnNames={collapsedColumnNames}
    />
  );
};

export default withConfirmDialog(Service);
