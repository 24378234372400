import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M11.57 7.03c2.71-1.81 5.9-2.78 9.15-2.78 4.37 0 8.56 1.74 11.65 4.83s4.83 7.28 4.83 11.65c0 3.26-.97 6.44-2.78 9.15-.28.41-.57.81-.88 1.19l9.69 9.69c.68.68.68 1.79 0 2.47-.68.68-1.79.68-2.47 0l-9.69-9.69c-1.22.98-2.58 1.79-4.04 2.4a16.498 16.498 0 0 1-9.52.94c-3.2-.64-6.13-2.21-8.44-4.51a16.52 16.52 0 0 1-4.51-8.44c-.64-3.2-.31-6.51.94-9.52s3.36-5.58 6.07-7.39zm9.15.72c3.44 0 6.74 1.37 9.18 3.8a12.98 12.98 0 0 1 1.61 16.39c-1.43 2.13-3.45 3.8-5.82 4.78a12.962 12.962 0 0 1-14.14-2.81A13.002 13.002 0 0 1 8 23.27c-.5-2.52-.24-5.13.74-7.5s2.65-4.4 4.78-5.82a12.93 12.93 0 0 1 7.21-2.19zm1.47 19.95c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5v-5.5h-5.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h5.5v-5.5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5v5.5h5.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-5.5z"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgSearchPlus = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgSearchPlus);
export default Memo;
