import { Alert, Box, Grid, Tab, Tabs, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router';
import { Link } from 'react-router-dom';
import CDivider from '../../../components_old/atoms/CDivider';
import CGuideLineFeature from '../../../components_old/organisms/CGuideLineFeature';
import { OptionLinePlanName } from '../../../models/salon';
import { checkPermission } from '../../../models/stylist';
import { selectMe } from '../../../selectors/salonSelector';
import { useSalonStatus } from '../../../templates/providers/salonStatus/salonStatusContext';
import { useResolvedLastPath } from '../../../util/hooks/router/useResolvedLastPath';
import ReservationBasicSettingCard from './_components/ReservationBasicSettingCard';
import ReservationMenuSettingCard from './_components/ReservationMenuSettingCard';
import ReservationStylistSettingCard from './_components/ReservationStylistSettingCard';

const useStyles = makeStyles((_: Theme) => ({
  root: {
    padding: '76px 20px 20px 20px',
  },
}));

const TabIndexMap: Record<string, number> = {
  basic: 0,
  stylist: 1,
  menu: 2,
};

export const ReservationSettings: FC = () => {
  const classes = useStyles();

  const me = useSelector(selectMe);
  const { salonStatus } = useSalonStatus();

  const lastPath = useResolvedLastPath();
  const tabIndex = TabIndexMap[lastPath] ?? 0;

  const hasPermission = checkPermission(me, 'canUpdateReservationSetting');

  return (
    <div className={classes.root}>
      {/* 基本プランが無効の場合に限り、注意文言を表示する */}
      {!salonStatus.isAvailable('basic') && (
        <Alert severity="error">
          お試し期間は終了しました。予約機能を利用するには
          {OptionLinePlanName}の契約が必要です。
        </Alert>
      )}

      {!salonStatus.hasSetting('reservation') ? (
        <Box mt={4}>
          <CGuideLineFeature />
        </Box>
      ) : (
        <>
          <Box mb={2}>
            <Tabs
              value={tabIndex}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
            >
              <Tab label="基本設定" component={Link} to="." />
              <Tab label="掲載スタッフ" component={Link} to="stylist" />
              <Tab label="掲載メニュー" component={Link} to="menu" />
            </Tabs>
            <CDivider spacing={0} />
          </Box>

          {!hasPermission && tabIndex !== 4 && (
            <Alert severity="warning">予約設定の編集権限がありません</Alert>
          )}

          <Routes>
            <Route
              path="*"
              element={
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      基本設定
                    </Typography>
                    <ReservationBasicSettingCard />
                  </Grid>
                </Grid>
              }
            />
            <Route
              path="stylist"
              element={
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      掲載スタッフ
                    </Typography>
                    <ReservationStylistSettingCard />
                  </Grid>
                </Grid>
              }
            />
            <Route
              path="menu"
              element={
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      掲載メニュー
                    </Typography>
                    <ReservationMenuSettingCard />
                  </Grid>
                </Grid>
              }
            />
          </Routes>
        </>
      )}
    </div>
  );
};
