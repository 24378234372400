import { Grid, InputLabel, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CardElement } from '@stripe/react-stripe-js';
import { TextValidator } from 'react-material-ui-form-validator';

type Props = {
  billingEmail: string;
  cardError: string | null;
  clearCardError(): void;
  onChangeBillingEmail(billingEmail: string): void;
  // カード情報の入力値はStripeのSDKによって勝手に取得されます
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 420,
    padding: theme.spacing(1),
  },
  cardContainer: {
    marginTop: theme.spacing(2),
  },
}));

export const RegisterPaymentProviderForm: FC<Props> = (props) => {
  const classes = useStyles();

  const { billingEmail, cardError, clearCardError, onChangeBillingEmail } =
    props;

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextValidator
            variant="standard"
            fullWidth
            label="請求先メールアドレス"
            type="email"
            inputProps={{ inputMode: 'email', maxLength: 256 }}
            value={billingEmail}
            onChange={(e) => onChangeBillingEmail(e.target.value)}
            validators={['required', 'isEmail']}
            errorMessages={[
              'メールアドレスは必須です',
              'メールアドレスが不正です',
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel shrink>クレジットカード</InputLabel>
          <div className={classes.cardContainer}>
            <CardElement onChange={clearCardError} />
            {cardError !== null && (
              <Typography color="error">{cardError}</Typography>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
