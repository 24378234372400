import CRadioGroup from '../../../../../components_old/atoms/CRadioGroup';

type Props = {
  isShimei: boolean;
  disabled?: boolean;
  onChange(isShimei: boolean): void;
};

export const VisitFormIsShimei: FC<Props> = ({
  isShimei,
  disabled,
  onChange,
}) => {
  return (
    <CRadioGroup
      row
      label="指名"
      currentValue={isShimei ? 1 : 0}
      options={[
        { value: 1, label: '有り' },
        { value: 0, label: '無し' },
      ]}
      onChange={(value) => onChange(value === 1)}
      disabled={disabled}
    />
  );
};
