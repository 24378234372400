import { deprecated } from 'typesafe-actions';

const { createAction } = deprecated;

enum ActionNames {
  SetViewAnalyticsServiceSummary = 'view/analytics/SetViewAnalyticsServiceSummary',
}

export const setViewAnalyticsServiceSummary = createAction(
  ActionNames.SetViewAnalyticsServiceSummary,
  (action) => {
    return ({ isFetching }: { isFetching: boolean }) => action({ isFetching });
  }
);
