import { ServiceType } from '@karutekun/core/salon-service';
import { createSelector } from 'reselect';
import { MySalon } from '../models/salon';
import {
  PlainService,
  Service,
  ServiceCategory,
  ServiceDiscount,
} from '../models/service';
import { GlobalState } from '../store';
import { notEmpty } from '../util/common';
import {
  selectMySalon,
  useSelectMySalon,
  useSelectSalon,
} from './salonSelector';

/**
 * カテゴリ
 */
export const selectServiceCategoryMap = createSelector(
  (state: GlobalState) => selectMySalon(state),
  (salon: MySalon): IdMap<ServiceCategory> => salon.services.categoryMap
);
export const selectActiveServiceCategories = createSelector(
  (state: GlobalState) => selectServiceCategoryMap(state),
  (serviceCategoryMap): ServiceCategory[] =>
    Object.values(serviceCategoryMap)
      .filter(notEmpty)
      .filter((c) => c.isActive)
      .sort((a, b) => b.order - a.order)
);
export const selectVisibleServiceCategories = createSelector(
  (state: GlobalState) => selectActiveServiceCategories(state),
  (serviceCategories): ServiceCategory[] =>
    serviceCategories.filter((c) => c.isVisible)
);

/**
 * メニュー
 */
export const selectPlainServiceMap = createSelector(
  (state: GlobalState) => selectMySalon(state),
  (salon: MySalon): IdMap<PlainService> => salon.services.serviceMap
);
export const selectServices = createSelector(
  (state: GlobalState) => selectPlainServiceMap(state),
  (state: GlobalState) => selectServiceCategoryMap(state),
  (plainServiceMap, serviceCategoryMap): Service[] =>
    Object.values(plainServiceMap)
      .filter(notEmpty)
      .sort((a, b) => b.order - a.order)
      .map((s) => ({
        ...s,
        // TODO 一時的に lint を無効化しています。気づいたベースで直してください
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        category: serviceCategoryMap[s.categoryId]!,
      }))
);
export const selectActiveServices = createSelector(
  (state: GlobalState) => selectServices(state),
  (services): Service[] => services.filter((s) => s.isActive)
);
export const selectVisibleServices = createSelector(
  (state: GlobalState) => selectActiveServices(state),
  (services): Service[] => services.filter((s) => s.isVisible)
);
export const selectServiceMap = createSelector(
  (state: GlobalState) => selectServices(state),
  (services: Service[]): IdMap<Service> => {
    const map: IdMap<Service> = {};
    for (const service of services) {
      map[service.id] = service;
    }
    return map;
  }
);

/**
 * typeごとに抽出
 */
function groupByServiceType<T extends { type: ServiceType }>(
  items: T[]
): { [key in ServiceType]: T[] } {
  const map: { [key in ServiceType]: T[] } = {
    [ServiceType.Menu]: [],
    [ServiceType.Product]: [],
    [ServiceType.Ticket]: [],
  };
  for (const item of items) {
    map[item.type].push(item);
  }
  return map;
}
export const selectActiveServicesGroupedByType = createSelector(
  (state: GlobalState) => selectActiveServices(state),
  (services): { [key in ServiceType]: Service[] } => {
    return groupByServiceType(services);
  }
);
export const selectActiveServiceCategoriesGroupedByType = createSelector(
  (state: GlobalState) => selectActiveServiceCategories(state),
  (categories): { [key in ServiceType]: ServiceCategory[] } => {
    return groupByServiceType(categories);
  }
);
export const selectVisibleServicesGroupedByType = createSelector(
  (state: GlobalState) => selectVisibleServices(state),
  (services): { [key in ServiceType]: Service[] } => {
    return groupByServiceType(services);
  }
);
export const selectVisibleServiceCategoriesGroupedByType = createSelector(
  (state: GlobalState) => selectVisibleServiceCategories(state),
  (categories): { [key in ServiceType]: ServiceCategory[] } => {
    return groupByServiceType(categories);
  }
);

/**
 * 割引
 */
export const selectServiceDiscountMap = createSelector(
  (state: GlobalState) => selectMySalon(state),
  (salon: MySalon): IdMap<ServiceDiscount> => salon.services.discountMap
);
export const selectActiveServiceDiscounts = createSelector(
  (state: GlobalState) => selectServiceDiscountMap(state),
  (serviceMap): ServiceDiscount[] =>
    Object.values(serviceMap)
      .filter(notEmpty)
      .filter((d) => d.isActive)
      .sort((a, b) => b.order - a.order)
);
export const selectVisibleServiceDiscounts = createSelector(
  (state: GlobalState) => selectActiveServiceDiscounts(state),
  (discounts): ServiceDiscount[] => discounts.filter((d) => d.isVisible)
);

//----------- hooks -------------
export function useSelectSalonServices(salonId?: number) {
  const mySalonId = useSelectMySalon().id;
  const salon = useSelectSalon(salonId ?? mySalonId);
  return {
    serviceMap: salon?.services?.serviceMap ?? {},
    categoryMap: salon?.services?.categoryMap ?? {},
    discountMap: salon?.services?.discountMap ?? {},
  };
}
export function useSelectService(
  serviceId: number,
  salonId?: number
): Service | null {
  const map = useSelectSalonServices(salonId).serviceMap;
  const service = map[serviceId] ?? null;
  const category = useSelectServiceCategory(service?.categoryId ?? 0);
  if (!service || !category) {
    return null;
  }
  return { ...service, category };
}
export function useSelectServiceCategory(
  categoryId: number,
  salonId?: number
): ServiceCategory | null {
  const map = useSelectSalonServices(salonId).categoryMap;
  return map[categoryId] ?? null;
}
export function useSelectServiceDiscount(
  discountId: number,
  salonId?: number
): ServiceDiscount | null {
  const map = useSelectSalonServices(salonId).discountMap;
  return map[discountId] ?? null;
}
export function useSelectDiscount(
  discountId: number,
  salonId?: number
): ServiceDiscount | null {
  const mySalonId = useSelectMySalon().id;
  const salon = useSelectSalon(salonId ?? mySalonId);
  const discountMap = salon?.services?.discountMap ?? {};
  return discountMap[discountId] ?? null;
}
