import { VisitResource } from '@karutekun/core/visit';
import { deprecated } from 'typesafe-actions';

const { createAction } = deprecated;

export enum ActionNames {
  SetViewScheduleCurrentDate = 'view/schedule/SetViewScheduleCurrentDate',

  // Drawer関係
  SetViewScheduleDrawerStatus = 'view/schedule/SetViewScheduleDrawerStatus',
  SetViewScheduleNewReservationCount = 'view/schedule/SetViewScheduleNewReservationCount',

  SetViewSchedulePreviewVisitId = 'view/schedule/SetViewSchedulePreviewVisitId',
  SetViewSchedulePreviewUpdates = 'view/schedule/SetViewSchedulePreviewUpdates',
}

export const setViewScheduleCurrentDate = createAction(
  ActionNames.SetViewScheduleCurrentDate,
  (action) => {
    return (date: string) => action({ date });
  }
);
export const setViewScheduleDrawerStatus = createAction(
  ActionNames.SetViewScheduleDrawerStatus,
  (action) => {
    return (drawerStates: {
      reservationList?: boolean;
      schedulePreview?: boolean;
    }) => action({ drawerStates });
  }
);
export const setViewSchedulePreviewVisitId = createAction(
  ActionNames.SetViewSchedulePreviewVisitId,
  (action) => {
    return (visitId: number | null) => action({ visitId });
  }
);
export const setViewSchedulePreviewUpdates = createAction(
  ActionNames.SetViewSchedulePreviewUpdates,
  (action) => {
    return (
      updates: Partial<VisitResource>,
      reset = false,
      triggerEditFlag = true
    ) => action({ updates, reset, triggerEditFlag });
  }
);
