import { InitialData, RemoteConfigData } from '../remoteConfig';

const env = process.env.REACT_APP_ENV;
const isEnabled = env === 'local' || env === 'dev';

export type DebugData = {
  remoteConfigDebugEnabled?: boolean;
  remoteConfigData?: RemoteConfigData;
};

class DebugToolServiceClass {
  data: DebugData = {};

  isEnabled() {
    return isEnabled;
  }

  clear() {
    this.data = {};
  }

  getData() {
    return this.data;
  }
  setData(update: Partial<DebugData>) {
    this.data = { ...this.data, ...update };
  }

  switchRemoteConfigDebug(enable: boolean) {
    if (enable && !this.getRemoteConfigData()) {
      this.setData({ remoteConfigData: InitialData });
    }
    this.setData({ remoteConfigDebugEnabled: enable });
  }
  getRemoteConfigData() {
    return this.data.remoteConfigData || null;
  }
  isRemoteConfigDebugEnabled() {
    return this.isEnabled() && !!this.data.remoteConfigDebugEnabled;
  }
}

export const DebugToolService = new DebugToolServiceClass();
