import { moment } from '@karutekun/shared/util/datetime';
import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import {
  Box,
  Collapse,
  Grid,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback, useState } from 'react';
import CButton from '../../../../../components_old/atoms/CButton';
import CDateTimePicker from '../../../../../components_old/atoms/CDateTimePicker';
import CRadioGroup from '../../../../../components_old/atoms/CRadioGroup';
import {
  PlainBroadcastMessage,
  PublishScheduleType,
} from '../../../../../models/broadcastMessage';
import { useSimpleDialog } from '../../../../../templates/providers/simpleDialog/simpleDialogContext';
import { formatDate } from '../../../../../util/common';
import { useFlag } from '../../../../../util/hooks/useFlag';
import { BroadcastMessageCustomerFilterChips } from './BroadcastMessageCustomerFilterChips';
import { BroadcastMessageFormCustomerFilter } from './BroadcastMessageFormCustomerFilter';

type Props = {
  broadcastMessage: PlainBroadcastMessage;
  onChange(boradcastMessage: PlainBroadcastMessage): void;
  onSubmit(isDraft: boolean): Promise<void>;

  disabled?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  name: {
    width: 320,
  },
  messageField: {
    maxWidth: 400,
  },
  errorText: {
    marginTop: theme.spacing(1),
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.error.main,
  },
}));

export const BroadcastMessageForm: FC<Props> = (props) => {
  const classes = useStyles();

  const { broadcastMessage, onChange, onSubmit, disabled } = props;

  const { open } = useSimpleDialog();

  const [isSubmitting, callWithSubmitting] = useFlag(false);

  const [nameError, setNameError] = useState<string | null>(null);
  const [messageError, setMessageError] = useState<string | null>(null);
  const [publishScheduledAtError, setPublishScheduledAtError] = useState<
    string | null
  >(null);

  const publishScheduledAtFormat = broadcastMessage.publishScheduledAt
    ? formatDate(broadcastMessage.publishScheduledAt, true)
    : '';

  const submitWithValidation = useCallback(
    (isDraft: boolean) => {
      if (broadcastMessage.name.length === 0) {
        setNameError('名前は必須です');
        return;
      }
      if (!isDraft && broadcastMessage.message.length === 0) {
        setMessageError('メッセージ内容は必須です');
        return;
      }

      if (
        broadcastMessage.publishScheduleType === PublishScheduleType.Scheduled
      ) {
        if (
          !broadcastMessage.publishScheduledAt ||
          broadcastMessage.publishScheduledAt < moment().unix()
        ) {
          setPublishScheduledAtError('配信日時は未来の日時を設定してください');
          return;
        }
      }

      if (isDraft) {
        callWithSubmitting(() => onSubmit(isDraft));
      } else {
        open({
          title: '確認',
          content:
            broadcastMessage.publishScheduleType ===
            PublishScheduleType.Immediate
              ? 'すぐに配信してよろしいですか？'
              : `${publishScheduledAtFormat}に配信を予約します。よろしいですか？`,
          onOk: () => callWithSubmitting(() => onSubmit(isDraft)),
        });
      }
    },
    [
      broadcastMessage.message.length,
      broadcastMessage.name.length,
      broadcastMessage.publishScheduleType,
      broadcastMessage.publishScheduledAt,
      callWithSubmitting,
      onSubmit,
      open,
      publishScheduledAtFormat,
    ]
  );

  const handleSubmitAsDraft = useCallback(
    () => submitWithValidation(true),
    [submitWithValidation]
  );

  const handleSubmitAndDeliver = useCallback(
    () => submitWithValidation(false),
    [submitWithValidation]
  );

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {disabled ? (
            <Typography variant="h6">{broadcastMessage.name}</Typography>
          ) : (
            <>
              <TextField
                variant="standard"
                className={classes.name}
                label="一斉配信の名前"
                placeholder="5月お誕生日のお客様へのご案内"
                value={broadcastMessage.name}
                onChange={(e) => {
                  setNameError(null);
                  onChange({
                    ...broadcastMessage,
                    name: e.target.value,
                  });
                }}
                error={nameError !== null}
                disabled={disabled}
              />
              {nameError !== null && (
                <Typography className={classes.errorText}>
                  {nameError}
                </Typography>
              )}
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" gutterBottom>
            配信ターゲット
          </Typography>
          <Box mt={1}>
            {disabled ? (
              <BroadcastMessageCustomerFilterChips
                filter={broadcastMessage.customerFilter}
              />
            ) : (
              <BroadcastMessageFormCustomerFilter
                filter={broadcastMessage.customerFilter}
                onChange={(filter) =>
                  onChange({
                    ...broadcastMessage,
                    customerFilter: filter,
                  })
                }
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <CRadioGroup
            label="配信日時"
            currentValue={broadcastMessage.publishScheduleType}
            onChange={(v) => {
              const publishScheduledAt =
                v === PublishScheduleType.Scheduled
                  ? moment().add(1, 'day').add(1, 'hour').minutes(0).unix()
                  : null;

              onChange({
                ...broadcastMessage,
                publishScheduledAt,
                publishScheduleType: v,
              });
            }}
            options={[
              {
                value: PublishScheduleType.Immediate,
                // 編集できない状態のときには実際の配信時刻を表示しておく
                label:
                  disabled &&
                  broadcastMessage.publishScheduleType ===
                    PublishScheduleType.Immediate &&
                  broadcastMessage.publishScheduledAt !== null
                    ? `すぐに配信する (${publishScheduledAtFormat})`
                    : 'すぐに配信する',
              },
              {
                value: PublishScheduleType.Scheduled,
                label: '配信日時を指定する',
              },
            ]}
            disabled={disabled}
          />
          <Collapse
            in={
              broadcastMessage.publishScheduleType ===
              PublishScheduleType.Scheduled
            }
            timeout="auto"
            unmountOnExit
          >
            <Box ml={2}>
              <CDateTimePicker
                label="配信日時"
                unixtime={
                  broadcastMessage.publishScheduledAt ?? moment().unix()
                }
                onChange={(publishScheduledAt) => {
                  setPublishScheduledAtError(null);
                  onChange({
                    ...broadcastMessage,
                    publishScheduledAt,
                  });
                }}
                disabled={disabled}
              />
              {publishScheduledAtError !== null && (
                <Typography className={classes.errorText}>
                  {publishScheduledAtError}
                </Typography>
              )}
            </Box>
          </Collapse>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            classes={{ root: classes.messageField }}
            variant="outlined"
            multiline
            rows={4}
            label="メッセージ内容"
            value={broadcastMessage.message}
            onChange={(e) => {
              setMessageError(null);
              onChange({
                ...broadcastMessage,
                message: e.target.value,
              });
            }}
            error={messageError !== null}
            disabled={disabled}
          />
          {messageError !== null && (
            <Typography className={classes.errorText}>
              {messageError}
            </Typography>
          )}
        </Grid>
      </Grid>

      {!disabled && (
        <Box mt={4}>
          <Grid container spacing={4}>
            <Grid item>
              <CButton
                variant="outlined"
                isLoading={isSubmitting}
                onClick={handleSubmitAsDraft}
              >
                下書き保存
              </CButton>
            </Grid>
            <Grid item>
              <CButton
                isLoading={isSubmitting}
                onClick={handleSubmitAndDeliver}
                startIcon={
                  broadcastMessage.publishScheduleType ===
                  PublishScheduleType.Immediate ? (
                    <SVGIcon name="send" size="sm" />
                  ) : (
                    <SVGIcon name="business-time" />
                  )
                }
              >
                {broadcastMessage.publishScheduleType ===
                PublishScheduleType.Immediate
                  ? 'すぐに配信する'
                  : `配信を予約する (${publishScheduledAtFormat})`}
              </CButton>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};
