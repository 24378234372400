/**
 * 外税の場合に、税率をかけた総額を計算する
 */
export function applyTax(price: number, taxRate: number): number {
  return Math.floor((price * (100 + taxRate)) / 100);
}

/**
 * 外税の場合に、税額を計算する
 */
export function calculateTax(price: number, taxRate: number): number {
  return Math.floor((price * taxRate) / 100);
}

/**
 * 内税の場合に、総額から税金分を計算する
 */
export function calculateIncludedTax(price: number, taxRate: number): number {
  return Math.floor((price / (100 + taxRate)) * taxRate);
}
