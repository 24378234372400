import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  PaymentProvider,
  PaymentProviderType,
} from '../../../../models/subscription';

export type Props = {
  paymentProvider: PaymentProvider | null;
};

const useStyles = makeStyles((theme: Theme) => ({
  indentText: {
    padding: theme.spacing(1),
  },
}));

/**
 * 支払い方法を表示するコンポーネント
 */
export const PaymentProviderInfo: FC<Props> = (props) => {
  const { paymentProvider } = props;

  if (!paymentProvider) {
    return (
      <Typography variant="body2" color="textSecondary">
        お支払い方法が設定されていません
      </Typography>
    );
  }

  switch (paymentProvider.paymentProviderType) {
    case PaymentProviderType.Stripe:
      return <StripeInfo paymentProvider={paymentProvider} />;
    case PaymentProviderType.AppleInAppPurchase:
      return <AppleIapInfo />;
    default:
      return null;
  }
};

const StripeInfo: FC<{ paymentProvider: PaymentProvider }> = (props) => {
  const classes = useStyles();
  const { paymentProvider } = props;

  return (
    <>
      <Typography variant="body2" color="textSecondary">
        請求先メールアドレス
      </Typography>
      <Typography className={classes.indentText} variant="body1" gutterBottom>
        {paymentProvider.billingEmail}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        クレジットカード
      </Typography>
      <Typography className={classes.indentText} variant="body1">
        **** - **** - **** - {paymentProvider.creditCardLast4}
      </Typography>
    </>
  );
};

const AppleIapInfo: FC = () => {
  return <Typography variant="body1">App Store</Typography>;
};
