import { moment } from '@karutekun/shared/util/datetime';

export enum DateType {
  // 1 ~ 7 までは isoWeekday 互換
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7,
  Holiday = 8,
}
export const DateTypes = [
  DateType.Monday,
  DateType.Tuesday,
  DateType.Wednesday,
  DateType.Thursday,
  DateType.Friday,
  DateType.Saturday,
  DateType.Sunday,
  DateType.Holiday,
];
export const DateTypeNameMap = {
  [DateType.Monday]: '月',
  [DateType.Tuesday]: '火',
  [DateType.Wednesday]: '水',
  [DateType.Thursday]: '木',
  [DateType.Friday]: '金',
  [DateType.Saturday]: '土',
  [DateType.Sunday]: '日',
  [DateType.Holiday]: '祝',
};

/**
 * 今月の日付範囲を返す
 */
export function getThisMonth() {
  return {
    from: moment().startOf('month').format('YYYY-MM-DD'),
    to: moment().endOf('month').format('YYYY-MM-DD'),
  };
}

/**
 * 直近１ヶ月の日付範囲を返す
 */
export function getRecentOneMonth() {
  return {
    from: moment().subtract(1, 'month').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
  };
}

/**
 * 直近半年の日付範囲を返す
 */
export function getRecentHalfYear(offset = 0, format = 'YYYY-MM-DD') {
  return {
    from: moment().subtract(6, 'month').add(offset, 'days').format(format),
    to: moment().add(offset, 'days').format(format),
  };
}

/**
 * 直近1年の日付範囲を返す
 */
export function getRecentYear(format = 'YYYY-MM-DD') {
  return {
    from: moment().subtract(1, 'year').format(format),
    to: moment().format(format),
  };
}

/**
 * N分刻みにした時間をかえす
 */
export function adjustMinuteToStep(
  unixtime: number,
  step: MinuteInterval,
  ceil = false
) {
  const func = ceil ? Math.ceil : Math.floor;
  const minute = func(moment.unix(unixtime).minute() / step) * step;
  return moment.unix(unixtime).minute(minute).unix();
}

/**
 * 一日のうちの何秒目か計算する
 */
export function calculateSecondsInDay(unixtime: number) {
  const m = moment.unix(unixtime);
  return m.hour() * 3600 + m.minute() * 60 + m.seconds();
}

/**
 * @deprecated util-datetime を使ってください
 * 指定月のカレンダーを返す
 *
 * @returns カレンダーを表現する2重配列(月曜日始まり)
 * e.x. 2020-07
 * [
 *   [null,         null,         "2020-07-01", "2020-07-02", ...],
 *   ["2020-07-06", "2020-07-07", "2020-07-08", "2020-07-09", ...],
 *   ...
 *   [...                , "2020-07-30", "2020-07-31", null, null]
 * ]
 */
export function getCalendar(year: number, month: number) {
  const weeks: (string | null)[][] = [];

  const currentDay = moment()
    .year(year)
    .month(month - 1)
    .startOf('month');
  const daysInMonth = currentDay.daysInMonth();

  weeks.push([...new Array(currentDay.isoWeekday() - 1).fill(null)]);

  let currentRowIndex = 0;
  for (let i = 0; i < daysInMonth; i += 1) {
    const row = weeks[currentRowIndex];

    row.push(currentDay.format('YYYY-MM-DD'));
    currentDay.add(1, 'day');

    if (row.length === 7) {
      weeks.push([]);
      currentRowIndex += 1;
    }
  }

  const lastWeekLack = 7 - weeks[weeks.length - 1].length;
  for (let i = 0; i < lastWeekLack; i += 1) {
    weeks[weeks.length - 1].push(null);
  }

  return weeks;
}

/**
 * 過去の日付をフォーマットする
 * @param shorten true なら「今日」「昨日」という表記を使うようになる
 */
export function formatPastDate(m: moment.Moment, shorten = false) {
  const now = moment().startOf('day');
  const daysDiff = m.clone().startOf('day').diff(now, 'days');

  if (shorten && (daysDiff === 0 || daysDiff === -1)) {
    if (daysDiff === 0) {
      return '今日';
    } else if (daysDiff === -1) {
      return '昨日';
    }
  } else if (m.year() < now.year()) {
    // 去年以前の場合は年月日
    return m.format('YYYY/M/D(ddd)');
  }

  // それ以外の場合は月日と曜日
  return m.format('M/D(ddd)');
}
