import { combineReducers } from 'redux';
import viewAnalyticsServiceReducer, {
  ViewAnalyticsServiceState,
} from '../reducers/view/viewAnalyticsServiceReducer';
import viewAutoCompleteCustomerReducer, {
  ViewAutoCompleteCustomerState,
} from '../reducers/view/viewAutoCompleteCustomerReducer';
import viewCustomersReducer, {
  ViewCustomersState,
} from '../reducers/view/viewCustomersReducer';
import viewScheduleReducer, {
  ViewScheduleState,
} from '../reducers/view/viewScheduleReducer';
import viewSelectCustomerReducer, {
  ViewSelectCustomerState,
} from '../reducers/view/viewSelectCustomerReducer';
import viewBroadcastMessageReducer, {
  ViewBroadcastMessageState,
} from './view/viewBroadcastMessageReducer';
import viewChatReducer, { ViewChatState } from './view/viewChatReducer';

export interface ViewState {
  analyticsService: ViewAnalyticsServiceState;
  customers: ViewCustomersState;
  schedule: ViewScheduleState;
  broadcastMessage: ViewBroadcastMessageState;
  selectCustomer: ViewSelectCustomerState;
  autoCompleteCustomer: ViewAutoCompleteCustomerState;
  chat: ViewChatState;
}

// TODO 一時的に lint を無効化しています。気づいたベースで直してください
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const viewReducer = combineReducers<ViewState, any>({
  analyticsService: viewAnalyticsServiceReducer,
  customers: viewCustomersReducer,
  schedule: viewScheduleReducer,
  broadcastMessage: viewBroadcastMessageReducer,
  selectCustomer: viewSelectCustomerReducer,
  autoCompleteCustomer: viewAutoCompleteCustomerReducer,
  chat: viewChatReducer,
});

export default viewReducer;
