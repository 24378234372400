import { trpc } from '@karutekun/shared/data-access/api-base';
import { dateRange } from '@karutekun/shared/util/datetime';
import { formatMoneyShrink } from '@karutekun/shared/util/format';
import { mapBy } from '@karutekun/shared/util/objects';
import { Card, Grid } from '@mui/material';
import { keepPreviousData } from '@tanstack/react-query';
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import CChartBar from '../../../components_old/atoms/CChartBar';
import CChartComposedCustomerNumAverageBudget from '../../../components_old/molecules/CChartComposedCustomerNumAverageBudget';
import CChartWrapper from '../../../components_old/organisms/charts/CChartWrapper';
import { selectAnalyticsDisplayTaxIncludedPrice } from '../../../selectors/settingsSelector';
import { InformationText } from '../../../texts/infomation';
import { fmtDate } from '../../../util/chart';
import { getSalesChartKeys } from './salesChartUtil';

type Props = {
  from: string;
  to: string;
};

export const SalonDailyStatsSection: FC<Props> = memo((props) => {
  const { from, to } = props;

  const displayTaxIncludedPrice = useSelector(
    selectAnalyticsDisplayTaxIncludedPrice
  );

  const { isRefetching, data: dailyStats = [] } =
    trpc.analytics.salonStats.dailyStats.useQuery(
      { from, to },
      { placeholderData: keepPreviousData }
    );

  const statsByDate = useMemo(() => mapBy(dailyStats, 'date'), [dailyStats]);
  const dates = useMemo(() => [...dateRange({ from, to })], [from, to]);

  const salesData = useMemo(
    () =>
      dates.map((d) => {
        const s = statsByDate[d];
        return {
          label: fmtDate(d),
          menuSales: s?.menuSales ?? 0,
          productSales: s?.productSales ?? 0,
          menuSalesWithoutTax: s?.menuSalesWithoutTax ?? 0,
          productSalesWithoutTax: s?.productSalesWithoutTax ?? 0,
        };
      }),
    [dates, statsByDate]
  );

  const customerShimeiData = useMemo(
    () =>
      dates.map((d) => {
        const s = statsByDate[d];
        return {
          label: fmtDate(d),
          firstCustomerNum: s?.isShimeiMap[1] ?? 0,
          secondCustomerNum: s?.isShimeiMap[0] ?? 0,
          averageBudget: !s?.averageSalesPerCustomer
            ? null
            : displayTaxIncludedPrice
              ? s.averageSalesPerCustomer
              : s.averageSalesPerCustomerWithoutTax,
        };
      }),
    [dates, displayTaxIncludedPrice, statsByDate]
  );

  const customerFirstRepeatData = useMemo(
    () =>
      dates.map((d) => {
        const s = statsByDate[d];
        return {
          label: fmtDate(d),
          firstCustomerNum: s?.firstVisitNum ?? 0,
          secondCustomerNum: s?.repeatVisitNum ?? 0,
          averageBudget: !s?.averageSalesPerCustomer
            ? null
            : displayTaxIncludedPrice
              ? s.averageSalesPerCustomer
              : s.averageSalesPerCustomerWithoutTax,
        };
      }),
    [dates, displayTaxIncludedPrice, statsByDate]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CChartWrapper
            title="日次売上 推移"
            isFetching={isRefetching}
            informationText={InformationText.salon.dailySales}
          >
            <CChartBar
              data={salesData}
              nameKey="label"
              formatter={formatMoneyShrink}
              valueKeys={getSalesChartKeys(displayTaxIncludedPrice)}
              stack
            />
          </CChartWrapper>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <CChartWrapper
            title="顧客数 推移(指名有り/無し)"
            isFetching={isRefetching}
            informationText={InformationText.salon.dailyShimei}
          >
            <CChartComposedCustomerNumAverageBudget
              data={customerShimeiData}
              firstCustomerName="指名有り"
              secondCustomerName="指名無し"
            />
          </CChartWrapper>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <CChartWrapper
            title="顧客数 推移(新規/固定)"
            isFetching={isRefetching}
            informationText={InformationText.salon.dailyCustomers}
          >
            <CChartComposedCustomerNumAverageBudget
              data={customerFirstRepeatData}
            />
          </CChartWrapper>
        </Card>
      </Grid>
    </Grid>
  );
});
