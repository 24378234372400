import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M24 4.25C13.096 4.25 4.25 13.096 4.25 24S13.096 43.75 24 43.75 43.75 34.904 43.75 24 34.904 4.25 24 4.25M7.75 24c0-8.971 7.279-16.25 16.25-16.25S40.25 15.029 40.25 24 32.971 40.25 24 40.25 7.75 32.971 7.75 24m11.487-7.237a1.75 1.75 0 1 0-2.474 2.474L21.525 24l-4.762 4.763a1.75 1.75 0 1 0 2.474 2.474L24 26.475l4.763 4.762a1.75 1.75 0 1 0 2.474-2.474L26.475 24l4.762-4.763a1.75 1.75 0 1 0-2.474-2.474L24 21.525z"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgTimesCircle = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgTimesCircle);
export default Memo;
