import { IVisitImage, VisitImageType } from '../data';

export function convertUrlsToVisitImages(data: {
  beforeImageUrls: string[];
  afterImageUrls: string[];
  otherImageUrls: string[];
}): Omit<IVisitImage, 'visitId'>[] {
  return [
    ...data.beforeImageUrls.map((url, i) => {
      return { type: VisitImageType.Before, displayNumber: i, url };
    }),
    ...data.afterImageUrls.map((url, i) => {
      return { type: VisitImageType.After, displayNumber: i, url };
    }),
    ...data.otherImageUrls.map((url, i) => {
      return { type: VisitImageType.Other, displayNumber: i, url };
    }),
  ];
}
