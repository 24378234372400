import {
  ApiBaseProvider,
  isTRPCError,
} from '@karutekun/shared/data-access/api-base';
import NetInfo from '@react-native-community/netinfo';
import { onlineManager } from '@tanstack/react-query';
import { useMemo } from 'react';
import { pushSnackbarError } from './actions/generalAction';
import { firebaseAuth } from './libs/firebase';
import { useThunkDispatch } from './util/hooks/useThunkDispatch';

// ネットワークがオンラインに復帰したときに自動的に refetch が走るように設定
onlineManager.setEventListener((setOnline) => {
  return NetInfo.addEventListener((state) => {
    setOnline(!!state.isConnected);
  });
});

const trpcOptions = {
  httpBatchLinkOptions: {
    url: `${process.env.REACT_APP_API_URL}trpc`,
    headers: async () => {
      const token = await firebaseAuth.currentUser?.getIdToken();
      const headers: Record<string, string> = {
        'Accept': 'application/json',
        'X-KaruteKun-Api-Version': process.env.REACT_APP_API_VERSION || '1',
        'X-KaruteKun-Client-Platform': 'web',
      };
      headers.Authorization = `Bearer ${token}`;
      return headers;
    },
  },
};

export const ApiProvider: FC = ({ children }) => {
  const dispatch = useThunkDispatch();
  const reactQueryOptions = useMemo(
    () => ({
      onMutationError(e: unknown) {
        const message = isTRPCError(e) ? e.data?.messageForUser : undefined;
        dispatch(pushSnackbarError(message ?? 'エラーが発生しました'));
      },
    }),
    [dispatch]
  );
  return (
    <ApiBaseProvider
      trpcClientOptions={trpcOptions}
      reactQueryOptions={reactQueryOptions}
    >
      {children}
    </ApiBaseProvider>
  );
};
