import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ChatMessageBodyReceipt as ChatMessageBodyReceiptEntity } from '../../models/chatMessageBodyReceipt/entity';

type Props = {
  chatMessageBody: ChatMessageBodyReceiptEntity;
};

const useStyles = makeStyles((theme: Theme) => ({
  subTitle: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
  },
  salonName: {
    fontSize: theme.typography.h5.fontSize,
    color: theme.palette.text.primary,
    fontWeight: 'bold',
  },
  nameWrapper: {
    flexWrap: 'wrap',
    flex: 1,
  },
  name: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary,
  },
  price: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary,
  },
}));

const ChatMessageBodyReceipt: FC<Props> = (props) => {
  const classes = useStyles();

  const body = props.chatMessageBody;

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography className={classes.subTitle}>
          レシートを送信しました
        </Typography>
      </Grid>

      <Grid container item spacing={1} direction="column">
        <Grid container item alignItems="center" direction="column">
          <Grid item>
            <Typography className={classes.salonName}>
              {body.salonName}
            </Typography>
          </Grid>

          {body.headerMemo !== undefined && (
            <Grid item>
              <Typography align="center" variant="body2" color="textSecondary">
                {body.headerMemo}
              </Typography>
            </Grid>
          )}

          <Grid container item alignItems="center" direction="column">
            {body.address !== undefined && (
              <Grid item>
                <Typography variant="body2" color="textSecondary">
                  {body.address}
                </Typography>
              </Grid>
            )}

            {body.phoneNumber !== undefined && (
              <Grid item>
                <Typography variant="body2" color="textSecondary">
                  {body.phoneNumber}
                </Typography>
              </Grid>
            )}

            <Grid item>
              <Typography variant="body2" color="textSecondary">
                {body.date}
              </Typography>
            </Grid>

            {body.customerName !== undefined && (
              <Grid item>
                <Typography variant="body2" color="textSecondary">
                  {body.customerName}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid container item direction="column">
          {body.voucherLines.map((pair, i) => (
            <NamePriceComponent key={`voucherLines:${i}`} pair={pair} />
          ))}

          {body.voucherDiscounts.map((pair, i) => (
            <NamePriceComponent key={`voucherDiscounts:${i}`} pair={pair} />
          ))}
        </Grid>

        <Grid container item direction="column">
          {body.prices.map((pair, i) => (
            <NamePriceComponent key={i} pair={pair} />
          ))}
        </Grid>

        <Grid container item direction="column">
          {body.paymentMethods.map((pair, i) => (
            <NamePriceComponent key={`paymentMethods:${i}`} pair={pair} />
          ))}

          {body.payments.map((pair, i) => (
            <NamePriceComponent key={`payments:${i}`} pair={pair} />
          ))}
        </Grid>

        {body.footerMemo !== undefined && (
          <Grid container item justifyContent="center" alignItems="center">
            <Grid item>
              <Typography align="center" variant="body2" color="textSecondary">
                {body.footerMemo}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const NamePriceComponent: FC<{
  pair: { name: string; price: string };
}> = (props) => {
  const { pair } = props;
  const classes = useStyles();

  return (
    <Grid
      container
      item
      justifyContent="space-between"
      direction="row"
      spacing={2}
    >
      <Grid item className={classes.nameWrapper}>
        <Typography className={classes.name}>{pair.name}</Typography>
      </Grid>

      <Grid item>
        <Typography className={classes.price}>{pair.price}</Typography>
      </Grid>
    </Grid>
  );
};

export default ChatMessageBodyReceipt;
