import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M40.84 6.18c.25.109.472.273.65.48a2 2 0 0 1-.1 2.78l-1.13 1.13a1.23 1.23 0 0 1-.4.27 1.33 1.33 0 0 1-.48.1 1.289 1.289 0 0 1-.48-.1 1.27 1.27 0 0 1-.41-.27l-1.06-1.06a1.3 1.3 0 0 1-.37-.89 1.26 1.26 0 0 1 .37-.88l1.16-1.15a1.89 1.89 0 0 1 .67-.45 2.24 2.24 0 0 1 .79-.14c.272.009.54.07.79.18M17.82 27.54 17 29.89a.84.84 0 0 0 .65 1.11c.07.01.14.01.21 0 .09.014.18.014.27 0l2.31-.78c.22-.061.42-.178.58-.34l16.34-16.4a1.351 1.351 0 0 0 .38-1 1.33 1.33 0 0 0-.39-.95l-.93-.93a1.37 1.37 0 0 0-1.9 0L18.16 27a1.37 1.37 0 0 0-.34.54M37 19.25A1.75 1.75 0 0 0 35.25 21v17.25c0 .264-.051.525-.15.77A2.13 2.13 0 0 1 34 40.1c-.245.099-.506.15-.77.15H10.75a2 2 0 0 1-2-2v-22.5a2 2 0 0 1 2-2h15.7a1.75 1.75 0 0 0 0-3.5h-15.7a5.5 5.5 0 0 0-5.5 5.5v22.5a5.5 5.5 0 0 0 5.5 5.5h22.5a5.389 5.389 0 0 0 2.1-.42 5.439 5.439 0 0 0 3-3c.28-.665.422-1.379.42-2.1V21A1.75 1.75 0 0 0 37 19.25"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgEdit = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgEdit);
export default Memo;
