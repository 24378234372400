import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M10.89 1.92h26.84c2.73 0 4.95 2.22 4.95 4.95v34.26c0 2.73-2.22 4.95-4.95 4.95H10.89c-2.73 0-4.95-2.22-4.95-4.95v-6.77H3.85c-.97 0-1.75-.78-1.75-1.75s.78-1.75 1.75-1.75h2.09v-5.11H3.85c-.97 0-1.75-.78-1.75-1.75s.78-1.75 1.75-1.75h2.09v-5.11H3.85c-.97 0-1.75-.78-1.75-1.75s.78-1.75 1.75-1.75h2.09V6.87c0-2.73 2.22-4.95 4.95-4.95m26.84 40.66c.8 0 1.45-.65 1.45-1.45V6.87c0-.8-.65-1.45-1.45-1.45H10.89c-.8 0-1.45.65-1.45 1.45v34.26c0 .8.65 1.45 1.45 1.45zM24.288 22.97c2.62 0 4.93-2.41 5.16-5.38.11-1.51-.37-2.93-1.35-3.98-.97-1.04-2.32-1.62-3.81-1.62-1.49 0-2.85.57-3.81 1.61-.98 1.05-1.45 2.47-1.34 3.99.21 2.97 2.53 5.38 5.15 5.38m-1.62-7.33c.39-.42.97-.66 1.62-.66.65 0 1.21.24 1.61.66.41.44.6 1.05.55 1.72-.11 1.53-1.23 2.6-2.16 2.6-.93 0-2.05-1.07-2.16-2.6-.05-.68.14-1.29.55-1.73zm1.62 9.26c-3.94 0-8.58 2.19-9.44 7-.12.69.05 1.38.48 1.89.41.48 1.01.76 1.65.76h14.64c.64 0 1.24-.28 1.65-.76.43-.51.6-1.2.48-1.89-.87-4.81-5.5-7-9.45-7zm-6.25 6.65c.95-2.5 3.79-3.65 6.25-3.65s5.3 1.15 6.25 3.65z"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgAddressBook = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgAddressBook);
export default Memo;
