import {
  IService,
  IServiceCategory,
  ServiceType,
} from '@karutekun/core/salon-service';
import { notEmpty, uniqueBy } from '@karutekun/shared/util/objects';

/**
 * voucher に適用されているサービスのカテゴリーを抽出して返す
 * 並び順は、メニュー → 店販の順と、カテゴリーの order で並び替える
 */
export function extractCategoriesUnique<
  Line extends { serviceId: number },
  ServiceLike extends Pick<IService, 'id' | 'categoryId'>,
  CategoryLike extends Pick<IServiceCategory, 'id' | 'type' | 'order'>
>(
  lines: Line[],
  {
    serviceMap,
    categoryMap,
  }: { serviceMap: IdMap<ServiceLike>; categoryMap: IdMap<CategoryLike> }
): CategoryLike[] {
  const categories = uniqueBy(
    lines
      .map((line) => {
        const service = serviceMap[line.serviceId];
        if (!service) {
          return null;
        }
        return categoryMap[service.categoryId] ?? null;
      })
      .filter(notEmpty),
    'id'
  );

  const menuCategories = [];
  const productCategories = [];
  for (const c of categories) {
    if (c.type === ServiceType.Menu) {
      menuCategories.push(c);
    } else if (c.type === ServiceType.Product) {
      productCategories.push(c);
    }
  }

  return [
    ...menuCategories.sort((a, b) => b.order - a.order),
    ...productCategories.sort((a, b) => b.order - a.order),
  ];
}
