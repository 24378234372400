import { CustomerSex } from '@karutekun/core/customer';
import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback, useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import CButton from '../../../components_old/atoms/CButton';
import CRadioGroup from '../../../components_old/atoms/CRadioGroup';
import CSelectMulti from '../../../components_old/atoms/CSelectMulti';
import CCounselingList, {
  Map as CounselingMap,
} from '../../../components_old/molecules/CCounselingList';
import { CounselingSection } from '../../../models/counseling';
import {
  CustomerSalonInformation,
  PlainCustomer,
  emptyCustomer,
} from '../../../models/customer';
import { CustomerCounselingAnswer } from '../../../models/customerCounselingAnswer';
import { SalonCustomSetting } from '../../../models/salonCustomSetting';
import { VisitMotivation } from '../../../models/visitMotivation';
import { useFlag } from '../../../util/hooks/useFlag';
import { useKana } from '../../../util/hooks/useKana';
import { useWidthDown } from '../../../util/hooks/useWidth';

export type OwnProps = {
  open: boolean;
  defaultData?: Partial<PlainCustomer>;
  showContinuousCreate?: boolean;

  onCanceled(): void;
  onCreateAndView(customerId: number): void;
};

export type StateProps = {
  counselings: CounselingSection[];
  visitMotivations: VisitMotivation[];
  customSetting: SalonCustomSetting;
};

export type DispatchProps = {
  create(
    plainCustomer: PlainCustomer,
    customerSalonInformation: CustomerSalonInformation
  ): Promise<PlainCustomer>;
  pushSnackbar(id: number, name: string): void;
};

type Props = OwnProps & StateProps & DispatchProps;

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    maxHeight: '80vh',
  },
  dialogActionsButtonsArea: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dialogActionsParts: {
    display: 'flex',
    flexDirection: 'row',
  },
  dialogActionsPartsItem: {
    marginLeft: theme.spacing(1),
  },
  counselingContainer: {
    maxHeight: '50vh',
    overflow: 'auto',
  },
}));

const CreateCustomerDialog: FC<Props> = (props) => {
  const classes = useStyles();

  const { open, defaultData } = props;

  const [customer, setCustomer] = useState(
    emptyCustomer(
      defaultData,
      props.customSetting.customerForm?.sex?.default ?? CustomerSex.Female
    )
  );
  const [customerSalonInformation, setCustomerSalonInformation] =
    useState<CustomerSalonInformation>({
      visitMotivationIds: [],
      counselingAnswers: [],
    });
  const [isLoading, callWithLoading] = useFlag(false);
  const [isNameValid, setIsNameValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const isValid = isNameValid && isEmailValid;

  useEffect(() => {
    if (open) {
      setCustomer(
        emptyCustomer(
          defaultData,
          props.customSetting.customerForm?.sex?.default ?? CustomerSex.Female
        )
      );
    }
  }, [open, defaultData, props.customSetting.customerForm]);

  const updateBasicInfo = useCallback(
    (update: Partial<PlainCustomer>) => {
      setCustomer({ ...customer, ...update });
    },
    [customer]
  );

  function nameValidatorListener(result: boolean) {
    setIsNameValid(result && customer.name !== '' && customer.nameKana !== '');
  }

  function renderSexRadioGroup() {
    let sexOrder: CustomerSex[];
    const defaultSex =
      props.customSetting.customerForm?.sex?.default ?? CustomerSex.Female;
    switch (defaultSex) {
      case CustomerSex.Male:
        sexOrder = [CustomerSex.Male, CustomerSex.Female];
        break;
      default:
        sexOrder = [CustomerSex.Female, CustomerSex.Male];
    }

    const options = sexOrder.map((sex) => {
      return { value: sex, label: sex === CustomerSex.Male ? '男性' : '女性' };
    });

    return (
      <CRadioGroup
        label="性別"
        currentValue={customer.sex}
        options={options}
        onChange={(value) =>
          updateBasicInfo({
            sex: value,
          })
        }
        row
      />
    );
  }

  const fullScreen = useWidthDown('sm');
  const setKanaSource = useKana();

  return (
    <Dialog
      disableEscapeKeyDown
      classes={fullScreen ? {} : { paper: classes.dialog }}
      maxWidth="xs"
      fullWidth={true}
      fullScreen={fullScreen}
      open={open}
      onClose={props.onCanceled}
    >
      <DialogTitle>カルテ新規作成</DialogTitle>

      <DialogContent dividers>
        <ValidatorForm
          onSubmit={() => {
            // 何もしない
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">必須情報</Typography>
            </Grid>

            <Grid container item spacing={2}>
              <Grid item>
                <TextValidator
                  variant="standard"
                  required
                  label="お名前"
                  value={customer.name}
                  onChange={(e) => {
                    const nameKana = setKanaSource(e.target.value);
                    updateBasicInfo({ nameKana, name: e.target.value });
                  }}
                  validatorListener={nameValidatorListener}
                  validators={['required']}
                  errorMessages={['必須項目です']}
                />
              </Grid>
              <Grid item>
                <TextValidator
                  variant="standard"
                  required
                  label="よみがな"
                  value={customer.nameKana}
                  onChange={(e) =>
                    updateBasicInfo({ nameKana: e.target.value })
                  }
                  validatorListener={nameValidatorListener}
                  validators={['required']}
                  errorMessages={['必須項目です']}
                />
              </Grid>
            </Grid>

            {!props.customSetting.customerForm?.sex?.isHidden && (
              <Grid item>{renderSexRadioGroup()}</Grid>
            )}

            <Grid item xs={12}>
              <Typography variant="h6">詳細情報</Typography>
            </Grid>

            {!props.customSetting.customerForm?.birthday?.isHidden && (
              <Grid container item spacing={1}>
                <Grid item xs={4}>
                  <TextField
                    variant="standard"
                    label="生年月日(年)"
                    value={customer.birthdayYear || ''}
                    onChange={(e) =>
                      updateBasicInfo({ birthdayYear: Number(e.target.value) })
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant="standard"
                    label="(月)"
                    value={customer.birthdayMonth || ''}
                    onChange={(e) => {
                      const month = Number(e.target.value);
                      updateBasicInfo({
                        birthdayMonth: month >= 1 && month <= 12 ? month : 0,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant="standard"
                    label="(日)"
                    value={customer.birthdayDay || ''}
                    onChange={(e) => {
                      const day = Number(e.target.value);
                      updateBasicInfo({
                        birthdayDay: day >= 1 && day <= 31 ? day : 0,
                      });
                    }}
                  />
                </Grid>
              </Grid>
            )}

            {!props.customSetting.customerForm?.job?.isHidden && (
              <Grid item xs={12}>
                <TextField
                  variant="standard"
                  label="職業"
                  value={customer.job}
                  onChange={(e) => updateBasicInfo({ job: e.target.value })}
                />
              </Grid>
            )}

            {!props.customSetting.customerForm?.postalCode?.isHidden && (
              <Grid item xs={12}>
                <TextField
                  variant="standard"
                  fullWidth
                  label="郵便番号"
                  value={customer.postalCode || ''}
                  onChange={(e) =>
                    updateBasicInfo({ postalCode: e.target.value })
                  }
                />
              </Grid>
            )}

            {!props.customSetting.customerForm?.address?.isHidden && (
              <Grid item xs={12}>
                <TextField
                  variant="standard"
                  fullWidth
                  label="住所"
                  value={customer.address || ''}
                  onChange={(e) => updateBasicInfo({ address: e.target.value })}
                />
              </Grid>
            )}

            {!props.customSetting.customerForm?.phone?.isHidden && (
              <Grid item>
                <TextField
                  variant="standard"
                  label="電話番号"
                  placeholder="0000000000"
                  value={customer.phone || ''}
                  onChange={(e) => updateBasicInfo({ phone: e.target.value })}
                />
              </Grid>
            )}

            {!props.customSetting.customerForm?.email?.isHidden && (
              <Grid item xs={12}>
                <TextValidator
                  variant="standard"
                  style={{ width: '100%' }}
                  placeholder="karutekun@example.com"
                  label="メールアドレス"
                  type="email"
                  value={customer.email || ''}
                  onChange={(e) => updateBasicInfo({ email: e.target.value })}
                  validatorListener={setIsEmailValid}
                  validators={['isEmail']}
                  errorMessages={['メールアドレスが不正です']}
                />
              </Grid>
            )}
            {props.visitMotivations.length > 0 && (
              <Grid item xs={12}>
                <CSelectMulti
                  fullWidth
                  label="来店動機"
                  onChange={(visitMotivationIds) => {
                    setCustomerSalonInformation({
                      ...customerSalonInformation,
                      visitMotivationIds,
                    });
                  }}
                  selected={customerSalonInformation.visitMotivationIds}
                  options={props.visitMotivations.map((m) => ({
                    value: m.id,
                    element: m.name,
                  }))}
                />
              </Grid>
            )}

            {!props.customSetting.customerForm?.allergy?.isHidden && (
              <Grid item xs={12}>
                <TextField
                  style={{ width: '100%' }}
                  multiline
                  label="アレルギー等の注意事項"
                  rows="2"
                  value={customer.allergy}
                  onChange={(e) => updateBasicInfo({ allergy: e.target.value })}
                  variant="outlined"
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <TextField
                style={{ width: '100%' }}
                multiline
                label="メモ"
                rows="4"
                value={customer.memo}
                onChange={(e) => updateBasicInfo({ memo: e.target.value })}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <Card className={classes.counselingContainer}>
                <CCounselingList
                  counselings={props.counselings}
                  map={customerSalonInformation.counselingAnswers.reduce(
                    (
                      prev: CounselingMap,
                      current: CustomerCounselingAnswer
                    ) => {
                      prev[current.optionId] = { value: current.value };
                      return prev;
                    },
                    {}
                  )}
                  onChange={(map) => {
                    setCustomerSalonInformation({
                      ...customerSalonInformation,
                      counselingAnswers: Object.keys(map).map((questionId) => ({
                        optionId: Number(questionId),
                        // TODO 一時的にルールを無効化しています。気づいたベースで直してください
                        // @ts-expect-error: TS7015: Element implicitly has an 'any' type because index expression is not of type 'number'.
                        value: map[questionId].value,
                      })),
                    });
                  }}
                />
              </Card>
            </Grid>
          </Grid>
        </ValidatorForm>
      </DialogContent>

      <DialogActions>
        <div className={classes.dialogActionsButtonsArea}>
          <CButton
            isLoading={isLoading}
            variant="outlined"
            onClick={props.onCanceled}
          >
            キャンセル
          </CButton>

          <div className={classes.dialogActionsParts}>
            {props.showContinuousCreate && (
              <CButton
                className={classes.dialogActionsPartsItem}
                isLoading={isLoading}
                disabled={!isValid}
                onClick={async () => {
                  const created = await callWithLoading(async () => {
                    const created = await props.create(
                      customer,
                      customerSalonInformation
                    );

                    return created;
                  });
                  props.pushSnackbar(created.id, created.name);
                  setCustomer(
                    emptyCustomer(
                      undefined,
                      props.customSetting.customerForm?.sex?.default ??
                        CustomerSex.Female
                    )
                  );
                }}
              >
                連続して作成
              </CButton>
            )}
            <CButton
              className={classes.dialogActionsPartsItem}
              isLoading={isLoading}
              disabled={!isValid}
              onClick={async () => {
                const created = await callWithLoading(async () => {
                  const created = await props.create(
                    customer,
                    customerSalonInformation
                  );

                  return created;
                });
                props.onCreateAndView(created.id);
              }}
            >
              作成
            </CButton>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default CreateCustomerDialog;
