import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      d="M30.21 31.15a4.18 4.18 0 0 0-.35 2.58 4.29 4.29 0 0 0 3.5 3.51 4.191 4.191 0 0 0 2.58-.35 4.31 4.31 0 0 0 2.45-4.32 4.31 4.31 0 0 0-3.86-3.86 4.31 4.31 0 0 0-4.31 2.44zm2.26 1.07a1.79 1.79 0 0 1 .74-.8c.272-.15.579-.23.89-.23h.18a1.84 1.84 0 0 1 1.1.53c.3.293.484.683.52 1.1a1.82 1.82 0 0 1-.22 1.07 1.76 1.76 0 0 1-.81.74 1.79 1.79 0 0 1-2.55-1.33 1.69 1.69 0 0 1 .15-1.08"
    />
    <path
      fill={props.color || 'currentColor' || '#000'}
      d="M45.27 30.2a1.77 1.77 0 0 0-.21-1.2L43 25.45a1.85 1.85 0 0 0-.94-.76 1.79 1.79 0 0 0-1.23 0l-1.91.77a6.991 6.991 0 0 0-.75-.44l-.3-2.12a1.75 1.75 0 0 0-.61-1 1.79 1.79 0 0 0-1.12-.41h-2.65A3.14 3.14 0 0 1 34 24h1.49l.23 1.65c.05.325.176.634.37.9a2 2 0 0 0 .76.62c.297.147.58.317.85.51a2 2 0 0 0 .92.34 1.83 1.83 0 0 0 1-.13l1.55-.62 1.41 2.43-1.32 1a2.05 2.05 0 0 0-.76 1.76v.49c0 .274-.02.548-.06.82a1.23 1.23 0 0 0 .49 1.23l1.65 1.3-1.41 2.4-1.55-.63a1.999 1.999 0 0 0-1-.12 2 2 0 0 0-.92.34 6.205 6.205 0 0 1-.87.51 2 2 0 0 0-1.11 1.53L35.51 42h-2.82l-.23-1.65a2 2 0 0 0-1.12-1.52 8.008 8.008 0 0 1-.86-.5 2 2 0 0 0-.93-.35h-.22c-.256 0-.51.048-.75.14l-1.58.59-1.4-2.43 1.32-1a2 2 0 0 0 .6-.79 2 2 0 0 0 .15-1v-.97a2.07 2.07 0 0 0-.16-1c0-.05-.06-.09-.09-.15a2.718 2.718 0 0 1-.54-.15l-2.29-.92-.39.22-.16 1.15 1.12.88v.88l-1.65 1.3a1.79 1.79 0 0 0-.61 1c-.085.438 0 .893.24 1.27l2.05 3.55c.213.359.546.632.94.77.396.15.834.15 1.23 0l1.91-.76c.24.15.49.3.75.44l.3 2.11a1.79 1.79 0 0 0 1.74 1.42h4.11a1.87 1.87 0 0 0 1.13-.43 1.84 1.84 0 0 0 .6-1.1l.28-2c.26-.14.5-.28.75-.44l1.93.78a1.75 1.75 0 0 0 2.14-.83L45.06 37a1.77 1.77 0 0 0-.43-2.27L43 33.43v-.88l1.65-1.29a1.76 1.76 0 0 0 .62-1.06M21.94 15.07a5.74 5.74 0 0 0-6.86-.93 5.7 5.7 0 0 0-2.81 5.95 5.72 5.72 0 0 0 4.65 4.65c.33.06.665.09 1 .09a5.73 5.73 0 0 0 5.68-6.28 5.83 5.83 0 0 0-1.66-3.48m-1.68 5.38a2.71 2.71 0 0 1-1.2 1.12 2.73 2.73 0 0 1-3.84-2 2.749 2.749 0 0 1 1.34-2.83c.41-.23.87-.35 1.34-.35h.27a2.71 2.71 0 0 1 2.43 2.44 2.63 2.63 0 0 1-.34 1.62"
    />
    <path
      fill={props.color || 'currentColor' || '#000'}
      d="m24.52 29.19 2.75 1.11a2.15 2.15 0 0 0 1.52 0 2.23 2.23 0 0 0 1.19-1l2.82-4.88a2.21 2.21 0 0 0-.54-2.83l-2.32-1.83v-1.4l2.36-1.85a2.26 2.26 0 0 0 .76-1.31 2.2 2.2 0 0 0-.26-1.51L30 8.84a2.2 2.2 0 0 0-1.17-1 2.15 2.15 0 0 0-1.54 0L24.52 9c-.387-.264-.791-.5-1.21-.71l-.42-3a2.24 2.24 0 0 0-2.17-1.78h-5.67a2.22 2.22 0 0 0-1.4.54 2.28 2.28 0 0 0-.76 1.36l-.41 2.92a10.99 10.99 0 0 0-1.2.7l-2.77-1.1A2.18 2.18 0 0 0 7 7.9c-.5.181-.92.536-1.18 1L3 13.75a2.22 2.22 0 0 0 .53 2.83l2.33 1.83v1.42L3.5 21.68A2.22 2.22 0 0 0 2.74 23 2.16 2.16 0 0 0 3 24.49l2.82 4.89c.261.463.68.818 1.18 1a2.27 2.27 0 0 0 1.54 0l2.73-1.1c.39.256.794.49 1.21.7l.43 3a2.22 2.22 0 0 0 2.17 1.78h5.67a2.21 2.21 0 0 0 2.15-1.89l.41-2.92c.417-.231.82-.485 1.21-.76m-2.73-1.89c-.38.18-.707.456-.95.8a2.38 2.38 0 0 0-.46 1.14L20 31.7h-4.24l-.34-2.44A2.57 2.57 0 0 0 15 28.1a2.63 2.63 0 0 0-1-.79 8 8 0 0 1-1.2-.7 2.61 2.61 0 0 0-1.18-.44h-.28a2.37 2.37 0 0 0-.95.19l-2.31.93-2.14-3.71 2-1.54c.33-.267.591-.61.76-1a2.46 2.46 0 0 0 .19-1.23v-.69a5.43 5.43 0 0 1 0-.69 2.6 2.6 0 0 0-.21-1.23 2.54 2.54 0 0 0-.76-1l-2-1.54L8.07 11l2.32.93c.392.161.819.22 1.24.17a2.62 2.62 0 0 0 1.16-.44c.38-.28.785-.524 1.21-.73a2.55 2.55 0 0 0 1-.8A2.64 2.64 0 0 0 15.42 9l.35-2.47H20L20.38 9a2.56 2.56 0 0 0 1.42 1.94c.423.202.828.439 1.21.71.35.238.75.389 1.17.44a2.5 2.5 0 0 0 1.24-.17l2.31-.92 2.13 3.68-2 1.54a2.59 2.59 0 0 0-.77 1 2.5 2.5 0 0 0-.2 1.23v.7c0 .388-.03.776-.09 1.16a1.51 1.51 0 0 0 .56 1.39l2.43 1.9-2.13 3.68-2.32-.93a2.6 2.6 0 0 0-1.24-.16 2.53 2.53 0 0 0-1.16.44 10.51 10.51 0 0 1-1.15.67"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgCogs = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgCogs);
export default Memo;
