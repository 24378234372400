import { ChatMessageBody } from '../../models/chatMessageBody/entity';
import { ChatMessageBodyImage as ChatMessageBodyImageSchema } from './schema';

export class ChatMessageBodyImage implements ChatMessageBody {
  urls: string[];

  constructor(args: ChatMessageBodyImageSchema) {
    this.urls = args.urls;
  }
}
