import { DiscountType, IServiceDiscount } from '@karutekun/core/salon-service';
import { VoucherFormData } from '@karutekun/shared/data-access/voucher';
import { useArray } from '@karutekun/shared/util/react-hooks';
import {
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback, useEffect, useState } from 'react';
import { CDiscountIcon } from '../../../../../components_old/atoms/CDiscountIcon';
import CMoneyInput from '../../../../../components_old/atoms/CMoneyInput';
import CRadioGroup from '../../../../../components_old/atoms/CRadioGroup';
import { useSelectSalonServices } from '../../../../../selectors/serviceSelector';

type Props = {
  open: boolean;
  voucher: VoucherFormData;
  discount: IServiceDiscount | null;
  onApplyToTotal(discount: IServiceDiscount): void;
  onApplyToLines(lineIndices: number[], discount: IServiceDiscount): void;
  onCancel(): void;
};

const useStyles = makeStyles((theme: Theme) => ({
  listItemSecondaryAction: {
    paddingRight: 80,
  },
  discountText: {
    color: theme.palette.primary.main,
  },
}));

const ApplyDiscountDialog: FC<Props> = (props) => {
  const { open, voucher, discount, onCancel, onApplyToLines, onApplyToTotal } =
    props;

  const { serviceMap } = useSelectSalonServices();
  const type: DiscountType = discount?.type ?? DiscountType.Price;
  const [value, setValue] = useState(0);
  const [discountTargetType, setDiscountTargetType] = useState<
    'total' | 'lines'
  >('total');
  const [targetLineIndices, targetLineIndicesMutations] = useArray<number>([]);

  useEffect(() => {
    if (open) {
      setValue(discount?.value ?? 0);
      setDiscountTargetType('total');
      targetLineIndicesMutations.set([]);
    }
  }, [discount?.value, open, targetLineIndicesMutations]);

  const handleClickLine = useCallback(
    (index: number) => {
      setDiscountTargetType('lines');
      targetLineIndicesMutations.toggle(index);
    },
    [targetLineIndicesMutations]
  );

  const handleClickOk = useCallback(() => {
    if (!discount) {
      return;
    }
    if (discountTargetType === 'total') {
      onApplyToTotal({ ...discount, value });
    } else {
      onApplyToLines(targetLineIndices, { ...discount, value });
    }
  }, [
    discount,
    discountTargetType,
    onApplyToLines,
    onApplyToTotal,
    targetLineIndices,
    value,
  ]);

  const valueLabel = type === DiscountType.Price ? '割引額' : '割引率(%)';

  return (
    <Dialog open={open} onClose={onCancel} maxWidth="xs">
      <DialogTitle>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <CDiscountIcon size={24} />
          </Grid>
          <Grid item>{discount?.name}</Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {type === DiscountType.Price ? (
              <CMoneyInput
                label={valueLabel}
                allowNegative={false}
                value={value}
                onChangeAmount={(amount) => {
                  setValue(Number(amount));
                }}
              />
            ) : (
              <TextField
                variant="standard"
                label={valueLabel}
                value={value.toString()}
                onChange={(e) => {
                  const v = Number(e.target.value) || 0;
                  setValue(Math.max(Math.min(v, 100), 0));
                }}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <CRadioGroup
              label="割引対象"
              currentValue={discountTargetType === 'total' ? 0 : 1}
              options={[
                { value: 0, label: '会計金額に対して割引' },
                { value: 1, label: '個別メニューに対して割引' },
              ]}
              onChange={(v) =>
                setDiscountTargetType(v === 0 ? 'total' : 'lines')
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Collapse
              in={discountTargetType === 'lines'}
              timeout="auto"
              unmountOnExit
            >
              <List>
                {voucher.lines.map((vl, i) => (
                  <VoucherLineListItem
                    key={i}
                    disabled={discountTargetType !== 'lines'}
                    serviceName={serviceMap[vl.serviceId]?.name ?? '?'}
                    isSelected={targetLineIndices.includes(i)}
                    onClick={() => handleClickLine(i)}
                  />
                ))}
              </List>
            </Collapse>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onCancel}>
          キャンセル
        </Button>
        <Button variant="contained" color="primary" onClick={handleClickOk}>
          割引を適用
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const VoucherLineListItem: FC<{
  serviceName: string;
  isSelected: boolean;
  disabled: boolean;
  onClick(): void;
}> = (props) => {
  const classes = useStyles();

  const { serviceName, isSelected, disabled, onClick } = props;

  return (
    <ListItem
      dense
      classes={{ secondaryAction: classes.listItemSecondaryAction }}
      onClick={() => onClick()}
      disabled={disabled}
      button
    >
      <ListItemAvatar>
        <Checkbox edge="start" checked={isSelected} disableRipple />
      </ListItemAvatar>
      <ListItemText primary={serviceName} />
    </ListItem>
  );
};

export default ApplyDiscountDialog;
