import { theme } from '@karutekun/shared-fe/react-ui-old';

// tslint:disable
export const InformationText = {
  dashboard: {
    overall: `
##<span style="color:${theme.palette.primary.main}">概要</span>
本日の売上情報や来店記録を確認するためのページです。
お客様の来店に備えての準備や、会計状況の確認などにご活用ください。

<span style="color:#444;font-size:13px;">※ 本日の来店記録は施術開始時間が当日に設定されるものを指します。</span>
<span style="color:#444;font-size:13px;">※ 金額表記は税込・税抜を画面右上のボタンで切り替えられます。</span>
`,
    realTimeSalesCard: `
##<span style="color:${theme.palette.primary.main}">概要</span>
売上を施術、店販の2項目に分けて表示しています。

##<span style="color:${theme.palette.primary.main}">集計方法</span>
会計済みの来店記録を対象としてリアルタイム集計しています。
施術/店販の割引があった場合は、割引を反映させた後の金額を集計しています。
来店記録の「施術開始時刻」によって、集計対象の日にちが決定されます。

##<span style="color:${theme.palette.primary.main}">ヒント</span>
施術、店販それぞれの項目を毎日の目標数字と比較して活用しましょう。
`,
    realTimeCustomerCard: `
##<span style="color:${theme.palette.primary.main}">概要</span>
来店されたお客様を新規/固定に分類し、平均したお客様の支払い単価も表示しています。

##<span style="color:${theme.palette.primary.main}">集計方法</span>
来店回数は「来店記録」を対象として、来店回数が1回のお客様を新規、2回以上のお客様を固定としてリアルタイム集計しています。

平均単価は「会計済みの来店記録」を対象として、総売上金額を総来店回数で割って計算しています。

##<span style="color:${theme.palette.primary.main}">ヒント</span>
新規、固定それぞれの来店人数を毎日の目標数字と比較して活用しましょう。
`,
  },
  salon: {
    overall: `
##<span style="color:${theme.palette.primary.main}">概要</span>
店舗全体に焦点を当てた分析を行うページです。
売上の構成要素やお客様の属性、席の稼働状況をまとめて検証することで、改善の余地がある箇所の特定を行います。
改善箇所が具体的に特定できたら、より詳細な個別レポートで具体的な改善策を検討してみましょう。
また期間を自由に設定することで、ピークシーズンや閑散期に狙い通りの集客、営業活動ができているかの確認をしてみましょう。

<span style="color:#444;font-size:13px;">※ 各集計項目の反映には最大1日程度かかる場合があります。</span>
<span style="color:#444;font-size:13px;">※ 金額表記は税込・税抜を画面右上のボタンで切り替えられます。</span>
`,
    salesCard: `
##<span style="color:${theme.palette.primary.main}">概要</span>
売上を施術、店販の2項目に分けて表示しています。

##<span style="color:${theme.palette.primary.main}">集計方法</span>
会計済みの来店記録を対象として集計しています。
施術/店販の割引があった場合は、割引を反映させた後の金額を集計しています。
来店記録の「施術開始時刻」によって、集計対象の日にちが決定されます。

<span style="color:#444;font-size:13px;">※ 来店記録の会計対応が終わってから集計に最大30分ほどかかる場合があります。</span>
<span style="color:#444;font-size:13px;">※ 来店記録の日にちを変更した場合は集計に最大1日程度かかる場合があります。</span>

##<span style="color:${theme.palette.primary.main}">ヒント</span>
施術、店販それぞれの項目を毎日の目標数字と比較して活用しましょう。
`,
    customerCard: `
##<span style="color:${theme.palette.primary.main}">概要</span>
来店されたお客様を新規/固定に分類し、平均したお客様の支払い単価も表示しています。

##<span style="color:${theme.palette.primary.main}">集計方法</span>
来店回数は「来店記録」を対象として、来店回数が1回のお客様を新規、2回以上のお客様を固定として集計しています。

平均単価は「会計済みの来店記録」を対象として、総売上金額を総来店回数で割って計算しています。

来店記録の会計対応が終わってから集計に反映されるまで最大30分ほどかかる場合があります。

##<span style="color:${theme.palette.primary.main}">ヒント</span>
新規、固定それぞれの来店人数を毎日の目標数字と比較して活用しましょう。
`,
    monthlySales: `
##<span style="color:${theme.palette.primary.main}">概要</span>
月次での売上を集計して表示しています。傾向を確認することで改善策の効果確認や、改善点がありそうな箇所の特定に活用します。

##<span style="color:${theme.palette.primary.main}">集計方法</span>
「施術開始時間」が対象月の、「会計済みの来店記録」を集計対象としています。集計項目は対象来店記録の施術、店販、それぞれの割引額を反映した値です。

##<span style="color:${theme.palette.primary.main}">ヒント</span>
単月での分析では見落としがちな変化を傾向を追うことで発見できます。想定よりも上振れしていた場合にはより経営の飛躍に繋がる示唆を発見するために、下振れていれば改善箇所の深掘りのために活用できます。季節要因を適切に排除しながら傾向を分析しましょう。
`,
    dailySales: `
##<span style="color:${theme.palette.primary.main}">概要</span>
売上を日次で集計して表示しています。月次の売上傾向から詳細な分析を行う期間を特定することで、より効果的に日次での売上分析を行うことが可能です。

##<span style="color:${theme.palette.primary.main}">集計方法</span>
「施術開始時刻」が対象日の、「会計済みの来店記録」を集計対象としています。集計項目は対象来店記録の施術、店販、それぞれの割引額を反映した値です。

##<span style="color:${theme.palette.primary.main}">ヒント</span>
売上目標を達成していても、それが施術、店販それぞれで達成できていたのか確認することは重要です。どちらかが大幅に達成することで売上目標に到達していたのであれば、上振れした項目はよりお店の強みを伸ばすための分析に、下振れした項目は弱点克服のための分析に活用できます。
`,
    dailyShimei: `
##<span style="color:${theme.palette.primary.main}">概要</span>
来店されたお客様人数を指名有無で分けて日次で集計して表示しています。

##<span style="color:${theme.palette.primary.main}">集計方法</span>
指定期間の来店記録が集計対象です。
`,
    dailyCustomers: `
##<span style="color:${theme.palette.primary.main}">概要</span>
来店されたお客様人数を新規/固定に分けて日次で集計して表示しています。

##<span style="color:${theme.palette.primary.main}">集計方法</span>
指定期間の来店記録が集計対象です。
1回目の来店の場合は新規として、2回目以降の来店は固定として集計されます。
平均単価は期間中の総売上を会計済み来店回数で割って計算しています。

##<span style="color:${theme.palette.primary.main}">ヒント</span>
固定のお客様の来店人数が右肩上がりに上昇していれば良い傾向です。理想的な経営状態では、徐々に新規のお客様の人数は減っていき、固定客のお客様で予約が埋まります。もし大幅な固定客の人数増減や、客単価の変化があった場合はその付近で作成された来店記録を確認してみましょう。
`,
    menuAnalytics: `
##<span style="color:${theme.palette.primary.main}">概要</span>
指定された期間での施術売上を回数及び売上金額で表示しています。

##<span style="color:${theme.palette.primary.main}">集計方法</span>
「施術開始時刻」が対象日の来店記録を集計対象としています。
集計対象の来店記録で選択されたすべての施術項目を選択回数と金額で集計しています。
売上(割引適用前)は、施術の金額×選択回数で計算されています。
売上(割引適用後)は、施術の割引を施術金額に応じて按分しています。
例：カット4,000円、カラー6,000円、割引1,000円で選択された場合、カットは3,600円、カラーは5,400円で集計されます。

##<span style="color:${theme.palette.primary.main}">ヒント</span>
提供回数が多い施術は人気であることがわかります。それぞれの施術の売上への寄与度がどれくらいなのかを確認してみましょう。割引適用前と後を比較して割引額も確認してみましょう。
`,
    productAnalytics: `
##<span style="color:${theme.palette.primary.main}">概要</span>
指定された期間での店販売上を回数及び売上金額で表示しています。

##<span style="color:${theme.palette.primary.main}">集計方法</span>
「施術開始時刻」が対象日の来店記録を集計対象としています。
集計対象の来店記録で選択されたすべての店販項目を選択回数と金額で集計しています。
売上(割引適用前)は、店販の金額×選択回数で計算されています。
売上(割引適用後)は、店販の割引を店販金額に応じて按分しています。
例：シャンプー2,000円、トリートメント3,000円、割引1,000円で選択された場合、シャンプーは1,600円、トリートメントは2,400円で集計されます。

##<span style="color:${theme.palette.primary.main}">ヒント</span>
提供回数が多い店販は人気であることがわかります。それぞれの店販の売上への寄与度がどれくらいなのかを確認してみましょう。割引適用前と後を比較して割引額も確認してみましょう。
`,
    dayOfWeekVisitTime: `
##<span style="color:${theme.palette.primary.main}">概要</span>
指定された期間の曜日・時間別の平均滞在人数を表示しています。色が濃い時間帯は多くのお客様が滞在していることを示しています。

##<span style="color:${theme.palette.primary.main}">集計方法</span>
「施術開始時刻」が指定された期間の来店記録を集計対象にしています。60分滞在した場合は1人とし、端数の場合は小数点で表示されています。
例: 9:45〜11:45に滞在していた場合は9時に0.25人、11時に1人、10時に0.75人として計算されます。

##<span style="color:${theme.palette.primary.main}">ヒント</span>
店舗の稼働状況が確認できるため、比較的空きが目立つ曜日や時間がある場合はその時間・曜日限定のメニューなどを考案することで空きを減らすことが可能になるかもしれません。または、スタッフの稼働日を調整したりすることで少人数での運用ができる可能性もあります。それぞれの時間帯が十分に稼働しているのか、1ヶ月単位で分析してみると良いでしょう。
`,
    dayOfWeekCustomerNum: `
##<span style="color:${theme.palette.primary.main}">概要</span>
指定された期間での、曜日別の1日の平均来店人数とその内訳を表示しています。

##<span style="color:${theme.palette.primary.main}">集計方法</span>
「施術開始時刻」が指定された期間の「会計済みの来店記録」を集計対象にしています。集計対象の来店記録のうち、初回来店の場合は新規、2回目以降の来店の場合は固定として集計しています。
平均単価は期間中の総売上をお客様の来店回数で割って計算しています。

##<span style="color:${theme.palette.primary.main}">ヒント</span>
曜日によって来店人数の多い、少ないの確認やお客様層の違いを確認してみましょう。固定客が相対的に多い曜日は、固定客を多く持っているスタッフの出勤が関係しているなど、お店の強みの理解に役立ちます。相対的に来店人数が少ない曜日は、人数を増やすためのキャンペーンやスタッフの休暇に充ててみると良いでしょう。
`,
    dayOfWeekSales: `
##<span style="color:${theme.palette.primary.main}">概要</span>
指定された期間での、曜日別の1日の平均売上の内訳を表示しています。

##<span style="color:${theme.palette.primary.main}">集計方法</span>
「施術開始時刻」が指定された期間の会計済みの来店記録を集計対象にしています。集計項目は対象来店記録の施術、店販に対してそれぞれの割引額を反映させた金額です。

##<span style="color:${theme.palette.primary.main}">ヒント</span>
曜日ごとに差異が見られる場合には、その理由を特定するとお店の強み・弱みの発見に繋がります。どのようなお客様がどのような購買行動をとっているため、曜日別の差が生まれるのか考えてみると良いでしょう。
`,
    stylistGrade: `
##<span style="color:${theme.palette.primary.main}">概要</span>
指定された期間での、所属しているスタッフ全員の売上情報を表示しています。

<span style="color:#444;font-size:13px;">※ 各集計項目の反映には最大1日程度かかる場合があります。</span>

##<span style="color:${theme.palette.primary.main}">集計方法</span>

> ##総売上
主担当売上+店販売上

> ##主担当売上
主担当として指定された来店記録の施術売上を、担当者関係なく合計したものです。来店記録の指名の有無別に集計されます。

> ##施術売上
担当した施術の売上を合計したものです。指名の有無別に集計されます。
※ 担当スタッフが複数人設定されているメニューの売上は、各スタッフに分配されて集計されます。

> ##店販売上
担当した店販の売上を合計したものです。指名の有無別に集計されます。
※ 担当スタッフが複数人設定されているメニューの売上は、各スタッフに分配されて集計されます。

例: ある来店記録の主担当がAで、施術はカット3,000円(Aが担当)、カラー4,000円(Bが担当)、店販はシャンプー2,000円(Aが担当)、トリートメント2,000円(Bが担当)の場合

\`\`\`
Aの主担当売上=7,000円
Aの施術売上=3,000円
Aの店販売上=2,000円
\`\`\`
\`\`\`
Bの主担当売上=0円
Bの施術売上=4,000円
Bの店販売上=2,000円
\`\`\`

となり、合計して

\`\`\`
Aの総売上=9,000円
Aの担当メニュー売上=5,000円
\`\`\`
\`\`\`
Bの総売上=2,000円
Bの担当メニュー売上=6,000円
\`\`\`

となります。

> ##単価
総売上を担当回数で割った値となります。

> ##担当回数(会計済み)
担当回数は主担当になった来店回数の数を表示しています。
カッコの中は、会計済みの来店回数です。

> ##固定客比率
指定期間のなかで以下の計算式で計算されます。
\`\`\`
2回目以降来店の合計回数 ÷ (1回目来店の合計回数 + 2回目以降来店の合計回数)
\`\`\`
<span style="color:#444;font-size:13px;">※ 同一のお客様も指定期間内で1回目、2回目の来店があった場合は重複して計算されます。</span>

##<span style="color:${theme.palette.primary.main}">集計対象について</span>
固定客比率と担当回数は「施術開始時刻」が指定された期間の来店記録を集計対象にしています。

売上や単価は同じ期間の「会計済みの来店記録」を集計対象にしています。

##<span style="color:${theme.palette.primary.main}">ヒント</span>
固定客比率の成績が良いスタッフに注目をしてみましょう。固定客比率が高いということは、リピーターとなるお客様を獲得できていることを意味します。スタッフ個別のレポートに移動して、お客様に支持されている理由を確認してみましょう。
`,
    sexAge: `
##<span style="color:${theme.palette.primary.main}">概要</span>
お客様の男女・年齢別の構成を表示しています。

##<span style="color:${theme.palette.primary.main}">集計方法</span>
指定された期間で、来店記録のお客様の性別と年齢を集計しています。

##<span style="color:${theme.palette.primary.main}">ヒント</span>
指定期間を1-2ヶ月程度と短めに設定することで、来店されたお客様の性別や年齢が期間によって変動がないかを確認してみましょう。季節要因などが発見できれば、特定のお客様層に絞ったキャンペーンも活用できます。

逆に3-6ヶ月と長めに設定することで、支持されているお客様層を特定することにも活用できます。
`,
    downloadDailySalesSummary: `
##<span style="color:${theme.palette.primary.main}">概要</span>
指定された範囲の売上と支払い方法をCSVでダウンロードできます。

新規来店記録作成から最大で30分程度、過去の来店記録を変更してから最大1日程度反映が遅延することがあります。
`,
  },

  stylists: {
    overall: `
##<span style="color:${theme.palette.primary.main}">概要</span>
個別のスタッフに焦点を当てて分析を行うページです。
固定客セグメントのお客様推移を見ることで、お客様との長期的な信頼関係を築けているかを確認できます。
また、指定された期間での施術情報の統計データや、各種チャートを確認することができます。統計データから気になる箇所が見つかった場合は、すぐ下に表示されているスタッフの担当した来店記録からより詳細な原因調査に進みましょう。

<span style="color:#444;font-size:13px;">※ 各集計項目の反映には最大1日程度かかる場合があります。</span>
<span style="color:#444;font-size:13px;">※ 金額表記は税込・税抜を画面右上のボタンで切り替えられます。</span>
`,
    dailyRepeat: `
##<span style="color:${theme.palette.primary.main}">概要</span>
選択されたスタッフの固定客人数の推移を表示しています。

##<span style="color:${theme.palette.primary.main}">集計方法</span>
固定客セグメントのお客様の人数を指定されたスタッフ別に毎日集計しています。
<span style="color:#444;font-size:13px;">※ 固定客セグメント：来店回数が2回以上で、現在時刻が最終来店日から来店周期+30日よりも前のお客様を指します。来店を継続しているリピーターのお客様を示す指標となります。</span>
<span style="color:#444;font-size:13px;">※ 来店周期は最新5回の来店間隔の平均日数です。</span>

初回来店のお客様が固定客セグメントになると固定客セグメントのお客様人数は増えます。
固定客セグメントのお客様が固定失客セグメントへ変わるか、別のスタッフが最終担当者になった場合に固定客セグメントの人数が減少します。

指名有無は、集計日までの最終来店の来店記録の指名有無を集計しています。

##<span style="color:${theme.palette.primary.main}">ヒント</span>
固定客推移が右肩上がりのグラフになっていればお客様の支持を順調に取り付けていることを意味しています。
一方売上金額があったとしても、固定客数が増加していない場合は経営の安定には繋がりません。
売上金額の大小にとらわれずに、固定客数の増加に注目することが安定した店舗経営には必須です。
`,
    monthlyLost: `
##<span style="color:${theme.palette.primary.main}">概要</span>
選択されたスタッフの毎月の固定失客、新規失客の人数推移を表示しています。

##<span style="color:${theme.palette.primary.main}">集計方法</span>
固定客セグメント、新規客セグメントのお客様が該当月にそれぞれ固定失客セグメント、新規失客セグメントへ移動した場合に集計に加算しています。
<span style="color:#444;font-size:13px;">※ 固定失客セグメント:来店回数が2回以上で、現在時刻が最終来店日から来店周期+30日よりも後のお客様を指します。来店周期は最新5回の来店間隔の平均日数です。</span>
<span style="color:#444;font-size:13px;">※ 新規失客セグメント:来店回数が1回で、現在時刻が最終来店日から90日よりも後のお客様を指します。</span>

セグメントの移動が発生するたびに加算されますので、同一のお客様が複数回セグメント移動を行うことで重複して加算されることがあります。
例:1月に固定客セグメントから固定失客セグメントに移動したものの、2月に再度来店を行い固定客セグメントへ戻り、5月に再び固定失客セグメントへ移動した場合は、1月と5月のそれぞれで固定失客でカウントされます。

##<span style="color:${theme.palette.primary.main}">ヒント</span>
失客を防ぐことはお店の経営安定にとって最重要事項です。失客数が増えていないかを常に確認しましょう。失客が近いお客様一覧を確認し、再来店を促してみると良いでしょう。
すでに失客してしまったお客様は、必ず毎月全員確認し最後に作成した来店記録を振り返って失客を招いた原因を特定すると良いでしょう。
`,
    salesSummary: `
##<span style="color:${theme.palette.primary.main}">概要</span>
指定された期間の各種売上データが表示されています。

##<span style="color:${theme.palette.primary.main}">集計方法</span>
「施術開始時刻」が指定された期間の「会計済みの来店記録」を集計対象にしています。

> ##総売上
主担当売上+店販売上です。

> ##主担当売上
主担当として指定された来店記録の施術売上を、担当者関係なく合計したものです。来店記録の指名の有無別に集計されます。

> ##施術売上
担当した施術の売上を合計したものです。指名の有無別に集計されます。
※ 担当スタッフが複数人設定されているメニューの売上は、各スタッフに分配されて集計されます。

> ##店販売上
担当した店販の売上を合計したものです。指名の有無別に集計されます。
※ 担当スタッフが複数人設定されているメニューの売上は、各スタッフに分配されて集計されます。

例: ある来店記録の主担当がAで、施術はカット3,000円(Aが担当)、カラー4,000円(Bが担当)、店販はシャンプー2,000円(Aが担当)、トリートメント2,000円(Bが担当)の場合

\`\`\`
Aの主担当売上=7,000円
Aの施術売上=3,000円
Aの店販売上=2,000円
\`\`\`
\`\`\`
Bの主担当売上=0円
Bの施術売上=4,000円
Bの店販売上=2,000円
\`\`\`

となり、合計して

\`\`\`
Aの総売上=9,000円
Aの担当メニュー売上=5,000円
\`\`\`
\`\`\`
Bの総売上=2,000円
Bの担当メニュー売上=6,000円
\`\`\`

となります。

##<span style="color:${theme.palette.primary.main}">ヒント</span>
売上表示を正確に行うためにも、来店記録作成時のメニュー別の担当者はしっかりと設定しましょう。
`,
    customersSummary: `
##<span style="color:${theme.palette.primary.main}">概要</span>
指定された期間でのお客様の担当回数に焦点を当てた各種データを表示しています。

##<span style="color:${theme.palette.primary.main}">集計方法</span>
「施術開始時刻」が指定された期間の、選択されたスタッフが主担当である「来店記録」を集計対象にしています。

> ##主担当回数
主担当になった回数です。

> ##新規
お客様の来店が1回目の回数です。

> ##固定
お客様の来店が2回目以降の回数です。

> ##指名有り
指名にチェックを付けた回数です。

> ##指名無し
指名にチェックを付けていない回数です。

> ##単価
総売上を「会計済みの主担当回数」で割った値です。

##<span style="color:${theme.palette.primary.main}">ヒント</span>
主担当として設定されたスタッフでないと集計されないことに注意しましょう。
`,
    dailySales: `
##<span style="color:${theme.palette.primary.main}">概要</span>
指定期間での売上の日別の推移を表示しています。

##<span style="color:${theme.palette.primary.main}">集計方法</span>
「施術開始時刻」が指定された期間の、選択されたスタッフが主担当である「会計済みの来店記録」を集計対象にしています。

> ##主担当売上
主担当として指定された来店記録の施術売上を合計したものです。

> ##店販売上
対象来店記録のうち、店販での販売担当者別の売上金額を合計したものです。
`,
    dailyShimei: `
##<span style="color:${theme.palette.primary.main}">概要</span>
指定期間での来店記録の指名の有無の推移を表示しています

##<span style="color:${theme.palette.primary.main}">集計方法</span>
選択されたスタッフが主担当で設定された来店記録を、日毎に指名の有無で集計しています。
`,
    dailyNewRepeat: `
##<span style="color:${theme.palette.primary.main}">概要</span>
指定期間でのお客様が新規・固定どちらであったかの推移を表示しています

##<span style="color:${theme.palette.primary.main}">集計方法</span>
選択されたスタッフが主担当で設定された来店記録を、お客様の来店回数が1回目か2回目以降かで集計しています。

##<span style="color:${theme.palette.primary.main}">ヒント</span>
まずは固定客推移表から十分な固定客のお客様を抱えられているのかを確認しましょう。
固定客のお客様が十分に獲得できていれば、新規よりも固定のお客様の比率が増えるはずです。十分な数の固定客のお客様がいない場合は、新規のお客様のご対応ができているのか確認しましょう。
`,
    menuAnalytics: `
##<span style="color:${theme.palette.primary.main}">概要</span>
スタッフごとに施術別に担当した回数、合計金額(割引適用前)、合計金額(割引適用後)で集計し、表示したチャートです。

##<span style="color:${theme.palette.primary.main}">集計方法</span>
指定された期間での来店記録を、施術ごとの担当者で集計しています。
合計金額(割引適用後)は施術それぞれの金額比率によって割引額を按分しています。
例：スタッフ1がカット4,000円、スタッフ2がカラー6,000円を担当し、合計で1,000円引きの割引を行った場合。
合計金額(割引適用前)：スタッフ1はカット4,000円、スタッフ2はカラー6,000円が集計
合計金額(割引適用後)：スタッフ1はカット3,600円、スタッフ2はカラー5,400円が集計)
<span style="color:#444;font-size:13px;">※ 来店記録の主担当ではなく、施術別担当者での集計であることにご注意ください。</span>

##<span style="color:${theme.palette.primary.main}">ヒント</span>
来店の際にどのような施術を担当しているかが把握できます。合計金額を確認することで、売上には表示されないスタッフの貢献度合いを確認できます。
正確にチャートを活用するためにも、メニュー別担当者の設定は正確に行いましょう。
`,
    productAnalytics: `
##<span style="color:${theme.palette.primary.main}">概要</span>
スタッフごとに店販別に担当した回数、合計金額(割引適用前)、合計金額(割引適用後)で集計し、表示したチャートです。

##<span style="color:${theme.palette.primary.main}">集計方法</span>
指定された期間での来店記録を、店販ごとの担当者で集計しています。
合計金額(割引適用後)は店販それぞれの金額比率によって割引額を按分しています。
例：スタッフ1がシャンプー3,000円、スタッフ2がヘアオイル2,000円を販売し、合計で1,000円引きの割引を行った場合。
合計金額(割引適用前)：スタッフ1はシャンプー3,000円、スタッフ2はヘアオイル2,000円が集計
合計金額(割引適用後)：スタッフ1はシャンプー2,400円、スタッフ2はヘアオイル1,600円が集計
<span style="color:#444;font-size:13px;">※ 来店記録の主担当ではなく、店販別担当者での集計であることにご注意ください。</span>

##<span style="color:${theme.palette.primary.main}">ヒント</span>
スタッフの得意/不得意を確認しスタッフの商品販売力の底上げに活用しましょう。
`,
    sexAge: `
##<span style="color:${theme.palette.primary.main}">概要</span>
スタッフ別のお客様の男女・年齢別の構成を表示しています。

##<span style="color:${theme.palette.primary.main}">集計方法</span>
指定された期間で、選択されたスタッフが主担当である来店記録のお客様の性別と年齢を集計しています。

##<span style="color:${theme.palette.primary.main}">ヒント</span>
指定期間を1-2ヶ月程度と短めに設定することで、来店されたお客様の性別や年齢が期間によって変動がないかを確認してみましょう。季節要因などが発見できれば、特定のお客様層に絞ったキャンペーンも活用できます。

逆に3-6ヶ月と長めに設定することで、スタッフが支持されているお客様層を特定することにも活用できます。
`,
  },

  services: {
    overall: `
##<span style="color:${theme.palette.primary.main}">概要</span>
個別の施術・店販に焦点を当てて分析を行うページです。
分析したい期間とカテゴリを選択して集計を実行してみましょう。

「会計済み」の来店記録が集計対象となります。
指定可能な期間は最大で1年間です。
カテゴリ内で更に細かく分析を行いたい場合、個別メニューのチェックを外すことで施術・店販内での合計から除外することができます。

<span style="color:#444;font-size:13px;">※ 金額表記は施術や店販の料金を税込に直した金額になっています。</span>
`,
    columns: {
      totalAdjustedPrice:
        '会計時に行った価格調整後の合計価格です。割引は反映されません。',
      discountPercentage:
        '定価合計から割引按分後の合計価格への比率です。価格調整と割引を両方反映しています。',
      totalSalesPrice:
        '割引を全て反映した後の合計価格です。割引を各メニューに割り当てる際に1円単位の誤差が発生することがあります。',
      totalSalesPerStylist:
        '1つのメニューを複数人で担当した場合に、割引を全て反映した後の合計価格を担当者間で按分した金額です。',
      totalVoucherNum:
        '1度の来店で施術や店販を複数個選択されても、来店回数は1回とカウントされます。',
      pricePerVisit: '定価合計価格を来店回数で割り算したものです。',
      adjustedPricePerVisit:
        '価格調整後の合計価格を来店回数で割り算したものです。',
      salesPerVisit: '割引按分後合計価格を来店回数で割り算したものです。',
    },
    termSummary: {
      overall: `
##<span style="color:${theme.palette.primary.main}">概要</span>
指定期間内の全会計済み来店記録の施術・店販の合計です。

<span style="color:#444;font-size:13px;">※ 選択したカテゴリでの絞り込みは行われていません。</span>
`,
    },
  },
  customerCommon: {
    budgetDistribution: `
##<span style="color:${theme.palette.primary.main}">概要</span>
お客様が来店された時の支払い金額を1,000円刻みで集計しております。

##<span style="color:${theme.palette.primary.main}">集計方法</span>
該当セグメントのお客様の過去すべての会計済み来店記録で施術・店販の合計金額を集計しています。
`,
    visitMotivation: `
##<span style="color:${theme.palette.primary.main}">概要</span>
お客様の来店動機を集計して表示しています。

<span style="color:#444;font-size:13px;">※ 来店動機はアプリの設定ページから自由に追加・編集することが可能です。</span>

##<span style="color:${theme.palette.primary.main}">ヒント</span>
集客方法によって固定客になりやすい、なりにくいなどの傾向があるかもしれません。来店動機の項目を適切に設定することで、固定客のお客様を獲得しやすい集客方法を発見しましょう。
`,
    menuAnalytics: `
##<span style="color:${theme.palette.primary.main}">概要</span>
過去に来店された際にどのような施術を提供していたかを表示しています

##<span style="color:${theme.palette.primary.main}">集計方法</span>
最大10項目まで分類し、それ以上に項目がある場合は「その他」として表示されます。
`,
    productAnalytics: `
##<span style="color:${theme.palette.primary.main}">概要</span>
過去に来店された際にどのような店販商品を購入していたかを表示しています

##<span style="color:${theme.palette.primary.main}">集計方法</span>
最大10項目まで分類し、それ以上に項目がある場合は「その他」と表示されます。
`,
  },

  repeatCustomer: {
    overall: `
##<span style="color:${theme.palette.primary.main}">概要</span>
安定した経営で最も重要な固定客セグメントのお客様の分析に特化したページです。

固定客数が増加していれば店舗経営は安定していきます。表示されている統計データから、お店が支持されている理由を特定し、より強みを伸ばしていきましょう。

##<span style="color:${theme.palette.primary.main}">集計方法</span>
現在時刻における固定客セグメントのお客様の人数と、過去すべての来店での施術と店販の平均単価を表示しています。割引は施術、店販共に加味されて集計されています。

<span style="color:#444;font-size:13px;">※ 固定客セグメント：来店回数が2回以上で、現在時刻が最終来店日から来店周期+30日よりも前のお客様を指します。来店を継続しているリピーターのお客様を示す指標となります。</span>
<span style="color:#444;font-size:13px;">※ 来店周期は最新5回の来店間隔の平均日数です。</span>
<span style="color:#444;font-size:13px;">※ 各集計項目の反映には最大1日程度かかる場合があります。</span>
<span style="color:#444;font-size:13px;">※ 金額表記は税込・税抜を画面右上のボタンで切り替えられます。</span>
`,
    dailyCustomerNum: `
##<span style="color:${theme.palette.primary.main}">概要</span>
指定期間における固定客人数の推移を表示しています。

##<span style="color:${theme.palette.primary.main}">集計方法</span>
新規に来店されたお客様が固定客セグメントになると固定客のお客様人数は増えます。
固定客セグメントのお客様が固定失客セグメントへ進むと人数が減ります。

指名有無は、集計日までの最終来店の来店記録の指名有無を集計しています。

##<span style="color:${theme.palette.primary.main}">ヒント</span>
固定客推移が右肩上がりのグラフになっていればお客様の支持を順調に取り付けていることを意味しています。
一方売上金額があったとしても、固定客数が増加していない場合は経営の安定には繋がりません。
売上金額の大小にとらわれずに、固定客数の増加に注目することが安定した店舗経営には必須です。
`,
    visitNum: `
##<span style="color:${theme.palette.primary.main}">概要</span>
お客様の来店回数を集計して表示しています。

##<span style="color:${theme.palette.primary.main}">集計方法</span>
毎回の来店時に来店記録を作成していただき、その枚数で来店回数をカウントしています。
`,
    visitPeriod: `
##<span style="color:${theme.palette.primary.main}">概要</span>
お客様の平均的な来店までの日数を計算して集計しております。

##<span style="color:${theme.palette.primary.main}">集計方法</span>
直近5回の来店日数の間隔を平均して算出しております。
例：初回に来店し、2回目来店が45日後、3回目来店が更に35日後の場合
 (45 + 35) ÷ 2 = 40日
`,
    shimei: `
##<span style="color:${theme.palette.primary.main}">概要</span>
固定客が来店した際の、指名の有無を集計して表示しています。

##<span style="color:${theme.palette.primary.main}">集計方法</span>
最終来店の来店記録での指名の有無を集計しています。
`,
  },

  firstCustomer: {
    overall: `
##<span style="color:${theme.palette.primary.main}">概要</span>
お客様の初回来店に焦点を当てたページです。

初回来店時のお客様を統計的に分析することで、お客様からどのようにお店が見られているのかを把握することができます。固定客分析と合わせて行うことで、お店の理想としている客層にアプローチできているかを確認してみましょう。

<span style="color:#444;font-size:13px;">※ 各集計項目の反映には最大1日程度かかる場合があります。</span>
<span style="color:#444;font-size:13px;">※ 金額表記は税込・税抜を画面右上のボタンで切り替えられます。</span>
`,
    monthlyCustomerNum: `
##<span style="color:${theme.palette.primary.main}">概要</span>
初回来店のお客様数を月次で集計して表示しています

##<span style="color:${theme.palette.primary.main}">集計方法</span>
最も施術開始時間が早い来店記録が初回来店と判定されます。

##<span style="color:${theme.palette.primary.main}">ヒント</span>
初回来店数は固定客来店数とのバランスで考える必要があります。スタッフ数の増減などがない限りは固定客来店数が増加するにつれて、初回来店数は減少することが健全です。単純に右肩上がりをすれば良い固定客数とは異なり、常にバランスを見ながらチャートをみてみましょう。
`,
    shimei: `
##<span style="color:${theme.palette.primary.main}">概要</span>
お客様が初来店した日の、指名の有無を集計して表示しています。

<span style="color:#444;font-size:13px;">※ お客様の初来店日に2つ以上の来店記録がある場合、そのお客様の初来店日の最後の来店記録が集計されます。</span>
`,
    budgetDistribution: `
##<span style="color:${theme.palette.primary.main}">概要</span>
お客様が来店された時の支払金額を1,000円刻みで集計しております。

##<span style="color:${theme.palette.primary.main}">集計方法</span>
お客様の初来店日の会計済みの来店記録の施術・店販の合計金額を集計しています。
`,
    visitMotivation: `
##<span style="color:${theme.palette.primary.main}">概要</span>
お客様の来店動機を集計して表示しています。

<span style="color:#444;font-size:13px;">※ 来店動機はアプリの設定ページから自由に追加・編集することが可能です。</span>

##<span style="color:${theme.palette.primary.main}">ヒント</span>
集客方法によって新規集客に繋がりやすい、繋がりにくいなどの傾向があるかもしれません。来店動機の項目を適切に設定することで、新規のお客様を獲得しやすい集客方法を発見しましょう。
`,
    menuAnalytics: `
##<span style="color:${theme.palette.primary.main}">概要</span>
お客様が初来店日に、どのような施術を提供していたかを表示しています。

##<span style="color:${theme.palette.primary.main}">集計方法</span>
最大10項目まで分類し、それ以上に項目がある場合は「その他」として表示されます。
<span style="color:#444;font-size:13px;">※お客様の初来店日に2つ以上の来店記録がある場合、初来店日の全ての来店記録が集計対象となります。</span>
`,
    productAnalytics: `
##<span style="color:${theme.palette.primary.main}">概要</span>
お客様が初来店日に、どのような店販商品を購入していたかを表示しています。

##<span style="color:${theme.palette.primary.main}">集計方法</span>
最大10項目まで分類し、それ以上に項目がある場合は「その他」と表示されます。
<span style="color:#444;font-size:13px;">※お客様の初来店日に2つ以上の来店記録がある場合、初来店日の全ての来店記録が集計対象となります。</span>
`,
  },

  repeatLostCustomer: {
    overall: `
##<span style="color:${theme.palette.primary.main}">概要</span>
固定失客セグメントのお客様分析に特化したページです。

経営の安定に欠かせない固定客セグメントからの失客は最優先に調査すべき問題です。統計的な情報と共に、個別の失客してしまったお客様の来店記録を確認することで再発防止に努めましょう。

<span style="color:#444;font-size:13px;">※ 固定失客セグメント：来店回数が2回以上で、最終来店日から、来店周期+30日を過ぎてしまったお客様</span>
<span style="color:#444;font-size:13px;">※ 各集計項目の反映には最大1日程度かかる場合があります。</span>
<span style="color:#444;font-size:13px;">※ 金額表記は税込・税抜を画面右上のボタンで切り替えられます。</span>
`,
    monthlyCustomerNum: `
##<span style="color:${theme.palette.primary.main}">概要</span>
固定失客セグメントのお客様数を月次で集計して表示しています

##<span style="color:${theme.palette.primary.main}">集計方法</span>
現在のお客様セグメントが固定失客セグメントのお客様を最終来店月別に集計しています。一度固定失客セグメントに移行したお客様が再び来店された場合には、集計されていた人数が少なくなる可能性があります。

##<span style="color:${theme.palette.primary.main}">ヒント</span>
固定客のお客様が失客してしまっているのは早急に対応すべき問題です。失客が特に増えている時期や、共通点がないかを確認してみましょう。
`,
    shimei: `
##<span style="color:${theme.palette.primary.main}">概要</span>
失客した固定客の最終来店時の、指名の有無を集計して表示しています。

##<span style="color:${theme.palette.primary.main}">集計方法</span>
最終来店の来店記録での指名の有無を集計しています。
`,
  },

  newLostCustomer: {
    overall: `
##<span style="color:${theme.palette.primary.main}">概要</span>
新規失客セグメントのお客様分析に特化したページです。

新規に来店して頂いたものの、再来店に繋がらず固定客へとステップアップできなかった理由を分析することが可能です。施術などのサービス水準や教育水準の底上げのための分析と、お店のターゲットとなる客層を集客するための手段を見直す2つの視点から活用することができます。

<span style="color:#444;font-size:13px;">※ 新規失客セグメント：来店回数が1回で、最終来店日から90日を過ぎてしまったお客様</span>
<span style="color:#444;font-size:13px;">※ 各集計項目の反映には最大1日程度かかる場合があります。</span>
<span style="color:#444;font-size:13px;">※ 金額表記は税込・税抜を画面右上のボタンで切り替えられます。</span>
`,
    monthlyCustomerNum: `
##<span style="color:${theme.palette.primary.main}">概要</span>
新規失客セグメントのお客様数を月次で集計して表示しています

##<span style="color:${theme.palette.primary.main}">集計方法</span>
現在のお客様セグメントが新規失客セグメントのお客様を最終来店月別に集計しています。再び来店された場合には人数が少なくなる可能性があります。

##<span style="color:${theme.palette.primary.main}">ヒント</span>
新規失客が増えているのは施術に満足いただけなかった場合と、集客方法を適切に扱えなかった場合の2つが考えられます。他にも表示されているレポートを確認し、新規失客の傾向がどこに起因するのかを特定しましょう。
`,
    shimei: `
##<span style="color:${theme.palette.primary.main}">概要</span>
初回来店で失客したお客様の、来店時の指名の有無を集計して表示しています。
`,
    visitMotivation: `
##<span style="color:${theme.palette.primary.main}">概要</span>
初回来店で失客したお客様の、来店動機を集計して表示しています。

<span style="color:#444;font-size:13px;">※ 来店動機はアプリの設定ページから自由に追加・編集することが可能です。</span>

##<span style="color:${theme.palette.primary.main}">ヒント</span>
集客方法によって再来店に繋がりやすい、繋がりにくいなどの傾向があるかもしれません。来店動機の項目を適切に設定することで、再来店するお客様を獲得しやすい集客方法を発見しましょう。
`,
  },

  customers: {
    csvDownload: `
##<span style="color:${theme.palette.primary.main}">概要</span>
指定されたフィルタの範囲のカルテをCSVでダウンロードできます。
並び替えは反映されません。

個人情報閲覧権限があるスタッフのみ利用可能です。

##<span style="color: ${theme.palette.primary.main}">カルテダウンロード</span>
カルテの基本情報と来店動機が含まれています。
カルテ番号はカルテごとに一意に決まる番号です。
`,
  },
  karutes: {
    csvDownload: `
##<span style="color:${theme.palette.primary.main}">概要</span>
指定されたフィルタの範囲の来店記録をCSVでダウンロードできます。
並び替えは反映されません。

##<span style="color:${theme.palette.primary.main}">来店記録ダウンロード</span>
来店記録と支払い方法が含まれています。
来店記録番号は来店記録ごとに一意に決まる番号です。

##<span style="color:${theme.palette.primary.main}">施術・店販ダウンロード</span>
来店記録で選択された施術・店販が含まれています。
`,
  },
  visitHistories: {
    csvDownload: `
##<span style="color:${theme.palette.primary.main}">概要</span>
指定されたフィルタの範囲の来店記録をCSVでダウンロードできます。
並び替えは反映されません。

##<span style="color:${theme.palette.primary.main}">来店記録ダウンロード</span>
来店記録と支払い方法が含まれています。
来店記録番号は来店記録ごとに一意に決まる番号です。

##<span style="color:${theme.palette.primary.main}">施術・店販ダウンロード</span>
来店記録で選択された施術・店販が含まれています。
`,
  },
  counselings: {
    overall: `
##<span style="color:${theme.palette.primary.main}">概要</span>
サロン独自のカウンセリング項目を作成できます。

##<span style="color:${theme.palette.primary.main}">セクション</span>
いくつかの質問をまとめたものです。
セクション単位でのタイトルや説明が必要でしたら入力して下さい。

##<span style="color:${theme.palette.primary.main}">質問</span>
質問形式は複数選択、択一質問、プルダウン、記述式から選択できます。
タイトルは必須です。質問単位での説明が必要でしたら入力して下さい。

##<span style="color:${theme.palette.primary.main}">選択肢/質問文</span>
選択肢や記述式の質問文を記載して下さい。
複数選択、択一質問では、記述式の質問項目を追加することができます。
例: 「その他」の項目に、「その他詳細」の記述式質問を追加できます。
`,
  },
};
