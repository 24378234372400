import { deprecated } from 'typesafe-actions';

const { createAction } = deprecated;

export enum ActionNames {
  OpenSideMenu = 'general/OpenSideMenu',
}

export const openSideMenu = createAction(ActionNames.OpenSideMenu, (action) => {
  return (isOpen: boolean) => action({ isOpen });
});
