import { mapById } from '@karutekun/shared/util/objects';
import { Dispatch } from 'redux';
import {
  setSalonServiceCategoryMap,
  setSalonServiceDiscountMap,
  setSalonServiceMap,
} from '../../actions/salonAction';
import Logger, { AnalyticsEvent } from '../../logger';
import {
  PlainService,
  PlainServiceCategory,
  PlainServiceDiscount,
  serviceCategoryFromResource,
  serviceDiscountFromResource,
  serviceFromResource,
} from '../../models/service';
import { sendRequest } from '../request';

/**
 * カテゴリCRUD
 */
export function createServiceCategory(category: PlainServiceCategory) {
  return async function (dispatch: Dispatch): Promise<PlainServiceCategory> {
    const json = await sendRequest(
      dispatch,
      'salons/settings/services/categories',
      {
        method: 'POST',
        body: JSON.stringify({
          name: category.name,
          type: category.type,
          iconName: category.iconName,
          isVisible: category.isVisible,
        }),
      }
    );

    const created: PlainServiceCategory = serviceCategoryFromResource(json);

    dispatch(setSalonServiceCategoryMap({ [created.id]: created }));

    Logger.logEvent(AnalyticsEvent.createServiceCategory);

    return created;
  };
}
export function updateServiceCategory(category: PlainServiceCategory) {
  return async function (dispatch: Dispatch): Promise<PlainServiceCategory> {
    const json = await sendRequest(
      dispatch,
      `salons/settings/services/categories/${category.id}`,
      {
        method: 'POST',
        body: JSON.stringify({
          name: category.name,
          iconName: category.iconName,
          isVisible: category.isVisible,
        }),
      }
    );

    const updated: PlainServiceCategory = serviceCategoryFromResource(json);

    dispatch(setSalonServiceCategoryMap({ [updated.id]: updated }));

    Logger.logEvent(AnalyticsEvent.updateServiceCategory);

    return updated;
  };
}
export function deleteServiceCategory(categoryId: number) {
  return async function (dispatch: Dispatch): Promise<PlainServiceCategory> {
    const json = await sendRequest(
      dispatch,
      `salons/settings/services/categories/${categoryId}`,
      { method: 'DELETE' }
    );

    const deleted: PlainServiceCategory = serviceCategoryFromResource(json);

    dispatch(setSalonServiceCategoryMap({ [deleted.id]: deleted }));

    Logger.logEvent(AnalyticsEvent.deleteServiceCategory);

    return deleted;
  };
}
export function updateServiceCategoryOrder(categoryIds: number[]) {
  return async function (dispatch: Dispatch): Promise<PlainServiceCategory[]> {
    const json = await sendRequest(
      dispatch,
      `salons/settings/services/categories/order`,
      { method: 'POST', body: JSON.stringify({ categoryIds }) }
    );

    const categories: PlainServiceCategory[] = json.map(
      serviceCategoryFromResource
    );

    dispatch(setSalonServiceCategoryMap(mapById(categories)));

    Logger.logEvent(AnalyticsEvent.updateServiceCategoryOrder);

    return categories;
  };
}

/**
 * メニューCRUD
 */
export function createService(service: PlainService) {
  return async function (dispatch: Dispatch): Promise<PlainService> {
    const json = await sendRequest(dispatch, 'salons/settings/services', {
      method: 'POST',
      body: JSON.stringify({
        categoryId: service.categoryId,
        type: service.type,
        name: service.name,
        price: service.price,
        time: service.time,
        isTaxIncluded: service.isTaxIncluded,
        taxRate: service.taxRate,
        isVisible: service.isVisible,
      }),
    });

    const created: PlainService = serviceFromResource(json);

    dispatch(setSalonServiceMap({ [created.id]: created }));

    Logger.logEvent(AnalyticsEvent.createService);

    return created;
  };
}
export function updateService(service: PlainService) {
  return async function (dispatch: Dispatch): Promise<PlainService> {
    const json = await sendRequest(
      dispatch,
      `salons/settings/services/${service.id}`,
      {
        method: 'POST',
        body: JSON.stringify({
          categoryId: service.categoryId,
          name: service.name,
          price: service.price,
          time: service.time,
          isTaxIncluded: service.isTaxIncluded,
          taxRate: service.taxRate,
          isVisible: service.isVisible,
        }),
      }
    );

    const updated: PlainService = serviceFromResource(json);

    dispatch(setSalonServiceMap({ [updated.id]: updated }));

    Logger.logEvent(AnalyticsEvent.updateService);

    return updated;
  };
}
export function deleteService(serviceId: number) {
  return async function (dispatch: Dispatch): Promise<PlainService> {
    const json = await sendRequest(
      dispatch,
      `salons/settings/services/${serviceId}`,
      { method: 'DELETE' }
    );

    const deleted: PlainService = serviceFromResource(json);

    dispatch(setSalonServiceMap({ [deleted.id]: deleted }));

    Logger.logEvent(AnalyticsEvent.updateService);

    return deleted;
  };
}
export function updateServiceOrder(categoryId: number, serviceIds: number[]) {
  return async function (dispatch: Dispatch): Promise<PlainService[]> {
    const json = await sendRequest(dispatch, `salons/settings/services/order`, {
      method: 'POST',
      body: JSON.stringify({ categoryId, serviceIds }),
    });

    const services: PlainService[] = json.map(serviceFromResource);

    dispatch(setSalonServiceMap(mapById(services)));

    Logger.logEvent(AnalyticsEvent.updateServiceOrder);

    return services;
  };
}

/**
 * 割引CRUD
 */
export function createServiceDiscount(discount: PlainServiceDiscount) {
  return async function (dispatch: Dispatch): Promise<PlainServiceDiscount> {
    const json = await sendRequest(
      dispatch,
      'salons/settings/services/discounts',
      {
        method: 'POST',
        body: JSON.stringify({
          name: discount.name,
          type: discount.type,
          value: discount.value,
          isVisible: discount.isVisible,
        }),
      }
    );

    const created: PlainServiceDiscount = serviceDiscountFromResource(json);

    dispatch(setSalonServiceDiscountMap({ [created.id]: created }));

    Logger.logEvent(AnalyticsEvent.createServiceDiscount);

    return created;
  };
}
export function updateServiceDiscount(discount: PlainServiceDiscount) {
  return async function (dispatch: Dispatch): Promise<PlainServiceDiscount> {
    const json = await sendRequest(
      dispatch,
      `salons/settings/services/discounts/${discount.id}`,
      {
        method: 'POST',
        body: JSON.stringify({
          name: discount.name,
          type: discount.type,
          value: discount.value,
          isVisible: discount.isVisible,
        }),
      }
    );

    const updated: PlainServiceDiscount = serviceDiscountFromResource(json);

    dispatch(setSalonServiceDiscountMap({ [updated.id]: updated }));

    Logger.logEvent(AnalyticsEvent.updateServiceDiscount);

    return updated;
  };
}
export function deleteServiceDiscount(discountId: number) {
  return async function (dispatch: Dispatch): Promise<PlainServiceDiscount> {
    const json = await sendRequest(
      dispatch,
      `salons/settings/services/discounts/${discountId}`,
      { method: 'DELETE' }
    );

    const deleted: PlainServiceDiscount = serviceDiscountFromResource(json);

    dispatch(setSalonServiceDiscountMap({ [deleted.id]: deleted }));

    Logger.logEvent(AnalyticsEvent.deleteServiceDiscount);

    return deleted;
  };
}
export function updateServiceDiscountOrder(discountIds: number[]) {
  return async function (dispatch: Dispatch): Promise<PlainServiceDiscount[]> {
    const json = await sendRequest(
      dispatch,
      `salons/settings/services/discounts/order`,
      { method: 'POST', body: JSON.stringify({ discountIds }) }
    );

    const discounts: PlainServiceDiscount[] = json.map(
      serviceDiscountFromResource
    );

    dispatch(setSalonServiceDiscountMap(mapById(discounts)));

    Logger.logEvent(AnalyticsEvent.updateServiceDiscountOrder);

    return discounts;
  };
}
