import { Box, BoxProps, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import * as React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  general: {
    padding: theme.spacing(1),
    fontSize: theme.typography.body2.fontSize,
    borderRadius: 4,
    whiteSpace: 'pre-line',
  },
  note: {
    // TODO 一時的にルールを無効化しています。気づいたベースで直してください
    // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type '100' can't be used to index type 'PaletteColor'.
    backgroundColor: theme.palette.primary[100],
    color: theme.palette.text.primary,
  },
  error: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.main,
  },
  info: {
    // TODO 一時的にルールを無効化しています。気づいたベースで直してください
    // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type '50' can't be used to index type 'PaletteColor'.
    backgroundColor: theme.palette.secondary[50],
    color: theme.palette.secondary.main,
  },
}));

type OwnProps = BoxProps & {
  type?: 'note' | 'error' | 'info';
};

const CTextBox: FC<OwnProps> = React.memo(function CTextBox(props) {
  const classes = useStyles();
  const { children, type = 'note', ...etc } = props;
  return (
    <Box
      {...etc}
      className={clsx(classes.general, {
        [classes.note]: type === 'note',
        [classes.error]: type === 'error',
        [classes.info]: type === 'info',
      })}
    >
      {children}
    </Box>
  );
});

export default CTextBox;
