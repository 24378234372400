import {
  Alert,
  Box,
  Card,
  Grid,
  Tab,
  Tabs,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router';
import { Link } from 'react-router-dom';
import CDivider from '../../../components_old/atoms/CDivider';
import { checkPermission } from '../../../models/stylist';
import { selectMe } from '../../../selectors/salonSelector';
import { useSalonStatus } from '../../../templates/providers/salonStatus/salonStatusContext';
import { useResolvedLastPath } from '../../../util/hooks/router/useResolvedLastPath';
import { BusinessHourSettingCard } from './_components/BusinessHourSettingCard';
import BusinessInfo from './_components/BusinessInfo';
import ScheduleBasicSettingCard from './_components/ScheduleBasicSettingCard';
import { ScheduleCapacitySettingCard } from './_components/ScheduleCapacitySettingCard';
import ShiftPatternSettingCard from './_components/ShiftPatternSettingCard';
import StylistShiftSettingCard from './_components/StylistShiftSettingCard';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '76px 20px 20px 20px',
  },
  container: {
    marginTop: theme.spacing(4),
    padding: 0,
    textAlign: 'center',
  },
  titleImage: {
    width: 268,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: 64,
    [theme.breakpoints.down('sm')]: {
      width: 40,
    },
  },
}));

const TabIndexMap: Record<string, number> = {
  basic: 0,
  business_hour: 1,
  shift_pattern: 2,
  stylist_shift: 3,
  business_info: 4,
};

export const ScheduleSettings: FC = () => {
  const classes = useStyles();

  const me = useSelector(selectMe);
  const { salonStatus } = useSalonStatus();
  const isBusinessInfoAvailable = salonStatus.hasSetting('businessInfo');

  const lastPath = useResolvedLastPath();
  const tabIndex = TabIndexMap[lastPath] ?? 0;

  function renderSettingContents() {
    const hasPermission = checkPermission(me, 'canUpdateScheduleSetting');
    return (
      <>
        <Box mb={2}>
          <Tabs
            value={tabIndex}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
          >
            <Tab label="基本設定" component={Link} to="." />
            <Tab label="営業時間" component={Link} to="business_hour" />
            <Tab label="勤務パターン" component={Link} to="shift_pattern" />
            <Tab
              label="スタッフの基本シフト"
              component={Link}
              to="stylist_shift"
            />
            <Tab label="毎月の営業情報" component={Link} to="business_info" />
          </Tabs>
          <CDivider spacing={0} />
        </Box>

        {!hasPermission && (
          <Alert severity="warning">予約台帳設定の編集権限がありません</Alert>
        )}

        <Routes>
          <Route
            path="*"
            element={
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    基本設定
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <ScheduleBasicSettingCard />
                </Grid>
              </Grid>
            }
          />
          <Route
            path="business_hour"
            element={
              <Grid container spacing={2}>
                {isBusinessInfoAvailable ? (
                  <>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" gutterBottom>
                        営業時間
                      </Typography>
                      <BusinessHourSettingCard />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" gutterBottom>
                        サロンの予約同時受付可能数
                      </Typography>
                      <ScheduleCapacitySettingCard />
                    </Grid>
                  </>
                ) : (
                  <Alert severity="warning">
                    営業情報設定が有効になっていません。基本設定から営業情報設定を有効にしてください。
                  </Alert>
                )}
              </Grid>
            }
          />
          <Route
            path="shift_pattern"
            element={
              <Grid container spacing={2}>
                {isBusinessInfoAvailable ? (
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      勤務パターン
                    </Typography>
                    <ShiftPatternSettingCard />
                  </Grid>
                ) : (
                  <Alert severity="warning">
                    営業情報設定が有効になっていません。基本設定から営業情報設定を有効にしてください。
                  </Alert>
                )}
              </Grid>
            }
          />
          <Route
            path="stylist_shift"
            element={
              <Grid container spacing={2}>
                {isBusinessInfoAvailable ? (
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      スタッフの基本シフト
                    </Typography>
                    <StylistShiftSettingCard />
                  </Grid>
                ) : (
                  <Alert severity="warning">
                    営業情報設定が有効になっていません。基本設定から営業情報設定を有効にしてください。
                  </Alert>
                )}
              </Grid>
            }
          />
          <Route
            path="business_info"
            element={
              <Grid container spacing={2}>
                {isBusinessInfoAvailable ? (
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      毎月の営業情報
                    </Typography>
                    <Card>
                      <BusinessInfo />
                    </Card>
                  </Grid>
                ) : (
                  <Alert severity="warning">
                    営業情報設定が有効になっていません。基本設定から営業情報設定を有効にしてください。
                  </Alert>
                )}
              </Grid>
            }
          />
        </Routes>
      </>
    );
  }

  return <div className={classes.root}>{renderSettingContents()}</div>;
};
