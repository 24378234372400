import { TextField } from '@mui/material';

type Props = {
  reservationMemo: string;
  disabled?: boolean;
  onChange(reservationMemo: string): void;
  fullWidth?: boolean;
};

export const VisitFormReservationMemo: FC<Props> = ({
  reservationMemo,
  disabled,
  onChange,
  fullWidth,
}) => {
  return (
    <TextField
      multiline
      label="予約時メモ"
      value={reservationMemo}
      onChange={(e) => onChange(e.target.value)}
      variant="outlined"
      disabled={disabled}
      fullWidth={fullWidth}
    />
  );
};
