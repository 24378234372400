import { IServiceDiscount } from '@karutekun/core/salon-service';
import { uniqueBy } from '@karutekun/shared/util/objects';
import { IVoucherDiscount, IVoucherLineDiscount } from '../data';

/**
 * voucher に適用されている割引を全て抽出して、ユニークにして返す
 * 以下をまとめて返す
 * - voucher.discounts
 * - voucher.lines[].discounts
 */
export function extractAllDiscountsUnique<
  Line extends {
    discounts: Pick<IVoucherLineDiscount, 'discountId'>[];
  },
  Voucher extends {
    lines: Line[];
    discounts: Pick<IVoucherDiscount, 'discountId'>[];
  },
  DiscountLike extends Pick<IServiceDiscount, 'id'>
>(
  voucher: Voucher,
  { discountMap }: { discountMap: IdMap<DiscountLike> }
): DiscountLike[] {
  const discounts = [
    ...voucher.discounts.map((d) => discountMap[d.discountId]),
    ...voucher.lines.flatMap((line) =>
      line.discounts.map((d) => discountMap[d.discountId])
    ),
  ];
  return uniqueBy(discounts, 'id');
}
