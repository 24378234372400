import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import { theme } from '@karutekun/shared-fe/react-ui-old';
import {
  Backdrop,
  Box,
  Button,
  ButtonProps,
  Card,
  Checkbox,
  Collapse,
  Container,
  FormControlLabel,
  Grid,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { dispatchWithErrorHandling } from '../../../../actions/helper/dispatchWithErrorHandling';
import { createSalon } from '../../../../actions/userAction';
import CProgress from '../../../../components_old/atoms/CProgress';
import CTypographyIcon from '../../../../components_old/atoms/CTypographyIcon';
import {
  BusinessCategories,
  Questionnaire,
} from '../../../../models/salonCustomSetting';
import { selectUserIsJoinedSalon } from '../../../../selectors/userSelector';
import { useRedirect } from '../../../../util/hooks/router/useRedirect';
import { useFlag } from '../../../../util/hooks/useFlag';
import { useThunkDispatch } from '../../../../util/hooks/useThunkDispatch';

const Steps: Record<string, number> = {
  index: 0,
  business_category: 1,
  number_of_staffs: 2,
  confirmation: 3,
};

const NumberOfStaffButtons: Record<number, string> = {
  1: '1人',
  2: '2 〜 3人',
  4: '4 〜 5人',
  6: '6人〜',
};

type SetupData = {
  salonName: string;
  isNewOpen: boolean;
  newOpenDate: string;
  businessCategories: (keyof typeof BusinessCategories)[];
  appUserNum: number;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: 76,
  },
  card: {
    padding: theme.spacing(2),
  },
  subHeader: {
    marginBottom: theme.spacing(1),
  },
  topLogo: {
    margin: theme.spacing(2),
    height: 48,
    color: theme.palette.primary.dark,
    textAlign: 'center',
  },
  stepContentContainer: {
    margin: theme.spacing(1),
  },
  newOpenContainer: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
  },
  toggleButton: {
    width: 85,
    height: 85,
    color: theme.palette.text.secondary,
    padding: 0,
  },
  toggleButtonSelected: {
    // TODO 一時的にルールを無効化しています。気づいたベースで直してください
    // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type '50' can't be used to index type 'PaletteColor'.
    'backgroundColor': theme.palette.secondary[50],
    '&:hover': {
      // TODO 一時的にルールを無効化しています。気づいたベースで直してください
      // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type '50' can't be used to index type 'PaletteColor'.
      backgroundColor: theme.palette.secondary[50],
    },
  },
  stepButtonContainer: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export const SetupNew: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useThunkDispatch();

  // すでにサロン所属しているならリダイレクト
  const isJoinedSalon = useSelector(selectUserIsJoinedSalon);
  useRedirect(!!isJoinedSalon);

  const { step = 'index' } = useParams<{ step: string }>();
  const activeStep = Steps[step] ?? 0;

  const [isFetching, callWithFetching] = useFlag(false);
  const [setupData, setSetupData] = useState<SetupData>({
    salonName: '',
    isNewOpen: false,
    newOpenDate: '',
    businessCategories: [],
    appUserNum: 0,
  });

  const handleChangeSetupData = useCallback((updates: Partial<SetupData>) => {
    setSetupData((current) => ({
      ...current,
      ...updates,
    }));
  }, []);

  const handleClickComplete = useCallback(async () => {
    const questionnaire: Questionnaire = {
      appUserNum: setupData.appUserNum,
      isNewOpen: setupData.isNewOpen,
      newOpenDate: setupData.newOpenDate,
      businessCategory: setupData.businessCategories.join(','),
    };
    try {
      await callWithFetching(
        async () =>
          await dispatchWithErrorHandling(
            dispatch,
            createSalon(setupData.salonName, questionnaire),
            { success: 'サロンが作成できました' },
            true
          )
      );
      navigate('/', { replace: true });
    } catch (e) {
      console.log(e);
    }
  }, [
    setupData.appUserNum,
    setupData.isNewOpen,
    setupData.newOpenDate,
    setupData.businessCategories,
    setupData.salonName,
    callWithFetching,
    navigate,
    dispatch,
  ]);

  return (
    <Container className={classes.root} maxWidth="sm">
      <Backdrop className={classes.backdrop} open={isFetching}>
        <CProgress />
      </Backdrop>

      <Card className={classes.card}>
        <Box textAlign="center">
          <div className={classes.topLogo}>
            <SVGIcon name="store" size="lg" style={{ transform: 'scale(2)' }} />
          </div>
          <Typography variant="h6" gutterBottom>
            あなたのサロンのセットアップをします
          </Typography>
        </Box>

        <Stepper activeStep={activeStep} orientation="vertical">
          {Object.keys(Steps).map((step) => {
            const isActive = Steps[step] === activeStep;
            switch (step) {
              case 'index':
                return (
                  <Step key={step}>
                    <StepLabel>
                      {!isActive && setupData.salonName !== ''
                        ? `サロン名: ${setupData.salonName}`
                        : 'サロン名入力'}
                    </StepLabel>
                    <StepContent>
                      <StepContentIndex
                        setupData={setupData}
                        onChangeSetupData={handleChangeSetupData}
                      />
                      <div className={classes.stepButtonContainer}>
                        <Button
                          disabled={setupData.salonName === ''}
                          color="primary"
                          variant="contained"
                          onClick={() =>
                            navigate('/signup/setup/new/business_category')
                          }
                        >
                          次へ
                        </Button>
                      </div>
                    </StepContent>
                  </Step>
                );
              case 'business_category':
                return (
                  <Step key={step}>
                    <StepLabel>
                      {!isActive && setupData.businessCategories.length > 0
                        ? `業種: ${setupData.businessCategories
                            .map((bc) => BusinessCategories[bc])
                            .join(', ')}`
                        : '業種選択'}
                    </StepLabel>
                    <StepContent>
                      <StepContentBusinessType
                        setupData={setupData}
                        onChangeSetupData={handleChangeSetupData}
                      />
                      <Grid
                        container
                        spacing={2}
                        className={classes.stepButtonContainer}
                      >
                        <Grid item>
                          <Button color="primary" onClick={() => navigate(-1)}>
                            前へ
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            disabled={setupData.businessCategories.length === 0}
                            color="primary"
                            variant="contained"
                            onClick={() =>
                              navigate('/signup/setup/new/number_of_staffs')
                            }
                          >
                            次へ
                          </Button>
                        </Grid>
                      </Grid>
                    </StepContent>
                  </Step>
                );
              case 'number_of_staffs':
                return (
                  <Step key={step}>
                    <StepLabel>
                      {!isActive && setupData.appUserNum > 0
                        ? `スタッフ人数: ${
                            NumberOfStaffButtons[setupData.appUserNum]
                          }`
                        : 'スタッフ人数'}
                    </StepLabel>
                    <StepContent>
                      <StepContentNumberOfStaffs
                        setupData={setupData}
                        onChangeSetupData={handleChangeSetupData}
                      />
                      <Grid
                        container
                        spacing={2}
                        className={classes.stepButtonContainer}
                      >
                        <Grid item>
                          <Button color="primary" onClick={() => navigate(-1)}>
                            前へ
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            disabled={setupData.appUserNum === 0}
                            color="primary"
                            variant="contained"
                            onClick={() =>
                              navigate('/signup/setup/new/confirmation')
                            }
                          >
                            次へ
                          </Button>
                        </Grid>
                      </Grid>
                    </StepContent>
                  </Step>
                );
              case 'confirmation':
                return (
                  <Step key={step}>
                    <StepLabel>確認</StepLabel>
                    <StepContent>
                      <StepContentConfirmation
                        setupData={setupData}
                        onChangeSetupData={handleChangeSetupData}
                      />
                      <Box mt={2}>
                        <Button
                          size="large"
                          color="primary"
                          variant="contained"
                          onClick={handleClickComplete}
                        >
                          無料トライアルを開始
                        </Button>
                      </Box>
                      <div className={classes.stepButtonContainer}>
                        <Button color="primary" onClick={() => navigate(-1)}>
                          前へ
                        </Button>
                      </div>
                    </StepContent>
                  </Step>
                );
              default:
                return null;
            }
          })}
        </Stepper>
      </Card>
    </Container>
  );
};

type StepContentProps = {
  setupData: SetupData;
  onChangeSetupData(updates: Partial<SetupData>): void;
};

const StepContentIndex: FC<StepContentProps> = (props) => {
  const classes = useStyles();
  const {
    setupData: { salonName, isNewOpen, newOpenDate },
    onChangeSetupData,
  } = props;

  return (
    <div className={classes.stepContentContainer}>
      <Typography variant="h6" className={classes.subHeader} gutterBottom>
        サロン名を教えてください
      </Typography>
      <TextField
        variant="standard"
        label="サロン名"
        value={salonName}
        inputProps={{ maxLength: 255 }}
        onChange={(e) => onChangeSetupData({ salonName: e.target.value })}
        fullWidth
      />

      <div className={classes.newOpenContainer}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isNewOpen}
              onChange={(_, checked) =>
                onChangeSetupData({ isNewOpen: checked })
              }
            />
          }
          label={<Typography variant="body1">新規開業予定です</Typography>}
        />
        <Box ml={2}>
          <CTypographyIcon
            icon={
              <SVGIcon
                name="exclamation-circle"
                color={theme.palette.secondary.dark}
              />
            }
            variant="body1"
            gutterBottom
          >
            新規開業予定の方向けに特別のサポートがあります
          </CTypographyIcon>

          <Collapse in={isNewOpen} timeout="auto" unmountOnExit>
            <TextField
              variant="standard"
              label="開業予定時期はいつですか？"
              placeholder="4月頃、未定、など"
              value={newOpenDate}
              inputProps={{ maxLength: 255 }}
              onChange={(e) =>
                onChangeSetupData({ newOpenDate: e.target.value })
              }
              fullWidth
            />
          </Collapse>
        </Box>
      </div>
    </div>
  );
};

const StepContentBusinessType: FC<StepContentProps> = (props) => {
  const classes = useStyles();
  const {
    setupData: { businessCategories },
    onChangeSetupData,
  } = props;

  return (
    <div className={classes.stepContentContainer}>
      <Typography variant="h6" className={classes.subHeader} gutterBottom>
        サロンの業種を選んでください
      </Typography>

      <Grid container spacing={1}>
        {Object.keys(BusinessCategories).map((businessCategory) => {
          const category = businessCategory as keyof typeof BusinessCategories;
          const name = BusinessCategories[category];
          const selected = businessCategories.includes(category);
          return (
            <Grid item key={category}>
              <ToggleButton
                selected={selected}
                onClick={() => {
                  if (selected) {
                    onChangeSetupData({
                      businessCategories: businessCategories.filter(
                        (bc) => bc !== category
                      ),
                    });
                  } else {
                    onChangeSetupData({
                      businessCategories: [...businessCategories, category],
                    });
                  }
                }}
              >
                {name}
              </ToggleButton>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

const StepContentNumberOfStaffs: FC<StepContentProps> = (props) => {
  const classes = useStyles();
  const {
    setupData: { appUserNum },
    onChangeSetupData,
  } = props;

  return (
    <div className={classes.stepContentContainer}>
      <Typography variant="h6" className={classes.subHeader} gutterBottom>
        何名でご利用予定ですか？
      </Typography>
      <Typography variant="body1" gutterBottom>
        複数スタッフでデータを共有してご活用いただけます
      </Typography>

      <Grid container spacing={1}>
        {Object.keys(NumberOfStaffButtons).map((appUserNumStr) => {
          const num = Number(appUserNumStr);
          return (
            <Grid item key={num}>
              <ToggleButton
                selected={num === appUserNum}
                onClick={() => onChangeSetupData({ appUserNum: num })}
              >
                {NumberOfStaffButtons[num]}
              </ToggleButton>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

const StepContentConfirmation: FC<StepContentProps> = () => {
  const classes = useStyles();
  return (
    <div className={classes.stepContentContainer}>
      <Typography variant="h6" className={classes.subHeader} gutterBottom>
        セットアップ完了です
      </Typography>

      <CTypographyIcon
        variant="h6"
        icon={<SVGIcon name="check" color={theme.palette.secondary.dark} />}
        gutterBottom
      >
        最初の30日間は完全無料
      </CTypographyIcon>
      <CTypographyIcon
        variant="h6"
        icon={<SVGIcon name="check" color={theme.palette.secondary.dark} />}
        gutterBottom
      >
        自動で料金が発生することはありません
      </CTypographyIcon>
    </div>
  );
};

const ToggleButton: FC<ButtonProps & { selected: boolean }> = (props) => {
  const classes = useStyles();
  const { children, selected, ...buttonProps } = props;
  return (
    <Button
      color="secondary"
      variant="outlined"
      className={clsx(classes.toggleButton, {
        [classes.toggleButtonSelected]: selected,
      })}
      {...buttonProps}
    >
      {selected && (
        <div style={{ position: 'absolute', top: 2, right: 2 }}>
          <SVGIcon
            name="check-circle"
            size="lg"
            color={theme.palette.secondary.dark}
          />
        </div>
      )}
      {children}
    </Button>
  );
};
