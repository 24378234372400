import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import {
  Backdrop,
  Box,
  Button,
  Card,
  Container,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  pushSnackbarError,
  pushSnackbarSuccess,
} from '../../../actions/generalAction';
import {
  reloadFirebaseStatus,
  sendEmailVerification,
} from '../../../actions/userAction';
import CProgress from '../../../components_old/atoms/CProgress';
import CTextBox from '../../../components_old/atoms/CTextBox';
import { selectUserLoginInfo } from '../../../selectors/userSelector';
import { useRedirect } from '../../../util/hooks/router/useRedirect';
import { useFlag } from '../../../util/hooks/useFlag';
import { useThunkDispatch } from '../../../util/hooks/useThunkDispatch';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: 76,
  },
  card: {
    padding: theme.spacing(2),
  },
  topLogo: {
    margin: theme.spacing(2),
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
  header: {
    marginBottom: theme.spacing(4),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export const EmailVerification: FC = () => {
  const classes = useStyles();

  const dispatch = useThunkDispatch();

  const [resendDisabled, setResendDisabled] = useState(false);
  const [isReloading, callWithReloading] = useFlag(false);

  // すでにメール認証済みであればリダイレクト
  const loginInfo = useSelector(selectUserLoginInfo);
  useRedirect(!!loginInfo?.isEmailVerified);

  const handleReload = useCallback(async () => {
    if (!isReloading) {
      await callWithReloading(async () => {
        const loginInfo = await dispatch(reloadFirebaseStatus());
        if (!loginInfo?.isEmailVerified) {
          dispatch(pushSnackbarError('認証が完了していません'));
        }
      });
    }
  }, [callWithReloading, dispatch, isReloading]);

  useEffect(() => {
    // ウィンドウがアクティブになったときに状態のリロードを自動で行う
    window.addEventListener('visibilitychange', handleReload);
    window.addEventListener('focus', handleReload);
    return () => {
      window.removeEventListener('visibilitychange', handleReload);
      window.removeEventListener('focus', handleReload);
    };
  }, [handleReload]);

  const handleResendEmail = useCallback(async () => {
    const resendEmail = async () => {
      try {
        await sendEmailVerification();
        dispatch(pushSnackbarSuccess('認証メールを再送しました。'));
      } catch (e) {
        dispatch(
          pushSnackbarError(
            '認証メールの再送ができませんでした。しばらく時間をおいてからもう一度試してください。'
          )
        );
        throw e;
      }
    };

    setResendDisabled(true);
    resendEmail()
      .then(() => {
        // 送信に成功している場合はしばらく再送ボタンを押せなくする
        setTimeout(() => setResendDisabled(false), 10000);
      })
      .catch(() => setResendDisabled(false));
  }, [dispatch]);

  if (!loginInfo) {
    return null;
  }

  return (
    <Container className={classes.root} maxWidth="sm">
      <Backdrop className={classes.backdrop} open={isReloading}>
        <CProgress />
      </Backdrop>

      <Card className={classes.card}>
        <Box textAlign="center">
          <div className={classes.topLogo}>
            <SVGIcon
              name="envelope"
              size="lg"
              style={{ transform: 'scale(2)' }}
            />
          </div>
          <Typography variant="h6" className={classes.header}>
            認証メールを確認してください
          </Typography>

          <Typography variant="body1" gutterBottom>
            {loginInfo.email}
          </Typography>
          <Typography variant="body1" gutterBottom>
            宛に認証メールを送信しました。
          </Typography>
          <Typography variant="body1">
            メールをご確認いただき、認証を完了してください。
          </Typography>

          <Box mt={4}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleReload}
            >
              メール認証が完了しました
            </Button>
          </Box>
        </Box>

        <Box mt={8} textAlign="center">
          <Typography variant="subtitle1" gutterBottom>
            メールが届きませんか？
          </Typography>
          <CTextBox textAlign="left">
            {`もしメールが届かない場合は、以下を再度ご確認ください
              ・メールアドレスを間違って入力されていないかご確認ください(KaruteKun登録時のメールアドレスをご利用ください)。
              ・メールが迷惑メールフォルダに振り分けられる場合がございます。一度迷惑メールフォルダをご確認ください。
              ・ドメイン指定受信を設定されていないかご確認ください(「@karutekun.com」からのメールを受け取るように設定してください)。`}
          </CTextBox>

          <Box mt={2}>
            <Button
              disabled={resendDisabled}
              variant="outlined"
              color="primary"
              type="submit"
              onClick={handleResendEmail}
            >
              認証メールを再送する
            </Button>
          </Box>
        </Box>
      </Card>
    </Container>
  );
};
