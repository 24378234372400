import { moment } from '@karutekun/shared/util/datetime';
import { InputLabel, OutlinedInput, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useCallback } from 'react';
import CDatePicker from '../atoms/CDatePicker';
import CTimePicker from '../atoms/CTimePicker';

type OwnProps = {
  label?: string;
  from: number;
  to: number;
  minuteInterval?: MinuteInterval;
  disabled?: boolean;

  onChange(from: number, to: number): void;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  outlinedInput: {
    padding: theme.spacing(1),
    textAlign: 'center',
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.text.primary,
    cursor: 'pointer',
  },
  outlinedInputDisabled: {
    color: theme.palette.text.secondary,
    cursor: 'default',
  },
}));

const CDateTimeRange: FC<OwnProps> = (props) => {
  const classes = useStyles();

  const { from, to, minuteInterval = 15, onChange } = props;

  const date = moment.unix(from).format('YYYY-MM-DD');

  const handleDateChange = useCallback(
    (date: string | undefined) => {
      if (date) {
        const fromHHmmss = moment.unix(from).format('HH:mm:ss');
        const toHHmmss = moment.unix(to).format('HH:mm:ss');
        onChange(
          moment(`${date} ${fromHHmmss}`).unix(),
          moment(`${date} ${toHHmmss}`).unix()
        );
      }
    },
    [from, to, onChange]
  );

  const handleFromChange = useCallback(
    (hhmmss: string) => {
      const newFrom = moment(`${date} ${hhmmss}`).unix();
      const newTo = to + newFrom - from;
      onChange(newFrom, newTo);
    },
    [date, from, to, onChange]
  );
  const handleToChange = useCallback(
    (hhmmss: string) => {
      const newFrom = from;
      let newTo = moment(`${date} ${hhmmss}`).unix();

      // 開始が終了よりあとの場合は、終了を開始に揃える
      if (newFrom > newTo) {
        newTo = newFrom;
      }

      onChange(newFrom, newTo);
    },
    [date, from, onChange]
  );

  return (
    <div>
      <InputLabel shrink>{props.label}</InputLabel>

      <div className={classes.container}>
        <CDatePicker
          date={date}
          onChange={handleDateChange}
          format="YYYY年 M月D日 (ddd)"
          textFieldProps={{
            variant: 'outlined',
            sx: { marginRight: 1 },
            InputProps: {
              classes: {
                input: clsx(classes.outlinedInput, {
                  [classes.outlinedInputDisabled]: props.disabled,
                }),
              },
            },
          }}
          disabled={props.disabled}
        />

        <CTimePicker
          value={moment.unix(from).format('HH:mm:ss')}
          minuteInterval={minuteInterval}
          onChange={handleFromChange}
          disabled={props.disabled}
        />

        <div style={{ marginLeft: 2, marginRight: 2 }}>
          <Typography variant="body2">〜</Typography>
        </div>

        {/* 終了時刻も当日なら時間を選択できるように */}
        {moment.unix(to).format('YYYY-MM-DD') === date ? (
          <CTimePicker
            value={moment.unix(to).format('HH:mm:ss')}
            minuteInterval={minuteInterval}
            onChange={handleToChange}
            disabled={props.disabled}
          />
        ) : (
          <OutlinedInput
            disabled
            value={moment.unix(to).format('HH:mm')}
            classes={{
              input: clsx(classes.outlinedInput, {
                [classes.outlinedInputDisabled]: props.disabled,
              }),
            }}
          />
        )}
      </div>
    </div>
  );
};

export default CDateTimeRange;
