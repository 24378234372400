import { CircularProgress } from '@mui/material';

const CProgress: FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default CProgress;
