import { Card, CardContent, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CustomerStatistics } from '../../models/customer';
import { formatDate } from '../../util/common';
import CDivider from '../atoms/CDivider';
import CInformation from '../atoms/CInformation';
import CMoneyTypography from '../atoms/CMoneyTypography';

type OwnProps = {
  title: string;
  stats: CustomerStatistics;
};

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    'padding': theme.spacing(2),
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  dividerRight: {
    borderRight: '1px solid #d9d9d9',
  },
}));

const CCustomerStatsSummaryCard: FC<OwnProps> = (props) => {
  const classes = useStyles();

  const { title, stats } = props;

  return (
    <Card>
      <CardContent className={classes.content}>
        <Typography variant="h6">{title}</Typography>
        <Grid container>
          <Grid item xs={6} className={classes.dividerRight}>
            <CMoneyTypography
              amount={stats.totalSales}
              variant="h6"
              align="center"
            />
            <Typography
              variant="subtitle2"
              color="textSecondary"
              align="center"
            >
              総支払額
              <CInformation
                type="tooltip"
                content="会計済みの来店記録を集計しています"
              />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <CMoneyTypography
              amount={stats.averageBudget}
              variant="h6"
              align="center"
            />
            <Typography
              variant="subtitle2"
              color="textSecondary"
              align="center"
            >
              平均予算
              <CInformation
                type="tooltip"
                content="会計済みの来店記録を集計しています"
              />
            </Typography>
          </Grid>
        </Grid>

        <CDivider />

        <Grid container>
          <Grid item xs={4}>
            <Typography variant="subtitle1" align="center">
              {stats.averageVisitPeriod} 日
            </Typography>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              align="center"
            >
              来店周期
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" align="center">
              {stats.totalVisitNum} 回
            </Typography>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              align="center"
            >
              来店回数
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" align="center">
              {stats.lastVisitedAt !== 0
                ? formatDate(stats.lastVisitedAt)
                : '-'}
            </Typography>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              align="center"
            >
              最終来店
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CCustomerStatsSummaryCard;
