import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M24 1.344a9.25 9.25 0 0 0-9.25 9.25v7.156H13.5A6.25 6.25 0 0 0 7.25 24v16.5a6.25 6.25 0 0 0 6.25 6.25h21a6.25 6.25 0 0 0 6.25-6.25V24a6.25 6.25 0 0 0-6.25-6.25h-1.25v-7.156A9.25 9.25 0 0 0 24 1.344m5.75 16.406v-7.156a5.75 5.75 0 1 0-11.5 0v7.156zm-13.25 3.5h-3A2.75 2.75 0 0 0 10.75 24v16.5a2.75 2.75 0 0 0 2.75 2.75h21a2.75 2.75 0 0 0 2.75-2.75V24a2.75 2.75 0 0 0-2.75-2.75h-3z"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgLock = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgLock);
export default Memo;
