import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M24.72 2.905a1.75 1.75 0 0 0-1.44 0c-7.44 3.36-10.768 4.43-19.005 5.907a1.75 1.75 0 0 0-1.438 1.613C1.241 35.727 21.782 44.494 23.346 45.123c.42.17.888.17 1.307 0 1.564-.63 22.105-9.396 20.509-34.698a1.75 1.75 0 0 0-1.438-1.613C35.487 7.335 32.16 6.265 24.72 2.905M24 41.592c3.493-1.608 18.32-9.622 17.732-29.586C34.419 10.639 30.794 9.459 24 6.419z"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgShield = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgShield);
export default Memo;
