import { Card } from '@mui/material';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { TooltipProps } from 'recharts';

type OwnProps = TooltipProps<number, string> & {
  showTotal?: boolean;
  itemSuffixStyle?: object;
};

export default class CChartTooltip extends React.PureComponent<OwnProps> {
  render() {
    const {
      active,
      payload,
      label,
      labelStyle,
      itemStyle,
      itemSuffixStyle,
      labelFormatter,
      formatter,
      showTotal,
    } = this.props;

    if (active && payload && payload.length > 0) {
      let totalComponent;
      const total = payload.reduce((p, c) => p + (c.value ?? 0), 0);
      if (showTotal) {
        totalComponent = (
          <Typography style={labelStyle}>
            {formatter
              ? formatter(total, '', { name: '', value: total }, 0, [])
              : total}
          </Typography>
        );
      }

      return (
        <Card
          style={{
            padding: 8,
            backgroundColor: '#fff',
          }}
        >
          {label && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography style={{ ...labelStyle, marginRight: 8 }}>
                {labelFormatter ? labelFormatter(label, []) : label}
              </Typography>
              {totalComponent}
            </div>
          )}

          {label && <Divider style={{ marginTop: 4, marginBottom: 8 }} />}

          {payload.map((entry, i) => {
            const { name, value, color } = entry;
            return (
              <div
                key={i}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: 4,
                }}
              >
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  {color && (
                    <Typography
                      style={{ ...itemStyle, color, marginRight: 4 }}
                      display="inline"
                    >
                      ●
                    </Typography>
                  )}
                  <Typography
                    style={{ ...itemStyle, marginRight: 8 }}
                    display="inline"
                    noWrap
                  >
                    {name}:
                  </Typography>
                </span>
                <Typography
                  style={{ flex: 1, ...itemStyle }}
                  display="inline"
                  noWrap
                  align="right"
                >
                  {formatter
                    ? formatter(value ?? 0, name ?? '', entry, i, [])
                    : value}
                </Typography>
                {payload.length > 1 && total > 0 && (
                  <Typography
                    style={{ width: 25, ...itemSuffixStyle }}
                    display="inline"
                    noWrap
                    align="right"
                  >
                    {Math.round((100 * (value as number)) / total)}%
                  </Typography>
                )}
              </div>
            );
          })}
        </Card>
      );
    }
    return null;
  }
}
