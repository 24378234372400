import { VisitResource } from '@karutekun/core/visit';
import React from 'react';
import { XYCoord } from 'react-dnd';

export type DragItemData = {
  visit: VisitResource;
  layout: Layout;
};

export type SnapInfo = {
  x: number;
  y: number;
  h: number;
  stylistId: number;
  startedAt: number;
};

export type Layout = {
  left: number;
  top: number;
  width: number;
  height: number;
};

export enum ScheduleZIndex {
  Background = -1,
  StylistHeader = 1,
  TimeHeader = 10,
}

export const ScheduleContext = React.createContext<{
  getContainerOffset: (offset: XYCoord) => XYCoord | null;
  getSnappedInformation: (offset: XYCoord) => SnapInfo | null;
  getOffsetY: (yIndex: number) => number;
  getOffsetX: (xIndex: number) => number;
  getHeight: (grids: number) => number;
  getWidth: (grids: number) => number;
  getOffsetXFromSeconds: (secondsInDay: number) => number;
  getWidthFromSeconds: (seconds: number) => number;
  sizes: {
    gridWidth: number;
    gridHeight: number;
    headerWidth: number;
    headerHeight: number;
  };
  minuteInterval: MinuteInterval;
}>({
  getContainerOffset: () => null,
  getSnappedInformation: () => null,
  getOffsetY: () => 0,
  getOffsetX: () => 0,
  getHeight: () => 0,
  getWidth: () => 0,
  getOffsetXFromSeconds: () => 0,
  getWidthFromSeconds: () => 0,
  sizes: {
    gridWidth: 0,
    gridHeight: 0,
    headerWidth: 0,
    headerHeight: 0,
  },
  minuteInterval: 15,
});
