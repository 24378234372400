import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface ApiBaseState {
  // メンテナンス状態かどうか
  isMaintenance: boolean;

  // スタッフのアクセス制限で、制限されているかどうか
  stylistCanAccess: boolean;

  // 強制ログアウトが必要かどうか
  // このフラグを監視して、立った場合はログアウトさせる処理を書くこと
  shouldForceLogout: boolean;
}
interface ExpectedGlobalState {
  apiBase: ApiBaseState;
}

const initialState: ApiBaseState = {
  isMaintenance: false,
  stylistCanAccess: true,
  shouldForceLogout: false,
};

export const apiBaseSlice = createSlice({
  name: 'apiBase',
  initialState,
  reducers: {
    setIsMaintenance: (state, action: PayloadAction<boolean>) => {
      state.isMaintenance = action.payload;
    },
    setStylistCanAccess: (state, action: PayloadAction<boolean>) => {
      state.stylistCanAccess = action.payload;
    },
    setShouldForceLogout: (state, action: PayloadAction<boolean>) => {
      state.shouldForceLogout = action.payload;
    },
  },
});

export const { setIsMaintenance, setStylistCanAccess, setShouldForceLogout } =
  apiBaseSlice.actions;

export const selectIsMaintenance = (state: ExpectedGlobalState) =>
  state.apiBase.isMaintenance;
export const selectStylistCanAccess = (state: ExpectedGlobalState) =>
  state.apiBase.stylistCanAccess;
export const selectShouldForceLogout = (state: ExpectedGlobalState) =>
  state.apiBase.shouldForceLogout;

export const apiBaseReducer = apiBaseSlice.reducer;
