/**
 * このファイルは自動生成されています。
 * 詳しくは README を参照してください。
 **/

export const HolidayMap: { [key: string]: string } = {
  '2015-01-01': '元日',
  '2015-01-12': '成人の日',
  '2015-02-11': '建国記念の日',
  '2015-03-21': '春分の日',
  '2015-04-29': '昭和の日',
  '2015-05-03': '憲法記念日',
  '2015-05-04': 'みどりの日',
  '2015-05-05': 'こどもの日',
  '2015-05-06': '休日',
  '2015-07-20': '海の日',
  '2015-09-21': '敬老の日',
  '2015-09-22': '休日',
  '2015-09-23': '秋分の日',
  '2015-10-12': '体育の日',
  '2015-11-03': '文化の日',
  '2015-11-23': '勤労感謝の日',
  '2015-12-23': '天皇誕生日',
  '2016-01-01': '元日',
  '2016-01-11': '成人の日',
  '2016-02-11': '建国記念の日',
  '2016-03-20': '春分の日',
  '2016-03-21': '休日',
  '2016-04-29': '昭和の日',
  '2016-05-03': '憲法記念日',
  '2016-05-04': 'みどりの日',
  '2016-05-05': 'こどもの日',
  '2016-07-18': '海の日',
  '2016-08-11': '山の日',
  '2016-09-19': '敬老の日',
  '2016-09-22': '秋分の日',
  '2016-10-10': '体育の日',
  '2016-11-03': '文化の日',
  '2016-11-23': '勤労感謝の日',
  '2016-12-23': '天皇誕生日',
  '2017-01-01': '元日',
  '2017-01-02': '休日',
  '2017-01-09': '成人の日',
  '2017-02-11': '建国記念の日',
  '2017-03-20': '春分の日',
  '2017-04-29': '昭和の日',
  '2017-05-03': '憲法記念日',
  '2017-05-04': 'みどりの日',
  '2017-05-05': 'こどもの日',
  '2017-07-17': '海の日',
  '2017-08-11': '山の日',
  '2017-09-18': '敬老の日',
  '2017-09-23': '秋分の日',
  '2017-10-09': '体育の日',
  '2017-11-03': '文化の日',
  '2017-11-23': '勤労感謝の日',
  '2017-12-23': '天皇誕生日',
  '2018-01-01': '元日',
  '2018-01-08': '成人の日',
  '2018-02-11': '建国記念の日',
  '2018-02-12': '休日',
  '2018-03-21': '春分の日',
  '2018-04-29': '昭和の日',
  '2018-04-30': '休日',
  '2018-05-03': '憲法記念日',
  '2018-05-04': 'みどりの日',
  '2018-05-05': 'こどもの日',
  '2018-07-16': '海の日',
  '2018-08-11': '山の日',
  '2018-09-17': '敬老の日',
  '2018-09-23': '秋分の日',
  '2018-09-24': '休日',
  '2018-10-08': '体育の日',
  '2018-11-03': '文化の日',
  '2018-11-23': '勤労感謝の日',
  '2018-12-23': '天皇誕生日',
  '2018-12-24': '休日',
  '2019-01-01': '元日',
  '2019-01-14': '成人の日',
  '2019-02-11': '建国記念の日',
  '2019-03-21': '春分の日',
  '2019-04-29': '昭和の日',
  '2019-04-30': '休日',
  '2019-05-01': '休日（祝日扱い）',
  '2019-05-02': '休日',
  '2019-05-03': '憲法記念日',
  '2019-05-04': 'みどりの日',
  '2019-05-05': 'こどもの日',
  '2019-05-06': '休日',
  '2019-07-15': '海の日',
  '2019-08-11': '山の日',
  '2019-08-12': '休日',
  '2019-09-16': '敬老の日',
  '2019-09-23': '秋分の日',
  '2019-10-14': '体育の日（スポーツの日）',
  '2019-10-22': '休日（祝日扱い）',
  '2019-11-03': '文化の日',
  '2019-11-04': '休日',
  '2019-11-23': '勤労感謝の日',
  '2020-01-01': '元日',
  '2020-01-13': '成人の日',
  '2020-02-11': '建国記念の日',
  '2020-02-23': '天皇誕生日',
  '2020-02-24': '休日',
  '2020-03-20': '春分の日',
  '2020-04-29': '昭和の日',
  '2020-05-03': '憲法記念日',
  '2020-05-04': 'みどりの日',
  '2020-05-05': 'こどもの日',
  '2020-05-06': '休日',
  '2020-07-23': '海の日',
  '2020-07-24': 'スポーツの日',
  '2020-08-10': '山の日',
  '2020-09-21': '敬老の日',
  '2020-09-22': '秋分の日',
  '2020-11-03': '文化の日',
  '2020-11-23': '勤労感謝の日',
  '2021-01-01': '元日',
  '2021-01-11': '成人の日',
  '2021-02-11': '建国記念の日',
  '2021-02-23': '天皇誕生日',
  '2021-03-20': '春分の日',
  '2021-04-29': '昭和の日',
  '2021-05-03': '憲法記念日',
  '2021-05-04': 'みどりの日',
  '2021-05-05': 'こどもの日',
  '2021-07-22': '海の日',
  '2021-07-23': 'スポーツの日',
  '2021-08-08': '山の日',
  '2021-08-09': '休日',
  '2021-09-20': '敬老の日',
  '2021-09-23': '秋分の日',
  '2021-11-03': '文化の日',
  '2021-11-23': '勤労感謝の日',
  '2022-01-01': '元日',
  '2022-01-10': '成人の日',
  '2022-02-11': '建国記念の日',
  '2022-02-23': '天皇誕生日',
  '2022-03-21': '春分の日',
  '2022-04-29': '昭和の日',
  '2022-05-03': '憲法記念日',
  '2022-05-04': 'みどりの日',
  '2022-05-05': 'こどもの日',
  '2022-07-18': '海の日',
  '2022-08-11': '山の日',
  '2022-09-19': '敬老の日',
  '2022-09-23': '秋分の日',
  '2022-10-10': 'スポーツの日',
  '2022-11-03': '文化の日',
  '2022-11-23': '勤労感謝の日',
  '2023-01-01': '元日',
  '2023-01-02': '休日',
  '2023-01-09': '成人の日',
  '2023-02-11': '建国記念の日',
  '2023-02-23': '天皇誕生日',
  '2023-03-21': '春分の日',
  '2023-04-29': '昭和の日',
  '2023-05-03': '憲法記念日',
  '2023-05-04': 'みどりの日',
  '2023-05-05': 'こどもの日',
  '2023-07-17': '海の日',
  '2023-08-11': '山の日',
  '2023-09-18': '敬老の日',
  '2023-09-23': '秋分の日',
  '2023-10-09': 'スポーツの日',
  '2023-11-03': '文化の日',
  '2023-11-23': '勤労感謝の日',
  '2024-01-01': '元日',
  '2024-01-08': '成人の日',
  '2024-02-11': '建国記念の日',
  '2024-02-12': '休日',
  '2024-02-23': '天皇誕生日',
  '2024-03-20': '春分の日',
  '2024-04-29': '昭和の日',
  '2024-05-03': '憲法記念日',
  '2024-05-04': 'みどりの日',
  '2024-05-05': 'こどもの日',
  '2024-05-06': '休日',
  '2024-07-15': '海の日',
  '2024-08-11': '山の日',
  '2024-08-12': '休日',
  '2024-09-16': '敬老の日',
  '2024-09-22': '秋分の日',
  '2024-09-23': '休日',
  '2024-10-14': 'スポーツの日',
  '2024-11-03': '文化の日',
  '2024-11-04': '休日',
  '2024-11-23': '勤労感謝の日',
  '2025-01-01': '元日',
  '2025-01-13': '成人の日',
  '2025-02-11': '建国記念の日',
  '2025-02-23': '天皇誕生日',
  '2025-02-24': '休日',
  '2025-03-20': '春分の日',
  '2025-04-29': '昭和の日',
  '2025-05-03': '憲法記念日',
  '2025-05-04': 'みどりの日',
  '2025-05-05': 'こどもの日',
  '2025-05-06': '休日',
  '2025-07-21': '海の日',
  '2025-08-11': '山の日',
  '2025-09-15': '敬老の日',
  '2025-09-23': '秋分の日',
  '2025-10-13': 'スポーツの日',
  '2025-11-03': '文化の日',
  '2025-11-23': '勤労感謝の日',
  '2025-11-24': '休日',
};

export const Holidays = Object.keys(HolidayMap);
