export function sortDscBy<K extends string, T extends { [key in K]: number }>(
  objects: T[],
  key: K
) {
  return [...objects].sort((a, b) => b[key] - a[key]);
}

export function sortAscBy<K extends string, T extends { [key in K]: number }>(
  objects: T[],
  key: K
) {
  return [...objects].sort((a, b) => a[key] - b[key]);
}
