import { useBoolean } from '@karutekun/shared/util/react-hooks';
import {
  Backdrop,
  Box,
  Button,
  Card,
  Grid,
  Link,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { dispatchWithErrorHandling } from '../../actions/helper/dispatchWithErrorHandling';
import { login, resetPassword } from '../../actions/userAction';
import { Images } from '../../assets/images';
import CButton from '../../components_old/atoms/CButton';
import CProgress from '../../components_old/atoms/CProgress';
import CTextBox from '../../components_old/atoms/CTextBox';
import { selectUserLoginInfo } from '../../selectors/userSelector';
import { useRedirect } from '../../util/hooks/router/useRedirect';
import { useFlag } from '../../util/hooks/useFlag';
import { useThunkDispatch } from '../../util/hooks/useThunkDispatch';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    maxWidth: 550,
  },
  innerContainer: {
    padding: theme.spacing(2),
    backgroundColor: '#fff',
  },
  footer: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
  },
  footerLink: {
    color: '#fff',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  link: {
    textDecoration: 'none',
  },
}));

export const Login: FC = () => {
  const classes = useStyles();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const [email, setEmail] = useState(params.get('email') || '');

  const [isLoginMode, isLoginModeMutations] = useBoolean(true);

  const dispatch = useThunkDispatch();

  // ユーザーのログイン状態を監視して、ログイン状態になればページ遷移させる
  const loginInfo = useSelector(selectUserLoginInfo);
  useRedirect(loginInfo !== null);

  const handleLogin = async (email: string, password: string) => {
    await dispatchWithErrorHandling(dispatch, login(email, password));
  };

  const handleResetPassword = async (email: string) => {
    await dispatchWithErrorHandling(dispatch, resetPassword(email), {
      success: 'パスワード再設定用のメールを送信しました',
    });
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Card className={classes.card}>
        <Grid
          container
          justifyContent="space-evenly"
          alignItems="center"
          direction="column"
          style={{
            paddingTop: 40,
            paddingBottom: 16,
            paddingLeft: 32,
            paddingRight: 32,
          }}
        >
          <img
            src={Images.common.title}
            alt="ログイン"
            style={{ width: '60%' }}
          />
          <Typography
            variant="h5"
            align="center"
            style={{ color: '#fff', paddingTop: 24 }}
          >
            {isLoginMode ? 'ログイン' : 'パスワード再設定'}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.innerContainer}>
          <Box mt={4}>
            <CTextBox>
              {`※ KaruteKunアプリと同じメールアドレス、パスワードをご利用下さい。
                  ※ PC/タブレットからの利用を推奨しております。`}
            </CTextBox>
          </Box>
        </Grid>

        {isLoginMode ? (
          <LoginForm
            email={email}
            setEmail={setEmail}
            login={handleLogin}
            switchLoginMode={isLoginModeMutations.setFalse}
          />
        ) : (
          <ResetPasswordForm
            email={email}
            resetPassword={handleResetPassword}
            switchLoginMode={isLoginModeMutations.setTrue}
          />
        )}

        <Grid className={classes.footer} container justifyContent="center">
          <Link
            className={classes.footerLink}
            href="https://techners.jp/"
            target="_blank"
            rel="noopener"
          >
            powered by techners
          </Link>
        </Grid>
      </Card>
    </Grid>
  );
};

const LoginForm: FC<{
  email: string;
  setEmail(email: string): void;
  login(email: string, password: string): Promise<void>;
  switchLoginMode(): void;
}> = (props) => {
  const classes = useStyles();
  const { email, setEmail, login, switchLoginMode } = props;

  const [password, setPassword] = useState('');
  const [isFetching, callWithFetching] = useFlag(false);
  // 空文字で送信するとuncaught errorが起きるので防いでおく
  const canSubmit = !isFetching && !!email && !!password;

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        if (canSubmit) {
          await callWithFetching(() => login(email, password));
        }
      }}
    >
      <Backdrop className={classes.backdrop} open={isFetching}>
        <CProgress />
      </Backdrop>

      <Grid
        className={classes.innerContainer}
        container
        justifyContent="space-evenly"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={12} md={8}>
          <TextField
            variant="standard"
            name="email"
            type="email"
            label="メールアドレス"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            variant="standard"
            name="password"
            type="password"
            label="パスワード"
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item container xs={12} justifyContent="center">
          {/**
           * 自動入力された状態だと、画面をクリックするまでの間は canSubmit = false になることがあったので、
           * 自動入力されたのにボタンが押せない見た目にならないように disabled判定 には isFetching を使う
           */}
          <CButton
            disabled={isFetching}
            type="submit"
            style={{ height: 44, fontSize: 15, fontWeight: 700 }}
          >
            ログイン
          </CButton>
        </Grid>

        <Grid item container xs={12} justifyContent="center">
          <Grid item xs={12}>
            <Box textAlign="center" style={{ marginTop: 8, marginBottom: 12 }}>
              <RouterLink to="/signup" className={classes.link}>
                <Button
                  color="primary"
                  style={{ padding: 0, fontSize: 12, lineHeight: 2 }}
                >
                  ユーザー登録はこちら
                </Button>
              </RouterLink>
            </Box>
          </Grid>

          <CButton
            disabled={isFetching}
            variant="text"
            onClick={switchLoginMode}
            style={{ padding: 0, fontSize: 12, lineHeight: 2 }}
          >
            パスワードを忘れた方はこちら
          </CButton>
        </Grid>
      </Grid>
    </form>
  );
};

const ResetPasswordForm: FC<{
  email: string;
  resetPassword(email: string): Promise<void>;
  switchLoginMode(): void;
}> = (props) => {
  const classes = useStyles();
  const { resetPassword, switchLoginMode } = props;

  const [email, setEmail] = useState(props.email);
  const [isFetching, callWithFetching] = useFlag(false);

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        await callWithFetching(() => resetPassword(email));
      }}
    >
      <Backdrop className={classes.backdrop} open={isFetching}>
        <CProgress />
      </Backdrop>

      <Grid
        className={classes.innerContainer}
        container
        justifyContent="space-evenly"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={12} md={8}>
          <TextField
            variant="standard"
            name="email"
            type="email"
            label="メールアドレス"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item container xs={12} justifyContent="center">
          <CButton
            disabled={isFetching}
            type="submit"
            style={{ height: 44, fontSize: 15, fontWeight: 700 }}
          >
            パスワード再設定メール送信
          </CButton>
        </Grid>

        <Grid item container xs={12} justifyContent="center">
          <CButton
            disabled={isFetching}
            variant="text"
            onClick={switchLoginMode}
          >
            ログイン画面に戻る
          </CButton>
        </Grid>

        <Grid item xs={12}>
          <CTextBox>
            {`もしメールが届かない場合は、以下を再度ご確認ください
                      ・メールアドレスを間違って入力されていないかご確認ください(KaruteKun登録時のメールアドレスをご利用ください)。
                      ・メールが迷惑メールフォルダに振り分けられる場合がございます。一度迷惑メールフォルダをご確認ください。
                      ・ドメイン指定受信を設定されていないかご確認ください(「@karutekun.com」からのメールを受け取るように設定してください)。`}
          </CTextBox>
        </Grid>
      </Grid>
    </form>
  );
};
