import { moment } from '@karutekun/shared/util/datetime';
import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Images } from '../../assets/images';
import { ChatMessage as ChatMessageEntity } from '../../models/chatMessage/entity';
import {
  ChatMessageSender,
  ChatMessageStatus,
} from '../../models/chatMessage/schema';
import { ChatMessageBodyBroadcast as ChatMessageBodyBroadcastEntity } from '../../models/chatMessageBodyBroadcast/entity';
import { ChatMessageBodyImage as ChatMessageBodyImageEntity } from '../../models/chatMessageBodyImage/entity';
import { ChatMessageBodyKarute as ChatMessageBodyKaruteEntity } from '../../models/chatMessageBodyKarute/entity';
import { ChatMessageBodyReceipt as ChatMessageBodyReceiptEntity } from '../../models/chatMessageBodyReceipt/entity';
import { ChatMessageBodyReservation as ChatMessageBodyReservationEntity } from '../../models/chatMessageBodyReservation/entity';
import { ChatMessageBodyReservationRemind as ChatMessageBodyReservationRemindEntity } from '../../models/chatMessageBodyReservationRemind/entity';
import { ChatMessageBodyText as ChatMessageBodyTextEntity } from '../../models/chatMessageBodyText/entity';
import CButton from '../atoms/CButton';
import CStylistAvatar from '../atoms/CStylistAvatar';
import ChatMessageBodyBroadcast from './ChatMessageBodyBroadcast';
import ChatMessageBodyImage from './ChatMessageBodyImage';
import ChatMessageBodyKarute from './ChatMessageBodyKarute';
import ChatMessageBodyReceipt from './ChatMessageBodyReceipt';
import ChatMessageBodyReservation from './ChatMessageBodyReservation';
import ChatMessageBodyReservationRemind from './ChatMessageBodyReservationRemind';
import ChatMessageBodyText from './ChatMessageBodyText';

type Props = {
  chatMessage: ChatMessageEntity;
  onResendMessage(): Promise<void>;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
  },
  containerLeft: {
    justifyContent: 'flex-start',
  },
  containerCenter: {
    justifyContent: 'center',
  },
  containerRight: {
    justifyContent: 'flex-end',
  },
  balloonBase: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: 16,
    flexShrink: 1,
    maxWidth: '70%',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all',
  },
  balloonCustomer: {
    backgroundColor: '#f2f3f5',
  },
  balloonSalon: {
    backgroundColor: '#d1dbf5',
  },
  systemMessageContainer: {
    minWidth: '40%',
    maxWidth: '70%',
  },
  systemMessageText: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body2.fontSize,
  },
  systemMessage: {
    flexShrink: 1,
    padding: theme.spacing(1),
    backgroundColor: '#fff',
    border: `solid 1px ${theme.palette.divider}`,
    borderRadius: 16,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all',
  },
  bottom: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  loadingPulse: {
    width: 16,
    height: 16,
  },
}));

const ChatMessage: FC<Props> = (props) => {
  const classes = useStyles();

  const { chatMessage, onResendMessage } = props;

  const messageSender = chatMessage.detectSender();

  const body =
    chatMessage.body instanceof ChatMessageBodyTextEntity ? (
      <ChatMessageBodyText chatMessageBody={chatMessage.body} />
    ) : chatMessage.body instanceof ChatMessageBodyImageEntity ? (
      <ChatMessageBodyImage chatMessageBody={chatMessage.body} />
    ) : chatMessage.body instanceof ChatMessageBodyKaruteEntity ? (
      <ChatMessageBodyKarute chatMessageBody={chatMessage.body} />
    ) : chatMessage.body instanceof ChatMessageBodyReservationEntity ? (
      <ChatMessageBodyReservation chatMessageBody={chatMessage.body} />
    ) : chatMessage.body instanceof ChatMessageBodyBroadcastEntity ? (
      <ChatMessageBodyBroadcast chatMessageBody={chatMessage.body} />
    ) : chatMessage.body instanceof ChatMessageBodyReceiptEntity ? (
      <ChatMessageBodyReceipt chatMessageBody={chatMessage.body} />
    ) : chatMessage.body instanceof ChatMessageBodyReservationRemindEntity ? (
      <ChatMessageBodyReservationRemind chatMessageBody={chatMessage.body} />
    ) : null;

  const timeComponent = (
    <div className={classes.bottom}>
      <Typography variant="body2" color="textSecondary">
        {moment(chatMessage.createdAtMs).format('HH:mm')}
      </Typography>
    </div>
  );

  switch (messageSender) {
    case ChatMessageSender.Customer:
      return (
        <div className={clsx(classes.container, classes.containerLeft)}>
          <div className={clsx(classes.balloonBase, classes.balloonCustomer)}>
            {body}
          </div>
          {timeComponent}
        </div>
      );
    case ChatMessageSender.Salon:
      return (
        <div className={clsx(classes.container, classes.containerRight)}>
          <div className={classes.bottom}>
            {chatMessage.status === ChatMessageStatus.Sending && (
              <img
                className={classes.loadingPulse}
                src={Images.common.loadingPulse}
                alt=""
              />
            )}

            {chatMessage.status === ChatMessageStatus.Failure && (
              <CButton
                size="small"
                startIcon={<SVGIcon name="exclamation-circle" size="sm" />}
                variant="text"
                onClick={onResendMessage}
              >
                送信に失敗しました。クリックして再送します。
              </CButton>
            )}
            {timeComponent}
          </div>
          <div className={clsx(classes.balloonBase, classes.balloonSalon)}>
            {body}
          </div>
          <CStylistAvatar stylist={chatMessage.stylist} />
        </div>
      );
    case ChatMessageSender.System:
      return (
        <div className={clsx(classes.container, classes.containerCenter)}>
          <div className={classes.systemMessageContainer}>
            <Box display="flex" flexDirection="row">
              <Typography className={classes.systemMessageText}>
                システムメッセージ
              </Typography>
              {timeComponent}
            </Box>
            <div className={clsx(classes.systemMessage)}>{body}</div>
          </div>
        </div>
      );
  }
};

export default ChatMessage;
