import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      stroke={props.color || 'currentColor' || '#000'}
      strokeWidth={0.5}
      d="M6.258 8.733a1.75 1.75 0 0 1 2.474-2.474l.005.004 33 33A1.75 1.75 0 0 1 40.5 42.25V42a1.494 1.494 0 0 1-1.06-.44zm0 0 .005.004 33 33zm6.697 23.635c3.47 2.247 7.175 3.382 11.013 3.382 1.597 0 3.187-.2 4.733-.598a.626.626 0 0 1 .597.156l.002.003 2.023 2.023zm0 0c-3.12-2.014-5.937-4.891-8.153-8.319 1.833-2.604 3.834-4.807 5.978-6.587m2.175 14.906L10.78 17.462m0 0a.437.437 0 0 0 .03-.643v-.002m-.03.645.03-.645m0 0-1.867-1.864m1.867 1.864-1.867-1.864M1.83 22.188c1.983-2.844 4.165-5.275 6.52-7.26zm0 0a3.242 3.242 0 0 0-.065 3.61c2.493 3.901 5.704 7.191 9.288 9.51 4.036 2.615 8.386 3.943 12.915 3.943M1.83 22.187 23.97 39.25m0 0a22.67 22.67 0 0 0 7.185-1.192M23.97 39.25l7.186-1.192m0 0a.437.437 0 0 0 .183-.121m-.183.12.183-.12m14.888-15.704a3.27 3.27 0 0 1 .01 3.53v.001l-.21-.134zm0 0c-2.498-3.864-5.742-7.149-9.38-9.499-4.028-2.603-8.481-3.984-12.878-3.984m22.258 13.483L23.968 8.75m0 0c-2.42.004-4.82.416-7.103 1.217m7.103-1.217-5.246 3.962m0 0-.002-.002-2.02-2.019a.437.437 0 0 1 .165-.724m1.857 2.745a.624.624 0 0 0 .605.156 17.823 17.823 0 0 1 4.641-.618c3.762 0 7.455 1.149 10.978 3.429m-16.224-2.967 16.225 2.967M16.865 9.967l.082.236m-.082-.236.082.236m0 0a.188.188 0 0 0-.07.31zm18 5.476c3.182 2.054 6.035 4.933 8.258 8.33m-8.258-8.33 8.258 8.33m0 0a28.883 28.883 0 0 1-5.878 6.632.437.437 0 0 0-.033.645l.001.001 1.865 1.864zM8.943 14.954a.438.438 0 0 0-.593-.027zm22.395 22.983a.438.438 0 0 0 .102-.195m-.102.195.102-.195m0 0a.438.438 0 0 0-.005-.22m.005.22-.005-.22m0 0a.438.438 0 0 0-.112-.188z"
    />
    <path
      fill={props.color || 'currentColor' || '#000'}
      d="M24 15c-.674 0-1.346.075-2.003.225a.188.188 0 0 0-.094.317l10.555 10.552a.187.187 0 0 0 .271-.005.188.188 0 0 0 .046-.088A8.998 8.998 0 0 0 24 15m-8.458 6.906a.187.187 0 0 0-.317.093 9 9 0 0 0 10.781 10.782.187.187 0 0 0 .094-.317z"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgEyeSlash = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgEyeSlash);
export default Memo;
