import * as React from 'react';
import { VisitMotivation } from '../../models/visitMotivation';
import CChartPie, { ChartData } from '../atoms/CChartPie';

type OwnProps = {
  salonVisitMotivationMap: IdMap<VisitMotivation>;
  visitMotivationMap: { [visitMotivationId: number]: number };
};

class CChartPieVisitMotivation extends React.PureComponent<OwnProps> {
  render() {
    const { salonVisitMotivationMap, visitMotivationMap } = this.props;

    const data: ChartData[] = [];
    for (const visitMotivationId in visitMotivationMap) {
      if (salonVisitMotivationMap[visitMotivationId]) {
        data.push({
          id: Number(visitMotivationId),
          name: salonVisitMotivationMap[visitMotivationId].name,
          value: visitMotivationMap[visitMotivationId],
        });
      }
    }
    return (
      <CChartPie data={data} formatter={(v) => `${v}人`} sortDataOrder="asc" />
    );
  }
}

export default CChartPieVisitMotivation;
