import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { setViewScheduleDrawerStatus } from '../../../../actions/view/viewScheduleAction';
import { headerHeight } from '../../../../components/Header';
import CButton from '../../../../components_old/atoms/CButton';
import CDivider from '../../../../components_old/atoms/CDivider';
import {
  selectScheduleDrawerPreview,
  selectScheduleDrawerState,
} from '../../../../selectors/view/viewScheduleSelector';
import { useThunkDispatch } from '../../../../util/hooks/useThunkDispatch';
import { useWidthDown } from '../../../../util/hooks/useWidth';
import { SchedulesDrawerPreviewCreate } from './SchedulesDrawerPreviewCreate';
import { SchedulesDrawerPreviewEdit } from './SchedulesDrawerPreviewEdit';
import { SchedulesDrawerReservationList } from './SchedulesDrawerReservationList';

export const scheduleDrawerWidth = 320;

const useStyles = makeStyles((theme: Theme) => {
  const padding = theme.spacing(1);

  return {
    previewDrawer: {
      width: scheduleDrawerWidth,
      flexShrink: 0,
    },
    drawerHeader: {
      top: 0,
      position: 'sticky',
      backgroundColor: '#fff',
      zIndex: theme.zIndex.modal + 10,
      paddingTop: padding,
    },
    previewDrawerPaper: {
      width: scheduleDrawerWidth,
      paddingTop: headerHeight,
      paddingBottom: padding,
      paddingLeft: padding,
      paddingRight: padding,
    },
  };
});

type Props = {
  onClosePreview(): void;
};

export const SchedulesDrawer: FC<Props> = ({ onClosePreview }) => {
  const dispatch = useThunkDispatch();

  const isDrawerDialogMode = useWidthDown('sm');

  const drawerState = useSelector(selectScheduleDrawerState);
  const drawerPreview = useSelector(selectScheduleDrawerPreview);

  const handleCloseReservationList = useCallback(() => {
    dispatch(setViewScheduleDrawerStatus({ reservationList: false }));
  }, [dispatch]);

  return (
    <>
      <DrawerContainer
        open={drawerState.isReservationListOpen}
        isDialogMode={isDrawerDialogMode}
        title="新着の予約一覧"
        onClose={handleCloseReservationList}
      >
        <SchedulesDrawerReservationList />
      </DrawerContainer>
      <DrawerContainer
        open={drawerState.isSchedulePreviewOpen}
        isDialogMode={isDrawerDialogMode}
        title={
          drawerPreview.selectedVisitId ? '来店情報の編集' : '予約の新規作成'
        }
        onClose={onClosePreview}
      >
        {drawerPreview.selectedVisitId ? (
          <SchedulesDrawerPreviewEdit
            selectedVisitId={drawerPreview.selectedVisitId}
          />
        ) : (
          <SchedulesDrawerPreviewCreate />
        )}
      </DrawerContainer>
    </>
  );
};

const DrawerContainer: FC<{
  open: boolean;
  isDialogMode: boolean;
  title: string;
  onClose(): void;
}> = (props) => {
  const classes = useStyles();

  const { open, isDialogMode, title, children } = props;

  const fullscreen = useWidthDown('sm');

  return isDialogMode ? (
    <Dialog
      maxWidth="xs"
      fullScreen={fullscreen}
      open={open}
      onClose={props.onClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>{open && children}</DialogContent>
      <DialogActions>
        <CButton variant="outlined" color="primary" onClick={props.onClose}>
          閉じる
        </CButton>
      </DialogActions>
    </Dialog>
  ) : (
    <Drawer
      className={classes.previewDrawer}
      anchor="right"
      variant="persistent"
      open={open}
      onClose={props.onClose}
      classes={{ paper: classes.previewDrawerPaper }}
    >
      <div className={classes.drawerHeader}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h6">{title}</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={props.onClose} size="large">
              <SVGIcon name="times" />
            </IconButton>
          </Grid>
        </Grid>
        <CDivider />
      </div>

      {open && children}
    </Drawer>
  );
};
