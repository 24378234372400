import { dayjs } from '@karutekun/shared/util/datetime';
import { zMonthString } from './month-string';
import { z } from './zod';

export const zMonthRange = z
  .object({ from: zMonthString, to: zMonthString })
  .refine(({ from, to }) => {
    // to >= from であることをチェック
    return dayjs(to, 'YYYY-MM').diff(dayjs(from, 'YYYY-MM'), 'month') >= 0;
  });
