import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import CSelect from '../../../../../components_old/atoms/CSelect';
import { ScheduleCapacityMax } from '../../../../../models/salonScheduleSettings';

type Props = {
  capacity: number | null;
  disabled?: boolean;
  onChange(capacity: number): void;
};

const useStyles = makeStyles((theme: Theme) => ({
  capacityContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  capacitySelect: {
    marginLeft: theme.spacing(1),
    width: 50,
  },
}));

const capacityOptions = new Array(ScheduleCapacityMax + 1)
  .fill(0)
  .map((_, i) => ({
    value: i,
    element: `${i}`,
  }));

export const ScheduleCapacitySelect: FC<Props> = React.memo(
  function ScheduleCapacitySelect(props) {
    const classes = useStyles();
    const { capacity, disabled, onChange } = props;

    return (
      <div className={classes.capacityContainer}>
        <SVGIcon name="user-friends" size="sm" />
        <div className={classes.capacitySelect}>
          {capacity === null ? (
            <Typography variant="caption">時間帯別設定済み</Typography>
          ) : (
            <CSelect
              dense
              disabled={disabled}
              value={capacity}
              options={capacityOptions}
              onChange={onChange}
            />
          )}
        </div>
      </div>
    );
  }
);
