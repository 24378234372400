import { Action, Dispatch } from 'redux';
import { deprecated } from 'typesafe-actions';
import { sendRequest } from '../actions/request';
import {
  PlainBroadcastMessage,
  plainBroadcastMessageFromResource,
} from '../models/broadcastMessage';

const { createAction } = deprecated;

export enum ActionNames {
  SetBroadcastMessage = 'voucher/SetBroadcastMessage',
  SetBroadcastMessages = 'voucher/SetBroadcastMessages',
  UnsetBroadcastMessage = 'voucher/UnsetBroadcastMessage',
}

export const setBroadcastMessage = createAction(
  ActionNames.SetBroadcastMessage,
  (action) => {
    return (plainBroadcastMessage: PlainBroadcastMessage) =>
      action({ plainBroadcastMessage });
  }
);
export const setBroadcastMessages = createAction(
  ActionNames.SetBroadcastMessages,
  (action) => {
    return (plainBroadcastMessages: PlainBroadcastMessage[]) =>
      action({ plainBroadcastMessages });
  }
);
export const unsetBroadcastMessage = createAction(
  ActionNames.UnsetBroadcastMessage,
  (action) => {
    return (id: string) => action({ id });
  }
);

export function fetchBroadcastMessages(
  filterOption: {
    limit?: number;
    offset?: number;
  } = {}
) {
  return async (
    dispatch: Dispatch<Action>
  ): Promise<{ count: number; broadcastMessages: PlainBroadcastMessage[] }> => {
    const json = await sendRequest(dispatch, `chat/broadcast_messages`, {
      params: {
        filter: JSON.stringify(filterOption),
      },
    });

    const { count, messages } = json;
    const broadcastMessages = messages.map(plainBroadcastMessageFromResource);

    dispatch(setBroadcastMessages(broadcastMessages));

    return { count, broadcastMessages };
  };
}

export function fetchBroadcastMessage(broadcastMessageId: string) {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    const json = await sendRequest(
      dispatch,
      `chat/broadcast_messages/${broadcastMessageId}`
    );

    dispatch(setBroadcastMessage(plainBroadcastMessageFromResource(json)));
  };
}

export function createBroadcastMessage(
  broadcastMessage: PlainBroadcastMessage,
  isDraft: boolean
) {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    const json = await sendRequest(dispatch, `chat/broadcast_messages`, {
      method: 'POST',
      body: JSON.stringify({
        isDraft,
        id: broadcastMessage.id,
        name: broadcastMessage.name,
        message: broadcastMessage.message,
        customerFilter: broadcastMessage.customerFilter,
        publishScheduleType: broadcastMessage.publishScheduleType,
        publishScheduledAt: broadcastMessage.publishScheduledAt,
      }),
    });

    dispatch(setBroadcastMessage(plainBroadcastMessageFromResource(json)));
  };
}

export function updateBroadcastMessage(
  id: string,
  broadcastMessage: PlainBroadcastMessage,
  isDraft: boolean
) {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    const json = await sendRequest(dispatch, `chat/broadcast_messages/${id}`, {
      method: 'POST',
      body: JSON.stringify({
        isDraft,
        name: broadcastMessage.name,
        message: broadcastMessage.message,
        customerFilter: broadcastMessage.customerFilter,
        publishScheduleType: broadcastMessage.publishScheduleType,
        publishScheduledAt: broadcastMessage.publishScheduledAt,
      }),
    });

    dispatch(setBroadcastMessage(plainBroadcastMessageFromResource(json)));
  };
}

export function cancelBroadcastMessage(id: string) {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    const json = await sendRequest(
      dispatch,
      `chat/broadcast_messages/${id}/cancel`,
      { method: 'POST' }
    );

    dispatch(setBroadcastMessage(plainBroadcastMessageFromResource(json)));
  };
}

export function deleteBroadcastMessage(id: string) {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    await sendRequest(dispatch, `chat/broadcast_messages/${id}`, {
      method: 'DELETE',
    });

    dispatch(unsetBroadcastMessage(id));
  };
}
