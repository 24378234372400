import { ServiceType } from '@karutekun/core/salon-service';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { PlainServiceDiscount, Service } from '../../models/service';
import { CDiscountIcon } from '../atoms/CDiscountIcon';

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    serviceName: {
      display: 'flex',
      flexDirection: 'row',
      overflow: 'hidden',
    },
  };
});

const defaultIconStyle = { marginLeft: 3, marginRight: 3 };

type Props = {
  services: Pick<Service, 'type' | 'name'>[];
  discounts?: Pick<PlainServiceDiscount, 'name'>[];
  emptyComponent?: React.ReactElement;
  showType?: ServiceType;
  size?: number;
  iconStyle?: React.CSSProperties;
};

const CServiceNames: FC<Props> = React.memo(function CServiceNames(props) {
  const {
    services,
    discounts = [],
    emptyComponent = null,
    showType,
    size = 16,
    iconStyle = defaultIconStyle,
  } = props;
  const classes = useStyles();

  const filteredServices = services.filter(
    (s) => !showType || s.type === showType
  );

  if (filteredServices.length === 0 && discounts.length === 0) {
    return emptyComponent;
  }

  return (
    <Grid container spacing={1} className={classes.container}>
      <Grid item className={classes.serviceName}>
        <Typography variant="body2" color="textPrimary">
          {filteredServices.map((s) => s.name).join(' / ')}
        </Typography>
      </Grid>
      {discounts.map((d, i) => (
        <Grid item key={`d${i}`} className={classes.serviceName}>
          <CDiscountIcon style={iconStyle} size={size} />
          <Typography variant="body2" color="textPrimary">
            {d.name}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
});

export default CServiceNames;
