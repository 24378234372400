import {
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import { useCallback } from 'react';
import { switchLoginSalon } from '../../../actions/userAction';
import { Images } from '../../../assets/images';
import CAvatar from '../../../components_old/atoms/CAvatar';
import { SalonStylist } from '../../../models/salonStylist';
import { useThunkDispatch } from '../../../util/hooks/useThunkDispatch';

type Props = {
  open: boolean;
  currentSalonId: number;
  salonStylists: SalonStylist[];
  onClose(): void;
};

export const SwitchLoginSalonDialog: FC<Props> = (props) => {
  const { open, currentSalonId, salonStylists, onClose } = props;

  const dispatch = useThunkDispatch();

  const handleSwitchLoginSalon = useCallback(
    async (salonId: number) => {
      await dispatch(switchLoginSalon(salonId));
    },
    [dispatch]
  );

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={onClose}>
      <DialogTitle>ログインサロンの切り替え</DialogTitle>
      <DialogContent>
        <List>
          {salonStylists.map((ss) => (
            <ListItem
              key={ss.salonId}
              button
              onClick={() => {
                if (currentSalonId === ss.salonId || !ss.canAccess) {
                  return;
                }
                onClose();
                handleSwitchLoginSalon(ss.salonId);
              }}
              disabled={currentSalonId === ss.salonId || !ss.canAccess}
            >
              <ListItemAvatar>
                <CAvatar
                  size={32}
                  src={ss.salonImageURL ?? Images.common.salonNoImage}
                />
              </ListItemAvatar>
              <ListItemText
                primary={ss.salonName}
                secondary={
                  currentSalonId === ss.salonId
                    ? 'ログイン中'
                    : !ss.canAccess
                    ? 'アクセス制限'
                    : ''
                }
                style={{ whiteSpace: 'pre-wrap' }}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};
