import { moment } from '@karutekun/shared/util/datetime';
import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Images } from '../../assets/images';
import { BasicPlan, RestDaysThreshold } from '../../models/salon';
import { selectMySalon } from '../../selectors/salonSelector';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 600,
    marginBottom: 10,
  },
  restTrialDay: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.h4.fontSize,
  },
}));

const CTrialWillEndBanner: FC<{}> = () => {
  const classes = useStyles();
  const salon = useSelector(selectMySalon);
  if (salon.basicPlan !== BasicPlan.Trial) {
    return null;
  }

  const restTrialTime = salon.trialFinishAt - moment().unix();
  const restTrialDays = Math.ceil(restTrialTime / (60 * 60 * 24));
  if (RestDaysThreshold <= restTrialDays) {
    return null;
  }

  return (
    <Grid container justifyContent="center" alignItems="center">
      <div className={classes.container}>
        {restTrialDays > 0 && (
          <Typography variant="h6" align="center">
            残り
            <span className={classes.restTrialDay}>{restTrialDays}</span>日
          </Typography>
        )}
        <Link to="/admin/plan">
          {restTrialDays > 0 ? (
            <img
              src={Images.common.trialWillEndBanner}
              alt="お試し期間はまもなく終了します!"
              width="100%"
            />
          ) : (
            <img
              src={Images.common.trialEndBanner}
              alt="お試し期間が終了しました!"
              width="100%"
            />
          )}
        </Link>
      </div>
    </Grid>
  );
};

export default CTrialWillEndBanner;
