export const White = {
  white: '#FFFFFF',
};

export const Gray = {
  'gray-10': '#F9F9FF',
  'gray-50': '#EAF2FB',
  'gray-100': '#D8E3EE',
  'gray-200': '#C7CFE2',
  'gray-300': '#A3B0C9',
  'gray-400': '#7687A0',
  'gray-500': '#606C82',
  'gray-600': '#464E5E',
  'gray-700': '#333845',
  'gray-800': '#1E1E21',
  'gray-900': '#020202',
} as const;

export const Red = {
  'red-10': '#FFD5D8',
  'red-50': '#FFBBC0',
  'red-100': '#FFA6AC',
  'red-200': '#FF939B',
  'red-300': '#FF808A',
  'red-400': '#FF6571',
  'red-500': '#FF505E',
  'red-600': '#FF3E4E',
  'red-700': '#F22637',
  'red-800': '#CF1D2C',
  'red-900': '#A31D29',
} as const;

export const Pink = {
  'pink-10': '#FFE7EE',
  'pink-50': '#FFC5D6',
  'pink-100': '#FFA9C2',
  'pink-200': '#FF98B6',
  'pink-300': '#FF8AAD',
  'pink-400': '#FF779F',
  'pink-500': '#FF5C8C',
  'pink-600': '#F54E7F',
  'pink-700': '#EF3C71',
  'pink-800': '#DA2B5F',
  'pink-900': '#B81948',
} as const;

export const Blue = {
  'blue-10': '#EFF2FF',
  'blue-50': '#DBE1FF',
  'blue-100': '#C7D0FF',
  'blue-200': '#B2BAFF',
  'blue-300': '#969FFF',
  'blue-400': '#8286FF',
  'blue-500': '#696FFF',
  'blue-600': '#575EFF',
  'blue-700': '#3D44FF',
  'blue-800': '#333ADD',
  'blue-900': '#262CA6',
} as const;

export const Green = {
  'green-900': '#17b004',
};

export const Colors = {
  ...White,
  ...Gray,
  ...Red,
  ...Pink,
  ...Green,
  ...Blue,
};

export const ToastSuccess = '#28A85B';

export const DefaultShadow = '#3F5B753D';

export const SemanticColor = {
  primary: Gray['gray-800'],
  primaryDarken: Gray['gray-900'],
  secondary: Blue['blue-700'],
  secondaryDarken: Blue['blue-900'],
  accent: Pink['pink-700'],
  accentDarken: Pink['pink-900'],
  alert: Red['red-700'],
  success: ToastSuccess,
  shadow: DefaultShadow,
  textPrimaryMain: Gray['gray-800'],
  textPrimarySub: Gray['gray-500'],
  textPrimaryDisabled: Gray['gray-200'],
  textPrimaryInactive: Gray['gray-300'],
  textSecondary: Blue['blue-700'],
  textSecondaryDisabled: Blue['blue-100'],
  textSecondarySub: Blue['blue-100'],
  textAccent: Pink['pink-700'],
  textAccentDisabled: Pink['pink-100'],
  textDanger: Red['red-700'],
  textDangerDisabled: Red['red-100'],
  textWhite: White['white'],
  textLink: Blue['blue-700'],
  // TODO borderで参照してる箇所をborderThinに置き換えて、borderは削除する
  border: Gray['gray-100'],
  borderDark: Gray['gray-300'],
  borderThin: Gray['gray-100'],
  placeholder: Gray['gray-300'],
  backgroundWhite: White['white'],
  backgroundGray: Gray['gray-10'],
  backgroundPrimary: Gray['gray-800'],
  backgroundPrimaryActive: Gray['gray-900'],
  backgroundPrimaryDisabled: Gray['gray-50'],
  backgroundPrimaryInactive: Gray['gray-100'],
  backgroundSecondary: Blue['blue-700'],
  backgroundSecondarySub: Blue['blue-10'],
  backgroundSecondaryActive: Blue['blue-900'],
  backgroundSecondaryDisabled: Blue['blue-10'],
  backgroundSecondarySearch: Blue['blue-10'],
  backgroundAccent: Pink['pink-700'],
  backgroundAccentDarken: Pink['pink-900'],
  backgroundAccentDisabled: Pink['pink-10'],
  backgroundAlert: Red['red-10'],
  backgroundDanger: Red['red-10'],
  backgroundScheduleInactive: Gray['gray-50'],
  backgroundScheduleIcon: Blue['blue-50'],
  backgroundIcon: Gray['gray-50'],
  backgroundCheckboxDisabled: Gray['gray-200'],
  checkIconDisabled: Gray['gray-400'],
  focus: Blue['blue-200'],
} as const;

export const AppColor = {
  line: Green['green-900'],
  visit: {
    reserved: '#F1722A',
    visited: '#26BC86',
    paid: '#7687A0',
  },
  calendar: {
    sunday: { foreground: '#F0282C', background: '#FEEAEA' },
    saturday: { foreground: '#0079B2', background: '#E6F2F7' },
    weekday: {
      foreground: SemanticColor.textPrimaryMain,
      background: SemanticColor.backgroundWhite,
    },
  },
};
