import {
  Alert,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import {
  cancelBroadcastMessage,
  deleteBroadcastMessage,
  fetchBroadcastMessage,
  updateBroadcastMessage,
} from '../../../../actions/broadcastMessageAction';
import { dispatchWithErrorHandling } from '../../../../actions/helper/dispatchWithErrorHandling';
import CButton from '../../../../components_old/atoms/CButton';
import CLink from '../../../../components_old/atoms/CLink';
import {
  PublishScheduleType,
  State,
  emptyBroadcastMessage,
} from '../../../../models/broadcastMessage';
import { checkPermission } from '../../../../models/stylist';
import { selectBroadcastMessageMap } from '../../../../selectors/broadcastMessageSelector';
import { selectMe } from '../../../../selectors/salonSelector';
import { useSalonStatus } from '../../../../templates/providers/salonStatus/salonStatusContext';
import { useSimpleDialog } from '../../../../templates/providers/simpleDialog/simpleDialogContext';
import { formatDate } from '../../../../util/common';
import { useThunkDispatch } from '../../../../util/hooks/useThunkDispatch';
import { BroadcastMessageForm } from '../_components/BroadcastMessageForm';
import { BroadcastMessageStatusTable } from '../_components/BroadcastMessageStatusTable';

type PageParams = { id: string };

const useStyles = makeStyles((_: Theme) => ({
  root: {
    padding: '76px 20px 20px 20px',
  },
}));

export const ViewBroadcastMessage: FC = () => {
  const classes = useStyles();
  const { id = '' } = useParams<PageParams>();
  const { open } = useSimpleDialog();
  const { checkAndOpenDialog } = useSalonStatus();

  const dispatch = useThunkDispatch();
  const navigate = useNavigate();

  const me = useSelector(selectMe);
  const persistedBroadcastMessage =
    useSelector(selectBroadcastMessageMap)[id] ?? null;

  const [broadcastMessage, setBroadcastMessage] = useState(
    emptyBroadcastMessage(persistedBroadcastMessage ?? {})
  );

  useEffect(() => {
    setBroadcastMessage(emptyBroadcastMessage(persistedBroadcastMessage ?? {}));
  }, [persistedBroadcastMessage]);

  useEffect(() => {
    dispatchWithErrorHandling(dispatch, fetchBroadcastMessage(id));
  }, [dispatch, id]);

  const handleSubmit = useCallback(
    async (isDraft: boolean) => {
      if (!checkAndOpenDialog('line')) {
        return;
      }

      await dispatchWithErrorHandling(
        dispatch,
        updateBroadcastMessage(id, broadcastMessage, isDraft),
        {
          success: isDraft
            ? '下書き保存しました'
            : broadcastMessage.publishScheduleType ===
              PublishScheduleType.Immediate
            ? '配信の準備を開始しました'
            : '配信を予約しました',
        }
      );
    },
    [broadcastMessage, checkAndOpenDialog, dispatch, id]
  );

  const handleCancel = useCallback(() => {
    open({
      content: '配信をキャンセルして下書きに戻しますがよろしいですか？',
      onOk: async () => {
        await dispatchWithErrorHandling(dispatch, cancelBroadcastMessage(id), {
          success: '配信予約をキャンセルして、下書きに戻しました',
        });
      },
    });
  }, [dispatch, id, open]);

  const handleDelete = useCallback(() => {
    open({
      content: '本当に削除してよろしいですか？',
      onOk: async () => {
        await dispatchWithErrorHandling(dispatch, deleteBroadcastMessage(id), {
          onSuccess() {
            navigate('/chat/broadcast_messages', { replace: true });
          },
          success: '下書きを削除しました',
        });
      },
    });
  }, [dispatch, id, navigate, open]);

  if (!persistedBroadcastMessage) {
    return <CircularProgress />;
  }

  const hasPermission = checkPermission(me, 'canUpdateChatSetting');

  const isInDraft = broadcastMessage.state === State.Draft;

  return (
    <div className={classes.root}>
      <Typography variant="h6" gutterBottom>
        一斉配信メッセージの確認
      </Typography>

      <Box mt={1} mb={2}>
        <CLink to="/chat/broadcast_messages">&lt; 一覧に戻る</CLink>
      </Box>

      {!hasPermission && (
        <Box mt={2} mb={2}>
          <Alert severity="warning">一斉配信の編集権限がありません</Alert>
        </Box>
      )}

      <Grid container>
        <Grid item xs={12} md={8}>
          <Card>
            <CardContent>
              <BroadcastMessageForm
                broadcastMessage={broadcastMessage}
                onChange={setBroadcastMessage}
                onSubmit={handleSubmit}
                disabled={!hasPermission || !isInDraft}
              />
              <Box mt={2}>
                <Typography variant="subtitle2" gutterBottom>
                  最終更新日時
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {formatDate(persistedBroadcastMessage.editedAt, true)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  ({persistedBroadcastMessage.editedStylist?.name ?? '?'}が更新)
                </Typography>
              </Box>
              {isInDraft && (
                <Box mt={4}>
                  <CButton
                    variant="outlined"
                    onClick={handleDelete}
                    disabled={!hasPermission}
                  >
                    下書きを削除する
                  </CButton>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>

        {!isInDraft && (
          <Grid item xs={12} md={8}>
            <Box mt={4}>
              <Typography variant="h6" gutterBottom>
                配信状況
              </Typography>
            </Box>
            <Card>
              <BroadcastMessageStatusTable
                broadcastMessage={persistedBroadcastMessage}
              />
              {broadcastMessage.state === State.Reserved && (
                <Box p={2}>
                  <CButton
                    variant="outlined"
                    onClick={handleCancel}
                    disabled={!hasPermission}
                  >
                    配信予約をキャンセルする
                  </CButton>
                </Box>
              )}
            </Card>
          </Grid>
        )}
      </Grid>
    </div>
  );
};
