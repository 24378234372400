import { ReservationResource, VisitUtils } from '@karutekun/core/visit';
import { VoucherUtils } from '@karutekun/core/voucher';
import { RouterInput } from '@karutekun/shared/data-access/api-base';
import {
  useCheckIn,
  useInvalidateVisits,
  useUpdateReservation,
} from '@karutekun/shared/data-access/visit';
import { objectKeys, pick } from '@karutekun/shared/util/objects';
import { useBoolean } from '@karutekun/shared/util/react-hooks';
import { Box, Button, Grid, InputLabel, Typography } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  pushSnackbarError,
  pushSnackbarSuccess,
} from '../../../../../actions/generalAction';
import {
  setViewSchedulePreviewUpdates,
  setViewSchedulePreviewVisitId,
} from '../../../../../actions/view/viewScheduleAction';
import CButton from '../../../../../components_old/atoms/CButton';
import CDivider from '../../../../../components_old/atoms/CDivider';
import { VisitStatusTag } from '../../../../../features/visit/components/VisitStatusTag';
import { VisitFormAssignedStylist } from '../../../../../features/visit/components/form/VisitFormAssignedStylist';
import VisitFormCustomerSearchInput from '../../../../../features/visit/components/form/VisitFormCustomerSearchInput';
import { VisitFormDatetime } from '../../../../../features/visit/components/form/VisitFormDatetime';
import { VisitFormIsShimei } from '../../../../../features/visit/components/form/VisitFormIsShimei';
import { VisitFormReservationMemo } from '../../../../../features/visit/components/form/VisitFormReservationMemo';
import { VisitFormSource } from '../../../../../features/visit/components/form/VisitFormSource';
import { EditVoucherDialog } from '../../../../../features/voucher/components/EditVoucherDialog';
import { VoucherPreview } from '../../../../../features/voucher/components/VoucherPreview';
import { selectMySalon } from '../../../../../selectors/salonSelector';
import { useSalonStatus } from '../../../../../templates/providers/salonStatus/salonStatusContext';
import { useSimpleDialog } from '../../../../../templates/providers/simpleDialog/simpleDialogContext';
import { useThunkDispatch } from '../../../../../util/hooks/useThunkDispatch';

type FormData = RequiredDeep<
  Pick<RouterInput['visit']['updateReservation'], 'visit'>
>;

type Props = {
  reservation: ReservationResource;
};

export const SchedulesDrawerPreviewEditReservation: FC<Props> = ({
  reservation,
}) => {
  const dispatch = useThunkDispatch();
  const { open } = useSimpleDialog();

  const invalidate = useInvalidateVisits();

  const salon = useSelector(selectMySalon);
  const { checkAndOpenDialog } = useSalonStatus();
  const [isVoucherDialogOpen, setIsVoucherDialogOpen] = useBoolean(false);
  const { open: openConfirmDialog } = useSimpleDialog();

  const {
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { isDirty, dirtyFields },
  } = useForm<FormData>({
    values: {
      visit: pick(reservation, [
        'customerId',
        'assignedStylistId',
        'title',
        'reservationMemo',
        'startedAt',
        'finishedAt',
        'isShimei',
        'isConfirmedByStaff',
      ]),
    },
  });

  useEffect(() => {
    if (isDirty) {
      dispatch(setViewSchedulePreviewUpdates({}, false, true));
    }
  }, [dispatch, isDirty]);

  const { mutate: updateReservation, isPending } = useUpdateReservation({
    async onSuccess(res) {
      await invalidate();

      dispatch(setViewSchedulePreviewVisitId(res.reservation.id));
      dispatch(setViewSchedulePreviewUpdates({}, true));

      const message = res.lineNotified
        ? '予約を編集し、お客様にLINEでお知らせしました'
        : `予約を編集しました`;
      dispatch(pushSnackbarSuccess(message));
    },
    showConfirmation({ title, message }, onOk) {
      open({ title, content: message, onOk: async () => onOk() });
    },
    onUnhandledError(e) {
      dispatch(
        pushSnackbarError(e.data?.messageForUser ?? 'エラーが発生しました')
      );
    },
  });

  const { checkIn, checkInPending } = useCheckIn({
    showConfirmation(onOk) {
      openConfirmDialog({
        content: '翌日以降の予約です。本当に来店受付しますか？',
        onOk: async function () {
          await onOk();
        },
      });
    },
    reservationStartedAt: reservation.startedAt,
    onSuccess: async () => {
      await invalidate();
      dispatch(pushSnackbarSuccess('来店受付しました'));
    },
  });

  const handleCheckIn = useCallback(async () => {
    await checkIn(reservation.id);
  }, [checkIn, reservation.id]);

  const handleSave = useCallback(async () => {
    if (!checkAndOpenDialog()) {
      return;
    }

    handleSubmit((data) => {
      updateReservation({
        id: reservation.id,
        userUpdatedAt: reservation.userUpdatedAt,
        visit: pick(data.visit, objectKeys(dirtyFields.visit)),
      });
    })();
  }, [
    checkAndOpenDialog,
    dirtyFields.visit,
    handleSubmit,
    reservation.id,
    reservation.userUpdatedAt,
    updateReservation,
  ]);

  const customerId = watch('visit.customerId');
  const isValid = watch('visit.title') !== '' || customerId !== null;

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="visit.customerId"
            render={({ field: { onChange, value } }) => (
              <VisitFormCustomerSearchInput
                title={watch('visit.title')}
                customerId={value}
                isUnassociatable={true}
                onSelect={onChange}
                onChangeTitle={(title) =>
                  setValue('visit.title', title, { shouldDirty: true })
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel shrink>ステータス</InputLabel>
          <VisitStatusTag
            status={reservation.status}
            isPaid={VoucherUtils.isPaid(reservation.voucher)}
            size="small"
          />
        </Grid>
        <Grid item xs={12}>
          <VisitFormDatetime
            startedAt={watch('visit.startedAt')}
            finishedAt={watch('visit.finishedAt')}
            onChange={({ startedAt, finishedAt }) => {
              setValue('visit.startedAt', startedAt, { shouldDirty: true });
              setValue('visit.finishedAt', finishedAt, { shouldDirty: true });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="visit.isShimei"
            render={({ field: { onChange, value } }) => (
              <VisitFormIsShimei isShimei={value} onChange={onChange} />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="visit.assignedStylistId"
            render={({ field: { onChange, value } }) => (
              <VisitFormAssignedStylist
                salonId={salon.id}
                assignedStylistId={value}
                onChange={onChange}
                allowEmpty
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="visit.reservationMemo"
            render={({ field: { onChange, value } }) => (
              <VisitFormReservationMemo
                reservationMemo={value}
                onChange={onChange}
                fullWidth
              />
            )}
          />
        </Grid>
        {!!reservation.noteFromCustomer && (
          <Grid item xs={12}>
            <InputLabel shrink>お客様からのご要望</InputLabel>
            <Box whiteSpace="pre-wrap">
              <Typography variant="body2">
                {reservation.noteFromCustomer}
              </Typography>
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <Controller
            control={control}
            name="visit.isConfirmedByStaff"
            render={({ field: { onChange, value } }) => (
              <VisitFormSource
                salonId={salon.id}
                source={reservation.source}
                isConfirmedByStaff={value}
                createdStylistId={reservation.createdStylistId}
                createdAt={reservation.createdAt}
                onChange={onChange}
              />
            )}
          />
        </Grid>
      </Grid>

      <CDivider spacing={2} />

      <VoucherPreview voucher={reservation.voucher} />
      <Box mt={2} display="flex" justifyContent="center">
        <EditVoucherDialog
          open={isVoucherDialogOpen}
          voucher={reservation.voucher}
          showPaymentButton={false}
          onClose={setIsVoucherDialogOpen.setFalse}
          reservation={reservation}
        />
        <Button
          variant="outlined"
          color="secondary"
          onClick={setIsVoucherDialogOpen.setTrue}
        >
          メニューの編集
        </Button>
      </Box>

      <CDivider spacing={2} />

      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item>
          <CButton
            isLoading={isPending}
            disabled={!isDirty || !isValid}
            onClick={handleSave}
          >
            保存
          </CButton>
        </Grid>
        <Grid item>
          <CButton
            isLoading={checkInPending}
            disabled={isDirty || !VisitUtils.canCheckIn(reservation)}
            onClick={handleCheckIn}
          >
            来店受付
          </CButton>
        </Grid>
      </Grid>
    </div>
  );
};
