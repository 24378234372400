import { IService } from '@karutekun/core/salon-service';
import { IReservation } from '@karutekun/core/visit';
import { IVoucherLine, VoucherUtils } from '@karutekun/core/voucher';
import { useMemo } from 'react';

type Reservation = Pick<IReservation, 'startedAt' | 'finishedAt'>;
type Line = Pick<IVoucherLine, 'serviceId' | 'serviceType' | 'quantity'>;
type Service = Pick<IService, 'time'>;

export function useShouldConfirmChangeReservationTime(
  reservation: Nullable<Reservation>,
  {
    beforeLines,
    afterLines,
    serviceMap,
  }: {
    beforeLines: Nullable<Line[]>;
    afterLines: Nullable<Line[]>;
    serviceMap: IdMap<Service>;
  }
) {
  const { beforeMinutes, afterMinutes } = useMemo(() => {
    const beforeMinutes = VoucherUtils.calculateTotalServiceMinutes(
      beforeLines ?? [],
      serviceMap
    );
    const afterMinutes = VoucherUtils.calculateTotalServiceMinutes(
      afterLines ?? [],
      serviceMap
    );
    return { beforeMinutes, afterMinutes };
  }, [beforeLines, afterLines, serviceMap]);

  const shouldConfirm = reservation
    ? beforeMinutes !== afterMinutes &&
      reservation.startedAt + afterMinutes * 60 !== reservation.finishedAt
    : false;

  return { shouldConfirm, beforeMinutes, afterMinutes };
}
