import { Badge, Box, Container, Tab, Tabs } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router';
import { Link } from 'react-router-dom';
import CDivider from '../../../components_old/atoms/CDivider';
import { selectPendingJoinRequestNum } from '../../../selectors/joinRequestSelector';
import { useResolvedLastPath } from '../../../util/hooks/router/useResolvedLastPath';
import { StylistList } from './_components/StylistList';
import JoinRequests from './join_requests';
import PermissionRoles from './permission_roles';

const useStyles = makeStyles(() => ({
  root: {
    padding: '76px 20px 20px 20px',
  },
  container: {
    padding: 0,
  },
  badge: {
    transform: 'translate(100%, -50%)',
  },
}));

const TabIndexMap: Record<string, number> = {
  stylists: 0,
  permission_roles: 1,
  salon_requests: 2,
};

export const StylistSettings: FC = () => {
  const classes = useStyles();

  const joinRequestsNum = useSelector(selectPendingJoinRequestNum);

  const lastPath = useResolvedLastPath();
  const tabIndex = TabIndexMap[lastPath] ?? 0;

  return (
    <div className={classes.root}>
      <Box mb={2}>
        <Tabs value={tabIndex} indicatorColor="primary" textColor="primary">
          <Tab label="スタッフ一覧" component={Link} to="." />
          <Tab label="役割の管理" component={Link} to="permission_roles" />
          <Tab
            label={
              <Badge
                classes={{ badge: classes.badge }}
                badgeContent={joinRequestsNum}
                max={99}
                color="primary"
              >
                所属申請
              </Badge>
            }
            component={Link}
            to="salon_requests"
          />
        </Tabs>
        <CDivider spacing={0} />
      </Box>

      <Container maxWidth="md" className={classes.container}>
        <Routes>
          <Route path="/*" element={<StylistList />} />
          <Route path="/permission_roles" element={<PermissionRoles />} />
          <Route path="/salon_requests" element={<JoinRequests />} />
        </Routes>
      </Container>
    </div>
  );
};
