import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  Theme,
  Toolbar,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { openSideMenu } from '../actions/uiAction';
import { logout } from '../actions/userAction';
import { Images } from '../assets/images';
import CStylistAvatar from '../components_old/atoms/CStylistAvatar';
import { selectMe, selectMySalon } from '../selectors/salonSelector';
import { selectUserLoginInfo } from '../selectors/userSelector';
import { GlobalState } from '../store';
import { useThunkDispatch } from '../util/hooks/useThunkDispatch';

export const headerHeight = 56;

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: {
    height: headerHeight,
    minHeight: headerHeight,
    paddingRight: 24,
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${Images.common.title})`,
      backgroundSize: 'auto 40px',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
  },
  menuButton: {
    marginRight: 12,
  },
  logo: {
    height: 40,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  name: {
    color: '#fff',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export const Header: FC = () => {
  const classes = useStyles();
  const isSideMenuOpen = useSelector(
    (state: GlobalState) => state.ui.isSideMenuOpen
  );
  const loginInfo = useSelector(selectUserLoginInfo);
  const salon = useSelector(selectMySalon);
  const me = useSelector(selectMe);
  const dispatch = useThunkDispatch();

  const [logoutOpen, setLogoutOpen] = useState(false);

  const handleOpenLogoutDialog = useCallback(() => setLogoutOpen(true), []);
  const handleCloseLogoutDialog = useCallback(() => setLogoutOpen(false), []);
  const handleToggleSideMenu = useCallback(() => {
    dispatch(openSideMenu(!isSideMenuOpen));
  }, [dispatch, isSideMenuOpen]);

  const handleLogout = useCallback(() => {
    dispatch(logout());
    setLogoutOpen(false);
  }, [dispatch]);

  if (!loginInfo) {
    return null;
  }

  return (
    <AppBar position="absolute" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid container item xs justifyContent="flex-start">
            {salon.id !== 0 && (
              <Grid item>
                <IconButton
                  color="inherit"
                  onClick={handleToggleSideMenu}
                  className={classes.menuButton}
                  size="large"
                >
                  <SVGIcon
                    name={isSideMenuOpen ? 'angle-left' : 'bars'}
                    size="lg"
                    color="#fff"
                  />
                </IconButton>
              </Grid>
            )}
            <Grid container item xs alignItems="center">
              <img
                src={Images.common.title}
                alt="KaruteKun"
                className={classes.logo}
              />
            </Grid>
          </Grid>
          <Grid container item xs justifyContent="flex-end">
            {me.id !== 0 && (
              <Grid
                container
                item
                xs
                alignItems="center"
                justifyContent="flex-end"
              >
                <CStylistAvatar size={30} stylist={me} />
                <Typography
                  display="inline"
                  className={classes.name}
                  variant="h6"
                  align="center"
                >
                  {me.name}
                </Typography>
              </Grid>
            )}
            <Grid item>
              <IconButton onClick={handleOpenLogoutDialog} size="large">
                <SVGIcon name="enter" size="lg" color="#fff" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>

      <Dialog open={logoutOpen} keepMounted onClose={handleCloseLogoutDialog}>
        <DialogTitle>ログアウトしますか?</DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseLogoutDialog} color="primary">
            いいえ
          </Button>
          <Button onClick={handleLogout} color="primary">
            はい
          </Button>
        </DialogActions>
      </Dialog>
    </AppBar>
  );
};
