import {
  PermissionKey,
  PermissionRole,
  ReservedRoleIds,
  getPermissionDescription,
  getPermissionName,
} from '@karutekun/core/permission-role';
import {
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import CInformation from '../../../../../components_old/atoms/CInformation';

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    marginTop: theme.spacing(4),
  },
  tableCard: {
    width: 'fit-content',
    marginTop: theme.spacing(1),
  },
  table: {
    width: 'auto',
  },
  leftHeaderCell: {
    width: 200,
  },
  cell: {
    width: 100,
    padding: theme.spacing(1),
  },
  cellDisabled: {
    backgroundColor: theme.palette.grey[200],
  },
}));

export const PermissionSection: FC<{
  title: string;
  permissions: PermissionKey[];
  permissionRoles: PermissionRole[];
  canUpdate: boolean;
  onClickRole(roleId: number): void;
}> = (props) => {
  const classes = useStyles();
  const { title, permissions, permissionRoles, canUpdate, onClickRole } = props;

  return (
    <div className={classes.section}>
      <Typography variant="body1">{title}</Typography>
      <Card className={classes.tableCard} elevation={1}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.leftHeaderCell} />
              {permissionRoles.map((role) => (
                <TableCell
                  className={clsx(classes.cell, {
                    [classes.cellDisabled]:
                      role.roleId === ReservedRoleIds.Admin,
                  })}
                  align="center"
                  key={role.roleId}
                >
                  {role.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {permissions.map((permission, i) => {
              const permissionDesc = getPermissionDescription(permission);
              return (
                <TableRow key={i}>
                  <TableCell className={classes.leftHeaderCell} scope="row">
                    {getPermissionName(permission)}
                    {permissionDesc !== '' && (
                      <CInformation type="tooltip" content={permissionDesc} />
                    )}
                  </TableCell>
                  {permissionRoles.map((role) => (
                    <TableCell
                      className={clsx(classes.cell, {
                        [classes.cellDisabled]:
                          role.roleId === ReservedRoleIds.Admin,
                      })}
                      align="center"
                      key={role.roleId}
                    >
                      <Checkbox
                        disabled={
                          role.roleId === ReservedRoleIds.Admin || !canUpdate
                        }
                        checked={role[permission]}
                        onClick={() => onClickRole(role.roleId)}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Card>
    </div>
  );
};
