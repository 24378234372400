import React from 'react';
import ConfirmDialog, {
  OwnProps as ConfirmDialogProps,
} from '../../templates/hoc/components/ConfirmDialog';

type ArgumentProps = {
  title?: ConfirmDialogProps['title'];
  description: ConfirmDialogProps['description'];
  onCanceled?: ConfirmDialogProps['onCanceled'];
  onOk: ConfirmDialogProps['onOk'];
};
export type WithConfirmDialog = {
  openConfirmDialog(props: ArgumentProps): void;
  closeConfirmDialog(): void;
};

type State = ConfirmDialogProps;

export const withConfirmDialog = <OriginalProps extends {}>(
  WrappedComponent: React.ComponentType<OriginalProps & WithConfirmDialog>
) => {
  return class ConfirmDialogHOC extends React.Component<OriginalProps, State> {
    constructor(props: OriginalProps) {
      super(props);

      this.state = {
        open: false,
        title: '',
        description: '',
        onCanceled: this.closeConfirmDialog,
        onOk: this.closeConfirmDialog,
      };
    }

    openConfirmDialog = (props: ArgumentProps) => {
      this.setState({ ...this.state, ...props, open: true });
    };

    closeConfirmDialog = () => {
      this.setState({ ...this.state, open: false });
    };

    render() {
      return (
        <>
          <ConfirmDialog {...this.state} />
          <WrappedComponent
            openConfirmDialog={this.openConfirmDialog}
            closeConfirmDialog={this.closeConfirmDialog}
            {...this.props}
          />
        </>
      );
    }
  };
};
