import { onAuthStateChanged, signInWithCustomToken } from 'firebase/auth';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import RootComponent, { DispatchProps } from './RootComponent';
import { openSideMenu } from './actions/uiAction';
import {
  clearUser,
  fetchInitInfo,
  fetchUserJoinedSalon,
  getCustomToken,
  setUserLoginInfo,
} from './actions/userAction';
import FirebaseAnalytics from './firebaseAnalytics';
import { firebaseAuth } from './libs/firebase';
import Logger from './logger';
import RemoteConfig from './remoteConfig';
import { ThunkDispatch } from './store';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  setupSideMenu(open: boolean) {
    dispatch(openSideMenu(open));
  },

  async initializeFirebase(appStartLoginSalonId?: number) {
    await FirebaseAnalytics.initialize();
    await RemoteConfig.initialize();

    onAuthStateChanged(firebaseAuth, async (user) => {
      Logger.info('[Auth] onAuthStateChanged', user);

      if (user !== null) {
        // アプリ起動時のログインサロンが指定されている場合は
        // ここでカスタムトークンを取得してログインし直す
        if (appStartLoginSalonId) {
          const customToken = await dispatch(
            // TODO 一時的に lint を無効化しています。気づいたベースで直してください
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            getCustomToken(appStartLoginSalonId) as any
          );
          if (customToken) {
            await signInWithCustomToken(firebaseAuth, customToken);
          }
        }

        dispatch(
          setUserLoginInfo({
            email: user.email,
            isEmailVerified: user.emailVerified,
          })
        );
        Logger.setUser({
          firebaseUid: user.uid,
          email: user.email,
        });
      } else {
        dispatch(clearUser());
        Logger.unsetUser();
      }
    });
  },

  async fetchUserJoinedSalon() {
    await (dispatch as ThunkDispatch)(fetchUserJoinedSalon());
  },

  async fetchInitInfo() {
    await (dispatch as ThunkDispatch)(fetchInitInfo());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RootComponent);
