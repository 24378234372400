import { ChatMessageBody } from '../../models/chatMessageBody/entity';
import { ChatMessageBodyBroadcast as ChatMessageBodyBroadcastSchema } from './schema';

export class ChatMessageBodyBroadcast implements ChatMessageBody {
  text: string;
  broadcastMessageId: string;

  constructor(args: ChatMessageBodyBroadcastSchema) {
    this.text = args.text;
    this.broadcastMessageId = args.broadcastMessageId;
  }
}
