import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M3.25 24C3.25 12.541 12.541 3.25 24 3.25S44.75 12.541 44.75 24 35.459 44.75 24 44.75 3.25 35.459 3.25 24M24 6.75C14.474 6.75 6.75 14.474 6.75 24S14.474 41.25 24 41.25 41.25 33.526 41.25 24 33.526 6.75 24 6.75m-3 12.5a1.75 1.75 0 1 0 0 3.5h1.25v7.75H20a1.75 1.75 0 1 0 0 3.5h8a1.75 1.75 0 1 0 0-3.5h-2.25V21A1.75 1.75 0 0 0 24 19.25zm3-6.65a2.4 2.4 0 1 0 0 4.8 2.4 2.4 0 0 0 0-4.8"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgInfoCircle = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgInfoCircle);
export default Memo;
