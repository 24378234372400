import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M13.75 4.5c0-.97-.78-1.75-1.75-1.75s-1.75.78-1.75 1.75v1.25H9c-3.45 0-6.25 2.8-6.25 6.25v27c0 3.45 2.8 6.25 6.25 6.25h30c3.45 0 6.25-2.8 6.25-6.25V12c0-3.45-2.8-6.25-6.25-6.25h-1.25V4.5c0-.97-.78-1.75-1.75-1.75s-1.75.78-1.75 1.75v1.25h-20.5zM30 21.75c0 1.24-1.01 2.25-2.25 2.25s-2.25-1.01-2.25-2.25 1.01-2.25 2.25-2.25S30 20.51 30 21.75m7.5 0c0 1.24-1.01 2.25-2.25 2.25S33 22.99 33 21.75s1.01-2.25 2.25-2.25 2.25 1.01 2.25 2.25m-9.75 9.75c1.24 0 2.25-1.01 2.25-2.25S28.99 27 27.75 27s-2.25 1.01-2.25 2.25 1.01 2.25 2.25 2.25m9.75-2.25c0 1.24-1.01 2.25-2.25 2.25S33 30.49 33 29.25 34.01 27 35.25 27s2.25 1.01 2.25 2.25M12.75 31.5c1.24 0 2.25-1.01 2.25-2.25S13.99 27 12.75 27s-2.25 1.01-2.25 2.25 1.01 2.25 2.25 2.25m9.75-2.25c0 1.24-1.01 2.25-2.25 2.25S18 30.49 18 29.25 19.01 27 20.25 27s2.25 1.01 2.25 2.25M12.75 39c1.24 0 2.25-1.01 2.25-2.25s-1.01-2.25-2.25-2.25-2.25 1.01-2.25 2.25S11.51 39 12.75 39m9.75-2.25c0 1.24-1.01 2.25-2.25 2.25S18 37.99 18 36.75s1.01-2.25 2.25-2.25 2.25 1.01 2.25 2.25M27.75 39c1.24 0 2.25-1.01 2.25-2.25s-1.01-2.25-2.25-2.25-2.25 1.01-2.25 2.25S26.51 39 27.75 39"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgCalendarAltFill = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgCalendarAltFill);
export default Memo;
