import { moment } from '@karutekun/shared/util/datetime';
import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import {
  Alert,
  Box,
  Card,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect } from 'react';
import { fetchSubscriptionInfo } from '../../../actions/subscriptionAction';
import CButton from '../../../components_old/atoms/CButton';
import CProgress from '../../../components_old/atoms/CProgress';
import CTextBox from '../../../components_old/atoms/CTextBox';
import CTextLink from '../../../components_old/atoms/CTextLink';
import Logger, { AnalyticsEvent } from '../../../logger';
import {
  BasicPlan,
  BasicPlanNameMap,
  MySalon,
  OptionLinePlan,
  OptionLinePlanName,
} from '../../../models/salon';
import {
  PaymentProviderType,
  Subscription,
  SubscriptionStatus,
} from '../../../models/subscription';
import { useSelectMySalon } from '../../../selectors/salonSelector';
import { withAdminPage } from '../../../templates/hoc/AdminPageHOC';
import { formatDate } from '../../../util/common';
import { useFlag } from '../../../util/hooks/useFlag';
import { useThunkDispatch } from '../../../util/hooks/useThunkDispatch';
import { PaymentProviderInfo } from './_components/PaymentProviderInfo';
import { TemporarilyCanceledDescriptionBox } from './subscription/update/_components/TemporarilyCanceledDescriptionBox';

const useStyles = makeStyles((theme: Theme) => ({
  planCard: {
    maxWidth: 600,
    minHeight: 100,
  },
  buttonContainer: {
    textAlign: 'right',
    padding: theme.spacing(1),
  },
  table: {
    '& th': {
      width: 160,
    },
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
}));

const CheckPlan: FC = () => {
  const classes = useStyles();
  const salon = useSelectMySalon();
  const dispatch = useThunkDispatch();

  const [isFetching, callWithIsFetching] = useFlag(false);

  useEffect(() => {
    Logger.logEvent(AnalyticsEvent.viewCheckPlan);
  }, []);

  useEffect(() => {
    callWithIsFetching(() => dispatch(fetchSubscriptionInfo()));
  }, [callWithIsFetching, dispatch]);

  return (
    <Grid container spacing={2}>
      {salon.basicPlan !== BasicPlan.Trial && (
        <Grid item xs={12}>
          <Alert
            severity="info"
            action={
              <Link
                href={process.env.REACT_APP_TASK_KILL_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                再起動方法はこちら
              </Link>
            }
          >
            契約手続き後もアプリがトライアル期間のままである場合はアプリを再起動して下さい。
          </Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="h6">プラン内容</Typography>
      </Grid>
      <Grid item xs={12}>
        <Card className={classes.planCard}>
          {isFetching ? (
            <CProgress />
          ) : (
            <>
              <PlanDetailTable salon={salon} />
              <PlanEditButton salon={salon} />
            </>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

const PlanDetailTable: FC<{ salon: MySalon }> = (props) => {
  const classes = useStyles();

  const salon = props.salon;
  const subscription = salon.subscription;

  return (
    <Table className={classes.table}>
      <TableBody>
        <TableRow>
          <TableCell component="th" scope="row">
            サロン名
          </TableCell>
          <TableCell>
            <Typography variant="body1">{salon.name}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row">
            ご利用プラン
          </TableCell>
          <TableCell>
            <Typography variant="body2" color="textSecondary">
              基本プラン
            </Typography>
            <Box ml={2} mb={1}>
              <Typography variant="body1" gutterBottom>
                {BasicPlanNameMap[salon.basicPlan]}
              </Typography>
            </Box>
            <Typography variant="body2" color="textSecondary">
              オプション
            </Typography>
            <Box ml={2} mb={1}>
              <Typography variant="body1" gutterBottom>
                {salon.optionLinePlan === OptionLinePlan.Trial
                  ? 'なし'
                  : OptionLinePlanName}
              </Typography>
            </Box>

            <TemporarilyCanceledDescriptionBox
              subscription={subscription}
              mt={2}
            />
          </TableCell>
        </TableRow>

        {/* トライアルなら期間を表示 */}
        {salon.basicPlan === BasicPlan.Trial && (
          <TableRow>
            <TableCell component="th" scope="row">
              トライアル期間
            </TableCell>
            <TableCell>
              {moment().unix() < salon.trialFinishAt ? (
                <Typography variant="body1">
                  {formatDate(salon.trialFinishAt)} まで
                </Typography>
              ) : (
                <Typography variant="body1" color="error">
                  期限切れ
                </Typography>
              )}
            </TableCell>
          </TableRow>
        )}

        {subscription !== null && (
          // subscription がある場合は日付などの詳細を表示
          // basicPlan === BasicPlan.Trial でも subscription のレコード(status: incomplete)が
          // 存在するケースがあることに注意
          <SubscriptionDetailRows subscription={subscription} />
        )}

        {/* プラン登録しているが、クレカ支払いに切り替えていない場合 */}
        {salon.basicPlan !== BasicPlan.Trial && subscription === null && (
          <TableRow>
            <TableCell component="th" scope="row">
              お支払い方法
            </TableCell>
            <TableCell>銀行振込</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

const SubscriptionDetailRows: FC<{ subscription: Subscription }> = (props) => {
  const { subscription } = props;
  return (
    <>
      <TableRow>
        <TableCell component="th" scope="row">
          前回引き落とし日
        </TableCell>
        <TableCell>
          <Typography variant="body1">
            {subscription.lastPaymentSucceededAt === null ||
            // トライアル開始時も内部上は0円の決済が走ったことになるので、日時が出てしまう
            // 実際には請求はされないので混乱を招かないためにこの条件分を追加
            subscription.status === SubscriptionStatus.Trialing
              ? '-'
              : formatDate(subscription.lastPaymentSucceededAt)}
          </Typography>

          {/* 決済のエラーがある場合は表示される */}
          <PaymentErrorBox subscription={subscription} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell component="th" scope="row">
          {subscription.status === SubscriptionStatus.TemporarilyCanceled
            ? '解約予定日'
            : '次回引き落とし予定日'}
        </TableCell>
        <TableCell>
          <Typography variant="body1">
            {formatDate(subscription.nextPaymentAt)}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell component="th" scope="row">
          お支払い情報
        </TableCell>
        <TableCell>
          <PaymentProviderInfo paymentProvider={subscription.paymentProvider} />
        </TableCell>
      </TableRow>
    </>
  );
};

const PaymentErrorBox: FC<{ subscription: Subscription }> = (props) => {
  const { subscription } = props;

  if (!subscription.paymentFailedAt) {
    return null;
  }

  return (
    <CTextBox type="error" mt={2}>
      {subscription.status === SubscriptionStatus.Incomplete ? (
        // トライアル期限切れから即時決済失敗時(24時間後にexpireされる状態)
        // 非同期でクレカ認証されたりすると、webhook側でプランの有効化が走るので
        // お支払いが完了していない期間が微妙にでてしまう
        // 文言を他の決済の失敗時とは分けたほうが親切
        <>
          <div>
            {`まだお支払いの確認が出来ておりません。
            お支払い方法に問題がなければ、通常数分程度で処理が完了します。
            時間をおいて再度アクセスしてください。
            時間がたっても解決しない場合は、お支払い方法に問題がないかご確認いただくか、サポートにお問い合わせ下さい。`}
          </div>
          <Box m={1}>
            <CTextLink
              text="> お問い合わせ"
              linkTo={process.env.REACT_APP_CONTACT_URL}
            />
          </Box>
        </>
      ) : (
        <>
          <div>
            {`引き落としが出来ませんでした。
            お支払い情報に間違いがないか確認の上、再度設定しなおしてください。
            日時: ${formatDate(subscription.paymentFailedAt, true)}`}
          </div>
          <CButton variant="text" linkTo="/admin/plan/subscription/update">
            設定する
          </CButton>
        </>
      )}
    </CTextBox>
  );
};

const PlanEditButton: FC<{ salon: MySalon }> = (props) => {
  const classes = useStyles();

  const salon = props.salon;
  const subscription = salon.subscription;

  return (
    <div className={classes.buttonContainer}>
      {subscription !== null ? (
        subscription.paymentProvider?.paymentProviderType ===
        PaymentProviderType.Stripe ? (
          <CButton
            variant="text"
            startIcon={<SVGIcon name="pen" />}
            linkTo="/admin/plan/subscription/update"
          >
            詳細の確認と変更
          </CButton>
        ) : subscription.paymentProvider?.paymentProviderType ===
          PaymentProviderType.AppleInAppPurchase ? (
          <Typography variant="body1" color="textSecondary">
            ※ ご契約内容の変更は{' '}
            <a
              className={classes.link}
              href={process.env.REACT_APP_APP_STORE_CANCEL_SUBSCRIPTION_GUIDE}
              target="_blank"
              rel="noreferrer"
            >
              App Store
            </a>{' '}
            で行ってください
          </Typography>
        ) : null
      ) : salon.basicPlan === BasicPlan.Trial ? (
        <CButton
          variant="text"
          startIcon={<SVGIcon name="pen" />}
          linkTo="/admin/plan/subscription/new"
        >
          プランの変更
        </CButton>
      ) : (
        <CButton
          variant="text"
          startIcon={<SVGIcon name="pen" />}
          linkTo="/admin/plan/subscription/new"
        >
          お支払い方法の変更
        </CButton>
      )}
    </div>
  );
};

export default withAdminPage(CheckPlan);
