import { ChatMessageBody } from '../../models/chatMessageBody/entity';
import { ChatMessageBodyBroadcast } from '../../models/chatMessageBodyBroadcast/entity';
import { ChatMessageBodyBroadcast as ChatMessageBodyBroadcastSchema } from '../../models/chatMessageBodyBroadcast/schema';
import { ChatMessageBodyImage } from '../../models/chatMessageBodyImage/entity';
import { ChatMessageBodyImage as ChatMessageBodyImageSchema } from '../../models/chatMessageBodyImage/schema';
import { ChatMessageBodyKarute } from '../../models/chatMessageBodyKarute/entity';
import { ChatMessageBodyKarute as ChatMessageBodyKaruteSchema } from '../../models/chatMessageBodyKarute/schema';
import { ChatMessageBodyReceipt } from '../../models/chatMessageBodyReceipt/entity';
import { ChatMessageBodyReceipt as ChatMessageBodyReceiptSchema } from '../../models/chatMessageBodyReceipt/schema';
import { ChatMessageBodyReservation } from '../../models/chatMessageBodyReservation/entity';
import { ChatMessageBodyReservation as ChatMessageBodyReservationSchema } from '../../models/chatMessageBodyReservation/schema';
import { ChatMessageBodyReservationRemind } from '../../models/chatMessageBodyReservationRemind/entity';
import { ChatMessageBodyReservationRemind as ChatMessageBodyReservationRemindSchema } from '../../models/chatMessageBodyReservationRemind/schema';
import { ChatMessageBodyText } from '../../models/chatMessageBodyText/entity';
import { ChatMessageBodyText as ChatMessageBodyTextSchema } from '../../models/chatMessageBodyText/schema';
import { PlainStylist } from '../../models/stylist';
import {
  ChatMessage as ChatMessageSchema,
  ChatMessageSender,
  ChatMessageStatus,
  ChatMessageType,
} from './schema';

export class ChatMessage {
  documentId: string;
  salonId: number;
  customerId: number;
  fromId: number;
  status: ChatMessageStatus;
  type: ChatMessageType;
  body: ChatMessageBody;
  createdAtMs: number;
  stylist?: PlainStylist | null;

  constructor(args: ChatMessageSchema) {
    this.documentId = args.documentId;
    this.salonId = args.salonId;
    this.customerId = args.customerId;
    this.fromId = args.fromId;
    this.status = args.status;
    this.type = args.type;
    this.createdAtMs = args.createdAtMs;
    this.stylist = args.stylist;

    switch (args.type) {
      case ChatMessageType.Image:
        this.body = new ChatMessageBodyImage(
          args.body as ChatMessageBodyImageSchema
        );
        break;
      case ChatMessageType.Karute:
        this.body = new ChatMessageBodyKarute(
          args.body as ChatMessageBodyKaruteSchema
        );
        break;
      case ChatMessageType.Reservation:
        this.body = new ChatMessageBodyReservation(
          args.body as ChatMessageBodyReservationSchema
        );
        break;
      case ChatMessageType.Text:
        this.body = new ChatMessageBodyText(
          args.body as ChatMessageBodyTextSchema
        );
        break;
      case ChatMessageType.Broadcast:
        this.body = new ChatMessageBodyBroadcast(
          args.body as ChatMessageBodyBroadcastSchema
        );
        break;
      case ChatMessageType.Receipt:
        this.body = new ChatMessageBodyReceipt(
          args.body as ChatMessageBodyReceiptSchema
        );
        break;
      case ChatMessageType.ReservationRemind:
        this.body = new ChatMessageBodyReservationRemind(
          args.body as ChatMessageBodyReservationRemindSchema
        );
        break;
      default:
        throw new Error('invalid chat message type.');
    }
  }

  detectSender(): ChatMessageSender {
    switch (this.type) {
      case 'text':
      case 'image':
        // サロン、顧客ともに送信できる基本メッセージ
        return this.fromId === 0
          ? ChatMessageSender.Customer
          : ChatMessageSender.Salon;

      case 'karute':
      case 'receipt':
        return ChatMessageSender.Salon;

      case 'reservation':
      case 'broadcastMessage':
      case 'reservationRemind':
        return ChatMessageSender.System;

      default:
        throw Error(`Unknown chat message type: ${this.type}`);
    }
  }
}
