import { useMemo, useState } from 'react';

type Mutations<T> = {
  set: (items: T[]) => void;
  push: (item: T) => void;
  unshift: (item: T) => void;
  deleteIndex: (index: number) => void;
  replaceIndex: (index: number, item: T) => void;
  // 指定された item が存在する場合は配列から取り除き、存在しない場合は push する
  toggle: (newItem: T, comparator?: (item: T) => boolean) => void;
  // 指定したインデックスを入れ替える
  swap: (index1: number, index2: number) => void;
  findAndReplace: (newValue: T, func: (item: T) => boolean) => void;
  findAndRemove: (func: (item: T) => boolean) => void;
  clear: () => void;
};

export function useArray<T>(initialValue: T[] = []): [T[], Mutations<T>] {
  const [array, setArray] = useState<T[]>(initialValue);

  const mutations: Mutations<T> = useMemo(
    () => ({
      set: setArray,
      push: (item: T) => setArray((arr) => [...arr, item]),
      unshift: (item: T) => setArray((arr) => [item, ...arr]),
      deleteIndex: (index: number) => {
        setArray((arr) => {
          const copy = [...arr];
          copy.splice(index, 1);
          return copy;
        });
      },
      replaceIndex: (index: number, item: T) => {
        setArray((arr) => {
          const copy = [...arr];
          copy[index] = item;
          return copy;
        });
      },
      toggle: (newItem: T, comparator?: (item: T) => boolean) => {
        setArray((arr) => {
          const copy = [...arr];
          const index = comparator
            ? copy.findIndex((item) => comparator(item))
            : copy.findIndex((item) => item === newItem);
          if (index > -1) {
            copy.splice(index, 1);
          } else {
            copy.push(newItem);
          }
          return copy;
        });
      },
      swap: (i1: number, i2: number) => {
        setArray((arr) => {
          const copy = [...arr];
          const tmp = arr[i1];
          copy[i1] = copy[i2];
          copy[i2] = tmp;
          return copy;
        });
      },
      findAndReplace: (newItem: T, func: (item: T) => boolean) => {
        setArray((arr) => {
          const copy = [...arr];
          const index = copy.findIndex((item) => func(item));
          if (index > -1) {
            copy[index] = newItem;
          }

          return copy;
        });
      },
      findAndRemove: (func: (item: T) => boolean) => {
        setArray((arr) => {
          const copy = [...arr];
          const index = copy.findIndex((item) => func(item));
          if (index > -1) {
            copy.splice(index, 1);
          }

          return copy;
        });
      },
      clear: () => setArray([]),
    }),
    []
  );

  return [array, mutations];
}
