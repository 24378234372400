const hasNative =
  document &&
  // TODO 一時的に lint を無効化しています。気づいたベースで直してください
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (document.elementsFromPoint || (document as any).msElementsFromPoint);

function getDropTargetElementsAtPoint(
  x: number,
  y: number,
  dropTargets: HTMLElement[]
) {
  return dropTargets.filter((t) => {
    const rect = t.getBoundingClientRect();
    return (
      x >= rect.left && x <= rect.right && y <= rect.bottom && y >= rect.top
    );
  });
}

// 古い iOS の safari では document.elementsFromPoint がサポートされていない
export const backendOptions = !hasNative
  ? {
      getDropTargetElementsAtPoint,
    }
  : {};
