import { mapBy } from '@karutekun/shared/util/objects';
import { Dispatch } from 'redux';
import { deprecated } from 'typesafe-actions';
import {
  DailyBusinessInfo,
  DailyBusinessInfoUpdate,
  PlainDailyBusinessInfo,
  businessInfoFromResource,
} from '../models/salonBusinessInfo';
import { createCancellableAsyncAction } from './helper/asyncActionCreator';
import { sendRequest } from './request';

const { createAction } = deprecated;

export enum ActionNames {
  SetSalonBusinessInfoMap = 'salon/SetSalonBusinessInfoMap',
}

export const setSalonBusinessInfoMap = createAction(
  ActionNames.SetSalonBusinessInfoMap,
  (action) => {
    return (map: DateMap<PlainDailyBusinessInfo>) => action({ map });
  }
);

export function fetchBusinessInfo(range: { from: string; to?: string }) {
  return createCancellableAsyncAction(async (dispatch, signal) => {
    const params = { from: range.from, ...(range.to ? { to: range.to } : {}) };
    const json = await sendRequest(dispatch, `salons/business_info`, {
      signal,
      params,
    });

    if (!json || !Array.isArray(json)) {
      return {};
    }

    const list: PlainDailyBusinessInfo[] = json.map(businessInfoFromResource);
    const map = mapBy(list, 'date');
    dispatch(setSalonBusinessInfoMap(map));

    return map;
  });
}

export function updateBusinessInfo(
  updateMap: DateMap<DailyBusinessInfoUpdate>
) {
  return async function (dispatch: Dispatch) {
    const json = await sendRequest(dispatch, `salons/business_info`, {
      method: 'POST',
      body: JSON.stringify(updateMap),
    });

    const list: DailyBusinessInfo[] = json.map(businessInfoFromResource);
    const map = mapBy(list, 'date');
    dispatch(setSalonBusinessInfoMap(map));

    return map;
  };
}
