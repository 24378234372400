export enum TaxRoundingType {
  Floor = 0,
  Round = 1,
  Ceil = 2,
}

export const TaxRoundingTypes = Object.values(TaxRoundingType);

export const TaxRoundingTypeLabels = {
  [TaxRoundingType.Floor]: '切り捨て' as const,
  [TaxRoundingType.Round]: '四捨五入' as const,
  [TaxRoundingType.Ceil]: '切り上げ' as const,
};
