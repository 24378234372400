import { notEmpty } from '@karutekun/shared/util/objects';
import _ from 'lodash';

// 選択されたメニューカテゴリのなかの業種判定
export type BusinessCategory =
  | 'ヘア'
  | 'ネイル'
  | 'アイラッシュ'
  | 'エステ'
  | '整体';

function iconNameSetting(
  businessCategory: BusinessCategory | null = null,
  initialCategoryName: string | null = null,
  initialMenu: {
    name: string;
    time: number;
    price: number;
  } | null = null
) {
  return {
    businessCategory,
    initialCategoryName,
    initialMenu,
  };
}
// keyが数字以外になることがあればpickBusinessCategoryFromIconNames, createInitialCategoriesのロジックも要修正
const iconNameMap = {
  '1101': iconNameSetting('ヘア', 'カット', {
    name: 'カット',
    time: 30,
    price: 4000,
  }),
  '1102': iconNameSetting('ヘア', 'カラー', {
    name: 'カラー',
    time: 60,
    price: 6000,
  }),
  '1103': iconNameSetting('ヘア', 'パーマ', {
    name: 'パーマ',
    time: 60,
    price: 7000,
  }),
  '1104': iconNameSetting('ヘア', '縮毛矯正'),
  '1105': iconNameSetting('ヘア', 'トリートメント'),
  '1106': iconNameSetting('ヘア', 'シャンプー・ブロー'),
  '1107': iconNameSetting('ヘア', 'セット', {
    name: 'セット',
    time: 30,
    price: 3000,
  }),
  '1108': iconNameSetting(null, 'その他', {
    name: '初回限定コース',
    time: 60,
    price: 2000,
  }),
  '1201': iconNameSetting('ネイル', 'ジェル', {
    name: 'ソフトジェル10本',
    time: 60,
    price: 5000,
  }),
  '1202': iconNameSetting('ネイル', 'スカルプ'),
  '1203': iconNameSetting('ネイル', 'ネイルケア'),
  '1204': iconNameSetting('ネイル', 'フットネイル'),
  '1205': iconNameSetting('ネイル', 'ネイルオフ', {
    name: 'ソフトジェル付替オフ10本',
    time: 30,
    price: 1500,
  }),
  '1301': iconNameSetting('アイラッシュ', 'まつげエクステ', {
    name: 'シングルラッシュ100本',
    time: 60,
    price: 6000,
  }),
  '1302': iconNameSetting('アイラッシュ', 'アイブロウ'),
  '1303': iconNameSetting('アイラッシュ', 'まつ毛オフ', {
    name: '付替オフ',
    time: 30,
    price: 1000,
  }),
  '1401': iconNameSetting('エステ', 'フェイシャル', {
    name: 'お顔ほぐしコース',
    time: 60,
    price: 6000,
  }),
  '1402': iconNameSetting('エステ', 'ヘッド', {
    name: 'ドライヘッドスパコース',
    time: 60,
    price: 5000,
  }),
  '1403': iconNameSetting('エステ', 'ボディ'),
  '1404': iconNameSetting('エステ', '脱毛'),
  '1501': iconNameSetting('整体', 'ボディケア', {
    name: '全身もみほぐしコース30分',
    time: 30,
    price: 3000,
  }),
  '1502': iconNameSetting('整体', '足裏・リフレ', {
    name: '足つぼコース30分',
    time: 30,
    price: 3000,
  }),
  '1503': iconNameSetting('整体', 'ヘッドマッサージ'),
  // サロン作成時には選択できないが、設定から選択可能なカテゴリ
  '9001': iconNameSetting(),
  '9002': iconNameSetting(),
  '9003': iconNameSetting(),
  '9004': iconNameSetting(),
  '9005': iconNameSetting(),
  '9006': iconNameSetting(),
  '9007': iconNameSetting(),
  '9008': iconNameSetting(),
  '9009': iconNameSetting(),
  '9010': iconNameSetting(),
};

export type MenuIconName = keyof typeof iconNameMap;
export const defaultIconNames = Object.keys(iconNameMap).filter(
  // TODO 一時的にルールを無効化しています。気づいたベースで直してください
  // @ts-expect-error: strictFunctionTypes
  (iconName: MenuIconName) => iconNameMap[iconName].businessCategory === 'ヘア'
) as MenuIconName[];
// サロン作成時に強制追加されるカテゴリ
const additionalIconNames: MenuIconName[] = ['1108'];

// iconNameに応じて初期メニューデータを作る
// サロン作成時の初期メニューにのみ使用できます
export function buildInitialMenuDataValues(iconName: MenuIconName) {
  if (!iconNameMap[iconName]) {
    throw new Error(`iconName not found: ${iconName}`);
  }
  return iconNameMap[iconName].initialMenu;
}

// 選択されたメニューカテゴリの初期データ作成
// 「その他」は強制で追加
export function buildInitialMenuCategories(inputIconNames: MenuIconName[]) {
  const validIconNames = _.uniq(
    inputIconNames
      .filter((iconName) => iconNameMap[iconName])
      .concat(additionalIconNames)
  );

  return validIconNames.map((iconName, i) => ({
    iconName,
    name: iconNameMap[iconName].initialCategoryName,
    order: validIconNames.length - i,
  }));
}

export function getBusinessCategoriesByIconNames(
  inputIconNames: string[]
): BusinessCategory[] {
  return _.uniq(
    inputIconNames
      .filter((iconName) => iconName in iconNameMap)
      .map(
        // TODO 一時的にルールを無効化しています。気づいたベースで直してください
        // @ts-expect-error: strictFunctionTypes
        (iconName: MenuIconName) =>
          iconNameMap[iconName].businessCategory as BusinessCategory
      )
      .filter(notEmpty)
  );
}

export function isValidMenuIconName(iconName: string): boolean {
  return iconName in iconNameMap;
}
