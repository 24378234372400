import { CircularProgress, Theme } from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

type OwnProps = IconButtonProps & {
  isLoading?: boolean;
  linkTo?: string;
  openInNewWindow?: boolean;
};

const useStyles = makeStyles((_: Theme) => ({
  button: {
    position: 'relative',
  },
  loadingOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
  },
}));

const CIconButton: FC<OwnProps> = (props) => {
  const classes = useStyles();

  const {
    className,
    disabled,
    isLoading,
    children,
    linkTo,
    openInNewWindow,
    ...buttonProps
  } = props;

  return (
    <IconButton
      className={clsx(className, classes.button)}
      {...buttonProps}
      {...(linkTo
        ? {
            to: linkTo,
            target: openInNewWindow ? '_blank' : '_self',
            component: Link,
          }
        : {})}
      disabled={isLoading || disabled}
      size="large"
    >
      {children}
      {isLoading && !disabled && (
        <CircularProgress className={classes.loadingOverlay} size={24} />
      )}
    </IconButton>
  );
};

export default CIconButton;
