import { objectKeys } from '@karutekun/shared/util/objects';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { Card, Checkbox, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { dispatchWithErrorHandling } from '../../../../actions/helper/dispatchWithErrorHandling';
import {
  createReservationStylist,
  updateReservationStylist,
} from '../../../../actions/salon/reservationSettingsAction';
import CButton from '../../../../components_old/atoms/CButton';
import CDivider from '../../../../components_old/atoms/CDivider';
import CProgressOverlay from '../../../../components_old/atoms/CProgressOverlay';
import CRadioGroup from '../../../../components_old/atoms/CRadioGroup';
import CStylistAvatar from '../../../../components_old/atoms/CStylistAvatar';
import CTextInput from '../../../../components_old/atoms/CTextInput';
import CBreadCrumbs from '../../../../components_old/molecules/CBreadCrumbs';
import { EditablePlainReservationStylist } from '../../../../models/reservationStylist';
import { PlainStylist, checkPermission } from '../../../../models/stylist';
import {
  selectActiveReservationMenus,
  selectActiveReservationStylists,
} from '../../../../selectors/reservationSettingsSelector';
import {
  selectActiveStylists,
  selectMe,
} from '../../../../selectors/salonSelector';
import { formatMoney } from '../../../../util/common';
import { useQueryParams } from '../../../../util/hooks/router/useQueryParams';
import { useFlag } from '../../../../util/hooks/useFlag';
import { useThunkDispatch } from '../../../../util/hooks/useThunkDispatch';
import { formatTextForSearch } from '../../../../util/string';

const useStyles = makeStyles((_: Theme) => ({
  root: {
    padding: '76px 20px 20px 20px',
    maxWidth: 560,
  },
  card: {
    position: 'relative',
    padding: 24,
  },
  availableMenuList: {
    maxHeight: 800,
    overflow: 'auto',
  },
  searchTextInput: { marginTop: 24, marginLeft: 24, marginRight: 24 },
  topCheckboxContainer: {
    display: 'flex',
    marginTop: 16,
    marginBottom: 4,
    marginLeft: 16,
    marginRight: 24,
  },
  indeterminateIcon: { color: '#20a8d8' },
  reservationMenuItem: { cursor: 'pointer', marginLeft: 16, marginRight: 24 },
  reservationMenuDetail: { width: 72 + 16 }, // 72 + paddingの16
}));

export const AddReservationStylist: FC = () => {
  const queryParams = useQueryParams();
  const stylistId = Number(queryParams.get('id'));

  const stylists = useSelector(selectActiveStylists);
  const stylist = stylists.find((s) => s.id === stylistId);

  const [isFetching, callWithFetching] = useFlag(false);

  const dispatch = useThunkDispatch();
  const navigate = useNavigate();

  const handleCreate = useCallback(
    (stylistId: number, values: EditablePlainReservationStylist) =>
      callWithFetching(async () => {
        const reservationStylist = await dispatchWithErrorHandling(
          dispatch,
          createReservationStylist(stylistId, values),
          { success: 'スタッフの予約設定を作成しました' }
        );

        if (reservationStylist) {
          navigate(`/settings/reservation/stylist/${reservationStylist.id}`, {
            replace: true,
          });
        }
      }),
    [dispatch, callWithFetching, navigate]
  );

  if (!stylist) {
    return null;
  }

  return (
    <AddEditReservationStylist
      stylist={stylist}
      defaultValues={{ name: stylist.name }}
      isFetching={isFetching}
      onSave={handleCreate}
    />
  );
};

export const EditReservationStylist: FC = () => {
  const params = useParams<{ reservationStylistId?: string }>();
  const reservationStylistId = Number(params.reservationStylistId ?? 0);

  const [isFetching, callWithFetching] = useFlag(false);

  const reservationStylists = useSelector(selectActiveReservationStylists);
  const reservationStylist = reservationStylists.find(
    (r) => r.id === reservationStylistId
  );
  const dispatch = useThunkDispatch();

  const handleSave = useCallback(
    (stylistId: number, values: EditablePlainReservationStylist) => {
      if (!reservationStylist) {
        return;
      }

      // 更新された値だけ抽出する
      const updates: Partial<typeof values> = {};
      for (const key of objectKeys(values)) {
        if (!_.isEqual(values[key], reservationStylist[key])) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          updates[key] = values[key] as any;
        }
      }

      callWithFetching(async () => {
        await dispatchWithErrorHandling(
          dispatch,
          updateReservationStylist(stylistId, updates),
          {
            success: 'スタッフの予約設定を保存しました',
          }
        );
      });
    },
    [dispatch, reservationStylist, callWithFetching]
  );

  if (!reservationStylist) {
    return null;
  }

  return (
    <AddEditReservationStylist
      stylist={reservationStylist.stylist}
      defaultValues={reservationStylist}
      isFetching={isFetching}
      isEditMode
      onSave={handleSave}
    />
  );
};

const AddEditReservationStylist: FC<{
  stylist: PlainStylist;
  defaultValues: Partial<EditablePlainReservationStylist>;
  isFetching: boolean;
  isEditMode?: boolean;
  onSave(stylistId: number, values: EditablePlainReservationStylist): void;
}> = (props) => {
  const {
    defaultValues,
    stylist,
    isFetching,
    isEditMode = false,
    onSave,
  } = props;
  const classes = useStyles();

  const me = useSelector(selectMe);
  const reservationMenus = useSelector(selectActiveReservationMenus);

  const [values, setValues] = useState<EditablePlainReservationStylist>();

  useEffect(() => {
    setValues({
      name: '',
      description: '',
      imageUrl: stylist.imageUrl,
      isVisible: false,
      availableReservationMenuIds: reservationMenus.map((rm) => rm.id),
      ...defaultValues,
    });
  }, [defaultValues, stylist.imageUrl, reservationMenus]);

  const isUpdated = useMemo(
    () => !_.isEqual(values, defaultValues),
    [defaultValues, values]
  );

  const handleUpdate = useCallback(
    (changes: Partial<typeof values>) => {
      if (values) {
        setValues({ ...values, ...changes });
      }
    },
    [values]
  );

  const handleSave = useCallback(() => {
    if (values) {
      onSave(stylist.id, values);
    }
  }, [onSave, stylist.id, values]);

  // 権限がなければこのページに来ないはずだけど一応チェック
  const hasPermission = checkPermission(me, 'canUpdateReservationSetting');

  if (!values || !hasPermission) {
    return null;
  }

  return (
    <div className={classes.root}>
      {isFetching && <CProgressOverlay />}

      <CBreadCrumbs
        breadcrumbs={[
          {
            text: '予約受付：掲載スタッフ',
            linkTo: '/settings/reservation/stylist',
          },
          { text: isEditMode ? values.name ?? '' : '新規作成' },
        ]}
      />

      <ValidatorForm instantValidate={false} onSubmit={handleSave}>
        <Grid container spacing={4}>
          <Grid item>
            <Typography variant="h6" gutterBottom>
              基本設定
            </Typography>
            <Card className={classes.card}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="center" wrap="nowrap">
                    <Grid item>
                      <CStylistAvatar size={48} stylist={stylist} />
                    </Grid>
                    <Grid item>
                      <TextValidator
                        variant="standard"
                        label="表示スタッフ名"
                        value={values.name}
                        inputProps={{ maxLength: 64 }}
                        onChange={(e) => handleUpdate({ name: e.target.value })}
                        validators={['required']}
                        errorMessages={['スタッフ名は必須です']}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TextValidator
                    variant="outlined"
                    label="紹介文"
                    fullWidth
                    multiline
                    value={values.description}
                    inputProps={{ maxLength: 255 }}
                    onChange={(e) =>
                      handleUpdate({ description: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <CRadioGroup
                    label="掲載状況"
                    currentValue={values.isVisible ? 1 : 0}
                    onChange={(v) => handleUpdate({ isVisible: v === 1 })}
                    options={[
                      { value: 1, label: '掲載する（予約を受け付ける）' },
                      { value: 0, label: '掲載しない（予約を受け付けない）' },
                    ]}
                  />
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <ReservationStylistAvailableMenus
              availableReservationMenuIds={
                values.availableReservationMenuIds ?? []
              }
              handleUpdate={(availableReservationMenuIds: number[]) =>
                handleUpdate({ availableReservationMenuIds })
              }
            />
          </Grid>

          <Grid container item justifyContent="flex-end" spacing={1}>
            <Grid item>
              <CButton
                variant="outlined"
                linkTo="/settings/reservation/stylist"
              >
                キャンセル
              </CButton>
            </Grid>

            <Grid item>
              <CButton type="submit" disabled={!isUpdated}>
                保存
              </CButton>
            </Grid>
          </Grid>
        </Grid>
      </ValidatorForm>
    </div>
  );
};

const ReservationStylistAvailableMenus: FC<{
  availableReservationMenuIds: number[];
  handleUpdate(availableReservationMenuIds: number[]): void;
}> = (props) => {
  const { availableReservationMenuIds, handleUpdate } = props;

  const classes = useStyles();
  const reservationMenus = useSelector(selectActiveReservationMenus);

  const [filterText, setFilterText] = useState<string>('');

  const filteredMenus = useMemo(() => {
    const filterNames = filterText
      .split(/\s/)
      .filter((name) => name !== '')
      .map((name) => formatTextForSearch(name));

    return reservationMenus.filter((menu) => {
      if (!filterNames.length) {
        return true;
      }
      if (availableReservationMenuIds.includes(menu.id)) {
        return true;
      }

      const menuName = formatTextForSearch(menu.name);
      return filterNames.every((name) => menuName.includes(name));
    });
  }, [filterText, reservationMenus, availableReservationMenuIds]);

  const checkedMenuNum = availableReservationMenuIds.length;

  // 検索結果が全て選択されているか
  const isChecked =
    filteredMenus.length > 0 && filteredMenus.length === checkedMenuNum;
  const isIndeterminate = !isChecked && checkedMenuNum > 0;

  const onClick = useCallback(() => {
    if (!filteredMenus.length) {
      return;
    }

    handleUpdate(
      isChecked || isIndeterminate ? [] : filteredMenus.map((rm) => rm.id)
    );
  }, [isChecked, isIndeterminate, filteredMenus, handleUpdate]);

  const toggle = useCallback(
    (id: number) => {
      const filteredIds = availableReservationMenuIds.filter(
        (menuId) => menuId !== id
      );

      if (filteredIds.length !== availableReservationMenuIds.length) {
        handleUpdate(filteredIds);
      } else {
        handleUpdate(filteredIds.concat(id));
      }
    },
    [availableReservationMenuIds, handleUpdate]
  );

  return (
    <>
      <Typography variant="h6" gutterBottom>
        対応可能メニュー
      </Typography>

      {/* Dividerを横幅いっぱいにするためにcardにはpadding当てない */}
      <Card>
        <Grid item xs={12} className={classes.searchTextInput}>
          <CTextInput
            fullWidth
            label="掲載メニューを検索"
            placeholder=" "
            value={filterText}
            onChange={setFilterText}
          />
        </Grid>

        <Grid
          container
          onClick={onClick}
          alignItems="center"
          spacing={1}
          className={classes.topCheckboxContainer}
        >
          <Grid item>
            <Checkbox
              checked={isChecked}
              indeterminate={isIndeterminate}
              indeterminateIcon={
                <IndeterminateCheckBoxIcon
                  className={classes.indeterminateIcon}
                />
              }
              onClick={onClick}
            />
          </Grid>
          <Grid item xs>
            <Typography color="textSecondary">
              {availableReservationMenuIds.length
                ? `${checkedMenuNum}件を選択中`
                : `${filteredMenus.length}件をすべて選択`}
            </Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.availableMenuList}>
          {filteredMenus.map((reservationMenu, i) => (
            <React.Fragment key={reservationMenu.id}>
              <Grid
                container
                alignItems="center"
                onClick={() => toggle(reservationMenu.id)}
                spacing={1}
                className={classes.reservationMenuItem}
              >
                <Grid item style={{ marginTop: 8, marginBottom: 8 }}>
                  <Checkbox
                    checked={availableReservationMenuIds.includes(
                      reservationMenu.id
                    )}
                    onClick={() => toggle(reservationMenu.id)}
                  />
                </Grid>

                <Grid
                  item
                  container
                  xs
                  justifyContent="space-between"
                  spacing={2}
                  alignItems="center"
                >
                  <Grid item xs style={{ marginTop: 8, marginBottom: 8 }}>
                    <Typography variant="body1">
                      {reservationMenu.name}
                    </Typography>
                  </Grid>

                  <Grid item className={classes.reservationMenuDetail}>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      align="right"
                    >
                      {formatMoney(reservationMenu.service.price)}
                      {reservationMenu.showPriceFromSign && '〜'}
                    </Typography>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      align="right"
                    >
                      {reservationMenu.service.time}分
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {i < reservationMenus.length - 1 && (
                <CDivider spacing={0} style={{ width: '100%' }} />
              )}
            </React.Fragment>
          ))}
        </Grid>
      </Card>
    </>
  );
};
