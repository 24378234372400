import { CustomerLoyaltySegmentText, getAge } from '@karutekun/core/customer';
import { moment } from '@karutekun/shared/util/datetime';
import { Theme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React from 'react';
import {
  CustomerSortKey,
  CustomerSortOrder,
} from '../../actions/customerAction';
import { Customer, CustomerStatistics } from '../../models/customer';
import { CustomerListData } from '../../reducers/types';
import { formatDate, formatMoney } from '../../util/common';
import Routing from '../../util/routing';
import CSortTable from '../molecules/CSortTable';

const columnNames = {
  name: { label: '名前', sorting: true, align: 'left' },
  age: { label: '年齢', sorting: false },
  loyaltySegment: { label: '顧客セグメント' },
  totalSales: { label: '総支払額', sorting: true, align: 'right' },
  averageBudget: { label: '単価', sorting: true, align: 'right' },
  totalVisitNum: { label: '来店回数', sorting: true },
  firstVisitedAt: { label: '初回来店', sorting: true },
  lastVisitedAt: { label: '最終来店', sorting: true },
  averageVisitPeriod: { label: '来店周期', sorting: true },
  nextVisit: { label: '来店予想', sorting: true },
  createdAt: { label: '作成日', sorting: true },
  lastStylist: { label: '前回担当', align: 'left' },
  latestSalon: { label: '前回サロン', align: 'left' },
};
type ColumnName = keyof typeof columnNames;
const columns: ColumnName[] = [
  'name',
  'age',
  'loyaltySegment',
  'totalSales',
  'averageBudget',
  'totalVisitNum',
  'lastVisitedAt',
  'averageVisitPeriod',
  'nextVisit',
  'createdAt',
  'lastStylist',
  'latestSalon',
];

type OwnProps = {
  customers: Customer[];
  customerListData: Pick<
    CustomerListData,
    'isFetching' | 'sortKey' | 'sortOrder' | 'count' | 'page'
  >;

  // サロン単体の情報か、グループの情報を表示するか
  useGroupData?: boolean;

  // グループ化されているサロンかどうか
  hasGroupSalon?: boolean;

  rowsPerPage: number;

  // アクション
  onSortOrderChange(
    sortKey: CustomerSortKey,
    sortOrder: CustomerSortOrder
  ): void;
  onPageChange(page: number): void;

  // onClick を渡した場合は、それがクリックのハンドラとして設定される
  // 渡さない場合は、お客様詳細ページへのリンクが自動的に設定される
  onClick?(customerId: number): void;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
  },
  headerCellForGroup: {
    // TODO 一時的にルールを無効化しています。気づいたベースで直してください
    // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type '300' can't be used to index type 'PaletteColor'.
    backgroundColor: theme.palette.primary[300],
  },
  headerCellForSalon: {
    // TODO 一時的にルールを無効化しています。気づいたベースで直してください
    // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type '300' can't be used to index type 'PaletteColor'.
    backgroundColor: theme.palette.secondary[300],
  },
}));

const CCustomerTable: FC<OwnProps> = React.memo(
  function CCustomerTable(props) {
    const classes = useStyles();
    const {
      useGroupData,
      hasGroupSalon,
      customers,
      customerListData: { sortKey, sortOrder, page, count },
      rowsPerPage,
      onSortOrderChange,
      onPageChange,
      onClick,
    } = props;

    return (
      <div className={classes.container}>
        {props.customerListData.isFetching && (
          <div
            style={{
              position: 'absolute',
              zIndex: 1000,
              width: '100%',
              height: '100%',
              opacity: 0.5,
              backgroundColor: 'white',
              textAlign: 'center',
            }}
          >
            <CircularProgress style={{ margin: 30 }} />
          </div>
        )}

        <CSortTable
          rows={customers.map((c) => {
            const age = getAge(c);
            const ageString = age ? `${age}歳` : '-';
            const birthdayMonthString = c.birthdayMonth
              ? `(${c.birthdayMonth}月)`
              : '(-)';

            const stats: CustomerStatistics = useGroupData
              ? c
              : c.mySalonStatistics;

            const clickHandler = onClick
              ? { onClick: () => onClick(c.id) }
              : { linkTo: Routing.customer(c.id) };

            return {
              id: c.id,
              name: `${c.name} 様`,
              age: `${ageString} ${birthdayMonthString}`,
              loyaltySegment: CustomerLoyaltySegmentText[stats.loyaltySegment],
              totalSales: formatMoney(stats.totalSales),
              averageBudget: formatMoney(stats.averageBudget),
              totalVisitNum: `${stats.totalVisitNum} 回`,
              firstVisitedAt:
                stats.firstVisitedAt !== 0
                  ? formatDate(stats.firstVisitedAt)
                  : '-',
              lastVisitedAt:
                stats.lastVisitedAt !== 0
                  ? formatDate(stats.lastVisitedAt)
                  : '-',
              averageVisitPeriod:
                stats.averageVisitPeriod > 0
                  ? `${stats.averageVisitPeriod} 日`
                  : '-',
              nextVisit:
                stats.totalVisitNum === 1 || stats.lastVisitedAt === 0
                  ? '-'
                  : formatDate(
                      moment
                        .unix(stats.lastVisitedAt)
                        .add(stats.averageVisitPeriod, 'days')
                        .unix()
                    ),
              createdAt: c.createdAt > 0 ? formatDate(c.createdAt) : '-',
              lastStylist: stats.lastStylist ? stats.lastStylist.name : '-',
              latestSalon: c.latestSalon ? c.latestSalon.name : '-',
              ...clickHandler,
            };
          })}
          columns={(hasGroupSalon
            ? columns
            : columns.filter((c) => !['latestSalon'].includes(c))
          ).reduce((prev, current) => {
            // TODO 一時的にルールを無効化しています。気づいたベースで直してください
            // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type '"createdAt" | "name" | "firstVisitedAt" | "lastVisitedAt" | "totalVisitNum" | "totalSales" | "averageBudget" | "averageVisitPeriod" | "loyaltySegment" | "nextVisit" | "age" | "lastStylist" | "latestSalon"' can't be used to index type '{}'.
            prev[current] = columnNames[current];
            return prev;
          }, {})}
          pagination={{ rowsPerPage, onPageChange, page, count }}
          sortKey={sortKey}
          sortOrder={sortOrder}
          onOrderChange={onSortOrderChange}
          headerCellClassName={
            useGroupData
              ? classes.headerCellForGroup
              : classes.headerCellForSalon
          }
        />
      </div>
    );
  },
  (prev, next) => _.isEqual(prev, next)
);

export default CCustomerTable;
