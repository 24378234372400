import { PlainReservationMenu } from './reservationMenu';
import { PlainStylist } from './stylist';

/**
 * ReservationStylists
 */
export type PlainReservationStylist = {
  id: number;
  stylistId: number;
  name: string;
  imageUrl: string | null;
  description: string;
  order: number;
  isVisible: boolean;
  isActive: boolean;
  availableReservationMenuIds?: number[];
};
export type ReservationStylist = PlainReservationStylist & {
  stylist: PlainStylist;
  availableReservationMenus: PlainReservationMenu[];
};
export type EditablePlainReservationStylist = Pick<
  PlainReservationStylist,
  | 'name'
  | 'description'
  | 'imageUrl'
  | 'isVisible'
  | 'availableReservationMenuIds'
>;

export function reservationStylistFromResource(
  // TODO 一時的に lint を無効化しています。気づいたベースで直してください
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  res: any
): PlainReservationStylist {
  return {
    id: res.id,
    stylistId: res.stylistId,
    name: res.name,
    imageUrl: res.imageUrl,
    description: res.description,
    order: res.order,
    isVisible: res.isVisible,
    isActive: res.isActive,
    availableReservationMenuIds: res.availableReservationMenuIds,
  };
}
