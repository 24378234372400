import { CustomerLoyaltySegment, CustomerSex } from '@karutekun/core/customer';
import { dayOfWeek } from '@karutekun/shared/util/datetime';
import {
  IFirstCustomerDailyStats,
  IFirstCustomerStats,
  ILostCustomerDailyStats,
  ILostCustomerStats,
  IRepeatCustomerDailyStatsSnapshot,
} from './data';

function buildCustomerStatsCommon(salonId: number) {
  return {
    salonId,
    menuSales: 0,
    menuSalesWithoutTax: 0,
    productSales: 0,
    productSalesWithoutTax: 0,
    paidVoucherNum: 0,
    averageSalesPerCustomer: 0,
    averageSalesPerCustomerWithoutTax: 0,
    customerNum: 0,
    isShimeiMap: { 0: 0, 1: 0 },
    customerSexAgeGroupMap: {
      [CustomerSex.Male]: { unknown: 0 },
      [CustomerSex.Female]: { unknown: 0 },
    },
    visitMotivationMap: {},
    serviceNumMap: {},
    salesRangeMap: {},
  };
}

export function buildFirstCustomerStats(salonId: number): IFirstCustomerStats {
  return {
    ...buildCustomerStatsCommon(salonId),
    assignedStylistMap: {},
  };
}
export function buildFirstCustomerDailyStats(
  salonId: number,
  date: string
): IFirstCustomerDailyStats {
  return {
    date,
    dayOfWeek: dayOfWeek(date),
    ...buildFirstCustomerStats(salonId),
  };
}

export function buildLostCustomerStats(
  salonId: number,
  loyaltySegment:
    | CustomerLoyaltySegment.NewLost
    | CustomerLoyaltySegment.RepeatLost
): ILostCustomerStats {
  return {
    ...buildCustomerStatsCommon(salonId),
    loyaltySegment,
    assignedStylistMap: {},
  };
}
export function buildLostCustomerDailyStats(
  salonId: number,
  loyaltySegment:
    | CustomerLoyaltySegment.NewLost
    | CustomerLoyaltySegment.RepeatLost,
  date: string
): ILostCustomerDailyStats {
  return {
    date,
    dayOfWeek: dayOfWeek(date),
    ...buildLostCustomerStats(salonId, loyaltySegment),
  };
}

export function buildRepeatCustomerDailyStatsSnapshot(
  salonId: number,
  date: string
): IRepeatCustomerDailyStatsSnapshot {
  return {
    ...buildCustomerStatsCommon(salonId),
    date,
    dayOfWeek: dayOfWeek(date),
    averageVisitPeriodMap: {},
    visitNumMap: {},
    lastStylistShimeiMap: {},
  };
}
