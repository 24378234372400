import { InputLabel, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CDatePicker from '../atoms/CDatePicker';

type OwnProps = {
  label?: string;
  from?: string;
  to?: string;
  disabled?: boolean;
  clearable?: boolean;
  format?: string;
  fullWidth?: boolean;

  onChange(from: string | undefined, to: string | undefined): void;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: -4,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  separator: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: theme.typography.caption.fontSize,
  },
}));

const CDateRange: FC<OwnProps> = (props) => {
  const classes = useStyles();

  const { label, from, to, disabled, clearable, format, fullWidth, onChange } =
    props;

  return (
    <>
      <InputLabel shrink>{label}</InputLabel>
      <div className={classes.container}>
        <CDatePicker
          date={from}
          format={format}
          disabled={disabled}
          clearable={!disabled && clearable}
          onChange={(date) => onChange(date, to)}
          fullWidth={fullWidth}
        />
        <Typography className={classes.separator}>〜</Typography>
        <CDatePicker
          date={to}
          format={format}
          disabled={disabled}
          clearable={!disabled && clearable}
          onChange={(date) => onChange(from, date)}
          fullWidth={fullWidth}
        />
      </div>
    </>
  );
};

export default CDateRange;
