import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';

type OwnProps = {
  ml?: number;
  mr?: number;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: 1,
    padding: 1,
    borderRadius: 2,
    border: `1px solid ${theme.palette.primary.main}`,
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.primary.main,
    backgroundColor: '#fff',
  },
}));

const CShimeiIcon: FC<OwnProps> = React.memo(function CShimeiIcon(props) {
  const classes = useStyles();
  const { ml, mr, ...etc } = props;
  return (
    <span
      style={{ marginLeft: ml, marginRight: mr }}
      className={clsx(classes.container)}
      {...etc}
    >
      指
    </span>
  );
});

export default CShimeiIcon;
