import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import * as React from 'react';

type OwnProps = React.HTMLAttributes<HTMLDivElement>;

const useStyles = makeStyles(() => ({
  container: {
    overflow: 'auto',
    background:
      'linear-gradient(to left, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.2)) 0 0/6px 100%, linear-gradient(to right, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.2)) right/6px 100%',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'scroll',
  },
  shadowCanceller: {
    position: 'relative',
    minWidth: 'fit-content',
    background:
      'linear-gradient(to left, hsla(0,0%,100%,0), white 6px) 0 0 / 10px 100%, linear-gradient(to right, hsla(0,0%,100%,0), white 6px) right / 10px 100%',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'local',
  },
}));

const CHorizontalScrollHintContainer: FC<OwnProps> = (props) => {
  const classes = useStyles();
  const { className, children, ...etc } = props;
  return (
    <div className={clsx(className, classes.container)} {...etc}>
      <div className={classes.shadowCanceller}>{children}</div>
    </div>
  );
};

export default CHorizontalScrollHintContainer;
