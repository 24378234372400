import { useBoolean } from '@karutekun/shared/util/react-hooks';
import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import { Box, ButtonBase, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchOwnSalonStylists } from '../../../actions/salonStylistAction';
import { Images } from '../../../assets/images';
import CAvatar from '../../../components_old/atoms/CAvatar';
import { BasicPlanNameMap } from '../../../models/salon';
import { selectMySalon } from '../../../selectors/salonSelector';
import { useThunkDispatch } from '../../../util/hooks/useThunkDispatch';
import { SwitchLoginSalonDialog } from './SwitchLoginSalonDialog';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    'padding': theme.spacing(2),
    'textAlign': 'left',
    '&:focus, &:hover': {
      // TODO 一時的にルールを無効化しています。気づいたベースで直してください
      // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type '50' can't be used to index type 'PaletteColor'.
      backgroundColor: theme.palette.primary[50],
    },
  },
  marginLeft: {
    marginLeft: theme.spacing(2),
  },
  salonName: {
    fontWeight: 500,
    fontSize: theme.typography.h6.fontSize,
    lineHeight: 1.2,
  },
}));

export const LoginSalon: FC = React.memo(() => {
  const classes = useStyles();
  const dispatch = useThunkDispatch();

  const salon = useSelector(selectMySalon);

  const [isDialogOpen, isDialogOpenMutation] = useBoolean(false);
  const [loginSalonId, setLoginSalonId] = useState(0);
  const [salonStylists, setSalonStylists] = useState([]);

  useEffect(() => {
    dispatch(fetchOwnSalonStylists()).then((data) => {
      setSalonStylists(data.salonStylists);
      setLoginSalonId(data.loginSalonId);
    });
  }, [dispatch]);

  const hasMoreSalons = salonStylists.length > 1;

  return (
    <>
      <ButtonBase
        className={classes.button}
        onClick={isDialogOpenMutation.setTrue}
        disabled={!hasMoreSalons}
      >
        <Grid container wrap="nowrap" alignItems="center">
          <Grid item>
            <CAvatar
              size={32}
              src={salon.imageUrl ?? Images.common.salonNoImage}
            />
          </Grid>
          <Grid item xs className={classes.marginLeft}>
            <Box whiteSpace="normal">
              <Typography className={classes.salonName}>
                {salon.name}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {BasicPlanNameMap[salon.basicPlan]}
              </Typography>
            </Box>
          </Grid>
          {hasMoreSalons && (
            <Grid item className={classes.marginLeft}>
              <SVGIcon name="angle-right" />
            </Grid>
          )}
        </Grid>
      </ButtonBase>
      {hasMoreSalons && (
        <SwitchLoginSalonDialog
          open={isDialogOpen}
          currentSalonId={loginSalonId}
          salonStylists={salonStylists}
          onClose={isDialogOpenMutation.setFalse}
        />
      )}
    </>
  );
});
