import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Route, Routes } from 'react-router';
import CGuideLineFeature from '../../components_old/organisms/CGuideLineFeature';
import { useSalonStatus } from '../../templates/providers/salonStatus/salonStatusContext';
import { BroadcastMessages } from './broadcast_message';
import { ViewBroadcastMessage } from './broadcast_message/[id]';
import { CreateBroadcastMessage } from './broadcast_message/new';

const useStyles = makeStyles((_: Theme) => ({
  root: {
    padding: '76px 20px 20px 20px',
  },
}));

export const Chat: FC = () => {
  const classes = useStyles();

  const { salonStatus } = useSalonStatus();
  if (!salonStatus.hasSetting('line')) {
    return (
      <div className={classes.root}>
        <Box mt={4}>
          <CGuideLineFeature />
        </Box>
      </div>
    );
  }

  return (
    <Routes>
      <Route path="/broadcast_messages" element={<BroadcastMessages />} />
      <Route
        path="/broadcast_messages/new"
        element={<CreateBroadcastMessage />}
      />
      <Route
        path="/broadcast_messages/:id"
        element={<ViewBroadcastMessage />}
      />
    </Routes>
  );
};
