import { dayjs } from './dayjs';

export function dayOfWeek(dateOrUnix: string | number) {
  return typeof dateOrUnix === 'string'
    ? dayjs(dateOrUnix).isoWeekday()
    : dayjs.unix(dateOrUnix).isoWeekday();
}

/**
 * unixtime を YYYY-MM-DD に変換する
 */
export function unixToDate(unix: number) {
  return dayjs.unix(unix).format('YYYY-MM-DD');
}

/**
 * unixtime を YYYY-MM-DD HH:mm:ss に変換する
 */
export function unixToDateTime(unix: number) {
  return dayjs.unix(unix).format('YYYY-MM-DD HH:mm:ss');
}

/**
 * unixtime を HH:mm に変換する
 * @param appendSeconds 秒を表示するかどうか
 */
export function unixToTime(unix: number, appendSeconds?: boolean) {
  return dayjs.unix(unix).format(`HH:mm${appendSeconds ? ':ss' : ''}`);
}

/**
 * ユーザーに表示するための日付フォーマット
 * 例:
 * - 2023年5月14日
 */
export function formatDateForUser(unixOrDate: number | string) {
  return typeof unixOrDate === 'number'
    ? dayjs.unix(unixOrDate).format('YYYY年M月D日')
    : dayjs(unixOrDate).format('YYYY年M月D日');
}

/**
 * ユーザーに表示するための日時フォーマット
 * 例:
 * - 2023年5月14日 15:32
 * @param showDayOfWeek 曜日（日）を表示するかどうか
 */
export function formatDateTimeForUser(unix: number, showDayOfWeek?: boolean) {
  return dayjs
    .unix(unix)
    .format(`YYYY年M月D日${showDayOfWeek ? '（ddd）' : ''} H:mm`);
}

/**
 * ユーザーに表示するための短縮日付フォーマット
 * 例:
 * - 3/1
 * - 3/14(土)
 * - 2022/12/24(土)
 */
export function formatDateShortForUser(
  unixOrDate: number | string,
  options: {
    // 昨年以前の日付の場合は年を表示する
    showPastYear?: boolean;
    // 曜日を表示する
    showDayOfWeek?: boolean;
  } = {}
) {
  const { showPastYear = false, showDayOfWeek = false } = options;

  const dayjsObj =
    typeof unixOrDate === 'number' ? dayjs.unix(unixOrDate) : dayjs(unixOrDate);

  const showYear = showPastYear && dayjsObj.isBefore(dayjs(), 'year');
  const yearFmt = showYear ? 'YYYY/' : '';
  const dayOfWeekFmt = showDayOfWeek ? '(ddd)' : '';

  return dayjsObj.format(`${yearFmt}M/D${dayOfWeekFmt}`);
}

/**
 * ユーザーに表示するための時刻フォーマット
 * 例:
 * - 9:04
 * - 15:32
 */
export function formatTimeForUser(unix: number) {
  return dayjs.unix(unix).format('H:mm');
}

/**
 * ユーザーに表示するための時刻範囲フォーマット
 * 例:
 * - 9:04 - 15:32
 */
export function formatTimeRangeForUser(from: number, to: number) {
  return `${formatTimeForUser(from)} - ${formatTimeForUser(to)}`;
}
