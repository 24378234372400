import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M24.48 5.31c.26.64-.05 1.37-.69 1.63l.02-.01c-.64.26-1.37-.05-1.63-.69-.22-.53-.74-2.39 1.56-3.93 1.24-.74 1.56-1.23 1.56-1.23.21-.45.65-.74 1.15-.69.5.01.92.36 1.11.82.38.97 1.57 4.28.42 6.08-.44.68-1.15 1.09-2.01 1.13h-.07c-.66 0-1.21-.51-1.25-1.18-.04-.69.49-1.27 1.18-1.31.19-.23.15-1.03-.05-1.96-.22.15-.45.3-.72.46-.737.488-.621.778-.58.88zm20.493 31.13.009-.06c.009-.06.018-.12.018-.18V24.41c0-2.76-2.13-5.01-4.75-5.01H27.68v-8.06c0-.83-.48-1.61-1.23-1.98l-2.96-1.45c-.69-.33-1.48-.29-2.13.11-.65.4-1.03 1.1-1.03 1.86v9.49H7.76c-2.62 0-4.75 2.25-4.75 5.01v11.79c0 .09.01.18.03.27a2.741 2.741 0 0 0-1.78 2.57v4.4c0 1.52 1.23 2.75 2.75 2.75H44c1.52 0 2.75-1.23 2.75-2.75v-4.4a2.751 2.751 0 0 0-1.777-2.57M6.51 29.28v6.92H6.5v.09h34.99v-7.01c-.75.09-1.18.44-1.77.95-.82.72-1.84 1.61-3.8 1.61-1.96 0-2.99-.89-3.81-1.61-.68-.6-1.14-.99-2.16-.99-1.02 0-1.47.39-2.16.99-.82.72-1.84 1.61-3.8 1.61-1.96 0-2.98-.89-3.8-1.61-.69-.6-1.14-.99-2.16-.99-1.02 0-1.47.39-2.16.99-.82.72-1.84 1.61-3.8 1.61-1.96 0-2.98-.89-3.8-1.61-.58-.51-1.02-.85-1.76-.95m18.67-14.26-2.36-1.15v1.43l2.36 1.15zm0-2.23v-1.26l-2.36-1.15v1.26zm0 5.88-2.36-1.15v1.87h2.36zm15.06 4.22H7.76c-.69 0-1.25.68-1.25 1.51v2.35c1.7.11 2.65.92 3.41 1.58.69.6 1.14.99 2.16.99 1.02 0 1.47-.39 2.16-.99.82-.72 1.84-1.61 3.8-1.61 1.96 0 2.98.89 3.8 1.61.69.6 1.14.99 2.16.99 1.02 0 1.47-.39 2.16-.99.82-.72 1.84-1.61 3.8-1.61 1.96 0 2.98.89 3.8 1.61.68.6 1.14.99 2.16.99 1.02 0 1.47-.39 2.16-.99.76-.66 1.71-1.47 3.41-1.58V24.4c0-.83-.56-1.51-1.25-1.51M4.75 42.68h38.5v-2.9H4.75z"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgBirthdayCake = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgBirthdayCake);
export default Memo;
