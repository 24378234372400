import { mapBy } from '@karutekun/shared/util/objects';
import { Action, Dispatch } from 'redux';
import { deprecated } from 'typesafe-actions';
import { sendRequest } from '../actions/request';
import Logger, { AnalyticsEvent } from '../logger';
import { JoinRequest, JoinRequestStatus } from '../models/joinRequest';
import { fetchUserRequestingSalon, setUserRequestingSalon } from './userAction';

const { createAction } = deprecated;

export enum ActionNames {
  // 所属申請
  SetSalonJoinRequestMap = 'salon/SetSalonJoinRequestMap',
  SetSalonJoinRequest = 'salon/SetSalonJoinRequest',
}

export const setSalonJoinRequestMap = createAction(
  ActionNames.SetSalonJoinRequestMap,
  (action) => {
    return (joinRequestMap: IdMap<JoinRequest>) => action({ joinRequestMap });
  }
);

export const setSalonJoinRequest = createAction(
  ActionNames.SetSalonJoinRequest,
  (action) => {
    return (stylistId: number, joinRequest: Partial<JoinRequest>) =>
      action({ stylistId, joinRequest });
  }
);

/**
 * 所属申請一覧取得
 */
export function fetchSalonRequest(salonId: number) {
  return async function (dispatch: Dispatch<Action>) {
    const json: JoinRequest[] = await sendRequest(
      dispatch,
      `salons/${salonId}/requests`
    );

    dispatch(setSalonJoinRequestMap(mapBy(json, 'stylistId')));
  };
}

/**
 * 所属申請承認
 */
export function acceptSalonRequest(salonId: number, stylistId: number) {
  return async function (dispatch: Dispatch<Action>) {
    await sendRequest(
      dispatch,
      `salons/${salonId}/requests/${stylistId}/accept`,
      { method: 'POST' }
    );

    dispatch(
      setSalonJoinRequest(stylistId, {
        status: JoinRequestStatus.Accepted,
      })
    );
    Logger.logEvent(AnalyticsEvent.acceptSalonRequest);
  };
}

/**
 * 所属申請却下
 */
export function declineSalonRequest(salonId: number, stylistId: number) {
  return async function (dispatch: Dispatch<Action>) {
    await sendRequest(dispatch, 'salonRequest/decline', {
      method: 'POST',
      body: JSON.stringify({
        salonId,
        stylistId,
      }),
    });

    dispatch(
      setSalonJoinRequest(stylistId, {
        status: JoinRequestStatus.Declined,
      })
    );
  };
}

/**
 * 所属申請を取り消す
 */
export function cancelSalonRequest(salonId: number) {
  return async function (dispatch: Dispatch<Action>) {
    await sendRequest(dispatch, 'salonRequest/cancel', {
      method: 'POST',
      body: JSON.stringify({ salonId }),
    });

    // TODO 所属申請APIのレスポンスでサロン情報を返したい
    dispatch(setUserRequestingSalon(null));
  };
}

/**
 * 所属申請する
 */
export function sendSalonRequest(salonId: number) {
  return async function (dispatch: Dispatch<Action>) {
    await sendRequest(dispatch, 'salonRequest', {
      method: 'POST',
      body: JSON.stringify({ salonId }),
    });

    // TODO 所属申請APIのレスポンスでサロン情報を返したい
    // TODO 一時的に lint を無効化しています。気づいたベースで直してください
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    await dispatch(fetchUserRequestingSalon() as any);

    Logger.logEvent(AnalyticsEvent.createSalonRequest);
  };
}
