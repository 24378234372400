import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M40.55 7.56H13.6a3.89 3.89 0 0 0-3.83 3.94V13a1.75 1.75 0 0 0 3.5 0v-1.49a.4.4 0 0 1 .34-.44h26.94c.18 0 .33.2.33.44v18H25.94a1.75 1.75 0 1 0 0 3.5h14.94v1.56c0 .24-.15.44-.33.44h-11.8a1.75 1.75 0 1 0 0 3.5h11.8a3.89 3.89 0 0 0 3.83-3.94v-23a3.891 3.891 0 0 0-3.83-4.01m-26.5 21.73c3.19 0 6-2.95 6.28-6.57a6.32 6.32 0 0 0-1.65-4.84 6.2 6.2 0 0 0-4.63-2 6.19 6.19 0 0 0-6.27 6.82c.22 3.64 3.08 6.59 6.27 6.59m-2.08-9a2.95 2.95 0 0 1 4.15 0 2.901 2.901 0 0 1 .72 2.2c-.14 1.77-1.44 3.33-2.79 3.33s-2.65-1.56-2.78-3.32a2.858 2.858 0 0 1 .73-2.25zm-9.5 18.39c1.06-5.89 6.75-8.58 11.58-8.58 4.84 0 10.53 2.69 11.63 8.58a2.76 2.76 0 0 1-.57 2.26 2.582 2.582 0 0 1-2 .9H5a2.52 2.52 0 0 1-2-.91 2.69 2.69 0 0 1-.53-2.25m11.58-5.08c-3.13 0-6.75 1.49-7.89 4.74h15.78c-1.13-3.25-4.76-4.74-7.89-4.74"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgChalkboardTeacher = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgChalkboardTeacher);
export default Memo;
