import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      d="M16.5 24.248c4.05 0 7.61-3.76 7.93-8.38.17-2.38-.56-4.57-2.05-6.17-1.48-1.58-3.57-2.46-5.89-2.46s-4.39.88-5.88 2.47c-1.5 1.61-2.23 3.79-2.06 6.15.34 4.7 3.82 8.37 7.93 8.37zm-4.44-8.63c-.1-1.38.3-2.63 1.13-3.52.81-.88 1.99-1.36 3.31-1.36s2.52.48 3.32 1.34c.83.89 1.22 2.11 1.12 3.53-.2 2.78-2.23 5.12-4.44 5.12-2.21 0-4.24-2.35-4.44-5.13zM31.65 37.02c-1.74-6.98-8.59-10.27-15.15-10.27-6.56 0-13.42 3.19-15.16 10.28-.4 1.63.61 3.72 2.8 3.72h24.72c2.18 0 3.21-2.08 2.8-3.73zm-26.74.23c1.45-4.53 6.16-7 11.59-7s10.13 2.54 11.58 7zM27.5 17.56c.28 3.9 3.29 7.07 6.71 7.07s6.44-3.17 6.71-7.07c.14-2-.48-3.85-1.75-5.22-1.26-1.35-3.02-2.09-4.97-2.09-1.95 0-3.7.75-4.96 2.1-1.28 1.37-1.9 3.22-1.76 5.21zm4.32-2.82c.59-.64 1.44-.99 2.39-.99.95 0 1.82.35 2.41.98.61.65.89 1.55.82 2.58-.16 2.25-1.83 3.82-3.22 3.82-1.39 0-3.06-1.57-3.22-3.82-.07-1.01.22-1.92.83-2.57zM46.67 34.33c-1.26-5.07-6.26-8.47-12.45-8.47-1.86 0-3.49.24-4.98.73a1.752 1.752 0 1 0 1.09 3.33c1.13-.37 2.4-.55 3.88-.55 3.24 0 7.31 1.3 8.75 4.89h-8.47c-.97 0-1.75.78-1.75 1.75s.78 1.75 1.75 1.75h9.59c.82 0 1.58-.37 2.08-1.01.52-.66.7-1.56.5-2.4z"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgUserFriends = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgUserFriends);
export default Memo;
