import { mapById } from '@karutekun/shared/util/objects';
import { Dispatch } from 'redux';
import { sendRequest } from '../../actions/request';
import { setSalonScheduleSettings } from '../../actions/salonAction';
import {
  BusinessHourSetting,
  OverwriteOption,
  ScheduleBasicSetting,
  ScheduleCapacitySetting,
  ScheduleSettings,
  ShiftPattern,
  businessHourSettingFromResource,
  scheduleCapacitySettingFromResource,
  scheduleSettingsFromResource,
  shiftPatternFromResource,
} from '../../models/salonScheduleSettings';

/**
 * 予約台帳基本設定の取得
 */
export function fetchScheduleBasicSetting() {
  return async function (dispatch: Dispatch) {
    const json = await sendRequest(dispatch, `salons/settings/schedule/basic`);

    const settings: ScheduleSettings = scheduleSettingsFromResource(json);

    dispatch(setSalonScheduleSettings(settings));

    return settings;
  };
}

/**
 * 予約台帳基本設定の保存
 */
export function updateScheduleBasicSetting(
  updates: Partial<ScheduleBasicSetting>
) {
  return async function (dispatch: Dispatch) {
    const json = await sendRequest(dispatch, `salons/settings/schedule/basic`, {
      method: 'POST',
      body: JSON.stringify(updates),
    });

    const settings: ScheduleSettings = scheduleSettingsFromResource(json);

    dispatch(setSalonScheduleSettings(settings));

    return settings;
  };
}

/**
 * 営業時間設定の取得
 */
export function fetchBusinessHourSettings() {
  return async function (dispatch: Dispatch) {
    const json = await sendRequest(
      dispatch,
      `salons/settings/schedule/business_hour`
    );

    const setting: BusinessHourSetting = businessHourSettingFromResource(json);

    dispatch(setSalonScheduleSettings({ businessHourSetting: setting }));

    return setting;
  };
}

/**
 * 営業時間設定の保存
 */
export function updateBusinessHourSettings(
  setting: BusinessHourSetting,
  options: OverwriteOption
) {
  return async function (dispatch: Dispatch) {
    const json = await sendRequest(
      dispatch,
      'salons/settings/schedule/business_hour',
      {
        method: 'POST',
        body: JSON.stringify({ businessHourSetting: setting, ...options }),
      }
    );

    const updated: BusinessHourSetting = businessHourSettingFromResource(json);

    dispatch(setSalonScheduleSettings({ businessHourSetting: updated }));

    return updated;
  };
}

/**
 * 受付可能数設定の取得
 */
export function fetchScheduleCapacitySettings() {
  return async function (dispatch: Dispatch) {
    const json = await sendRequest(
      dispatch,
      `salons/settings/schedule/capacity`
    );

    const setting: ScheduleCapacitySetting =
      scheduleCapacitySettingFromResource(json);

    dispatch(setSalonScheduleSettings({ scheduleCapacitySetting: setting }));

    return setting;
  };
}

/**
 * 受付可能数設定の設定
 */
export function updateScheduleCapacitySettings(
  setting: ScheduleCapacitySetting,
  options: OverwriteOption
) {
  return async function (dispatch: Dispatch) {
    const json = await sendRequest(
      dispatch,
      'salons/settings/schedule/capacity',
      {
        method: 'POST',
        body: JSON.stringify({
          scheduleCapacitySetting: setting,
          ...options,
        }),
      }
    );

    const updated: ScheduleCapacitySetting =
      scheduleCapacitySettingFromResource(json);

    dispatch(setSalonScheduleSettings({ scheduleCapacitySetting: updated }));

    return updated;
  };
}

/**
 * 勤務パターンの取得
 */
export function fetchShiftPatterns() {
  return async function (dispatch: Dispatch) {
    const json = await sendRequest(
      dispatch,
      'salons/settings/schedule/shift_patterns'
    );

    const shiftPatterns: ShiftPattern[] = json.map(shiftPatternFromResource);
    const map = mapById(shiftPatterns);

    dispatch(
      setSalonScheduleSettings({
        shiftPatternMap: map,
      })
    );

    return map;
  };
}

/**
 * 勤務パターンの作成
 */
export function createShiftPattern(
  shiftPattern: Pick<
    ShiftPattern,
    'title' | 'titleShort' | 'startTime' | 'endTime'
  >
) {
  return async function (dispatch: Dispatch) {
    const json = await sendRequest(
      dispatch,
      'salons/settings/schedule/shift_patterns',
      {
        method: 'POST',
        body: JSON.stringify({
          title: shiftPattern.title,
          titleShort: shiftPattern.titleShort,
          startTime: shiftPattern.startTime,
          endTime: shiftPattern.endTime,
        }),
      }
    );

    const created: ShiftPattern = shiftPatternFromResource(json);

    dispatch(
      setSalonScheduleSettings({
        shiftPatternMap: {
          [created.id]: created,
        },
      })
    );
  };
}

/**
 * 勤務パターンの編集
 */
export function updateShiftPattern(
  id: number,
  updates: Pick<ShiftPattern, 'title' | 'titleShort'>
) {
  return async function (dispatch: Dispatch) {
    const json = await sendRequest(
      dispatch,
      `salons/settings/schedule/shift_patterns/${id}`,
      {
        method: 'POST',
        body: JSON.stringify(updates),
      }
    );

    const updated: ShiftPattern = shiftPatternFromResource(json);

    dispatch(
      setSalonScheduleSettings({
        shiftPatternMap: { [updated.id]: updated },
      })
    );
  };
}

/**
 * 勤務パターンの削除
 */
export function deleteShiftPattern(id: number) {
  return async function (dispatch: Dispatch) {
    const json = await sendRequest(
      dispatch,
      `salons/settings/schedule/shift_patterns/${id}`,
      { method: 'DELETE' }
    );

    const deleted: ShiftPattern = shiftPatternFromResource(json);

    dispatch(
      setSalonScheduleSettings({
        shiftPatternMap: { [deleted.id]: deleted },
      })
    );
  };
}
