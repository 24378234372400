export function pick<T extends object, K extends readonly (keyof T)[]>(
  obj: T,
  keys: K
): {
  [K2 in K[number]]: T[K2];
} {
  const ret = {} as {
    [K in keyof typeof obj]: typeof obj[K];
  };
  let key: keyof typeof obj;
  for (key of keys) {
    // プロトタイプチェーンをさかのぼって確認する
    if (key in obj) {
      ret[key] = obj[key];
    }
  }
  return ret;
}
