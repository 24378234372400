import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M12 2.75c.966 0 1.75.784 1.75 1.75v1.25h20.5V4.5a1.75 1.75 0 1 1 3.5 0v1.25H39A6.25 6.25 0 0 1 45.25 12v27A6.25 6.25 0 0 1 39 45.25H9A6.25 6.25 0 0 1 2.75 39V12A6.25 6.25 0 0 1 9 5.75h1.25V4.5c0-.966.784-1.75 1.75-1.75m0 6.5h27A2.75 2.75 0 0 1 41.75 12v1.25H6.25V12A2.75 2.75 0 0 1 9 9.25zm-5.75 7.5V39A2.75 2.75 0 0 0 9 41.75h30A2.75 2.75 0 0 0 41.75 39V16.75z"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgCalendar = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgCalendar);
export default Memo;
