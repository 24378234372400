import { createSelector } from 'reselect';
import { JoinRequest, JoinRequestStatus } from '../models/joinRequest';
import { GlobalState } from '../store';
import { notEmpty } from '../util/common';

export const selectJoinRequestMap = (state: GlobalState): IdMap<JoinRequest> =>
  state.joinRequest.joinRequestMap;

export const selectPendingJoinRequests = createSelector(
  (state: GlobalState) => selectJoinRequestMap(state),
  (joinRequestMap): JoinRequest[] =>
    Object.values(joinRequestMap)
      .filter(notEmpty)
      .filter((r) => r.status === JoinRequestStatus.Pending)
);

export const selectPendingJoinRequestNum = createSelector(
  (state: GlobalState) => selectPendingJoinRequests(state),
  (pendingJoinRequests): number => pendingJoinRequests.length
);
