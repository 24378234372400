import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      d="M38 8.25H10c-3.17 0-5.75 2.58-5.75 5.75v20c0 3.17 2.58 5.75 5.75 5.75h28c3.17 0 5.75-2.58 5.75-5.75V14c0-3.17-2.58-5.75-5.75-5.75M40.25 14v8.25h-5.5v-10.5H38c1.24 0 2.25 1.01 2.25 2.25m-18 11.75v10.5h-5.5v-10.5zm-5.5-3.5v-10.5h5.5v10.5zm9 3.5h5.5v10.5h-5.5zm0-3.5v-10.5h5.5v10.5zM10 11.75h3.25v10.5h-5.5V14c0-1.24 1.01-2.25 2.25-2.25M7.75 34v-8.25h5.5v10.5H10c-1.24 0-2.25-1.01-2.25-2.25M38 36.25h-3.25v-10.5h5.5V34c0 1.24-1.01 2.25-2.25 2.25"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgMonthly = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgMonthly);
export default Memo;
