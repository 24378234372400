import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { OptionLinePlanName } from '../../../models/salon';
import { selectSalonStatus } from '../../../selectors/salonStatusSelector';
import {
  SalonStatusContext,
  SalonStatusContextValue,
} from './salonStatusContext';

const SalonStatusProvider: FC = (props) => {
  const salonStatus = useSelector(selectSalonStatus);

  const [dialogInfo, setDialogInfo] = useState<{
    open: boolean;
    title: string;
    description: string;
  }>({ open: false, title: '', description: '' });

  const handleClose = useCallback(() => {
    setDialogInfo({ ...dialogInfo, open: false });
  }, [dialogInfo]);

  const value: SalonStatusContextValue = useMemo(() => {
    return {
      salonStatus,
      checkAndOpenDialog(checkKey = 'basic') {
        if (!salonStatus.isAvailable(checkKey)) {
          setDialogInfo({
            open: true,
            title: 'お試し期間は終了しました',
            description:
              checkKey === 'line'
                ? `こちらの機能を利用するには、${OptionLinePlanName}の契約が必要です`
                : 'こちらの機能を利用するには、基本プランの契約が必要です',
          });
          return false;
        }
        return true;
      },
    };
  }, [salonStatus]);

  return (
    <>
      <Dialog open={dialogInfo.open} onClose={handleClose}>
        <DialogTitle>{dialogInfo.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogInfo.description}</DialogContentText>
        </DialogContent>
      </Dialog>
      <SalonStatusContext.Provider value={value}>
        {props.children}
      </SalonStatusContext.Provider>
    </>
  );
};

export default SalonStatusProvider;
