import { moment } from '@karutekun/shared/util/datetime';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { formatPastDate } from '../../util/date';

type Props = {
  date: string;
};

const useStyles = makeStyles(() => ({
  container: {
    padding: 2,
    paddingLeft: 12,
    paddingRight: 12,
    color: '#fff',
    borderColor: '#999',
    backgroundColor: '#999',
    borderRadius: 16,
    alignSelf: 'center',
  },
}));

const ChatDateSeparator: FC<Props> = (props) => {
  const classes = useStyles();

  const formatDate = formatPastDate(moment(props.date), true);

  return (
    <div className={classes.container}>
      <Typography variant="body2">{formatDate}</Typography>
    </div>
  );
};

export default ChatDateSeparator;
