import { PermissionRoleEdit } from '@karutekun/core/permission-role';
import { useCallback, useState } from 'react';
import { dispatchWithErrorHandling } from '../../../../../actions/helper/dispatchWithErrorHandling';
import { updatePermissionRole } from '../../../../../actions/salon/permissionRoleAction';
import { useThunkDispatch } from '../../../../../util/hooks/useThunkDispatch';

export const useUpdatePermissionRole = () => {
  const dispatch = useThunkDispatch();
  const [isFetching, setIsFetching] = useState(false);
  return {
    isFetching,
    updatePermissionRole: useCallback(
      async (roleId: number, role: PermissionRoleEdit) => {
        setIsFetching(true);
        await dispatchWithErrorHandling(
          dispatch,
          updatePermissionRole(roleId, role),
          { success: `'${role.name}' の役割を更新しました` },
          true
        ).finally(() => setIsFetching(false));
      },
      [dispatch]
    ),
  };
};
