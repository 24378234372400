import { Theme, Typography } from '@mui/material';
import { WithStyles, withStyles } from '@mui/styles';
import Markdown from 'markdown-to-jsx';

type OwnProps = {
  text: string;
};

const styles = (theme: Theme) => ({
  listItem: {
    marginTop: theme.spacing(1),
  },
  code: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[100],
    fontSize: theme.typography.body1.fontSize,
  },
});

const options = {
  overrides: {
    h1: {
      // TODO 一時的に lint を無効化しています。気づいたベースで直してください
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      component: function h1(props: any) {
        return <Typography gutterBottom variant="h4" {...props} />;
      },
    },
    h2: {
      // TODO 一時的に lint を無効化しています。気づいたベースで直してください
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      component: function h2(props: any) {
        return <Typography gutterBottom variant="h6" {...props} />;
      },
    },
    h3: {
      // TODO 一時的に lint を無効化しています。気づいたベースで直してください
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      component: function h3(props: any) {
        return <Typography gutterBottom variant="subtitle1" {...props} />;
      },
    },
    h4: {
      // TODO 一時的に lint を無効化しています。気づいたベースで直してください
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      component: function h4(props: any) {
        return (
          <Typography gutterBottom variant="caption" paragraph {...props} />
        );
      },
    },
    p: {
      // TODO 一時的に lint を無効化しています。気づいたベースで直してください
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      component: function p(props: any) {
        return <Typography variant="body1" paragraph {...props} />;
      },
    },
    li: {
      component: withStyles(styles)(
        // TODO 一時的に lint を無効化しています。気づいたベースで直してください
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ({ classes, ...props }: any & WithStyles<typeof styles>) => (
          <li className={classes.listItem}>
            <Typography component="span" {...props} />
          </li>
        )
      ),
    },
    code: {
      component: withStyles(
        styles
        // TODO 一時的に lint を無効化しています。気づいたベースで直してください
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      )(({ classes, ...props }: any & WithStyles<typeof styles>) => (
        <div {...props} className={classes.code} />
      )),
    },
  },
};

const CMarkdown: FC<OwnProps> = (props) => {
  return <Markdown options={options}>{props.text}</Markdown>;
};

export default CMarkdown;
