import { ActionType, getType } from 'typesafe-actions';
import * as actions from '../actions/chatAction';
import { ChatInfo } from '../models/chat';
import { ChatMessage } from '../models/chatMessage/schema';
import { ChatRoom } from '../models/chatRoom/schema';

export type ChatActions = ActionType<typeof actions>;

export interface ChatState {
  chatInfoMap: { [salonId: number]: ChatInfo };

  chatMessageMap: {
    [salonId: number]: {
      [customerId: number]: {
        [documentId: string]: ChatMessage;
      };
    };
  };

  // 送信直後、送信内容を画面に出すためにローカルに保持するメッセージ
  localChatMessageMap: {
    [salonId: number]: {
      [customerId: number]: {
        [documentId: string]: ChatMessage;
      };
    };
  };

  chatRoomMap: {
    [salonId: number]: {
      [customerId: number]: ChatRoom;
    };
  };
}

const initialState: ChatState = {
  chatInfoMap: {},
  chatMessageMap: {},
  localChatMessageMap: {},
  chatRoomMap: {},
};

export function initialChatInfo() {
  return {
    totalUnreadNum: 0,
  };
}

function chatReducer(
  state: ChatState = initialState,
  action: ChatActions
): ChatState {
  switch (action.type) {
    case getType(actions.setChatInfo): {
      const { salonId, info } = action.payload;
      return {
        ...state,
        chatInfoMap: {
          ...state.chatInfoMap,
          [salonId]: info,
        },
      };
    }
    case getType(actions.setChatMessages): {
      const { salonId, customerId, chatMessages } = action.payload;

      // メッセージの情報を構築
      const oldSalonChatMap = state.chatMessageMap[salonId] || {};
      const newChatMap = {
        ...state.chatMessageMap,
        [salonId]: {
          ...oldSalonChatMap,
          [customerId]: {
            ...oldSalonChatMap[customerId],
            ...chatMessages.reduce((prev, current) => {
              // TODO 一時的にルールを無効化しています。気づいたベースで直してください
              // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
              prev[current.documentId] = current;
              return prev;
            }, {}),
          },
        },
      };

      // Localに保存されているメッセージのうち、
      // Firestoreから降ってきたドキュメントと同じものはもう必要ないので削除してしまう
      const oldSalonLocalChatMap = state.localChatMessageMap[salonId] || {};
      const newLocalCustomerChatMap = { ...oldSalonLocalChatMap[customerId] };
      for (const message of chatMessages) {
        delete newLocalCustomerChatMap[message.documentId];
      }
      const newLocalChatMap = {
        ...state.localChatMessageMap,
        [salonId]: {
          ...oldSalonLocalChatMap,
          [customerId]: newLocalCustomerChatMap,
        },
      };

      return {
        ...state,
        chatMessageMap: newChatMap,
        localChatMessageMap: newLocalChatMap,
      };
    }
    case getType(actions.setLocalChatMessages): {
      const { salonId, customerId, chatMessages } = action.payload;
      const oldSalonChatMap = state.localChatMessageMap[salonId] || {};
      return {
        ...state,
        localChatMessageMap: {
          ...state.localChatMessageMap,
          [salonId]: {
            ...oldSalonChatMap,
            [customerId]: {
              ...oldSalonChatMap[customerId],
              ...chatMessages.reduce((prev, current) => {
                // TODO 一時的にルールを無効化しています。気づいたベースで直してください
                // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
                prev[current.documentId] = current;
                return prev;
              }, {}),
            },
          },
        },
      };
    }
    case getType(actions.setLocalChatMessageStatus): {
      const { salonId, customerId, documentId, status } = action.payload;
      if (
        state.localChatMessageMap[salonId] &&
        state.localChatMessageMap[salonId][customerId] &&
        state.localChatMessageMap[salonId][customerId][documentId]
      ) {
        return {
          ...state,
          localChatMessageMap: {
            ...state.localChatMessageMap,
            [salonId]: {
              ...state.localChatMessageMap[salonId],
              [customerId]: {
                ...state.localChatMessageMap[salonId][customerId],
                [documentId]: {
                  ...state.localChatMessageMap[salonId][customerId][documentId],
                  status,
                },
              },
            },
          },
        };
      } else {
        return state;
      }
    }
    case getType(actions.setChatRooms): {
      const { salonId, chatRooms } = action.payload;
      return {
        ...state,
        chatRoomMap: {
          ...state.chatRoomMap,
          [salonId]: {
            ...state.chatRoomMap[salonId],
            ...chatRooms.reduce((prev, current) => {
              // TODO 一時的にルールを無効化しています。気づいたベースで直してください
              // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'number' can't be used to index type '{}'.
              prev[current.customerId] = current;
              return prev;
            }, {}),
          },
        },
      };
    }
    case getType(actions.markChatRoomRead): {
      const { salonId, customerId } = action.payload;
      if (state.chatRoomMap[salonId]?.[customerId]) {
        return {
          ...state,
          chatRoomMap: {
            ...state.chatRoomMap,
            [salonId]: {
              ...state.chatRoomMap[salonId],
              [customerId]: {
                ...state.chatRoomMap[salonId][customerId],
                unreadNum: 0,
              },
            },
          },
        };
      } else {
        return state;
      }
    }
    default:
      return state;
  }
}

export default chatReducer;
