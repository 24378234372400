import { VisitResource, VisitSource, VisitStatus } from '@karutekun/core/visit';
import { VoucherUtils } from '@karutekun/core/voucher';
import { SVGIcon, SVGName } from '@karutekun/shared-fe/icons/react';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import React, { CSSProperties, useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';
import CServiceCategoryIcons from '../../../../components_old/molecules/CServiceCategoryIcons';
import { NonAssignedReservationColor } from '../../../../models/view/viewSchedule';
import { selectStylistMap } from '../../../../selectors/salonSelector';
import { useSelectVoucherLineServiceCategories } from '../../../../selectors/voucherSelector';
import { DragItemData, Layout } from './context';

type Props = {
  visit: VisitResource;
  emphasize?: boolean;
  layout: Layout;
  onDragStart(visitId: number): void;
  onClick(scheduleId: number): void;
};

export const ScheduleItem: FC<Props> = React.memo<Props>(
  function ScheduleItem(props) {
    const { visit, emphasize, layout } = props;
    const stylistMap = useSelector(selectStylistMap);

    useEffect(() => {
      preview(getEmptyImage(), { captureDraggingState: true });
    });

    const voucher = visit.voucher;
    const categories = useSelectVoucherLineServiceCategories(voucher);
    const isPaid = VoucherUtils.isPaid(voucher);
    const canDrag = !isPaid;
    const [{ isDragging }, dragRef, preview] = useDrag({
      type: 'scheduleItem',
      item() {
        props.onDragStart(visit.id);
        return {
          visit,
          layout,
        } as DragItemData;
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      canDrag: canDrag,
    });

    const customer = visit.customer;
    const stylist = visit.assignedStylistId
      ? (stylistMap[visit.assignedStylistId] ?? null)
      : null;

    const colors = [
      'white',
      stylist ? stylist.color : NonAssignedReservationColor,
    ];
    const fgColor = emphasize ? colors[1] : colors[0];
    const bgColor = emphasize ? colors[0] : colors[1];

    const style: CSSProperties = {
      ...layout,
      overflow: 'hidden',
      padding: 2,
      opacity: isDragging ? 0.5 : 1,
      cursor: 'pointer',
      position: 'absolute',
      border: `1px solid ${fgColor}`,
      borderRadius: 3,
      backgroundColor: bgColor,
      transition: 'all .3s',
    };

    return (
      <div style={style} ref={dragRef} onClick={() => props.onClick(visit.id)}>
        <Grid
          container
          direction="column"
          wrap="nowrap"
          style={{ height: '100%' }}
        >
          <Grid container>
            <Typography
              variant="body2"
              noWrap={true}
              style={{ color: fgColor, fontWeight: 'bold' }}
            >
              {customer ? `${customer.name} 様` : visit.title}
            </Typography>
          </Grid>
          <Grid container wrap="nowrap">
            <CServiceCategoryIcons
              size={16}
              categories={categories}
              iconStyle={{ margin: 1 }}
            />
          </Grid>
          <ScheduleItemBottomArea
            visit={visit}
            isPaid={isPaid}
            allergy={customer?.allergy}
            isRepeat={!!customer && customer.totalVisitNum > 0}
            bgColor={bgColor}
            fgColor={fgColor}
          />
        </Grid>

        {isPaid && !emphasize && (
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              backgroundColor: '#000',
              opacity: 0.3,
              pointerEvents: 'none',
            }}
          />
        )}
      </div>
    );
  },
  (prev, next) => _.isEqual(prev, next)
);

const ScheduleItemBottomArea: FC<{
  visit: VisitResource;
  isPaid: boolean;
  allergy: Nullable<string>;
  isRepeat: boolean;
  bgColor: string;
  fgColor: string;
}> = React.memo(
  function ScheduleItemBottomArea(props) {
    const { visit, isPaid, allergy, isRepeat, bgColor, fgColor } = props;

    const newOrRepeatText = isRepeat ? '再' : '初';

    let statusIcon: SVGName | undefined;
    let statusDescription;
    if (!visit.customerId) {
      statusIcon = 'user-slash';
      statusDescription = 'まだお客様が登録されていません';
    } else if (visit.status === VisitStatus.Reserved) {
      statusIcon = 'user';
      statusDescription =
        'お客様が登録されていますが、まだ来店記録が登録されていません';
    } else if (!isPaid) {
      statusIcon = 'cut';
      statusDescription =
        '来店記録が登録されていますが、まだ会計されていません';
    } else {
      statusIcon = 'yen-sign';
      statusDescription = '会計が完了しています';
    }

    const iconStyle = {
      margin: 1,
      padding: 1,
      borderRadius: 2,
      color: bgColor,
      backgroundColor: fgColor,
    };

    return (
      <Grid
        container
        justifyContent="space-between"
        style={{ flex: 1, minHeight: 0 }}
      >
        <Grid item xs style={{ height: '100%', wordBreak: 'break-all' }}>
          <Typography variant="body2" style={{ color: fgColor }}>
            {[visit.reservationMemo, visit.noteFromCustomer, allergy]
              .filter((s) => s && s !== '')
              .join(' / ')}
          </Typography>
        </Grid>
        <Grid item style={{ display: 'flex', alignItems: 'flex-end' }}>
          {visit.source === VisitSource.Line && (
            <Tooltip title="LINE予約">
              <Box style={{ ...iconStyle, paddingLeft: 3, paddingRight: 3 }}>
                <Typography variant="caption" noWrap={true}>
                  L
                </Typography>
              </Box>
            </Tooltip>
          )}
          {visit.isShimei && (
            <Tooltip title="指名有">
              <Box style={iconStyle}>
                <Typography variant="caption" noWrap={true}>
                  指
                </Typography>
              </Box>
            </Tooltip>
          )}
          <Box style={iconStyle}>
            <Typography variant="caption" noWrap={true}>
              {newOrRepeatText}
            </Typography>
          </Box>
          <Tooltip title={statusDescription}>
            <Box style={iconStyle}>
              <SVGIcon
                name={statusIcon}
                style={{ verticalAlign: 'bottom' }}
                size="sm"
              />
            </Box>
          </Tooltip>
        </Grid>
      </Grid>
    );
  },
  (prev, next) => _.isEqual(prev, next)
);
