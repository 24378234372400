import createCachedSelector from 're-reselect';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import {
  Customer,
  PlainCustomer,
  emptyCustomer,
  emptyCustomerStatistics,
} from '../models/customer';
import { MySalon, Salon } from '../models/salon';
import { PlainUploadedPaper } from '../models/uploadedPaper';
import { selectAllSalonMap, selectMySalon } from '../selectors/salonSelector';
import { selectPlainUploadedPaperMap } from '../selectors/uploadedPaperSelector';
import { GlobalState } from '../store';

export const selectPlainCustomerMap = (
  state: GlobalState
): IdMap<PlainCustomer> => state.customer.map;

export const selectCustomerMap = createSelector(
  (state: GlobalState) => selectPlainCustomerMap(state),
  (state: GlobalState) => selectMySalon(state),
  (state: GlobalState) => selectAllSalonMap(state),
  (state: GlobalState) => selectPlainUploadedPaperMap(state),
  (
    plainCustomerMap: IdMap<PlainCustomer>,
    mySalon: MySalon,
    salonMap: IdMap<Salon>,
    plainUploadedPaperMap: IdMap<PlainUploadedPaper>
  ): IdMap<Customer> => {
    return Object.keys(plainCustomerMap).reduce((prev, id) => {
      // TODO 一時的にルールを無効化しています。気づいたベースで直してください
      // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
      prev[id] = buildCustomer(
        // TODO 一時的にルールを無効化しています。気づいたベースで直してください
        // @ts-expect-error: TS7015: Element implicitly has an 'any' type because index expression is not of type 'number'.
        plainCustomerMap[id],
        mySalon,
        salonMap,
        plainUploadedPaperMap
      );
      return prev;
    }, {});
  }
);

export const selectCustomer = createCachedSelector(
  (state: GlobalState) => selectCustomerMap(state),
  (state: GlobalState, customerId: number) => customerId,
  (customerMap: IdMap<Customer>, customerId: number): Customer | null => {
    return customerMap[customerId];
  }
)((state: GlobalState, customerId: number) => {
  return customerId;
});

export function useSelectCustomer(customerId: Nullable<number>) {
  const customerMap = useSelector(selectCustomerMap);
  return customerId ? customerMap[customerId] ?? null : null;
}

function buildCustomer(
  plainCustomer: PlainCustomer,
  mySalon: MySalon,
  salonMap: IdMap<Salon>,
  plainUploadedPaperMap: IdMap<PlainUploadedPaper>
): Customer {
  const customer = emptyCustomer(plainCustomer);
  const latestSalon = customer.latestSalonId
    ? salonMap[customer.latestSalonId] || null
    : null;

  const lastStylist = latestSalon
    ? latestSalon.stylistMap[customer.lastStylistId] || null
    : null;

  const plainStatistics = emptyCustomerStatistics(
    customer.plainSalonStatisticsMap[mySalon.id]
  );
  const mySalonStatistics = {
    ...plainStatistics,
    lastStylist: mySalon.stylistMap[plainStatistics.lastStylistId] || null,
  };

  return {
    ...customer,
    latestSalon,
    lastStylist,
    mySalonStatistics,
    uploadedPaper: customer.uploadedPaperId
      ? plainUploadedPaperMap[customer.uploadedPaperId]
      : null,
  };
}
