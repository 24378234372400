export enum PaymentMethodIcon {
  Cash = 'cash',
  Cash2 = 'cash2',
  Cash3 = 'cash3',
  Cash4 = 'cash4',
  Cash5 = 'cash5',
  Card = 'card',
  Card2 = 'card2',
  Card3 = 'card3',
  Card4 = 'card4',
  Card5 = 'card5',
  Point = 'point',
  Point2 = 'point2',
  Point3 = 'point3',
  Point4 = 'point4',
  Point5 = 'point5',
  Other = 'other',
  Other2 = 'other2',
  Other3 = 'other3',
  Other4 = 'other4',
  Other5 = 'other5',
}
export const ValidPaymentMethodIcons = Object.values(PaymentMethodIcon);

export interface IPaymentMethod {
  id: number;
  salonId: number;
  name: string;
  icon: PaymentMethodIcon;
  order: number;
  isActive: boolean;
  isVisible: boolean;
  // 領収書での計算から除外するフラグ
  isExcludedFromReceipt: boolean;
  createdAt: number;
  updatedAt: number;
}
