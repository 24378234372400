import { VisitUtils } from '@karutekun/core/visit';
import { trpc } from '@karutekun/shared/data-access/api-base';
import {
  useFetchVisit,
  useInvalidateVisits,
} from '@karutekun/shared/data-access/visit';
import { Grid, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { pushSnackbarSuccess } from '../../../../../actions/generalAction';
import {
  setViewScheduleDrawerStatus,
  setViewSchedulePreviewVisitId,
} from '../../../../../actions/view/viewScheduleAction';
import CButton from '../../../../../components_old/atoms/CButton';
import CDivider from '../../../../../components_old/atoms/CDivider';
import CInformation from '../../../../../components_old/atoms/CInformation';
import CProgress from '../../../../../components_old/atoms/CProgress';
import { selectMe } from '../../../../../selectors/salonSelector';
import { useSimpleDialog } from '../../../../../templates/providers/simpleDialog/simpleDialogContext';
import { useThunkDispatch } from '../../../../../util/hooks/useThunkDispatch';
import { SchedulesDrawerRecentVisitHistories } from '../_components/SchedulesDrawerRecentVisitHistories';
import { SchedulesDrawerPreviewEditReservation } from './SchedulesDrawerPreviewEditReservation';
import { SchedulesDrawerPreviewEditVisitHistory } from './SchedulesDrawerPreviewEditVisitHistory';

type Props = {
  selectedVisitId: number;
};

export const SchedulesDrawerPreviewEdit: FC<Props> = ({ selectedVisitId }) => {
  const dispatch = useThunkDispatch();
  const { open } = useSimpleDialog();

  const { data: visit, isLoading } = useFetchVisit(selectedVisitId);
  const invalidateVisits = useInvalidateVisits();

  const me = useSelector(selectMe);

  const { mutate: deleteVisitHistory } =
    trpc.visit.deleteVisitHistory.useMutation({
      async onSuccess() {
        await invalidateVisits();
        dispatch(setViewSchedulePreviewVisitId(null));
        dispatch(setViewScheduleDrawerStatus({ schedulePreview: false }));
        dispatch(pushSnackbarSuccess('来店記録を削除しました'));
      },
    });

  const { mutate: deleteReservation } =
    trpc.visit.deleteReservation.useMutation({
      async onSuccess() {
        await invalidateVisits();
        dispatch(setViewSchedulePreviewVisitId(null));
        dispatch(setViewScheduleDrawerStatus({ schedulePreview: false }));
        dispatch(pushSnackbarSuccess('予約を削除しました'));
      },
    });

  const { mutate: cancelCheckIn, isPending: cancelCheckInPending } =
    trpc.visit.cancelCheckIn.useMutation({
      async onSuccess() {
        await invalidateVisits();
        dispatch(pushSnackbarSuccess('来店受付を取り消しました'));
      },
    });

  const handleDelete = useCallback(() => {
    if (!visit) {
      return;
    }
    open({
      title: '確認',
      content: '本当に削除しますか？',
      onOk: async () => {
        if (VisitUtils.isReservation(visit)) {
          deleteReservation({ id: visit.id });
        } else if (VisitUtils.isVisitHistory(visit)) {
          deleteVisitHistory({ id: visit.id });
        }
      },
    });
  }, [deleteReservation, deleteVisitHistory, open, visit]);

  if (isLoading) {
    return <CProgress />;
  }
  if (!visit) {
    return <Typography>来店情報が見つかりません</Typography>;
  }

  const hasDeletePermission = VisitUtils.checkDeletePermission(
    visit,
    me.permissionRole
  );

  return (
    <div>
      {VisitUtils.isReservation(visit) ? (
        <SchedulesDrawerPreviewEditReservation reservation={visit} />
      ) : VisitUtils.isVisitHistory(visit) ? (
        <SchedulesDrawerPreviewEditVisitHistory visitHistory={visit} />
      ) : null}

      <CDivider spacing={2} />

      {VisitUtils.isVisitHistory(visit) && (
        <>
          <Grid container justifyContent="center">
            <CButton
              variant="text"
              color="error"
              isLoading={cancelCheckInPending}
              disabled={!VisitUtils.canCancelCheckIn(visit)}
              onClick={() => cancelCheckIn(visit.id)}
            >
              来店受付を取り消す
            </CButton>
          </Grid>
          <CDivider spacing={2} />
        </>
      )}

      <Grid container justifyContent="center">
        <CButton
          color="error"
          variant="text"
          onClick={handleDelete}
          disabled={!hasDeletePermission}
        >
          {VisitUtils.isReservation(visit) ? '予約' : '来店記録'}を削除する
        </CButton>
        {!hasDeletePermission && (
          <CInformation type="tooltip" content="削除権限がありません" />
        )}
      </Grid>
      {visit.customerId && (
        <>
          <CDivider spacing={2} />
          <SchedulesDrawerRecentVisitHistories customerId={visit.customerId} />
        </>
      )}
    </div>
  );
};
