import { ChatMessageBody } from '../../models/chatMessageBody/entity';
import { ChatMessageBodyText as ChatMessageBodyTextSchema } from './schema';

export class ChatMessageBodyText implements ChatMessageBody {
  text: string;

  constructor(args: ChatMessageBodyTextSchema) {
    this.text = args.text;
  }
}
