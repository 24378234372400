import { moment } from '@karutekun/shared/util/datetime';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React, { useContext } from 'react';
import { ScheduleContext, ScheduleZIndex } from './context';

type Props = {
  isClosed?: boolean;
  from?: string | null;
  to?: string | null;
  yIndexStart?: number;
  yIndexEnd?: number;
};

const useStyles = makeStyles((_: Theme) => ({
  background: {
    position: 'absolute',
    backgroundColor: '#ddd',
    zIndex: ScheduleZIndex.Background,
    bottom: 0,
  },
}));

export const ScheduleUnavailableBackground: FC<Props> = React.memo(
  function ScheduleUnavailableBackground(props) {
    const classes = useStyles();

    const {
      sizes: { headerWidth },
      getWidth,
      getOffsetXFromSeconds,
      getWidthFromSeconds,
      getOffsetY,
    } = useContext(ScheduleContext);

    const {
      isClosed = false,
      from = null,
      to = null,
      yIndexStart = 0,
      yIndexEnd,
    } = props;

    const top = getOffsetY(yIndexStart);
    const vStyle =
      yIndexEnd !== undefined
        ? {
            top,
            height: getOffsetY(yIndexEnd) - top,
          }
        : { top, bottom: 0 };

    if (isClosed) {
      return (
        <div
          className={classes.background}
          style={{ ...vStyle, left: headerWidth, width: getWidth(24) }}
        />
      );
    }

    if (from === null && to === null) {
      return null;
    }

    let openElement;
    let closeElement;

    if (from) {
      const open = moment(from || '00:00:00', 'HH:mm:ss');
      const openSeconds =
        open.hour() * 3600 + open.minute() * 60 + open.second();
      openElement = (
        <div
          className={classes.background}
          style={{
            ...vStyle,
            left: headerWidth,
            width: getWidthFromSeconds(openSeconds),
          }}
        />
      );
    }
    if (to) {
      const close = moment(to || '23:59:59', 'HH:mm:ss');
      const closeSeconds =
        close.hour() * 3600 + close.minute() * 60 + close.second();
      closeElement = (
        <div
          className={classes.background}
          style={{
            ...vStyle,
            left: getOffsetXFromSeconds(closeSeconds),
            right: 0,
          }}
        />
      );
    }

    return (
      <>
        {openElement}
        {closeElement}
      </>
    );
  },
  (prev, next) => _.isEqual(prev, next)
);
