import { moment } from '@karutekun/shared/util/datetime';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import CDatePicker from '../../../../../components_old/atoms/CDatePicker';
import CRadioGroup from '../../../../../components_old/atoms/CRadioGroup';
import { OverwriteOption } from '../../../../../models/salonScheduleSettings';

type Props = {
  open: boolean;
  description: string;
  onOk(overwriteOption: OverwriteOption): void;
  onCancel(): void;
};

export const OverwriteConfirmationDialog: FC<Props> = React.memo(
  function ConfirmationDialog(props) {
    const { open, description, onOk, onCancel } = props;

    const [overwrite, setOverwrite] = useState<boolean | undefined>(undefined);
    const [overwriteFrom, setOverwriteFrom] = useState(
      moment().format('YYYY-MM-DD')
    );
    const [overwriteManuallyModified, setOverwriteManuallyModified] =
      useState(false);

    useEffect(() => {
      if (open) {
        setOverwrite(undefined);
        setOverwriteFrom(moment().format('YYYY-MM-DD'));
        setOverwriteManuallyModified(false);
      }
    }, [open]);

    const handleOk = useCallback(() => {
      if (overwrite !== undefined) {
        onOk(
          overwrite
            ? {
                overwrite: true,
                overwriteFrom,
                overwriteManuallyModified,
              }
            : { overwrite: false }
        );
      }
    }, [onOk, overwrite, overwriteFrom, overwriteManuallyModified]);

    const handleUpdateFrom = useCallback((date: string) => {
      if (
        date &&
        moment(date).endOf('day').isAfter(moment().format('YYYY-MM-DD'))
      ) {
        setOverwriteFrom(date);
      }
    }, []);

    return (
      <Dialog open={open} onClose={onCancel}>
        <DialogTitle>確認</DialogTitle>
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
          <CRadioGroup
            currentValue={
              overwrite === undefined ? undefined : overwrite ? 1 : 0
            }
            options={[
              { value: 0, label: '更新しない' },
              { value: 1, label: '日付を指定して更新する' },
            ]}
            onChange={(v) => setOverwrite(v === 1)}
          />
          {overwrite && (
            <Box ml={2} mt={1}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                      <CDatePicker
                        date={overwriteFrom}
                        onChange={handleUpdateFrom}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">から更新する</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={overwriteManuallyModified}
                        onChange={(_, checked) =>
                          setOverwriteManuallyModified(checked)
                        }
                      />
                    }
                    label="手動で個別設定済みの日程も更新する"
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            キャンセル
          </Button>
          <Button
            disabled={overwrite === undefined}
            onClick={handleOk}
            color="primary"
            autoFocus
          >
            {!overwrite ? '設定を保存' : '設定を保存して反映'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);
