import { VisitResource } from '@karutekun/core/visit';
import { moment } from '@karutekun/shared/util/datetime';
import { Card, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectStylistMap } from '../../../selectors/salonSelector';
import { useSelectVoucherLineServices } from '../../../selectors/voucherSelector';
import CAvatar from '../../atoms/CAvatar';
import CButton from '../../atoms/CButton';
import CStylistAvatar from '../../atoms/CStylistAvatar';
import CServiceNames from '../../molecules/CServiceNames';

type Props = {
  visit: VisitResource;
  linkTo: string;
  openInNewWindow?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    padding: theme.spacing(1),
    textTransform: 'none',
  },
}));

export const CVisitCardSmall: FC<Props> = React.memo(
  function CVisitCardSmall(props) {
    const classes = useStyles();

    const { visit, linkTo, openInNewWindow } = props;

    const { services, discounts } = useSelectVoucherLineServices(visit.voucher);
    const stylistMap = useSelector(selectStylistMap);
    const assignedStylist = visit.assignedStylistId
      ? (stylistMap[visit.assignedStylistId] ?? null)
      : null;

    return (
      <Card square>
        <CButton
          className={classes.container}
          variant="text"
          linkTo={linkTo}
          openInNewWindow={openInNewWindow}
        >
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <CAvatar src={visit.imageUrl ?? ''} />
            </Grid>
            <Grid item container>
              <Grid item xs={6}>
                <Typography variant="body2" color="textPrimary">
                  {moment.unix(visit.startedAt).format('YYYY/MM/DD kk:mm')}〜
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Grid container wrap="nowrap">
                  <CStylistAvatar
                    stylist={assignedStylist}
                    size={16}
                    style={{ marginRight: 3 }}
                  />
                  <Typography variant="body2" color="textPrimary" noWrap={true}>
                    {assignedStylist?.name}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container>
              <CServiceNames services={services} discounts={discounts} />
            </Grid>
            <Grid item>
              <Typography
                variant="caption"
                color="textSecondary"
                style={{ whiteSpace: 'pre-wrap', lineHeight: '1.2' }}
              >
                {visit.treatmentMemo}
              </Typography>
            </Grid>
          </Grid>
        </CButton>
      </Card>
    );
  },
  (prev, next) => _.isEqual(prev, next)
);
