import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M33.25 10.5a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0m-3.339 1.417a6.25 6.25 0 1 1 1.717 3.05l-13.54 7.616a6.27 6.27 0 0 1 0 2.834l13.54 7.616a6.25 6.25 0 1 1-1.717 3.05l-13.54-7.616a6.25 6.25 0 1 1 0-8.934zM14.38 22.619a2.75 2.75 0 1 0-4.757 2.762 2.75 2.75 0 0 0 4.757-2.762M33.58 36.191a2.75 2.75 0 1 0 4.838 2.618 2.75 2.75 0 0 0-4.838-2.618"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgShareAlt = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgShareAlt);
export default Memo;
