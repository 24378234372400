import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import { theme } from '@karutekun/shared-fe/react-ui-old';
import { Theme, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { WithStyles, createStyles, withStyles } from '@mui/styles';
import * as React from 'react';
import CMarkdown from './CMarkdown';

const styles = (theme: Theme) =>
  createStyles({
    tooltip: {
      fontSize: theme.typography.pxToRem(16),
      maxWidth: 600,
      whiteSpace: 'pre-wrap',
    },
    corner: {
      position: 'absolute',
      right: 8,
      top: 8,
      zIndex: 10,
    },
  });

type IconSize = 'sm' | 'lg';

type OwnProps = WithStyles<typeof styles> & {
  content: string;
  type: 'tooltip' | 'dialog';
  size?: IconSize;
  mt?: number;
  mb?: number;
  ml?: number;
  mr?: number;
  t?: number;
  r?: number;
  inverse?: boolean;
  corner?: boolean;
};

type State = {
  isDialogOpen: boolean;
};

class CInformation extends React.Component<OwnProps, State> {
  static defaultProps = {
    size: 'sm' as IconSize,
    mt: 0,
    mb: 0,
    ml: 8,
    mr: 8,
  };

  constructor(props: OwnProps) {
    super(props);
    this.state = {
      isDialogOpen: false,
    };
  }

  render() {
    const { type, content, size, mt, mb, ml, mr, t, r, inverse, corner } =
      this.props;

    const iconComponent = (
      <span style={{ verticalAlign: 'bottom' }}>
        <SVGIcon
          name="question-circle"
          size={size}
          color={
            inverse
              ? theme.palette.secondary.contrastText
              : theme.palette.secondary.main
          }
        />
      </span>
    );

    return (
      <span
        className={corner ? this.props.classes.corner : undefined}
        style={{
          top: t,
          right: r,
          marginTop: mt,
          marginBottom: mb,
          marginLeft: ml,
          marginRight: mr,
        }}
      >
        {type === 'tooltip' ? (
          <Tooltip
            title={content}
            classes={{
              tooltip: this.props.classes.tooltip,
            }}
            enterTouchDelay={0}
          >
            {iconComponent}
          </Tooltip>
        ) : (
          <span>
            <span onClick={this.handleDialogOpen} style={{ cursor: 'pointer' }}>
              {iconComponent}
            </span>
            <Dialog
              onClose={this.handleDialogClose}
              open={this.state.isDialogOpen}
            >
              <DialogContent>
                <CMarkdown text={content} />
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleDialogClose} color="primary">
                  閉じる
                </Button>
              </DialogActions>
            </Dialog>
          </span>
        )}
      </span>
    );
  }

  handleDialogClose = () => {
    this.setState({ isDialogOpen: false });
  };

  handleDialogOpen = () => {
    this.setState({ isDialogOpen: true });
  };
}

export default withStyles(styles)(CInformation);
