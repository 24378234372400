import { trpc } from '@karutekun/shared/data-access/api-base';

type ConfirmationOptions = {
  title: string;
  message: string;
};

type UpdateReservationMutationOptions = Exclude<
  Parameters<typeof trpc.visit.updateReservation.useMutation>[0],
  undefined
>;

type UseUpdateReservationOptions = {
  showConfirmation: (options: ConfirmationOptions, onOk: () => void) => void;
  onUnhandledError?: UpdateReservationMutationOptions['onError'];
  onSuccess?: UpdateReservationMutationOptions['onSuccess'];
};

export function useUpdateReservation({
  showConfirmation,
  onUnhandledError,
  onSuccess,
}: UseUpdateReservationOptions) {
  const { mutate, isPending } = trpc.visit.updateReservation.useMutation({
    onSuccess,
    onError(e, data, context) {
      if (e.data?.karutekunErrorCode === 'RESERVATION_UNAVAILABLE') {
        showConfirmation(
          {
            title: '確認',
            message: `${e.data.messageForUser}\nこのまま保存しますか？`,
          },
          async () => {
            mutate({ ...data, skipAvailabilityCheck: true });
          }
        );
      } else if (e.data?.karutekunErrorCode === 'CONFLICT') {
        showConfirmation(
          {
            title: '確認',
            message:
              '直前に他の端末による更新があります。このまま保存してよろしいですか？',
          },
          async () => {
            mutate({ ...data, skipConflictCheck: true });
          }
        );
      } else {
        onUnhandledError?.(e, data, context);
      }
    },
  });

  return { mutate, isPending };
}
