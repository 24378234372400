import { DiscountType } from '@karutekun/core/salon-service';
import { TaxRoundingType, applyTax } from '@karutekun/core/tax';
import { calculateDiscountAmounts } from './calculate-discount-amounts';

/**
 * voucherLine の sales と taxAmount を計算する
 * 各割引のおおよその割引額も同時に返す
 */
export function calculateVoucherLineSales(
  line: Readonly<{
    adjustedUnitPrice: number;
    quantity: number;
    isTaxIncluded: boolean;
    taxRate: number;
    discounts: Readonly<{ type: DiscountType; value: number }>[];
  }>,
  taxRoundingType: TaxRoundingType
): {
  sales: number;
  taxAmount: number;
  approxDiscountAmounts: { amount: number; taxAmount: number }[];
} {
  const priceBeforeDiscount = line.adjustedUnitPrice * line.quantity;

  // 割引を適用する
  const { discountedPrice, discountAmounts } = calculateDiscountAmounts(
    priceBeforeDiscount,
    line.discounts
  );

  // 消費税を適用する
  const { priceWithTax: sales, tax: taxAmount } = applyTax(
    discountedPrice,
    line.isTaxIncluded,
    line.taxRate,
    taxRoundingType
  );

  // 各割引に対して、実際に割り引いた金額を計算。参考値なので、合計金額が合わないことは許容できる
  const approxDiscountAmounts = line.discounts.map((_, i) => {
    const { priceWithoutTax, tax } = applyTax(
      discountAmounts[i],
      line.isTaxIncluded,
      line.taxRate,
      taxRoundingType
    );

    return {
      amount: priceWithoutTax,
      taxAmount: tax,
    };
  });

  return {
    sales,
    taxAmount,
    approxDiscountAmounts,
  };
}
