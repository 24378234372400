import * as React from 'react';
import CChartPie from '../atoms/CChartPie';

type OwnProps = {
  isShimeiMap: { 0: number; 1: number };
};

class CChartPieShimei extends React.PureComponent<OwnProps> {
  render() {
    const { isShimeiMap } = this.props;

    return (
      <CChartPie
        data={[
          {
            id: 0,
            name: '指名無し',
            value: isShimeiMap[0],
          },
          {
            id: 1,
            name: '指名有り',
            value: isShimeiMap[1],
          },
        ]}
        formatter={(v) => `${v}人`}
      />
    );
  }
}

export default CChartPieShimei;
