import { ActionType, getType } from 'typesafe-actions';
import * as actions from '../actions/uiAction';

export type UIActions = ActionType<typeof actions>;

export interface UIState {
  isSideMenuOpen: boolean;
}

const initialState: UIState = {
  isSideMenuOpen: true,
};

function uiReducer(state = initialState, action: UIActions) {
  switch (action.type) {
    case getType(actions.openSideMenu): {
      const { isOpen } = action.payload;
      return {
        ...state,
        isSideMenuOpen: isOpen,
      };
    }
    default:
      return state;
  }
}

export default uiReducer;
