import { ServiceType } from '@karutekun/core/salon-service';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ServiceCategoryColorMap } from '../../models/service';
import {
  selectServiceCategoryMap,
  selectServiceMap,
} from '../../selectors/serviceSelector';
import { notEmpty } from '../../util/common';
import CChartBarGrouped from '../atoms/CChartBarGrouped';
import CChartPieGrouped from '../atoms/CChartPieGrouped';

type GroupData = {
  [groupId: number]: {
    name: string;
    color: string;
  };
};
type GroupedChartData = {
  groupId: number;
  id: number;
  name: string;
  value: number;
  color?: string;
};

type Props = {
  chartType: 'pie' | 'bar';
  serviceType: ServiceType.Menu | ServiceType.Product;

  serviceValueMap: { [serviceId: number]: number };

  formatter(value: number): string;
  maxDataNumForEachGroup?: number;
};

export const CChartGroupedService: FC<Props> = (props) => {
  const {
    chartType,
    serviceType,
    serviceValueMap,
    formatter,
    maxDataNumForEachGroup = 8,
  } = props;

  const categoryMap = useSelector(selectServiceCategoryMap);
  const serviceMap = useSelector(selectServiceMap);

  const data = useMemo(() => {
    const data: GroupedChartData[] = [];
    for (const serviceId in serviceValueMap) {
      const service = serviceMap[serviceId];
      if (service && service.type === serviceType) {
        data.push({
          id: Number(service.id),
          groupId: service.categoryId,
          name: service.name,
          value: serviceValueMap[serviceId],
        });
      }
    }
    return data;
  }, [serviceMap, serviceType, serviceValueMap]);

  const groupData = useMemo(() => {
    const groupData: GroupData = Object.values(categoryMap)
      .filter(notEmpty)
      .filter((s) => s.type === serviceType)
      .reduce((prev, current) => {
        // TODO 一時的にルールを無効化しています。気づいたベースで直してください
        // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'number' can't be used to index type '{}'.
        prev[current.id] = {
          name: current.name,
          color: ServiceCategoryColorMap[serviceType][current.iconName],
        };
        return prev;
      }, {});
    return groupData;
  }, [categoryMap, serviceType]);

  return chartType === 'pie' ? (
    <CChartPieGrouped
      groupData={groupData}
      data={data}
      formatter={formatter}
      maxDataNumForEachGroup={maxDataNumForEachGroup}
      sortGroupOrder="asc"
      sortDataByValue={true}
    />
  ) : (
    <CChartBarGrouped
      groupData={groupData}
      data={data}
      formatter={formatter}
      maxDataNumForEachGroup={maxDataNumForEachGroup}
      sortGroupOrder="asc"
      sortDataByValue={true}
    />
  );
};

export default CChartGroupedService;
