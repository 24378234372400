import { Card, Grid } from '@mui/material';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  fmtAvgCustomerNum,
  fmtAvgSales,
  legendProps,
  tooltipProps,
  xAxisProps,
  yAxisProps,
} from '../../util/chart';
import CEmpty from '../atoms/CEmpty';

type OwnProps = {
  data: {
    label: string;
    firstCustomerNum: number;
    secondCustomerNum: number;
    averageBudget: number | null;
  }[];
  firstCustomerName?: string;
  secondCustomerName?: string;
};

class CChartComposedCustomerNumAverageBudget extends React.PureComponent<OwnProps> {
  render() {
    if (this.props.data.length === 0) {
      return <CEmpty title="該当データがありません" />;
    }

    return (
      <ResponsiveContainer width="100%">
        <ComposedChart data={this.props.data}>
          <CartesianGrid stroke="#f5f5f5" />
          <Legend {...legendProps} />
          <XAxis {...xAxisProps} dataKey="label" />
          <YAxis
            {...yAxisProps}
            yAxisId="customerNum"
            orientation="left"
            tickFormatter={(value) => `${value}人`}
          />
          <YAxis
            {...yAxisProps}
            yAxisId="budget"
            orientation="right"
            tickFormatter={fmtAvgSales}
          />
          <Tooltip {...tooltipProps} content={this.renderTooltip} />
          <Bar
            dataKey="secondCustomerNum"
            stackId="a"
            name={this.props.secondCustomerName || '固定'}
            yAxisId="customerNum"
            fill="#ffab4f"
            isAnimationActive={false}
          />
          <Bar
            dataKey="firstCustomerNum"
            stackId="a"
            name={this.props.firstCustomerName || '新規'}
            yAxisId="customerNum"
            fill="#ffd34f"
            isAnimationActive={false}
          />
          <Line
            type="monotone"
            dataKey="averageBudget"
            name="客単価"
            yAxisId="budget"
            stroke="#4dbd74"
            isAnimationActive={false}
            connectNulls
          />
        </ComposedChart>
      </ResponsiveContainer>
    );
  }

  // TODO 一時的に lint を無効化しています。気づいたベースで直してください
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderTooltip(props: any) {
    const { payload, label } = props;
    return (
      <Card style={{ padding: 10, backgroundColor: '#fff' }}>
        <Typography align="center">{label}</Typography>
        <Divider variant="middle" />
        <Grid container direction="row">
          {/* 一時的に lint を無効化しています。気づいたベースで直してください */}
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {payload.map((entry: any, index: any) => (
            <Grid item style={{ padding: 5 }} key={`grid-${index}`}>
              {(() => {
                if (index < 2) {
                  return (
                    <Typography
                      key={`item-value-${index}`}
                      variant="h6"
                      align="center"
                    >
                      {fmtAvgCustomerNum(entry.value)}
                    </Typography>
                  );
                } else {
                  return (
                    <Typography
                      key={`item-value-${index}`}
                      variant="h6"
                      align="center"
                    >
                      {fmtAvgSales(entry.value)}
                    </Typography>
                  );
                }
              })()}
              <Typography
                key={`item-name-${index}`}
                color="textSecondary"
                align="center"
              >
                {entry.name}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Card>
    );
  }
}

export default CChartComposedCustomerNumAverageBudget;
