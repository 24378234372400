import { ActionType, getType } from 'typesafe-actions';
import * as actions from '../actions/salonAction';
import { PlainReservationMenu } from '../models/reservationMenu';
import { PlainReservationStylist } from '../models/reservationStylist';
import { MySalon, Salon, emptyMySalon } from '../models/salon';
import { emptyReservationBasicSetting } from '../models/salonReservationSettings';
import {
  emptyBusinessHourSetting,
  emptyScheduleBasicSetting,
  emptyScheduleCapacitySetting,
} from '../models/salonScheduleSettings';
import {
  mergeIgnoringUndefField,
  notEmpty,
  removeUndefField,
} from '../util/common';

export type SalonActions = ActionType<typeof actions>;

export type SalonState = {
  mySalon: MySalon;
  groupSalonMap: IdMap<Salon>;
};

const initialState: SalonState = {
  mySalon: emptyMySalon(),
  groupSalonMap: {},
};

function salonReducer(state = initialState, action: SalonActions): SalonState {
  switch (action.type) {
    case getType(actions.setMySalon): {
      const { salon } = action.payload;
      return {
        ...state,
        mySalon: { ...state.mySalon, ...salon },
      };
    }
    case getType(actions.setGroupSalons): {
      const { salons } = action.payload;
      return {
        ...state,
        groupSalonMap: salons.reduce((prev, current) => {
          // TODO 一時的にルールを無効化しています。気づいたベースで直してください
          // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'number' can't be used to index type '{}'.
          prev[current.id] = current;
          return prev;
        }, {}),
      };
    }
    case getType(actions.setSalonStylistMap): {
      const { stylistMap } = action.payload;
      return {
        ...state,
        mySalon: {
          ...state.mySalon,
          stylistMap: {
            ...state.mySalon.stylistMap,
            ...stylistMap,
          },
        },
      };
    }
    case getType(actions.setSalonStylist): {
      const { stylistId, stylist } = action.payload;
      return {
        ...state,
        mySalon: {
          ...state.mySalon,
          stylistMap: {
            ...state.mySalon.stylistMap,
            [stylistId]: {
              ...state.mySalon.stylistMap[stylistId],
              ...removeUndefField(stylist),
            },
          },
        },
      };
    }
    case getType(actions.setSalonPermissionRole): {
      const { id, permissionRole } = action.payload;
      return {
        ...state,
        mySalon: {
          ...state.mySalon,
          permissionRoleMap: {
            ...state.mySalon.permissionRoleMap,
            [id]: {
              ...state.mySalon.permissionRoleMap[id],
              ...permissionRole,
            },
          },
        },
      };
    }
    case getType(actions.setSalonPermissionRoleMap): {
      const { permissionRoleMap } = action.payload;
      return {
        ...state,
        mySalon: {
          ...state.mySalon,
          permissionRoleMap,
        },
      };
    }
    case getType(actions.setSalonCounselingMap): {
      const { counselingMap } = action.payload;
      return {
        ...state,
        mySalon: {
          ...state.mySalon,
          customInformation: {
            ...state.mySalon.customInformation,
            counselingMap,
          },
        },
      };
    }
    case getType(actions.setSalonServiceCategoryMap): {
      const { categoryMap } = action.payload;
      return {
        ...state,
        mySalon: {
          ...state.mySalon,
          services: {
            ...state.mySalon.services,
            categoryMap: {
              ...state.mySalon.services.categoryMap,
              ...categoryMap,
            },
          },
        },
      };
    }
    case getType(actions.setSalonServiceMap): {
      const { serviceMap } = action.payload;
      return {
        ...state,
        mySalon: {
          ...state.mySalon,
          services: {
            ...state.mySalon.services,
            serviceMap: {
              ...state.mySalon.services.serviceMap,
              ...serviceMap,
            },
          },
        },
      };
    }
    case getType(actions.setSalonServiceDiscountMap): {
      const { discountMap } = action.payload;
      return {
        ...state,
        mySalon: {
          ...state.mySalon,
          services: {
            ...state.mySalon.services,
            discountMap: {
              ...state.mySalon.services.discountMap,
              ...discountMap,
            },
          },
        },
      };
    }
    case getType(actions.setSalonSubscriptionPaymentProvider): {
      const { paymentProvider } = action.payload;
      if (!state.mySalon.subscription) {
        return state;
      }

      return {
        ...state,
        mySalon: {
          ...state.mySalon,
          subscription: {
            ...state.mySalon.subscription,
            paymentProvider,
          },
        },
      };
    }
    case getType(actions.setSalonCustomSetting): {
      const { customSetting } = action.payload;
      return {
        ...state,
        mySalon: {
          ...state.mySalon,
          customSetting: {
            ...state.mySalon.customSetting,
            ...customSetting,
          },
        },
      };
    }
    case getType(actions.setSalonReservationSettings): {
      const { settings } = action.payload;

      // 更新分は availableReservationMenuIds や availableReservationStylistIds が undefined なら上書きしない
      const reservationStylistMap: IdMap<PlainReservationStylist> = {
        ...state.mySalon.reservationSettings?.reservationStylistMap,
        ...Object.keys(settings.reservationStylistMap ?? {}).reduce((p, c) => {
          // TODO 一時的にルールを無効化しています。気づいたベースで直してください
          // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
          p[c] = mergeIgnoringUndefField(
            // TODO 一時的にルールを無効化しています。気づいたベースで直してください
            // @ts-expect-error: TS7015: Element implicitly has an 'any' type because index expression is not of type 'number'.
            state.mySalon.reservationSettings?.reservationStylistMap[c],
            // TODO 一時的にルールを無効化しています。気づいたベースで直してください
            // @ts-expect-error: TS7015: Element implicitly has an 'any' type because index expression is not of type 'number'.
            settings.reservationStylistMap?.[c]
          );
          return p;
        }, {}),
      };

      const reservationMenuMap: IdMap<PlainReservationMenu> = {
        ...state.mySalon.reservationSettings?.reservationMenuMap,
        ...Object.keys(settings.reservationMenuMap ?? {}).reduce((p, c) => {
          // TODO 一時的にルールを無効化しています。気づいたベースで直してください
          // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
          p[c] = mergeIgnoringUndefField(
            // TODO 一時的にルールを無効化しています。気づいたベースで直してください
            // @ts-expect-error: TS7015: Element implicitly has an 'any' type because index expression is not of type 'number'.
            state.mySalon.reservationSettings?.reservationMenuMap[c],
            // TODO 一時的にルールを無効化しています。気づいたベースで直してください
            // @ts-expect-error: TS7015: Element implicitly has an 'any' type because index expression is not of type 'number'.
            settings.reservationMenuMap?.[c]
          );
          return p;
        }, {}),
      };

      // reservationStylist <=> reservationMenuそれぞれの available** を更新
      // 初期化時以外に両方同時に更新される場合は書き換えてください
      if (settings.reservationStylistMap) {
        const availableReservationStylistIdsMap = {};

        Object.values(reservationStylistMap).forEach((rs) => {
          rs?.availableReservationMenuIds?.forEach((reservationMenuId) => {
            // TODO 一時的にルールを無効化しています。気づいたベースで直してください
            // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'number' can't be used to index type '{}'.
            if (!availableReservationStylistIdsMap[reservationMenuId]) {
              // TODO 一時的にルールを無効化しています。気づいたベースで直してください
              // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'number' can't be used to index type '{}'.
              availableReservationStylistIdsMap[reservationMenuId] = [];
            }
            // TODO 一時的にルールを無効化しています。気づいたベースで直してください
            // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'number' can't be used to index type '{}'.
            availableReservationStylistIdsMap[reservationMenuId].push(rs.id);
          });
        });

        Object.values(reservationMenuMap)
          .filter(notEmpty)
          .forEach((rm) => {
            rm.availableReservationStylistIds =
              // TODO 一時的にルールを無効化しています。気づいたベースで直してください
              // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'number' can't be used to index type '{}'.
              availableReservationStylistIdsMap[rm.id] ?? [];
          });
      } else if (settings.reservationMenuMap) {
        const availableReservationMenuIdsMap = {};

        Object.values(reservationMenuMap).forEach((rm) => {
          rm?.availableReservationStylistIds?.forEach(
            (reservationStylistId) => {
              // TODO 一時的にルールを無効化しています。気づいたベースで直してください
              // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'number' can't be used to index type '{}'.
              if (!availableReservationMenuIdsMap[reservationStylistId]) {
                // TODO 一時的にルールを無効化しています。気づいたベースで直してください
                // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'number' can't be used to index type '{}'.
                availableReservationMenuIdsMap[reservationStylistId] = [];
              }
              // TODO 一時的にルールを無効化しています。気づいたベースで直してください
              // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'number' can't be used to index type '{}'.
              availableReservationMenuIdsMap[reservationStylistId].push(rm.id);
            }
          );
        });

        Object.values(reservationStylistMap)
          .filter(notEmpty)
          .forEach((rs) => {
            rs.availableReservationMenuIds =
              // TODO 一時的にルールを無効化しています。気づいたベースで直してください
              // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'number' can't be used to index type '{}'.
              availableReservationMenuIdsMap[rs.id] ?? [];
          });
      }

      return {
        ...state,
        mySalon: {
          ...state.mySalon,
          reservationSettings: {
            reservationStylistMap,
            reservationMenuMap,
            basicSetting: {
              ...(state.mySalon.reservationSettings?.basicSetting ||
                emptyReservationBasicSetting()),
              ...settings.basicSetting,
            },
          },
        },
      };
    }
    case getType(actions.setSalonScheduleSettings): {
      const { settings } = action.payload;
      return {
        ...state,
        mySalon: {
          ...state.mySalon,
          scheduleSettings: {
            basicSetting: {
              ...(state.mySalon.scheduleSettings?.basicSetting ||
                emptyScheduleBasicSetting()),
              ...settings.basicSetting,
            },
            businessHourSetting: {
              ...(state.mySalon.scheduleSettings?.businessHourSetting ||
                emptyBusinessHourSetting()),
              ...settings.businessHourSetting,
            },
            scheduleCapacitySetting: {
              ...(state.mySalon.scheduleSettings?.scheduleCapacitySetting ||
                emptyScheduleCapacitySetting()),
              ...settings.scheduleCapacitySetting,
            },
            shiftPatternMap: {
              ...state.mySalon.scheduleSettings?.shiftPatternMap,
              ...settings.shiftPatternMap,
            },
          },
        },
      };
    }
    default:
      return state;
  }
}

export default salonReducer;
