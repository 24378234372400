import { SelectProps } from '@mui/material';
import { PlainStylist } from '../../models/stylist';
import CSelect from '../atoms/CSelect';

type OwnProps = Omit<SelectProps, 'value' | 'onChange'> & {
  label?: string;
  stylists: PlainStylist[];
  selected?: number;
  emptyString?: string;
  dense?: boolean;

  onChange(value: number | undefined): void;
};

const CStylistSelect: FC<OwnProps> = (props) => {
  const {
    label = '担当者',
    stylists,
    selected,
    emptyString,
    dense,
    onChange,
    ...selectProps
  } = props;

  const options = stylists
    .sort((a, b) => {
      return a.isActive === b.isActive ? 0 : a.isActive ? -1 : 1;
    })
    .map((s) => ({
      value: s.id,
      element: s.name,
      isInactive: !s.isActive,
    }));

  return (
    <CSelect
      label={label}
      options={options}
      value={selected}
      dense={dense}
      emptyString={emptyString}
      onChange={onChange}
      {...selectProps}
    />
  );
};

export default CStylistSelect;
