import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import { Card, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { selectAnalyticsDisplayTaxIncludedPrice } from '../../../selectors/settingsSelector';
import CInformation from '../../atoms/CInformation';
import CMoneyTypography from '../../atoms/CMoneyTypography';
import CProgressOverlay from '../../atoms/CProgressOverlay';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    flex: '1',
    textAlign: 'center',
    marginTop: theme.spacing(2),
    position: 'relative',
  },
  card: {
    overflow: 'inherit',
    minHeight: 52,
  },
  title: {
    float: 'left',
    margin: '-20px 20px 0 15px',
    zIndex: 100,
    borderRadius: 3,
    padding: '5px 10px',
  },
}));

type Props = {
  title: string;
  stats: Nullable<{
    firstVisitNum: number;
    repeatVisitNum: number;
    averageSalesPerCustomer: number;
    averageSalesPerCustomerWithoutTax: number;
  }>;
  isFetching: boolean;
  informationText: string;
};

export const CSalonCustomerStatsCard: FC<Props> = (props) => {
  const classes = useStyles();

  const { isFetching, title, stats, informationText } = props;

  const displayTaxIncludedPrice = useSelector(
    selectAnalyticsDisplayTaxIncludedPrice
  );

  const firstVisitNum = stats?.firstVisitNum ?? 0;
  const repeatVisitNum = stats?.repeatVisitNum ?? 0;
  const average = displayTaxIncludedPrice
    ? (stats?.averageSalesPerCustomer ?? 0)
    : (stats?.averageSalesPerCustomerWithoutTax ?? 0);

  return (
    <div className={classes.main}>
      {isFetching && <CProgressOverlay />}

      <CInformation type="dialog" content={informationText} corner inverse />

      <Card className={classes.title} style={{ backgroundColor: '#f86c6b' }}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ padding: 8 }}
        >
          <SVGIcon name="user-fill" color="#fff" />
        </Grid>
      </Card>
      <Card className={classes.card} style={{ backgroundColor: '#ffc107' }}>
        <Grid
          container
          justifyContent="space-evenly"
          alignItems="center"
          direction="column"
          style={{ paddingBottom: 16, height: 100 }}
        >
          <Typography variant="h4" style={{ color: '#fff' }}>
            {firstVisitNum + repeatVisitNum}回
          </Typography>
          <Typography variant="h6" style={{ color: '#fff' }}>
            {title}
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          style={{ backgroundColor: '#fff' }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid container item xs={8}>
            <Grid container style={{ padding: 8 }}>
              <Grid item xs={6}>
                <Typography variant="h6">{firstVisitNum}回</Typography>
                <Typography color="textSecondary" variant="subtitle1">
                  新規
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">{repeatVisitNum}回</Typography>
                <Typography color="textSecondary" variant="subtitle1">
                  固定
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="column"
            xs={4}
            style={{
              height: '80%',
              borderLeft: '1px solid #d9d9d9',
            }}
            justifyContent="center"
            alignItems="center"
          >
            <CMoneyTypography variant="h6" amount={average} />
            <Typography color="textSecondary" variant="subtitle1">
              平均単価
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};
