import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M30.755 2.75H10.495a7.768 7.768 0 0 0-7.745 7.745V30.755a7.015 7.015 0 0 0 6.995 6.995h.505v.406a7.094 7.094 0 0 0 7.094 7.094h20.812a7.094 7.094 0 0 0 7.094-7.094V17.344a7.094 7.094 0 0 0-7.094-7.094h-.416l.01-.464v-.041a7.015 7.015 0 0 0-6.995-6.995m5.204 11H17.344a3.594 3.594 0 0 0-3.594 3.594V38.156a3.594 3.594 0 0 0 3.594 3.594h20.812a3.594 3.594 0 0 0 3.594-3.594V17.344a3.594 3.594 0 0 0-3.594-3.594zM10.25 34.25V17.344a7.094 7.094 0 0 1 7.094-7.094h16.895l.01-.514a3.515 3.515 0 0 0-3.502-3.486H10.503a4.268 4.268 0 0 0-4.253 4.253v20.244a3.515 3.515 0 0 0 3.503 3.503z"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgCopy = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgCopy);
export default Memo;
