import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import { theme } from '@karutekun/shared-fe/react-ui-old';
import { InputAdornment, TextField } from '@mui/material';

interface OwnProps {
  label: string;
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  fullWidth?: boolean;

  onChange(value: string): void;
}

const CTextInput: FC<OwnProps> = (props) => {
  const { label, value, disabled, placeholder, fullWidth, onChange } = props;

  return (
    <TextField
      label={label}
      variant="standard"
      type="text"
      value={value}
      placeholder={placeholder || '検索'}
      onChange={(e) => onChange(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SVGIcon name="search" color={theme.palette.text.secondary} />
          </InputAdornment>
        ),
      }}
      InputLabelProps={{ shrink: true }}
      disabled={disabled}
      fullWidth={fullWidth}
    />
  );
};

export default CTextInput;
