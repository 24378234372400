import { IStylistStats } from '@karutekun/core/stats/stylist-stats';
import { Card, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { InformationText } from '../../../texts/infomation';
import { useWidthDown } from '../../../util/hooks/useWidth';
import CInformation from '../../atoms/CInformation';
import CMoneyTypography from '../../atoms/CMoneyTypography';
import CProgressOverlay from '../../atoms/CProgressOverlay';

type Props = {
  stats: Nullable<IStylistStats>;
  isFetching: boolean;
  displayTaxIncludedPrice: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  infoContainer: {
    position: 'relative',
  },
  header: {
    backgroundColor: '#20a8d8',
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    color: '#fff',
  },
  headerLeft: {
    flex: 1,
    borderRight: `1px solid #fff`,
  },
  headerRight: {
    flex: 1,
  },
  subBlock: {
    padding: theme.spacing(1),
  },
  shimeiTopBorder: {
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  shimeiLeftBorder: {
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
  label: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.h6.fontWeight,
  },
}));

export const CStylistSalesStatsCard: FC<Props> = (props) => {
  const classes = useStyles();

  const { isFetching, displayTaxIncludedPrice, stats } = props;

  const sales = displayTaxIncludedPrice
    ? stats?.sales ?? 0
    : stats?.salesWithoutTax ?? 0;

  const mainShimeiSales =
    (displayTaxIncludedPrice
      ? stats?.mainShimeiSales
      : stats?.mainShimeiSalesWithoutTax) ?? 0;
  const mainNotShimeiSales =
    (displayTaxIncludedPrice
      ? stats?.mainNotShimeiSales
      : stats?.mainNotShimeiSalesWithoutTax) ?? 0;

  const menuShimeiSales =
    (displayTaxIncludedPrice
      ? stats?.menuShimeiSales
      : stats?.menuShimeiSalesWithoutTax) ?? 0;
  const menuNotShimeiSales =
    (displayTaxIncludedPrice
      ? stats?.menuNotShimeiSales
      : stats?.menuNotShimeiSalesWithoutTax) ?? 0;

  const productShimeiSales =
    (displayTaxIncludedPrice
      ? stats?.productShimeiSales
      : stats?.productShimeiSalesWithoutTax) ?? 0;
  const productNotShimeiSales =
    (displayTaxIncludedPrice
      ? stats?.productNotShimeiSales
      : stats?.productNotShimeiSalesWithoutTax) ?? 0;

  const menuSales = menuShimeiSales + menuNotShimeiSales;
  const productSales = productShimeiSales + productNotShimeiSales;

  return (
    <Card style={{ position: 'relative', textAlign: 'center' }}>
      {isFetching && <CProgressOverlay />}

      <CInformation
        type="dialog"
        content={InformationText.stylists.salesSummary}
        corner
        inverse
      />

      <div className={classes.header}>
        <div className={classes.headerLeft}>
          <CMoneyTypography variant="h4" amount={sales} />
          <Typography variant="h6">総売上</Typography>
          <Typography className={classes.label}>(a) + (c)</Typography>
        </div>
        <div className={classes.headerRight}>
          <CMoneyTypography variant="h4" amount={menuSales + productSales} />
          <Typography variant="h6">担当メニュー売上</Typography>
          <Typography className={classes.label}>(b) + (c)</Typography>
        </div>
      </div>

      <Grid container justifyContent="center" alignItems="center">
        <SalesSubBlock
          title="主担当売上"
          label="(a)"
          help="主担当として指定された来店記録の施術売上を合計したものです"
          shimeiSales={mainShimeiSales}
          notShimeiSales={mainNotShimeiSales}
        />
        <SalesSubBlock
          title="施術売上"
          label="(b)"
          help="施術での販売担当者別の売上金額を合計したものです"
          shimeiSales={menuShimeiSales}
          notShimeiSales={menuNotShimeiSales}
        />
        <SalesSubBlock
          title="店販売上"
          label="(c)"
          help="店販での販売担当者別の売上金額を合計したものです"
          shimeiSales={productShimeiSales}
          notShimeiSales={productNotShimeiSales}
        />
      </Grid>
    </Card>
  );
};

const SalesSubBlock: FC<{
  title: string;
  label: string;
  help: string;
  shimeiSales: number;
  notShimeiSales: number;
}> = (props) => {
  const classes = useStyles();
  const isXs = useWidthDown('sm');

  const { title, label, help, shimeiSales, notShimeiSales } = props;

  return (
    <Grid
      item
      container
      alignItems="center"
      xs={12}
      sm={4}
      className={classes.subBlock}
    >
      <Grid item xs={5} sm={12} className={classes.infoContainer}>
        <CInformation type="tooltip" content={help} corner t={0} />
        <CMoneyTypography variant="h5" amount={shimeiSales + notShimeiSales} />
        <Typography variant="body1">{title}</Typography>
        <Typography className={classes.label}>{label}</Typography>
      </Grid>

      <Grid
        item
        xs={7}
        sm={12}
        className={clsx({
          [classes.shimeiTopBorder]: !isXs,
          [classes.shimeiLeftBorder]: isXs,
        })}
      >
        <Grid container>
          <Grid item xs={6}>
            <CMoneyTypography variant="body1" amount={shimeiSales} />
            <Typography color="textSecondary" variant="body2">
              指名有り
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <CMoneyTypography variant="body1" amount={notShimeiSales} />
            <Typography color="textSecondary" variant="body2">
              指名無し
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
