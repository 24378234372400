import {
  CustomerLoyaltySegment,
  CustomerSex,
  SalonCustomerLineState,
} from '@karutekun/core/customer';
import { moment } from '@karutekun/shared/util/datetime';
import { CustomerCounselingAnswer } from '../models/customerCounselingAnswer';
import { Salon } from '../models/salon';
import { PlainStylist } from '../models/stylist';
import { PlainUploadedPaper } from '../models/uploadedPaper';
import { CustomerImage } from './customerImage';

export type CustomerSalonLineSetting = {
  lineState: SalonCustomerLineState;
};

export type CustomerSalonInformation = {
  visitMotivationIds: number[];
  counselingAnswers: CustomerCounselingAnswer[];
};

export type PlainCustomerStatistics = {
  firstVisitedAt: number;
  lastVisitedAt: number;
  lastStylistId: number;
  totalVisitNum: number;
  totalSales: number;
  totalMenuSales: number;
  totalProductSales: number;
  averageBudget: number;
  averageVisitPeriod: number;
  isLastShimei: boolean;
  loyaltySegment: CustomerLoyaltySegment;
};
export type CustomerStatistics = PlainCustomerStatistics & {
  lastStylist: PlainStylist | null;
};

export type PlainCustomer = PlainCustomerStatistics & {
  id: number;
  salonId: number;
  name: string;
  nameKana: string;
  sex: CustomerSex;
  allergy: string;
  memo: string;
  birthdayYear: number | null;
  birthdayMonth: number | null;
  birthdayDay: number | null;
  job: string;
  profileImageUrl: string | null;
  createdAt: number;
  userUpdatedAt: number;

  latestSalonId: number | null;
  uploadedPaperId: number | null;

  phone?: string;
  email?: string;
  postalCode?: string;
  address?: string;

  images: CustomerImage[];
  salonInformationMap: IdMap<CustomerSalonInformation>;
  plainSalonStatisticsMap: IdMap<PlainCustomerStatistics>;
  salonLineSettingMap: IdMap<CustomerSalonLineSetting>;
};

export type Customer = PlainCustomer & {
  latestSalon: Salon | null;
  lastStylist: PlainStylist | null;

  // ログインサロンにおける統計情報
  mySalonStatistics: CustomerStatistics;

  uploadedPaper: PlainUploadedPaper | null;
};

export function customerFromResource(
  // TODO 一時的に lint を無効化しています。気づいたベースで直してください
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  res: any
): Partial<PlainCustomer> & { id: number } {
  const data: Partial<PlainCustomer> & { id: number } = {
    id: res.id,
    salonId: res.salonId,
    name: res.name,
    nameKana: res.nameKana,
    sex: res.sex,
    allergy: res.allergy,
    memo: res.memo,
    birthdayYear: res.birthdayYear,
    birthdayMonth: res.birthdayMonth,
    birthdayDay: res.birthdayDay,
    job: res.job,
    profileImageUrl: res.profileImageUrl,
    createdAt: res.createdAt,
    userUpdatedAt: res.userUpdatedAt,
    images: res.images,

    ...createCustomerSalonStatisticsFromResource(res),
  };

  // API response に含まれない可能性のあるキーを設置
  if (res.latestSalonId !== undefined) {
    data.latestSalonId = res.latestSalonId;
  }
  if (res.phone !== undefined) {
    data.phone = res.phone;
  }
  if (res.email !== undefined) {
    data.email = res.email;
  }
  if (res.postalCode !== undefined) {
    data.postalCode = res.postalCode;
  }
  if (res.address !== undefined) {
    data.address = res.address;
  }
  if (res.uploadedPaper !== undefined) {
    data.uploadedPaperId =
      res.uploadedPaper !== null ? res.uploadedPaper.id : null;
  }
  if (res.salonInformationMap !== undefined) {
    data.salonInformationMap = Object.keys(res.salonInformationMap).reduce(
      (prev, salonIdString) => {
        const salonId = Number(salonIdString);
        // TODO 一時的にルールを無効化しています。気づいたベースで直してください
        // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'number' can't be used to index type '{}'.
        prev[salonId] = createCustomerSalonInformationFromResource(
          res.salonInformationMap[salonId]
        );
        return prev;
      },
      {}
    );
  }
  if (res.salonStatisticsMap !== undefined) {
    data.plainSalonStatisticsMap = Object.keys(res.salonStatisticsMap).reduce(
      (prev, salonIdString) => {
        const salonId = Number(salonIdString);
        // TODO 一時的にルールを無効化しています。気づいたベースで直してください
        // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'number' can't be used to index type '{}'.
        prev[salonId] = createCustomerSalonStatisticsFromResource(
          res.salonStatisticsMap[salonId]
        );
        return prev;
      },
      {}
    );
  }
  if (res.salonLineSettingMap !== undefined) {
    data.salonLineSettingMap = Object.keys(res.salonLineSettingMap).reduce(
      (prev, salonIdString) => {
        const salonId = Number(salonIdString);
        // TODO 一時的にルールを無効化しています。気づいたベースで直してください
        // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'number' can't be used to index type '{}'.
        prev[salonId] = createCustomerSalonLineSettingFromResource(
          res.salonLineSettingMap[salonId]
        );
        return prev;
      },
      {}
    );
  }

  return data;
}

export function createCustomerSalonInformationFromResource(
  // TODO 一時的に lint を無効化しています。気づいたベースで直してください
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  res: any
): CustomerSalonInformation {
  return {
    visitMotivationIds:
      res && res.visitMotivations
        ? // TODO 一時的に lint を無効化しています。気づいたベースで直してください
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.visitMotivations.map((m: any) => m.id)
        : [],
    counselingAnswers:
      res && res.counselingAnswers
        ? // TODO 一時的に lint を無効化しています。気づいたベースで直してください
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.counselingAnswers.map((c: any) => ({
            optionId: c.optionId,
            value: c.value,
          }))
        : [],
  };
}

export function createCustomerSalonStatisticsFromResource(
  // TODO 一時的に lint を無効化しています。気づいたベースで直してください
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  res: any
): PlainCustomerStatistics {
  return {
    firstVisitedAt: res.firstVisitedAt,
    lastVisitedAt: res.lastVisitedAt,
    lastStylistId: res.lastStylistId,
    totalVisitNum: res.totalVisitNum,
    totalSales: res.totalSales,
    totalMenuSales: res.totalMenuSales,
    totalProductSales: res.totalProductSales,
    averageBudget: res.averageBudget,
    averageVisitPeriod: res.averageVisitPeriod,
    isLastShimei: res.isLastShimei,
    loyaltySegment: res.loyaltySegment,
  };
}

export function createCustomerSalonLineSettingFromResource(
  // TODO 一時的に lint を無効化しています。気づいたベースで直してください
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  res: any
): CustomerSalonLineSetting {
  return {
    lineState: res.lineState,
  };
}

export function emptyCustomerSalonInformation(
  overwrite?: Partial<CustomerSalonInformation>
): CustomerSalonInformation {
  return {
    visitMotivationIds: [],
    counselingAnswers: [],
    ...overwrite,
  };
}

export function emptyCustomerSalonLineSetting(
  overwrite?: Partial<CustomerSalonLineSetting>
): CustomerSalonLineSetting {
  return {
    lineState: SalonCustomerLineState.NotYet,
    ...overwrite,
  };
}

export function emptyCustomerStatistics(
  overwrite?: Partial<PlainCustomerStatistics>
): PlainCustomerStatistics {
  return {
    firstVisitedAt: 0,
    lastVisitedAt: 0,
    lastStylistId: 0,
    totalVisitNum: 0,
    totalSales: 0,
    totalMenuSales: 0,
    totalProductSales: 0,
    averageBudget: 0,
    averageVisitPeriod: 0,
    isLastShimei: false,
    loyaltySegment: CustomerLoyaltySegment.None,
    ...overwrite,
  };
}

export function emptyCustomer(
  overwrite?: Partial<PlainCustomer>,
  defaultSex?: CustomerSex
): PlainCustomer {
  return {
    id: 0,
    salonId: 0,
    name: '',
    nameKana: '',
    sex: defaultSex ?? CustomerSex.Female,
    allergy: '',
    memo: '',
    birthdayYear: null,
    birthdayMonth: null,
    birthdayDay: null,
    job: '',
    profileImageUrl: null,
    createdAt: moment().unix(),
    userUpdatedAt: 0,

    latestSalonId: null,
    uploadedPaperId: null,

    phone: '',
    email: '',
    postalCode: '',
    address: '',

    images: [],
    salonInformationMap: {},
    plainSalonStatisticsMap: {},
    salonLineSettingMap: {},

    ...emptyCustomerStatistics(),
    ...overwrite,
  };
}

export function useCustomerImage(customer: Customer) {
  return customer.profileImageUrl ?? '/static/images/noimage.png';
}
