import {
  RemoteConfig as OriginalRemoteConfig,
  fetchAndActivate,
  getRemoteConfig,
  getString,
  isSupported,
} from 'firebase/remote-config';
import firebaseApp from './libs/firebase';
import Logger from './logger';
import { DebugToolService } from './service/debugToolService';

const env = process.env.REACT_APP_ENV;
// prd以外ではremoteConfigを変更してすぐに反映できるようにしておく
const isDeveloperModeEnabled = env !== 'prd';

// firebase のデフォルトでは 12 hours
const minimumFetchIntervalMillis = isDeveloperModeEnabled ? 0 : 43200000;

/**
 * RemoteConfigを変更した場合、変更を公開するまで反映されないので注意
 */
export type RemoteConfigData = {
  dummy: string;
  // keyを追加するときはhasDataの判定を修正してください。
};
export const InitialData = {
  dummy: 'default',
};

class RemoteConfig {
  hasData: boolean;
  remoteConfig: OriginalRemoteConfig | undefined = undefined;
  constructor() {
    this.hasData = Object.keys(InitialData).length > 1;
  }

  async initialize() {
    const isSupportedResult = await isSupported();
    if (isSupportedResult) {
      Logger.info(`[RemoteConfig] Initialize`, minimumFetchIntervalMillis);
    } else {
      Logger.info(`[RemoteConfig] not supported environment`);
    }

    if (this.hasData && isSupportedResult) {
      this.remoteConfig = getRemoteConfig(firebaseApp);
    }

    // 一時的にremoteConfigを使わなくなったのでkeyが一つしかない場合は不要なfetchを避けています。
    if (!this.remoteConfig) {
      return;
    }

    this.remoteConfig.settings.minimumFetchIntervalMillis =
      minimumFetchIntervalMillis;
    this.remoteConfig.defaultConfig = InitialData;
    const fetchedRemotely = await fetchAndActivate(this.remoteConfig).catch(
      console.error
    );

    if (fetchedRemotely) {
      Logger.info(
        '[RemoteConfig] New configs were retrieved from the backend and activated.'
      );
    } else {
      Logger.info(
        '[RemoteConfig] No new configs were fetched from the backend, and the local configs were already activated'
      );
    }
  }

  getValue<K extends keyof RemoteConfigData>(key: K): RemoteConfigData[K] {
    if (DebugToolService.isRemoteConfigDebugEnabled()) {
      const data = DebugToolService.getRemoteConfigData();
      return data !== null ? data[key] : InitialData[key];
    } else if (this.remoteConfig) {
      return getString(this.remoteConfig, key);
    } else {
      return InitialData[key];
    }
  }

  getAll(): RemoteConfigData {
    return Object.keys(InitialData).reduce(
      (p, c) => {
        // TODO 一時的にルールを無効化しています。気づいたベースで直してください
        // @ts-expect-error: TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ dummy: string; }'.
        p[c] = this.getValue(c as keyof RemoteConfigData);
        return p;
      },
      { ...InitialData }
    );
  }
}

export default new RemoteConfig();
