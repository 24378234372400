import { ChatMessage } from '../chatMessage/entity';
import { ChatRoom as ChatRoomSchema } from './schema';

export class ChatRoom {
  documentId: string;
  salonId: number;
  customerId: number;
  latestMessage: ChatMessage;
  unreadNum: number;
  isDeleted: boolean;

  constructor(args: ChatRoomSchema) {
    this.documentId = args.documentId;
    this.salonId = args.salonId;
    this.customerId = args.customerId;
    this.latestMessage = new ChatMessage(args.latestMessage);
    this.unreadNum = args.unreadNum;
    this.isDeleted = args.isDeleted;
  }
}
