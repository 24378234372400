import type { SVGProps } from 'react';
import { memo } from 'react';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: SVGProps<SVGSVGElement>['style'];
};
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill={props.color || 'currentColor' || '#000'}
      fillRule="evenodd"
      d="M14.08 3.011a1.75 1.75 0 0 1 1.703-.076L18 4.043l2.217-1.108a1.75 1.75 0 0 1 1.567 0L24 4.045l2.254-1.114a1.75 1.75 0 0 1 1.564.007L30 4.04l2.193-1.103a1.75 1.75 0 0 1 1.57-.002l2.214 1.11 2.245-1.113a1.75 1.75 0 0 1 1.56.003L42 4.043l2.216-1.108A1.75 1.75 0 0 1 46.75 4.5V36a9.25 9.25 0 0 1-9.25 9.25h-27c-1.898 0-3.516-.31-4.856-.983-1.368-.689-2.342-1.705-3.008-2.933-1.258-2.32-1.386-5.386-1.386-8.328a3.236 3.236 0 0 1 3.256-3.256h8.744V4.5c0-.607.314-1.17.83-1.489M43.25 25.5zV36a5.75 5.75 0 0 1-11.5 0v-4.5A1.75 1.75 0 0 0 30 29.75H16.75V7.332l.467.233a1.75 1.75 0 0 0 1.566 0l2.216-1.108 2.212 1.108a1.75 1.75 0 0 0 1.559.004l2.25-1.113 2.191 1.106c.496.25 1.08.25 1.576.001l2.195-1.105 2.208 1.107a1.75 1.75 0 0 0 1.56.003l2.247-1.113 2.221 1.11a1.75 1.75 0 0 0 1.566 0l.466-.233zM30.254 41.75A9.25 9.25 0 0 1 28.25 36v-2.75H4.75c.01 2.926.21 5.027.963 6.416.35.647.822 1.13 1.504 1.474.71.357 1.747.61 3.283.61zM21 11.75a1.75 1.75 0 1 0 0 3.5h18a1.75 1.75 0 1 0 0-3.5zm6 7.5a1.75 1.75 0 1 0 0 3.5h12a1.75 1.75 0 1 0 0-3.5z"
      clipRule="evenodd"
    />
  </svg>
);
const SIZE_MAP = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 36,
};
const DEFAULT_VALUE = 20;
const SvgReceipt = (props: Props) => {
  const size = props.size
    ? (SIZE_MAP[props.size] ?? DEFAULT_VALUE)
    : DEFAULT_VALUE;
  return <SvgIcon width={size} height={size} {...props} />;
};
const Memo = memo(SvgReceipt);
export default Memo;
