import { VoucherUtils } from '@karutekun/core/voucher';
import {
  VoucherFormData,
  useVoucherForm,
} from '@karutekun/shared/data-access/voucher';
import { useBoolean } from '@karutekun/shared/util/react-hooks';
import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useCallback, useMemo, useState } from 'react';
import CBackdrop from '../../../../components_old/atoms/CBackdrop';
import CProgress from '../../../../components_old/atoms/CProgress';
import { ServiceSelect } from '../../../../features/service/components/ServiceSelect';
import { ServiceDiscount } from '../../../../models/service';
import { useSelectMySalon } from '../../../../selectors/salonSelector';
import { useSelectSalonServices } from '../../../../selectors/serviceSelector';
import { useWidthDown } from '../../../../util/hooks/useWidth';
import { VoucherPreview } from '../VoucherPreview';
import ApplyDiscountDialog from './components/ApplyDiscountDialog';
import { EditVoucherLineDialog } from './components/EditVoucherLineDialog';

type Props = {
  voucher: VoucherFormData;
  voucherFormMutations: ReturnType<typeof useVoucherForm>[1];
  defaultIsShimei: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  containerVertical: {
    flexDirection: 'column',
  },
  serviceSelectContainer: {
    flex: 2,
    minHeight: 0,
    borderRight: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  selectedServiceContainer: {
    flex: 1,
    minHeight: 0,
    overflow: 'auto',
  },
}));

export const VoucherForm: FC<Props> = (props) => {
  const classes = useStyles();

  const { voucher, voucherFormMutations, defaultIsShimei } = props;

  const salon = useSelectMySalon();
  const { serviceMap, discountMap } = useSelectSalonServices();

  const [editingLineIndex, setEditingLineIndex] = useState<number>(0);
  const [voucherLineDialogOpen, voucherLineDialogOpenMutations] =
    useBoolean(false);
  const [applyDiscountId, setApplyDiscountId] = useState<number | null>(null);
  const [discountDialogOpen, discountDialogOpenMutations] = useBoolean(false);

  const handleClickService = useCallback(
    (serviceId: number) => {
      const service = serviceMap[serviceId];
      if (service) {
        voucherFormMutations.addLine(service);
      }
    },
    [serviceMap, voucherFormMutations]
  );
  const handleClickDiscount = useCallback(
    (discountId: number) => {
      setApplyDiscountId(discountId);
      discountDialogOpenMutations.setTrue();
    },
    [discountDialogOpenMutations]
  );

  const handleApplyDiscountToTotal = useCallback(
    (discount: ServiceDiscount) => {
      discountDialogOpenMutations.setFalse();
      voucherFormMutations.addDiscount(discount);
    },
    [discountDialogOpenMutations, voucherFormMutations]
  );
  const handleApplyDiscountToLines = useCallback(
    (lineIndices: number[], discount: ServiceDiscount) => {
      discountDialogOpenMutations.setFalse();
      voucherFormMutations.addDiscountToLines(lineIndices, discount);
    },
    [discountDialogOpenMutations, voucherFormMutations]
  );

  const voucherForPreview = useMemo(
    () => ({
      salonId: salon.id,
      ...VoucherUtils.calculateAndFillVoucherSales(voucher),
    }),
    [salon.id, voucher]
  );

  const isVertical = useWidthDown('sm');

  return (
    <>
      <div
        className={clsx(classes.container, {
          [classes.containerVertical]: isVertical,
        })}
      >
        <div className={classes.serviceSelectContainer}>
          <ServiceSelect
            onClickService={handleClickService}
            onClickDiscount={handleClickDiscount}
          />
        </div>
        <div className={classes.selectedServiceContainer}>
          <Box p={1}>
            <Typography variant="h6" gutterBottom>
              選択中のメニュー
            </Typography>
            <VoucherPreview
              voucher={voucherForPreview}
              onClickEditLine={(index: number) => {
                setEditingLineIndex(index);
                voucherLineDialogOpenMutations.setTrue();
              }}
              onClickDeleteLine={voucherFormMutations.deleteLine}
              onClickDeleteDiscount={voucherFormMutations.deleteDiscount}
              sortLines={false}
            />
          </Box>
        </div>
      </div>
      {voucher.lines[editingLineIndex] && (
        <EditVoucherLineDialog
          open={voucherLineDialogOpen}
          defaultLineData={voucher.lines[editingLineIndex]}
          defaultIsShimei={defaultIsShimei}
          onOk={(line) => {
            voucherFormMutations.editLine(editingLineIndex, line);
            voucherLineDialogOpenMutations.setFalse();
          }}
          onDelete={() => voucherFormMutations.deleteLine(editingLineIndex)}
          onCancel={voucherLineDialogOpenMutations.setFalse}
        />
      )}
      <ApplyDiscountDialog
        open={discountDialogOpen}
        discount={applyDiscountId ? discountMap[applyDiscountId] : null}
        voucher={voucher}
        onApplyToTotal={handleApplyDiscountToTotal}
        onApplyToLines={handleApplyDiscountToLines}
        onCancel={discountDialogOpenMutations.setFalse}
      />
      <CBackdrop open={false}>
        <CProgress />
      </CBackdrop>
    </>
  );
};
