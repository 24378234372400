import { BasicPlan, OptionLinePlan } from '../data/salon';

export const BasicPlanNameMap = {
  [BasicPlan.Trial]: 'トライアル',
  [BasicPlan.Single]: 'シングル',
  [BasicPlan.Standard]: 'スタンダード',
  [BasicPlan.Unlimited]: 'アンリミテッド',
};

export const OptionLinePlanName = 'LINEオプション';
export const OptionLinePlanNameMap = {
  [OptionLinePlan.Trial]: 'トライアル',
  [OptionLinePlan.Single]: 'シングル',
  [OptionLinePlan.Standard]: 'スタンダード',
};
