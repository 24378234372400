import { moment } from '@karutekun/shared/util/datetime';
import * as React from 'react';
import CDateRange from './CDateRange';

const format = {
  date: 'YYYY-MM-DD',
  month: 'YYYY-MM',
};

type OwnProps = {
  from?: string;
  to?: string;

  onChange(from: string, to: string): void;
};

class CMonthRangeControl extends React.PureComponent<OwnProps> {
  handleChange(from?: string, to?: string) {
    if (
      from !== undefined &&
      to !== undefined &&
      (this.props.from !== from || this.props.to !== to)
    ) {
      this.props.onChange(from, to);
    }
  }

  toMonthFormat(date?: string) {
    if (!date) {
      return date;
    }
    return moment(date, format.date).format(format.month);
  }

  toDateFormat(month?: string) {
    if (!month) {
      return month;
    }
    return moment(month, format.month).format(format.date);
  }

  render() {
    return (
      <CDateRange
        from={this.toDateFormat(this.props.from)}
        to={this.toDateFormat(this.props.to)}
        format="YYYY/MM"
        onChange={(from, to) =>
          this.handleChange(this.toMonthFormat(from), this.toMonthFormat(to))
        }
      />
    );
  }
}

export default CMonthRangeControl;
