/**
 * dayjs のタイムゾーン処理が改善されたら dayjs を使うように修正する予定
 */

import { moment } from './dayjs';

// unixtime 関連

/**
 * 1日のうち何秒目かを計算する
 */
export function unixToSecondsInDay(unix: number) {
  return unix - moment.unix(unix).startOf('day').unix();
}

/**
 * 当日00:00:00のunixtimeを返す
 */
export function unixToStartOfDayUnix(unix: number) {
  return moment.unix(unix).startOf('day').unix();
}

/**
 * 当日23:59:59のunixtimeを返す
 */
export function unixToEndOfDayUnix(unix: number) {
  return moment.unix(unix).endOf('day').unix();
}

/**
 * 'YYYY-MM-DD'形式の日付を変換して、当日00:00:00のunixtimeを返す
 */
export function dateToStartOfDayUnix(date: string) {
  return moment(date).startOf('day').unix();
}
export const dateToUnix = dateToStartOfDayUnix;

/**
 * 'YYYY-MM-DD'形式の日付を変換して、当日23:59:59のunixtimeを返す
 */
export function dateToEndOfDayUnix(date: string) {
  return moment(date).endOf('day').unix();
}

/**
 * 'YYYY-MM-DD HH:mm:ss'形式の日時を変換して、unixtimeを返す
 */
export function datetimeToUnix(datetime: string) {
  return moment(datetime, 'YYYY-MM-DD HH:mm:ss').unix();
}

/**
 * date: 'YYYY-MM-DD'
 * hour: 0~23
 * minute: 0~59
 * を受け取り、unixtimeを返す
 */
export function dateHourMinuteToUnix(
  date: string,
  hour: number,
  minute: number
) {
  return moment(date).hour(hour).minute(minute).unix();
}

// week
export function dateToStartOfWeekDate(date: string) {
  return moment(date).startOf('week').format('YYYY-MM-DD');
}
export function dateToEndOfWeekDate(date: string) {
  return moment(date).endOf('week').format('YYYY-MM-DD');
}

// month
export function startOfMonthDate(month?: string) {
  return (month ? moment(month, 'YYYY-MM') : moment())
    .startOf('month')
    .format('YYYY-MM-DD');
}
export function endOfMonthDate(month?: string) {
  return (month ? moment(month, 'YYYY-MM') : moment())
    .endOf('month')
    .format('YYYY-MM-DD');
}
export function dateToMonth(date: string) {
  return date.substring(0, 7);
}
export function dateToStartOfMonthDate(date: string) {
  return startOfMonthDate(dateToMonth(date));
}
export function dateToEndOfMonthDate(date: string) {
  return endOfMonthDate(dateToMonth(date));
}

// year
export function startOfYearMonth() {
  return moment().startOf('year').format('YYYY-MM');
}
export function endOfYearMonth() {
  return moment().endOf('year').format('YYYY-MM');
}

export function manipulateDate(
  date: string,
  addValue: number,
  unit: 'day' | 'week' | 'month'
) {
  return moment(date).add(addValue, unit).format('YYYY-MM-DD');
}
