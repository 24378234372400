import { moment } from '@karutekun/shared/util/datetime';
import { Button, Card, Grid, Theme } from '@mui/material';
import { WithStyles, createStyles, withStyles } from '@mui/styles';
import * as React from 'react';
import { getRecentOneMonth, getThisMonth } from '../../util/date';
import CSticky from '../atoms/CSticky';
import CDateRange from './CDateRange';

const styles = (theme: Theme) =>
  createStyles({
    bar: {
      padding: 8,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.primary.main,
    },
  });

type OwnProps = WithStyles<typeof styles> & {
  label: string;
  from?: string;
  to?: string;

  onChange(from: string, to: string): void;
};

class CStickyDateRangeControl extends React.PureComponent<OwnProps> {
  handleChange(from?: string, to?: string) {
    if (
      from !== undefined &&
      to !== undefined &&
      (this.props.from !== from || this.props.to !== to)
    ) {
      this.props.onChange(from, to);
    }
  }

  render() {
    return (
      <CSticky>
        <Card className={this.props.classes.bar} square>
          <Grid container item xs={12} alignItems="center" spacing={1}>
            <Grid item>
              <CDateRange
                label="期間"
                from={this.props.from}
                to={this.props.to}
                onChange={(from, to) => this.handleChange(from, to)}
              />
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                style={{ marginRight: 8 }}
                onClick={() => {
                  const range = getRecentOneMonth();
                  this.handleChange(range.from, range.to);
                }}
              >
                直近1ヶ月
              </Button>
              <Button
                variant="outlined"
                color="primary"
                style={{ marginRight: 8 }}
                onClick={() => {
                  const range = getThisMonth();
                  this.handleChange(range.from, range.to);
                }}
              >
                今月
              </Button>
              <Button
                variant="outlined"
                color="primary"
                style={{ marginRight: 8 }}
                onClick={() => {
                  const base = moment(this.props.from).subtract(1, 'month');
                  this.handleChange(
                    base.startOf('month').format('YYYY-MM-DD'),
                    base.endOf('month').format('YYYY-MM-DD')
                  );
                }}
              >
                &lt;前月
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  const base = moment(this.props.from).add(1, 'month');
                  this.handleChange(
                    base.startOf('month').format('YYYY-MM-DD'),
                    base.endOf('month').format('YYYY-MM-DD')
                  );
                }}
              >
                翌月&gt;
              </Button>
            </Grid>
          </Grid>
        </Card>
      </CSticky>
    );
  }
}

export default withStyles(styles)(CStickyDateRangeControl);
