import { VisitHistoryFilter } from '@karutekun/shared/data-access/visit';
import { SVGIcon } from '@karutekun/shared-fe/icons/react';
import { Grid } from '@mui/material';
import React, { useCallback } from 'react';
import { sendRequest } from '../../../../actions/request';
import { InformationText } from '../../../../texts/infomation';
import { useFlag } from '../../../../util/hooks/useFlag';
import { useThunkDispatch } from '../../../../util/hooks/useThunkDispatch';
import CButton from '../../../atoms/CButton';
import CInformation from '../../../atoms/CInformation';

type Props = {
  filter: VisitHistoryFilter;
};

export const CDownloadCsvButtons: FC<Props> = React.memo((props) => {
  const dispatch = useThunkDispatch();

  const { filter } = props;

  const [isDownloading, callWithDownloading] = useFlag();

  const handleDownload = useCallback(
    async (path: string, filename: string) => {
      callWithDownloading(() =>
        dispatch(() =>
          sendRequest(dispatch, path, {
            params: { filter: JSON.stringify(filter) },
            download: { filename },
          })
        )
      );
    },
    [callWithDownloading, dispatch, filter]
  );

  return (
    <Grid container justifyContent="flex-end" direction="row" spacing={2}>
      <Grid item>
        <CButton
          size="small"
          startIcon={<SVGIcon name="cloud-download" size="sm" />}
          onClick={() =>
            handleDownload(
              'analytics/visit_histories/download',
              '来店記録データ.csv'
            )
          }
          isLoading={isDownloading}
        >
          来店記録ダウンロード
        </CButton>
      </Grid>
      <Grid item>
        <CButton
          size="small"
          startIcon={<SVGIcon name="cloud-download" size="sm" />}
          onClick={() =>
            handleDownload(
              'analytics/visit_histories/download_menus_and_products',
              '来店記録施術・店販データ.csv'
            )
          }
          isLoading={isDownloading}
        >
          施術・店販ダウンロード
        </CButton>
      </Grid>
      <Grid item>
        <CInformation
          type="dialog"
          content={InformationText.visitHistories.csvDownload}
          size="lg"
        />
      </Grid>
    </Grid>
  );
});
