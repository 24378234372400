import { moment } from '@karutekun/shared/util/datetime';
import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ChatMessageBodyReservation as ChatMessageBodyReservationEntity } from '../../models/chatMessageBodyReservation/entity';

type Props = {
  chatMessageBody: ChatMessageBodyReservationEntity;
};

const useStyles = makeStyles((theme: Theme) => ({
  bold: {
    fontWeight: 'bold',
  },
  header: {
    marginTop: theme.spacing(1),
  },
  indent: {
    marginLeft: theme.spacing(1),
  },
}));

const ChatMessageBodyReservation: FC<Props> = (props) => {
  const classes = useStyles();

  const { datetime, stylist, memo, reservationMenus } = props.chatMessageBody;

  const mom = moment(datetime.date).hour(datetime.hour).minute(datetime.minute);

  return (
    <Grid container spacing={1} direction="column">
      <Grid item>
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.bold}
        >
          予約を受け付けました
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" className={classes.bold}>
          {mom.format('M/D(ddd) H:mm')}
        </Typography>

        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.header}
        >
          メニュー
        </Typography>
        {reservationMenus.map((rm, i) => (
          <Typography variant="body1" key={i} className={classes.indent}>
            {rm.name}
          </Typography>
        ))}

        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.header}
        >
          指名
        </Typography>
        <Typography variant="body1" className={classes.indent}>
          {stylist ? stylist.name : '担当者指定なし'}
        </Typography>

        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.header}
        >
          ご意見・ご要望等
        </Typography>
        <Typography variant="body1" className={classes.indent}>
          {memo || 'なし'}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ChatMessageBodyReservation;
